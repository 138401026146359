import React from 'react';
import { FormattedMessage } from 'react-intl';
import { trainingConstants } from '../constants';

export default function transformTrainingStatus(status) {
  switch (status) {
    case trainingConstants.PLANNED: return <FormattedMessage id="training.planned" defaultMessage="Planificada" />;
    case trainingConstants.CANCELED: return <FormattedMessage id="training.cancelled" defaultMessage="Cancelada" />;
    case trainingConstants.DONE: return <FormattedMessage id="training.made" defaultMessage="Realizada" />;
    case trainingConstants.FINISHED: return <FormattedMessage id="training.finished" defaultMessage="Finalizada" />;
    default: return ' ';
  }
}
