import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Breadcrumbs, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { useDispatch, useSelector } from 'react-redux';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DataTable from '../../DataTable/DataTable';
import PageLayout from '../../PageLayout';
import Button from '../../controls/Button';
import { personActions } from '../../../redux/actions';
import { history } from '../../../helpers';
import CircleButton from '../../controls/CircleButton';

function PersonPage() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'people.title', defaultMessage: 'Personas' });
  const initialFieldValues = {
    selectedPersonId: 0,
    deletePersonDialog: false,
  };
  const [values, setValues] = useState(initialFieldValues);
  const { selectedPersonId, deletePersonDialog } = values;
  const deletePersonTitle = intl.formatMessage({ id: 'people.delete.title', defaultMessage: 'Eliminar persona' });
  const editPersonTitle = intl.formatMessage({ id: 'people.edit.title', defaultMessage: 'Modificar persona' });
  const deletePersonText = intl.formatMessage({ id: 'people.deletePersonText', defaultMessage: '¿Está seguro que quiere eliminar a la persona?' });
  const deletingPerson = useSelector((state) => state.person.deleting);
  const userAuth = useSelector((state) => state.authentication.user);
  const company = useSelector((state) => state.company);
  const isAdmin = userAuth.roles.includes('Admin');
  const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const deletePersonButtonText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'Eliminar' });
  const bulkCreationTitle = intl.formatMessage({ id: 'user.bulkCreationTitle', defaultMessage: 'Carga múltiple' });
  const doesNotHaveLabel = intl.formatMessage({ id: 'user.doesNotHave', defaultMessage: 'No posee' });
  const userTypeOptions = {
    Admin: intl.formatMessage({ id: 'people.admin', defaultMessage: 'Administrador' }),
    User: intl.formatMessage({ id: 'people.user', defaultMessage: 'Usuario' }),
    NoAccess: intl.formatMessage({ id: 'people.noAccess', defaultMessage: 'Sin acceso al sistema' }),
  };

  function handleDialogOpen(dialogName, personId = null) {
    if (personId) {
      setValues({ selectedPersonId: personId, [dialogName]: true });
    } else {
      setValues({ ...values, [dialogName]: true });
    }
  }

  function handleDialogClose(dialogName) {
    setValues({ ...values, [dialogName]: false });
  }

  function deletePerson(personId) {
    dispatch(personActions.deletePerson(personId));
    handleDialogClose('deletePersonDialog');
  }

  function actionsButtons(link, personId, isOwnerOfCompany) {
    if (!isAdmin) return null;
    return (
      <>
        <IconButton onClick={() => { history.push(link); }} style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}>
          <Tooltip title={editPersonTitle}>
            <CreateIcon style={{ width: 16, height: 16, color: '#F79A56' }} />
          </Tooltip>
        </IconButton>
        {!isOwnerOfCompany && (
          <IconButton
            onClick={() => { handleDialogOpen('deletePersonDialog', personId); }}
            style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}
          >
            <Tooltip title={deletePersonTitle}>
              <DeleteRoundedIcon style={{ width: 16, height: 16, color: '#727272' }} />
            </Tooltip>
          </IconButton>
        )}
      </>
    );
  }
  const columns = [{
    name: 'id',
    options: {
      display: 'excluded',
      filter: false,
    },
  }, {
    name: 'names',
    label: intl.formatMessage({ id: 'people.names', defaultMessage: 'Nombre' }),
  },
  {
    name: 'surnames',
    label: intl.formatMessage({ id: 'people.surnames', defaultMessage: 'Apellido' }),
  },
  {
    name: 'personRoles',
    label: intl.formatMessage({ id: 'people.role', defaultMessage: 'Puesto' }),
    options: {
      customBodyRender: (value) => {
        if (value === '-') return value;
        return value.map((role) => role.name).join(', ');
      },
    },
  },
  {
    name: 'personnelFile',
    label: intl.formatMessage({ id: 'people.personnelFile', defaultMessage: 'Numero de Legajo' }),
  },
  {
    name: 'email',
    label: intl.formatMessage({ id: 'user.email', defaultMessage: 'Email' }),
    options: {
      customBodyRender: (email) => {
        if (email === '-') return doesNotHaveLabel;
        return email;
      },
      customDownloadBodyRender: (email) => {
        if (email === '-') return doesNotHaveLabel;
        return email;
      },
    },
  },
  {
    name: 'userType',
    label: intl.formatMessage({ id: 'people.userType', defaultMessage: 'Tipo de usuario' }),
    options: {
      customBodyRender: (value) => {
        if (value === '-') return userTypeOptions.NoAccess;
        return value.map((role) => userTypeOptions[role]).join(', ');
      },
    },
  },
  {
    name: '',
    options: {
      filter: false,
      sort: false,
      download: false,
      customBodyRender: (value, meta) => {
        const personId = meta.rowData[0];
        const isOwnerOfCompany = meta.rowData[7].id === company.data?.creatorUser;
        const link = `/people/${personId}/edit`;
        return actionsButtons(link, personId, isOwnerOfCompany);
      },
    },
  },
  ];
  const addButtonText = intl.formatMessage({ id: 'people.addPerson', defaultMessage: '+ Agregar persona' });
  const keys = ['id', 'names', 'surnames', 'personRoles', 'personnelFile', 'email', 'userType', 'applicationUser'];
  const fetchUrl = `companies/${userAuth.company.id}/people/`;
  const itemUrl = 'people/';

  function PersonPageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/people" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{title}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  function ButtonGroup() {
    if (userAuth.company.roles.includes('Admin')) {
      return (
        <Box display="flex">
          <Button
            text={addButtonText}
            component={Link}
            to="people/new"
            style={{ fontSize: '11px', fontWeight: 600 }}
          />
          <CircleButton
            text={(
              <Tooltip title={bulkCreationTitle}>
                <GroupAddIcon color="primary" fontSize="small" />
              </Tooltip>
      )}
            component={Link}
            to="people/upload"
            variant="outlined"
          />
        </Box>
      );
    }
    return null;
  }

  return (
    <PageLayout
      title={title}
      breadCrumbs={<PersonPageBreadcrumbs />}
      buttonGroup={<ButtonGroup />}
    >
      <DataTable
        title={title}
        fetchUrl={fetchUrl}
        itemUrl={itemUrl}
        keys={keys}
        columns={columns}
        addButtonText={addButtonText}
        addButtonTo="people/new"
      />
      <Dialog open={deletePersonDialog} onClose={() => { handleDialogClose('deletePersonDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{deletePersonTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {deletePersonText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button text={cancelButtonText} onClick={() => { handleDialogClose('deletePersonDialog'); }} />
          <Button
            text={deletePersonButtonText}
            color="secondary"
            loading={deletingPerson}
            onClick={() => { deletePerson(selectedPersonId); }}
          />
        </DialogActions>
      </Dialog>
    </PageLayout>

  );
}

export default PersonPage;
