import React, { useState, useEffect } from 'react';
import {
  Box, Container, FormControl, Grid, LinearProgress, MenuItem, Typography, makeStyles, Select,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, InputAdornment, Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '../controls/Button';
import { objectiveActions } from '../../redux/actions';
import { personService, processService } from '../../services';
import { objectiveTexts, objectiveConstants } from '../../constants';
import ObjectiveMeasures from './ObjectiveDetailPage/ObjectiveMeasures';
import colors from '../../assets/colors';
import PopoverWarningMessage from '../controls/PopoverWarningMessage';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  formControl: {
    width: '100%',
    marginTop: '16px',
    textAlign: 'left',
  },
  textArea: {
    '& .MuiInputBase-inputMultiline': {
      backgroundColor: 'rgba(255, 255, 255) ',
      minHeight: '75px',
      alignItems: 'flex-start',
      padding: 8,
    },
  },
  textAreaAnnualTarget: {
    '& .MuiInputBase-inputMultiline': {
      backgroundColor: 'rgba(255, 255, 255) ',
      alignItems: 'flex-start',
      padding: 8,
    },
  },
  labelAreaText: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '12px',
    textAlign: 'left',
  },
  textLabel: {
    '& .MuiFormLabel-root': {
      fontSize: '12px',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px) scale(1)',
      textAlign: 'left',
    },
  },
  textSeparator: {
    marginLeft: '24px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  textSeparatorMeasure: {
    marginTop: '32px',
    marginLeft: '24px',
    marginBottom: '24px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
  },
}));

const ObjectiveForm = ({ edit, data }) => {
  const initialFieldValues = {
    operator: '>=', year: new Date().getFullYear(), measure: {}, measurementDay: objectiveConstants.defaultMeasurementDay, ...data,
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const creatingObjectives = useSelector((state) => state.objective.creating);
  const updatingObjectives = useSelector((state) => state.objective.updating);
  const deletingObjective = useSelector((state) => state.objective.deleting);
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const [objectiveDetails, setObjectiveDetails] = useState(initialFieldValues);
  const [people, setPeople] = useState([]);
  const [process, setProcess] = useState([]);
  const [selectedProcesses, setSelectedProcesses] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const isBooleanOperator = objectiveDetails.operator === objectiveConstants.BOOLEAN_OPERATOR;

  useEffect(() => {
    const fetchData = async () => {
      const resPersonService = await personService.getAll();
      setPeople(resPersonService);
      const resProcessService = await processService.getAll();
      setProcess(resProcessService);
    };
    fetchData();
    if (edit && data.processes) {
      setSelectedProcesses(data.processes);
      const processId = data.processes.map((processF) => processF.id);
      setObjectiveDetails((prevObjectiveDetails) => ({
        ...prevObjectiveDetails,
        processes: processId,
      }));
    }
  }, [edit, data.processes]);

  function toggleDeleteDialog() {
    setOpenDeleteDialog(!openDeleteDialog);
  }

  function handleDeleteObjectives(id) {
    dispatch(objectiveActions.deleteObjective(id, objectiveTexts.successfulDelete));
  }

  function handleCreateObjectives() {
    const objectiveToSend = { ...objectiveDetails };
    if (isBooleanOperator) {
      objectiveToSend.annualTarget = null;
      objectiveToSend.isAccumulativeObjective = false;
    }
    dispatch(objectiveActions.addObjective(objectiveToSend, objectiveTexts.successfulSave));
  }

  function handleSaveEditObjectives() {
    const objectiveToSend = { ...objectiveDetails };
    if (isBooleanOperator) {
      objectiveToSend.annualTarget = null;
      objectiveToSend.isAccumulativeObjective = false;
    }
    dispatch(objectiveActions.updateObjective(
      data.id,
      objectiveToSend,
      objectiveTexts.successfulUpdate,
    ));
  }

  function handleChangeFormData(e, checked) {
    const { name, value } = e.target;
    if (name === 'year') {
      return setObjectiveDetails({ ...objectiveDetails, [name]: Number(value) });
    }
    if (typeof checked === 'boolean') {
      return setObjectiveDetails({ ...objectiveDetails, isAccumulativeObjective: checked });
    }
    return setObjectiveDetails({ ...objectiveDetails, [name]: value });
  }

  function handleSubmitForm(e) {
    e.preventDefault();
    if (edit) return handleSaveEditObjectives();
    return handleCreateObjectives();
  }

  return (
    <div className={classes.main}>
      {process ? (
        <ValidatorForm
          onSubmit={(e) => {
            handleSubmitForm(e);
          }}
          autoComplete="off"
          className={classes.textLabel}
        >
          <Typography className={classes.textSeparator}>
            {objectiveTexts.generalInformation}
          </Typography>
          <Grid container spacing={3} align="center">
            <Grid item xs={12} md={6}>
              <Container>
                <TextValidator
                  fullWidth
                  id="title"
                  label={objectiveTexts.titleLabel}
                  margin="normal"
                  name="title"
                  onChange={handleChangeFormData}
                  size="small"
                  value={objectiveDetails.title}
                  validators={['required']}
                  errorMessages={[objectiveTexts.isRequired]}
                />
              </Container>
            </Grid>
            <Grid item xs={12} md={6}>
              <Container align="flex-start">
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    filterSelectedOptions
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    id="processes-select"
                    multiple
                    noOptionsText={objectiveTexts.noProcessesToSelect}
                    options={process}
                    size="small"
                    loading={!process.length}
                    loadingText={objectiveTexts.processesLoading}
                    value={selectedProcesses}
                    onChange={(event, newValue) => {
                      setSelectedProcesses(newValue);
                      const selectedObjectiveProcesses = newValue.map((processF) => processF.id);
                      setObjectiveDetails({
                        ...objectiveDetails,
                        processes: selectedObjectiveProcesses,
                      });
                    }}
                    renderOption={(option) => (
                      <Typography noWrap style={{ fontSize: '12px' }}>
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        value={selectedProcesses}
                        variant="standard"
                        label={objectiveTexts.processes}
                        placeholder="..."
                        style={{ fontSize: '12px' }}
                        validators={['required']}
                        errorMessages={[objectiveTexts.isRequired]}
                      />
                    )}
                  />
                </FormControl>
              </Container>
            </Grid>
            <Grid item xs={12} md={6}>
              <Container>
                <Typography className={classes.labelAreaText}>
                  {objectiveTexts.descriptionOfObjective}
                </Typography>
                <TextValidator
                  classes={{ root: classes.textArea }}
                  fullWidth
                  id="descriptionId"
                  margin="normal"
                  multiline
                  name="description"
                  onChange={handleChangeFormData}
                  size="small"
                  style={{ marginTop: 8 }}
                  value={objectiveDetails.description}
                  InputLabelProps={{ shrink: true }}
                  validators={['required']}
                  errorMessages={[objectiveTexts.isRequired]}
                />
              </Container>
            </Grid>
            <Grid item xs={12} md={6}>
              <Container>
                <Typography className={classes.labelAreaText}>
                  {objectiveTexts.evaluationMethod}
                </Typography>
                <TextValidator
                  classes={{ root: classes.textArea }}
                  style={{ marginTop: 8 }}
                  value={objectiveDetails.evaluationMethod}
                  onChange={handleChangeFormData}
                  fullWidth
                  margin="normal"
                  id="evaluationMethodId"
                  name="evaluationMethod"
                  size="small"
                  multiline
                  InputLabelProps={{ shrink: true }}
                  validators={['required']}
                  errorMessages={[objectiveTexts.isRequired]}
                />
              </Container>
            </Grid>
            <Grid item xs={12} md={6}>
              <Container>
                <Typography className={classes.labelAreaText}>
                  {objectiveTexts.actionPlanForCompliance}
                </Typography>
                <TextValidator
                  classes={{ root: classes.textArea }}
                  fullWidth
                  id="actionPlanForComplianceId"
                  margin="normal"
                  multiline
                  name="actionPlanForCompliance"
                  onChange={handleChangeFormData}
                  size="small"
                  style={{ marginTop: 8 }}
                  value={objectiveDetails.actionPlanForCompliance}
                  InputLabelProps={{ shrink: true }}
                  validators={['required']}
                  errorMessages={[objectiveTexts.isRequired]}
                />
              </Container>
            </Grid>
            <Grid item xs={12} md={6}>
              <Container>
                <Typography className={classes.labelAreaText}>
                  {objectiveTexts.necessaryResources}
                </Typography>
                <TextValidator
                  fullWidth
                  id="necessaryResourcesId"
                  name="necessaryResources"
                  margin="normal"
                  multiline
                  onChange={handleChangeFormData}
                  size="small"
                  value={objectiveDetails.necessaryResources}
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: classes.textArea }}
                  style={{ marginTop: 8 }}
                  validators={['required']}
                  errorMessages={[objectiveTexts.isRequired]}
                />
              </Container>
            </Grid>

            <Grid item xs={12} md={6}>
              <Container>
                <FormControl className={classes.formControl}>
                  <SelectValidator
                    fullWidth
                    id="responsibleForCompliance-select"
                    label={objectiveTexts.responsibleForCompliance}
                    name="responsibleForCompliance"
                    onChange={handleChangeFormData}
                    value={objectiveDetails.responsibleForCompliance}
                    validators={['required']}
                    errorMessages={[objectiveTexts.isRequired]}
                  >
                    <MenuItem style={{ fontSize: '12px' }} value={null}>
                      <em>{objectiveTexts.selectOption}</em>
                    </MenuItem>
                    {people.map((item) => {
                      if (!item.names) return null;
                      return (
                        <MenuItem
                          style={{ fontSize: '12px' }}
                          key={`${item.id}${item.surnames}`}
                          value={item.id}
                        >
                          {`${item.names} ${item.surnames}`}
                        </MenuItem>
                      );
                    })}
                  </SelectValidator>
                </FormControl>
              </Container>
            </Grid>
            <Grid item xs={12} md={6}>
              <Container>
                <TextValidator
                  type="number"
                  fullWidth
                  id="title"
                  label={objectiveTexts.yearTitle}
                  margin="normal"
                  name="year"
                  onChange={handleChangeFormData}
                  size="small"
                  value={objectiveDetails.year}
                  validators={['required']}
                  errorMessages={[objectiveTexts.isRequired]}
                />
              </Container>
            </Grid>
          </Grid>

          <Typography className={classes.textSeparatorMeasure}>
            {objectiveTexts.measureTitle}
          </Typography>
          <Grid container spacing={3} align="center">
            <Grid item xs={12} md={6}>
              <Container>
                <Typography className={classes.labelAreaText}>
                  {objectiveTexts.indicator}
                </Typography>
                <TextValidator
                  classes={{ root: classes.textArea }}
                  style={{ marginTop: 8 }}
                  value={objectiveDetails.indicator}
                  onChange={handleChangeFormData}
                  fullWidth
                  margin="normal"
                  id="indicatorId"
                  name="indicator"
                  size="small"
                  multiline
                  InputLabelProps={{ shrink: true }}
                  validators={['required']}
                  errorMessages={[objectiveTexts.isRequired]}
                />
              </Container>
            </Grid>
            <Grid item xs={12} md={6}>
              <Container>
                <Typography className={classes.labelAreaText}>
                  {objectiveTexts.annualTarget}
                </Typography>
                <TextValidator
                  classes={{ root: classes.textAreaAnnualTarget }}
                  style={{ marginTop: 8 }}
                  value={isBooleanOperator ? '-' : objectiveDetails.annualTarget}
                  onChange={handleChangeFormData}
                  fullWidth
                  margin="normal"
                  id="title"
                  name="annualTarget"
                  size="small"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  validators={['required']}
                  errorMessages={[objectiveTexts.isRequired]}
                  disabled={isBooleanOperator}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        variant="outlined"
                        position="start"
                        style={{ height: 'auto', marginRight: '0px' }}
                      >
                        <Select
                          id="operator-select"
                          disableUnderline
                          style={{ paddingLeft: '8px' }}
                          variant="standard"
                          value={objectiveDetails.operator}
                          name="operator"
                          onChange={handleChangeFormData}
                          fullWidth
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'center',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'center',
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {objectiveConstants.operators.map((value, index) => (
                            <MenuItem
                              style={{ fontSize: '12px' }}
                              key={`select-${value + index}`}
                              value={value}
                            >
                              {objectiveConstants.translateOperators(value)}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box display="flex">
                  <FormControlLabel
                    disabled={isBooleanOperator}
                    control={(
                      <Checkbox
                        checked={isBooleanOperator
                          ? false
                          : objectiveDetails.isAccumulativeObjective}
                        name="isAccumulativeObjective"
                        onChange={handleChangeFormData}
                      />
                  )}
                    labelPlacement="end"
                    label={(
                      <Typography style={{ fontSize: '12px', color: colors.darkGray }}>
                        {objectiveTexts.isAccumulativeObjective}
                      </Typography>
                  )}
                  />
                  <PopoverWarningMessage customStyle={{ maxWidth: '350px' }} id={1} message=" Si un objetivo es acumulativo, el sistema evaluará su cumplimiento a través de la sumatoria de cada una de las mediciones del año. Caso contrario, se evaluará a través de la última medición disponible." />
                </Box>
              </Container>
            </Grid>

            <Grid item xs={12} md={6}>
              <Container>
                <FormControl className={classes.formControl}>
                  <SelectValidator
                    label={objectiveTexts.frequency}
                    id="frequency-select"
                    disabled={edit && objectiveDetails.frequency && objectiveDetails.measure}
                    value={objectiveDetails.frequency}
                    name="frequency"
                    onChange={handleChangeFormData}
                    fullWidth
                    validators={['required']}
                    errorMessages={[objectiveTexts.isRequired]}
                  >
                    <MenuItem style={{ fontSize: '12px' }} value="">
                      <em>{objectiveTexts.selectOption}</em>
                    </MenuItem>
                    {Object.entries(objectiveConstants.frequency).map(([key, value], index) => (
                      <MenuItem
                        style={{ fontSize: '12px' }}
                        key={`select-${key + index}`}
                        value={value.key}
                      >
                        {value.label}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </FormControl>
              </Container>
            </Grid>

            <Grid item xs={12} md={6}>
              <Container>
                <FormControl className={classes.formControl}>
                  <SelectValidator
                    label={objectiveTexts.measurementDay}
                    id="frequency-measurementDay"
                    value={objectiveDetails.measurementDay}
                    name="measurementDay"
                    onChange={handleChangeFormData}
                    validators={['required']}
                    errorMessages={[objectiveTexts.isRequired]}
                  >
                    {objectiveConstants.measurementDay.map((value, index) => (
                      <MenuItem
                        style={{ fontSize: '12px' }}
                        key={`select-${value + index}`}
                        value={value}
                      >
                        {value}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </FormControl>
              </Container>
            </Grid>

            {edit
            && data.frequency && data.measure && objectiveDetails.year
            && (
            <Grid item xs={12} md={6}>
              <ObjectiveMeasures
                measures={data.measure}
                measureFrequency={data.frequency}
                year={objectiveDetails.year}
                isAccumulativeObjective={objectiveDetails.isAccumulativeObjective}
                operator={objectiveDetails.operator}
              />
            </Grid>
            )}

          </Grid>

          <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
            <Button
              component={Link}
              to="/objectives"
              variant="outlined"
              text={objectiveTexts.backText}
              style={{ fontSize: '10px', fontWeight: 600 }}
            />
            {edit ? (
              <>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => toggleDeleteDialog()}
                  text={objectiveTexts.deleteButton}
                  style={{ fontSize: '10px', fontWeight: 600 }}
                  disabled={!isAdmin}
                />
                <Button
                  type="submit"
                  text={objectiveTexts.saveText}
                  style={{ fontSize: '10px', fontWeight: 600 }}
                  loading={updatingObjectives}
                  disabled={!isAdmin}
                />

                <Dialog open={openDeleteDialog} onClose={() => toggleDeleteDialog()} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">{objectiveTexts.deleteObjectiveTitleDialog}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {objectiveTexts.deleteObjectiveContentDialog}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button text={objectiveTexts.cancel} onClick={() => toggleDeleteDialog()} />
                    <Button
                      className={classes.menuButton}
                      text={objectiveTexts.deleteButton}
                      color="secondary"
                      loading={deletingObjective}
                      onClick={() => { handleDeleteObjectives(data.id); }}
                    />
                  </DialogActions>
                </Dialog>
              </>
            )
              : (
                <Button
                  type="submit"
                  loading={creatingObjectives}
                  text={objectiveTexts.createText}
                  disabled={!isAdmin}
                />
              )}
          </Box>
        </ValidatorForm>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
};

ObjectiveForm.propTypes = {
  data: PropTypes.shape(),
  edit: PropTypes.bool,
};

ObjectiveForm.defaultProps = {
  data: {},
  edit: false,
};

export default ObjectiveForm;
