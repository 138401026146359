import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../redux/actions';
import BulkCreationResult from '../../BulkUpload/BulkCreationResult';

const useStyles = makeStyles((theme) => ({
  paddingNone: {
    padding: theme.spacing(0),
  },
  paddingItem: {
    paddingLeft: theme.spacing(0),
  },
}));

const UserBulkCreationResult = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const peopleBulkCreationTitle = intl.formatMessage({ id: 'user.peopleBulkCreationTitle', defaultMessage: 'Carga múltiple de personas' });
  const peopleText = intl.formatMessage({ id: 'people.title', defaultMessage: 'Personas' });

  const tableErrors = [
    {
      id: 1,
      row: 'row',
      name: intl.formatMessage({ id: 'user.row', defaultMessage: 'Fila' }),
      classesHead: classes.paddingNone,
      classesBody: classes.paddingItem,
    },
    { id: 2, row: 'email', name: intl.formatMessage({ id: 'user.email', defaultMessage: 'Email' }) },
    { id: 3, row: 'role', name: intl.formatMessage({ id: 'people.userType', defaultMessage: 'Tipo de usuario' }) },
    { id: 4, row: 'names', name: intl.formatMessage({ id: 'user.names', defaultMessage: 'Nombre' }) },
    { id: 5, row: 'surnames', name: intl.formatMessage({ id: 'user.surnames', defaultMessage: 'Apellido' }) },
    { id: 6, row: 'phoneNumber', name: intl.formatMessage({ id: 'people.phoneNumber', defaultMessage: 'Número de teléfono' }) },
    { id: 7, row: 'personRole', name: intl.formatMessage({ id: 'people.personRoles', defaultMessage: 'Puestos' }) },
  ];
  const tableLoaded = [
    { id: 1, row: 'email', name: intl.formatMessage({ id: 'user.email', defaultMessage: 'Email' }) },
    { id: 2, row: 'role', name: intl.formatMessage({ id: 'people.userType', defaultMessage: 'Tipo de usuario' }) },
    { id: 3, row: 'names', name: intl.formatMessage({ id: 'user.names', defaultMessage: 'Nombre' }) },
    { id: 4, row: 'surnames', name: intl.formatMessage({ id: 'user.surnames', defaultMessage: 'Apellido' }) },
    { id: 5, row: 'phoneNumber', name: intl.formatMessage({ id: 'people.phoneNumber', defaultMessage: 'Número de teléfono' }) },
    { id: 6, row: 'personRole', name: intl.formatMessage({ id: 'people.personRoles', defaultMessage: 'Puestos' }) },
  ];
  const linkRedirect = '/people/upload';
  const linkEntity = '/people';
  const text = {
    bulkCreationBreadcrumbs: peopleBulkCreationTitle,
    bulkNotCreated: intl.formatMessage({ id: 'user.bulkUsersNotCreated', defaultMessage: 'Estos usuarios no serán creados' }),
    bulkUploadedSuccess: intl.formatMessage({ id: 'user.bulkUsersUploadedSuccess', defaultMessage: 'Usuarios cargados correctamente:' }),
    create: intl.formatMessage({ id: 'user.createBulkUsers', defaultMessage: 'Crear personas' }),
    emptyListSuccessfulUpload: intl.formatMessage({ id: 'user.emptyListSuccessfulUploadUsers', defaultMessage: 'No hay usuarios cargados correctamente, por favor vuelva a cargar los datos respetando el archivo de ejemplo.' }),
    messageUploadedSuccess: intl.formatMessage({ id: 'user.messageUploadedSuccess', defaultMessage: 'Los usuarios se han registrado exitosamente' }),
    onlySuccessUploadWillBeCreated: intl.formatMessage({ id: 'user.onlySuccessUploadUsersWillBeCreated', defaultMessage: 'Solo se crearán las personas o usuarios cargados correctamente.' }),
    title: peopleText,
  };

  const handleConfirmUsers = () => {
    dispatch(
      userActions.confirmCreateBulkUsers(
        user.bulkCreationPreviewResult.id, text.messageUploadedSuccess,
      ),
    );
  };

  return (
    <BulkCreationResult
      entity={user}
      handleConfirmResult={handleConfirmUsers}
      linkEntity={linkEntity}
      linkRedirect={linkRedirect}
      tableErrors={tableErrors}
      tableLoaded={tableLoaded}
      text={text}
    />
  );
};

export default UserBulkCreationResult;
