import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, makeStyles, Box, Grid,
  Container, Select, Input, MenuItem, Checkbox, InputLabel, FormControl,
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { history } from '../../helpers';
import { validationConstants } from '../../constants';
import { userActions, alertActions, personActions } from '../../redux/actions';
import Button from '../controls/Button';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  formControl: {
    minWidth: '100%',
  },

}));

function UserForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { edit, data } = props;
  const initialFieldValues = {
    email: (data && Object.keys(data).length) ? data.email : '',
    roles: (data && Object.keys(data).length) ? data.roles : [],
    person: (data && data.person) ? data.person : '',
    deleteUserDialog: false,
  };
  const [values, setValues] = useState(initialFieldValues);
  const {
    email, roles, person, deleteUserDialog,
  } = values;
  const people = useSelector((state) => state.person.people);
  const creatingUser = useSelector((state) => state.user.creating);
  const deletingUser = useSelector((state) => state.user.deleting);
  const userAuth = useSelector((state) => state.authentication.user);
  const asignRolesText = intl.formatMessage({ id: 'user.asignRoles', defaultMessage: 'Asignar roles' });
  const deleteUserTitle = intl.formatMessage({ id: 'user.delete.title', defaultMessage: 'Eliminar usuario' });
  const personLinkedLabel = intl.formatMessage({ id: 'user.linkedPerson', defaultMessage: 'Persona asociada' });
  const deleteUserButtonText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'Eliminar' });
  const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const deleteUserText = intl.formatMessage({ id: 'user.deleteUserText', defaultMessage: '¿Está seguro que quiere eliminar el usuario?' });
  const deleteText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'eliminar' });
  const saveText = intl.formatMessage({ id: 'app.save', defaultMessage: 'guardar' });
  const createText = intl.formatMessage({ id: 'app.create', defaultMessage: 'crear' });
  const nonePersonLabel = intl.formatMessage({ id: 'user.none', defaultMessage: 'Ninguna' });
  const companyCreatorOnlyAccessLabel = intl.formatMessage({ id: 'stripe.companyCreatorOnlyAccess', defaultMessage: 'Solo puede acceder el creador del empresa' });

  const defaultRoles = [
    {
      name: 'Admin',
    },
    {
      name: 'User',
    },
  ];

  useEffect(() => {
    if (!people) {
      dispatch(personActions.getAll());
    }
  }, [dispatch, people]);

  function handleSubmit(e) {
    e.preventDefault();
    const user = {
      email, roles, person: person || null,
    };

    if (data) { user.id = data.id; }

    if (!edit) {
      dispatch(userActions.create(user, '/users/'));
    } else {
      if (!person) {
        dispatch(personActions.unlinkUser(data.person.id));
      }
      dispatch(userActions.update(user, '/users/'));
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  }

  function handleDialogOpen(dialogName) {
    setValues({ ...values, [dialogName]: true });
  }

  function handleDialogClose(dialogName) {
    setValues({ ...values, [dialogName]: false });
  }

  function deleteUser() {
    dispatch(userActions.deleteUser(data.id));
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    getContentAnchorEl: null,
  };

  if (!userAuth.isSuperAdmin) {
    dispatch(alertActions.info(companyCreatorOnlyAccessLabel));
    return <Redirect to={{ pathname: '/home' }} />;
  }

  return (
    <div className={classes.main}>
      <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Container>
              <TextValidator
                value={email}
                onChange={handleChange}
                fullWidth
                margin="normal"
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                validators={['required', 'isEmail']}
                errorMessages={[
                  validationConstants.EMAIL_REQUIRED,
                  validationConstants.EMAIL_INVALID,
                ]}
              />
            </Container>
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginTop: '32px' }}>
            <Container>
              <Select
                id="roles"
                name="roles"
                displayEmpty
                multiple
                value={roles}
                onChange={handleChange}
                input={<Input />}
                MenuProps={MenuProps}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>{asignRolesText}</em>;
                  }
                  return selected.join(', ');
                }}
                fullWidth
              >
                <MenuItem disabled value="">
                  <em>{asignRolesText}</em>
                </MenuItem>
                {defaultRoles.map((role) => (
                  <MenuItem
                    key={role.name}
                    value={role.name}
                  >
                    <Checkbox
                      color="primary"
                      checked={roles.indexOf(role.name) > -1}
                    />
                    {role.name}
                  </MenuItem>
                ))}

              </Select>
            </Container>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Container>
              <FormControl className={classes.formControl}>
                <InputLabel id="person-label">{personLinkedLabel}</InputLabel>
                <Select
                  id="person"
                  name="person"
                  value={person}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                  labelId="person-label"
                  style={{ width: '100%' }}
                >
                  <MenuItem value="">
                    <em>{nonePersonLabel}</em>
                  </MenuItem>
                  {people && people.map((personToSelect) => {
                    if (person && (personToSelect.id !== person.id)) {
                      return (
                        <MenuItem key={personToSelect.id} value={personToSelect}>
                          {`${personToSelect.names} ${personToSelect.surnames}`}
                        </MenuItem>
                      );
                    } if (person) {
                      return (
                        <MenuItem key={person.id} value={person}>
                          {`${person.names} ${person.surnames}`}
                        </MenuItem>
                      );
                    }
                    if (!person) {
                      return (
                        <MenuItem key={personToSelect.id} value={personToSelect}>
                          {`${personToSelect.names} ${personToSelect.surnames}`}
                        </MenuItem>
                      );
                    }
                    return null;
                  })}
                </Select>
              </FormControl>
            </Container>
          </Grid>
        </Grid>

        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
          <Button
            onClick={history.goBack}
            variant="outlined"
            text={<FormattedMessage id="app.back" defaultMessage="volver" />}
          />
          {edit
            ? (
              <>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => { handleDialogOpen('deleteUserDialog'); }}
                  text={deleteText}
                  style={{ fontSize: '10px', fontWeight: 600 }}
                />
                <Button
                  type="submit"
                  text={saveText}
                  style={{ fontSize: '10px', fontWeight: 600 }}
                />
                <Dialog open={deleteUserDialog} onClose={() => { handleDialogClose('deleteUserDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">{deleteUserTitle}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {deleteUserText}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button text={cancelButtonText} onClick={() => { handleDialogClose('deleteUserDialog'); }} />
                    <Button
                      className={classes.menuButton}
                      text={deleteUserButtonText}
                      color="secondary"
                      loading={deletingUser}
                      onClick={deleteUser}
                    />
                  </DialogActions>
                </Dialog>
              </>
            )
            : (
              <>
                <Button
                  type="submit"
                  loading={creatingUser}
                  text={createText}
                />
              </>
            )}

        </Box>
      </ValidatorForm>
    </div>
  );
}

UserForm.propTypes = {
  data: PropTypes.shape(),
  edit: PropTypes.bool,
};

UserForm.defaultProps = {
  data: {},
  edit: false,
};

export default UserForm;
