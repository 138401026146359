import React from 'react';
import { SvgIcon } from "@material-ui/core";

function PeopleIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M8.51705 8C10.7145 8 12.4943 6.21 12.4943 4C12.4943 1.79 10.7145 0 8.51705 0C6.3196 0 4.53977 1.79 4.53977 4C4.53977 6.21 6.3196 8 8.51705 8ZM8.51705 10C5.86222 10 0.5625 11.34 0.5625 14V16H16.4716V14C16.4716 11.34 11.1719 10 8.51705 10Z" />
      </SvgIcon>
    );
  }

  export default PeopleIcon

  