/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OutlineEvaluationForm from '../OutlineEvaluationForm';
import { personActions } from '../../../redux/actions';
import PageLayout from '../../PageLayout';

const NewOutlineEvaluationPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const people = useSelector((state) => state.person.people);
  const newOutlineEvaluationText = intl.formatMessage({ id: 'outlineEvaluation.newTitle', defaultMessage: 'Nueva evaluación de perfil' });
  const outlinesEvaluationsText = intl.formatMessage({ id: 'outlineEvaluation.evaluationsText', defaultMessage: 'Evaluaciones' });

  useEffect(() => {
    dispatch(personActions.getAll());
  }, [dispatch]);

  function NewOutlineEvaluationBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/outlines" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{outlinesEvaluationsText}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{newOutlineEvaluationText}</Typography>
      </Breadcrumbs>
    );
  }

  return (
    <PageLayout title={newOutlineEvaluationText} breadCrumbs={<NewOutlineEvaluationBreadcrumbs />}>
      <OutlineEvaluationForm data={null} people={people} />
    </PageLayout>
  );
};

export default NewOutlineEvaluationPage;
