/**
* Use the CSS tab above to style your Element's container.
*/
import React, { useState } from 'react';
import {
  CardElement,
} from '@stripe/react-stripe-js';
import './CardSectionStyles.css';
import { useIntl } from 'react-intl';
import { Box, Input, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextFieldsIcon from '@material-ui/icons/TextFields';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
  hidePostalCode: true,
};

const useStyles = makeStyles(() => ({
  containerTitular: {
    height: 40,
    padding: '10px 12px',
    marginTop: '12px',
    boxShadow: '0 1px 3px 0 #e6ebf1',
    '&:focus, &:hover': {
      boxShadow: '0 1px 3px 0 #cfd7df',
    },
  },
  titularName: {
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    padding: 0,
    '&::placeholder': {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      fontWeight: 500,
    },
  },
}));

const CardSection = () => {
  const classes = useStyles();
  const intl = useIntl();
  const cardDetailsText = intl.formatMessage({ id: 'stripe.cardDetails', defaultMessage: 'Datos de tarjeta' });
  const ownersNameText = intl.formatMessage({ id: 'stripe.ownersName', defaultMessage: 'Nombre del titular' });
  const [titularName, setTitularName] = useState('');

  const handleNameChange = (e) => {
    const { value } = e.target;
    setTitularName(value);
  };

  return (
    <Box maxWidth={400}>
      <Typography variant="body1" style={{ paddingBottom: 24, fontSize: 14 }}>
        {cardDetailsText}
      </Typography>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      <Box className={classes.containerTitular}>
        <Input
          id="titularName"
          name="titularName"
          classes={{ input: classes.titularName }}
          disableUnderline
          onChange={handleNameChange}
          placeholder={ownersNameText}
          value={titularName}
          startAdornment={<TextFieldsIcon style={{ color: '#DCDFE6', marginRight: 17 }} />}
        />
      </Box>
    </Box>
  );
};

export default CardSection;
