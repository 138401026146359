import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import * as joint from 'jointjs';
import {
  AppBar, Box, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Divider, Fab, LinearProgress, makeStyles, Menu, MenuItem, Toolbar,
  Tooltip, Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import CloseIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import RemoveIcon from '@material-ui/icons/Remove';
import svgPanZoom from 'svg-pan-zoom';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../controls/Button';
import {
  OrgChartLink, OrgChartBox, createPaper,
} from '../../jointjs/CustomElementsOrgChart';
import { history } from '../../../helpers';
import { companyActions, personRoleActions } from '../../../redux/actions';
import { validationConstants, videoTutorialId } from '../../../constants';
import AddPeopleModal from '../AddPeopleModal/AddPeopleModal';
import defaultPhotoActions from '../../../assets/defaultPhotoPerfil.png';
import VideoTutorialModal from '../../controls/VideoTutorialModal';
import TutorialInfo from '../../controls/TutorialInfo';
import tutorialActions from '../../../redux/actions/tutorial.actions';

const useStyles = makeStyles((theme) => ({
  canvas: {
    width: '100%',
    marginLeft: 40,
    marginBottom: 20,
    marginRight: 24,
    backgroundColor: '#FFFFFF',
    cursor: 'pointer',
  },
  toolbar: {
    backgroundColor: '#F8F8F8',
    padding: '0 0 0 16px',
  },
  toolbarTitle: {
    color: '#F79A56',
    fontWeight: 700,
  },
  saveMessage: {
    color: '#757575',
  },
  saveIcon: {
    color: '#757575',
  },
  zoomButton: {
    margin: theme.spacing(1),
    boxShadow: 'none',
  },
  showEmptyCanvas: {
    margin: 'auto',
    marginTop: 100,
    marginBottom: 40,
    maxWidth: 408,
    maxHeight: 121,
  },
  centerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  tutorialEightStepWrapper: {
    position: "absolute",
    right: 70,
    bottom: 140,
    [theme.breakpoints.up('xs')]: {
      right: 0,
    },
    [theme.breakpoints.up('lg')]: {
      right: 120,
    },
    [theme.breakpoints.up('xl')]: {
      right: 240,
    },
  }
}));

const initialFieldValues = {
  newRoleName: '',
  addPersonDialog: false,
  newRoleDialog: false,
  removePersonDialog: false,
  editMode: false,
};

const initialContextMenu = {
  mouseX: null,
  mouseY: null,
};

const OrgChart = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const canvas = useRef(null);
  const paper = useRef(null);
  const svgPan = useRef(null);
  const graph = useRef(null);
  const personRoles = useSelector((state) => state.personRole.personRoles);
  const company = useSelector((state) => state.company);
  const userCompanyId = useSelector(
    (state) => (state.authentication.user.company ? state.authentication.user.company.id : null),
  );
  const { roles, id: userId } = useSelector((state) => state.authentication.user);

  const step = useSelector((state) => state.tutorial.step);
  const isAdmin = roles.includes('Admin');
  const [contextMenu, setContextMenu] = useState(initialContextMenu);
  const [graphLoadComplete, setGraphLoadComplete] = useState(false);
  const [hasOrgChart, setHasOrgChart] = useState(false);
  const [positionXNewPerson, setPositionXNewPerson] = useState(300);
  const [positionYNewPerson, setPositionYNewPerson] = useState(100);
  const [selectedCell, setSelectedCell] = useState();
  const [updatingGraph, setUpdatingGraph] = useState(false);
  const [values, setValues] = useState(initialFieldValues);
  const [addedPeople, setAddedPeople] = useState([]);
  const [newPeopleInOrgChart, setNewPeopleInOrgChart] = useState([]);

  const addPersonButtonText = intl.formatMessage({ id: 'orgChart.addPerson', defaultMessage: 'Agregar Persona' });
  const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const doneButtonText = intl.formatMessage({ id: 'app.done', defaultMessage: 'Finalizar' });
  const editOrgChartText = intl.formatMessage({ id: 'orgChart.editOrgChart', defaultMessage: 'Editar organigrama' });
  const enterNewRoleText = intl.formatMessage({ id: 'orgChart.enterNewRole', defaultMessage: 'Ingrese el nuevo nombre del puesto de la persona' });
  const newRoleText = intl.formatMessage({ id: 'orgChart.newRole', defaultMessage: 'Nuevo puesto' });
  const newRoleTitle = intl.formatMessage({ id: 'orgChart.newRoleTitle', defaultMessage: 'Cambiar el puesto de la persona' });
  const deletePersonConfirmText = intl.formatMessage({ id: 'orgChart.deletePersonConfirm', defaultMessage: '¿Está seguro que quiere remover a la persona del organigrama?' });
  const deletePersonTitle = intl.formatMessage({ id: 'orgChart.deletePerson', defaultMessage: 'Eliminar persona' });
  const deleteText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'Eliminar' });
  const renameRoleText = intl.formatMessage({ id: 'orgChart.renameRolePerson', defaultMessage: 'Cambiar puesto' });
  const saveText = intl.formatMessage({ id: 'app.save', defaultMessage: 'Guardar' });
  const toPersonText = intl.formatMessage({ id: 'orgChart.toPerson', defaultMessage: 'Ir al perfil' });

  const debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const handleClick = (event) => {
    event.preventDefault();
    if (svgPan.current) svgPan.current.disablePan();
  };

  const handleCloseContextMenu = () => {
    setContextMenu(initialContextMenu);
    if (svgPan.current) svgPan.current.enablePan();
  };

  function handleDialogOpen(dialogName) {
    setValues((prevValues) => ({ ...prevValues, [dialogName]: true }));
  }

  function handleDialogClose(dialogName) {
    setValues((prevValues) => ({ ...prevValues, [dialogName]: false }));
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  }

  function endEditing() {
    if (svgPan.current) {
      svgPan.current.resetZoom();
      svgPan.current.resetPan();
    }
    paper.current.setInteractivity(false);
    paper.current.off('cell:pointerup');
    paper.current.off('cell:pointerdown');
    paper.current.off('cell:mouseenter');
    paper.current.off('cell:mouseleave');
    paper.current.off('link:mouseenter');
    paper.current.off('link:mouseleave');
    graph.current.off('all');
    setValues((prevValues) => ({ ...prevValues, editMode: false }));
  }

  const saveGraph = useCallback(() => {
    const graphJson = graph.current.toJSON();
    const companyToUpdate = company.data;
    companyToUpdate.organizationChart = JSON.stringify(graphJson);
    dispatch(companyActions.updateOrganizationChart(companyToUpdate));
    const hasPeople = graphJson.cells.length > 0;
    setHasOrgChart(hasPeople);
  }, [company.data, dispatch]);

  const saveGraphDebounced = debounce(saveGraph, 2000);

  function formatText(text, maxLength) {
    if (text && maxLength && (text.length > maxLength)) {
      return `${text.slice(0, (maxLength - 2))}...`;
    }
    return text;
  }

  const addPerson = useCallback((personRole) => {
    const orgChartBox = new OrgChartBox();
    const imageUrl = personRole.person.imageUrl || defaultPhotoActions;
    const fullName = `${personRole.person.names} ${personRole.person.surnames}`;
    orgChartBox.attr('person/isPersonalRole', true);
    orgChartBox.attr('person/id', personRole.id);
    orgChartBox.attr('person/role', personRole.name);
    orgChartBox.attr('role/text', formatText(personRole.name, 18));

    orgChartBox.attr('person/personId', personRole.person.id);
    orgChartBox.attr('name/text', formatText(fullName, 18));
    orgChartBox.attr('person/name', fullName);
    orgChartBox.attr('image/xlink:href', imageUrl);
    orgChartBox.attr('person/imageUrl', imageUrl);
    orgChartBox.position(positionXNewPerson, positionYNewPerson);
    graph.current.addCell(orgChartBox);
    setPositionXNewPerson(positionXNewPerson + 20);
    setPositionYNewPerson(positionYNewPerson + 20);
    return orgChartBox;
  }, [positionXNewPerson, positionYNewPerson]);

  const syncPeople = useCallback(() => {
    const elements = graph.current.getElements();
    const graphPeople = elements.filter((element) => element.attributes.type === 'custom.OrgChartBox');
    const allPeopleModel = graphPeople.map((element) => element.attributes.attrs.person);
    setAddedPeople(allPeopleModel);
    saveGraph();
  }, [saveGraph]);

  const renameByModel = useCallback((model, newName, label, attribute, maxLength) => {
    model.attr(label, formatText(newName, maxLength));
    model.attr(attribute, newName);
    return model;
  }, []);

  function handleRenameRole() {
    const orgChartBox = renameByModel(selectedCell.model, values.newRoleName, 'role/text', 'person/role', 18);

    const personRoleId = orgChartBox.attr('person/id');
    const personRole = personRoles.find((pr) => pr.id === personRoleId);
    if (personRole) {
      personRole.name = values.newRoleName;
      dispatch(personRoleActions.update(personRole));
    }

    setValues((prevValues) => ({ ...prevValues, newRoleDialog: false, newRoleName: '' }));
    saveGraph();
  }

  function deleteByModel(model) {
    const cellId = model.id;
    graph.current.getCell(cellId).remove();
  }

  function handleRemovePerson() {
    deleteByModel(selectedCell.model);
    handleDialogClose('removePersonDialog');
    syncPeople();
  }

  function handleZoomIn() {
    if (svgPan.current) svgPan.current.zoomIn();
  }

  function handleZoomOut() {
    if (svgPan.current) svgPan.current.zoomOut();
  }

  function handleLinkMouseHover() {
    paper.current.on('link:mouseenter', (linkView) => {
      if (!linkView._toolsView) {
        const toolsView = new joint.dia.ToolsView({
          tools: [
            new joint.linkTools.Remove({ focusOpacity: 0.5, rotate: true }),
            new joint.linkTools.Vertices(),
            new joint.linkTools.Segments(),
            new joint.linkTools.Boundary(),
            new joint.linkTools.TargetArrowhead(),
            new joint.linkTools.Remove(),
            new joint.linkTools.Remove({
              action() {
                linkView.model.remove({ ui: true, tool: linkView.cid });
                saveGraph();
              },
            }),
          ],
        });
        linkView.addTools(toolsView);
      }
      linkView.showTools();
    });

    paper.current.on('link:mouseleave', (linkView) => {
      linkView.hideTools();
    });
  }

  function handleCellMouseHover() {
    paper.current.on('cell:mouseenter', (cellView) => {
      const cellType = cellView.model.attributes.type;
      if (cellType === 'custom.OrgChartBox') {
        cellView.model.attr('tool/strokeWidth', 2);
      }

      if (
        cellType !== 'custom.OrgChartLink'
        && cellType !== 'custom.OrgChartBox'
        && !cellView._toolsView
      ) {
        cellView.addTools(
          new joint.dia.ToolsView({
            tools: [
              new joint.elementTools.Remove({
                focusOpacity: 1,
                rotate: true,
                action(evt, shape, toolView) {
                  shape.model.remove({ ui: true, tool: toolView.cid });
                },
              }),
            ],
          }),
        );
      }

      cellView.showTools();
    });

    paper.current.on('cell:mouseleave', (cellView) => {
      if (cellView.model.attributes.type === 'custom.OrgChartBox') {
        cellView.model.attr('tool/strokeWidth', 0);
      }

      cellView.hideTools();
    });
  }

  function handleCellClick() {
    paper.current.on('cell:pointerdown', (cellView) => {
      if (cellView.model.attributes.type === 'custom.OrgChartBox') {
        cellView.model.attr('tool/strokeWidth', 0);
      }
      cellView.hideTools();
      cellView.highlight(cellView.model.id);
      if (svgPan.current) svgPan.current.disablePan();
    });

    paper.current.on('cell:pointerup', (cellView) => {
      if (cellView.model.attributes.type === 'custom.OrgChartBox') {
        cellView.model.attr('tool/strokeWidth', 2);
      }
      cellView.showTools();
      cellView.unhighlight(cellView.model.id);
      if (svgPan.current) svgPan.current.enablePan();
    });
  }

  function handleCanvas() {
    svgPan.current = svgPanZoom('#canvas svg', {
      center: false,
      mouseWheelZoomEnabled: false,
      zoomEnabled: true,
      panEnabled: true,
      fit: false,
      minZoom: 0.5,
      maxZoom: 2,
      zoomScaleSensitivity: 0.1,
    });
  }

  function handleContextMenu() {
    paper.current.on('cell:contextmenu', (cellView, event) => {
      if (cellView.model.attributes.type === 'custom.OrgChartBox') {
        setContextMenu({
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        });
      }

      setSelectedCell(cellView);
    });
  }

  function redirectToPerson() {
    const personId = selectedCell.model.attr('person/personId');
    history.push(`/people/${personId}`);

    handleCloseContextMenu();
  }

  function openRemovePersonDialog() {
    handleDialogOpen('removePersonDialog');
    handleCloseContextMenu();
  }

  function openNewRoleDialog() {
    const selectedPersonRole = selectedCell.model.attr('person/role');
    setValues((prevValues) => ({
      ...prevValues,
      newRoleName: selectedPersonRole,
      newRoleDialog: true,
    }));
    handleCloseContextMenu();
  }

  function handleGraphChanges() {
    const excludedEvents = [
      'change:attrs',
      'change',
      'add',
      'sort',
      'update',
      'batch:start',
      'batch:stop',
      'remove',
    ];
    graph.current.on('all', (eventName) => {
      if (!excludedEvents.includes(eventName)) {
        setUpdatingGraph(true);
        saveGraphDebounced();
      }
    });
  }

  function enableEditMode() {
    setValues((prevValues) => ({ ...prevValues, editMode: true }));
    handleLinkMouseHover();
    handleCellMouseHover();
    handleCellClick();
    handleGraphChanges();
    paper.current.setInteractivity(true);
  }

  useEffect(() => {
    function syncCurrentStatusPeople(personRoleList) {
      const elements = graph.current.getElements();
      const graphPeople = elements.filter((element) => element.attributes.type === 'custom.OrgChartBox');

      graphPeople.forEach((graphPerson) => {
        const personGraph = graphPerson.attributes.attrs.person;
        if (personGraph && !personGraph.isPersonalRole) {
          const personRole = personRoleList.filter((role) => role.person.id === personGraph.id);
          if (personRole.length > 0) {
            const foundRole = personRole.find((role) => role.name === personGraph.role);

            const newRoleId = foundRole ? foundRole.id : personRole[0].id;
            const newPersonId = foundRole ? foundRole.person.id : personRole[0].person.id;
            const newRoleName = foundRole ? foundRole.name : personRole[0].name;
            const fullName = `${personRole[0].person.names} ${personRole[0].person.surnames}`;
            const personImageUrl = personRole[0].person.imageUrl || defaultPhotoActions;

            graphPerson.attr('person/id', newRoleId);
            graphPerson.attr('person/personId', newPersonId);
            graphPerson.attr('person/isPersonalRole', true);
            renameByModel(graphPerson, newRoleName, 'role/text', 'person/role', 18);
            renameByModel(graphPerson, fullName, 'name/text', 'person/name', 18);
            renameByModel(graphPerson, personImageUrl, 'image/xlink:href', 'person/imageUrl');
          } else {
            deleteByModel(graphPerson);
          }
        } else if (personGraph && personGraph.isPersonalRole) {
          const foundPersonRole = personRoleList.find((role) => role.id === personGraph.id);
          if (foundPersonRole) {
            const fullName = `${foundPersonRole.person.names} ${foundPersonRole.person.surnames}`;
            const personImageUrl = foundPersonRole.person.imageUrl || defaultPhotoActions;

            renameByModel(graphPerson, foundPersonRole.name, 'role/text', 'person/role', 18);
            renameByModel(graphPerson, fullName, 'name/text', 'person/name', 18);
            renameByModel(graphPerson, personImageUrl, 'image/xlink:href', 'person/imageUrl');
          } else {
            deleteByModel(graphPerson);
          }
        }
      });
    }

    function initCanvas() {
      joint.shapes.custom = {};
      joint.shapes.custom.OrgChartLink = OrgChartLink;
      joint.shapes.custom.OrgChartBox = OrgChartBox;

      graph.current = new joint.dia.Graph([], { cellNamespace: joint.shapes });
      paper.current = createPaper(canvas.current, graph.current);
      let lengthOrgChart = 0;

      if (company.data.organizationChart) {
        const JSONparseOrgChart = JSON.parse(company.data.organizationChart);
        lengthOrgChart = JSONparseOrgChart.cells.length;
        graph.current.fromJSON(JSONparseOrgChart);
      }
      if (lengthOrgChart) handleCanvas();
      handleContextMenu();
      setGraphLoadComplete(true);
      setHasOrgChart(Boolean(lengthOrgChart));
      paper.current.unfreeze();
      syncPeople();
    }

    if (!paper.current && !company.data && userCompanyId) {
      dispatch(companyActions.get(userCompanyId));
    }

    if (!paper.current && company.data) {
      dispatch(personRoleActions.getAll());
      initCanvas();
    }

    if (personRoles) syncCurrentStatusPeople(personRoles);

    if (company.saveResult) {
      setUpdatingGraph(false);
    }

    if (newPeopleInOrgChart.length) {
      let positionY = 0;
      let positionX = 0;
      newPeopleInOrgChart.forEach((person) => {
        const orgChartBox = addPerson(person);
        orgChartBox.translate(positionX, positionY);
        positionY += 60;
        positionX += 20;
      });
      setNewPeopleInOrgChart([]);
      syncPeople();
    }
  }, [addPerson, company.data, company.saveResult, dispatch, personRoles,
    newPeopleInOrgChart, syncPeople, userCompanyId, renameByModel]);

  function showEmptyCanvas() {
    return (
      <Box mt={5} className={classes.showEmptyCanvas}>
        <Box className={classes.centerContainer}>
          <Typography variant="caption" style={{ fontWeight: 700, fontSize: 14 }}>
            <FormattedMessage id="orgChart.startCreationOrgChart" defaultMessage="¡Empezá a crear el organigrama!" />
          </Typography>
        </Box>
        <Box className={classes.centerContainer} mt={1} width={400}>
          <Typography align="center" variant="caption" style={{ fontWeight: 400, fontSize: 11 }}>
            <FormattedMessage id="orgChart.startAddingPeopleHere" defaultMessage="Puedes comenzar añadiendo a las personas aquí" />
          </Typography>
        </Box>
        <Box className={classes.centerContainer} mt={2}>
          <Button
            onClick={() => handleDialogOpen('addPersonDialog')}
            text={<FormattedMessage id="orgChart.create" defaultMessage="CREAR ORGANIGRAMA" />}
            style={{ fontSize: 10, fontWeight: 600 }}
            disabled={!isAdmin}
          />
        </Box>
      </Box>
    );
  }

  function handleLastTutorialStep() {
    dispatch(tutorialActions.setTutorialAsCompleted(step, userId));
  }

  return (
    <div className={classes.main} onContextMenu={handleClick} style={{ cursor: 'context-menu' }}>
      <AppBar elevation={0} className={classes.toolbar} position="static">
        <Toolbar>
          <Box alignItems="center" display="flex" flexGrow={1}>
            <Typography className={classes.toolbarTitle} display="inline" variant="h6">
              <FormattedMessage id="orgChart.title" defaultMessage="Organigrama" />
            </Typography>
            <Box ml={5}>
              <VideoTutorialModal
                title={<FormattedMessage id="videoTutorial.orgChartTitle" defaultMessage="Cómo crear el Organigrama" />}
                videoId={videoTutorialId.ORG_CHART}
                isOrgChart
              />
            </Box>
          </Box>
          {updatingGraph && (
            <>
              <CachedIcon className={classes.saveIcon} fontSize="small" />
              <Typography className={classes.saveMessage} variant="caption">
                <FormattedMessage id="orgChart.saving" defaultMessage="Guardando..." />
              </Typography>
            </>
          )}
          {company.syncResult && company.syncResult.sync && !updatingGraph && (
            <>
              <DoneIcon className={classes.saveIcon} fontSize="small" />
              <Typography className={classes.saveMessage} variant="caption">
                <FormattedMessage id="orgChart.syncResultOk" defaultMessage="Todos los cambios están sincronizados" />
              </Typography>
            </>
          )}
          {company.syncResult && hasOrgChart && !company.syncResult.sync && !updatingGraph && (
            <>
              <CloseIcon className={classes.saveIcon} fontSize="small" />
              <Typography className={classes.saveMessage} variant="caption">
                <FormattedMessage id="orgChart.syncResultFailed" defaultMessage="No se pudieron sincronizar los cambios" />
              </Typography>
            </>
          )}
          {company.saveResult && company.saveResult.success && !updatingGraph && (
            <>
              <DoneIcon className={classes.saveIcon} fontSize="small" />
              <Tooltip title={`${company.saveResult.date}   ${company.saveResult.time}`}>
                <Typography className={classes.saveMessage} variant="caption">
                  <FormattedMessage id="orgChart.saveResultOk" defaultMessage="Todos los cambios están guardados" />
                </Typography>
              </Tooltip>
            </>
          )}
          {company.saveResult && !company.saveResult.success && !updatingGraph && (
            <>
              <CloseIcon className={classes.saveIcon} fontSize="small" />
              <Typography className={classes.saveMessage} variant="caption">
                <FormattedMessage id="orgChart.saveResultFailed" defaultMessage="No se pudieron guardar los cambios" />
              </Typography>
            </>
          )}
          {values.editMode && hasOrgChart && (
            <>
              <Fab id="zoomin" onClick={handleZoomIn} size="small" aria-label="add" className={classes.zoomButton}>
                <AddIcon />
              </Fab>
              <Fab id="zoomout" onClick={handleZoomOut} size="small" aria-label="remove" className={classes.zoomButton}>
                <RemoveIcon />
              </Fab>
              <Button
                variant="outlined"
                color="primary"
                text={addPersonButtonText}
                onClick={() => handleDialogOpen('addPersonDialog')}
              />
              <Button className={classes.menuButton} text={doneButtonText} onClick={endEditing} />
            </>
          )}
          <Dialog
            aria-labelledby="form-dialog-title"
            fullWidth
            onClose={() => handleDialogClose('newRoleDialog')}
            open={values.newRoleDialog}
          >
            <ValidatorForm style={{ width: '100%' }} onSubmit={handleRenameRole}>
              <DialogTitle id="form-dialog-title">{newRoleTitle}</DialogTitle>
              <DialogContent>
                <DialogContentText>{enterNewRoleText}</DialogContentText>
                <TextValidator
                  value={values.newRoleName}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  id="newRoleName"
                  label={newRoleText}
                  name="newRoleName"
                  autoFocus
                  validators={['required']}
                  errorMessages={[validationConstants.PERSON_POSITION_REQUIRED]}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  text={cancelButtonText}
                  onClick={() => handleDialogClose('newRoleDialog')}
                  variant="outlined"
                />
                <Button className={classes.menuButton} text={saveText} type="submit" />
              </DialogActions>
            </ValidatorForm>
          </Dialog>

          <Dialog
            open={values.removePersonDialog}
            onClose={() => handleDialogClose('removePersonDialog')}
            fullWidth
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">{deletePersonTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText>{deletePersonConfirmText}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                text={cancelButtonText}
                onClick={() => handleDialogClose('removePersonDialog')}
              />
              <Button
                className={classes.menuButton}
                text={deleteText}
                color="secondary"
                onClick={handleRemovePerson}
              />
            </DialogActions>
          </Dialog>

          <Dialog
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
            onClose={() => handleDialogClose('addPersonDialog')}
            open={values.addPersonDialog}
          >
            <AddPeopleModal
              isOpen={values.addPersonDialog}
              setIsOpen={() => handleDialogClose('addPersonDialog')}
              personRoles={personRoles}
              addedPeople={addedPeople}
              setNewPeopleInOrgChart={setNewPeopleInOrgChart}
            />
          </Dialog>
          {isAdmin && !values.editMode && hasOrgChart && (
            <Button
              className={classes.menuButton}
              text={editOrgChartText}
              loading={!company}
              onClick={enableEditMode}
            />
          )}
        </Toolbar>
      </AppBar>
      {!graphLoadComplete && <LinearProgress />}
      {!hasOrgChart && graphLoadComplete && showEmptyCanvas()}
      <Box className={classes.canvas}>
        <div
          ref={canvas}
          id="canvas"
          data-filter="all"
          style={{
            backgroundColor: hasOrgChart ? '#FFFFFF' : '#F8F8F8',
            height: hasOrgChart ? 500 : 1,
          }}
        />
      </Box>

      {step === 8 && (
        <Box className={classes.tutorialEightStepWrapper} onClick={handleLastTutorialStep}>
          <TutorialInfo step={8} />
        </Box>
      )}

      <Menu
        keepMounted
        open={
          contextMenu.mouseY !== null
          && selectedCell
          && selectedCell.model.attributes.type === 'custom.OrgChartBox'
        }
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu.mouseY !== null && contextMenu.mouseX !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={redirectToPerson}>{toPersonText}</MenuItem>
        {values.editMode && (
          <div>
            <Divider />
            <MenuItem onClick={openNewRoleDialog}>{renameRoleText}</MenuItem>
            <Divider />
            <MenuItem onClick={openRemovePersonDialog}>{deleteText}</MenuItem>
          </div>
        )}
      </Menu>
    </div>
  );
};

export default OrgChart;
