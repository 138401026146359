const tutorialConstants = {
    SET_TUTORIAL_STEP: 'SET_TUTORIAL_STEP',
    SET_STEPS_AS_COMPLETED: 'SET_STEPS_AS_COMPLETED',
};


export const STEPS = [
    {
        title: '¡BIENVENIDO A PERTIX!',
        subText: 'Vamos a comenzar a armar tus procesos',
        width: '272px',
        hasOnClose: false,
        alignText: 'center',
    },
    {
        text: 'Haz click aqui y selecciona "Nuevo"',
        width: '242px',
        hasOnClose: true,
        alignText: 'center',
    },
    {
        text: 'Introduce el Nombre y el Área, luego haz clic en “Crear”',
        text2: 'Por ejemplo: Nombre: “Ventas”  Área: “Comercial”',
        width: '383px',
        hasOnClose: true,
        alignText: 'left',
    },
    {
        text: 'Muy bien! Ya tienes tu primer proceso, agrega más así completas tu mapa',
        hasOnClose: true,
        width: '494px',
        alignText: 'center',
        goToNextStep: true,
    },
    {
        text: 'Si necesitas más información sobre alguna funcionalidad, contacta a un asesor o accede a nuestro canal de Youtube haciendo clic',
        link: 'AQUÍ',
        hasOnClose: true,
        width: '455px',
        goToNextStep: true,
    },
    {
        text: '¡Vamos a explorar otras funciones!',
        hasOnClose: true,
        width: '260px',
        alignText: 'center',
        goToNextStep: true,
    },
    {
        text: 'Recuerda que tienes video tutoriales haciendo click aquí',
        hasOnClose: true,
        goToNextStep: true,
    },
    {
        text: 'Puedes contactarnos de forma rápida si necesitas ayuda o quieres una capacitación gratuita.',
        hasOnClose: true,
        width: '360px',
    }
]

export default tutorialConstants;
