import React from 'react';
import { Alert } from '@material-ui/lab';
import { Snackbar, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import { alertActions, modalAlertActions } from '../../redux/actions';

const SnackbarAlert = () => {
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const horizontal = 'center';
  const vertical = 'top';

  const handleCloseAlert = () => {
    dispatch(alertActions.clear());
  };

  const alertIconMapping = {
    error: <ErrorIcon fontSize="inherit" />,
    info: <ErrorIcon fontSize="inherit" />,
    success: <CheckCircleIcon fontSize="inherit" />,
    warning: <WarningIcon fontSize="inherit" />,
  };

  const getMessage = (message) => {
    if (Array.isArray(message)) {
      dispatch(modalAlertActions.infoSubscription(message[0]));
      return message[1];
    }
    return message;
  };

  return (
    <>
      {alert.message && (
        <Snackbar
          open={Boolean(alert)}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
          onClose={handleCloseAlert}
          autoHideDuration={3000}
        >
          <Alert
            onClose={() => {}}
            onClick={handleCloseAlert}
            variant="filled"
            iconMapping={alertIconMapping}
            severity={alert.type}
          >
            <Typography variant="caption" display="block" gutterBottom>
              {getMessage(alert.message)}
            </Typography>
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default SnackbarAlert;
