import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, TableCell, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { affiliateText } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  planTitle: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 700,
    paddingTop: 18,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    height: '100%',
  },
  subLabel: { fontSize: 8, fontWeight: 700, letterSpacing: '0.23em' },
  priceText: { fontSize: 30, fontWeight: 600, lineHeight: 1.2 },
  defaultCursor: { cursor: 'default', padding: '6px 20px 6px 12px' },
  priceTextAdornment: { fontSize: 12 },
  strikethroughPrice: {
    letterSpacing: '0.16px',
    textDecorationLine: 'line-through',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '11px',
    color: '#6D6D6D',
  },
  promotionalText: {
    fontSize: 10, fontWeight: 400, color: '#6D6D6D',
  },
}));

const FormatPlansTableCell = ({
  plan, isValidForAffiliateDiscountedPrice, ...others
}) => {
  const classes = useStyles();

  return (
    <TableCell {...others} className={classes.defaultCursor} style={isValidForAffiliateDiscountedPrice ? { height: '140px' } : { height: '110px' }}>
      <Box className={classes.titleContainer}>
        <Box>
          <Typography align="center" className={classes.planTitle}>
            {plan.name}
          </Typography>
          {isValidForAffiliateDiscountedPrice && plan.subLabel
            ? (
              <Box>
                <Typography align="center" className={classes.subLabel}>
                  {plan.subLabel}
                </Typography>
                <Typography align="center" className={classes.promotionalText}>
                  {plan.isAnnual ? affiliateText.firstYear : affiliateText.first3Months}
                </Typography>
              </Box>
            ) : <Box style={{ display: 'block', height: '27px' }} />}
        </Box>
        <Box>
          {plan.price && (
            <Box alignItems="baseline" justifyContent="center" display="flex" height={40}>
              <Typography display="inline" className={classes.priceTextAdornment} style={{ paddingRight: 8, whiteSpace: 'nowrap' }}>
                USD $
              </Typography>
              <Typography display="inline" className={classes.priceText}>
                {plan.price}
              </Typography>
              <Typography display="inline" className={classes.priceTextAdornment} style={{ paddingLeft: 4 }}>
                /
              </Typography>
              <Typography display="inline" className={classes.priceTextAdornment}>
                {plan.frequency}
              </Typography>
            </Box>
            )}

          {!plan.price && (
              <Box alignItems="flex-end" justifyContent="center" display="flex" height={40}>
                <Typography align="center" style={{ paddingBottom: 5, fontWeight: 700, fontSize: 22 }}>
                  <CircularProgress />
                </Typography>
              </Box>
            )}

          {isValidForAffiliateDiscountedPrice && plan.price && plan.strikethroughPrice
            && (
              <Box alignItems="baseline" justifyContent="center" display="flex" height={20}>
                <Typography className={classes.strikethroughPrice}>
                  USD $
                  {plan.strikethroughPrice}
                  /
                  {plan.frequency}
                </Typography>
              </Box>
            )}
        </Box>
      </Box>
    </TableCell>
  );
};
FormatPlansTableCell.propTypes = {
  plan: PropTypes.shape(),
  isValidForAffiliateDiscountedPrice: PropTypes.bool,
};
FormatPlansTableCell.defaultProps = {
  plan: {},
  isValidForAffiliateDiscountedPrice: false,
};

export default FormatPlansTableCell;
