import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs, LinearProgress, Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PageLayout from '../../PageLayout';
import { deviationActions } from '../../../redux/actions';
import DeviationForm from '../DeviationForm';

const EditDeviationDetailPage = ({ routeParams: { id: deviationId } }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const deviation = useSelector((state) => state.deviation.data);
  const title = intl.formatMessage({ id: 'deviation.title', defaultMessage: 'Desvíos' });
  const editDeviation = intl.formatMessage({ id: 'deviation.editDeviation', defaultMessage: 'Editar' });

  function EditDeviationBreadcrumbs() {
    const link = `/deviations/${deviationId}`;
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/deviations" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{title}</Typography>
        </Link>
        <Link color="inherit" to={link} style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{deviation.id}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{editDeviation}</strong></Typography>
      </Breadcrumbs>
    );
  }

  function EditDeviationDetailBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/deviations" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{title}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{editDeviation}</strong></Typography>
      </Breadcrumbs>
    );
  }

  useEffect(() => {
    dispatch(deviationActions.getDeviations(deviationId));
  }, [deviationId, dispatch]);

  return (
    <>
      {deviation
        ? (
          <PageLayout title={title} breadCrumbs={<EditDeviationBreadcrumbs />}>
            <DeviationForm edit data={deviation} />
          </PageLayout>
        )
        : (
          <PageLayout title={title} breadCrumbs={<EditDeviationDetailBreadcrumbs />}>
            <LinearProgress />
          </PageLayout>
        )}
    </>
  );
};

EditDeviationDetailPage.propTypes = {
  routeParams: PropTypes.shape(),
};

EditDeviationDetailPage.defaultProps = {
  routeParams: {},
};

export default EditDeviationDetailPage;
