import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box, Breadcrumbs, makeStyles, Typography, Grid, LinearProgress,
  Container, FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../helpers';
import PageLayout from '../../PageLayout';
import { personActions, userActions } from '../../../redux/actions';
import Button from '../../controls/Button';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  toolbar: {
    backgroundColor: '#F8F8F8',
    zIndex: 1,
  },
  toolbarTitle: {
    color: '#F79A56',
    flexGrow: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    backgroundColor: '#F8F8F8',
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(2),
    },
  },
  linkIcon: {
    width: '16px',
    height: '14px',
    color: '#696969',
  },
  documentIcon: {
    width: '9px',
    height: '11px',
    color: '#F16C24',
  },
  linkDocuments: {
    textTransform: 'uppercase',
    fontSize: '10px',
    color: '#F16C24',
    fontWeight: 600,
  },
  formControl: {
    minWidth: '100%',
  },
}));

function UserDetailsPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector((state) => state.user.data);
  const people = useSelector((state) => state.person.people);
  const linkingPerson = useSelector((state) => state.person.linking);
  const [person, setPerson] = useState('');
  const [componentMount, setComponentMount] = useState(false);
  const nonePersonLabel = intl.formatMessage({ id: 'person.none', defaultMessage: 'Ninguna' });
  const personLinkedLabel = intl.formatMessage({ id: 'user.linkedPerson', defaultMessage: 'Persona asociada' });
  const userId = props.match.params.id;
  const userEmailLabel = intl.formatMessage({ id: 'user.email', defaultMessage: 'Email' });
  const userRoleLabel = intl.formatMessage({ id: 'user.role', defaultMessage: 'Rol' });
  const usersTitle = intl.formatMessage({ id: 'user.usersTitle', defaultMessage: 'Usuarios' });
  const editLink = `${userId}/edit`;
  const userDetailsText = intl.formatMessage({ id: 'user.details', defaultMessage: 'Detalle de usuario' });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    getContentAnchorEl: null,
  };

  useEffect(() => {
    if (!componentMount) {
      dispatch(userActions.get(userId));
      dispatch(personActions.getAll());
      setComponentMount(true);
    } else {
      setPerson((user && user.person) ? user.person : '');
    }
  }, [dispatch, componentMount, user, userId]);

  function UserDetailsBreadcrumbs() {
    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/users" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{usersTitle}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{user.email}</strong></Typography>
      </Breadcrumbs>
    );
  }

  function UserDetailsLoadingBreadcrumbs() {
    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/users" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{usersTitle}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{userDetailsText}</strong></Typography>
      </Breadcrumbs>
    );
  }

  const listUserRoles = (roles) => {
    let rolesList = '';
    roles.forEach((role) => {
      rolesList = rolesList ? `${rolesList}, ${role}` : role;
    });
    return rolesList;
  };

  function handleChangeSelect(e) {
    const { value } = e.target;
    if (value.id) {
      dispatch(personActions.linkUser(value.id, user.id));
    } else {
      dispatch(personActions.unlinkUser(user.person.id));
    }
    setPerson(value);
  }

  return (
    <>
      {user && people
        ? (
          <PageLayout title={user.email} breadCrumbs={<UserDetailsBreadcrumbs />}>
            <Box pl={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: '14px' }}>{userEmailLabel}</strong>
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                    {user.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: '14px' }}>{userRoleLabel}</strong>
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                    {listUserRoles(user.roles)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Container style={{ padding: '0 0 0 0' }}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="person-label">{personLinkedLabel}</InputLabel>
                      {!linkingPerson ? (
                        <Select
                          id="person"
                          name="person"
                          value={person}
                          onChange={handleChangeSelect}
                          MenuProps={MenuProps}
                          labelId="person-label"
                          style={{ width: '100%' }}
                        >
                          <MenuItem value="">
                            <em>{nonePersonLabel}</em>
                          </MenuItem>
                          {people && people.map((personToSelect) => {
                            if (person && (personToSelect.id !== person.id)) {
                              return (
                                <MenuItem key={personToSelect.id} value={personToSelect}>
                                  {`${personToSelect.names} ${personToSelect.surnames}`}
                                </MenuItem>
                              );
                            } if (person) {
                              return (
                                <MenuItem key={person.id} value={person}>
                                  {`${person.names} ${person.surnames}`}
                                </MenuItem>
                              );
                            }
                            if (!person) {
                              return (
                                <MenuItem key={personToSelect.id} value={personToSelect}>
                                  {`${personToSelect.names} ${personToSelect.surnames}`}
                                </MenuItem>
                              );
                            }
                            return null;
                          })}
                        </Select>
                      ) : (
                        <Box mt={3}>
                          <LinearProgress />
                        </Box>
                      )}

                    </FormControl>
                  </Container>
                </Grid>
              </Grid>

            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
              <Button
                onClick={history.goBack}
                variant="outlined"
                text={<FormattedMessage id="app.back" defaultMessage="volver" />}
              />
              <Button
                component={Link}
                to={editLink}
                type="submit"
                text={<FormattedMessage id="app.edit" defaultMessage="editar" />}
              />
            </Box>
          </PageLayout>
        )
        : (
          <PageLayout title={userDetailsText} breadCrumbs={<UserDetailsLoadingBreadcrumbs />}>
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
}

export default UserDetailsPage;
