import { workFieldConstants } from '../../constants';
import { workFieldService } from '../../services';
import { history } from '../../helpers';
import alertActions from './alert.actions';

function create(workField, message) {
  function request() { return { type: workFieldConstants.CREATE_REQUEST }; }
  function success(createdWorkField) {
    return { type: workFieldConstants.CREATE_SUCCESS, createdWorkField };
  }
  function failure(error) { return { type: workFieldConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(workField));

    workFieldService.create(workField)
      .then(
        (createdWorkField) => {
          dispatch(success(createdWorkField));
          dispatch(alertActions.success(message));
          history.push(`/workFields/${createdWorkField.id}`);
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getAll() {
  function request() { return { type: workFieldConstants.GET_ALL_REQUEST }; }
  function success(workField) { return { type: workFieldConstants.GET_ALL_SUCCESS, workField }; }
  function failure(error) { return { type: workFieldConstants.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    workFieldService.getAll()
      .then(
        (workField) => {
          dispatch(success(workField));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function get(id) {
  function request() { return { type: workFieldConstants.GET_REQUEST }; }
  function success(workField) { return { type: workFieldConstants.GET_SUCCESS, workField }; }
  function failure(error) { return { type: workFieldConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    workFieldService.get(id)
      .then(
        (workField) => {
          dispatch(success(workField));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
          history.push('/home');
        },
      );
  };
}

function update(id, workField, message) {
  function request() { return { type: workFieldConstants.UPDATE_REQUEST }; }
  function success(updatedWorkField) {
    return { type: workFieldConstants.UPDATE_SUCCESS, updatedWorkField };
  }
  function failure(error) { return { type: workFieldConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    workFieldService.update(id, workField)
      .then(
        (updatedWorkField) => {
          dispatch(success(updatedWorkField));
          dispatch(alertActions.success(message));
          history.push(`/workFields/${updatedWorkField.id}`);
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function deleteWorkField(workFieldId, message) {
  function request() { return { type: workFieldConstants.DELETE_REQUEST }; }
  function success() { return { type: workFieldConstants.DELETE_SUCCESS }; }
  function failure() { return { type: workFieldConstants.DELETE_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    workFieldService.deleteWorkField(workFieldId)
      .then(
        () => {
          dispatch(success());
          dispatch(alertActions.success(message));
          history.push('/workFields');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

const workFieldActions = {
  create,
  get,
  getAll,
  update,
  deleteWorkField,
};

export default workFieldActions;
