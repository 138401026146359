import React from 'react';
import {
  Box, DialogContent, makeStyles, Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import CalendarEventDetailPersonCard from '../CalendarEventDetailPersonCard';
import ObjectiveGauge from '../../../Objective/ObjectiveDetailPage/ObjectiveGauge';
import CalendarEventDetailProcessList from '../CalendarEventDetailProcessList';
import { objectiveConstants, objectiveTexts } from '../../../../constants';
import { objectiveHelper } from '../../../../helpers';

const useStyles = makeStyles(() => ({
  flexCenterBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  dialogContent: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    margin: '20px 0px 10px',
    boxSizing: 'border-box',
  },
  objectiveCardContainer: {
    boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    height: '212px',
    width: '190px',
  },
  lastUsageDatetimeText: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '12px',
    marginBottom: '6px',
  },
  lastUsageDatetimeContainer: {
    marginBottom: '16px',
    borderLeft: '1px solid #C4C4C4',
    paddingLeft: '27px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  accumulativeTotal: {
    fontSize: '12px',
    fontWeight: 600,
    marginTop: '6px',
  },
}));

const CalendarEventDetailObjectiveContent = ({ event }) => {
  const classes = useStyles();
  const intl = useIntl();
  const invalidData = [null, undefined, NaN, ''];
  const responsibleText = intl.formatMessage({ id: 'people.responsible', defaultMessage: 'Responsable' });
  const lastUsageDatetimeText = intl.formatMessage({ id: 'objective.monthOfLastMeasure', defaultMessage: 'Mes de la última medición' });

  const formatLastUsageDateTime = (measures) => {
    let lastMeasureLabel = objectiveTexts.withoutMeasure;
    objectiveConstants.frequency.monthly.values.forEach((month) => {
      if (!invalidData.includes(measures[month])) {
        lastMeasureLabel = objectiveTexts[month];
      }
    });
    return lastMeasureLabel;
  };

  return (
    <DialogContent className={classes.dialogContent}>
      <Box display="flex" alignItems="center">
        <Box className={classes.flexCenterBox}>
          <Typography variant="h5" style={{ fontSize: '12px', fontWeight: 600 }}>
            {responsibleText}
          </Typography>
          <CalendarEventDetailPersonCard person={event.eventResponsible} />
        </Box>
        <Box className={classes.lastUsageDatetimeContainer}>
          <Typography variant="h5" className={classes.lastUsageDatetimeText}>
            {lastUsageDatetimeText}
          </Typography>
          <Typography variant="h6" style={{ fontSize: '12px', fontWeight: 400, lineHeight: '12px' }}>
            {formatLastUsageDateTime(event.eventInfo.measure)}
          </Typography>
          {event.eventInfo.isAccumulativeObjective && (
            <>
              <Typography variant="h5" className={classes.accumulativeTotal}>
                {objectiveTexts.accumulativeTotal}
              </Typography>
              <Typography variant="h6" style={{ fontSize: '12px', fontWeight: 400, lineHeight: '12px' }}>
                {objectiveHelper.calculateAccObjective(event.eventInfo.measure)}
              </Typography>
            </>
          )}
          <CalendarEventDetailProcessList processes={event.eventInfo.processes} />
        </Box>
      </Box>

      <Box className={classes.objectiveCardContainer}>
        <ObjectiveGauge
          annualTarget={event.eventInfo.annualTarget}
          id={event.eventInfo.id}
          measures={event.eventInfo.measure}
          operator={event.eventInfo.operator}
          title={event.eventInfo.title}
          isCard
          isAccumulativeObjective
        />
      </Box>
    </DialogContent>
  );
};

CalendarEventDetailObjectiveContent.propTypes = {
  event: PropTypes.shape({
    type: PropTypes.string.isRequired,
    eventInfo: PropTypes.shape({
      processes: PropTypes.arrayOf(PropTypes.any),
      annualTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      measure: PropTypes.shape(),
      operator: PropTypes.string,
      title: PropTypes.string,
      responsibleForCompliance: PropTypes.string,
      isAccumulativeObjective: PropTypes.bool,
    }),
    eventResponsible: PropTypes.shape({}),
  }).isRequired,
};

export default CalendarEventDetailObjectiveContent;
