import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import GroupAdd from '@material-ui/icons/GroupAdd';
import CircleButton from '../../controls/CircleButton';
import Button from '../../controls/Button';
import PageLayout from '../../PageLayout';
import riskActions from '../../../redux/actions/risk.actions';
import RiskMatrix from './RiskMatrix';
import CustomDialogAlert from '../../controls/CustomDialogAlert';
import VideoTutorialModal from '../../controls/VideoTutorialModal';
import { videoTutorialId } from '../../../constants';

function RiskPage() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const risks = useSelector((state) => state.risk.risks);
  const noLinkedProcesses = useSelector((state) => state.risk.noLinkedProcesses);
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const [openNoLinkedProcessesDialog, setOpenNoLinkedProcessesDialog] = useState(false);
  const title = intl.formatMessage({ id: 'risks.riskMatrix', defaultMessage: 'Matriz de riesgos' });
  const addButtonText = intl.formatMessage({ id: 'risks.addRisk', defaultMessage: '+ Agregar riesgo' });
  const breadcrumbsTitle = intl.formatMessage({ id: 'risks.title', defaultMessage: 'Riesgos' });
  const bulkCreationTitle = intl.formatMessage({ id: 'risks.bulkCreationTitle', defaultMessage: 'Carga múltiple de riesgos' });
  const noLinkedProcessesMessage = {
    title: intl.formatMessage({ id: 'bulkCreation.riskWithoutLinkedProcessesTitle', defaultMessage: 'Consejo' }),
    body: intl.formatMessage({ id: 'bulkCreation.riskWithoutLinkedProcessesBody', defaultMessage: 'Si lo desea, a continuación puede editar los Riesgos creados para relacionarlos a uno o más procesos.' }),
  };

  function toggleNoLinkedProcessesDialog() {
    setOpenNoLinkedProcessesDialog((prevValue) => !prevValue);
  }

  useEffect(() => {
    if (noLinkedProcesses) {
      setOpenNoLinkedProcessesDialog(true);
    }
  }, [noLinkedProcesses]);

  useEffect(() => {
    dispatch(riskActions.getAll());
  }, [dispatch]);

  function RiskBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/risks" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{breadcrumbsTitle}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  function ButtonGroup() {
    if (!isAdmin) return null;
    return (
      <>
        <Button
          text={addButtonText}
          component={Link}
          to="risks/new"
          style={{ fontSize: '11px', fontWeight: 600 }}
        />
        <CircleButton
          text={(
            <Tooltip title={bulkCreationTitle}>
              <GroupAdd color="primary" fontSize="small" />
            </Tooltip>
          )}
          component={Link}
          to="risks/upload"
          variant="outlined"
        />
      </>
    );
  }

  return (
    <PageLayout
      title={title}
      breadCrumbs={<RiskBreadcrumbs />}
      buttonGroup={<ButtonGroup />}
      iconVideoTutorial={(
        <VideoTutorialModal
          title={<FormattedMessage id="videoTutorial.riskUploadTitle" defaultMessage="Carga de riesgos" />}
          body={<FormattedMessage id="videoTutorial.riskUploadBody" defaultMessage="De esta forma puedes generar y administrar los riesgos por cada proceso" />}
          videoId={videoTutorialId.RISK_UPLOAD}
        />
      )}
    >
      <RiskMatrix risks={risks} />
      <CustomDialogAlert
        toggleDialog={toggleNoLinkedProcessesDialog}
        openDialog={openNoLinkedProcessesDialog}
        message={noLinkedProcessesMessage}
      />
    </PageLayout>
  );
}

export default RiskPage;
