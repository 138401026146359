import React from 'react';
import { SvgIcon } from '@material-ui/core';

function EditOutlined(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 39 40">
      <path d="M22.8455 15.3294L24.3405 16.8244L9.61805 31.5469H8.12305V30.0519L22.8455 15.3294ZM28.6955 5.54688C28.2893 5.54688 27.8668 5.70937 27.558 6.01812L24.5843 8.99187L30.678 15.0856L33.6518 12.1119C34.2855 11.4781 34.2855 10.4544 33.6518 9.82063L29.8493 6.01812C29.5243 5.69312 29.118 5.54688 28.6955 5.54688ZM22.8455 10.7306L4.87305 28.7031V34.7969H10.9668L28.9393 16.8244L22.8455 10.7306Z" fill="#F2994A" />
    </SvgIcon>
  );
}

export default EditOutlined;
