import * as joint from 'jointjs';

export const ProcessLink = joint.shapes.standard.Link.define('custom.ProcessLink', {
  attrs: {
    line: {
      connection: true,
      stroke: '#757575',
      strokeWidth: 1.5,
      strokeLinejoin: 'round',
      targetMarker: {
        type: 'path',
        d: 'M 10 -5 0 0 10 5 z',
      },
    },
    wrapper: {
      connection: true,
      strokeWidth: 10,
      strokeLinejoin: 'round',
    },
  },
}, {
  markup: [{
    tagName: 'path',
    selector: 'wrapper',
    attributes: {
      fill: 'none',
      cursor: 'pointer',
      stroke: 'transparent',
    },
  }, {
    tagName: 'path',
    selector: 'line',
    attributes: {
      fill: 'none',
      'pointer-events': 'none',
    },
  }],
});

export const ProcessBox = joint.shapes.basic.Rect.define('custom.ProcessBox', {
  attrs: {
    root: {
      magnet: false,
    },
    body: {
      strokeWidth: 0.7,
      stroke: '#757575',
      fill: '#F8F8F8',
      rx: 5,
      ry: 5,
      ref: 'label',
      cursor: 'default',
      visibility: 'visible',
      refX: '-12.5%',
      refY: '-50%',
      refWidth: '125%',
      refHeight: '200%',
    },
    label: {
      textVerticalAnchor: 'middle',
      textAnchor: 'middle',
      fontSize: 14,
      fill: 'black',
      style: '-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;',
      pointerEvents: 'none',
      visibility: 'visible',
    },
    tool: {
      event: 'processBox:tool:pointerdown',
      r: 6,
      fill: '#F8F8F8',
      strokeWidth: 0,
      stroke: '#F16C24',
      magnet: false,
    },
    process: {
      id: '',
      name: '',
      area: '',
    },
  },
}, {
  markup: [{
    tagName: 'rect',
    selector: 'body',
  }, {
    tagName: 'circle',
    selector: 'tool',
  }, {
    tagName: 'text',
    selector: 'label',
  }],
});

export const ProcessArea = joint.shapes.devs.Coupled.define('custom.ProcessArea', {
  attrs: {
    rect: {
      stroke: '#757575',
      fill: '#F8F8F8',
      strokeWidth: 0.2,
    },
    '.label': {
      'font-size': '18px',
      'font-weight': 'bold',
      style: '-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;',
    },
  },
  position: {
    x: 550,
    y: 50,
  },
  size: {
    width: 300,
    height: 300,
  },
});

export function createPaper(canvas, graph, style) {
  return new joint.dia.Paper({
    ...style,
    el: canvas,
    model: graph,
    cellViewNamespace: joint.shapes,
    gridSize: 5,
    snapLinks: true,
    linkPinning: true,
    embeddingMode: true,
    interactive: false,
    defaultLink() {
      return new ProcessLink();
    },
    connectionStrategy(end) {
      return { id: end.id };
    },
    highlighting: {
      default: {
        name: 'stroke',
        options: {
          padding: 3,
          rx: 5,
          ry: 5,
          attrs: {
            'stroke-width': 2,
            stroke: '#F16C24',
          },
        },
      },
      embedding: {
        name: 'addClass',
        options: {
          className: 'highlighted-parent',
        },
      },
    },
    validateEmbedding(childView, parentView) {
      return parentView.model instanceof joint.shapes.devs.Coupled;
    },

    validateConnection(sourceView, sourceMagnet, targetView) {
      return sourceView !== targetView;
    },
  });
}
