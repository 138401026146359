import React, { useState } from 'react';
import {
  Box, Dialog, DialogContent, DialogTitle, IconButton, makeStyles, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import ButtonLoad from '../../controls/Button';
import { stripeService } from '../../../services';
import { alertActions, companyActions, modalAlertActions } from '../../../redux/actions';
import { userCompanySelectorHelper } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: '208px',
    minWidth: '405px',
  },
  textDialog: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    maxWidth: '374px',
  },
  textTitleDialog: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '18px',
    textTransform: 'uppercase',
  },
  closeButton: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

const UnsubscribeModal = ({ openDialogCancel, toggleDialogCancel }) => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const cancelText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const acceptText = intl.formatMessage({ id: 'app.accept', defaultMessage: 'Aceptar' });
  const titleCancelText = intl.formatMessage({ id: 'stripe.unsubscribeTitle', defaultMessage: 'Cancelar suscripción' });
  const bodyCancelText = intl.formatMessage({ id: 'stripe.unsubscribeBody', defaultMessage: '¿Está seguro que quiere dar de baja su suscripción?' });
  const bodyInfoCancelText = intl.formatMessage({ id: 'stripe.unsubscribeBodyInfo', defaultMessage: 'Al dar de baja su suscripción pasará al Plan Free, su información no será borrada pero las funcionalidades quedaran limitadas al espacio disponible restante.' });
  const titleStripe = intl.formatMessage({ id: 'stripe.cancelSuccessfulPlanTitle', defaultMessage: 'Cancelación exitosa' });
  const messageStripe = intl.formatMessage({ id: 'stripe.cancelSuccessfulPlanBody', defaultMessage: 'Su plan ha sido dado de baja con éxito. Recuerde que puede volver a suscribirse en cuanto lo desee. Su información no será borrada.' });
  const buttonStripe = intl.formatMessage({ id: 'stripe.seePlan', defaultMessage: 'Ver Planes' });

  const handleUnsubscribe = async () => {
    setLoading(true);
    await stripeService.unsubscribe()
      .then(() => {
        const currentCompany = userCompanySelectorHelper.getCurrentCompany();
        dispatch(companyActions.get(currentCompany.id));
        dispatch(modalAlertActions.infoAndGoToHome(titleStripe, messageStripe, buttonStripe));
      })
      .catch((err) => {
        dispatch(alertActions.error(err));
      })
      .finally(() => { setLoading(false); });
  };

  return ((
    <>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        open={openDialogCancel}
        onClose={toggleDialogCancel}
        aria-labelledby="alert-dialog-cancel-title"
        aria-describedby="alert-dialog-cancel-description"
      >
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton aria-label="close" className={classes.closeButton} onClick={toggleDialogCancel}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogTitle disableTypography style={{ paddingTop: 0, paddingBottom: 8 }}>
          <Box pb={2} display="flex" justifyContent="center">
            <WarningIcon color="primary" fontSize="large" />
          </Box>
          <Box display="flex" justifyContent="center">
            <Typography align="center" className={classes.textTitleDialog} variant="body1">
              {titleCancelText}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography align="center" display="block" className={classes.textDialog} variant="body1">
            {bodyInfoCancelText}
          </Typography>
          <Typography align="center" display="block" className={classes.textDialog} variant="body1">
            {bodyCancelText}
          </Typography>
        </DialogContent>
        <Box pt={1} pb={3} display="flex" justifyContent="center">
          <ButtonLoad onClick={toggleDialogCancel} variant="outlined" text={cancelText} />
          <ButtonLoad onClick={handleUnsubscribe} text={acceptText} loading={loading} />
        </Box>
      </Dialog>

    </>
  ));
};

UnsubscribeModal.propTypes = {
  toggleDialogCancel: PropTypes.func.isRequired,
  openDialogCancel: PropTypes.bool.isRequired,
};

export default UnsubscribeModal;
