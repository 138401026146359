import axios from '../axios/axios';
import { authHeader, userCompanySelectorHelper } from '../helpers';
import handler from './handler';

function create(affiliate) {
  const headers = authHeader();
  const postUrl = 'affiliates';
  return axios.post(postUrl, affiliate, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAll() {
  const headers = authHeader();
  const getUrl = 'affiliates';
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function get(id) {
  const headers = authHeader();
  const getUrl = `affiliates/${id}`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function resetUserPassword(userId) {
  const headers = authHeader();
  return axios.post(`affiliates/${userId}/reset-password`, null, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getPrices() {
  const headers = authHeader();
  const company = userCompanySelectorHelper.getCurrentCompany();
  const getUrl = `companies/${company.id}/subscription/prices`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}
function setAffiliate(affiliateToken, companyId) {
  const headers = authHeader();
  const postUrl = `companies/${companyId}/affiliate`;
  return axios.post(postUrl, { affiliateToken }, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAffiliateData(token) {
  const headers = authHeader();
  const getUrl = `affiliates/coupon/${token}`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const affiliateService = {
  setAffiliate,
  create,
  get,
  getAll,
  getAffiliateData,
  getPrices,
  resetUserPassword,
};

export default affiliateService;
