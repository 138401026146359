import { exampleCompanyConstants } from '../../constants';

function exampleCompany(state = { }, action) {
  switch (action.type) {
    case exampleCompanyConstants.CREATE_REQUEST:
      return { data: state.data, creating: true };
    case exampleCompanyConstants.CREATE_SUCCESS:
      return { data: action.created };
    case exampleCompanyConstants.CREATE_FAILURE:
      return { error: action.error };

    case exampleCompanyConstants.GET_REQUEST:
      return { data: state.data, getting: true };
    case exampleCompanyConstants.GET_SUCCESS:
      return { data: action.exampleCompany };
    case exampleCompanyConstants.GET_FAILURE:
      return { error: action.error };

    case exampleCompanyConstants.GET_ALL_REQUEST:
      return { data: state.data, getting: true };
    case exampleCompanyConstants.GET_ALL_SUCCESS:
      return { data: action.exampleCompany };
    case exampleCompanyConstants.GET_ALL_FAILURE:
      return { error: action.error };

    case exampleCompanyConstants.UPDATE_REQUEST:
      return { data: state.data, getting: true };
    case exampleCompanyConstants.UPDATE_SUCCESS:
      return { data: action.updated };
    case exampleCompanyConstants.UPDATE_FAILURE:
      return { error: action.error };

    case exampleCompanyConstants.DELETE_REQUEST:
      return { data: state.data, deleting: true };
    case exampleCompanyConstants.DELETE_SUCCESS:
      return { };
    case exampleCompanyConstants.DELETE_FAILURE:
      return {};

    case exampleCompanyConstants.CONFIRM_REQUEST:
      return { data: state.data, requesting: true };
    case exampleCompanyConstants.CONFIRM_SUCCESS:
      return { };
    case exampleCompanyConstants.CONFIRM_FAILURE:
      return { error: action.error };

    default:
      return state;
  }
}

export default exampleCompany;
