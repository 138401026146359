import React, { useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import PropTypes from 'prop-types';
import {
  Box, Container, Dialog, DialogContent, Grid, IconButton, makeStyles, Typography,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import CreateIcon from '@material-ui/icons/Create';
import TextField from '@material-ui/core/TextField';
import DragHandleIcon from '../../../assets/icons/DragHandleIcon.png';
import sortableHelper from '../../../helpers/sortableHelper';
import { CloseIcon } from '../../../assets/icons';
import Button from '../../controls/Button';
import { validationConstants } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  item: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  closeIcon: {
    width: 14,
    height: 13,
    cursor: 'pointer',
  },
}));

function SortableList(props) {
  const {
    type, values, listItems, setValues, addItemText,
  } = props;
  const [list, setList] = useState(listItems || []);
  const intl = useIntl();
  const classes = useStyles();
  const noItemsText = intl.formatMessage({ id: 'dataTable.noMatch', defaultMessage: 'No hay datos para mostrar' });
  const addWeaknesses = intl.formatMessage({ id: 'foda.addWeaknesses', defaultMessage: '+ Agregar debilidad' });
  const addThreats = intl.formatMessage({ id: 'foda.addThreats', defaultMessage: '+ Agregar amenaza' });
  const addFortress = intl.formatMessage({ id: 'foda.addFortress', defaultMessage: '+ Agregar fortaleza' });
  const addOpportunities = intl.formatMessage({ id: 'foda.addOpportunities', defaultMessage: '+ Agregar oportunidad' });
  const descriptionWeaknesses = intl.formatMessage({ id: 'foda.descriptionWeaknesses', defaultMessage: 'Describir debilidad' });
  const descriptionThreats = intl.formatMessage({ id: 'foda.descriptionThreats', defaultMessage: 'Describir amenaza' });
  const descriptionFortress = intl.formatMessage({ id: 'foda.descriptionFortress', defaultMessage: 'Describir fortaleza' });
  const descriptionOpportunities = intl.formatMessage({ id: 'foda.descriptionOpportunities', defaultMessage: 'Describir oportunidad' });
  const [dialogTitle, setDialogTitle] = useState('');
  const [descriptionField, setDescriptionField] = useState('');
  const [descriptionLabel, setDescriptionLabel] = useState('');
  const [openAddTextDialog, setOpenAddTextDialog] = useState(false);
  const [descriptionOrder, setDescriptionOrder] = useState(null);
  const [typeField, setTypeField] = useState('');
  const [submitted, setSubmitted] = React.useState(false);

  const types = {
    weaknessesType: 2,
    threatsType: 3,
    fortressType: 0,
    opportunitiesType: 1,
  };

  function arraySetOrder(array) {
    array.map((value, index) => {
      const newValue = value;
      newValue.order = index + 1;

      return newValue;
    });

    return array;
  }

  function setListAndValues(listUpdated) {
    const nameList = sortableHelper.getNameListByType(type);
    setList(listUpdated);
    setValues({ ...values, [nameList]: listUpdated });
  }

  function handleChange(e) {
    const { oldIndex, newIndex } = e;
    let listUpdated = arrayMove(list, oldIndex, newIndex);
    listUpdated = arraySetOrder(listUpdated);
    setListAndValues(listUpdated);
  }

  function deleteItem(e, order) {
    e.preventDefault();
    const listUpdated = list;
    const removeIndex = list.findIndex((item) => item.order === order);
    listUpdated.splice(removeIndex, 1);
    setListAndValues(listUpdated);
  }

  function setPropertiesForDialog(event, description = null) {
    // eslint-disable-next-line default-case
    switch (type) {
      case types.fortressType:
        setDialogTitle(addFortress);
        setTypeField(types.fortressType);
        setDescriptionLabel(descriptionFortress); break;
      case types.opportunitiesType:
        setDialogTitle(addOpportunities);
        setTypeField(types.opportunitiesType);
        setDescriptionLabel(descriptionOpportunities); break;
      case types.weaknessesType:
        setDialogTitle(addWeaknesses);
        setTypeField(types.weaknessesType);
        setDescriptionLabel(descriptionWeaknesses); break;
      case types.threatsType:
        setDialogTitle(addThreats);
        setTypeField(types.threatsType);
        setDescriptionLabel(descriptionThreats); break;
    }
    if (description) setDescriptionField(description);

    setOpenAddTextDialog(true);
  }

  function editItem(e, description, order) {
    e.preventDefault();
    setDescriptionOrder(order);
    setPropertiesForDialog(e, description);
  }

  function handleChangeDialog(e) {
    const { value } = e.target;
    setDescriptionField(value);
  }

  const SortableItem = SortableElement(({ value }) => (
    <li key={value.order} style={{ fontSize: 11, listStyle: 'none', cursor: 'move' }}>
      <Grid container xs={12} className={classes.item}>
        <Grid item xs={10}>
          <Box display="flex" alignItems="flex-start" justifyContent="flex-start">
            <IconButton
              onClick={() => {}}
              style={{
                cursor: 'move',
                paddingLeft: 0,
                paddingTop: 18,
              }}
            >
              <img style={{ color: '#727272' }} src={DragHandleIcon} alt="drag" />
            </IconButton>
            <Typography
              variant="caption"
              color="textPrimary"
              style={{
                fontWeight: 400,
                fontSize: 11,
                margin: 10,
              }}
            >
              {value.description}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
            <IconButton onClick={(e) => { editItem(e, value.description, value.order); }}>
              <CreateIcon style={{ width: 16, height: 16, color: '#F79A56' }} />
            </IconButton>
            <IconButton onClick={(e) => { deleteItem(e, value.order); }}>
              <DeleteRoundedIcon style={{ width: 16, height: 16, color: '#727272' }} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </li>
  ));

  const SortableRow = SortableContainer(({ items }) => (
    <ul style={{ listStyle: 'none', paddingLeft: 0, margin: 0 }}>
      {items.map((item, index) => (
        <SortableItem key={item.id} index={index} value={item} />
      ))}
    </ul>
  ));

  function shouldCancelStart(e) {
    return (['input', 'textarea', 'select', 'option', 'svg', 'path', 'button'].indexOf(e.target.tagName.toLowerCase()) !== -1);
  }

  function handleClose() {
    setSubmitted(false);
    setOpenAddTextDialog(!openAddTextDialog);
  }

  function getOrderItem() {
    if (list.length > 0) return list[list.length - 1].order + 1;
    return 1;
  }
  function addOrEditItem() {
    const order = getOrderItem();
    handleClose();
    if (descriptionOrder != null) {
      list.forEach((element, index) => {
        if (element.order === descriptionOrder) list[index].description = descriptionField;
      });
    } else {
      list.push({
        id: 0, order, type: typeField, description: descriptionField,
      });
    }
    setDescriptionField('');
    setSubmitted(false);
    setDescriptionOrder(null);
  }
  function handleSubmitDescription(e) {
    e.preventDefault();
    setSubmitted(true);
    if (descriptionField) addOrEditItem();
  }

  return (
    <Grid container spacing={1}>
      { list.length > 0
        ? (
          <>
            <Grid item xs={12} sm={4} className={classes.item}>
              <Box display="flex" alignItems="left" justifyContent="left" style={{ fontWeight: 700, fontSize: 12 }}>
                {sortableHelper.getNameByType(type)}
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} className={classes.item} />
            <Grid item xs={12} sm={12}>
              <SortableRow
                items={list}
                onSortEnd={handleChange}
                shouldCancelStart={shouldCancelStart}
              />
            </Grid>
          </>
        )
        : (
          <>
            <Grid item xs={12} sm={12} className={classes.item}>
              <Box display="flex" alignItems="left" justifyContent="left" style={{ fontWeight: 700, fontSize: 12 }}>
                {sortableHelper.getNameByType(type)}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.item}>
              <Box display="flex" alignItems="left" justifyContent="left" style={{ padding: 10, paddingLeft: 0 }}>
                <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>
                  {noItemsText}
                </Typography>
              </Box>
            </Grid>
          </>
        ) }
      <Box
        display="flex"
        alignItems="left"
        justifyContent="left"
        style={{ padding: 10, paddingLeft: 0 }}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Box
          style={{ textDecoration: 'none', cursor: 'pointer' }}
          onClick={(e) => { setPropertiesForDialog(e); }}
        >
          <Typography
            variant="subtitle1"
            display="inline"
            style={{
              fontSize: 10,
              fontWeight: 600,
              color: '#F16C24',
              textTransform: 'uppercase',
            }}
          >
            +
            {' '}
            {addItemText}
          </Typography>
        </Box>
      </Box>

      <Dialog
        open={openAddTextDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        max-width="xs"
        fullWidth
      >
        <DialogContent>
          <Box display="flex" justifyContent="flex-end">
            <CloseIcon className={classes.closeIcon} onClick={handleClose} />
          </Box>
          <Typography align="center" className={classes.title}>
            {dialogTitle}
          </Typography>
          <form className={classes.root} noValidate autoComplete="off">
            <Box p={2} align="center">
              <Container>

                <TextField
                  value={descriptionField}
                  onChange={handleChangeDialog}
                  fullWidth
                  margin="normal"
                  id="descriptionField"
                  label={descriptionLabel}
                  name="descriptionField"
                  autoFocus
                  size="small"
                  error={!descriptionField && submitted}
                  helperText={
                    !descriptionField && submitted && validationConstants.DESCRIPTION_REQUIRED
                  }
                  /* eslint-disable-next-line no-unused-expressions */
                  onKeyPress={(event) => { event.key === 'Enter' && handleSubmitDescription(event); }}
                />

              </Container>
            </Box>
          </form>
          <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
            <Button
              onClick={handleClose}
              variant="outlined"
              text={<FormattedMessage id="app.cancel" defaultMessage="Cancelar" />}
            />
            <Button
              onClick={handleSubmitDescription}
              text={<FormattedMessage id="app.save" defaultMessage="Guardar" />}
            />
          </Box>
        </DialogContent>
      </Dialog>

    </Grid>
  );
}

SortableList.propTypes = {
  type: PropTypes.number,
  values: PropTypes.arrayOf(PropTypes.object),
  setValues: PropTypes.func,
  listItems: PropTypes.arrayOf(PropTypes.object),
  addItemText: PropTypes.string,
};

SortableList.defaultProps = {
  type: 0,
  values: [],
  setValues: null,
  listItems: [],
  addItemText: '',
};

export default SortableList;
