import { workFieldConstants } from '../../constants';

function workField(state = {}, action) {
  switch (action.type) {
    case workFieldConstants.CREATE_REQUEST:
      return { creating: true };
    case workFieldConstants.CREATE_SUCCESS:
      return { data: action.createdWorkField, created: true };
    case workFieldConstants.CREATE_FAILURE:
      return { creating: false };

    case workFieldConstants.GET_REQUEST:
      return { requesting: true };
    case workFieldConstants.GET_SUCCESS:
      return { data: action.workField };
    case workFieldConstants.GET_FAILURE:
      return {};

    case workFieldConstants.GET_ALL_REQUEST:
      return { requesting: true };
    case workFieldConstants.GET_ALL_SUCCESS:
      return { data: action.workField };
    case workFieldConstants.GET_ALL_FAILURE:
      return {};

    case workFieldConstants.UPDATE_REQUEST:
      return { data: state.data, updating: true };
    case workFieldConstants.UPDATE_SUCCESS:
      return { data: action.updatedWorkField, updated: true };
    case workFieldConstants.UPDATE_FAILURE:
      return {};

    case workFieldConstants.DELETE_REQUEST:
      return { data: state.data, deleting: true };
    case workFieldConstants.DELETE_SUCCESS:
      return { deleted: true };
    case workFieldConstants.DELETE_FAILURE:
      return {};

    default:
      return state;
  }
}

export default workField;
