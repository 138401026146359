import React from 'react';
import { FormattedMessage } from 'react-intl';

const validationConstants = {
  EMAIL_REQUIRED: <FormattedMessage id="validation.emailRequired" defaultMessage="Ingrese su dirección de correo electrónico" />,
  EMAIL_INVALID: <FormattedMessage id="validation.emailInvalid" defaultMessage="Ingrese una dirección de correo electrónico válida" />,

  TYC_INVALID: <FormattedMessage id="validation.termsAndConditionsRequired" defaultMessage="No ha aceptado los términos y condiciones" />,
  PP_INVALID: <FormattedMessage id="validation.privacyPolicyRequired" defaultMessage="No ha aceptado la política de privacidad" />,

  PASSWORD_REQUIRED: <FormattedMessage id="validation.passwordRequired" defaultMessage="Ingrese su contraseña" />,
  PASSWORD_CONFIRM_REQUIRED: <FormattedMessage id="validation.passwordConfirmRequired" defaultMessage="Repita su contraseña" />,
  PASSWORD_CONFIRM_MATCH: <FormattedMessage id="validation.passwordConfirmMatch" defaultMessage="Las contraseñas deben coincidir" />,
  PASSWORD_MATCH_PATTERN: <FormattedMessage id="validation.passwordMatchPattern" defaultMessage="La contraseña debe cumplir con los requisitos" />,

  PASSWORD_REGEX: 'matchRegexp:^(?=.*\\d)(?=.*[^\\w\\s)]|[_])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$',

  FIRST_NAME_REQUIRED: <FormattedMessage id="validation.firstNameRequired" defaultMessage="Ingrese su nombre" />,
  LAST_NAME_REQUIRED: <FormattedMessage id="validation.lastNameRequired" defaultMessage="Ingrese su apellido" />,

  COMPANY_REQUIRED: <FormattedMessage id="validation.companyRequired" defaultMessage="Ingrese el nombre de su empresa" />,

  PROCESS_NAME_REQUIRED: <FormattedMessage id="validation.processNameRequired" defaultMessage="Ingrese el nombre del proceso" />,
  PROCESS_AREA_NAME_REQUIRED: <FormattedMessage id="validation.processAreaNameRequired" defaultMessage="Ingrese el nombre del area" />,

  DOCUMENT_TITLE_REQUIRED: <FormattedMessage id="validation.documentTitleRequired" defaultMessage="Ingrese el título del documento" />,
  DOCUMENT_REVIEWER_REQUIRED: <FormattedMessage id="validation.documentReviewerRequired" defaultMessage="Seleccione una persona" />,
  DOCUMENT_REQUIRED: <FormattedMessage id="validation.documentRequired" defaultMessage="Ingrese un documento o contenido en el editor" />,

  PERSON_FIRST_NAME_REQUIRED: <FormattedMessage id="validation.personFirstNameRequired" defaultMessage="Ingrese el nombre de la persona" />,
  PERSON_LAST_NAME_REQUIRED: <FormattedMessage id="validation.personLastNameRequired" defaultMessage="Ingrese el apellido de la persona" />,
  PERSON_PERSONNEL_FILE_REQUIRED: <FormattedMessage id="validation.personPersonnelFileRequired" defaultMessage="Ingrese el numero de legajo de la persona" />,
  PERSON_POSITION_REQUIRED: <FormattedMessage id="validation.personPositionRequired" defaultMessage="Ingrese el puesto de la persona" />,
  PERSON_PHONE_REQUIRED: <FormattedMessage id="validation.personPhoneRequired" defaultMessage="Ingrese el número de contacto de la persona" />,
  PERSON_PHONE_INVALID: <FormattedMessage id="validation.personPhoneInvalid" defaultMessage="El número de teléfono es inválido" />,

  OUTLINE_NAME_REQUIRED: <FormattedMessage id="validation.outlineNameRequired" defaultMessage="Ingrese el nombre del perfil" />,
  OUTLINE_REQUIRED_KNOWLEDGES_REQUIRED: <FormattedMessage id="validation.outlineRequiredKnowledgesRequired" defaultMessage="Ingrese los conocimientos requeridos para el perfil" />,

  PERSON_TITLE_REQUIRED: <FormattedMessage id="validation.trainingTitleRequired" defaultMessage="Ingrese el título" />,
  PERSON_TOTAL_TIME_REQUIRED: <FormattedMessage id="validation.trainingTotalTimeRequired" defaultMessage="Ingrese la duración total en horas" />,
  TRAINING_IN_CHARGE_PERSON_NAME_REQUIRED: <FormattedMessage id="validation.trainingInChargePersonNameRequiredRequired" defaultMessage="Ingrese la persona a cargo de la capacitación" />,
  TRAINING_TRAINER_TYPE_REQUIRED: <FormattedMessage id="validation.trainerTypeRequired" defaultMessage="Ingrese el tipo de capacitador" />,

  DESCRIPTION_REQUIRED: <FormattedMessage id="validation.description" defaultMessage="Ingrese la descripción" />,

  RISK_TITLE_REQUIRED: <FormattedMessage id="validation.riskTitleRequired" defaultMessage="Ingrese el título del riesgo" />,
  RISK_PROBABILITY_REQUIRED: <FormattedMessage id="validation.riskProbabilityRequired" defaultMessage="Ingrese probabilidad del riesgo" />,
  RISK_IMPACT_REQUIRED: <FormattedMessage id="validation.riskImpactRequired" defaultMessage="Ingrese impacto del riesgo" />,
  RISK_CONTEXT_REQUIRED: <FormattedMessage id="validation.riskContextRequired" defaultMessage="Ingrese contexto del riesgo" />,
  RISK_TYPE_REQUIRED: <FormattedMessage id="validation.riskTypeRequired" defaultMessage="Ingrese tipo del riesgo" />,
  RISK_RESPONSIBLE_REQUIRED: <FormattedMessage id="validation.riskResponsibleRequired" defaultMessage="Ingrese un responsable de cumplimiento" />,
  RISK_ACTIONS_REQUIRED: <FormattedMessage id="validation.riskActionsRequired" defaultMessage="Ingrese acciones del riesgo" />,
  ACTIONS_REQUIRED: <FormattedMessage id="validation.actions" defaultMessage="Ingrese las acciones" />,
  RISK_PROCESS_REQUIRED: <FormattedMessage id="validation.riskProcessRequired" defaultMessage="Seleccione al menos un proceso" />,
  RISK_SWOT_ITEM_REQUIRED: <FormattedMessage id="validation.riskSwotItemRequired" defaultMessage="Seleccione o ingrese el item de FODA asociado" />,

  SUPERADMIN_NEW_COMPANY: <FormattedMessage id="validation.superAdminNewCompany" defaultMessage="Ingrese el nombre de la empresa" />,
  IS_REQUIRED: <FormattedMessage id="validation.isRequired" defaultMessage="Este campo es requerido para continuar" />,
  PROVIDER_NAME: <FormattedMessage id="validation.providerName" defaultMessage="Ingrese el nombre del proveedor" />,
  PROVIDER_WORK_FIELD: <FormattedMessage id="validation.providerWorkField" defaultMessage="Seleccione al menos un rubro" />,
  PROVIDER_OFFERED: <FormattedMessage id="validation.providerProductsOrServicesOffered" defaultMessage="Ingrese el producto o servicio ofrecido por el proveedor" />,

  WORK_FIELD_NAME_REQUIRED: <FormattedMessage id="validation.workFieldNameRequired" defaultMessage="Ingrese el nombre del perfil" />,
  WORK_FIELD_ITEMS_REQUIRED: <FormattedMessage id="validation.workFieldItemsRequired" defaultMessage="Ingrese los items a evaluar por rubro" />,

};

export default validationConstants;
