import React from 'react';
import { IconButton, Tooltip, makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { DownloadIcon } from '../../assets/icons';

import { documentHelpers } from '../../helpers';

const useStyles = makeStyles(() => ({
  downloadIcon: {
    color: '#373435',
    height: 16,
    width: 16,
  },

}));

const DownloadDocument = ({ document, actualVersion, iconClassName }) => {
  const classes = useStyles();
  const intl = useIntl();
  const downloadLabel = intl.formatMessage({ id: 'app.download', defaultMessage: 'Descargar' });

  return (
    <Tooltip title={downloadLabel}>
      <IconButton
        style={{ textDecoration: 'none', color: '#373435', padding: '0px' }}
        onClick={() => documentHelpers.handleDownloadDocument(document, actualVersion)}
      >
        <DownloadIcon className={iconClassName || classes.downloadIcon} />
      </IconButton>
    </Tooltip>
  );
};

DownloadDocument.propTypes = {
  document: PropTypes.shape({}).isRequired,
  actualVersion: PropTypes.shape({}).isRequired,
  iconClassName: PropTypes.string,
};

DownloadDocument.defaultProps = {
  iconClassName: '',
};

export default DownloadDocument;
