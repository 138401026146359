import { riskConstants } from '../../constants';
import { riskService } from '../../services';
import { history } from '../../helpers';
import alertActions from './alert.actions';

function create(risk, redirectUrl = null) {
  function request(riskToCreate) {
    return {
      type: riskConstants.CREATE_REQUEST, riskToCreate,
    };
  }
  function success(createdRisk) {
    return {
      type: riskConstants.CREATE_SUCCESS, createdRisk,
    };
  }
  function failure(error) { return { type: riskConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(risk));

    riskService.create(risk)
      .then(
        (createdRisk) => {
          dispatch(success(createdRisk));
          if (redirectUrl) { history.replace(`${redirectUrl}${createdRisk.id}`); }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function get(id) {
  function request() { return { type: riskConstants.GET_REQUEST }; }
  function success(risk) {
    return { type: riskConstants.GET_SUCCESS, risk };
  }
  function failure(error) { return { type: riskConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    riskService.get(id)
      .then(
        (risk) => {
          dispatch(success(risk));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
          history.push('/risks');
        },
      );
  };
}

function getAll() {
  function request() { return { type: riskConstants.GET_ALL_REQUEST }; }
  function success(risks) {
    return { type: riskConstants.GET_ALL_SUCCESS, risks };
  }
  function failure(error) { return { type: riskConstants.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    riskService.getAll()
      .then(
        (risks) => {
          dispatch(success(risks));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function update(risk, redirectUrl = null) {
  function request(riskToUpdate) {
    return {
      type: riskConstants.UPDATE_REQUEST, riskToUpdate,
    };
  }
  function success(updatedRisk) {
    return {
      type: riskConstants.UPDATE_SUCCESS, updatedRisk,
    };
  }
  function failure(error) { return { type: riskConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(risk));

    riskService.update(risk)
      .then(
        (updatedRisk) => {
          dispatch(success(updatedRisk));
          if (redirectUrl) { history.replace(`${redirectUrl}${updatedRisk.id}`); }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

const riskEvaluationUpdate = (risk, evaluation, redirectUrl = null,) => {
  const request = (riskToUpdate) => {
    return {
      riskToUpdate,
      type: riskConstants.UPDATE_REQUEST,
    };
  }

  const success = (updatedRisk) => {
    return {
      type: riskConstants.UPDATE_SUCCESS,
      updatedRisk,
    };
  }

  const failure = (error) => {
    return {
      error,
      type: riskConstants.UPDATE_FAILURE,
    };
  }

  return (dispatch) => {
    dispatch(request(risk));

    riskService.riskEvaluationUpdate(evaluation)
      .then((updatedEvaluation) => {
        if (updatedEvaluation) {
          dispatch(success(risk));
          if (redirectUrl) {
            history.replace(`${redirectUrl}${risk.id}`);
          }
        }
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      });
  };
}

function updateNonVersionableData(risk, redirectUrl = null) {
  function request(riskToUpdate) { return { type: riskConstants.UPDATE_REQUEST, riskToUpdate }; }
  function success(updatedRisk) { return { type: riskConstants.UPDATE_SUCCESS, updatedRisk }; }
  function failure(error) { return { type: riskConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(risk));
    riskService.updateNonVersionableData(risk)
      .then(
        (updatedRisk) => {
          dispatch(success(updatedRisk));
          if (redirectUrl) { history.push(`${redirectUrl}${updatedRisk.id}`); }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function deleteRisk(riskId) {
  function request() { return { type: riskConstants.DELETE_REQUEST }; }
  function success() { return { type: riskConstants.DELETE_SUCCESS }; }
  function failure() { return { type: riskConstants.DELETE_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    riskService.deleteRisk(riskId)
      .then(
        () => {
          dispatch(success());
          history.push('/risks');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function createBulkRisks(risksExcelFile) {
  function request() { return { type: riskConstants.CREATE_BULK_REQUEST }; }
  function success(bulkCreationPreviewResult) {
    return {
      type: riskConstants.CREATE_BULK_SUCCESS,
      bulkCreationPreviewResult,
    };
  }
  function failure(error) {
    return { type: riskConstants.CREATE_BULK_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    riskService.createBulkRisk(risksExcelFile)
      .then(
        (bulkCreationPreviewResult) => {
          dispatch(success(bulkCreationPreviewResult));
          history.push('/risks/result');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function confirmCreateBulkRisks(id, messageSuccess) {
  function request() { return { type: riskConstants.CONFIRM_CREATE_BULK_REQUEST }; }
  function success() { return { type: riskConstants.CONFIRM_CREATE_BULK_SUCCESS }; }
  function failure(error) {
    return { type: riskConstants.CONFIRM_CREATE_BULK_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    riskService.confirmCreateBulkRisk(id)
      .then(
        () => {
          dispatch(success());
          dispatch(alertActions.success(messageSuccess));
          history.replace('/risks');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

const riskActions = {
  confirmCreateBulkRisks,
  create,
  createBulkRisks,
  deleteRisk,
  get,
  getAll,
  riskEvaluationUpdate,
  update,
  updateNonVersionableData,
};

export default riskActions;
