import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Breadcrumbs,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
  Box,
  Divider,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '@material-ui/pickers';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import PageLayout from '../../PageLayout';
import { personActions, riskActions } from '../../../redux/actions';
import validationConstants from '../../../constants/validation.constants';
import Button from '../../controls/Button';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
}));

function RiskEvaluationForm({ routeParams: { id: riskId } }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector((state) => state.authentication.user);
  const risk = useSelector((state) => state.risk.data);
  const people = useSelector((state) => state.person.people);

  const isAdmin = user.roles.includes('Admin');
  const [componentMounted, setComponentMounted] = useState(false);
  const breadcrumbsTitle = intl.formatMessage({ id: 'risks.title', defaultMessage: 'Riesgos' });
  const newEvaluationText = intl.formatMessage({ id: 'risks.newEvaluation', defaultMessage: 'Nueva evaluación' });
  const evaluationDateLabel = intl.formatMessage({ id: 'risks.evaluationDate', defaultMessage: 'Fecha de evaluación' });
  const nextEvaluationDateLabel = intl.formatMessage({ id: 'risks.nextEvaluationDate', defaultMessage: 'Fecha de próxima evaluación' });
  const evaluationText = intl.formatMessage({ id: 'app.evaluation', defaultMessage: 'Evaluación' });
  const riskInChargePersonLabel = intl.formatMessage({ id: 'risks.label.inChargePerson', defaultMessage: 'Responsable de cumplimiento' });
  const previousEvaluationText = intl.formatMessage({ id: 'risks.previousEvaluation', defaultMessage: 'Evaluaciones anteriores' });
  const previousEvaluationActionsText = intl.formatMessage({ id: 'risks.previousEvaluationActions', defaultMessage: 'Acciones evaluación anterior' });
  const lastActionResults = intl.formatMessage({ id: 'risks.lastActionsResults', defaultMessage: 'Resultados de acciones anteriores' });
  const newActionsLabel = intl.formatMessage({ id: 'risks.newActions', defaultMessage: 'Nuevas acciones' });
  const invalidDateLabel = intl.formatMessage({ id: 'app.invalid_date', defaultMessage: 'Fecha incorrecta' });
  const newProbabilityLabel = intl.formatMessage({ id: 'risks.newProbability', defaultMessage: 'Nueva probabilidad' });
  const newImpactLabel = intl.formatMessage({ id: 'risks.newImpact', defaultMessage: 'Nuevo impacto' });
  const resultWillBeCalculatedText = intl.formatMessage({ id: 'risks.resultWillBeCalculated', defaultMessage: 'Se autocalculará el resultado' });
  const backText = intl.formatMessage({ id: 'app.back', defaultMessage: 'volver' });
  const saveText = intl.formatMessage({ id: 'app.save', defaultMessage: 'guardar' });
  const InputProps = { disableUnderline: true };
  const initialFieldValues = {
    evaluationDate: new Date(),
    nextEvaluationDate: new Date(),
    result: '',
    actions: '',
    probability: '',
    impact: '',
    riskResult: null,
    riskLevel: null,
    inChargePersonId: '',
  };

  const [values, setValues] = useState(initialFieldValues);
  const {
    evaluationDate, nextEvaluationDate, result, actions,
    probability, impact, riskResult, riskLevel, inChargePersonId,
  } = values;

  const probabilityImpactValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const riskResultColors = {
    veryHigh: '#BF1919',
    high: '#FB9435',
    mid: '#FDC41C',
    low: '#43A047',
  };

  const riskResultLimits = {
    veryHigh: 7,
    high: 6,
    mid: 4,
  };

  const getRiskResult = (riskProbability, riskImpact) => (riskProbability + riskImpact) / 2;

  const getRiskLevel = (riskResultValue) => {
    if (riskResultValue >= riskResultLimits.veryHigh) { return intl.formatMessage({ id: 'risks.level.veryHigh', defaultMessage: 'Muy alto' }); }
    if (riskResultValue >= riskResultLimits.high) { return intl.formatMessage({ id: 'risks.level.high', defaultMessage: 'Alto' }); }
    if (riskResultValue >= riskResultLimits.mid) { return intl.formatMessage({ id: 'risks.level.mid', defaultMessage: 'Medio' }); }
    return intl.formatMessage({ id: 'risks.level.low', defaultMessage: 'Bajo' });
  };

  useEffect(() => {
    if (!people) { dispatch(personActions.getAll()); }

    if (user && !componentMounted) {
      dispatch(riskActions.get(riskId));
      setComponentMounted(true);
    }
    if (probability && impact) {
      const riskResultValue = getRiskResult(probability, impact);
      setValues({
        ...values,
        riskResult: riskResultValue,
        riskLevel: getRiskLevel(riskResultValue),
      });
    }
    // eslint-disable-next-line
  }, [dispatch, user, risk, componentMounted, probability, impact]);

  function RiskEvaluationLoadingBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/risks" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{breadcrumbsTitle}</Typography>
        </Link>
        <Link color="inherit" to="/risks" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{newEvaluationText}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  function RiskEvaluationBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/risks" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{breadcrumbsTitle}</Typography>
        </Link>
        <Link color="inherit" to={`/risks/${riskId}`} style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{risk && risk.title}</Typography>
        </Link>
        <Link color="inherit" to="/risks" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{newEvaluationText}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  function getRiskColor(riskCoefficient) {
    if (riskCoefficient >= riskResultLimits.veryHigh) { return riskResultColors.veryHigh; }
    if (riskCoefficient >= riskResultLimits.high) { return riskResultColors.high; }
    if (riskCoefficient >= riskResultLimits.mid) { return riskResultColors.mid; }
    return riskResultColors.low;
  }

  function handleSubmit(e) {
    e.preventDefault();
    const riskToUpdate = {
      ...risk,
      processes: risk.processes.map((process) => process.id),
      evaluationDate,
      nextEvaluationDate,
      result,
      actions,
      probability,
      impact,
      inChargePersonId,
    };

    dispatch(riskActions.update(riskToUpdate, '/risks/'));
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  }

  function getLastEvaluation() {
    if (risk && risk.riskEvaluations) return risk.riskEvaluations[risk.riskEvaluations.length - 1];
    return { actions: '', evaluationDate: null };
  }

  function LastActions() {
    const lastEvaluation = getLastEvaluation();
    return lastEvaluation.actions;
  }

  function LastEvaluationDate() {
    const lastEvaluation = getLastEvaluation();
    return moment(lastEvaluation.evaluationDate).format('DD/MM/YYYY');
  }

  function getCoefficientWithColor(riskProbability, riskImpact) {
    const coefficient = getRiskResult(riskProbability, riskImpact);
    const color = getRiskColor(coefficient);
    return (
      <>
        <span style={{ display: 'inline-block', width: '2em' }}>
          {coefficient}
        </span>
        <span style={{ paddingLeft: 10, color }}>
          <strong>{getRiskLevel(coefficient)}</strong>
        </span>
      </>
    );
  }
  return (
    <>
      { risk
        ? (
          <PageLayout
            title={risk.title}
            breadCrumbs={<RiskEvaluationBreadcrumbs />}
          >
            <div className={classes.main}>
              <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Container>
                      <DatePicker
                        value={evaluationDate}
                        onChange={(value) => { setValues({ ...values, evaluationDate: value }); }}
                        fullWidth
                        margin="normal"
                        id="evaluationDate"
                        name="evaluationDate"
                        label={evaluationDateLabel}
                        size="small"
                        format="dd/MM/yyyy"
                      />
                    </Container>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Container>
                      <DatePicker
                        value={nextEvaluationDate}
                        onChange={(value) => {
                          setValues({ ...values, nextEvaluationDate: value });
                        }}
                        fullWidth
                        margin="normal"
                        id="nextEvaluationDate"
                        name="nextEvaluationDate"
                        label={nextEvaluationDateLabel}
                        size="small"
                        format="dd/MM/yyyy"
                        minDateMessage={invalidDateLabel}
                        minDate={new Date(evaluationDate)}
                      />
                    </Container>
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ marginTop: 16 }}>
                    <Container maxWidth={false}>
                      <Box display="flex" alignItems="flex-start" justifyContent="flex-start">
                        <Typography variant="subtitle2" color="textPrimary" style={{ fontSize: 14, fontWeight: 600 }}>{evaluationText}</Typography>
                      </Box>
                      <Box display="flex" alignItems="center" justifyContent="center" pt={1}>
                        <Divider variant="middle" style={{ width: '100%' }} />
                      </Box>
                    </Container>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Container>
                      <Typography variant="caption" color="textPrimary" style={{ color: '#6D6D6D', fontWeight: 400, fontSize: 12 }}>
                        {lastActionResults}
                      </Typography>
                      <TextField
                        InputProps={InputProps}
                        value={result}
                        name="result"
                        id="result"
                        multiline
                        rows={5}
                        onChange={handleChange}
                        fullWidth
                        style={{ background: '#FFFFFF', margin: 2 }}
                      />
                    </Container>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Container>
                      <Typography variant="caption" color="textPrimary" style={{ color: '#6D6D6D', fontWeight: 400, fontSize: 12 }}>
                        {newActionsLabel}
                      </Typography>
                      <TextValidator
                        InputProps={InputProps}
                        value={actions}
                        name="actions"
                        id="actions"
                        multiline
                        rows={5}
                        onChange={handleChange}
                        fullWidth
                        style={{ background: '#FFFFFF', margin: 2 }}
                        validators={['required']}
                        errorMessages={[validationConstants.ACTIONS_REQUIRED]}
                      />
                    </Container>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Container>
                          <>
                            <SelectValidator
                              id="probability"
                              name="probability"
                              value={probability}
                              label={newProbabilityLabel}
                              displayEmpty
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '100%' }}
                              validators={['required']}
                              errorMessages={[validationConstants.RISK_PROBABILITY_REQUIRED]}
                            >
                              <MenuItem disabled value="">
                                <em>{newProbabilityLabel}</em>
                              </MenuItem>
                              {probabilityImpactValues.map((val) => (
                                <MenuItem key={val} value={val}>
                                  {val}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </>
                        </Container>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Container>
                          <>
                            <SelectValidator
                              id="impact"
                              name="impact"
                              value={impact}
                              displayEmpty
                              label={newImpactLabel}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '100%' }}
                              validators={['required']}
                              errorMessages={[validationConstants.RISK_IMPACT_REQUIRED]}
                            >
                              <MenuItem disabled value="">
                                <em>{newImpactLabel}</em>
                              </MenuItem>
                              {probabilityImpactValues.map((val) => (
                                <MenuItem key={val} value={val}>
                                  {val}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </>
                        </Container>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ marginTop: 32 }}>
                        <Container>
                          <Box display="flex" alignItems="flex-start" justifyContent="flex-start" mt={3}>
                            <span>
                              <FormattedMessage id="risks.coefficient" defaultMessage="Coeficiente" />
                              :
                            </span>
                            <span style={{ paddingLeft: 5, color: riskResult && getRiskColor(riskResult) }}>
                              <strong>{riskResult || resultWillBeCalculatedText}</strong>
                            </span>
                          </Box>
                        </Container>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ marginTop: 32 }}>
                        <Container>
                          <Box display="flex" alignItems="flex-start" justifyContent="flex-start" mt={3}>
                            <span>
                              <FormattedMessage id="app.risks" defaultMessage="Riesgos" />
                              :
                            </span>
                            <span style={{ paddingLeft: 5, color: getRiskColor(riskResult) }}>
                              <strong>{riskLevel}</strong>
                            </span>
                          </Box>
                        </Container>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ marginTop: 32 }}>
                      <Container>
                        <>
                          <SelectValidator
                            id="inChargePersonId-select"
                            label={riskInChargePersonLabel}
                            name="inChargePersonId"
                            value={values.inChargePersonId}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '100%' }}
                            validators={['required']}
                            errorMessages={[validationConstants.RISK_RESPONSIBLE_REQUIRED]}
                          >
                            {people && people.map((item) => {
                              if (!item.names) return null;
                              return (
                                <MenuItem key={`${item.id}${item.surnames}`} value={item.id}>
                                  {`${item.names} ${item.surnames}`}
                                </MenuItem>
                              );
                            })}
                          </SelectValidator>
                        </>
                      </Container>
                    </Grid>

                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Container>
                      <Box display="flex" alignItems="flex-start" justifyContent="flex-start" mt={3}>
                        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 12 }}>
                          {`${previousEvaluationActionsText} ${LastEvaluationDate()}`}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="flex-start" justifyContent="flex-start" mt={1}>
                        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>
                          {LastActions()}
                        </Typography>
                      </Box>
                    </Container>
                  </Grid>

                  <Grid item xs={12} sm={12} style={{ marginTop: 32 }}>
                    <Container maxWidth={false}>
                      <Box display="flex" alignItems="flex-start" justifyContent="flex-start">
                        <Typography variant="subtitle2" color="textPrimary" style={{ fontSize: 14, fontWeight: 600 }}>{previousEvaluationText}</Typography>
                      </Box>
                      <Box display="flex" alignItems="center" justifyContent="center" pt={1}>
                        <Divider variant="middle" style={{ width: '100%' }} />
                      </Box>
                    </Container>
                  </Grid>
                  <Grid item xs={12}>
                    <Container maxWidth={false}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell size="small" style={{ fontSize: 12, fontWeight: 700 }}><FormattedMessage id="app.date" defaultMessage="Fecha" /></TableCell>
                            <TableCell size="small" style={{ fontSize: 12, fontWeight: 700, lineHeight: 1 }}><FormattedMessage id="risks.nextEvaluation" defaultMessage="Próxima evaluación" /></TableCell>
                            <TableCell size="small" style={{ fontSize: 12, fontWeight: 700, lineHeight: 1 }}>
                              <FormattedMessage id="risks.probability" defaultMessage="Probabilidad" />
                              {' '}
                              /
                              {' '}
                              <FormattedMessage id="risks.impact" defaultMessage="Impacto" />
                            </TableCell>
                            <TableCell size="small" style={{ fontSize: 12, fontWeight: 700 }}><FormattedMessage id="risks.coefficient" defaultMessage="Coeficiente" /></TableCell>
                            <TableCell size="small" style={{ fontSize: 12, fontWeight: 700, lineHeight: 1 }}>
                              <FormattedMessage id="risks.resultOfPreviousActions" defaultMessage="Resultado de acciones anteriores" />
                            </TableCell>
                            <TableCell size="small" style={{ fontSize: 12, fontWeight: 700 }}><FormattedMessage id="risks.actions" defaultMessage="Acciones" /></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          { risk.riskEvaluations.map((evaluation) => (
                            <TableRow key={evaluation.id}>
                              <TableCell size="small" style={{ fontSize: 11, fontWeight: 400 }}>{moment(evaluation.evaluationDate).format('DD/MM/YY')}</TableCell>
                              <TableCell size="small" style={{ fontSize: 11, fontWeight: 400 }}>{moment(evaluation.nextEvaluationDate).format('DD/MM/YY')}</TableCell>
                              <TableCell size="small" style={{ fontSize: 11, fontWeight: 400 }}>
                                {`${evaluation.probability} / ${evaluation.impact}`}
                              </TableCell>
                              <TableCell size="small" style={{ fontSize: 11, fontWeight: 400, whiteSpace: 'nowrap' }}>
                                {getCoefficientWithColor(evaluation.probability, evaluation.impact)}
                              </TableCell>
                              <TableCell size="small">
                                <Box style={{
                                  width: 275,
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                >
                                  <Tooltip title={evaluation.result} placement="top">
                                    <Typography
                                      variant="caption"
                                      style={{
                                        fontWeight: 400,
                                        fontSize: 11,
                                      }}
                                    >
                                      {evaluation.result}
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              </TableCell>
                              <TableCell size="small">
                                <Box style={{
                                  width: 275,
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                >
                                  <Tooltip title={evaluation.actions} placement="top">
                                    <Typography
                                      variant="caption"
                                      style={{
                                        fontWeight: 400,
                                        fontSize: 11,
                                      }}
                                    >
                                      {evaluation.actions}
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Container>
                  </Grid>
                </Grid>
                <Box display="flex" alignItems="flex-end" justifyContent="flex-end" mt={2}>
                  <Button
                    component={Link}
                    to={`/risks/${riskId}`}
                    variant="outlined"
                    text={backText}
                  />
                  <Button
                    type="submit"
                    text={saveText}
                    style={{ fontSize: '10px', fontWeight: 600 }}
                    disabled={!isAdmin}
                  />
                </Box>
              </ValidatorForm>
            </div>
          </PageLayout>
        )
        : (
          <PageLayout title={newEvaluationText} breadCrumbs={<RiskEvaluationLoadingBreadcrumbs />}>
            <LinearProgress />
          </PageLayout>
        )}
    </>
  );
}

RiskEvaluationForm.propTypes = {
  routeParams: PropTypes.shape(),
};

RiskEvaluationForm.defaultProps = {
  routeParams: {},
};

export default RiskEvaluationForm;
