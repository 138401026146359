import React from 'react';
import { SvgIcon } from "@material-ui/core";

function UploadIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M0 9.70588V11H9V9.70588H0ZM0 4.52941H2.57143V8.41176H6.42857V4.52941H9L4.5 0L0 4.52941Z" />
        </SvgIcon>
    );
}

export default UploadIcon

