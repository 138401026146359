import axios from '../axios/axios';
import { authHeader, userCompanySelectorHelper } from '../helpers';
import handler from './handler';

function create(outlineEvaluation) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `/companies/${company.id}/outlineEvaluation`;
  return axios.post(postUrl, outlineEvaluation, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function get(id) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/outlineEvaluation/${id}`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAll() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/outlineEvaluation`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function update(outlineEvaluation) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const putUrl = `/companies/${company.id}/outlineEvaluation/${outlineEvaluation.id}`;
  return axios.put(putUrl, outlineEvaluation, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function deleteOutlineEvaluation(outlineEvaluationId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const deleteUrl = `/companies/${company.id}/outlineEvaluation/${outlineEvaluationId}`;
  return axios.delete(deleteUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const outlineEvaluationService = {
  create,
  get,
  getAll,
  update,
  deleteOutlineEvaluation,
};

export default outlineEvaluationService;
