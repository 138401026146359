import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Breadcrumbs, LinearProgress, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PageLayout from '../../PageLayout';
import TrainingForm from '../TrainingForm';
import { trainingActions } from '../../../redux/actions';

function EditTrainingPage({ routeParams: { id: trainingId } }) {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'training.edit.title', defaultMessage: 'Modificar capacitación' });
  const trainingText = intl.formatMessage({ id: 'training.trainingsText', defaultMessage: 'Capacitaciones' });
  const modifyText = intl.formatMessage({ id: 'app.modify', defaultMessage: 'Modificar' });
  const dispatch = useDispatch();
  const training = useSelector((state) => state.training.data);

  useEffect(() => {
    dispatch(trainingActions.get(trainingId));
  }, [dispatch, trainingId]);

  function EditTrainingBreadcrumbs() {
    const trainingLink = `/trainings/${trainingId}`;

    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/trainings" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{trainingText}</Typography>
        </Link>
        <Link color="inherit" to={trainingLink} style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{training.title}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{modifyText}</strong></Typography>
      </Breadcrumbs>
    );
  }

  function EditTrainingLoadingBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/trainings" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{trainingText}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{modifyText}</strong></Typography>
      </Breadcrumbs>
    );
  }

  return (
    <>
      {training
        ? (
          <PageLayout title={title} breadCrumbs={<EditTrainingBreadcrumbs />}>
            <TrainingForm edit data={training} />
          </PageLayout>
        )
        : (
          <PageLayout title={title} breadCrumbs={<EditTrainingLoadingBreadcrumbs />}>
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
}

EditTrainingPage.propTypes = {
  routeParams: PropTypes.shape(),
};

EditTrainingPage.defaultProps = {
  routeParams: {},
};

export default EditTrainingPage;
