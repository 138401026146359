import React, { useEffect, useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  makeStyles, Typography, Table, TableBody, TableContainer,
  TableRow, Paper, withStyles, TableCell as MuiTableCell, LinearProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  riskMatrix: {
    backgroundColor: '#F8F8F8',
    boxShadow: 'none',
    marginTop: '20px',
  },
  axisRow: {
    cursor: 'default',
  },
  impactAxis: {
    borderRight: '1px solid #757575',
    borderBottom: 'none',
    width: '10px',
    cursor: 'default',
  },
  axisText: {
    fontSize: '16px',
    cursor: 'default',
    fontWeight: 'bold',
  },
  impactText: {
    padding: 0,
    width: '1px',
    transform: 'rotate(-90deg)',
    borderBottom: 'none',
    cursor: 'default',
  },
  probabilityAxis: {
    borderBottom: 'none',
    borderTop: '1px solid #757575',
  },
  riskCard: {
    margin: 0,
    color: 'white',
    padding: '5px',
    fontSize: '11px',
    height: '51px',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  riskCell: {
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: 0,
    width: '90px',
    cursor: 'default',
  },
}));

const axisValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

function RiskMatrix({ risks }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [matrix, setMatrix] = useState(null);

  const riskResultColors = useMemo(() => ({
    veryHigh: '#FF0000',
    high: '#FFA500',
    mid: '#FFD700',
    low: '#008000',
  }), []);

  useEffect(() => {
    function getRiskColor(riskResult) {
      if (riskResult >= 7) { return riskResultColors.veryHigh; }
      if (riskResult >= 6) { return riskResultColors.high; }
      if (riskResult >= 4) { return riskResultColors.mid; }
      return riskResultColors.low;
    }

    function getRiskResult(probability, impact) {
      return (probability + impact) / 2;
    }

    function parseRisksBuildMatrix(data) {
      const matrixData = [];
      data.forEach((item) => {
        const lastEvaluation = item.riskEvaluations.find(
          (risk) => item.lastRiskEvaluationId === risk.id,
        );
        const { probability, impact } = lastEvaluation;
        const result = getRiskResult(probability, impact);
        // eslint-disable-next-line no-param-reassign
        item.color = getRiskColor(result);
        // eslint-disable-next-line no-param-reassign
        item.result = result;
        if (typeof matrixData[impact] === 'undefined') { matrixData[impact] = []; }
        if (typeof matrixData[impact][probability] === 'undefined') { matrixData[impact][probability] = []; }
        matrixData[impact][probability].push(item);
      });
      return matrixData;
    }

    if (risks && !matrix) {
      setMatrix(parseRisksBuildMatrix(risks));
    }
  }, [dispatch, riskResultColors, risks, matrix]);

  return (
    <>
      {matrix ? (
        <>
          <TableContainer component={Paper} className={classes.riskMatrix}>
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                {axisValues
                  .slice(0)
                  .reverse()
                  .map((valueI) => (
                    <TableRow key={valueI}>
                      {valueI === 10 && (
                        <TableCell
                          className={classes.impactText}
                          rowSpan={10}
                        >
                          <Typography className={classes.axisText}>
                            <FormattedMessage id="risks.impact" defaultMessage="Impacto" />
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell className={classes.impactAxis}>{valueI}</TableCell>
                      {axisValues.map((valueJ) => (
                        <TableCell
                          key={valueJ}
                          className={classes.riskCell}
                          style={
                            matrix[valueI]
                            && matrix[valueI][valueJ]
                            && matrix[valueI][valueJ].length === 1
                              ? { height: '0' }
                              : {}
                          }
                        >
                          {matrix[valueI]
                            && matrix[valueI][valueJ]
                            && matrix[valueI][valueJ].map((risk, index) => (
                              <Link key={risk.id} color="inherit" to={`/risks/${risk.id}`} style={{ textDecoration: 'none' }}>
                                <div
                                  key={risk.id}
                                  className={classes.riskCard}
                                  style={
                                    matrix[valueI][valueJ].length > 1
                                    && index + 1 !== matrix[valueI][valueJ].length
                                      ? { marginBottom: '2px', backgroundColor: risk.color }
                                      : { backgroundColor: risk.color }
                                  }
                                >
                                  <span>{risk.result}</span>
                                  <span style={{ display: 'block' }}>{risk.title}</span>
                                </div>
                              </Link>
                            ))}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}

                <TableRow className={classes.axisRow}>
                  {axisValues.map((value) => {
                    if (value === 1) {
                      return [
                        <TableCell key={0} scope="row" colSpan={2}>
                          {' '}
                        </TableCell>,
                        <TableCell
                          key={value}
                          align="center"
                          scope="row"
                          style={{ borderTop: '1px solid #757575' }}
                          className={classes.probabilityAxis}
                        >
                          {value}
                        </TableCell>,
                      ];
                    }
                    return (
                      <TableCell
                        key={value}
                        align="center"
                        scope="row"
                        className={classes.probabilityAxis}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Typography align="center" className={classes.axisText}>
            <FormattedMessage id="risks.probability" defaultMessage="Probabilidad" />
          </Typography>
        </>
      ) : (
        <LinearProgress />
      )}
    </>
  );
}

RiskMatrix.propTypes = {
  risks: PropTypes.arrayOf((PropTypes.shape())),
};

RiskMatrix.defaultProps = {
  risks: null,
};

export default RiskMatrix;
