/* eslint-disable max-len */
import React from 'react';
import {
  Box, Dialog, DialogContent, DialogTitle, IconButton, makeStyles, Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { companyActions, modalAlertActions } from '../../redux/actions';
import ButtonLoad from './Button';
import { history } from '../../helpers';
import { CloseIcon } from '../../assets/icons';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: '250px',
    minWidth: '410px',
  },
  textDialog: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    maxWidth: '380px',
  },
  textTitleDialog: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '18px',
    textTransform: 'uppercase',
  },
  closeButton: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

const ModalStripeAlert = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const modalAlert = useSelector((state) => (
    state.modalAlert.data.length ? state.modalAlert.data[0] : { }
  ));
  const user = useSelector((state) => state.authentication.user);
  const company = useSelector((state) => state.company.data);
  const plansText = intl.formatMessage({ id: 'app.plans', defaultMessage: 'Planes' });
  const acceptButtonText = intl.formatMessage({ id: 'app.accept', defaultMessage: 'Aceptar' });
  const rightClickLink = modalAlert.linkTo ? modalAlert.linkTo : '/stripe/plans';
  const theButtonRedirectsToSameURL = history.location.pathname === rightClickLink;
  const isCreatorUser = user && company && user.id === company.creatorUser;

  const handleCloseStripeAlert = () => {
    if (company && company.isFirstLogin) dispatch(companyActions.setFirstLogin(company.id));
    if (modalAlert.reload) window.location.reload();
    dispatch(modalAlertActions.clear());
  };

  const goToHomePage = () => {
    handleCloseStripeAlert();
    history.push('/home');
  };

  const handleRightButton = () => {
    handleCloseStripeAlert();
    history.push(rightClickLink);
  };

  const handleLeftButton = () => {
    if (modalAlert.goToHome) return goToHomePage();
    if (modalAlert.leftButtonFunction) {
      dispatch(modalAlert.leftButtonFunction);
    }
    return handleCloseStripeAlert();
  };

  return (
    <>
      {modalAlert.title && modalAlert.message && (
        <Dialog
          aria-labelledby="modal-plan-notify"
          open={Boolean(modalAlert)}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleCloseStripeAlert();
            }
          }}
          classes={{ paper: classes.dialogPaper }}
        >
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <IconButton
              aria-label="close-modal"
              className={classes.closeButton}
              onClick={() => handleCloseStripeAlert()}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogTitle disableTypography style={{ paddingTop: 0, paddingBottom: 8 }}>
            <Box display="flex" justifyContent="center">
              <Typography align="center" className={classes.textTitleDialog} variant="body1">
                {modalAlert.title}
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Typography
              align="center"
              display="block"
              className={classes.textDialog}
              variant="body1"
            >
              {modalAlert.message}
            </Typography>
          </DialogContent>
          <Box pt={1} pb={3} display="flex" justifyContent="center">
            <ButtonLoad
              style={{ minWidth: '150px' }}
              variant="outlined"
              text={modalAlert.buttonLeft || acceptButtonText}
              onClick={() => handleLeftButton()}
            />
            {(isCreatorUser || modalAlert.showButtonToAll) && !theButtonRedirectsToSameURL
            && (
              <ButtonLoad
                color="primary"
                style={{ minWidth: '150px' }}
                text={modalAlert.button || plansText}
                onClick={() => handleRightButton()}
              />
            )}
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default ModalStripeAlert;
