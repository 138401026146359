import React from 'react';
import { FormattedMessage } from 'react-intl';

function translateContext(context) {
  switch (context) {
    case 0: return <FormattedMessage id="risks.context.inner" defaultMessage="Interno" />;
    case 1: return <FormattedMessage id="risks.context.outer" defaultMessage="Externo" />;
    default: return 'error';
  }
}

function translateType(type) {
  switch (type) {
    case 0: return <FormattedMessage id="risks.type.negative" defaultMessage="Negativo" />;
    case 1: return <FormattedMessage id="risks.type.positive" defaultMessage="Positivo" />;
    default: return 'error';
  }
}

function getRiskCoefficient(probability, impact) {
  const coefficient = (probability + impact) / 2;
  return coefficient;
}

const riskResultColors = {
  veryHigh: '#BF1919',
  high: '#FB9435',
  mid: '#FDC41C',
  low: '#43A047',
};

const riskResultLimits = {
  veryHigh: 7,
  high: 6,
  mid: 4,
};
function getRiskLevel(probability, impact) {
  const riskCoefficient = getRiskCoefficient(probability, impact);
  if (riskCoefficient >= riskResultLimits.veryHigh) { return 'Muy alto'; }

  if (riskCoefficient >= riskResultLimits.high) { return 'Alto'; }

  if (riskCoefficient >= riskResultLimits.mid) { return 'Medio'; }

  return 'Bajo';
}

function getRiskColor(probability, impact) {
  const riskCoefficient = getRiskCoefficient(probability, impact);
  if (riskCoefficient >= riskResultLimits.veryHigh) { return riskResultColors.veryHigh; }
  if (riskCoefficient >= riskResultLimits.high) { return riskResultColors.high; }
  if (riskCoefficient >= riskResultLimits.mid) { return riskResultColors.mid; }
  return riskResultColors.low;
}

const riskHelper = {
  translateContext,
  translateType,
  getRiskCoefficient,
  getRiskColor,
  getRiskLevel,
};

export default riskHelper;
