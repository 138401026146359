import { exampleCompanyConstants } from '../../constants';
import { exampleCompanyService } from '../../services';
import { history } from '../../helpers';
import alertActions from './alert.actions';
import companyActions from './company.actions';

function getAll() {
  function request() { return { type: exampleCompanyConstants.GET_ALL_REQUEST }; }
  function success(exampleCompany) {
    return { type: exampleCompanyConstants.GET_ALL_SUCCESS, exampleCompany };
  }
  function failure(error) { return { type: exampleCompanyConstants.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    exampleCompanyService.getAll()
      .then(
        (exampleCompany) => {
          dispatch(success(exampleCompany));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function showWelcomeModal() {
  function success() {
    localStorage.setItem('showWelcomeModal', true);
    return { type: exampleCompanyConstants.OPEN_MODAL };
  }

  return (dispatch) => { dispatch(success()); };
}

function closeWelcomeModal() {
  function success() {
    localStorage.removeItem('showWelcomeModal');
    return { type: exampleCompanyConstants.CLOSE_MODAL };
  }

  return (dispatch) => { dispatch(success()); };
}

function fromScratch() {
  function success() {
    return { type: exampleCompanyConstants.FROM_SCRATCH };
  }

  return (dispatch) => { dispatch(success()); };
}

function confirmProcessMap(exampleCompanyId, currentCompanyId) {
  function request() { return { type: exampleCompanyConstants.CONFIRM_REQUEST }; }
  function success() { return { type: exampleCompanyConstants.CONFIRM_SUCCESS }; }
  function failure(error) {
    return { type: exampleCompanyConstants.CONFIRM_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    exampleCompanyService.confirmProcessMap(exampleCompanyId)
      .then(
        () => {
          dispatch(success());
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      )
      .finally(() => {
        dispatch(closeWelcomeModal());
        dispatch(companyActions.get(currentCompanyId));
        history.push('/home');
      });
  };
}

const exampleCompanyActions = {
  getAll,
  showWelcomeModal,
  closeWelcomeModal,
  fromScratch,
  confirmProcessMap,
};

export default exampleCompanyActions;
