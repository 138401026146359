import React from 'react';
import { FormattedMessage } from 'react-intl';

const request = {
  CREATE_REQUEST: 'OBJECTIVE_CREATE_REQUEST',
  CREATE_SUCCESS: 'OBJECTIVE_CREATE_SUCCESS',
  CREATE_FAILURE: 'OBJECTIVE_CREATE_FAILURE',

  GET_ALL_REQUEST: 'OBJECTIVE_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'OBJECTIVE_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'OBJECTIVE_GET_ALL_FAILURE',

  GET_REQUEST: 'OBJECTIVE_GET_REQUEST',
  GET_SUCCESS: 'OBJECTIVE_GET_SUCCESS',
  GET_FAILURE: 'OBJECTIVE_GET_FAILURE',

  UPDATE_REQUEST: 'OBJECTIVE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'OBJECTIVE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'OBJECTIVE_UPDATE_FAILURE',

  UPDATE_MEASURE_REQUEST: 'OBJECTIVE_UPDATE_MEASURE_REQUEST',
  UPDATE_MEASURE_SUCCESS: 'OBJECTIVE_UPDATE_MEASURE_SUCCESS',
  UPDATE_MEASURE_FAILURE: 'OBJECTIVE_UPDATE_MEASURE_FAILURE',

  DELETE_REQUEST: 'OBJECTIVE_DELETE_REQUEST',
  DELETE_SUCCESS: 'OBJECTIVE_DELETE_SUCCESS',
  DELETE_FAILURE: 'OBJECTIVE_DELETE_FAILURE',

  CREATE_BULK_REQUEST: 'OBJECTIVE_CREATE_BULK_REQUEST',
  CREATE_BULK_SUCCESS: 'OBJECTIVE_CREATE_BULK_SUCCESS',
  CREATE_BULK_FAILURE: 'OBJECTIVE_CREATE_BULK_FAILURE',

  CONFIRM_CREATE_BULK_REQUEST: 'OBJECTIVE_CONFIRM_CREATE_BULK_REQUEST',
  CONFIRM_CREATE_BULK_SUCCESS: 'OBJECTIVE_CONFIRM_CREATE_BULK_SUCCESS',
  CONFIRM_CREATE_BULK_FAILURE: 'OBJECTIVE_CONFIRM_CREATE_BULK_FAILURE',
};

const frequency = {
  monthly: {
    key: 'monthly',
    label: <FormattedMessage id="objective.monthly" defaultMessage="Mensual" />,
    values: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
  },
  bimonthly: {
    key: 'bimonthly',
    label: <FormattedMessage id="objective.bimonthly" defaultMessage="Bimestral" />,
    values: ['february', 'april', 'june', 'august', 'october', 'december'],
  },
  quarterly: {
    key: 'quarterly',
    label: <FormattedMessage id="objective.quarterly" defaultMessage="Trimestral" />,
    values: ['march', 'june', 'september', 'december'],
  },
  fourMonthly: {
    key: 'fourMonthly',
    label: <FormattedMessage id="objective.fourMonthly" defaultMessage="Cuatrimestral" />,
    values: ['april', 'august', 'december'],
  },
  biannual: {
    key: 'biannual',
    label: <FormattedMessage id="objective.biannual" defaultMessage="Semestral" />,
    values: ['june', 'december'],
  },
  yearly: {
    key: 'yearly',
    label: <FormattedMessage id="objective.yearly" defaultMessage="Anual" />,
    values: ['december'],
  },
};

const BOOLEAN_OPERATOR = 'boolean';

const translateOperators = (operator) => {
  if (operator === BOOLEAN_OPERATOR) {
    return <FormattedMessage id="objective.booleanOperator" defaultMessage="Cumple / No cumple" />;
  }
  return operator;
};

const operators = ['>=', '<=', '=', '>', '<', BOOLEAN_OPERATOR];

const measurementDay = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30];

const defaultMeasurementDay = 10;

const objectiveConstants = {
  request,
  frequency,
  operators,
  measurementDay,
  defaultMeasurementDay,
  translateOperators,
  BOOLEAN_OPERATOR,
};

export default objectiveConstants;
