/* eslint-disable react/prop-types */
import React from 'react';
import {
  Dialog, Box, Typography, DialogContent, makeStyles,
} from '@material-ui/core/';
import { FormattedMessage } from 'react-intl';
import ButtonLoad from './Button';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    padding: '40px 40px 25px',
  },
  termsDiv: {
    maxWidth: '600px',
    maxHeight: '300px',
    overflow: 'auto',
    scrollbarColor: '#999999',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '0.3em',
      paddingLeft: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(0,0,0,.4)',
    },
  },
}));

function TermsAndConditionsModal({
  open, setOpen, onChange, constants, name,
}) {
  const classes = useStyles();

  const onClickAccept = () => {
    const event = { target: { name, checked: true } };
    onChange(event);
    setOpen(false);
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      maxWidth="md"
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <Typography variant="subtitle1" style={{ fontWeight: 700 }} align="center">
          {constants.title}
        </Typography>

        <Box className={classes.termsDiv} mt={3}>
          {constants.subTitles.map((text, key) => {
            const keyId = `${key}-ID`;
            return (
              <Typography key={keyId} align="center" style={{ fontSize: '14px', fontWeight: 600 }}>
                {text}
              </Typography>
            );
          })}
          {constants.texts.map((text, key) => {
            const keyId = `${key}-ID`;
            return (
              <Typography key={keyId} style={{ fontSize: '14px', fontWeight: 400, textAlign: 'justify' }}>
                {text}
              </Typography>
            );
          })}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
          <ButtonLoad
            type="submit"
            text={<FormattedMessage id="app.close" defaultMessage="Cerrar" />}
            onClick={() => setOpen(false)}
            style={{ borderRadius: '30px', width: '158px', fontSize: '12px' }}
            variant="outlined"
          />
          {Boolean(onChange) && (
          <ButtonLoad
            type="submit"
            text={<FormattedMessage id="app.accept" defaultMessage="Aceptar" />}
            onClick={onClickAccept}
            style={{ borderRadius: '30px', width: '158px', fontSize: '12px' }}
          />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default TermsAndConditionsModal;
