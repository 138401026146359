import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box, Breadcrumbs, makeStyles, Typography, LinearProgress,
  Grid, ListItem, ListItemText, Collapse,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MuiButton from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { history } from '../../../helpers';
import PageLayout from '../../PageLayout';
import { processActions } from '../../../redux/actions';
import Button from '../../controls/Button';
import ObjectiveGauge from '../../Objective/ObjectiveDetailPage/ObjectiveGauge';
import ProcessDocumentDetail from './ProcessDocumentDetail';
import ProcessPeopleDetail from './ProcessPeopleDetail';
import ProcessDeviationDetail from './ProcessDeviationDetail';
import RiskMatrix from '../../Risk/RiskPage/RiskMatrix';

const useStyles = makeStyles(() => ({
  accordionSummary: {
    paddingLeft: '0px',
    cursor: 'pointer',
  },
  titleEntity: {
    fontSize: '14px',
    fontWeight: 700,
    paddingLeft: '8px',
  },
  seeViewIcon: {
    color: '#F16C24',
    display: 'inline',
    paddingRight: '4px',
  },
  collapsedView: {
    maxHeight: '230px',
    overflow: 'hidden',
    paddingLeft: '8px',
  },
  expandedView: {
    maxHeight: '690px',
    overflow: 'auto',
    paddingLeft: '8px',
  },
  goToEntity: {
    color: '#F16C24',
    fontSize: '10px',
    fontWeight: 600,
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
}));

const defaultValuesForCollapsibleList = {
  objective: true,
  deviations: true,
  interestedPeople: true,
  responsiblePeople: true,
  documents: true,
  risks: true,
  seeMoreObjective: false,
};

function ProcessDetailsPage({ routeParams: { id: processId } }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const process = useSelector((state) => state.process.data);
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const seeMoreText = intl.formatMessage({ id: 'app.seeMore', defaultMessage: 'Ver más' });
  const seeLessText = intl.formatMessage({ id: 'app.seeLess', defaultMessage: 'Ver menos' });
  const areaLabel = intl.formatMessage({ id: 'processes.area', defaultMessage: 'Area' });
  const processLabel = intl.formatMessage({ id: 'processes.title', defaultMessage: 'Procesos' });
  const processDetailsLabel = intl.formatMessage({ id: 'processes.details', defaultMessage: 'Detalle de proceso' });
  const objectiveTitleText = intl.formatMessage({ id: 'objective.linkedObjectives', defaultMessage: 'Objetivos vinculados' }, { count: process ? process.objectives.length : 0 });
  const deviationTitleText = intl.formatMessage({ id: 'deviation.linkedDeviation', defaultMessage: 'Desvíos vinculados' }, { count: process ? process.deviations.length : 0 });
  const riskTitleText = intl.formatMessage({ id: 'risks.linkedRisk', defaultMessage: 'Riesgos vinculados' }, { count: process ? process.risks.length : 0 });

  const editLink = `${processId}/edit`;
  const [openCollapse, setOpenCollapse] = useState(defaultValuesForCollapsibleList);

  useEffect(() => {
    dispatch(processActions.getWithDocuments(processId));
  }, [dispatch, processId]);

  function ProcessDetailsBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/processes" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{processLabel}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{process.name}</Typography>
      </Breadcrumbs>
    );
  }

  function ProcessDetailsLoadingBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/processes" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{processLabel}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{processDetailsLabel}</Typography>
      </Breadcrumbs>
    );
  }

  function toggleCollapseState(e, name) {
    e.stopPropagation();
    setOpenCollapse((prevValues) => ({
      ...prevValues, [name]: !prevValues[name],
    }));
  }

  return (
    <>
      {process
        ? (
          <PageLayout title={process.name} breadCrumbs={<ProcessDetailsBreadcrumbs />} isProcessDetailsPage>
            <Box pl={3}>
              <Typography variant="caption" gutterBottom>
                <strong style={{ fontSize: '14px' }}>{areaLabel}</strong>
              </Typography>
              <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                {process.area}
              </Typography>
              <Box display="flex" spacing={1}>
                <ProcessDocumentDetail
                  documents={process.documents}
                  processId={processId}
                />

                <ProcessPeopleDetail
                  interestedPeople={process.interestedPeople}
                  responsiblePeople={process.responsiblePeople}
                  processId={processId}
                />
              </Box>

              <Box pt={4} style={{ maxWidth: '1200px' }}>
                <ListItem onClick={(e) => toggleCollapseState(e, 'deviations')} className={classes.accordionSummary}>
                  {openCollapse.deviations ? <ExpandLess /> : <ExpandMore />}
                  <ListItemText primary={(
                    <Typography variant="caption" className={classes.titleEntity}>
                      {deviationTitleText}
                    </Typography>
                    )}
                  />
                </ListItem>
                <Collapse in={openCollapse.deviations} timeout="auto" mountOnEnter unmountOnExit>
                  <ProcessDeviationDetail
                    deviations={process.deviations}
                  />
                  <Box pt={2} pl={2}>
                    <MuiButton
                      component={Link}
                      to="/deviations/board"
                      color="primary"
                      className={classes.goToEntity}
                    >
                      <FormattedMessage id="deviation.seeAllDeviations" defaultMessage=" Ver todos los desvíos" />
                    </MuiButton>
                  </Box>
                </Collapse>
              </Box>

              <Box pt={4} style={{ maxWidth: '1200px' }}>
                <ListItem onClick={(e) => toggleCollapseState(e, 'objective')} className={classes.accordionSummary}>
                  {openCollapse.objective ? <ExpandLess /> : <ExpandMore />}
                  <ListItemText primary={(
                    <Typography variant="caption" className={classes.titleEntity}>
                      {objectiveTitleText}
                    </Typography>
                    )}
                  />
                </ListItem>
                <Collapse in={openCollapse.objective} timeout="auto" mountOnEnter unmountOnExit>
                  <Grid
                    container
                    spacing={2}
                    className={openCollapse.seeMoreObjective
                      ? classes.expandedView
                      : classes.collapsedView}
                  >
                    {process.objectives.reverse().map((objective) => (
                      <Grid item key={objective.id}>
                        <ObjectiveGauge
                          annualTarget={objective.annualTarget}
                          id={objective.id}
                          measures={objective.measure}
                          operator={objective.operator}
                          title={objective.title}
                          isCard
                          isAccumulativeObjective={objective.isAccumulativeObjective}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Box pt={2} pl={2} display="flex" alignItems="center">
                    {process.objectives.length > 4 && (
                      <MuiButton
                        className={classes.goToEntity}
                        color="primary"
                        onClick={(e) => toggleCollapseState(e, 'seeMoreObjective')}
                      >
                        {openCollapse.seeMoreObjective ? (
                          <>
                            <ExpandLess className={classes.seeViewIcon} />
                            {seeLessText}
                          </>
                        ) : (
                          <>
                            <ExpandMore className={classes.seeViewIcon} />
                            {seeMoreText}
                          </>
                        )}
                      </MuiButton>
                    )}
                    <MuiButton
                      className={classes.goToEntity}
                      color="primary"
                      component={Link}
                      to="/objectives"
                    >
                      <FormattedMessage id="objective.seeAllObjective" defaultMessage=" Ver todos los objetivos" />
                    </MuiButton>
                  </Box>
                </Collapse>
              </Box>

              <Box pt={4} style={{ maxWidth: '1200px' }}>
                <ListItem onClick={(e) => toggleCollapseState(e, 'risks')} className={classes.accordionSummary}>
                  {openCollapse.risks ? <ExpandLess /> : <ExpandMore />}
                  <ListItemText primary={(
                    <Typography variant="caption" className={classes.titleEntity}>
                      {riskTitleText}
                    </Typography>
                    )}
                  />
                </ListItem>
                <Collapse in={openCollapse.risks} timeout="auto" mountOnEnter unmountOnExit>
                  {Boolean(process.risks.length) && (
                    <RiskMatrix risks={process.risks} />
                  )}
                  <Box pl={2}>
                    <MuiButton
                      component={Link}
                      to="/risks"
                      color="primary"
                      className={classes.goToEntity}
                    >
                      <FormattedMessage id="risks.seeAllRisks" defaultMessage=" Ver todos los riesgos" />
                    </MuiButton>
                  </Box>
                </Collapse>
              </Box>

            </Box>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                onClick={history.goBack}
                variant="outlined"
                text={<FormattedMessage id="app.back" defaultMessage="volver" />}
              />
              <Button
                component={Link}
                to={editLink}
                type="submit"
                text={<FormattedMessage id="app.edit" defaultMessage="editar" />}
                disabled={!isAdmin}
              />
            </Box>

          </PageLayout>
        )
        : (
          <PageLayout
            title={processDetailsLabel}
            breadCrumbs={<ProcessDetailsLoadingBreadcrumbs />}
          >
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
}

ProcessDetailsPage.propTypes = {
  routeParams: PropTypes.shape(),
};

ProcessDetailsPage.defaultProps = {
  routeParams: {},
};

export default ProcessDetailsPage;
