import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Breadcrumbs,
  Grid,
  makeStyles,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  DialogContent, Dialog, IconButton, LinearProgress, Tooltip,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import PropTypes from 'prop-types';
import PageLayout from '../../PageLayout';
import { documentActions } from '../../../redux/actions';
import { documentConstants } from '../../../constants';
import Button from '../../controls/Button';
import {
  LinkIcon, DownloadIcon, CloseIcon, AlertIcon,
} from '../../../assets/icons';
import FileIcon from '../../../assets/icons/FileIcon.png';
import { documentHelpers, history, renderFileSize } from '../../../helpers';
import DownloadDocument from '../../controls/DownloadDocument';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#FFFFFF',
    margin: theme.spacing(3, 0),
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 3),
    borderRadius: '10px',
    minHeight: '230px',
  },
  table: {
    fontSize: '12px',
  },
  linkIcon: {
    width: '16px',
    height: '14px',
  },
  boxComment: {
    backgroundColor: '#EEEEEE',
    minWidth: '270px',
    minHeight: '100px',
    marginTop: '48px',
  },
  fileIcon: {
    width: '51px',
    height: '61px',
  },
  dialogPaper: {
    width: '468px',
    height: '328px',
    overflowY: 'hidden',
  },
  alertIcon: {
    width: '35px',
    height: '30px',
    color: '#F16C24',
  },
  closeIcon: {
    width: '14px',
    height: '13px',
    cursor: 'pointer',
  },
  unlinkDialogPaper: {
    width: '468px',
    height: '230px',
  },
  unlinkText: {
    width: '280px',
    paddingTop: '20px',
    fontSize: '12px',
    fontWeight: 400,
  },
}));

function DocumentDetailsPage({ routeParams: { id: documentId, id_version: documentVersion } }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Document = useSelector((state) => state.document.data);
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const versionsLabel = intl.formatMessage({ id: 'app.previousVersions', defaultMessage: 'Versiones Anteriores' });
  const documentText = intl.formatMessage({ id: 'document.title', defaultMessage: 'Documento' });
  const documentBreadcrumbsText = intl.formatMessage({ id: 'document.documentsTitle', defaultMessage: 'Documentos' });
  const documentDetailsText = intl.formatMessage({ id: 'document.details', defaultMessage: 'Detalle de documento' });
  const detailLabel = intl.formatMessage({ id: 'document.detailOfChange', defaultMessage: 'Detalle de cambios' });
  const useInLabel = intl.formatMessage({ id: 'document.usageIn', defaultMessage: 'Documento utilizado en: ' });
  const noChangeDetails = intl.formatMessage({ id: 'document.noChangeDetails', defaultMessage: 'No hay detalles de cambios' });
  const documentTitle = <FormattedMessage id="document.tableTitle" defaultMessage="Título" />;
  const documentDate = <FormattedMessage id="document.date" defaultMessage="Fecha y hora" />;
  const documentAuthor = <FormattedMessage id="document.author" defaultMessage="Autor" />;
  const documentReviewer = intl.formatMessage({ id: 'document.reviewerUserNames', defaultMessage: 'Revisor' });
  const documentChangeDetails = <FormattedMessage id="document.changeDetails" defaultMessage="Detalle de cambios" />;
  const unlinkLabel = intl.formatMessage({ id: 'document.unlink', defaultMessage: 'Desvincular' });
  const editLink = `/documents/${documentId}/new`;
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [unlinkDialog, setUnlinkDialog] = useState(false);
  const [updatedDialog, setUpdatedDialog] = useState(true);
  const [processToUnlink, setProcessToUnlink] = useState();
  const [actualVersion, setActualVersion] = useState();
  const [componentMounted, setComponentMounted] = useState(false);
  useEffect(() => {
    if (!componentMounted) {
      dispatch(documentActions.get(documentId));
      setComponentMounted(true);
    }

    if (Document && componentMounted) {
      const actualVersionAux = Document.versions ? Document.versions.find(
        (version) => version.id === Document.actualDocumentVersionId,
      ) : '';
      setActualVersion(actualVersionAux);
    }
  }, [dispatch, Document, actualVersion, documentId, componentMounted]);

  function DocumentDetailsBreadcrumbs() {
    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/documents" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{documentBreadcrumbsText}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{Document.title}</Typography>
      </Breadcrumbs>
    );
  }

  function DocumentDetailsLoadingBreadcrumbs() {
    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/documents" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{documentBreadcrumbsText}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: '12px' }}>{documentDetailsText}</Typography>
      </Breadcrumbs>
    );
  }
  function handleDeleteDialog() {
    setDeleteDialog(!deleteDialog);
  }

  function handleUnlinkDialog(id) {
    setUnlinkDialog(!unlinkDialog);
    setProcessToUnlink(id);
  }

  function handleUpdatedDocument() {
    setUpdatedDialog(!updatedDialog);
  }

  function unlinkDocument(entityId) {
    if (Document.type === documentConstants.PROCESS_DOCUMENT) {
      dispatch(documentActions.unlinkProcess(entityId, documentId));
    }
    if (Document.type === documentConstants.PERSON_DOCUMENT) {
      dispatch(documentActions.unlinkPerson(entityId, documentId));
    }
    if (Document.type === documentConstants.DEVIATION_DOCUMENT) {
      dispatch(documentActions.unlinkDeviation(entityId, documentId));
    }
    if (Document.type === documentConstants.TRAINING_DOCUMENT) {
      dispatch(documentActions.unlinkTraining(entityId, documentId));
    }
    if (Document.type === documentConstants.PROVIDER_DOCUMENT) {
      dispatch(documentActions.unlinkProvider(entityId, documentId));
    }
    if (Document.type === documentConstants.WORKFIELD_EVALUATION_DOCUMENT) {
      dispatch(documentActions.unlinkWorkFieldEvaluation(entityId, documentId));
    }
    window.location.reload();
  }

  function handleDeleteDocument() {
    dispatch(documentActions.deleteDocument(documentId));
  }

  return (
    <>
      { Document && actualVersion
        ? (
          <PageLayout title={documentText} breadCrumbs={<DocumentDetailsBreadcrumbs />}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={9} style={{ paddingLeft: '36px' }}>
                <Typography variant="subtitle1" style={{ fontSize: '13px', fontWeight: 600 }}>
                  {Document.title}
                </Typography>

                { actualVersion && actualVersion.editableHtml && (
                  <>
                    <Box className={classes.paper}>
                      <div style={{ fontSize: '11px' }}>
                        {' '}
                        { ReactHtmlParser(actualVersion.editableHtml) }
                        {' '}
                      </div>
                    </Box>
                    <Button
                      onClick={() => {
                        documentHelpers.downloadHtmlAsDocx(actualVersion.editableHtml,
                          Document.title,
                          actualVersion.versionName);
                      }}
                      text={<FormattedMessage id="app.download" defaultMessage="Descargar" />}
                      startIcon={<DownloadIcon style={{ width: '12px', height: '12px', marginRight: '10px' }} />}
                      style={{ fontSize: '9px' }}
                    />
                  </>
                ) }

                {actualVersion && actualVersion.extension && (
                  <Box className={classes.paper} style={{ alignItems: 'center', marginTop: '35px' }}>
                    <img className={classes.fileIcon} src={FileIcon} alt="File" />
                    <Grid item xs={12}>
                      <Typography style={{
                        fontSize: '10px', color: '#000000', fontWeight: '500', lineHeight: '18px',
                      }}
                      >
                        {`${Document.title}.${actualVersion.extension}`}
                      </Typography>
                      <Typography style={{
                        fontSize: '8px', color: '#757575', fontWeight: '500', lineHeight: '18px', textAlign: 'center',
                      }}
                      >
                        { renderFileSize(actualVersion.fileSize, actualVersion.extension)}
                      </Typography>
                    </Grid>
                    {' '}
                    <Button
                      onClick={() => {
                        documentHelpers.handleDownloadAction(
                          documentId, actualVersion, Document.title,
                        );
                      }}
                      text={<FormattedMessage id="app.download" defaultMessage="Descargar" />}
                      startIcon={<DownloadIcon style={{ width: '12px', height: '12px', marginRight: '10px' }} />}
                      style={{ fontSize: '9px' }}
                    />
                  </Box>
                )}

              </Grid>
              <Grid item xs={12} sm={3}>
                { (Document && actualVersion)
                  ? (
                    <Box className={classes.boxComment}>
                      <Typography
                        variant="h1"
                        style={{ padding: '15px 17px 17px', fontSize: '12px', fontWeight: 600 }}
                      >
                        {' '}
                        {detailLabel}
                        {' '}
                      </Typography>
                      <Box style={{
                        backgroundColor: '#FFFFFF',
                        marginRight: 17,
                        marginLeft: 18,
                        marginBottom: 20,
                      }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{ padding: '13px 18px 13px 13px', fontSize: 12, fontWeight: 400 }}
                        >
                          {(actualVersion && actualVersion.comment)
                            ? actualVersion.comment
                            : noChangeDetails}
                        </Typography>
                      </Box>
                    </Box>
                  )
                  : '' }
                { Document.usage
                  ? (
                    <Box className={classes.boxComment} style={{ marginTop: '24px' }}>
                      <Typography variant="h1" style={{ padding: '18px 13px 13px 18px', fontSize: 12, fontWeight: 600 }}>
                        {' '}
                        {useInLabel}
                        {' '}
                      </Typography>
                      { Document.usage.map((relation) => (
                        <Grid key={relation.id} container>
                          <Grid item xs={11} sm={9} md={6}>
                            <Typography variant="subtitle1" style={{ paddingLeft: '18px', fontSize: 12, fontWeight: 400 }}>
                              {relation.entityName}
                            </Typography>
                          </Grid>
                          {isAdmin && (
                          <Grid item xs={1} sm={3} md={6}>
                            <Tooltip title={unlinkLabel}>
                              <IconButton onClick={() => { handleUnlinkDialog(relation.id); }} style={{ textDecoration: 'none', padding: '0px' }}>
                                <LinkIcon className={classes.linkIcon} />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          )}
                        </Grid>
                      ))}
                    </Box>
                  ) : '' }
              </Grid>
              { Document.versions
                ? (
                  <Grid item xs={12} sm={12} style={{ padding: '36px' }}>
                    <Typography variant="h6" gutterBottom style={{ fontSize: '14px' }}>
                      <strong>{versionsLabel}</strong>
                    </Typography>
                    <Box>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell size="small" style={{ paddingLeft: '0px', fontSize: '12px' }}><strong>{documentTitle}</strong></TableCell>
                            <TableCell size="small" style={{ fontSize: '12px' }}><strong>{documentDate}</strong></TableCell>
                            <TableCell size="small" style={{ fontSize: '12px' }}><strong>{documentAuthor}</strong></TableCell>
                            <TableCell size="small" style={{ fontSize: '12px' }}><strong>{documentReviewer}</strong></TableCell>
                            <TableCell size="small" style={{ fontSize: '12px' }}><strong>{documentChangeDetails}</strong></TableCell>
                            <TableCell> </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          { Document.versions.map((version) => (
                            <TableRow key={version.id} onClick={() => history.push(`/documents/${documentId}/${documentVersion}`)}>
                              <TableCell size="small" style={{ paddingLeft: '0px', fontSize: '12px' }}>{version.versionName}</TableCell>
                              <TableCell size="small" style={{ fontSize: '12px' }}>{moment(version.creationDate).format('DD-MM-YYYY HH:mm:ss')}</TableCell>
                              <TableCell size="small" style={{ fontSize: '12px' }}>{version.creatorUserNames}</TableCell>
                              <TableCell size="small" style={{ fontSize: '12px' }}>{version.reviewerUserNames}</TableCell>
                              <TableCell size="small" style={{ fontSize: '12px' }}>{(version.comment !== 'undefined') ? version.comment : ''}</TableCell>
                              <TableCell size="small">
                                <DownloadDocument
                                  document={document}
                                  actualVersion={actualVersion}
                                />
                              </TableCell>
                            </TableRow>
                          )) }
                        </TableBody>
                      </Table>
                    </Box>
                  </Grid>
                ) : ''}
            </Grid>
            {isAdmin && (
            <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
              <Button
                onClick={handleDeleteDialog}
                variant="outlined"
                text={<FormattedMessage id="app.delete" defaultMessage="Eliminar" />}
              />
              <Button
                component={Link}
                to={editLink}
                type="submit"
                text={<FormattedMessage id="document.newVersion" defaultMessage="Crear nueva versión" />}
              />
            </Box>
            )}
            <Dialog
              classes={{ paper: classes.unlinkDialogPaper }}
              open={unlinkDialog}
              onClose={handleUnlinkDialog}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth="xs"
            >
              <DialogContent>
                <Box display="flex" justifyContent="flex-end">
                  <CloseIcon className={classes.closeIcon} onClick={handleUnlinkDialog} />
                </Box>
                <Box display="flex" justifyContent="center">
                  <Typography variant="subtitle1" style={{ fontSize: '14px', fontWeight: 700 }}>
                    {Document.type === documentConstants.PROCESS_DOCUMENT && <FormattedMessage id="document.unlinkProcess" defaultMessage="DESVINCULAR PROCESO" />}
                    {Document.type === documentConstants.PERSON_DOCUMENT && <FormattedMessage id="document.unlinkPerson" defaultMessage="DESVINCULAR PERSONA" />}
                    {Document.type === documentConstants.DEVIATION_DOCUMENT && <FormattedMessage id="document.unlinkDeviation" defaultMessage="DESVINCULAR DESVÍO" />}
                    {Document.type === documentConstants.TRAINING_DOCUMENT && <FormattedMessage id="document.unlinkTraining" defaultMessage="DESVINCULAR CAPACITACIÓN" />}
                    {Document.type === documentConstants.PROVIDER_DOCUMENT && <FormattedMessage id="document.unlinkProvider" defaultMessage="DESVINCULAR PROVEEDOR" />}
                    {Document.type === documentConstants.WORKFIELD_EVALUATION_DOCUMENT && <FormattedMessage id="document.unlinkWorkFieldEvaluation" defaultMessage="DESVINCULAR EVALUACIÓN" />}
                  </Typography>
                </Box>
                <Box display="flex" align="center" justifyContent="center">
                  <Typography variant="subtitle1" className={classes.unlinkText}>
                    {Document.type === documentConstants.PROCESS_DOCUMENT && <FormattedMessage id="document.unlinkProcessText" defaultMessage="Se dejará de utilizar el documento en éste proceso, pero no se eliminará del sistema, ni de los otros procesos donde esté asociado." />}
                    {Document.type === documentConstants.PERSON_DOCUMENT && <FormattedMessage id="document.unlinkPersonText" defaultMessage="Se dejará de utilizar el documento en ésta persona, pero no se eliminará del sistema, ni de los otros procesos donde esté vinculada." />}
                    {Document.type === documentConstants.DEVIATION_DOCUMENT && <FormattedMessage id="document.unlinkDeviationText" defaultMessage="Se dejará de utilizar el documento con este desvío, pero no se eliminará del sistema." />}
                    {Document.type === documentConstants.TRAINING_DOCUMENT && <FormattedMessage id="document.unlinkTrainingText" defaultMessage="Se dejará de utilizar el documento con esta capacitación, pero no se eliminará del sistema." />}
                    {Document.type === documentConstants.PROVIDER_DOCUMENT && <FormattedMessage id="document.unlinkProviderText" defaultMessage="Se dejará de utilizar el documento con este proveedor, pero no se eliminará del sistema." />}
                    {Document.type === documentConstants.WORKFIELD_EVALUATION_DOCUMENT && <FormattedMessage id="document.unlinkWorkFieldEvaluationText" defaultMessage="Se dejará de utilizar el documento con esta evaluación, pero no se eliminará del sistema." />}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                  <Button
                    onClick={handleUnlinkDialog}
                    variant="outlined"
                    text={<FormattedMessage id="app.cancel" defaultMessage="CANCELAR" />}
                  />
                  <Button
                    onClick={() => { unlinkDocument(processToUnlink); }}
                    text={<FormattedMessage id="app.unlink" defaultMessage="desvincular" />}
                  />
                </Box>
              </DialogContent>
            </Dialog>
            <Dialog
              classes={{ paper: classes.dialogPaper }}
              open={deleteDialog}
              onClose={handleDeleteDialog}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <Box display="flex" justifyContent="flex-end">
                  <CloseIcon className={classes.closeIcon} onClick={handleDeleteDialog} />
                </Box>
                <Box display="flex" justifyContent="center">
                  <AlertIcon className={classes.alertIcon} />
                </Box>
                <Box display="flex" justifyContent="center">
                  <Typography variant="subtitle1" style={{ paddingTop: '20px', fontSize: '14px', fontWeight: 700 }}>
                    <strong><FormattedMessage id="alert.danger" defaultMessage="ADVERTENCIA" /></strong>
                  </Typography>
                </Box>
                <Box display="flex" align="center" justifyContent="center" style={{ width: '100%', maxWidth: '374px', margin: 'auto' }}>
                  <Typography variant="subtitle1" style={{ paddingTop: '20px', fontSize: '12px', fontWeight: 400 }}>
                    <FormattedMessage id="document.deleteText" defaultMessage=" Al eliminar se borrará el documento completo con todas sus versiones. Este ya no estará disponible en ningún proceso." />
                  </Typography>
                </Box>
                <Box
                  align="center"
                  justifyContent="center"
                  style={{
                    paddingTop: '20px', width: '100%', maxWidth: '374px', margin: 'auto',
                  }}
                >
                  <Typography variant="subtitle1" display="inline" style={{ paddingTop: '20px', fontSize: '12px', fontWeight: 400 }}>
                    <FormattedMessage id="document.newVersionPreviousText" defaultMessage=" Si quiere reemplazar la versión sin eliminar el documento, puede " />
                  </Typography>
                  <Link to={editLink} style={{ textDecoration: 'none' }}>
                    <Typography
                      variant="subtitle1"
                      display="inline"
                      style={{
                        paddingTop: '20px', fontSize: '12px', fontWeight: 400, color: '#F16C24',
                      }}
                    >
                      {' '}
                      <FormattedMessage id="document.newVersionToLowerCase" defaultMessage="crear una nueva versión" />
                    </Typography>
                  </Link>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                  <Button
                    onClick={handleDeleteDialog}
                    variant="outlined"
                    text={<FormattedMessage id="app.cancel" defaultMessage="CANCELAR" />}
                  />
                  <Button
                    onClick={handleDeleteDocument}
                    text={<FormattedMessage id="app.delete" defaultMessage="Eliminar" />}
                  />
                </Box>
              </DialogContent>
            </Dialog>
            <Dialog classes={{ paper: classes.dialogPaper }} style={{ height: '340px' }} open={updatedDialog && (documentVersion && parseInt(documentVersion, 10) !== parseInt(Document.actualDocumentVersionId, 10))} onClose={handleUpdatedDocument} aria-labelledby="form-dialog-title">
              <DialogContent>
                <Box display="flex" justifyContent="flex-end">
                  <CloseIcon className={classes.closeIcon} onClick={handleUpdatedDocument} />
                </Box>
                <Box display="flex" justifyContent="center">
                  <AlertIcon className={classes.alertIcon} />
                </Box>
                <Box display="flex" justifyContent="center">
                  <Typography variant="subtitle1" style={{ paddingTop: '20px', fontSize: '14px', fontWeight: 700 }}>
                    <strong><FormattedMessage id="alert.danger" defaultMessage="ADVERTENCIA" /></strong>
                  </Typography>
                </Box>
                <Box align="center" justifyContent="center" style={{ paddingTop: 20, width: 315, margin: 'auto' }}>
                  <Typography variant="subtitle1" display="inline" style={{ paddingTop: 20, fontSize: 12, fontWeight: 400 }}>
                    <FormattedMessage id="document.outdatedText" defaultMessage="No está viendo la última versión de este documento. Si quiere puede ver la" />
                  </Typography>

                  <Link to={`/documents/${documentId}/${Document.actualDocumentVersionId}`} style={{ textDecoration: 'none' }}>
                    <Typography
                      variant="subtitle1"
                      display="inline"
                      style={{
                        paddingTop: 20, fontSize: 12, fontWeight: 400, color: '#F16C24',
                      }}
                    >
                      <FormattedMessage id="document.lastVersion" defaultMessage="última Versión" />
                    </Typography>
                  </Link>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                  <Button
                    onClick={handleUpdatedDocument}
                    variant="outlined"
                    text={<FormattedMessage id="app.cancel" defaultMessage="CANCELAR" />}
                  />
                  {isAdmin && (
                  <Button
                    component={Link}
                    to={editLink}
                    text={<FormattedMessage id="document.newVersion" defaultMessage="Crear nueva versión" />}
                  />
                  )}
                </Box>
              </DialogContent>
            </Dialog>
          </PageLayout>
        )
        : (
          <PageLayout
            title={documentText}
            noAlert
            breadCrumbs={<DocumentDetailsLoadingBreadcrumbs />}
          >
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
}

DocumentDetailsPage.propTypes = {
  routeParams: PropTypes.shape(),
};

DocumentDetailsPage.defaultProps = {
  routeParams: {},
};

export default DocumentDetailsPage;
