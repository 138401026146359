import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box, Breadcrumbs, makeStyles, Typography, Table, TableBody,
  TableCell, TableRow, Dialog, DialogContent, Grid, IconButton,
  LinearProgress,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MuiButton from '@material-ui/core/Button';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PropTypes from 'prop-types';
import { history, transformTrainingStatus } from '../../../helpers';
import PageLayout from '../../PageLayout';
import { documentActions, personActions, userActions } from '../../../redux/actions';
import Button from '../../controls/Button';
import { LinkIcon, DocumentIcon, CloseIcon } from '../../../assets/icons/index';
import SetNewDocumentModal from '../../Documents/SetNewDocumentModal/SetNewDocumentModal';
import DownloadDocument from '../../controls/DownloadDocument';
import { outlineEvaluationConstants } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  personalInformation: {
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(2),
    },
    borderRadius: 1,
    borderTop: `4px solid ${theme.palette.primary.main}`,
  },
  personalPhoto: {
    width: 120,
    height: 120,
  },
  linkIcon: {
    width: '16px',
    height: '14px',
    color: '#696969',
  },
  documentIcon: {
    width: '9px',
    height: '11px',
    color: '#F16C24',
  },
  linkDocuments: {
    textTransform: 'uppercase',
    fontSize: '10px',
    color: '#F16C24',
    fontWeight: 600,
  },
  editIcon: {
    width: 16, height: 16,
  },
}));

const PersonDetailsPage = ({ routeParams: { id: personId } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const person = useSelector((state) => state.person.data);
  const users = useSelector((state) => state.user.users);
  const [componentMounted, setComponentMounted] = useState(false);
  const documentsLabel = intl.formatMessage({ id: 'documents.title_pl', defaultMessage: 'Documentos' });
  const personnelFileLabel = intl.formatMessage({ id: 'people.personnelFile', defaultMessage: 'Número de legajo' });
  const positionLabel = intl.formatMessage({ id: 'people.role', defaultMessage: 'Puesto' });
  const emailLabel = intl.formatMessage({ id: 'user.mail', defaultMessage: 'Mail' });
  const addressLabel = intl.formatMessage({ id: 'people.address', defaultMessage: 'Dirección' });
  const birthdateLabel = intl.formatMessage({ id: 'people.birthdate', defaultMessage: 'Fecha de nacimiento' });
  const peopleTitle = intl.formatMessage({ id: 'people.title', defaultMessage: 'Personas' });
  const editLink = `${personId}/edit`;
  const seeDocumentText = intl.formatMessage({ id: 'document.see', defaultMessage: 'Ver Documento' });
  const addDocumentText = intl.formatMessage({ id: 'document.addToPerson', defaultMessage: 'ASOCIAR DOCUMENTO A LA PERSONA' });
  const documentNameText = intl.formatMessage({ id: 'document.name', defaultMessage: 'Nombre' });
  const documentVersionText = intl.formatMessage({ id: 'document.version', defaultMessage: 'Versión' });
  const unlinkText = intl.formatMessage({ id: 'app.unlink', defaultMessage: 'Desvincular' });
  const noPersonDocumentsText = intl.formatMessage({ id: 'people.noDocuments', defaultMessage: 'No hay documentos vinculados a la persona' });
  const processesLabel = intl.formatMessage({ id: 'people.processes', defaultMessage: 'Procesos relacionados' });
  const processNameText = intl.formatMessage({ id: 'people.processName', defaultMessage: 'Nombre de proceso' });
  const relationTypeText = intl.formatMessage({ id: 'people.relationType', defaultMessage: 'Relación' });
  const seeProcessText = intl.formatMessage({ id: 'people.seeProcess', defaultMessage: 'Ver proceso' });
  const noProcessesText = intl.formatMessage({ id: 'people.noProcesses', defaultMessage: 'No hay procesos relacionados' });
  const interestText = intl.formatMessage({ id: 'people.interest', defaultMessage: 'Interesado' });
  const responsibleText = intl.formatMessage({ id: 'people.responsible', defaultMessage: 'Responsable' });
  const personDetailsText = intl.formatMessage({ id: 'people.details', defaultMessage: 'Detalle de persona' });
  const outlineLabel = intl.formatMessage({ id: 'outline.outlinesText', defaultMessage: 'Perfiles' });
  const dateLabel = intl.formatMessage({ id: 'app.date_start', defaultMessage: 'Fecha inicio' });
  const evaluationDateLabel = intl.formatMessage({ id: 'outlineEvaluation.date', defaultMessage: 'Fecha de evaluación' });
  const resultLabel = intl.formatMessage({ id: 'outlineEvaluation.result', defaultMessage: 'Resultado de evaluación' });
  const evaluatePersonLabel = intl.formatMessage({ id: 'person.evaluatePersonLabel', defaultMessage: 'Evaluar persona' });
  const evaluationLabel = intl.formatMessage({ id: 'outlineEvaluation.evaluationsText', defaultMessage: 'Evaluaciones' });
  const noEvaluationsText = intl.formatMessage({ id: 'person.noEvaluation', defaultMessage: 'No se realizó ninguna evaluación a esta persona' });
  const noPersonOutlineText = intl.formatMessage({ id: 'outline.noPersonOutline', defaultMessage: 'No se vinculó ningún perfil a la persona' });
  const trainingsLabel = intl.formatMessage({ id: 'training.trainingsText', defaultMessage: 'Capacitaciones' });
  const noTrainingsText = intl.formatMessage({ id: 'person.noTrainings', defaultMessage: 'La persona no realizó ninguna capacitación' });
  const trainingTitleLabel = intl.formatMessage({ id: 'app.title', defaultMessage: 'Título' });
  const trainingStatusLabel = intl.formatMessage({ id: 'app.status', defaultMessage: 'Estado' });
  const phoneNumberLabel = intl.formatMessage({ id: 'people.phoneNumber', defaultMessage: 'Número de teléfono' });

  const getEvaluationStatus = (evaluation) => {
    if (evaluation === outlineEvaluationConstants.FOR_REVISION) {
      return intl.formatMessage({ id: 'outlineEvaluation.forRevision', defaultMessage: 'Para revisión' });
    }
    if (evaluation === outlineEvaluationConstants.APPROVED) {
      return intl.formatMessage({ id: 'outlineEvaluation.approved', defaultMessage: 'Aprobada' });
    }
    return intl.formatMessage({ id: 'outlineEvaluation.pendingEvaluation', defaultMessage: 'Evaluación pendiente' });
  };

  const [documentModal, setDocumentModal] = useState(false);
  const [unlinkDocumentDialog, setUnlinkDocumentDialog] = useState(false);
  const [documentIdLink, setDocumentIdLink] = useState();

  useEffect(() => {
    if (!componentMounted) {
      dispatch(personActions.getWithDocuments(personId));
      dispatch(userActions.getAll());
      setComponentMounted(true);
    }
  }, [dispatch, users, person, personId, componentMounted]);

  function handleDocumentModal() {
    return setDocumentModal(!documentModal);
  }

  function handleUnlinkDocumentDialog(id) {
    setUnlinkDocumentDialog(!unlinkDocumentDialog);
    setDocumentIdLink(id);
  }

  function unlinkDocument(documentId) {
    dispatch(documentActions.unlinkPerson(personId, documentId));
    setUnlinkDocumentDialog(!unlinkDocumentDialog);
    window.location.reload(false);
  }

  function PersonDetailsBreadcrumbs() {
    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/people" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{peopleTitle}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{`${person.names} ${person.surnames}`}</strong></Typography>
      </Breadcrumbs>
    );
  }

  function PersonDetailsLoadingBreadcrumbs() {
    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/people" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{peopleTitle}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{personDetailsText}</strong></Typography>
      </Breadcrumbs>
    );
  }

  function redirectToEvaluation() {
    history.push(`/evaluations/new?personId=${person.id}`);
  }

  function getOutlinesName(personOutlines) {
    return personOutlines
      .map((outline) => outline.outline.name)
      .join(', ');
  }

  return (
    <>
      {person && users
        ? (
          <PageLayout title={`${person.names} ${person.surnames}`} breadCrumbs={<PersonDetailsBreadcrumbs />}>
            <Box pl={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Paper className={classes.personalInformation}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Box display="flex" alignItems="center" justifyContent="center" mt={2} mb={2}>
                          { person.imageUrl
                            ? (
                              <Avatar
                                className={classes.personalPhoto}
                                src={person.imageUrl}
                                alt={`${person.names} ${person.surnames}`}
                              />
                            )
                            : (
                              <AccountCircle
                                style={{ color: '#E0E0E0', width: 120, height: 120 }}
                                fontSize="large"
                              />
                            )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="caption" gutterBottom>
                          <strong style={{ fontSize: 12 }}>{personnelFileLabel}</strong>
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                          {person.personnelFile}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="caption" gutterBottom>
                          <strong style={{ fontSize: 12 }}>{positionLabel}</strong>
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                          {person.personRoles.map((role) => role.name).join(', ')}
                        </Typography>
                      </Grid>
                      { person.address && (
                        <Grid item xs={12} sm={6}>
                          <Typography variant="caption" gutterBottom>
                            <strong style={{ fontSize: 12 }}>{addressLabel}</strong>
                          </Typography>
                          <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                            {person.address}
                          </Typography>
                        </Grid>
                      )}
                      { person.birthdate && (
                        <Grid item xs={12} sm={6}>
                          <Typography variant="caption" gutterBottom>
                            <strong style={{ fontSize: 12 }}>{birthdateLabel}</strong>
                          </Typography>
                          <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                            {moment(person.birthdate).format('DD/MM/YYYY')}
                          </Typography>
                        </Grid>
                      )}
                      { person.email && (
                        <Grid item xs={12} sm={6}>
                          <Typography variant="caption" gutterBottom>
                            <strong style={{ fontSize: 12 }}>{emailLabel}</strong>
                          </Typography>
                          <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                            {person.email}
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12}>
                        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                          <Button
                            component={Link}
                            to={editLink}
                            startIcon={<EditIcon className={classes.editIcon} fontSize="small" />}
                            type="submit"
                            text={<FormattedMessage id="app.edit" defaultMessage="editar" />}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={8} style={{ paddingLeft: 50 }}>
                  <Box>
                    <Typography variant="caption" gutterBottom>
                      <strong style={{ fontSize: '14px' }}>{documentsLabel}</strong>
                    </Typography>
                    <Table>
                      <TableBody>
                        {(person.documents && (person.documents.length > 0)) ? (
                          <TableRow>
                            <TableCell size="small"><strong>{documentNameText}</strong></TableCell>
                            <TableCell size="small"><strong>{documentVersionText}</strong></TableCell>
                            <TableCell size="small"><strong>{seeDocumentText}</strong></TableCell>
                            <TableCell size="small"><strong>{unlinkText}</strong></TableCell>
                            <TableCell className={classes.tableHead} size="small" />
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell size="small">
                              <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                                {noPersonDocumentsText}
                                {' '}

                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                        {person.documents && person.documents.map((document) => {
                          const actualVersion = document.versions.find(
                            (version) => version.id === document.actualDocumentVersionId,
                          );
                          return (
                            <TableRow key={document.id}>
                              <TableCell size="small">{document.title}</TableCell>
                              <TableCell size="small">{actualVersion.versionName}</TableCell>
                              <TableCell size="small">
                                <Link to={`/documents/${document.id}/${actualVersion.id}`} style={{ textDecoration: 'none' }} className={classes.linkDocuments}>
                                  {seeDocumentText}
                                </Link>
                              </TableCell>
                              <TableCell size="small">
                                <IconButton onClick={() => { handleUnlinkDocumentDialog(document.id); }} style={{ textDecoration: 'none' }}>
                                  <LinkIcon className={classes.linkIcon} />
                                </IconButton>
                              </TableCell>
                              <TableCell size="small">
                                <DownloadDocument
                                  document={document}
                                  actualVersion={actualVersion}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        }) }
                      </TableBody>
                    </Table>
                  </Box>
                  <Box pt={2}>
                    <DocumentIcon className={classes.documentIcon} display="inline" />
                    <MuiButton color="primary" onClick={handleDocumentModal} display="inline" className={classes.linkDocuments} style={{ paddingLeft: '10px', textDecoration: 'none' }}>{addDocumentText}</MuiButton>
                  </Box>
                  <Box pt={4}>
                    <Typography variant="caption" gutterBottom>
                      <strong style={{ fontSize: '14px' }}>{processesLabel}</strong>
                    </Typography>
                    <Table>
                      <TableBody>
                        {((person.processesInterestedIn
                            && person.processesInterestedIn.length > 0)
                          || (person.processesResponsibleFor
                              && person.processesResponsibleFor.length > 0))
                          ? (
                            <TableRow>
                              <TableCell size="small"><strong>{processNameText}</strong></TableCell>
                              <TableCell size="small"><strong>{relationTypeText}</strong></TableCell>
                              <TableCell size="small"><strong>{seeProcessText}</strong></TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell size="small">
                                <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                                  {noProcessesText}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        {person.processesInterestedIn && person.processesInterestedIn.map(
                          (process) => (
                            <TableRow key={process.id}>
                              <TableCell size="small">{process.name}</TableCell>
                              <TableCell size="small">{interestText}</TableCell>
                              <TableCell size="small">
                                <Link to={`/processes/${process.id}`} style={{ textDecoration: 'none' }} className={classes.linkDocuments}>
                                  {seeProcessText}
                                </Link>
                              </TableCell>

                            </TableRow>
                          ),
                        )}
                        {person.processesResponsibleFor
                     && person.processesResponsibleFor.map((process) => (
                       <TableRow key={process.id}>
                         <TableCell size="small">{process.name}</TableCell>
                         <TableCell size="small">{responsibleText}</TableCell>
                         <TableCell size="small">
                           <Link to={`/processes/${process.id}`} style={{ textDecoration: 'none' }} className={classes.linkDocuments}>
                             {seeProcessText}
                           </Link>
                         </TableCell>

                       </TableRow>
                     ))}
                      </TableBody>
                    </Table>
                  </Box>

                  <Box pt={4}>
                    <Typography variant="caption" gutterBottom>
                      <strong style={{ fontSize: '14px' }}>{outlineLabel}</strong>
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                      {
                      person.outlines && person.outlines.length
                        ? getOutlinesName(person.outlines)
                        : noPersonOutlineText
                      }
                    </Typography>
                  </Box>

                  {person.phoneNumber && (
                    <Box pt={4}>
                      <Typography variant="caption" gutterBottom>
                        <strong style={{ fontSize: '14px' }}>{phoneNumberLabel}</strong>
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                        { person.phoneNumber }
                      </Typography>
                    </Box>
                  )}

                  <Box pt={4}>
                    <Typography variant="caption" gutterBottom>
                      <strong style={{ fontSize: '14px' }}>{evaluationLabel}</strong>
                    </Typography>
                  </Box>

                  <Table>
                    <TableBody>
                      {person.outlineEvaluations && person.outlineEvaluations.length > 0 ? (
                        <>
                          <TableRow>
                            <TableCell size="small"><strong>{outlineLabel}</strong></TableCell>
                            <TableCell size="small"><strong>{evaluationDateLabel}</strong></TableCell>
                            <TableCell size="small"><strong>{resultLabel}</strong></TableCell>
                          </TableRow>
                          {person.outlineEvaluations.map((outlineEvaluation) => (
                            <TableRow key={outlineEvaluation.id}>
                              <TableCell size="small">{outlineEvaluation.outline.name}</TableCell>
                              <TableCell size="small">
                                {moment(outlineEvaluation.date).format('DD/MM/YYYY')}
                              </TableCell>
                              <TableCell size="small">
                                {getEvaluationStatus(outlineEvaluation.evaluationStatus)}
                              </TableCell>
                            </TableRow>
                          ))}

                        </>
                      ) : (
                        <TableRow>
                          <TableCell size="small">
                            <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                              {noEvaluationsText}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>

                  <Box pt={2}>
                    <MuiButton
                      color="primary"
                      size="small"
                      display="inline"
                      onClick={redirectToEvaluation}
                      variant="outlined"
                      style={{ paddingLeft: '10px', textDecoration: 'none' }}
                    >
                      {evaluatePersonLabel}
                    </MuiButton>
                  </Box>

                  <Box pt={4}>
                    <Typography variant="caption" gutterBottom>
                      <strong style={{ fontSize: '14px' }}>{trainingsLabel}</strong>
                    </Typography>
                  </Box>
                  <Table>
                    <TableBody>

                      {person.trainings && person.trainings.length > 0 ? (
                        <>
                          <TableRow>
                            <TableCell size="small"><strong>{trainingTitleLabel}</strong></TableCell>
                            <TableCell size="small"><strong>{dateLabel}</strong></TableCell>
                            <TableCell size="small"><strong>{trainingStatusLabel}</strong></TableCell>
                          </TableRow>
                          {person.trainings.map((training) => (
                            <TableRow key={training.id}>
                              <TableCell size="small">{training.title}</TableCell>
                              <TableCell size="small">
                                {`${moment(training.dateFrom).format('DD/MM/YYYY')} ${moment(training.dateTo).format('DD/MM/YYYY')}`}
                              </TableCell>
                              <TableCell size="small">{transformTrainingStatus(training.status)}</TableCell>
                            </TableRow>
                          ))}
                        </>
                      ) : (
                        <TableRow>
                          <TableCell size="small">
                            <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                              {noTrainingsText}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <Box display="flex" alignItems="center" justifyContent="flex-end" pt={4}>
                    <Button
                      onClick={history.goBack}
                      variant="outlined"
                      text={<FormattedMessage id="app.back" defaultMessage="volver" />}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box>
                <Dialog
                  open={documentModal}
                  onClose={handleDocumentModal}
                  aria-labelledby="form-dialog-title"
                >
                  <SetNewDocumentModal
                    isOpen={documentModal}
                    setIsOpen={setDocumentModal}
                    personId={personId}
                  />
                </Dialog>
              </Box>

            </Box>
            <Dialog
              classes={{ paper: classes.unlinkDialogPaper }}
              open={unlinkDocumentDialog}
              onClose={handleUnlinkDocumentDialog}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth="xs"
            >
              <DialogContent>
                <Box display="flex" justifyContent="flex-end">
                  <CloseIcon className={classes.closeIcon} onClick={handleUnlinkDocumentDialog} />
                </Box>
                <Box display="flex" justifyContent="center">
                  <Typography variant="subtitle1" style={{ fontSize: '14px', fontWeight: 700 }}>
                    <strong><FormattedMessage id="document.unlinkDocument" defaultMessage="DESVINCULAR DOCUMENTO" /></strong>
                  </Typography>
                </Box>
                <Box display="flex" align="center" justifyContent="center">
                  <Typography
                    variant="subtitle1"
                    style={{
                      width: '280px', paddingTop: '20px', fontSize: '12px', fontWeight: 400,
                    }}
                  >
                    <FormattedMessage id="document.unlinkPersonText" defaultMessage="Se dejará de utilizar el documento en ésta persona, pero no se eliminará del sistema, ni de las otras personas donde esté asociado." />
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                  <Button
                    onClick={handleUnlinkDocumentDialog}
                    variant="outlined"
                    text={<FormattedMessage id="app.cancel" defaultMessage="CANCELAR" />}
                  />
                  <Button
                    onClick={() => { unlinkDocument(documentIdLink); }}
                    text={<FormattedMessage id="app.unlink" defaultMessage="desvincular" />}
                  />
                </Box>
              </DialogContent>
            </Dialog>

          </PageLayout>
        )
        : (
          <PageLayout title={personDetailsText} breadCrumbs={<PersonDetailsLoadingBreadcrumbs />}>
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
};

PersonDetailsPage.propTypes = {
  routeParams: PropTypes.shape(),
};

PersonDetailsPage.defaultProps = {
  routeParams: {},
};

export default PersonDetailsPage;
