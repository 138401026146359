import { tutorialConstants } from "../../constants";
import { userService } from "../../services";
import alertActions from "./alert.actions";
import userActions from "./user.actions";

function setTutorialStep(step) {
    localStorage.setItem('step', step);
    return { type: tutorialConstants.SET_TUTORIAL_STEP, step }
}

function setTutorialAsCompleted(step, userId) {
    if (step === 8) {
        function update() {
          return { type: tutorialConstants.SET_STEPS_AS_COMPLETED };
        }

        return (dispatch) => {
            userService.setTutorialAsCompleted(userId)
              .then(
                () => {
                  localStorage.removeItem('step');
                  dispatch(userActions.updateTutorialComplete());
                  return dispatch(update())
                },
                (error) => {
                  dispatch(alertActions.error(error));
                },
            );
        };
    }
}

const tutorialActions = {
    setTutorialStep,
    setTutorialAsCompleted,
};
  
export default tutorialActions;
  