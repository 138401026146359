const calendarConstants = {
  GET_ALL_REQUEST: 'CALENDAR_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'CALENDAR_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'CALENDAR_GET_ALL_FAILURE',

  GET_MONTHLY_SUCCESS: 'CALENDAR_GET_MONTHLY_SUCCESS',

  GET_REQUEST: 'CALENDAR_GET_REQUEST',
  GET_SUCCESS: 'CALENDAR_GET_SUCCESS',
  GET_FAILURE: 'CALENDAR_GET_FAILURE',
};

export default calendarConstants;
