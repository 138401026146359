import React from 'react';
import { FormattedMessage } from 'react-intl';

const workFieldTexts = {
  back: <FormattedMessage id="app.back" defaultMessage="Volver" />,
  save: <FormattedMessage id="app.save" defaultMessage="Guardar" />,
  cancel: <FormattedMessage id="app.cancel" defaultMessage="Cancelar" />,
  edit: <FormattedMessage id="app.edit" defaultMessage="Editar" />,
  create: <FormattedMessage id="app.create" defaultMessage="Crear" />,
  title: <FormattedMessage id="app.title" defaultMessage="Título" />,
  delete: <FormattedMessage id="app.delete" defaultMessage="Eliminar" />,
  new: <FormattedMessage id="app.new" defaultMessage="Nuevo" />,
  modify: <FormattedMessage id="app.modify" defaultMessage="Modificar" />,
  addButton: <FormattedMessage id="workField.addButton" defaultMessage="+ Crear rubro" />,

  itemsToBeEvaluated: <FormattedMessage id="workField.itemsToBeEvaluated" defaultMessage="Ítems a evaluar" />,
  workFieldItemsToBeEvaluated: <FormattedMessage id="workField.workFieldItemsToBeEvaluated" defaultMessage="Ítems a evaluar por rubro" />,
  weightingPoints: <FormattedMessage id="workField.weightingPoints" defaultMessage="Puntos de ponderación" />,
  weightingPercentage: <FormattedMessage id="workField.weightingPercentage" defaultMessage="Porcentaje de ponderación" />,
  addRequiredWorkFieldItems: <FormattedMessage id="workField.addRequiredWorkFieldItems" defaultMessage="Agregue los ítems requeridos a evaluar para el rubro" />,
  item: <FormattedMessage id="workField.item" defaultMessage="Item" />,
  frequency: <FormattedMessage id="workField.frequency" defaultMessage="Frecuencia de medición" />,
  isRequired: <FormattedMessage id="workField.isRequired" defaultMessage="Este campo es requerido para continuar" />,
  name: <FormattedMessage id="workField.name" defaultMessage="Nombre del rubro" />,
  workFields: <FormattedMessage id="workField.workFields" defaultMessage="Rubros" />,

  workField: <FormattedMessage id="workField.workField" defaultMessage="Rubro" />,

  newWorkField: <FormattedMessage id="workField.newWorkField" defaultMessage="Nuevo rubro" />,
  modifyWorkField: <FormattedMessage id="workField.modifyWorkField" defaultMessage="Modificar rubro" />,
  successfulUpdate: <FormattedMessage id="workField.successfulUpdate" defaultMessage="Se ha actualizado correctamente" />,
  successfulSave: <FormattedMessage id="workField.successfulSave" defaultMessage="Se ha guardado con éxito" />,
  successfulDelete: <FormattedMessage id="workField.successfulDelete" defaultMessage="Se ha eliminado con éxito" />,
  selectOption: <FormattedMessage id="workField.selectOption" defaultMessage="Seleccione una opción" />,
  deleteContentDialog: <FormattedMessage id="workField.deleteContentModal" defaultMessage="¿Está seguro que quiere eliminar este rubro?" />,
  deleteTitleDialog: <FormattedMessage id="workField.deleteTitleModal" defaultMessage="Eliminar rubro" />,
  updateWorkField: <FormattedMessage id="workField.updateWorkField" defaultMessage="Actualizar rubro" />,
  workFieldsDetails: <FormattedMessage id="workField.workFieldsDetails" defaultMessage="Detalles del rubro" />,
  noWorkField: <FormattedMessage id="workField.noWorkField" defaultMessage="No hay rubros para asignar, puede " />,
  addWorkField: <FormattedMessage id="workField.addWorkField" defaultMessage="Seleccionar rubros" />,
  createLink: <FormattedMessage id="workField.create" defaultMessage="crear un nuevo rubro." />,
  resultEvaluation: <FormattedMessage id="workField.resultEvaluation" defaultMessage="Resultado de la evaluación" />,
  totalEvaluationResult: <FormattedMessage id="workField.totalEvaluationResult" defaultMessage="Resultado total de la evaluación" />,

};

export default workFieldTexts;
