import React from 'react';
import { useIntl } from 'react-intl';
import { Breadcrumbs, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PageLayout from '../../PageLayout';
import TrainingForm from '../TrainingForm';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  toolbar: {
    backgroundColor: '#F8F8F8',
    zIndex: 1,
  },
  toolbarTitle: {
    color: '#F79A56',
    flexGrow: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    backgroundColor: '#F8F8F8',
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(2),
    },
  },
}));

function NewTrainingPage() {
  const classes = useStyles();
  const intl = useIntl();
  const newTrainingText = intl.formatMessage({ id: 'processes.new.title', defaultMessage: 'Nuevo' });
  const trainingText = intl.formatMessage({ id: 'training.trainingsText', defaultMessage: 'Capacitaciones' });

  function NewTrainingBreadcrumbs() {
    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/trainings" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{trainingText}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{newTrainingText}</strong></Typography>
      </Breadcrumbs>
    );
  }

  return (
    <PageLayout title={newTrainingText} breadCrumbs={<NewTrainingBreadcrumbs />}>
      <TrainingForm />
    </PageLayout>
  );
}

export default NewTrainingPage;
