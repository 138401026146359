import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Breadcrumbs, LinearProgress, makeStyles, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PersonForm from '../PersonForm';
import { personActions } from '../../../redux/actions';
import PageLayout from '../../PageLayout';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  toolbar: {
    backgroundColor: '#F8F8F8',
    zIndex: 1,
  },
  toolbarTitle: {
    color: '#F79A56',
    flexGrow: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    backgroundColor: '#F8F8F8',
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(2),
    },
  },
}));

function EditPersonPage(props) {
  const classes = useStyles();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'people.edit.title', defaultMessage: 'Modificar persona' });
  const peopleText = intl.formatMessage({ id: 'people.title', defaultMessage: 'Personas' });
  const modifyText = intl.formatMessage({ id: 'app.modify', defaultMessage: 'Modificar' });
  const dispatch = useDispatch();
  const personId = props.match.params.id;
  const person = useSelector((state) => state.person.data);
  const imageUrl = useSelector((state) => state.person.imageUrl);
  useEffect(() => {
    dispatch(personActions.get(personId));
  }, [dispatch, personId]);

  function EditPersonBreadcrumbs() {
    const personLink = `/people/${personId}`;

    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/people" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{peopleText}</Typography>
        </Link>
        <Link color="inherit" to={personLink} style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{`${person.names} ${person.surnames}`}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{modifyText}</strong></Typography>
      </Breadcrumbs>
    );
  }

  function EditPersonLoadingBreadcrumbs() {
    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/people" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{peopleText}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{modifyText}</strong></Typography>
      </Breadcrumbs>
    );
  }

  return (
    <>
      {person
        ? (
          <PageLayout title={title} breadCrumbs={<EditPersonBreadcrumbs />}>
            <PersonForm edit data={person} imageUrl={imageUrl} />
          </PageLayout>
        )
        : (
          <PageLayout title={title} breadCrumbs={<EditPersonLoadingBreadcrumbs />}>
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
}

export default EditPersonPage;
