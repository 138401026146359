import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs, Typography, makeStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PageLayout from '../../PageLayout';
import DataTable from '../../DataTable/DataTable';
import { providersTexts } from '../../../constants';
import PopoverWarningMessage from '../../controls/PopoverWarningMessage';
import { workFieldHelper } from '../../../helpers';

const useStyles = makeStyles(() => ({
  breadcrumbs: {
    textDecoration: 'none',
    fontWeight: 400,
    fontSize: 11,
  },
  tableBody: {
    color: 'black',
    textDecoration: 'none',
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
}));

const ProviderRejectedPage = () => {
  const intl = useIntl();
  const classes = useStyles();
  const userAuth = useSelector((state) => state.authentication.user);

  function ProviderRejectedPageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography component={Link} to="/providers" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {providersTexts.providers}
        </Typography>
        <Typography component={Link} to="/providers/rejected" variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11, textDecoration: 'none' }}>
          {providersTexts.rejectedProviders}
        </Typography>
      </Breadcrumbs>
    );
  }

  function getWorkFieldName(providerWorkField) {
    const entityLink = `/workFields/${providerWorkField.id}`;
    return (
      <Typography className={classes.tableBody} style={{ display: 'inline' }} component={Link} to={entityLink}>
        {providerWorkField.name}
      </Typography>
    );
  }

  const columns = [{
    name: 'id',
    options: {
      display: 'excluded',
      filter: false,
    },
  }, {
    name: 'name',
    label: intl.formatMessage({ id: 'app.name', defaultMessage: 'Nombre' }),
    options: {
      customBodyRender: (value, meta) => {
        const nameId = meta.rowData[0];
        const textTitle = (
          <Typography className={classes.tableBody} component={Link} to={`/providers/${nameId}`}>
            {value}
          </Typography>
        );
        return textTitle;
      },
    },
  }, {
    name: 'workField',
    label: intl.formatMessage({ id: 'providers.workFields', defaultMessage: 'Rubros' }),
    options: {
      customBodyRender: (value, meta) => {
        const id = meta.rowData[0];
        if (value === '-') {
          return (
            <PopoverWarningMessage
              id={id}
              message={providersTexts.noLinkedWorkFielPopoverMessage}
              customStyle={{ maxWidth: '350px' }}
            />
          );
        }
        return getWorkFieldName(value);
      },
      filter: false,
      customDownloadBodyRender: (value) => value.map((workField) => workField.workField.name).join(', '),
      sortCompare: (order) => workFieldHelper.sortCompare(order),
    },
  }, {
    name: 'cuit',
    label: intl.formatMessage({ id: 'providers.cuit', defaultMessage: 'CUIT' }),
  }, {
    name: 'businessName',
    label: intl.formatMessage({ id: 'providers.businessName', defaultMessage: 'Razón Social' }),
  }, {
    name: 'lastWorkFieldEvaluationStatus',
    label: intl.formatMessage({ id: 'providers.lastWorkFieldEvaluations', defaultMessage: 'Resultado última evaluación' }),
  }, {
    name: 'lastWorkFieldEvaluationDate',
    label: intl.formatMessage({ id: 'workFieldEvaluation.date', defaultMessage: 'Fecha de evaluación' }),
    options: {
      customBodyRender: (value) => {
        if (value && value > '01/01/1900') {
          return moment(value).format('DD/MM/YYYY');
        }
        return '-';
      },
    },
  }];

  const keys = ['id', 'name', 'workField', 'cuit', 'businessName', 'lastWorkFieldEvaluationStatus', 'lastWorkFieldEvaluationDate'];
  const fetchUrl = `companies/${userAuth.company.id}/providers/rejected`;
  const itemUrl = '/providers/';

  return (
    <PageLayout
      title={providersTexts.rejectedProviders}
      breadCrumbs={<ProviderRejectedPageBreadcrumbs />}
    >
      <DataTable
        title={providersTexts.rejectedProviders}
        fetchUrl={fetchUrl}
        itemUrl={itemUrl}
        keys={keys}
        columns={columns}
      />
    </PageLayout>
  );
};

export default ProviderRejectedPage;
