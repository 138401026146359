import React, { useEffect } from 'react';
import {
  Breadcrumbs, LinearProgress, makeStyles, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import EditRiskForm from './EditRiskForm';
import { riskActions } from '../../../redux/actions';
import PageLayout from '../../PageLayout';

const useStyles = makeStyles(() => ({
  textBreadcrumbs: {
    fontWeight: 400,
    fontSize: 11,
  },
  textBreadcrumbsBold: {
    fontWeight: 700,
    fontSize: 11,
  },

}));

function EditRiskPage({ routeParams: { id: riskId } }) {
  const classes = useStyles();
  const intl = useIntl();
  const editTitle = intl.formatMessage({ id: 'risks.editTitle', defaultMessage: 'Modificar Riesgo' });
  const risksText = intl.formatMessage({ id: 'risks.title', defaultMessage: 'Riesgos' });
  const modifyText = intl.formatMessage({ id: 'app.modify', defaultMessage: 'Modificar' });
  const dispatch = useDispatch();
  const risk = useSelector((state) => state.risk.data);

  useEffect(() => {
    dispatch(riskActions.get(riskId));
  }, [dispatch, riskId]);

  function EditRiskBreadcrumbs() {
    const riskLink = `/risks/${riskId}`;

    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/risks" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" className={classes.textBreadcrumbs}>{risksText}</Typography>
        </Link>
        <Link color="inherit" to={riskLink} style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" className={classes.textBreadcrumbs}>{risk.title}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" className={classes.textBreadcrumbsBold}>{modifyText}</Typography>
      </Breadcrumbs>
    );
  }

  function EditRiskLoadingBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/risks" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" className={classes.textBreadcrumbs}>{risksText}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" className={classes.textBreadcrumbsBold}>{modifyText}</Typography>
      </Breadcrumbs>
    );
  }
  return (
    <>
      {risk
        ? (
          <PageLayout title={editTitle} breadCrumbs={<EditRiskBreadcrumbs />}>
            <EditRiskForm edit data={risk} />
          </PageLayout>
        )
        : (
          <PageLayout title={editTitle} breadCrumbs={<EditRiskLoadingBreadcrumbs />}>
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
}

EditRiskPage.propTypes = {
  routeParams: PropTypes.shape(),
};

EditRiskPage.defaultProps = {
  routeParams: {},
};

export default EditRiskPage;
