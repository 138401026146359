import axios from '../axios/axios';
import { authHeader, userCompanySelectorHelper } from '../helpers';
import handler from './handler';

function create(person) {
  const company = userCompanySelectorHelper.getCurrentCompany();

  const headers = authHeader();
  const postUrl = `/companies/${company.id}/usersPerson`;
  return axios.post(postUrl, person, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function get(id) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/people/${id}`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAll() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/people`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function update(person, personId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const putUrl = `/companies/${company.id}/usersPerson/${personId}`;
  return axios.put(putUrl, person, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function deletePerson(personId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const deleteUrl = `/companies/${company.id}/people/${personId}`;
  return axios.delete(deleteUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getWithDocuments(id) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/people/${id}`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function uploadPersonalPhoto(data, personId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  return axios.post(`/companies/${company.id}/People/${personId}/personal-photo`, data, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function linkUser(personId, userId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/People/${personId}/user/${userId}/link`;
  return axios.post(getUrl, null, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function unlinkUser(personId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/People/${personId}/user/unlink`;
  return axios.post(getUrl, null, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const personService = {
  create,
  get,
  getAll,
  update,
  deletePerson,
  getWithDocuments,
  uploadPersonalPhoto,
  linkUser,
  unlinkUser,
};

export default personService;
