import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box, Breadcrumbs, Typography, Grid, LinearProgress,
  List, ListItem, ListItemText, Chip, makeStyles, Dialog,
  DialogTitle, DialogContent, DialogContentText, TextField, DialogActions,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { CheckCircle, Error } from '@material-ui/icons';
import MuiButton from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { history } from '../../../helpers';
import PageLayout from '../../PageLayout';
import { outlineEvaluationActions, personActions } from '../../../redux/actions';
import Button from '../../controls/Button';
import CustomRatingOutline from '../../controls/CustomRatingOutline';
import { outlineEvaluationConstants } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  titleText: { fontSize: '14px', fontWeight: 700 },
  contentText: { fontSize: '14px' },
  feedbackIcon: {
    color: theme.palette.primary.main,
  },
  feedbackIconContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  linkDocuments: {
    color: theme.palette.primary.main,
    fontSize: '10px',
    fontWeight: 600,
    textTransform: 'uppercase',
    textDecoration: 'none',
  },
}));

const OutlineEvaluationDetailsPage = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { roles } = useSelector((state) => state.authentication.user);
  const people = useSelector((state) => state.person.people);
  const isAdmin = roles.includes('Admin');
  const outlineEvaluation = useSelector((state) => state.outlineEvaluation.data);
  const outlineEvaluationId = match.params.id;
  const outlineEvaluationPersonLabel = intl.formatMessage({ id: 'outlineEvaluation.person', defaultMessage: 'Persona evaluada' });
  const outlinesEvaluationsText = intl.formatMessage({ id: 'outlineEvaluation.evaluationsText', defaultMessage: 'Evaluaciones' });
  const editLink = `${outlineEvaluationId}/edit`;
  const outlineEvaluationDetailsText = intl.formatMessage({ id: 'outlineEvaluation.details', defaultMessage: 'Detalles de evaluación' });
  const outlineEvaluationOutlineLabel = intl.formatMessage({ id: 'outlineEvaluation.outline', defaultMessage: 'Perfil evaluado' });
  const outlineEvaluationDateLabel = intl.formatMessage({ id: 'outlineEvaluation.date', defaultMessage: 'Fecha de evaluación' });
  const assessedKnowledgeLabel = intl.formatMessage({ id: 'outlineEvaluation.assessedKnowledge', defaultMessage: 'Conocimientos evaluados' });
  const knowledgeLabel = intl.formatMessage({ id: 'outlineEvaluation.knowledge', defaultMessage: 'Conocimiento' });
  const desiredLevelLabel = intl.formatMessage({ id: 'outlineEvaluation.desiredLevel', defaultMessage: 'Nivel deseado' });
  const evaluationLabel = intl.formatMessage({ id: 'outlineEvaluation.evaluation', defaultMessage: 'Evaluación' });
  const outlineEvaluationInChargePersonLabel = intl.formatMessage({ id: 'outlineEvaluation.inChargePerson', defaultMessage: 'Responsable de la evaluación' });
  const evaluatorCommentsLabel = intl.formatMessage({ id: 'outlineEvaluation.evaluatorComments', defaultMessage: 'Comentarios del evaluador' });
  const feedbackLabel = intl.formatMessage({ id: 'outlineEvaluation.feedbackLabel', defaultMessage: 'Feedback del evaluado' });
  const addFeedbackText = intl.formatMessage({ id: 'app.addFeedback', defaultMessage: 'Agregar feedback' });
  const addText = intl.formatMessage({ id: 'app.add', defaultMessage: 'Agregar' });
  const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const thereIsNoFeedbackText = intl.formatMessage({ id: 'app.thereIsNoFeedback', defaultMessage: 'No hay feedback registrado' });
  const editFeedbackText = intl.formatMessage({ id: 'app.editFeedback', defaultMessage: 'Editar feedback' });

  const getEvaluationStatus = (evaluation) => {
    if (evaluation === outlineEvaluationConstants.FOR_REVISION) {
      return intl.formatMessage({ id: 'outlineEvaluation.forRevision', defaultMessage: 'Para revisión' });
    }
    if (evaluation === outlineEvaluationConstants.APPROVED) {
      return intl.formatMessage({ id: 'outlineEvaluation.approved', defaultMessage: 'Aprobada' });
    }
    return intl.formatMessage({ id: 'outlineEvaluation.pendingEvaluation', defaultMessage: 'Evaluación pendiente' });
  };

  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [personFeedback, setPersonFeedback] = useState('');

  useEffect(() => {
    if (!people) {
      dispatch(personActions.getAll());
    }
    dispatch(outlineEvaluationActions.get(outlineEvaluationId));
  }, [dispatch, outlineEvaluationId, people]);

  function handleFeedbackDialogOpen(feedback) {
    setPersonFeedback(feedback);
    setOpenFeedbackDialog(true);
  }

  function handleFeedbackDialogClose() {
    setOpenFeedbackDialog(false);
  }

  function setFeedbackToPerson() {
    setOpenFeedbackDialog(false);
    setPersonFeedback('');
    const updatedOutlineEvaluation = { ...outlineEvaluation, feedback: personFeedback };
    dispatch(outlineEvaluationActions.update(updatedOutlineEvaluation, '/evaluations/'));
  }

  function OutlineEvaluationDetailsBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/evaluations" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{outlinesEvaluationsText}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{`${outlineEvaluation.person.names} ${outlineEvaluation.person.surnames} : ${outlineEvaluation.outline.name}`}</strong></Typography>
      </Breadcrumbs>
    );
  }

  function OutlineEvaluationDetailsLoadingBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/evaluations" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{outlinesEvaluationsText}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{outlineEvaluationDetailsText}</strong></Typography>
      </Breadcrumbs>
    );
  }

  return (
    <>
      {outlineEvaluation
        ? (
          <PageLayout
            title={outlineEvaluationDetailsText}
            breadCrumbs={<OutlineEvaluationDetailsBreadcrumbs />}
          >
            <Box pl={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Chip
                    label={getEvaluationStatus(outlineEvaluation.evaluationStatus)}
                    // eslint-disable-next-line max-len
                    style={outlineEvaluation.evaluationStatus === outlineEvaluationConstants.APPROVED
                      ? { color: '#fff', backgroundColor: '#43A047', labelColor: '#fff' }
                      : { color: '#fff', backgroundColor: '#FF9800', labelColor: '#fff' }}
                    icon={outlineEvaluation.evaluationStatus === outlineEvaluationConstants.APPROVED
                      ? <CheckCircle style={{ color: '#fff' }} />
                      : <Error style={{ color: '#fff' }} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.titleText} variant="caption" gutterBottom>
                    {outlineEvaluationDateLabel}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom className={classes.contentText}>
                    {moment(outlineEvaluation.date).format('DD/MM/YYYY')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.titleText} variant="caption" gutterBottom>
                    {outlineEvaluationPersonLabel}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom className={classes.contentText}>
                    {`${outlineEvaluation.person.names} ${outlineEvaluation.person.surnames}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.titleText} variant="caption" gutterBottom>
                    {outlineEvaluationOutlineLabel}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom className={classes.contentText}>
                    {outlineEvaluation.outline.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.titleText} variant="caption" gutterBottom>
                    {outlineEvaluationInChargePersonLabel}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom className={classes.contentText}>
                    {people && people.map((item) => {
                      if (item.id === outlineEvaluation.inChargePersonId) return (` ${item.names} ${item.surnames} `);
                      return null;
                    })}
                  </Typography>
                </Grid>
                { outlineEvaluation.evaluatorComments && (
                  <Grid item xs={12} sm={6}>
                    <>
                      <Typography className={classes.titleText} variant="caption" gutterBottom>
                        {evaluatorCommentsLabel}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom style={{ fontSize: 12 }}>
                        {outlineEvaluation.evaluatorComments}
                      </Typography>
                    </>
                  </Grid>
                )}

                <Grid item xs={12} sm={6}>
                  <Typography className={classes.titleText} variant="caption" gutterBottom>
                    {feedbackLabel}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: 12 }}>
                    {outlineEvaluation.feedback
                      ? outlineEvaluation.feedback
                      : thereIsNoFeedbackText}
                  </Typography>
                  <Box px={1} className={classes.feedbackIconContainer}>
                    {
                      outlineEvaluation.feedback
                        ? <EditIcon className={classes.feedbackIcon} display="inline" />
                        : <AddIcon className={classes.feedbackIcon} display="inline" />
                    }
                    <MuiButton
                      color="primary"
                      onClick={() => handleFeedbackDialogOpen(outlineEvaluation.feedback)}
                      display="inline"
                      className={classes.linkDocuments}
                    >
                      {outlineEvaluation.feedback ? editFeedbackText : addFeedbackText}
                    </MuiButton>
                  </Box>
                </Grid>

              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.titleText} variant="caption" gutterBottom>
                    {assessedKnowledgeLabel}
                  </Typography>
                  <List dense>
                    <ListItem key="titles" disableGutters>
                      <ListItemText primary={knowledgeLabel} />
                      <Typography variant="body2" style={{ width: '220px' }}>
                        {desiredLevelLabel}
                      </Typography>
                    </ListItem>
                    {JSON.parse(outlineEvaluation.answers).map((item) => (
                      <ListItem key={item.name} disableGutters>
                        <ListItemText
                          primary={item.name}
                        />
                        <CustomRatingOutline
                          knowledgeName={item.name}
                          knowledgeScore={item.minRequired}
                          readOnly
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box mt={2.875} />
                  <List dense>
                    <ListItem key="EvaluationKey" disableGutters style={{ height: '35px' }}>
                      <ListItemText primary={evaluationLabel} />
                    </ListItem>
                    {JSON.parse(outlineEvaluation.answers).map((item) => (
                      <ListItem key={item.name} disableGutters style={{ height: '36px' }}>
                        <CustomRatingOutline
                          knowledgeName={item.name}
                          knowledgeScore={item.score}
                          withColor={item.score >= item.minRequired ? 'approved' : 'disapproved'}
                          readOnly
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>

              </Grid>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
              <Button
                onClick={history.goBack}
                variant="outlined"
                text={<FormattedMessage id="app.back" defaultMessage="volver" />}
              />
              <Button
                component={Link}
                to={editLink}
                type="submit"
                text={<FormattedMessage id="app.edit" defaultMessage="editar" />}
                disabled={!isAdmin}
              />
            </Box>
            <Dialog
              open={openFeedbackDialog}
              onClose={handleFeedbackDialogClose}
              fullWidth
              maxWidth="sm"
              aria-labelledby="form-dialog-title-feedback"
              style={{ backgroundColor: '#F8F8F8' }}
            >
              <DialogTitle id="form-dialog-title-feedback" style={{ textTransform: 'uppercase' }}>{addFeedbackText}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <TextField
                    id="personFeedback"
                    name="personFeedback"
                    label={feedbackLabel}
                    multiline
                    rows={4}
                    fullWidth
                    style={{ paddingTop: 10, backgroundColor: '#FFFFFF' }}
                    value={personFeedback}
                    onChange={(e) => setPersonFeedback(e.target.value)}
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" text={cancelButtonText} onClick={handleFeedbackDialogClose} />
                <Button
                  className={classes.menuButton}
                  text={addText}
                  onClick={setFeedbackToPerson}
                />
              </DialogActions>
            </Dialog>
          </PageLayout>
        )
        : (
          <PageLayout
            title={outlineEvaluationDetailsText}
            breadCrumbs={<OutlineEvaluationDetailsLoadingBreadcrumbs />}
          >
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
};
OutlineEvaluationDetailsPage.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default OutlineEvaluationDetailsPage;
