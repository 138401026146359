import { Box, IconButton, Typography, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react'
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import tutorialActions from '../../redux/actions/tutorial.actions';
import { STEPS } from '../../constants/tutorial.constants';

const useStyles = makeStyles(() => ({
    container: {
      backgroundColor: '#F16C24',
      boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
      boxSizing: 'border-box',
      padding: '6px 12px',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      top: 0,
      left: 0,
      margin: 0,
      zIndex: 8000,
      height: '100%',
      color: '#FFF',
    },
    textContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: '14px',
        alignSelf: 'center',
        fontWeight: 700,
    },
    text: {
        fontSize: '12px',
        fontWeight: 400,
    },
    closeIconContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    closeIcon: {
        padding: 0,
        color: '#FFF',
    },
    link: {
        fontWeight: 700,
        color: '#FFF',
    }
}));

function TutorialInfo ({ step }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [display, setDisplay] = useState('flex');
    const currentStep = STEPS[step - 1];

    function handleOnClose() {
        setDisplay('none');
        if (currentStep.goToNextStep) {
            dispatch(tutorialActions.setTutorialStep(step + 1));
            setDisplay('flex');
        }
    };

    return (
        <Box className={classes.container} style={{ width: currentStep.width, display }}>
            <Box className={classes.textContainer}>
                {currentStep.title && (
                    <Typography className={classes.title}>
                        {currentStep.title}
                    </Typography>
                )}
                {currentStep.subText && (
                    <Typography className={classes.text} style={{ alignSelf: currentStep.alignText }}>
                        {currentStep.subText}
                    </Typography>
                )}
                {currentStep.text && (
                    <Typography className={classes.text} style={{ alignSelf: currentStep.alignText }}>
                        {currentStep.text}
                    </Typography>
                )}
                {currentStep.text2 && (
                    <Typography className={classes.text} style={{ alignSelf: currentStep.alignText }}>
                        {currentStep.text2}
                    </Typography>
                )}
            </Box>
            {currentStep.hasOnClose && (
                <Box className={classes.closeIconContainer}>
                    <IconButton 
                        className={classes.closeIcon}
                        onClick={handleOnClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            )}
        </Box>
    )
}

TutorialInfo.propTypes = {
    step: PropTypes.number.isRequired,
};

export default TutorialInfo;