import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
  Breadcrumbs, Typography, makeStyles, Chip, IconButton,
  Tooltip,
} from '@material-ui/core';
import { CheckCircle, Error, FileCopyOutlined } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../controls/Button';
import DataTable from '../../DataTable/DataTable';
import PageLayout from '../../PageLayout';
import { affiliateHelpers } from '../../../helpers';
import { affiliateText } from '../../../constants';
import { alertActions } from '../../../redux/actions';

const useStyles = makeStyles(() => ({
  referalSystemEnabledChip: {
    color: '#fff',
    height: 24,
  },
}));

const AffiliatePage = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.authentication.user);
  const titleText = intl.formatMessage({ id: 'affiliates.title', defaultMessage: 'Afiliados' });
  const activatedText = intl.formatMessage({ id: 'affiliates.activated', defaultMessage: 'Activado' });
  const disabledText = intl.formatMessage({ id: 'affiliates.disabled', defaultMessage: 'Desactivado' });

  if (!userAuth.isSuperAdmin) {
    return <Redirect to={{ pathname: '/home' }} />;
  }

  const customRenderForFixedFilterColumnIndex = (value) => (value ? activatedText : disabledText);

  function copyURLtoClipboard(url) {
    navigator.clipboard.writeText(url);
    dispatch(alertActions.success(affiliateText.copiedToClipboard));
  }

  function actionsButtons(url) {
    return (
      <>
        <IconButton onClick={() => copyURLtoClipboard(url)}>
          <Tooltip title={affiliateText.copy}>
            <FileCopyOutlined style={{ width: 16, height: 16, color: '#F79A56' }} />
          </Tooltip>
        </IconButton>
      </>
    );
  }

  const columns = [{
    name: 'id',
    options: {
      display: 'excluded',
      filter: false,
    },
  }, {
    name: 'names',
    label: intl.formatMessage({ id: 'app.name', defaultMessage: 'Nombre' }),
  }, {
    name: 'email',
    label: intl.formatMessage({ id: 'user.email', defaultMessage: 'Email' }),
  }, {
    name: 'discountCoupon',
    label: intl.formatMessage({ id: 'affiliates.discountCoupon', defaultMessage: 'Descuento a referidos' }),
    options: {
      customBodyRender: (value) => (
        <Tooltip title={affiliateText.onlyFirst3Months}>
          <span>{`${value} %`}</span>
        </Tooltip>
      ),
    },
  }, {
    name: 'commission',
    label: intl.formatMessage({ id: 'affiliates.commission', defaultMessage: 'Comisión' }),
    options: {
      customBodyRender: (value) => `${value} %`,
    },
  },{
    name: 'includesFreeInitialMonth',
    label: intl.formatMessage({ id: 'affiliates.includesFreeInitialMonth', defaultMessage: '30 días gratis' }),
    options: {
      customBodyRender: (value) => value && (<CheckCircle style={{ color: '#43A047' }} />),
    },
  }, {
    name: 'referalSystemEnabled',
    label: intl.formatMessage({ id: 'affiliates.referalSystemEnabled', defaultMessage: 'SISTEMA DE REFERIDOS' }),
    options: {
      customBodyRender: (value) => (
        <Chip
          className={classes.referalSystemEnabledChip}
          label={value ? activatedText : disabledText}
          icon={value ? <CheckCircle style={{ color: '#fff' }} /> : <Error style={{ color: '#fff' }} />}
          style={value ? { backgroundColor: '#43A047' } : { backgroundColor: '#FF9800' }}
        />
      ),
      filterOptions: {
        renderValue: (value) => (value ? activatedText : disabledText),
      },
      customFilterListOptions: {
        render: (value) => (value ? activatedText : disabledText),
      },
    },
  }, {
    name: 'couponId',
    label: intl.formatMessage({ id: 'affiliates.affiliateUrlForReferral', defaultMessage: 'URL para referidos' }),
    options: {
      customBodyRender: (value, meta) => {
        const indexColumnReferalSystemEnabled = keys.findIndex((key) => key === 'referalSystemEnabled')
        return (meta.rowData[indexColumnReferalSystemEnabled] && value !== '-')
        ? affiliateHelpers.getAffiliateURL(value)
        : '-';
      }
    },
  }, {
    name: '',
    options: {
      filter: false,
      sort: false,
      download: false,
      customBodyRender: (value, meta) => {
        const indexColumnCouponId = keys.findIndex((key) => key === 'couponId')
        const affiliateURL = affiliateHelpers.getAffiliateURL(meta.rowData[indexColumnCouponId]);
        return actionsButtons(affiliateURL);
      },
    },
  },
  ];

  const keys = ['id', 'names', 'email', 'discountCoupon', 'commission', 'includesFreeInitialMonth', 'referalSystemEnabled', 'couponId'];
  const fetchUrl = 'affiliates/';
  const itemUrl = 'affiliates/';

  function AffiliatePageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/affiliates" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{titleText}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  function ButtonGroup() {
    return (
      <>
        <Button
          text={intl.formatMessage({ id: 'affiliates.addButton', defaultMessage: 'Crear Afiliado' })}
          component={Link}
          to="affiliates/new"
          style={{ fontSize: '11px', fontWeight: 600 }}
        />
      </>
    );
  }

  return (
    <PageLayout
      title={titleText}
      breadCrumbs={<AffiliatePageBreadcrumbs />}
      buttonGroup={<ButtonGroup />}
    >
      <DataTable
        title={titleText}
        fetchUrl={fetchUrl}
        itemUrl={itemUrl}
        keys={keys}
        columns={columns}
        showFixedFilterForColumnIndex={5}
        customRenderForFixedFilterColumnIndex={customRenderForFixedFilterColumnIndex}
      />
    </PageLayout>
  );
};

export default AffiliatePage;
