import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs, IconButton, Tooltip, Typography, makeStyles, Box,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PageLayout from '../../PageLayout';
import Button from '../../controls/Button';
import DataTable from '../../DataTable/DataTable';
import CircleButton from '../../controls/CircleButton';
import { history } from '../../../helpers';
import PopoverWarningMessage from '../../controls/PopoverWarningMessage';
import CustomDialogAlert from '../../controls/CustomDialogAlert';
import VideoTutorialModal from '../../controls/VideoTutorialModal';
import { deviationTexts, videoTutorialId } from '../../../constants';
import { deviationActions } from '../../../redux/actions';
import colors from '../../../assets/colors';

const useStyles = makeStyles(() => ({
  tableBody: {
    color: 'black',
    textDecoration: 'none',
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  iconActions: {
    width: 16,
    height: 16,
  },
}));

const DeviationPage = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.authentication.user);
  const isAdmin = userAuth.roles.includes('Admin');
  const noLinkedProcesses = useSelector((state) => state.deviation.noLinkedProcesses);
  const deletingDeviation = useSelector((state) => state.deviation.deleting);
  const deletedDeviation = useSelector((state) => state.deviation.deleted);
  const [openNoLinkedProcessesDialog, setOpenNoLinkedProcessesDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deviationsID, setDeviationID] = useState(null);

  const title = intl.formatMessage({ id: 'deviation.title', defaultMessage: 'Desvíos' });
  const addButtonText = intl.formatMessage({ id: 'deviation.add.title', defaultMessage: '+ Crear nuevo' });
  const updateDeviationTitle = intl.formatMessage({ id: 'deviation.update.title', defaultMessage: 'Actualizar desvío' });
  const bulkCreationTitle = intl.formatMessage({ id: 'deviation.bulkCreationTitle', defaultMessage: 'Carga múltiple de desvíos' });
  const noLinkedProcessesMessage = {
    title: intl.formatMessage({ id: 'bulkCreation.deviationWithoutLinkedProcessesTitle', defaultMessage: 'Consejo' }),
    body: intl.formatMessage({ id: 'bulkCreation.deviationWithoutLinkedProcessesBody', defaultMessage: 'Si lo desea, a continuación puede editar los Desvíos creados para agregar o asignar un responsable y relacionarlos a uno o más procesos.' }),
  };
  const noLinkedProcessesPopoverMessage = intl.formatMessage({ id: 'bulkCreation.noLinkedProcessesPopover', defaultMessage: 'No está vinculado a ningún proceso' });

  const deviationOriginLabel = {
    claim: { key: 'claim', label: intl.formatMessage({ id: 'deviation.card.claim', defaultMessage: 'Reclamo' }) },
    internal: { key: 'internal', label: intl.formatMessage({ id: 'deviation.card.internal', defaultMessage: 'Interno' }) },
    internalAudit: { key: 'internalAudit', label: intl.formatMessage({ id: 'deviation.card.internalAudit', defaultMessage: 'Auditoría Interna' }) },
    externalAudit: { key: 'externalAudit', label: intl.formatMessage({ id: 'deviation.card.externalAudit', defaultMessage: 'Auditoría Externa' }) },
  };

  const deviationStatusLabel = {
    documented: { key: 'documented', label: intl.formatMessage({ id: 'deviation.form.documented', defaultMessage: 'Documentado' }) },
    analyzed: { key: 'analyzed', label: intl.formatMessage({ id: 'deviation.form.analyzed', defaultMessage: 'Analizado' }) },
    correctiveActionDefined: { key: 'correctiveActionDefined', label: intl.formatMessage({ id: 'deviation.form.correctiveActionDefined', defaultMessage: 'Acción correctiva definida' }) },
    correctiveActionImplemented: { key: 'correctiveActionImplemented', label: intl.formatMessage({ id: 'deviation.form.correctiveActionImplemented', defaultMessage: 'Acción correctiva implementada' }) },
    archived: { key: 'archived', label: intl.formatMessage({ id: 'deviation.form.archived', defaultMessage: 'Archivado' }) },
  };

  function toggleNoLinkedProcessesDialog() {
    setOpenNoLinkedProcessesDialog((prevValue) => !prevValue);
  }

  useEffect(() => {
    if (noLinkedProcesses) {
      setOpenNoLinkedProcessesDialog(true);
    }
    if (deletedDeviation) {
      setOpenDeleteDialog(false);
    }
  }, [noLinkedProcesses, deletedDeviation]);

  function toggleDeleteDialog() {
    setOpenDeleteDialog(!openDeleteDialog);
  }

  function handleDeleteDeviations(id) {
    dispatch(deviationActions.deleteDeviations(id, deviationTexts.successfulDelete));
  }

  function actionsButtons(link, deviationId) {
    if (!isAdmin) return null;
    return (
      <>
        <IconButton
          onClick={() => { history.push(link); }}
          style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}
        >
          <Tooltip title={updateDeviationTitle}>
            <CreateIcon className={classes.iconActions} style={{ color: colors.orangeLight }} />
          </Tooltip>
        </IconButton>
        <IconButton onClick={() => { setDeviationID(deviationId); toggleDeleteDialog(); }} style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}>
          <Tooltip title={deviationTexts.deleteDeviationTitleDialog}>
            <DeleteRoundedIcon className={classes.iconActions} style={{ color: colors.darkGray }} />
          </Tooltip>
        </IconButton>
      </>
    );
  }

  const columns = [{
    name: 'id',
    options: {
      display: 'excluded',
      filter: false,
    },
  }, {
    name: 'title',
    label: intl.formatMessage({ id: 'deviation.name', defaultMessage: 'Título' }),
    options: {
      customBodyRender: (value, meta) => {
        const titleId = meta.rowData[0];
        const textTitle = (
          <Typography className={classes.tableBody} component={Link} to={`/deviations/${titleId}`}>
            {value}
          </Typography>
        );
        return textTitle;
      },
    },
  }, {
    name: 'origin',
    label: intl.formatMessage({ id: 'deviation.origin', defaultMessage: 'Origen' }),
    options: {
      customBodyRender: (value, meta) => {
        const id = meta.rowData[0];
        return (
          <Typography className={classes.tableBody} onClick={() => history.push(`/deviations/${id}`)}>
            {deviationOriginLabel[value].label}
          </Typography>
        );
      },
      filterOptions: {
        renderValue: (value) => deviationOriginLabel[value].label,
      },
      customFilterListOptions: {
        render: (value) => deviationOriginLabel[value].label,
      },
      customDownloadBodyRender: (value) => deviationOriginLabel[value].label,
    },
  }, {
    name: 'processes',
    label: intl.formatMessage({ id: 'deviation.processes', defaultMessage: 'Procesos' }),
    options: {
      filter: false,
      customBodyRender: (processes, meta) => {
        const id = meta.rowData[0];
        if (!processes.length) {
          return <PopoverWarningMessage id={id} message={noLinkedProcessesPopoverMessage} />;
        }
        if (processes.length < 3) {
          return processes.map((item, index) => {
            if ((processes.length - 1) !== index) return (`${item.name}, `);
            return (`${item.name}`);
          });
        }
        return (
          <Box onClick={() => history.push(`/deviations/${id}`)}>
            <FormattedMessage
              id="deviation.card.processes"
              defaultMessage="Procesos"
              values={{ cant: processes.length }}
            />
          </Box>
        );
      },
      customDownloadBodyRender: (value) => value.map((processes) => processes.name).toString(),
    },
  }, {
    name: 'currentState',
    label: intl.formatMessage({ id: 'deviation.currentState', defaultMessage: 'Estado actual' }),
    options: {
      customBodyRender: (value, meta) => {
        const id = meta.rowData[0];
        return (
          <Typography className={classes.tableBody} onClick={() => history.push(`/deviations/${id}`)}>
            {deviationStatusLabel[value].label}
          </Typography>
        );
      },
      filterOptions: {
        renderValue: (value) => deviationStatusLabel[value].label,
      },
      customFilterListOptions: {
        render: (value) => deviationStatusLabel[value].label,
      },
      customDownloadBodyRender: (value) => deviationStatusLabel[value].label,
    },
  }, {
    name: 'plannedImplementationDate',
    label: intl.formatMessage({ id: 'deviation.form.plannedImplementationDate', defaultMessage: 'Fecha de implementación prevista' }),
    options: {
      customBodyRender: (date) => {
        if (date === '-') return date;
        return moment(date).format('DD/MM/YYYY');
      },
      customDownloadBodyRender: (date) => {
        if (date === '-') return date;
        return moment(date).format('DD/MM/YYYY');
      },
    },
  }, {
    name: '',
    options: {
      filter: false,
      sort: false,
      download: false,
      customBodyRender: (value, meta) => {
        const deviationId = meta.rowData[0];
        const link = `/deviations/${deviationId}/edit`;
        return actionsButtons(link, deviationId);
      },
    },
  }];

  const keys = ['id', 'title', 'origin', 'processes', 'currentState', 'plannedImplementationDate'];
  const fetchUrl = `companies/${userAuth.company.id}/deviations/`;
  const itemUrl = 'deviations/';

  function DeviationPageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/deviations" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{title}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  function ButtonGroup() {
    if (!isAdmin) return null;
    return (
      <>
        <Button
          text={addButtonText}
          component={Link}
          to="deviations/new"
          style={{ fontSize: '11px', fontWeight: 600 }}
        />
        <CircleButton
          text={(
            <Tooltip title={bulkCreationTitle}>
              <GroupAddIcon color="primary" fontSize="small" />
            </Tooltip>
          )}
          component={Link}
          to="deviations/upload"
          variant="outlined"
        />
      </>
    );
  }

  return (
    <PageLayout
      title={title}
      breadCrumbs={<DeviationPageBreadcrumbs />}
      buttonGroup={<ButtonGroup />}
      iconVideoTutorial={(
        <VideoTutorialModal
          title={<FormattedMessage id="videoTutorial.deviationUploadTitle" defaultMessage="Carga individual de desvíos" />}
          videoId={videoTutorialId.DEVIATION_UPLOAD}
        />
      )}
    >
      <DataTable
        title={title}
        fetchUrl={fetchUrl}
        itemUrl={itemUrl}
        keys={keys}
        columns={columns}
        addButtonText={addButtonText}
        addButtonTo="deviations/new"
      />
      <CustomDialogAlert
        toggleDialog={toggleNoLinkedProcessesDialog}
        openDialog={openNoLinkedProcessesDialog}
        message={noLinkedProcessesMessage}
      />
      <Dialog open={openDeleteDialog} onClose={() => toggleDeleteDialog()} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{deviationTexts.deleteDeviationTitleDialog}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {deviationTexts.deleteDeviationContentDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button text={deviationTexts.cancel} onClick={() => { toggleDeleteDialog(); }} />
          <Button
            text={deviationTexts.deleteButton}
            color="secondary"
            loading={deletingDeviation}
            onClick={() => { handleDeleteDeviations(deviationsID); }}
          />
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
};

export default DeviationPage;
