const exampleCompanyConstants = {
  CREATE_REQUEST: 'EXAMPLE_COMPANY_CREATE_REQUEST',
  CREATE_SUCCESS: 'EXAMPLE_COMPANY_CREATE_SUCCESS',
  CREATE_FAILURE: 'EXAMPLE_COMPANY_CREATE_FAILURE',

  GET_REQUEST: 'EXAMPLE_COMPANY_GET_REQUEST',
  GET_SUCCESS: 'EXAMPLE_COMPANY_GET_SUCCESS',
  GET_FAILURE: 'EXAMPLE_COMPANY_GET_FAILURE',

  GET_ALL_REQUEST: 'EXAMPLE_COMPANY_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'EXAMPLE_COMPANY_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'EXAMPLE_COMPANY_GET_ALL_FAILURE',

  UPDATE_REQUEST: 'EXAMPLE_COMPANY_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'EXAMPLE_COMPANY_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'EXAMPLE_COMPANY_UPDATE_FAILURE',

  DELETE_REQUEST: 'EXAMPLE_COMPANY_DELETE_REQUEST',
  DELETE_SUCCESS: 'EXAMPLE_COMPANY_DELETE_SUCCESS',
  DELETE_FAILURE: 'EXAMPLE_COMPANY_DELETE_FAILURE',

  CONFIRM_REQUEST: 'EXAMPLE_COMPANY_CONFIRM_REQUEST',
  CONFIRM_SUCCESS: 'EXAMPLE_COMPANY_CONFIRM_SUCCESS',
  CONFIRM_FAILURE: 'EXAMPLE_COMPANY_CONFIRM_FAILURE',

  OPEN_MODAL: 'EXAMPLE_COMPANY_OPEN_MODAL',
  CLOSE_MODAL: 'EXAMPLE_COMPANY_CLOSE_MODAL',
  FROM_SCRATCH: 'EXAMPLE_COMPANY_FROM_SCRATCH',

  SCREEN_WELCOME: 1,
  SCREEN_OPTIONS: 2,
};

export default exampleCompanyConstants;
