import axios from '../axios/axios';
import { authHeader, userCompanySelectorHelper } from '../helpers';
import handler from './handler';

function create(process) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `/companies/${company.id}/processes`;
  return axios.post(postUrl, process, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function get(id) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/processes/${id}`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAll() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/processes`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function update(process) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const putUrl = `/companies/${company.id}/processes/${process.id}`;
  return axios.put(putUrl, process, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function deleteProcess(processId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const deleteUrl = `/companies/${company.id}/processes/${processId}`;
  return axios.delete(deleteUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getWithDocuments(id) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/processes/${id}/with-documents`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function linkInterestedPeople(processId, interestedPeople) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `/companies/${company.id}/processes/${processId}/interest/link`;
  return axios.post(postUrl, interestedPeople, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function linkResponsiblePeople(processId, responsiblePeople) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `/companies/${company.id}/processes/${processId}/responsible/link`;
  return axios.post(postUrl, responsiblePeople, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function unlinkInterested(processId, personId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const data = {
    processId,
    personId,
  };
  const postUrl = `/companies/${company.id}/processes/${processId}/interest/${personId}/unlink`;
  return axios.post(postUrl, data, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function unlinkResponsible(processId, personId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const data = {
    processId,
    personId,
  };
  const postUrl = `/companies/${company.id}/processes/${processId}/responsible/${personId}/unlink`;
  return axios.post(postUrl, data, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}
const processService = {
  create,
  get,
  getAll,
  update,
  deleteProcess,
  getWithDocuments,
  linkInterestedPeople,
  linkResponsiblePeople,
  unlinkInterested,
  unlinkResponsible,
};

export default processService;
