import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@material-ui/core';
import { Error } from '@material-ui/icons';

import axios from '../../../../../axios/axios';
import { companyActions } from '../../../../../redux/actions';
import { authHeader } from '../../../../../helpers';
import ButtonLoad from '../../../../controls/Button';
import TaxIdSelector from '../../../../Auth/TaxIdSelector/TaxIdSelector';
import { validationConstants } from '../../../../../constants';
import { CloseIcon } from '../../../../../assets/icons';
import { handleChange } from '../../../../../common/handlers';
import { selectMenuProps } from '../../../../../common/constants';
import useStyles from './useStyles';

const initialFieldValues = {
    companyName: '',
    responsible: '',
    taxID: '',
    taxIDType: ''
};

const CompanyEditModal = ({
    company,
    editModalOpen,
    setEditModalOpen
}) => {
    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const [isClosing, setIsClosing] = useState(false);
    const [responsibles, setResponsibles] = useState([]);
    const [values, setValues] = useState(initialFieldValues);

    const acceptButtonText = intl.formatMessage({ id: 'app.accept', defaultMessage: 'Aceptar' });
    const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
    const companyEdit = intl.formatMessage({ id: 'superAdmin.companyEdit', defaultMessage: 'Edición de Empresa' });
    const confirmationMessage = intl.formatMessage({ id: 'superAdmin.confirmationMessage', defaultMessage: '¿Estás seguro?' });
    const continueEditingButtonText = intl.formatMessage({ id: 'superAdmin.continueEditing', defaultMessage: 'Seguir editando' });
    const exitWithoutSaving = intl.formatMessage({ id: 'superAdmin.exitWithoutSaving', defaultMessage: 'Vas a salir sin guardar los cambios que realizaste sobre tu empresa. ¿Querés hacerlo?' });
    const peopleResponsible = intl.formatMessage({ id: 'people.responsible', defaultMessage: 'Responsable' });
    const saveText = intl.formatMessage({ id: 'app.save', defaultMessage: 'Guardar' });

    const handleSubmit = () => {
        const newCompany = {
            id: company.id,
            ...(values.companyName && { name: values.companyName }),
            ...(values.responsible && { creatorUser: values.responsible }),
            ...(values.taxID && { stripeCustomerTaxId: values.taxID }),
            ...(values.taxIDType && { stripeCustomerTaxType: values.taxIDType })
        };

        dispatch(companyActions.update(newCompany));
    };

    const handleClose = () => {
        if (isClosing) {
            setEditModalOpen(false);
            setIsClosing(false);
        } else {
            setIsClosing(true);
        };
    };

    const getResponsibles = async () => {
        const headers = authHeader();

        try {
            const { data } = await axios.get(`companies/${company.id}/with-users`, { headers });

            if (data) {
                const { users } = data;

                if (users?.length) {
                    const adminResponsibles = users.filter(({ companies }) => {
                        return companies.some(({ id, roles }) => {
                            return (id === Number(company.id)) && roles.includes('Admin');
                        });
                    });

                    setResponsibles(adminResponsibles);
                }
            };
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (company) {
            getResponsibles();
            setValues({
                companyName: company.name,
                responsible: company.creatorUser,
                taxID: company.stripeCustomerTaxId || '',
                taxIDType: company.stripeCustomerTaxType || '',
            });
        };

        // eslint-disable-next-line
    }, [company]);

    return (
        <Dialog classes={{ paper: isClosing ? classes.dialogPaperIsClosing : classes.dialogPaper }} open={editModalOpen}>
            {
                isClosing ? (
                    <>
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <IconButton aria-label="close-modal" className={classes.closeButton} onClick={handleClose}>
                                <CloseIcon style={{ width: 24, height: 24 }} />
                            </IconButton>
                        </Box>
                        <DialogTitle disableTypography>
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                <Error style={{ width: 40, height: 40, color: '#F16C24' }} />
                                <Typography align="center" className={classes.textTitleDialogIsClosing} variant="body1">
                                    {confirmationMessage}
                                </Typography>
                            </Box>
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <Box className={classes.dialogContentIsClosing}>
                                <Typography align="center" className={classes.isClosingMessage}>
                                    {exitWithoutSaving}
                                </Typography>
                            </Box>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Grid container justifyContent='center'>
                                <Grid item xs={3}>
                                    <ButtonLoad
                                        fullWidth
                                        onClick={handleClose}
                                        text={acceptButtonText}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ButtonLoad
                                        fullWidth
                                        onClick={() => setIsClosing(false)}
                                        style={{ whiteSpace: 'nowrap' }}
                                        text={continueEditingButtonText}
                                    />
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </>
                ) : (
                    <>
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <IconButton aria-label="close-modal" className={classes.closeButton} onClick={handleClose}>
                                <CloseIcon style={{ width: 24, height: 24 }} />
                            </IconButton>
                        </Box>
                        <DialogTitle disableTypography>
                            <Box display="flex" justifyContent="center">
                                <Typography className={classes.textTitleDialog} variant="body1">
                                    {companyEdit}
                                </Typography>
                            </Box>
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <ValidatorForm onSubmit={handleSubmit} ref={formRef}>
                                <Grid spacing={1} container>
                                    <Grid item xs={12}>
                                        <TextValidator
                                            errorMessages={[validationConstants.COMPANY_REQUIRED]}
                                            fullWidth
                                            id="companyName"
                                            label={<FormattedMessage id="register.company" defaultMessage="Empresa" />}
                                            name="companyName"
                                            onChange={(event) => handleChange({ event, setValues, values })}
                                            style={{ minHeight: '68px' }}
                                            validators={['required']}
                                            value={values.companyName}
                                        />
                                    </Grid>
                                    <TaxIdSelector
                                        disablePortal={false}
                                        handleChange={(event) => handleChange({ event, setValues, values })}
                                        minHeight='68px'
                                        shrink
                                        values={values}
                                    />
                                    <Grid item xs={12}>
                                        <InputLabel id="responsible-select-label">{peopleResponsible}</InputLabel>
                                        {responsibles?.length > 0 && (
                                            <Select
                                                fullWidth
                                                id="responsible-select"
                                                labelId="responsible-select-label"
                                                MenuProps={selectMenuProps}
                                                name="responsible"
                                                onChange={(event) => handleChange({ event, setValues, values })}
                                                value={values.responsible}
                                            >
                                                {
                                                    responsibles?.map(({ email, id }) => (
                                                        <MenuItem key={`${id}-${email}`} value={id}>
                                                            {email}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        )}
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Grid container justifyContent='center'>
                                <Grid item xs={3}>
                                    <ButtonLoad
                                        fullWidth
                                        onClick={handleClose}
                                        text={cancelButtonText}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ButtonLoad
                                        fullWidth
                                        onClick={() => formRef.current.submit()}
                                        style={{ whiteSpace: 'nowrap' }}
                                        text={saveText}
                                    />
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </>
                )
            }
        </Dialog>
    );
};

export default CompanyEditModal;
