import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs, IconButton, Tooltip, Typography, makeStyles, Box,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PageLayout from '../../PageLayout';
import Button from '../../controls/Button';
import DataTable from '../../DataTable/DataTable';
import { history, objectiveHelper } from '../../../helpers';
import { objectiveActions } from '../../../redux/actions';
import { objectiveConstants, objectiveTexts, videoTutorialId } from '../../../constants';
import CircleButton from '../../controls/CircleButton';
import ObjectiveMeasures from '../ObjectiveDetailPage/ObjectiveMeasures';
import ObjectiveGauge from '../ObjectiveDetailPage/ObjectiveGauge';
import PopoverWarningMessage from '../../controls/PopoverWarningMessage';
import CustomDialogAlert from '../../controls/CustomDialogAlert';
import VideoTutorialModal from '../../controls/VideoTutorialModal';

const useStyles = makeStyles(() => ({
  tableBody: {
    color: 'black',
    textDecoration: 'none',
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
}));

const ObjectivePage = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.authentication.user);
  const deletingObjective = useSelector((state) => state.objective.deleting);
  const deletedObjective = useSelector((state) => state.objective.deleted);
  const noLinkedProcesses = useSelector((state) => state.objective.noLinkedProcesses);
  const isAdmin = userAuth.roles.includes('Admin');
  const titleText = intl.formatMessage({ id: 'objective.title', defaultMessage: 'Objetivos' });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [objectiveID, setObjectiveID] = useState(null);
  const [openSeeMeasureDialog, setOpenSeeMeasureDialog] = useState(false);
  const [openNoLinkedProcessesDialog, setOpenNoLinkedProcessesDialog] = useState(false);
  const noLinkedProcessesMessage = {
    title: intl.formatMessage({ id: 'bulkCreation.noLinkedProcessesTitle', defaultMessage: 'Consejo' }),
    body: intl.formatMessage({ id: 'bulkCreation.noLinkedProcessesBody', defaultMessage: 'Si lo desea, a continuación puede editar los Objetivos creados para agregar o asignar un responsable y relacionarlos a uno o más procesos.' }),
  };
  const noLinkedProcessesPopoverMessage = intl.formatMessage({ id: 'bulkCreation.noLinkedProcessesPopover', defaultMessage: 'No está vinculado a ningún proceso' });
  const [valuesModalMeasure, setValuesModalMeasure] = useState({
    measureFrequency: null,
    measures: null,
    year: null,
    title: null,
    annualTarget: null,
    operator: null,
    isAccumulativeObjective: false,
  });

  const objectiveFrequencyLabel = {
    monthly: { key: 'monthly', label: intl.formatMessage({ id: 'objective.monthly', defaultMessage: 'Mensual' }) },
    bimonthly: { key: 'bimonthly', label: intl.formatMessage({ id: 'objective.bimonthly', defaultMessage: 'Bimestral' }) },
    quarterly: { key: 'quarterly', label: intl.formatMessage({ id: 'objective.quarterly', defaultMessage: 'Trimestral' }) },
    fourMonthly: { key: 'fourMonthly', label: intl.formatMessage({ id: 'objective.fourMonthly', defaultMessage: 'Cuatrimestral' }) },
    biannual: { key: 'biannual', label: intl.formatMessage({ id: 'objective.biannual', defaultMessage: 'Semestral' }) },
    yearly: { key: 'yearly', label: intl.formatMessage({ id: 'objective.yearly', defaultMessage: 'Anual' }) },
  };

  useEffect(() => {
    if (deletedObjective) {
      setOpenDeleteDialog(false);
    }
  }, [deletedObjective]);

  useEffect(() => {
    if (noLinkedProcesses) {
      setOpenNoLinkedProcessesDialog(true);
    }
  }, [noLinkedProcesses]);

  function toggleDeleteDialog() {
    setOpenDeleteDialog(!openDeleteDialog);
  }

  function toggleSeeMeasureDialog() {
    setOpenSeeMeasureDialog(!openSeeMeasureDialog);
  }

  function toggleNoLinkedProcessesDialog() {
    setOpenNoLinkedProcessesDialog(!openNoLinkedProcessesDialog);
  }

  function handleDeleteObjectives(id) {
    dispatch(objectiveActions.deleteObjective(id, objectiveTexts.successfulDelete));
  }

  function actionsButtons(link, id) {
    if (!isAdmin) return null;
    return (
      <>
        <IconButton onClick={() => history.push(link)} style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}>
          <Tooltip title={objectiveTexts.updateObjectiveTitle}>
            <CreateIcon style={{ width: 16, height: 16, color: '#F79A56' }} />
          </Tooltip>
        </IconButton>
        <IconButton onClick={() => { setObjectiveID(id); toggleDeleteDialog(); }} style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}>
          <Tooltip title={objectiveTexts.deleteObjectiveTitleDialog}>
            <DeleteRoundedIcon style={{ width: 16, height: 16, color: '#727272' }} />
          </Tooltip>
        </IconButton>
      </>
    );
  }

  function actionsButtonsModal(
    id, measureFrequency, measures, year, title, annualTarget, operator, isAccumulativeObjective,
  ) {
    if (measures === '-') return null;
    return (
      <IconButton
        onClick={() => {
          setObjectiveID(id);
          setValuesModalMeasure({
            measureFrequency,
            measures,
            year,
            title,
            annualTarget,
            operator,
            isAccumulativeObjective,
          });
          toggleSeeMeasureDialog();
        }}
        style={{ padding: '0px', marginRight: '16px', marginLeft: '8px' }}
      >
        <Tooltip title={objectiveTexts.seeMeasure}>
          <ShowChartIcon style={{ width: 16, height: 16, color: '#F79A56' }} />
        </Tooltip>
      </IconButton>
    );
  }

  const columns = [{
    name: 'id',
    options: {
      display: 'excluded',
      filter: false,
    },
  }, {
    name: 'title',
    label: intl.formatMessage({ id: 'app.title', defaultMessage: 'Título' }),
    options: {
      customBodyRender: (value, meta) => {
        const titleId = meta.rowData[0];
        const textTitle = (
          <Typography className={classes.tableBody} component={Link} to={`/objectives/${titleId}`}>
            {value}
          </Typography>
        );
        return textTitle;
      },
    },
  }, {
    name: 'processes',
    label: intl.formatMessage({ id: 'objective.processes', defaultMessage: 'Procesos' }),
    options: {
      filter: false,
      customBodyRender: (processes, meta) => {
        const id = meta.rowData[0];
        if (!processes.length) {
          return <PopoverWarningMessage id={id} message={noLinkedProcessesPopoverMessage} />;
        }
        if (processes.length < 3) {
          return processes.map((item, index) => {
            if ((processes.length - 1) !== index) return (`${item.name}, `);
            return (`${item.name}`);
          });
        }
        return (
          <Box onClick={() => history.push(`/objectives/${id}`)}>
            <FormattedMessage
              id="objective.processes.cant"
              defaultMessage="Procesos"
              values={{ cant: processes.length }}
            />
          </Box>
        );
      },
      customDownloadBodyRender: (value) => value.map((processes) => processes.name).toString(),
    },
  }, {
    name: 'annualTarget',
    label: intl.formatMessage({ id: 'objective.annualTarget', defaultMessage: 'Objetivo anual' }),
    options: {
      customBodyRender: (value, meta) => {
        const id = meta.rowData[0];
        const operator = meta.rowData[8];
        if (operator === objectiveConstants.BOOLEAN_OPERATOR) {
          return (
            <Typography className={classes.tableBody} onClick={() => history.push(`/objectives/${id}`)}>
              {objectiveTexts.booleanOperator}
            </Typography>
          );
        }
        if (value === '-') return value;
        return (
          <Typography className={classes.tableBody} onClick={() => history.push(`/objectives/${id}`)}>
            {`${operator} ${new Intl.NumberFormat('es-AR').format(value)}`}
          </Typography>
        );
      },
    },
  }, {
    name: 'isAccumulativeObjective',
    label: intl.formatMessage({ id: 'objective.lastMeasure', defaultMessage: 'Última medición' }),
    options: {
      customBodyRender: (value, meta) => {
        const id = meta.rowData[0];
        const isAccumulativeObjective = meta.rowData[4];
        const measures = meta.rowData[7];
        const operator = meta.rowData[8];
        const isBooleanOperator = operator === objectiveConstants.BOOLEAN_OPERATOR;
        const lastMeasure = isAccumulativeObjective
          ? objectiveHelper.calculateAccObjective(measures)
          : objectiveHelper.getLastMeasure(measures);
        return (
          <Tooltip placement="bottom-start" title={isAccumulativeObjective ? objectiveTexts.accumulativeTotal : objectiveTexts.lastMeasure} aria-label="titleObjective">
            <Typography
              className={classes.tableBody}
              onClick={() => history.push(`/objectives/${id}`)}
            >
              {lastMeasure !== null
                ? objectiveHelper.formattedValue(lastMeasure, isBooleanOperator)
                : objectiveTexts.withoutMeasure}
            </Typography>
          </Tooltip>
        );
      },
    },
  }, {
    name: 'frequency',
    label: intl.formatMessage({ id: 'objective.frequency', defaultMessage: 'Frecuencia de medición' }),
    options: {
      customBodyRender: (value, meta) => {
        const id = meta.rowData[0];
        return (
          <Typography className={classes.tableBody} onClick={() => history.push(`/objectives/${id}`)}>
            {objectiveFrequencyLabel[value]
              ? objectiveFrequencyLabel[value].label
              : '-'}
          </Typography>
        );
      },
      filterOptions: {
        renderValue: (value) => {
          if (objectiveFrequencyLabel[value]) { return objectiveFrequencyLabel[value].label; }
          return '-';
        },
      },
      customFilterListOptions: {
        render: (value) => {
          if (objectiveFrequencyLabel[value]) { return objectiveFrequencyLabel[value].label; }
          return '-';
        },
      },
      customDownloadBodyRender: (value) => {
        if (objectiveFrequencyLabel[value]) { return objectiveFrequencyLabel[value].label; }
        return '-';
      },
    },
  }, {
    name: 'year',
    label: intl.formatMessage({ id: 'objective.year', defaultMessage: 'Año' }),
    options: {
      display: 'excluded',
      filterList: [new Date().getFullYear()],
    },
    customDownloadBodyRender: (date) => {
      if (date === '-') return date;
      return moment(date).format('YYYY');
    },
  }, {
    name: 'measure',
    label: intl.formatMessage({ id: 'objective.seeMeasures', defaultMessage: 'VER MEDICIONES' }),
    options: {
      filter: false,
      download: false,
      customBodyRender: (value, meta) => {
        const id = meta.rowData[0];
        const title = meta.rowData[1];
        const annualTarget = meta.rowData[3];
        const isAccumulativeObjective = meta.rowData[4];
        const measureFrequency = meta.rowData[5];
        const year = meta.rowData[6];
        const measures = meta.rowData[7];
        const operator = meta.rowData[8];
        return actionsButtonsModal(
          id, measureFrequency, measures,
          year, title, annualTarget, operator, isAccumulativeObjective,
        );
      },
    },
  }, {
    name: '',
    options: {
      filter: false,
      sort: false,
      download: false,
      customBodyRender: (value, meta) => {
        const objectiveId = meta.rowData[0];
        const link = `/objectives/${objectiveId}/edit`;
        return actionsButtons(link, objectiveId);
      },
    },
  }];

  const keys = ['id', 'title', 'processes', 'annualTarget', 'isAccumulativeObjective', 'frequency', 'year', 'measure', 'operator'];
  const fetchUrl = `companies/${userAuth.company.id}/objectives/`;
  const itemUrl = 'objectives/';

  function ObjectivePageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/objectives" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{objectiveTexts.title}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  function ButtonGroup() {
    if (!isAdmin) return null;
    return (
      <>
        <Button
          text={objectiveTexts.addButtonText}
          component={Link}
          to="objectives/new"
          style={{ fontSize: '11px', fontWeight: 600 }}
        />
        <CircleButton
          text={(
            <Tooltip title={objectiveTexts.bulkCreationTitle}>
              <GroupAddIcon color="primary" fontSize="small" />
            </Tooltip>
          )}
          component={Link}
          to="objectives/upload"
          variant="outlined"
        />
      </>
    );
  }

  return (
    <PageLayout
      title={objectiveTexts.title}
      breadCrumbs={<ObjectivePageBreadcrumbs />}
      buttonGroup={<ButtonGroup />}
      iconVideoTutorial={(
        <VideoTutorialModal
          title={<FormattedMessage id="videoTutorial.objectiveMassiveUploadTitle" defaultMessage="Carga masiva de objetivos" />}
          videoId={videoTutorialId.OBJECTIVE_MASSIVE_UPLOAD}
        />
      )}
    >
      <DataTable
        title={titleText}
        fetchUrl={fetchUrl}
        itemUrl={itemUrl}
        keys={keys}
        columns={columns}
        showFixedFilterForColumnIndex={6}
      />
      <Dialog open={openDeleteDialog} onClose={() => toggleDeleteDialog()} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{objectiveTexts.deleteObjectiveTitleDialog}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {objectiveTexts.deleteObjectiveContentDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button text={objectiveTexts.cancel} onClick={() => { toggleDeleteDialog(); }} />
          <Button
            text={objectiveTexts.deleteButton}
            color="secondary"
            loading={deletingObjective}
            onClick={() => { handleDeleteObjectives(objectiveID); }}
          />
        </DialogActions>
      </Dialog>

      <Dialog open={openSeeMeasureDialog} onClose={() => toggleSeeMeasureDialog()} maxWidth="lg" aria-labelledby="form-dialog-title-measure">
        <DialogTitle id="form-dialog-title-measure" style={{ textAlign: 'center' }}>{valuesModalMeasure.title}</DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center">
            <Box minWidth="400px">
              <ObjectiveMeasures
                measures={valuesModalMeasure.measures}
                measureFrequency={valuesModalMeasure.measureFrequency}
                year={valuesModalMeasure.year}
                showNewMeasure={false}
                isAccumulativeObjective={valuesModalMeasure.isAccumulativeObjective}
                operator={valuesModalMeasure.operator}
              />
            </Box>
            <Box display="flex" alignItems="center">
              <ObjectiveGauge
                annualTarget={valuesModalMeasure.annualTarget}
                id={objectiveID}
                measures={valuesModalMeasure.measures}
                operator={valuesModalMeasure.operator}
                title={valuesModalMeasure.title}
                isAccumulativeObjective={valuesModalMeasure.isAccumulativeObjective}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            text={objectiveTexts.cancel}
            onClick={() => { toggleSeeMeasureDialog(); }}
          />
          <Button
            text={objectiveTexts.objectiveLinkLabel}
            color="primary"
            onClick={() => history.push(`/objectives/${objectiveID}`)}
          />
        </DialogActions>
      </Dialog>
      <CustomDialogAlert
        toggleDialog={toggleNoLinkedProcessesDialog}
        openDialog={openNoLinkedProcessesDialog}
        message={noLinkedProcessesMessage}
      />
    </PageLayout>
  );
};

export default ObjectivePage;
