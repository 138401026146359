import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography, Box, DialogContent, makeStyles, Select, MenuItem, Input, CircularProgress,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';
import Button from '../../controls/Button';
import { CloseIcon } from '../../../assets/icons';
import { personActions, processActions } from '../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  title: {
    fontSize: '14px',
    fontWeight: 700,
    paddingTop: '25px',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '12px',
  },
  closeIcon: {
    width: '14px',
    height: '13px',
    cursor: 'pointer',
  },
  chip: {
    margin: 2,
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  getContentAnchorEl: null,
};

function LinkPeopleModal(props) {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const people = useSelector((state) => state.person.people);
  const chooseInterestedText = intl.formatMessage({ id: 'people.chooseInterested', defaultMessage: 'Elija a las personas que desea vincular como interesados' });
  const chooseResponsibleText = intl.formatMessage({ id: 'people.chooseResponsible', defaultMessage: 'Elija a las personas que desea vincular como responsables' });
  const noPeopleText = intl.formatMessage({ id: 'people.noPeople', defaultMessage: 'No hay personas para agregar, puede ' });
  const createPerson = intl.formatMessage({ id: 'people.create', defaultMessage: 'crear una nueva persona.' });
  const addResponsibleText = intl.formatMessage({ id: 'people.addResponsible', defaultMessage: 'Agregar responsable' });
  const addInterestedText = intl.formatMessage({ id: 'people.addInterested', defaultMessage: 'Agregar interesado' });

  const [interestedPeople, setInterestedPeople] = React.useState([]);
  const [responsiblePeople, setResponsiblePeople] = React.useState([]);
  const [excludedIds] = React.useState([]);
  const {
    isOpen, setIsOpen, processId,
    processInterestedPeople, interestedModal,
    processResponsiblePeople, responsibleModal,
  } = props;

  useEffect(() => {
    if (processId && !people) { dispatch(personActions.getAll()); }
    Object.values(processInterestedPeople).forEach((person) => {
      excludedIds.push(person.id);
    });
    Object.values(processResponsiblePeople).forEach((person) => {
      excludedIds.push(person.id);
    });
  }, [dispatch, processId, excludedIds, processInterestedPeople, processResponsiblePeople, people]);

  function handleSubmit(e) {
    e.preventDefault();

    if (interestedPeople && interestedPeople.length > 0) {
      const interestedPeopleIds = interestedPeople.map((obj) => obj.id);
      dispatch(processActions.linkInterestedPeople(processId, interestedPeopleIds));
    }
    if (responsiblePeople && responsiblePeople.length > 0) {
      const responsiblePeopleIds = responsiblePeople.map((obj) => obj.id);
      dispatch(processActions.linkResponsiblePeople(processId, responsiblePeopleIds));
    }
    setIsOpen(!isOpen);
  }

  const handleChangeResponsible = (event) => {
    setResponsiblePeople(event.target.value);
  };

  const handleChangeInterested = (event) => {
    setInterestedPeople(event.target.value);
  };

  function handleClose() {
    return setIsOpen(!isOpen);
  }

  function allPeopleLinked() {
    const linkedPeople = processResponsiblePeople.concat(processInterestedPeople);
    const isLinkedToProcess = (person) => linkedPeople.some(
      (linkedPerson) => linkedPerson.id === person.id,
    );
    const allLinked = people.every(isLinkedToProcess);
    return allLinked;
  }

  const renderChip = (selected) => {
    const render = selected.map((value) => (
      <Chip
        key={`${value.names} ${value.surnames}`}
        label={`${value.names} ${value.surnames}`}
        className={classes.chip}
      />
    ));
    return render;
  };

  return (

    <DialogContent>
      <Box display="flex" justifyContent="flex-end">
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </Box>

      <Typography align="center" className={classes.title}>
        {responsibleModal && (
        <FormattedMessage id="people.responsible.add" defaultMessage="AGREGAR RESPONSABLE" />

        )}
        {interestedModal && (
        <FormattedMessage id="people.interested.add" defaultMessage="AGREGAR INTERESADO" />

        )}
      </Typography>
      <Box pt={2} align="center">
        {!people && <CircularProgress />}

        {interestedModal && people && !allPeopleLinked() && (
        <>
          <Box mt={3} pl={5} pr={5} ml={5} mr={5} align="center">
            <Typography className={classes.subtitle} align="center" display="inline">
              {' '}
              {chooseInterestedText}
              {' '}
            </Typography>

          </Box>

          <Select
            id="interestedPeople"
            displayEmpty
            multiple
            value={interestedPeople}
            onChange={handleChangeInterested}
            input={<Input />}
            MenuProps={MenuProps}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>{addInterestedText}</em>;
              }
              return renderChip(selected);
            }}
            style={{ width: '100%' }}
          >
            <MenuItem disabled value="">
              <em>{addInterestedText}</em>
            </MenuItem>
            {people.map((person) => {
              if (!excludedIds.includes(person.id)) {
                return (
                  <MenuItem
                    key={person.id}
                    value={person}
                  >
                    <Checkbox
                      color="primary"
                      checked={interestedPeople.indexOf(person) > -1}
                    />
                    {`${person.names} ${person.surnames}`}
                  </MenuItem>
                );
              } return null;
            })}

          </Select>

        </>
        )}

        {responsibleModal && people && !allPeopleLinked() && (
        <>
          <Box mt={3} pl={5} pr={5} ml={5} mr={5} align="center">
            <Typography className={classes.subtitle} align="center" display="inline">
              {' '}
              {chooseResponsibleText}
              {' '}
            </Typography>

          </Box>

          <Select
            id="responsiblePeople"
            displayEmpty
            multiple
            value={responsiblePeople}
            onChange={handleChangeResponsible}
            input={<Input />}
            MenuProps={MenuProps}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>{addResponsibleText}</em>;
              }
              return renderChip(selected);
            }}
            style={{ width: '100%' }}
          >
            <MenuItem disabled value="">
              <em>{addResponsibleText}</em>
            </MenuItem>
            {people.map((person) => {
              if (!excludedIds.includes(person.id)) {
                return (
                  <MenuItem
                    key={person.id}
                    value={person}
                  >
                    <Checkbox
                      color="primary"
                      checked={responsiblePeople.indexOf(person) > -1}
                    />
                    {`${person.names} ${person.surnames}`}
                  </MenuItem>
                );
              } return null;
            })}
          </Select>

        </>
        )}
      </Box>

      { people && (!(people.length > 0) || allPeopleLinked()) && (
      <Box mt={5} pl={5} pr={5} ml={5} mr={5} align="center">
        <Typography className={classes.subtitle} align="center" display="inline">
          {' '}
          {noPeopleText}
          {' '}
        </Typography>
        <Link to="/people/new" style={{ textDecoration: 'none' }}>
          <Typography className={classes.subtitle} align="center" display="inline" style={{ color: '#F16C24' }}>
            {' '}
            {createPerson}
            {' '}
          </Typography>
        </Link>
      </Box>
      )}

      <Box display="flex" alignItems="right" justifyContent="flex-end" mt={5}>
        <Button
          onClick={handleClose}
          variant="outlined"
          text={<FormattedMessage id="app.cancel" defaultMessage="Cancelar" />}
        />
        <Button
          onClick={handleSubmit}
          type="submit"
          disabled={(interestedPeople.length === 0 && responsiblePeople.length === 0)}
          text={<FormattedMessage id="app.add" defaultMessage="Agregar" />}
        />
      </Box>
    </DialogContent>

  );
}

LinkPeopleModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  processId: PropTypes.string.isRequired,
  processInterestedPeople: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  interestedModal: PropTypes.bool.isRequired,
  processResponsiblePeople: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  responsibleModal: PropTypes.bool.isRequired,
};

export default LinkPeopleModal;
