import { createTheme } from '@material-ui/core/styles';
import 'react-dropzone-uploader/dist/styles.css';
import colors from './colors';

const theme = createTheme({
  typography: {
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    primary: {
      main: '#F16C24',
      contrastText: '#fff',
    },
    secondary: {
      main: colors.rustyRed,
      contrastText: '#fff',
    },
  },
  overrides: {
    MUIDataTable: {
      paper: {
        backgroundColor: '#F8F8F8',
        boxShadow: 'none',
      },
      responsiveBase: {
        padding: '0 24px 0 24px',
      },
    },
    MUIDataTableBodyCell: {
      root: {
        fontWeight: 400,
        fontSize: '12px',
      },
    },
    MUIDataTableHeadCell: {
      root: {
        backgroundColor: '#F8F8F8 !important',
      },
      data: {
        fontWeight: 'bold',
        fontSize: '12px',
      },
    },
    MUIDataTableToolbar: {
      titleText: {
        color: colors.orangeLight,
        fontWeight: 'bold',
      },
    },
    MUIDataTableBody: {
      emptyTitle: {
        fontSize: '12px',
      },
    },
    MuiTablePagination: {
      root: {
        fontSize: '12px',
      },
      caption: {
        fontSize: '12px',
      },
      spacer: {
        MuiTypography: {
          fontSize: '12px',
          fontWeight: 600,
        },
      },
    },
    MuiInputBase: {
      root: {
        width: '210px',
        fontSize: '12px',
      },
    },

    MuiTableRow: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '16px',
      },
    },

  },
});

export default theme;
