const processConstants = {
  CREATE_REQUEST: 'PROCESS_CREATE_REQUEST',
  CREATE_SUCCESS: 'PROCESS_CREATE_SUCCESS',
  CREATE_FAILURE: 'PROCESS_CREATE_FAILURE',

  GET_REQUEST: 'PROCESS_GET_REQUEST',
  GET_SUCCESS: 'PROCESS_GET_SUCCESS',
  GET_FAILURE: 'PROCESS_GET_FAILURE',

  GET_ALL_REQUEST: 'PROCESS_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'PROCESS_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'PROCESS_GET_ALL_FAILURE',

  UPDATE_REQUEST: 'PROCESS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PROCESS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PROCESS_UPDATE_FAILURE',

  DELETE_REQUEST: 'PROCESS_DELETE_REQUEST',
  DELETE_SUCCESS: 'PROCESS_DELETE_SUCCESS',
  DELETE_FAILURE: 'PROCESS_DELETE_FAILURE',

  LINK_INTERESTED_PEOPLE_REQUEST: 'LINK_INTERESTED_PEOPLE_REQUEST',
  LINK_INTERESTED_PEOPLE_SUCCESS: 'LINK_INTERESTED_PEOPLE_SUCCESS',
  LINK_INTERESTED_PEOPLE_FAILURE: 'LINK_INTERESTED_PEOPLE_FAILURE',

  LINK_RESPONSIBLE_PEOPLE_REQUEST: 'LINK_RESPONSIBLE_PEOPLE_REQUEST',
  LINK_RESPONSIBLE_PEOPLE_SUCCESS: 'LINK_RESPONSIBLE_PEOPLE_SUCCESS',
  LINK_RESPONSIBLE_PEOPLE_FAILURE: 'LINK_RESPONSIBLE_PEOPLE_FAILURE',

  UNLINK_INTERESTED_REQUEST: 'UNLINK_INTERESTED_REQUEST',
  UNLINK_INTERESTED_SUCCESS: 'UNLINK_INTERESTED_SUCCESS',
  UNLINK_INTERESTED_FAILURE: 'UNLINK_INTERESTED_FAILURE',

  UNLINK_RESPONSIBLE_REQUEST: 'UNLINK_RESPONSIBLE_REQUEST',
  UNLINK_RESPONSIBLE_SUCCESS: 'UNLINK_RESPONSIBLE_SUCCESS',
  UNLINK_RESPONSIBLE_FAILURE: 'UNLINK_RESPONSIBLE_FAILURE',

  CLEAN_CREATED_PROCESS: 'CLEAN_CREATED_PROCESS',
};

export default processConstants;
