import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '../../controls/Button';
import { history } from '../../../helpers';

const useStyles = makeStyles(() => ({
  continueButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    padding: '0 0 27px',
  },
}));

const CalendarEventDetailButton = ({ eventType, eventId }) => {
  const classes = useStyles();
  const intl = useIntl();

  const entityUrl = {
    objective: `/objectives/${eventId}`,
    risk: `/risks/${eventId}`,
    evaluation: `/evaluations/${eventId}`,
    workfield_evaluation: `/workFieldEvaluations/${eventId}`,
    deviation: `/deviations/${eventId}`,
    training: `/trainings/${eventId}`,
  };

  const buttonTexts = {
    objective: intl.formatMessage({ id: 'calendar.objectiveGoTo', defaultMessage: 'VER OBJETIVO' }),
    training: intl.formatMessage({ id: 'calendar.trainingGoTo', defaultMessage: 'VER CAPACITACIÓN' }),
    evaluation: intl.formatMessage({ id: 'calendar.evaluationGoTo', defaultMessage: 'VER EVALUACIÓN' }),
    deviation: intl.formatMessage({ id: 'calendar.deviationGoTo', defaultMessage: 'VER DESVÍO' }),
    risk: intl.formatMessage({ id: 'calendar.riskGoTo', defaultMessage: 'VER RIESGO' }),
    workfield_evaluation: intl.formatMessage({ id: 'calendar.workfield_evaluationGoTo', defaultMessage: 'VER EVALUACIÓN DEL PROVEEDOR' }),
  };

  return (
    <Box className={classes.continueButtonContainer}>
      <Button
        onClick={() => { history.push(entityUrl[eventType]); }}
        text={buttonTexts[eventType]}
        startIcon={<OpenInNewOutlinedIcon style={{ fontSize: '12px' }} />}
        style={{ padding: '12px 32px' }}
      />
    </Box>
  );
};

CalendarEventDetailButton.propTypes = {
  eventType: PropTypes.string.isRequired,
  eventId: PropTypes.number.isRequired,
};

export default CalendarEventDetailButton;
