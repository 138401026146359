import React from 'react';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  slider: {
    maxWidth: '215px',
  },
  labelPercentage: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    marginRight: '10px',
  },
}));

const CustomSliderWorkField = ({
  itemName, weightingPoint, handleOnChangeWeightingPoints, readOnly, weightPercentage,
}) => {
  const classes = useStyles();

  return (
    <>
      {weightPercentage && (
        <Typography variant="body1" className={classes.labelPercentage}>
          {`${weightPercentage}%`}
        </Typography>
      )}
      <Slider
        defaultValue={30}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={1}
        min={1}
        max={100}
        name={itemName}
        className={classes.slider}
        disabled={readOnly}
        value={weightingPoint}
        onChange={(event, newValue) => {
          handleOnChangeWeightingPoints(newValue, itemName);
        }}
      />
    </>
  );
};

CustomSliderWorkField.propTypes = {
  itemName: PropTypes.string.isRequired,
  weightingPoint: PropTypes.number.isRequired,
  handleOnChangeWeightingPoints: PropTypes.func,
  readOnly: PropTypes.bool,
  weightPercentage: PropTypes.number,
};

CustomSliderWorkField.defaultProps = {
  weightPercentage: 0,
  readOnly: false,
  handleOnChangeWeightingPoints: () => {},
};

export default CustomSliderWorkField;
