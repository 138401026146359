import { companyConstants } from '../../constants';
import { companyService } from '../../services';
import alertActions from './alert.actions';

function get(id) {
  function request() { return { type: companyConstants.GET_REQUEST }; }
  function success(company) { return { type: companyConstants.GET_SUCCESS, company }; }
  function failure(error) { return { type: companyConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(id));

    companyService.get(id)
      .then(
        (company) => {
          dispatch(success(company));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function update(company) {
  function request(companyToUpdate) {
    return { type: companyConstants.UPDATE_REQUEST, companyToUpdate };
  }
  function success(companyUpdated) {
    return { type: companyConstants.UPDATE_SUCCESS, companyUpdated };
  }
  function failure(error, companyToUpdate) {
    return { type: companyConstants.UPDATE_FAILURE, error, companyToUpdate };
  }

  return (dispatch) => {
    dispatch(request(company));

    companyService.update(company)
      .then(
        (companyUpdated) => {
          dispatch(success(companyUpdated));
        },
        (error) => {
          dispatch(failure(error, company));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function updateProcessMap(company) {
  function request(companyToUpdate) {
    return { type: companyConstants.UPDATE_REQUEST, companyToUpdate };
  }
  function success(companyUpdated) {
    return { type: companyConstants.UPDATE_SUCCESS, companyUpdated };
  }
  function failure(error, companyToUpdate) {
    return { type: companyConstants.UPDATE_FAILURE, error, companyToUpdate };
  }

  return (dispatch) => {
    dispatch(request(company));

    companyService.updateProcessMap(company)
      .then(
        (companyUpdated) => {
          dispatch(success(companyUpdated));
        },
        (error) => {
          dispatch(failure(error, company));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function updateOrganizationChart(company) {
  function request(companyToUpdate) {
    return { type: companyConstants.UPDATE_REQUEST, companyToUpdate };
  }
  function success(companyUpdated) {
    return { type: companyConstants.UPDATE_SUCCESS, companyUpdated };
  }
  function failure(error, companyToUpdate) {
    return { type: companyConstants.UPDATE_FAILURE, error, companyToUpdate };
  }

  return (dispatch) => {
    dispatch(request(company));

    companyService.updateOrganizationChart(company)
      .then(
        (companyUpdated) => {
          dispatch(success(companyUpdated));
        },
        (error) => {
          dispatch(failure(error, company));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function setFirstLogin(companyId) {
  function request() { return { type: companyConstants.FIRST_LOGIN_COMPANY_REQUEST }; }
  function success() { return { type: companyConstants.FIRST_LOGIN_COMPANY_SUCCESS }; }
  function failure(error) { return { type: companyConstants.FIRST_LOGIN_COMPANY_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    companyService.setFirstLogin()
      .then(
        () => {
          dispatch(success());
          dispatch(get(companyId));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

const companyActions = {
  get,
  update,
  setFirstLogin,
  updateProcessMap,
  updateOrganizationChart,
};

export default companyActions;
