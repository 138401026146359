import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircle, Error, Cancel } from '@material-ui/icons';
import { workFieldHelper } from '../../helpers';

const IconStatus = ({ status, ...rest }) => {
  switch (status) {
    case workFieldHelper.statusEnum.APPROVED:
      return <CheckCircle {...rest} />;
    case workFieldHelper.statusEnum.REJECTED:
      return <Cancel {...rest} />;
    case workFieldHelper.statusEnum.CONDITIONAL:
      return <Error {...rest} />;
    default:
      return <Error {...rest} />;
  }
};
IconStatus.propTypes = {
  status: PropTypes.number.isRequired,
};

export default IconStatus;
