import React from 'react';
import {
  AppBar,
  Box,
  makeStyles,
  IconButton,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Home from './Home/LoggedInLayout';
import Button from './controls/Button';
import TutorialInfo from './controls/TutorialInfo';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  breadcrumbs: {
    fontWeight: 700,
    fontSize: '11px',
    paddingLeft: '24px',
    position: 'relative',
    zIndex: 2,
    top: '16px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  toolbar: {
    backgroundColor: '#F8F8F8',
    zIndex: 1,
    paddingLeft: theme.spacing(2),
  },
  toolbarTitle: {
    color: '#F79A56',
    fontSize: '18px',
    fontWeight: 700,
    display: 'inline',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    backgroundColor: '#F8F8F8',
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(2),
      paddingTop: 0,
    },
  },
}));

const PageLayout = ({
  addButtonText,
  addButtonTo,
  breadCrumbs,
  buttonGroup,
  children,
  editable,
  iconVideoTutorial,
  isProcessDetailsPage,
  onEditClick,
  title
}) => {
  const classes = useStyles();
  const step = useSelector((state) => state.tutorial.step);
  const location = useLocation();

  return (
    <Home>
      <div className={classes.main}>
        <AppBar elevation={0} className={classes.toolbar} position="static">
          <Box className={classes.breadcrumbs}>
            {breadCrumbs}
          </Box>
          <Toolbar>
            <Box alignItems="center" display="flex" flexGrow={1}>
              <Typography className={classes.toolbarTitle} variant="h6">
                <strong>{title}</strong>
              </Typography>
              {editable && (
                <IconButton onClick={onEditClick}>
                  <Tooltip title="Editar">
                    <Edit style={{ width: 16, height: 16, color: '#F79A56' }} />
                  </Tooltip>
                </IconButton>
              )}
              {Boolean(iconVideoTutorial) && (
                <Box ml={5}>
                  {iconVideoTutorial}
                </Box>
              )}
            </Box>
            {step === 3 && location.pathname === '/processes/new' && (
              <Box flexGrow={1}>
                <TutorialInfo step={3} />
              </Box>
            )}
            {step === 4 && isProcessDetailsPage && (
              <Box flexGrow={1}>
                <TutorialInfo step={4} />
              </Box>
            )}
            {buttonGroup}
            {addButtonText
              && (
                <Button
                  text={addButtonText}
                  component={Link}
                  to={addButtonTo}
                />
              )}
          </Toolbar>
        </AppBar>
        <div className={classes.layout}>
          <Paper className={classes.paper} elevation={0}>
            <>
              {children}
            </>
          </Paper>
        </div>
      </div>
    </Home>
  );
};

export default PageLayout;
