import { trainingConstants } from '../../constants';

function training(state = {}, action) {
  switch (action.type) {
    case trainingConstants.CREATE_REQUEST:
      return { creating: true };
    case trainingConstants.CREATE_SUCCESS:
      return { createdTraining: action.createdTraining };
    case trainingConstants.CREATE_FAILURE:
      return { creating: false };
    case trainingConstants.GET_REQUEST:
      return { requesting: true };
    case trainingConstants.GET_SUCCESS:
      return { data: action.training };
    case trainingConstants.GET_FAILURE:
      return {};
    case trainingConstants.GET_ALL_REQUEST:
      return { requesting: true };
    case trainingConstants.GET_ALL_SUCCESS:
      return { training: action.training };
    case trainingConstants.GET_ALL_FAILURE:
      return {};
    case trainingConstants.UPDATE_REQUEST:
      return { updating: true };
    case trainingConstants.UPDATE_SUCCESS:
      return { data: action.updatedTraining };
    case trainingConstants.UPDATE_FAILURE:
    case trainingConstants.CLEAN_CREATED_PROCESS:
      return {};
    case trainingConstants.DELETE_REQUEST:
      return { deleting: true };
    case trainingConstants.DELETE_SUCCESS:
      return { };
    case trainingConstants.DELETE_FAILURE:
      return {};
    default:
      return state;
  }
}

export default training;
