import axios from '../axios/axios';
import { authHeader, userCompanySelectorHelper } from '../helpers';
import handler from './handler';

function create(workField) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `/companies/${company.id}/workField`;
  return axios.post(postUrl, workField, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function get(id) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/workField/${id}`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAll() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/workField`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function update(workFieldId, workField) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const putUrl = `/companies/${company.id}/workField/${workFieldId}`;
  return axios.put(putUrl, workField, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function deleteWorkField(workFieldId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const deleteUrl = `/companies/${company.id}/workField/${workFieldId}`;
  return axios.delete(deleteUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const workFieldService = {
  create,
  get,
  getAll,
  update,
  deleteWorkField,
};

export default workFieldService;
