import dispatch from './dispatch';
import { store } from './store';
import { userActions } from '../redux/actions';

function getSelectedCompany(fullUser, companyId) {
  const companySelected = fullUser.companies.find(
    (company) => String(company.id) === String(companyId),
  );
  return {
    ...fullUser,
    company: companySelected,
    roles: companySelected.roles,
  };
}

function getCurrentCompany() {
  const { authentication } = store.getState();
  if (!authentication.user) return dispatch(userActions.logout());
  return authentication.user.company;
}

function getUserDataFromCurrentCompany(fullUser) {
  const currentCompany = getCurrentCompany();
  const userCurrentCompany = fullUser.companies.find((company) => company.id === currentCompany.id);
  return {
    ...fullUser,
    roles: userCurrentCompany.roles,
    person: userCurrentCompany.person,
  };
}

const userCompanySelectorHelper = {
  getSelectedCompany, getCurrentCompany, getUserDataFromCurrentCompany,
};
export default userCompanySelectorHelper;
