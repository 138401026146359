import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2)
    },
    closeIcon: {
        height: 24,
        width: 24
    },
    closeIconContainer: {
        margin: '10px',
        position: 'absolute',
        right: 0,
        top: 0
    },
    dialog: {
        padding: '20px 60px 20px 60px',
        height: '80dvh'
    },
}));

export default useStyles;
