import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs, IconButton, Tooltip, Typography, makeStyles,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { useDispatch, useSelector } from 'react-redux';
import PageLayout from '../../PageLayout';
import Button from '../../controls/Button';
import DataTable from '../../DataTable/DataTable';
import { history } from '../../../helpers';
import { workFieldActions } from '../../../redux/actions';
import { providersTexts, workFieldTexts } from '../../../constants';
import colors from '../../../assets/colors';

const useStyles = makeStyles(() => ({
  tableBody: {
    color: 'black',
    textDecoration: 'none',
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  breadcrumbs: {
    textDecoration: 'none',
    fontWeight: 400,
    fontSize: 11,
  },
}));

const WorkFieldPage = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.authentication.user);
  const deletingWorkField = useSelector((state) => state.workField.deleting);
  const deletedWorkField = useSelector((state) => state.workField.deleted);
  const isAdmin = userAuth.roles.includes('Admin');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [workFieldID, setWorkFieldID] = useState(null);

  useEffect(() => {
    if (deletedWorkField) {
      setOpenDeleteDialog(false);
    }
  }, [deletedWorkField]);

  function toggleDeleteDialog() {
    setOpenDeleteDialog(!openDeleteDialog);
  }

  function handleDeleteWorkFields(id) {
    dispatch(workFieldActions.deleteWorkField(id, workFieldTexts.successfulDelete));
  }

  function WorkFieldPageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography component={Link} to="/providers" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {providersTexts.providers}
        </Typography>
        <Typography component={Link} to="/workFields" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {workFieldTexts.workFields}
        </Typography>
      </Breadcrumbs>
    );
  }

  function ButtonGroup() {
    if (!isAdmin) return null;
    return (
      <Button
        text={workFieldTexts.addButton}
        component={Link}
        to="workFields/new"
        style={{ fontSize: '11px', fontWeight: 600 }}
      />
    );
  }

  function actionsButtons(link, id) {
    if (!isAdmin) return null;
    return (
      <>
        <IconButton onClick={() => history.push(link)} style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}>
          <Tooltip title={workFieldTexts.updateWorkField}>
            <CreateIcon style={{ width: 16, height: 16, color: colors.orangeLight }} />
          </Tooltip>
        </IconButton>
        <IconButton onClick={() => { setWorkFieldID(id); toggleDeleteDialog(); }} style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}>
          <Tooltip title={workFieldTexts.deleteTitleDialog}>
            <DeleteRoundedIcon style={{ width: 16, height: 16, color: colors.greyLight }} />
          </Tooltip>
        </IconButton>
      </>
    );
  }

  const workFieldFrequencyLabel = {
    monthly: { key: 'monthly', label: intl.formatMessage({ id: 'objective.monthly', defaultMessage: 'Mensual' }) },
    bimonthly: { key: 'bimonthly', label: intl.formatMessage({ id: 'objective.bimonthly', defaultMessage: 'Bimestral' }) },
    quarterly: { key: 'quarterly', label: intl.formatMessage({ id: 'objective.quarterly', defaultMessage: 'Trimestral' }) },
    fourMonthly: { key: 'fourMonthly', label: intl.formatMessage({ id: 'objective.fourMonthly', defaultMessage: 'Cuatrimestral' }) },
    biannual: { key: 'biannual', label: intl.formatMessage({ id: 'objective.biannual', defaultMessage: 'Semestral' }) },
    yearly: { key: 'yearly', label: intl.formatMessage({ id: 'objective.yearly', defaultMessage: 'Anual' }) },
  };

  const columns = [{
    name: 'id',
    options: {
      display: 'excluded',
      filter: false,
    },
  }, {
    name: 'name',
    label: intl.formatMessage({ id: 'app.name', defaultMessage: 'Nombre' }),
    options: {
      customBodyRender: (value, meta) => {
        const nameId = meta.rowData[0];
        const textTitle = (
          <Typography className={classes.tableBody} component={Link} to={`/workFields/${nameId}`}>
            {value}
          </Typography>
        );
        return textTitle;
      },
    },
  }, {
    name: 'workFieldItems',
    label: intl.formatMessage({ id: 'workField.itemsToBeEvaluated', defaultMessage: 'Ítems a evaluar' }),
    options: {
      customBodyRender: (workFieldItems) => {
        const parsedWorkFieldItems = JSON.parse(workFieldItems);
        if (parsedWorkFieldItems.length > 0) {
          const workFieldItemsString = parsedWorkFieldItems.map((item) => (` ${item.name}`));
          return workFieldItemsString.join();
        }
        return '-';
      },
      customDownloadBodyRender: (workFieldItems) => {
        const parsedWorkFieldItems = JSON.parse(workFieldItems);
        if (parsedWorkFieldItems.length > 0) {
          const workFieldItemsString = parsedWorkFieldItems.map((item) => (` ${item.name}`));
          return workFieldItemsString.join();
        }
        return '-';
      },
    },
  }, {
    name: 'frequency',
    label: intl.formatMessage({ id: 'workField.frequency', defaultMessage: 'Frecuencia de medición' }),
    options: {
      customBodyRender: (value, meta) => {
        const id = meta.rowData[0];
        return (
          <Typography className={classes.tableBody} onClick={() => history.push(`/workFields/${id}`)}>
            {workFieldFrequencyLabel[value]
              ? workFieldFrequencyLabel[value].label
              : '-'}
          </Typography>
        );
      },
      filterOptions: {
        renderValue: (value) => {
          if (workFieldFrequencyLabel[value]) { return workFieldFrequencyLabel[value].label; }
          return '-';
        },
      },
      customFilterListOptions: {
        render: (value) => {
          if (workFieldFrequencyLabel[value]) { return workFieldFrequencyLabel[value].label; }
          return '-';
        },
      },
      customDownloadBodyRender: (value) => {
        if (workFieldFrequencyLabel[value]) { return workFieldFrequencyLabel[value].label; }
        return '-';
      },
    },
  }, {
    name: '',
    options: {
      filter: false,
      sort: false,
      download: false,
      customBodyRender: (value, meta) => {
        const workFieldId = meta.rowData[0];
        const link = `/workFields/${workFieldId}/edit`;
        return actionsButtons(link, workFieldId);
      },
    },
  }];

  const keys = ['id', 'name', 'workFieldItems', 'frequency'];
  const fetchUrl = `companies/${userAuth.company.id}/workField/`;
  const itemUrl = 'workFields/';

  return (
    <PageLayout
      title={workFieldTexts.workFields}
      breadCrumbs={<WorkFieldPageBreadcrumbs />}
      buttonGroup={<ButtonGroup />}
    >
      <DataTable
        title={workFieldTexts.workFields}
        fetchUrl={fetchUrl}
        itemUrl={itemUrl}
        keys={keys}
        columns={columns}
      />
      <Dialog open={openDeleteDialog} onClose={() => toggleDeleteDialog()} fullWidth maxWidth="xs" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{workFieldTexts.deleteTitleDialog}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {workFieldTexts.deleteContentDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button text={workFieldTexts.cancel} onClick={() => toggleDeleteDialog()} />
          <Button
            text={workFieldTexts.delete}
            color="secondary"
            loading={deletingWorkField}
            onClick={() => { handleDeleteWorkFields(workFieldID); }}
          />
        </DialogActions>
      </Dialog>

    </PageLayout>
  );
};

export default WorkFieldPage;
