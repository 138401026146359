import React from 'react';
import { SvgIcon } from '@material-ui/core';

function PlayYouTubeIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 15 15">
      <path d="M1.83335 2.83333H0.416687V12.75C0.416687 13.5292 1.05419 14.1667 1.83335 14.1667H11.75V12.75H1.83335V2.83333ZM13.1667 0H4.66669C3.88752 0 3.25002 0.6375 3.25002 1.41667V9.91667C3.25002 10.6958 3.88752 11.3333 4.66669 11.3333H13.1667C13.9459 11.3333 14.5834 10.6958 14.5834 9.91667V1.41667C14.5834 0.6375 13.9459 0 13.1667 0ZM7.50002 8.85417V2.47917L11.75 5.66667L7.50002 8.85417Z" />
    </SvgIcon>
  );
}

export default PlayYouTubeIcon;
