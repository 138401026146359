import { userConstants } from '../../constants';
import { userService } from '../../services';
import affiliateAction from './affiliate.actions';
import alertActions from './alert.actions';
import { userCompanySelectorHelper, history } from '../../helpers';

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function register(user) {
  function request(userReq) { return { type: userConstants.REGISTER_REQUEST, userReq }; }
  function success(userSuccess) { return { type: userConstants.REGISTER_SUCCESS, userSuccess }; }
  function failure(error) { return { type: userConstants.REGISTER_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ user }));

    userService.register(user)
      .then(
        () => {
          dispatch(success(user));
          dispatch(alertActions.info('Se ha enviado un link de confirmación de registro a su casilla'));
          
          if (localStorage.getItem('affiliate')) localStorage.removeItem('affiliate');
          if (localStorage.getItem('utm_source')) localStorage.removeItem('utm_source');

          history.push('/login');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function confirm(email, token, affiliateId, companyId, messageSuccess) {
  function request() { return { type: userConstants.REGISTER_CONFIRM_REQUEST }; }
  function success() { return { type: userConstants.REGISTER_CONFIRM_SUCCESS }; }
  function failure() { return { type: userConstants.REGISTER_CONFIRM_FAILURE }; }

  return (dispatch) => {
    dispatch(request({ email }));

    userService.confirm(email, token, affiliateId, companyId)
      .then(
        () => {
          dispatch(success());
          dispatch(alertActions.success(messageSuccess));
          setTimeout(() => {
            if (affiliateId) {
              localStorage.removeItem('affiliate');
            }
            const currentUser = localStorage.getItem('user');
            if (currentUser && currentUser.email !== email) {
              dispatch(logout());
            }
            history.push('/login');
          }, 4000);
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function login(username, password) {
  function request(user) { return { type: userConstants.LOGIN_REQUEST, user }; }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user }; }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(username, password)
      .then(
        (user) => {
          dispatch(success(user));
          if (localStorage.getItem('utm_source')) localStorage.removeItem('utm_source');
          history.push('/company');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function googleLogin(googleToken) {
  function request(token) { return { type: userConstants.GOOGLE_LOGIN_REQUEST, token }; }
  function success(user) { return { type: userConstants.GOOGLE_LOGIN_SUCCESS, user }; }
  function failure(error) { return { type: userConstants.GOOGLE_LOGIN_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(googleToken));

    userService.googleLogin(googleToken)
      .then(
        (user) => {
          dispatch(success(user));
          history.push('/company');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function setCompanySelectedForImpersonation(impersonated, selectedCompanyId) {
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user }; }

  const impersonator = JSON.parse(localStorage.getItem('user'));
  const impersonatedUser = userCompanySelectorHelper.getSelectedCompany(
    impersonated,
    selectedCompanyId,
  );

  localStorage.setItem('impersonator', JSON.stringify(impersonator));
  localStorage.setItem('user', JSON.stringify(impersonatedUser));

  return (dispatch) => {
    dispatch(success(impersonatedUser));
  };
}

function getOutCompanySelectedForImpersonation(impersonator, companyIdPreviouslySelected) {
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user }; }

  const user = userCompanySelectorHelper.getSelectedCompany(
    impersonator,
    companyIdPreviouslySelected,
  );
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.removeItem('impersonator');

  return (dispatch) => {
    dispatch(success(user));
  };
}

function setCompanySelected(fullUser, selectedCompanyId, successfulAffiliated) {
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user }; }
  const user = userCompanySelectorHelper.getSelectedCompany(fullUser, selectedCompanyId);
  localStorage.setItem('user', JSON.stringify(user));

  return (dispatch) => {
    const affiliate = localStorage.getItem('affiliate');
    if (affiliate) {
      dispatch(affiliateAction.setAffiliate(affiliate, user.company.id, successfulAffiliated));
    }
    dispatch(success(user));
  };
}

function setCompany(company, companyId, successfulAffiliated) {
  function success(user) { return { type: userConstants.CREATE_COMPANY_SUCCESS, user }; }
  function failure(error) { return { type: userConstants.CREATE_COMPANY_FAILURE, error }; }

  return (dispatch) => {
    userService.setCompany(company)
      .then(
        (user) => {
          dispatch(success(user));
          setCompanySelected(user, companyId, successfulAffiliated);
          if (localStorage.getItem('utm_source')) localStorage.removeItem('utm_source');
          window.location.reload();
        },
        (error) => {
          history.push('/login');
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function forgotPassword(email) {
  return (dispatch) => {
    userService.forgotPassword(email)
      .then(
        () => {
          dispatch(alertActions.info('Se ha enviado un link de reseteo de contraseña a su casilla'));
          history.push('/login');
        },
        (error) => {
          dispatch(alertActions.error(error));
          history.push('/login');
        },
      );
  };
}

function resetPassword(email, newPassword, token) {
  return (dispatch) => {
    userService.resetPassword(email, newPassword, token)
      .then(
        () => {
          dispatch(alertActions.success('Contraseña cambiada correctamente'));
          history.push('/login');
        },
        (error) => {
          dispatch(alertActions.error(error));
        },
      );
  };
}

function updatePassword(email, password, newPassword, messageSuccess) {
  function request() { return { type: userConstants.UPDATE_PASSWORD_REQUEST }; }
  function success() { return { type: userConstants.UPDATE_PASSWORD_SUCCESS }; }
  function failure(error) { return { type: userConstants.UPDATE_PASSWORD_FAILURE, error }; }
  function clearRegistration() { return { type: userConstants.REGISTER_CONFIRM_CLEAR }; }

  return (dispatch) => {
    dispatch(request());

    userService.updatePassword(email, password, newPassword)
      .then(
        () => {
          dispatch(alertActions.success(messageSuccess));
          dispatch(clearRegistration());
          dispatch(success());
          history.push('/home');
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        },
      );
  };
}

function updatePasswordAndMustChange(email, password, newPassword, messageSuccess, user) {
  function request() { return { type: userConstants.UPDATE_PASSWORD_REQUEST }; }
  function success() { return { type: userConstants.UPDATE_PASSWORD_SUCCESS }; }
  function failure(error) { return { type: userConstants.UPDATE_PASSWORD_FAILURE, error }; }
  function successMustChangePassword() {
    localStorage.setItem('user', JSON.stringify(user));
    return { type: userConstants.UPDATE_MUST_CHANGE_PASSWORD_SUCCESS, user };
  }
  function clearRegistration() { return { type: userConstants.REGISTER_CONFIRM_CLEAR }; }

  return (dispatch) => {
    dispatch(request());

    userService.updatePassword(email, password, newPassword)
      .then(
        () => {
          dispatch(alertActions.success(messageSuccess));
          dispatch(clearRegistration());
          dispatch(successMustChangePassword());
          dispatch(success());
          history.push('/home');
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        },
      );
  };
}

function create(user, redirectUrl = null) {
  function request(userToCreate) { return { type: userConstants.CREATE_REQUEST, userToCreate }; }
  function success(createdUser) { return { type: userConstants.CREATE_SUCCESS, createdUser }; }
  function failure(error) { return { type: userConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ user }));

    userService.create(user)
      .then(
        (createdUser) => {
          dispatch(success(createdUser));
          if (redirectUrl) { history.replace(`${redirectUrl}${createdUser.id}`); }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function createBulkUsers(usersExcelFile) {
  function request() { return { type: userConstants.CREATE_BULK_REQUEST }; }
  function success(bulkCreationPreviewResult) {
    return { type: userConstants.CREATE_BULK_SUCCESS, bulkCreationPreviewResult };
  }
  function failure(error) { return { type: userConstants.CREATE_BULK_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    userService.createBulkUsers(usersExcelFile)
      .then(
        (bulkCreationPreviewResult) => {
          dispatch(success(bulkCreationPreviewResult));
          history.push('/people/result');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },

      );
  };
}

function confirmCreateBulkUsers(id, messageSuccess) {
  function request() { return { type: userConstants.CONFIRM_CREATE_BULK_REQUEST }; }
  function success() { return { type: userConstants.CONFIRM_CREATE_BULK_SUCCESS }; }
  function failure(error) { return { type: userConstants.CONFIRM_CREATE_BULK_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    userService.confirmCreateBulkUsers(id)
      .then(
        () => {
          dispatch(success());
          dispatch(alertActions.success(messageSuccess));
          history.push('/people');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}
function get(id) {
  function request() { return { type: userConstants.GET_REQUEST }; }
  function success(userData) {
    const user = userCompanySelectorHelper.getUserDataFromCurrentCompany(userData);
    return { type: userConstants.GET_SUCCESS, user };
  }
  function failure(error) { return { type: userConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    userService.get(id)
      .then(
        (user) => {
          dispatch(success(user));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
          history.push('/users');
        },
      );
  };
}

function getAll() {
  function request() { return { type: userConstants.GET_ALL_REQUEST }; }
  function success(users) { return { type: userConstants.GET_ALL_SUCCESS, users }; }
  function failure(error) { return { type: userConstants.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    userService.getAll()
      .then(
        (users) => {
          dispatch(success(users));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
          history.push('/home');
        },
      );
  };
}
function update(user, redirectUrl = null) {
  function request(userToUpdate) {
    return {
      type: userConstants.UPDATE_REQUEST, userToUpdate,
    };
  }
  function success(userData) {
    const updatedUser = userCompanySelectorHelper.getUserDataFromCurrentCompany(userData);
    return { type: userConstants.UPDATE_SUCCESS, updatedUser };
  }
  function failure(error) { return { type: userConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(user));

    userService.update(user)
      .then(
        (updatedUser) => {
          dispatch(success(updatedUser));
          if (redirectUrl) { history.replace(`${redirectUrl}${updatedUser.id}`); }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function deleteUser(userId) {
  function request() { return { type: userConstants.DELETE_REQUEST }; }
  function success() { return { type: userConstants.DELETE_SUCCESS }; }
  function failure() { return { type: userConstants.DELETE_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    userService.deleteUser(userId)
      .then(
        () => {
          dispatch(success());
          history.push('/users');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function requestResendEmail(email) {
  return (dispatch) => {
    userService.requestResendEmail(email)
      .then(
        () => {
          dispatch(alertActions.info('Se ha enviado un link de confirmación de registro a su casilla'));
          history.push('/login');
        },
        (error) => {
          dispatch(alertActions.error(error));
        },
      );
  };
}

function updateTutorialComplete() {
  const currentUser = localStorage.getItem('user');
  if (currentUser) {
    const newUser = {
      ...JSON.parse(currentUser),
      isFirstLogin: false,
    }
    localStorage.setItem('user', JSON.stringify(newUser));
  }

  function update() { return { type: userConstants.UPDATE_TUTORIAL_COMPLETE } }

  return (dispatch) => {
    dispatch(update());
  }
}

function clear() {
  return { type: userConstants.CLEAR };
}

const userActions = {
  register,
  confirm,
  setCompany,
  setCompanySelected,
  setCompanySelectedForImpersonation,
  getOutCompanySelectedForImpersonation,
  login,
  googleLogin,
  logout,
  forgotPassword,
  resetPassword,
  updatePassword,
  create,
  createBulkUsers,
  confirmCreateBulkUsers,
  get,
  getAll,
  update,
  updatePasswordAndMustChange,
  deleteUser,
  clear,
  requestResendEmail,
  updateTutorialComplete,
};

export default userActions;
