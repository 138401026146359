const fodaConstants = {
  GET_REQUEST: 'FODA_GET_REQUEST',
  GET_SUCCESS: 'FODA_GET_SUCCESS',
  GET_FAILURE: 'FODA_GET_FAILURE',

  UPDATE_REQUEST: 'FODA_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'FODA_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'FODA_UPDATE_FAILURE',

  FORTRESS_TYPE: 0,
  OPPORTUNITIES_TYPE: 1,
  WEAKNESSES_TYPE: 2,
  THREATS_TYPE: 3,
};

export default fodaConstants;
