import { stripeConstants } from '../../constants';
import { stripeService } from '../../services';
import alertActions from './alert.actions';
import companyActions from './company.actions';

function getCards() {
  function request() { return { type: stripeConstants.request.GET_CARDS_REQUEST }; }
  function success(cards) { return { type: stripeConstants.request.GET_CARDS_SUCCESS, cards }; }
  function failure(error) { return { type: stripeConstants.request.GET_CARDS_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    stripeService.getCards()
      .then(
        (cards) => {
          dispatch(success(cards));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getAllowedActions({ fullAllowedActions = false }) {
  function request() { return { type: stripeConstants.request.GET_ALLOWED_ACTION_REQUEST }; }
  function success(list) {
    return { type: stripeConstants.request.GET_ALLOWED_ACTION_SUCCESS, list };
  }
  function failure(error) {
    return { type: stripeConstants.request.GET_ALLOWED_ACTION_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    stripeService.getAllowedActions({ fullAllowedActions })
      .then(
        (list) => {
          dispatch(success(list));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function cancelSubscriptionNotified(companyId) {
  return (dispatch) => {
    stripeService.cancelSubscriptionNotified()
      .then(
        () => {
          dispatch(companyActions.get(companyId));
        },
        (error) => {
          dispatch(alertActions.error(error));
        },
      );
  };
}

const stripeActions = {
  getCards, getAllowedActions, cancelSubscriptionNotified,
};

export default stripeActions;
