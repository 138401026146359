const workFieldConstants = {
  CREATE_REQUEST: 'WORK_FIELD_CREATE_REQUEST',
  CREATE_SUCCESS: 'WORK_FIELD_CREATE_SUCCESS',
  CREATE_FAILURE: 'WORK_FIELD_CREATE_FAILURE',

  GET_REQUEST: 'WORK_FIELD_GET_REQUEST',
  GET_SUCCESS: 'WORK_FIELD_GET_SUCCESS',
  GET_FAILURE: 'WORK_FIELD_GET_FAILURE',

  GET_ALL_REQUEST: 'WORK_FIELD_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'WORK_FIELD_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'WORK_FIELD_GET_ALL_FAILURE',

  UPDATE_REQUEST: 'WORK_FIELD_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'WORK_FIELD_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'WORK_FIELD_UPDATE_FAILURE',

  DELETE_REQUEST: 'WORK_FIELD_DELETE_REQUEST',
  DELETE_SUCCESS: 'WORK_FIELD_DELETE_SUCCESS',
  DELETE_FAILURE: 'WORK_FIELD_DELETE_FAILURE',
};

export default workFieldConstants;
