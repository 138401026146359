const impersonateConstants = {
  POST_REQUEST: 'IMPERSONATE_POST_REQUEST',
  POST_SUCCESS: 'IMPERSONATE_POST_SUCCESS',
  POST_FAILURE: 'IMPERSONATE_POST_FAILURE',

  STOP_IMPERSONATING_REQUEST: 'STOP_IMPERSONATING_REQUEST',
  STOP_IMPERSONATING_SUCCESS: 'STOP_IMPERSONATING_SUCCESS',
  STOP_IMPERSONATING_FAILURE: 'STOP_IMPERSONATING_FAILURE',
};

export default impersonateConstants;
