import { outlineConstants } from '../../constants';

function outline(state = {}, action) {
  switch (action.type) {
    case outlineConstants.CREATE_REQUEST:
      return { creating: true };
    case outlineConstants.CREATE_SUCCESS:
      return { createdOutline: action.createdOutline };
    case outlineConstants.CREATE_FAILURE:
      return { error: action.error };

    case outlineConstants.GET_REQUEST:
      return { getting: true };
    case outlineConstants.GET_SUCCESS:
      return { data: action.outline };
    case outlineConstants.GET_FAILURE:
      return { error: action.error };

    case outlineConstants.GET_ALL_REQUEST:
      return { getting: true };
    case outlineConstants.GET_ALL_SUCCESS:
      return { outlines: action.outlines };
    case outlineConstants.GET_ALL_FAILURE:
      return { error: action.error };

    case outlineConstants.UPDATE_REQUEST:
      return { getting: true };
    case outlineConstants.UPDATE_SUCCESS:
      return { data: action.updatedOutline };
    case outlineConstants.UPDATE_FAILURE:
      return { error: action.error };

    case outlineConstants.DELETE_REQUEST:
      return { deleting: true };
    case outlineConstants.DELETE_SUCCESS:
      return { };
    case outlineConstants.DELETE_FAILURE:
      return {};

    default:
      return state;
  }
}

export default outline;
