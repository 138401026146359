import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Breadcrumbs, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DataTable from '../../DataTable/DataTable';
import PageLayout from '../../PageLayout';
import Button from '../../controls/Button';
import { trainingActions } from '../../../redux/actions';
import { history } from '../../../helpers';

function TrainingPage() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'training.trainingsText', defaultMessage: 'Capacitaciones' });
  const user = useSelector((state) => state.authentication.user);
  const isAdmin = user.roles.includes('Admin');
  const initialFieldValues = { trainingId: '', deleteTrainingDialog: false };
  const [values, setValues] = useState(initialFieldValues);
  const { trainingId, deleteTrainingDialog } = values;
  const deleteTrainingTitle = intl.formatMessage({ id: 'training.delete.title', defaultMessage: 'Eliminar capacitación' });
  const editTrainingTitle = intl.formatMessage({ id: 'training.edit.title', defaultMessage: 'Modificar capacitación' });
  const deleteTrainingText = intl.formatMessage({ id: 'training.deleteTrainingText', defaultMessage: '¿Está seguro que quiere eliminar la capacitación?' });
  const deletingTraining = useSelector((state) => state.training.deleting);
  const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const deleteButtonText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'Eliminar' });
  const addButtonText = intl.formatMessage({ id: 'training.addTraining', defaultMessage: '+ Agregar capacitación' });

  const transformTrainingStatus = {
    0: intl.formatMessage({ id: 'training.planned', defaultMessage: 'Planificada' }),
    1: intl.formatMessage({ id: 'training.cancelled', defaultMessage: 'Cancelada' }),
    2: intl.formatMessage({ id: 'training.made', defaultMessage: 'Realizada' }),
    3: intl.formatMessage({ id: 'training.finished', defaultMessage: 'Finalizada' }),
  };

  function handleDialogOpen(dialogName, id = null) {
    if (id) {
      setValues({
        trainingId: id,
        [dialogName]: true,
      });
    } else {
      setValues({
        ...values,
        [dialogName]: true,
      });
    }
  }

  function actionsButtons(link, id) {
    if (!isAdmin) return null;
    return (
      <>
        <IconButton onClick={() => { history.push(link); }} style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}>
          <Tooltip title={editTrainingTitle}>
            <CreateIcon style={{ width: 16, height: 16, color: '#F79A56' }} />
          </Tooltip>
        </IconButton>

        <IconButton onClick={() => { handleDialogOpen('deleteTrainingDialog', id); }} style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}>
          <Tooltip title={deleteTrainingTitle}>
            <DeleteRoundedIcon style={{ width: 16, height: 16, color: '#727272' }} />
          </Tooltip>
        </IconButton>
      </>
    );
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'title',
      label: intl.formatMessage({ id: 'app.title', defaultMessage: 'Título' }),
    },
    {
      name: 'dateFrom',
      label: intl.formatMessage({ id: 'app.date_start', defaultMessage: 'Fecha de inicio' }),
      options: {
        customBodyRender: (date) => {
          if (date === '-') return date;
          return moment(date).format('DD/MM/YYYY');
        },
        sortCompare: (order) => (obj1, obj2) => {
          const val1 = obj1.data;
          const val2 = obj2.data;
          return (val1 > val2 && order === 'asc') ? 1 : -1;
        },
        customDownloadBodyRender: (value) => moment(value).format('DD/MM/YYYY'),
      },
    },
    {
      name: 'dateTo',
      label: intl.formatMessage({ id: 'app.date_end', defaultMessage: 'Fecha de fin' }),
      options: {
        customBodyRender: (date) => {
          if (date === '-') return date;
          return moment(date).format('DD/MM/YYYY');
        },
        sortCompare: (order) => (obj1, obj2) => {
          const val1 = obj1.data;
          const val2 = obj2.data;
          return (val1 > val2 && order === 'asc') ? 1 : -1;
        },
        customDownloadBodyRender: (value) => moment(value).format('DD/MM/YYYY'),
      },
    },
    {
      name: 'status',
      label: intl.formatMessage({ id: 'app.status', defaultMessage: 'Estado' }),
      options: {
        customBodyRender: (value) => transformTrainingStatus[value],
        customDownloadBodyRender: (value) => transformTrainingStatus[value],
      },
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, meta) => {
          const id = meta.rowData[0];
          const link = `/trainings/${id}/edit`;
          return actionsButtons(link, id);
        },
      },
    },
  ];

  const keys = ['id', 'title', 'dateFrom', 'dateTo', 'status'];
  const fetchUrl = `companies/${user.company.id}/trainings/`;
  const itemUrl = 'trainings/';

  function handleDialogClose(dialogName) {
    setValues({
      ...values,
      [dialogName]: false,
    });
  }

  function deleteTraining(id) {
    dispatch(trainingActions.deleteTraining(id));
    handleDialogClose('deleteTrainingDialog');
  }

  function TrainingPageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/trainings" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{title}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  function ButtonGroup() {
    if (user.company.roles.includes('Admin')) {
      return (
        <Button
          text={addButtonText}
          component={Link}
          to="trainings/new"
          style={{ fontSize: '11px', fontWeight: 600 }}
        />
      );
    }
    return null;
  }

  return (
    <PageLayout
      title={title}
      breadCrumbs={<TrainingPageBreadcrumbs />}
      buttonGroup={<ButtonGroup />}
    >
      <DataTable
        title={title}
        fetchUrl={fetchUrl}
        itemUrl={itemUrl}
        keys={keys}
        columns={columns}
      />
      <Dialog open={deleteTrainingDialog} onClose={() => { handleDialogClose('deleteTrainingDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{deleteTrainingTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {deleteTrainingText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button text={cancelButtonText} onClick={() => { handleDialogClose('deleteTrainingDialog'); }} />
          <Button
            text={deleteButtonText}
            color="secondary"
            loading={deletingTraining}
            onClick={() => { deleteTraining(trainingId); }}
          />
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
}

export default TrainingPage;
