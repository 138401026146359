import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { exampleCompanyActions } from '../../../redux/actions';
import ScreenWelcomeModal from './ScreenWelcomeModal';
import ScreenOptionsModal from './ScreenOptionsModal';
import { exampleCompanyConstants } from '../../../constants';

const SetExampleProcessMapModal = () => {
  const dispatch = useDispatch();
  const exampleCompanies = useSelector((state) => state.exampleCompany.data);
  const [screenToShow, setScreenToShow] = useState(exampleCompanyConstants.SCREEN_WELCOME);

  useEffect(() => {
    if (!exampleCompanies) {
      dispatch(exampleCompanyActions.getAll());
    }
  }, [dispatch, exampleCompanies]);

  function handleChangeScreen(screen) {
    setScreenToShow(screen);
  }

  const switchScreensMap = (selectedScreen) => {
    switch (selectedScreen) {
      case exampleCompanyConstants.SCREEN_WELCOME:
        return <ScreenWelcomeModal handleChangeScreen={handleChangeScreen} />;
      case exampleCompanyConstants.SCREEN_OPTIONS:
        return <ScreenOptionsModal handleChangeScreen={handleChangeScreen} />;
      default:
        return <ScreenWelcomeModal handleChangeScreen={handleChangeScreen} />;
    }
  };
  return (
    <>
      {switchScreensMap(screenToShow)}
    </>
  );
};

export default SetExampleProcessMapModal;
