import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const CalendarEventDetailProcessList = ({ processes }) => (
  <Box>
    <Typography variant="h5" style={{ fontSize: '12px', fontWeight: 600, margin: '10px 0px 6px' }}>
      <FormattedMessage id="processes.title" defaultMessage="Procesos" />
    </Typography>
    <Box>
      {processes.length > 0
        ? processes.map((process, index) => {
          const keyId = `keyId-${index}`;
          return (
            <Typography key={keyId} variant="h6" style={{ fontSize: '12px', lineHeight: '16px' }}>
              {process.name}
            </Typography>
          );
        })
        : '-'}
    </Box>
  </Box>
);

CalendarEventDetailProcessList.propTypes = {
  processes: PropTypes.arrayOf(PropTypes.shape()),
};

CalendarEventDetailProcessList.defaultProps = {
  processes: [],
};

export default CalendarEventDetailProcessList;
