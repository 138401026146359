import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Breadcrumbs, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageLayout from '../PageLayout';
import RiskForm from './RiskForm';

function NewRiskPage() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector((state) => state.authentication.user);
  const [componentMounted, setComponentMounted] = useState(false);
  const breadcrumbsTitle = intl.formatMessage({ id: 'risks.title', defaultMessage: 'Riesgos' });
  const title = intl.formatMessage({ id: 'risks.newRisk', defaultMessage: 'Nuevo riesgo' });

  useEffect(() => {
    if (user && !componentMounted) {
      // dispatch(riskActions.get(user.company.id));
      setComponentMounted(true);
    }
  }, [dispatch, user, componentMounted]);

  function NewRiskPageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/risks" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{breadcrumbsTitle}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>
          <strong><FormattedMessage id="app.new" defaultMessage="Nuevo" /></strong>
        </Typography>
      </Breadcrumbs>
    );
  }

  return (
    <PageLayout title={title} breadCrumbs={<NewRiskPageBreadcrumbs />}>
      <RiskForm />
    </PageLayout>
  );
}

export default NewRiskPage;
