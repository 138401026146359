import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  LinearProgress,
  Typography
} from '@material-ui/core';

import { alertActions } from '../../../redux/actions';
import { companyService } from '../../../services';
import CompanyUsersDataTable from './CompanyUsersDataTable';
import PageLayout from '../../PageLayout';
import { stripePlanSpecs } from '../../../constants';
import LoadingCompanyUsersPageBreadcrumbs from './components/LoadingCompanyUsersPageBreadcrumbs';
import CompanyUsersPageBreadcrumbs from './components/CompanyUsersPageBreadcrumbs';
import CompanyEditModal from './components/CompanyEditModal';

const CompanyUsersPage = ({ routeParams: { id: companyId } }) => {
  const intl = useIntl();
  const user = useSelector((state) => state.authentication.user);
  const dispatch = useDispatch();
  const [company, setCompany] = useState({});
  const [companyPlan, setCompanyPlan] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);

  const subscriptionDataText = intl.formatMessage({ id: 'stripe.subscriptionData', defaultMessage: 'Datos de suscripción' });
  const titleLabel = intl.formatMessage({ id: 'superAdmin.systemAdmin', defaultMessage: 'Administración del sistema' });
  const userPlanText = intl.formatMessage({ id: 'user.plan', defaultMessage: 'Plan' });

  useEffect(() => {
    if (companyId) {
      companyService.get(companyId)
        .then((companySelected) => {
          setCompany(companySelected);
          const plan = stripePlanSpecs.searchPlan(companySelected.plan);
          if (plan) setCompanyPlan(plan);
        })
        .catch((err) => dispatch(alertActions.error(err)));
    };
  }, [companyId, dispatch]);

  if (!user.isSuperAdmin) {
    return <Redirect to={{ pathname: '/company/configuration' }} />;
  };

  return (
    <>
      {company.id
        ? (
          <PageLayout
            breadCrumbs={<CompanyUsersPageBreadcrumbs companyName={company.name} />}
            editable
            onEditClick={() => setEditModalOpen((oldState) => !oldState)}
            title={company.name}
          >
            <Box pl={3}>
              <Typography variant="h6" style={{ marginBottom: '8px', fontSize: '18px', fontWeight: 700 }}>
                {subscriptionDataText}
              </Typography>
              <Typography display="inline" variant="subtitle1" gutterBottom style={{ marginLeft: '8px', fontSize: '14px', fontWeight: 700 }}>
                {`${userPlanText}: `}
              </Typography>
              <Typography display="inline" variant="subtitle1" gutterBottom style={{ marginLeft: '8px', fontSize: '14px' }}>
                {companyPlan.label}
              </Typography>
              <Typography display="block" variant="subtitle1" gutterBottom style={{ marginLeft: '8px', fontSize: '14px' }}>
                <b>CustomerID: </b>
                {company.stripeCustomerId}
              </Typography>
            </Box>
            <CompanyUsersDataTable companyId={companyId} />
            {
              editModalOpen && (
                <CompanyEditModal
                  company={company}
                  editModalOpen={editModalOpen}
                  setEditModalOpen={setEditModalOpen}
                />
              )
            }
          </PageLayout >
        ) : (
          <PageLayout
            title={titleLabel}
            breadCrumbs={<LoadingCompanyUsersPageBreadcrumbs />}
          >
            <LinearProgress />
          </PageLayout>
        )}
    </>
  );
};

CompanyUsersPage.propTypes = {
  routeParams: PropTypes.shape().isRequired,
};

export default CompanyUsersPage;
