import React from 'react';
import {
  Box, Dialog, DialogContent,
  DialogTitle, IconButton, makeStyles, Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Button from './Button';

const useStyles = makeStyles((theme) => ({
  textDialog: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    maxWidth: '370px',
  },
  textTitleDialog: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '18px',
    textTransform: 'uppercase',
  },
  closeButton: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

const CustomDialogAlert = ({ toggleDialog, openDialog, message }) => {
  const classes = useStyles();
  const intl = useIntl();
  const continueText = intl.formatMessage({ id: 'app.continue', defaultMessage: 'Continuar' });

  return (
    <Dialog
      open={openDialog}
      onClose={toggleDialog}
      aria-labelledby="alert-dialog-bulkCreation-title"
      aria-describedby="alert-dialog-bulkCreation-description"
    >
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <IconButton aria-label="close" className={classes.closeButton} onClick={toggleDialog}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle disableTypography style={{ paddingTop: 0, paddingBottom: 8 }}>
        <Box display="flex" justifyContent="center">
          <Typography align="center" className={classes.textTitleDialog} variant="body1">
            {message.title}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" display="block" className={classes.textDialog} variant="body1">
          {message.body}
        </Typography>
      </DialogContent>
      <Box pt={1} pb={3} display="flex" justifyContent="center">
        <Button onClick={toggleDialog} text={continueText} />
      </Box>
    </Dialog>
  );
};

CustomDialogAlert.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  openDialog: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  }).isRequired,

};

export default CustomDialogAlert;
