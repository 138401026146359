import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles, Grid } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import userActions from '../../redux/actions/user.actions';
import Button from '../controls/Button';
import { validationConstants } from '../../constants';
import MuiPhoneNumberValidator from '../controls/MuiPhoneNumberValidator';
import TaxIdSelector from '../Auth/TaxIdSelector/TaxIdSelector';
import { handleChange } from '../../common/handlers';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: '208px',
  },
}));

const initialFieldValues = {
  company: '',
  phoneNumber: '',
  taxID: '',
  taxIDType: ''
};

function CompanyCreateModal({ companyId }) {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = React.useState(false);
  const [open] = React.useState(true);
  const [values, setValues] = useState(initialFieldValues);
  const phoneNumberLabel = intl.formatMessage({ id: 'people.phoneNumber', defaultMessage: 'Número de teléfono' });

  const successfulAffiliated = {
    title: intl.formatMessage({ id: 'affiliates.congratulations', defaultMessage: '¡Felicitaciones!' }),
    messagePart1: intl.formatMessage({ id: 'affiliates.theAgreementSuccessfullyAppliedPart1', defaultMessage: 'Se aplicó con éxito en tu cuenta el convenio con ' }),
    messagePart2: intl.formatMessage({ id: 'affiliates.theAgreementSuccessfullyAppliedPart2', defaultMessage: 'Contás con un plazo de 60 días para obtener un descuento sobre la suscripción al plan que prefieras.' }),
    button: intl.formatMessage({ id: 'affiliates.seeDiscountedPlans', defaultMessage: 'Ver planes con descuento' }),
    linkTo: '/stripe/plans',
    buttonLeft: intl.formatMessage({ id: 'affiliates.maybeLater', defaultMessage: 'Quizás luego' }),
  };

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    const userCompany = {
      name: values.company,
      phoneNumber: values.phoneNumber,
      stripeCustomerTaxId: values.taxID,
      stripeCustomerTaxType: values.taxIDType
    };
    dispatch(userActions.setCompany(userCompany, companyId, successfulAffiliated));
  }

  function handleChangePhoneNumber(value) {
    setValues({ ...values, phoneNumber: value });
  }

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <Typography color="primary" variant="h6" align="center">
          <FormattedMessage
            id="app.welcome"
            defaultMessage="¡Te damos la bienvenida!"
          />
        </Typography>

        <Box mt={3}>
          <Typography variant="subtitle1" align="center">
            <FormattedMessage id="companyCreate.lastStep" defaultMessage="Solo queda un paso más" />
          </Typography>

          <Typography display="block" align="center" variant="subtitle1">
            <strong>
              <FormattedMessage id="companyCreate.companyName" defaultMessage="¿Cuál es el nombre de su empresa?" />
            </strong>
          </Typography>
        </Box>
        <ValidatorForm style={{ width: '100%' }} onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextValidator
                value={values.company}
                onChange={(event) => handleChange({ event, setValues, values })}
                margin="normal"
                fullWidth
                id="company"
                label={<FormattedMessage id="register.company" defaultMessage="Nombre de la empresa" />}
                name="company"
                autoComplete="company"
                size="small"
                validators={['required']}
                errorMessages={[validationConstants.COMPANY_REQUIRED]}
                autoFocus
              />
            </Grid>
            <TaxIdSelector values={values} handleChange={(event) => handleChange({ event, setValues, values })} />
            <Grid item xs={12}>
              <MuiPhoneNumberValidator
                autoFormat={false}
                value={values.phoneNumber}
                onChange={handleChangePhoneNumber}
                defaultCountry="ar"
                fullWidth
                margin="normal"
                label={phoneNumberLabel}
                validators={['required', 'minStringLength:6']}
                errorMessages={[
                  validationConstants.PERSON_PHONE_REQUIRED,
                  validationConstants.PERSON_PHONE_INVALID,
                ]}
              />
            </Grid>
          </Grid>
          <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
            <Button
              type="submit"
              loading={submitted}
              text={<FormattedMessage id="app.finish" defaultMessage="Finalizar" />}
            />
          </Box>
        </ValidatorForm>

      </DialogContent>
    </Dialog>
  );
}

CompanyCreateModal.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default CompanyCreateModal;
