import { exampleCompanyConstants } from '../../constants';

const showWelcomeModal = JSON.parse(localStorage.getItem('showWelcomeModal'));
const initialState = showWelcomeModal ? { showWelcomeModal: true } : {};

function modals(state = initialState, action) {
  switch (action.type) {
    case exampleCompanyConstants.OPEN_MODAL:
      return { showWelcomeModal: true };

    case exampleCompanyConstants.CLOSE_MODAL:
      return { };

    case exampleCompanyConstants.FROM_SCRATCH:
      return { processMapFromScratch: true };

    default:
      return state;
  }
}

export default modals;
