import React from 'react';
import red from '@material-ui/core/colors/red';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

const red500 = red['500'];

const style = {
  right: 0,
  fontSize: '12px',
  color: red500,
};

const StyledFormControlLabel = withStyles({
  root: {
    marginRight: 3,
  },
})(FormControlLabel);

class CheckboxValidator extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      errorMessages,
      validators,
      requiredError,
      value,
      labelControl,
      labelRight,
      validatorListener,
      ...rest
    } = this.props;

    return (
      <>
        <Box display="flex" alignItems="center">
          <StyledFormControlLabel
            control={(
              <Checkbox
                {...rest}
                ref={(r) => { this.input = r; }}
              />
            )}
            label={labelControl}
          />
          {labelRight}
        </Box>
        {this.errorText()}
      </>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return <div style={style}>{this.getErrorMessage()}</div>;
  }
}

export default CheckboxValidator;
