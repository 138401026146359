import { handleTaxIDType } from '../helpers/handleTaxIDType';

const handleChange = ({
    event,
    setValues,
    values
}) => {
    const { name, value } = event.target;

    if (['taxIDType', 'taxID'].includes(name)) {
        handleTaxIDType({ name, value, setValues });
    } else {
        setValues({ ...values, [name]: value || '' });
    };
};

export {
    handleChange
};
