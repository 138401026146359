import alertActions from './alert.actions';
import { deviationConstants } from '../../constants';
import { history } from '../../helpers';
import { deviationService } from '../../services';

function addDeviation(deviation, message) {
  function request() { return { type: deviationConstants.request.CREATE_REQUEST }; }
  function success() { return { type: deviationConstants.request.CREATE_SUCCESS }; }
  function failure(error) { return { type: deviationConstants.request.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    deviationService.addDeviation(deviation)
      .then(
        () => {
          dispatch(success());
          dispatch(alertActions.success(message));
          history.push('/deviations/board');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getAllDeviations() {
  function request() { return { type: deviationConstants.request.GET_ALL_REQUEST }; }
  function success(deviations) {
    return { type: deviationConstants.request.GET_ALL_SUCCESS, deviations };
  }
  function failure(error) { return { type: deviationConstants.request.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    deviationService.getAllDeviations()
      .then(
        (response) => {
          dispatch(success(response));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getDeviations(id) {
  function request() { return { type: deviationConstants.request.GET_REQUEST }; }
  function success(deviation) {
    return { type: deviationConstants.request.GET_SUCCESS, deviation };
  }
  function failure(error) { return { type: deviationConstants.request.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    deviationService.getDeviations(id)
      .then(
        (response) => {
          dispatch(success(response));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function updateDeviation(id, deviation, message) {
  function request() {
    return { type: deviationConstants.request.UPDATE_REQUEST };
  }
  function success() {
    return { type: deviationConstants.request.UPDATE_SUCCESS };
  }
  function failure(error) {
    return { type: deviationConstants.request.UPDATE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    deviationService.updateDeviation(id, deviation)
      .then(
        () => {
          dispatch(success());
          dispatch(alertActions.success(message));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function archiveDeviation(id, deviation, message) {
  function request() { return { type: deviationConstants.request.ARCHIVE_REQUEST }; }
  function success() { return { type: deviationConstants.request.ARCHIVE_SUCCESS }; }
  function failure(error) {
    return { type: deviationConstants.request.ARCHIVE_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    deviationService.updateDeviation(id, deviation)
      .then(
        () => {
          dispatch(success());
          dispatch(alertActions.success(message));
          history.push('/deviations');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function updateDeviationForm(id, deviation, message) {
  function request() { return { type: deviationConstants.request.UPDATE_FORM_REQUEST }; }
  function success() { return { type: deviationConstants.request.UPDATE_FORM_SUCCESS }; }
  function failure(error) {
    return { type: deviationConstants.request.UPDATE_FORM_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    deviationService.updateDeviation(id, deviation)
      .then(
        () => {
          dispatch(success());
          dispatch(alertActions.success(message));
          history.push('/deviations/board');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function createBulkDeviations(deviationsExcelFile) {
  function request() { return { type: deviationConstants.request.CREATE_BULK_REQUEST }; }
  function success(bulkCreationPreviewResult) {
    return {
      type: deviationConstants.request.CREATE_BULK_SUCCESS,
      bulkCreationPreviewResult,
    };
  }
  function failure(error) {
    return { type: deviationConstants.request.CREATE_BULK_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    deviationService.createBulkDeviations(deviationsExcelFile)
      .then(
        (bulkCreationPreviewResult) => {
          dispatch(success(bulkCreationPreviewResult));
          history.push('/deviations/result');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function confirmCreateBulkDeviations(id, messageSuccess) {
  function request() { return { type: deviationConstants.request.CONFIRM_CREATE_BULK_REQUEST }; }
  function success() { return { type: deviationConstants.request.CONFIRM_CREATE_BULK_SUCCESS }; }
  function failure(error) {
    return { type: deviationConstants.request.CONFIRM_CREATE_BULK_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    deviationService.confirmCreateBulkDeviations(id)
      .then(
        () => {
          dispatch(success());
          dispatch(alertActions.success(messageSuccess));
          history.replace('/deviations');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function deleteDeviations(deviationId, message) {
  function request() { return { type: deviationConstants.request.DELETE_REQUEST }; }
  function success() { return { type: deviationConstants.request.DELETE_SUCCESS }; }
  function failure() { return { type: deviationConstants.request.DELETE_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    deviationService.deleteDeviations(deviationId)
      .then(
        () => {
          dispatch(success());
          dispatch(alertActions.success(message));
          history.push('/deviations');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

const deviationActions = {
  addDeviation,
  deleteDeviations,
  getAllDeviations,
  getDeviations,
  updateDeviation,
  updateDeviationForm,
  archiveDeviation,
  confirmCreateBulkDeviations,
  createBulkDeviations,
};

export default deviationActions;
