import React from 'react';
import { useDispatch } from 'react-redux';
import { objectiveTexts } from '../../../constants';
import { downloadHelper } from '../../../helpers';
import { objectiveActions } from '../../../redux/actions';
import BulkCreation from '../../BulkUpload/BulkCreation';

const ObjectiveBulkCreation = () => {
  const dispatch = useDispatch();
  const linkDownload = downloadHelper.listObjectivesExample;
  const linkEntity = '/objectives';
  const text = {
    bulkCreationBreadcrumbs: objectiveTexts.bulkCreationBreadcrumbs,
    bulkCreationStep1: objectiveTexts.bulkCreationStep1,
    bulkCreationStep2: objectiveTexts.bulkCreationStep2,
    bulkCreationStep3: objectiveTexts.bulkCreationStep3,
    bulkCreationSteps: objectiveTexts.bulkCreationSteps,
    bulkCreationSubtitle: objectiveTexts.bulkCreationSubtitle,
    bulkCreationTitle: objectiveTexts.bulkCreationTitle,
    downloadListExample: objectiveTexts.downloadObjectivesListExample,
    title: objectiveTexts.title,
    titlePageLayout: objectiveTexts.title,
  };

  function handleDispatcher(formData) {
    return (
      dispatch(objectiveActions.createBulkObjectives(formData))
    );
  }

  return (
    <BulkCreation
      text={text}
      linkEntity={linkEntity}
      linkDownload={linkDownload}
      handleDispatcher={handleDispatcher}
    />
  );
};

export default ObjectiveBulkCreation;
