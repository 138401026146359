import React from 'react';
import HTMLtoDOCX from '@tupaca/html-to-docx';
import { saveAs } from 'file-saver';
import { FormattedMessage } from 'react-intl';
import { alertActions, documentActions } from '../redux/actions';
import dispatch from './dispatch';

function handleDownloadAction(idDocument, currentVersion, documentTitle) {
  const filename = `${documentTitle}.${currentVersion.extension}`;
  dispatch(documentActions.download(idDocument, currentVersion.contentType, filename));
}

const downloadHtmlAsDocx = async (content, title, version) => {
  try {
    const dataToDownload = await HTMLtoDOCX(content);
    const documentName = `${title} - ${version}`;
    const blob = new Blob([dataToDownload], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    saveAs(blob, `${documentName}.docx`);
  } catch (error) {
    const errorDownloadText = <FormattedMessage id="error.downloadFile" defaultMessage="No se pudo descargar el archivo." />;
    dispatch(alertActions.error(errorDownloadText));
  }
};

const handleDownloadDocument = (document, currentVersion) => {
  if (currentVersion.editableHtml) {
    downloadHtmlAsDocx(currentVersion.editableHtml, document.title, currentVersion.versionName);
  }
  if (currentVersion.contentType) {
    handleDownloadAction(document.id, currentVersion, document.title);
  }
};

const documentHelpers = {
  handleDownloadAction,
  downloadHtmlAsDocx,
  handleDownloadDocument,
};

export default documentHelpers;
