import { companyConstants } from '../../constants';

function addZero(i) {
  const number = i;
  return number < 10 ? `0${number}` : number;
}

function company(state = {}, action) {
  const currentdate = new Date();
  const lastSyncDate = `${currentdate.getDate()}/${
    currentdate.getMonth() + 1}/${
    currentdate.getFullYear()}`;
  const lastSyncTime = `${currentdate.getHours()}:${
    addZero(currentdate.getMinutes())}:${
    addZero(currentdate.getSeconds())}`;

  switch (action.type) {
    case companyConstants.GET_REQUEST:
      return { requesting: true };
    case companyConstants.UPDATE_REQUEST:
      return { data: action.companyToUpdate, updating: true };
    case companyConstants.GET_SUCCESS:
      return {
        data: action.company,
        syncResult: { sync: true, date: lastSyncDate, time: lastSyncTime },
      };
    case companyConstants.UPDATE_SUCCESS:
      return {
        data: action.companyUpdated,
        saveResult: { success: true, date: lastSyncDate, time: lastSyncTime },
      };
    case companyConstants.GET_FAILURE:
      return { syncResult: { sync: false, date: lastSyncDate, time: lastSyncTime } };
    case companyConstants.UPDATE_FAILURE:
      return {
        data: action.companyToUpdate,
        saveResult: { success: false, error: action.error },
      };

    case companyConstants.FIRST_LOGIN_COMPANY_REQUEST:
      return {
        requesting: true,
        data: state.data ? state.data : null,
        syncResult: { sync: true, date: lastSyncDate, time: lastSyncTime },
      };
    case companyConstants.FIRST_LOGIN_COMPANY_SUCCESS:
      return {
        requesting: false,
        data: state.data ? { ...state.data, isFirstLogin: false } : null,
        syncResult: { sync: true, date: lastSyncDate, time: lastSyncTime },
      };
    case companyConstants.FIRST_LOGIN_COMPANY_FAILURE:
      return {
        data: state.data ? state.data : null,
        syncResult: { sync: false, date: lastSyncDate, time: lastSyncTime },
      };
    default:
      return state;
  }
}

export default company;
