import React, { useState } from 'react';
import {
  Box, Grid, Typography, makeStyles, Dialog, DialogTitle, DialogContent,
  DialogContentText, DialogActions, ListItemText, ListItem, List,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../controls/Button';
import { workFieldActions } from '../../../redux/actions';
import { objectiveConstants, workFieldTexts } from '../../../constants';
import { history } from '../../../helpers';
import CustomSliderWorkField from '../../controls/CustomSliderWorkField';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  root: {
    backgroundColor: '#F8F8F8',
    paddingTop: 16,
    width: '100%',
  },
  textTitle: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '18px',
    textAlign: 'left',
  },
  textValue: {
    paddingTop: '8px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'left',
  },
  textItems: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  textTitleItems: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '18px',
  },
}));

const WorkFieldViewDetail = ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const deletingWorkField = useSelector((state) => state.workField.deleting);
  const { roles } = useSelector((state) => state.authentication.user);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const isAdmin = roles.includes('Admin');

  const parsedWorkFieldItems = data ? JSON.parse(data.workFieldItems) : [];

  function toggleDeleteDialog() {
    setOpenDeleteDialog(!openDeleteDialog);
  }

  function handleDeleteWorkFields(id) {
    dispatch(workFieldActions.deleteWorkField(id, workFieldTexts.successfulDelete));
  }

  return (
    <div className={classes.main}>
      <Box pl={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.textTitle}>{workFieldTexts.name}</Typography>
            <Typography className={classes.textValue}>{data.name}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box pl={3} pt={2}>
              <Typography className={classes.textTitle}>{workFieldTexts.frequency}</Typography>
              <Typography className={classes.textValue}>
                {objectiveConstants.frequency[data.frequency]
                  ? objectiveConstants.frequency[data.frequency].label
                  : '-'}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <List className={classes.root}>
              <ListItem disableGutters>
                <ListItemText
                  primary={workFieldTexts.itemsToBeEvaluated}
                  disableTypography
                  className={classes.textTitleItems}
                />
                <Typography className={classes.textTitleItems} style={{ width: '260px' }}>
                  {workFieldTexts.weightingPercentage}
                </Typography>
              </ListItem>
              {parsedWorkFieldItems.length > 0
                && parsedWorkFieldItems.map((item, index) => {
                  const keyId = `keyId-${index}`;
                  return (
                    <ListItem key={keyId} disableGutters>
                      <ListItemText
                        primary={`${item.name}`}
                        disableTypography
                        className={classes.textItems}
                      />
                      <CustomSliderWorkField
                        itemName={item.name}
                        weightingPoint={item.weightingPoint}
                        weightPercentage={item.weightPercentage}
                        readOnly
                      />
                    </ListItem>
                  );
                })}
            </List>
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" alignItems="flex-end" justifyContent="flex-end" mt={3}>
        <Button onClick={history.goBack} variant="outlined" text={workFieldTexts.back} />
        <Button
          type="button"
          color="secondary"
          onClick={() => {
            toggleDeleteDialog();
          }}
          text={workFieldTexts.delete}
          style={{ fontSize: '10px', fontWeight: 600 }}
          disabled={!isAdmin}
        />
        <Button
          component={Link}
          to={`${data.id}/edit`}
          type="submit"
          text={workFieldTexts.edit}
          disabled={!isAdmin}
        />
      </Box>
      <Dialog
        open={openDeleteDialog}
        onClose={() => toggleDeleteDialog()}
        fullWidth
        maxWidth="xs"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{workFieldTexts.deleteTitleDialog}</DialogTitle>
        <DialogContent>
          <DialogContentText>{workFieldTexts.deleteContentDialog}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button text={workFieldTexts.cancel} onClick={() => toggleDeleteDialog()} />
          <Button
            text={workFieldTexts.delete}
            color="secondary"
            loading={deletingWorkField}
            onClick={() => {
              handleDeleteWorkFields(data.id);
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

WorkFieldViewDetail.propTypes = {
  data: PropTypes.shape(),
};

WorkFieldViewDetail.defaultProps = {
  data: {},
};

export default WorkFieldViewDetail;
