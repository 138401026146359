/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import {
  Typography, makeStyles, Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { deviationActions } from '../../../../redux/actions';
import { deviationTexts, deviationConstants } from '../../../../constants';

const useStyles = makeStyles(() => ({
  textArea: {
    '& .MuiInputBase-inputMultiline': {
      backgroundColor: '#F9F9F9',
      minHeight: '75px',
      alignItems: 'flex-start',
      padding: 8,
    },
  },
  labelAreaText: {
    fontSize: '12px',
    lineHeight: '12px',
    textAlign: 'justify',
  },
  labelAreaTextPotentialCause: {
    fontSize: '12px',
    lineHeight: '12px',
    textAlign: 'justify',
  },
  textLabel: {
    '& .MuiFormLabel-root': {
      fontSize: '12px',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px) scale(1)',
    },
    padding: '0px 40px',
  },
  requiredField: {
    fontSize: '12px',
    lineHeight: '12px',
    textAlign: 'justify',
    paddingBottom: '16px',
  },
}));

const BoardModalAnalyzedDeviationForm = ({
  data, target, setRefToTheForm, eventBus,
}) => {
  const initialFieldValues = {
    ...data.cardDetails,
    currentState: target,
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deviationDetails, setDeviationDetails] = useState(initialFieldValues);

  const formEl = useRef();
  setRefToTheForm(formEl);

  useEffect(() => {
    ValidatorForm.addValidationRule('passIfRuleIsUndefined', () => true);
  }, []);

  function handleSaveEditDeviations() {
    dispatch(
      deviationActions.updateDeviation(
        data.cardDetails.id,
        deviationDetails,
        deviationTexts.successfulUpdate,
      ),
    );
  }

  function handleSubmitForm() {
    handleSaveEditDeviations();
    const newCurrentCard = {
      ...data, cardDetails: { ...deviationDetails },
    };
    eventBus.publish({
      type: 'MOVE_CARD',
      index: 0,
      ...newCurrentCard,
    });
    eventBus.publish({
      type: 'UPDATE_CARD',
      laneId: target,
      card: deviationDetails,
    });
  }

  function handleChangeFormData(e) {
    const { name, value } = e.target;
    setDeviationDetails({ ...deviationDetails, [name]: value });
  }

  return (
    <ValidatorForm onSubmit={() => handleSubmitForm()} autoComplete="off" className={classes.textLabel} instantValidate ref={formEl}>
      <Typography className={classes.requiredField}>
        Campos obligatorios
        <span style={{ color: 'red' }}> *</span>
      </Typography>
      <Box py={1}>
        <Typography className={classes.labelAreaTextPotentialCause}>
          {deviationTexts.potentialCause}
          {deviationConstants.statusForm[target].rules.potentialCause && <span style={{ color: 'red' }}> *</span>}
        </Typography>
        <TextValidator
          classes={{ root: classes.textArea }}
          style={{ marginTop: 8 }}
          value={deviationDetails.potentialCause}
          onChange={handleChangeFormData}
          fullWidth
          margin="normal"
          id="potentialCauseId"
          name="potentialCause"
          size="small"
          multiline
          InputLabelProps={{ shrink: true }}
          validators={[deviationConstants.statusForm[target].rules.potentialCause || 'passIfRuleIsUndefined']}
          errorMessages={[deviationTexts.deviationDefaultRequired]}
        />
      </Box>
      <Box py={1}>
        <Typography className={classes.labelAreaText}>
          {deviationTexts.immediateAction}
          {deviationConstants.statusForm[target].rules.immediateAction && <span style={{ fontWeight: 'normal', color: 'red' }}> *</span>}
        </Typography>
        <TextValidator
          classes={{ root: classes.textArea }}
          style={{ marginTop: 8 }}
          value={deviationDetails.immediateAction}
          onChange={handleChangeFormData}
          fullWidth
          margin="normal"
          id="immediateActionId"
          name="immediateAction"
          size="small"
          multiline
          InputLabelProps={{ shrink: true }}
          validators={[deviationConstants.statusForm[target].rules.immediateAction || 'passIfRuleIsUndefined']}
          errorMessages={[deviationTexts.deviationDefaultRequired]}
        />
      </Box>
    </ValidatorForm>
  );
};

BoardModalAnalyzedDeviationForm.propTypes = {
  data: PropTypes.shape().isRequired,
  eventBus: PropTypes.shape().isRequired,
  setRefToTheForm: PropTypes.func.isRequired,
  target: PropTypes.string.isRequired,
};

export default BoardModalAnalyzedDeviationForm;
