import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Box, makeStyles, Typography, Table, TableBody,
  TableCell, TableRow, Divider, Dialog,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MuiButton from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { DocumentIcon } from '../../assets/icons/index';
import SetNewDocumentModal from '../Documents/SetNewDocumentModal/SetNewDocumentModal';
import DownloadDocument from '../controls/DownloadDocument';

const useStyles = makeStyles((theme) => ({
  documentIcon: {
    color: theme.palette.primary.main,
    height: '11px',
    width: '9px',
  },
  linkDocuments: {
    color: theme.palette.primary.main,
    fontSize: '10px',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  textSeparator: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  historyTableDivider: {
    marginBottom: '16px',
  },
  table: {
    maxWidth: 525,
  },
  tableBody: {
    cursor: 'default',
    fontSize: '12px',
    padding: '8px 16px 8px 16px',
  },
  tableHeadEmpty: {
    cursor: 'default',
    fontSize: '12px',
    paddingTop: '0px',
  },
  tableHead: {
    cursor: 'default',
    fontSize: '12px',
    fontWeight: 700,
    paddingTop: '0px',
  },
  tableLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 600,
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
}));

const WorkFieldEvaluationDocuments = ({
  documents, showDivider, workFieldEvaluationId,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const documentsLabel = intl.formatMessage({ id: 'documents.title_pl', defaultMessage: 'Documentos' });
  const seeDocumentText = intl.formatMessage({ id: 'document.see', defaultMessage: 'Ver Documento' });
  const addDocumentText = intl.formatMessage({ id: 'document.addToWorkFieldEvaluation', defaultMessage: 'AGREGAR DOCUMENTO A LA EVALUACIÓN' });
  const documentNameText = intl.formatMessage({ id: 'document.name', defaultMessage: 'Nombre' });
  const documentVersionText = intl.formatMessage({ id: 'document.version', defaultMessage: 'Versión' });
  const noDocumentsLinkedToWorkFieldEvaluation = intl.formatMessage({ id: 'document.noDocumentsLinkedToWorkFieldEvaluation', defaultMessage: 'No hay documentos vinculados a la evaluación' });
  const [documentModal, setDocumentModal] = useState(false);

  function handleDocumentModal() {
    return setDocumentModal(!documentModal);
  }

  return (
    <Box textAlign="left">
      <Typography className={classes.textSeparator}>
        {documentsLabel}
      </Typography>
      {showDivider ? <Divider className={classes.historyTableDivider} /> : <Box mb={1.5} />}
      <Box pr={3} pb={3}>
        <Table className={classes.table}>
          <TableBody>
            {(documents.length > 0) ? (
              <TableRow>
                <TableCell className={classes.tableHead} size="small" style={{ paddingLeft: '0px' }}>{documentNameText}</TableCell>
                <TableCell className={classes.tableHead} size="small">{documentVersionText}</TableCell>
                <TableCell className={classes.tableHead} size="small" />
                <TableCell className={classes.tableHead} size="small" />
              </TableRow>
            ) : (
              <TableRow>
                <TableCell className={classes.tableHeadEmpty} style={{ paddingLeft: '0px', borderBottom: '0px' }} size="small">{noDocumentsLinkedToWorkFieldEvaluation}</TableCell>
              </TableRow>
            )}
            {(documents.length > 0) && documents.map((document) => {
              const actualVersion = document.versions
                ? document.versions.find(
                  (version) => version.id === document.actualDocumentVersionId,
                )
                : '';
              return (
                <TableRow key={document.id}>
                  <TableCell size="small" className={classes.tableBody} style={{ paddingLeft: '0px' }}>{document.title}</TableCell>
                  <TableCell size="small" className={classes.tableBody}>{actualVersion.versionName}</TableCell>
                  <TableCell size="small" className={`${classes.tableBody} ${classes.tableLink}`} component={Link} to={`/documents/${document.id}/${actualVersion.id}`}>
                    {seeDocumentText}
                  </TableCell>
                  <TableCell size="small">
                    <DownloadDocument document={document} actualVersion={actualVersion} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      {isAdmin && (
      <Box pr={3}>
        <DocumentIcon className={classes.documentIcon} display="inline" />
        <MuiButton
          color="primary"
          onClick={() => handleDocumentModal()}
          display="inline"
          className={classes.linkDocuments}
          style={{ paddingLeft: '10px', textDecoration: 'none' }}
        >
          {addDocumentText}
        </MuiButton>
      </Box>
      )}
      <Box>
        <Dialog
          open={documentModal}
          onClose={handleDocumentModal}
          aria-labelledby="form-dialog-title"
        >
          <SetNewDocumentModal
            isOpen={documentModal}
            setIsOpen={handleDocumentModal}
            workFieldEvaluationId={workFieldEvaluationId}
          />
        </Dialog>
      </Box>
    </Box>
  );
};

WorkFieldEvaluationDocuments.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  showDivider: PropTypes.bool,
  workFieldEvaluationId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

WorkFieldEvaluationDocuments.defaultProps = {
  showDivider: false,
  workFieldEvaluationId: '',
};

export default WorkFieldEvaluationDocuments;
