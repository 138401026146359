const superAdminConstants = {

  COMPANY_POST_REQUEST: 'COMPANY_POST_REQUEST',
  COMPANY_POST_SUCCESS: 'COMPANY_POST_SUCCESS',
  COMPANY_POST_FAILURE: 'COMPANY_POST_FAILURE',

  COMPANY_DELETE_REQUEST: 'COMPANY_DELETE_REQUEST',
  COMPANY_DELETE_SUCCESS: 'COMPANY_DELETE_SUCCESS',
  COMPANY_DELETE_FAILURE: 'COMPANY_DELETE_FAILURE',

  RESET_PASS_REQUEST: 'USER_RESET_PASS_POST_REQUEST',
  RESET_PASS_SUCCESS: 'USER_RESET_PASS_POST_SUCCESS',
  RESET_PASS_FAILURE: 'USER_RESET_PASS_POST_FAILURE',

  CLEAN_SUPER_ADMIN: 'CLEAN_SUPER_ADMIN',

};

export default superAdminConstants;
