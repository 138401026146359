import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Button from '../../../controls/Button';
import BoardModalAnalyzedDeviationForm from './BoardModalAnalyzedDeviationForm';
import BoardModalCorrectiveActionDefinedDeviationForm from './BoardModalCorrectiveActionDefinedDeviationForm';
import BoardModalCorrectiveActionImplementedDeviationForm from './BoardModalCorrectiveActionImplementedDeviationForm';
import { CloseIcon } from '../../../../assets/icons';
import { deviationConstants } from '../../../../constants';

const useStyles = makeStyles(() => ({
  containerTitleDialog: {
    paddingTop: '26px',
  },
  titleDialog: {
    fontSize: '14px',
    fontWeight: 700,
  },
  subtitleDialog: {
    fontSize: '12px',
    fontWeight: 500,
  },
  closeIcon: {
    width: 16,
    height: 16,
    cursor: 'pointer',
    color: '#727272',
  },
  buttonDialog: {
    justifyContent: 'center',
    paddingBottom: '30px',
  },
}));

const ModalDragAndDropConfirm = ({
  currentCard, openModal, toggleModalConfirm, eventBus,
}) => {
  const classes = useStyles();
  const [refToTheForm, setRefToTheForm] = useState(null);
  const updatingDeviation = useSelector((state) => state.deviation.updating);
  const titleDialog = <FormattedMessage id="deviation.boardDialogTitle" defaultMessage="COMPLETE LOS DATOS NECESARIOS PARA REALIZAR ESTA ACCIÓN" />;
  const cancelButtonText = <FormattedMessage id="app.cancel" defaultMessage="Cancelar" />;
  const continueButtonText = <FormattedMessage id="app.continue" defaultMessage="Continuar" />;
  const subtitleDialog = (
    <FormattedMessage
      id="deviation.boardDialogSubtitle"
      defaultMessage="Mover Desvío"
      values={{
        deviation: currentCard.cardDetails.title,
        target: deviationConstants.status[currentCard.toLaneId].label,
        b: (chunks) => <b>{chunks}</b>,
      }}
    />
  );

  return (
    <Dialog open={openModal} fullWidth aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" disableTypography classes={{ root: classes.containerTitleDialog }}>
        <Box display="flex" justifyContent="flex-end">
          <CloseIcon className={classes.closeIcon} onClick={toggleModalConfirm} />
        </Box>
        <Typography align="center" className={classes.titleDialog}>
          {titleDialog}
        </Typography>
        <Typography align="center" className={classes.subtitleDialog}>
          {subtitleDialog}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {(currentCard.toLaneId === deviationConstants.status.analyzed.key) && (
        <BoardModalAnalyzedDeviationForm
          data={currentCard}
          target={currentCard.toLaneId}
          setRefToTheForm={setRefToTheForm}
          eventBus={eventBus}
        />
        )}
        {(currentCard.toLaneId === deviationConstants.status.correctiveActionDefined.key) && (
        <BoardModalCorrectiveActionDefinedDeviationForm
          data={currentCard}
          target={currentCard.toLaneId}
          setRefToTheForm={setRefToTheForm}
          eventBus={eventBus}
        />
        )}
        {(currentCard.toLaneId === deviationConstants.status.correctiveActionImplemented.key) && (
        <BoardModalCorrectiveActionImplementedDeviationForm
          data={currentCard}
          target={currentCard.toLaneId}
          setRefToTheForm={setRefToTheForm}
          eventBus={eventBus}
        />
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.buttonDialog }}>
        <Button
          text={cancelButtonText}
          variant="outlined"
          onClick={() => toggleModalConfirm()}
        />
        <Button
          text={continueButtonText}
          loading={updatingDeviation}
          onClick={() => refToTheForm && refToTheForm.current && refToTheForm.current.submit()}
        />
      </DialogActions>
    </Dialog>
  );
};

ModalDragAndDropConfirm.propTypes = {
  currentCard: PropTypes.shape().isRequired,
  toggleModalConfirm: PropTypes.func.isRequired,
  eventBus: PropTypes.shape().isRequired,
  openModal: PropTypes.bool.isRequired,
};

export default ModalDragAndDropConfirm;
