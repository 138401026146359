const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color', 'background', 'font', 'size',
];

const initialFieldValues = {
    comment: '',
    editableHtml: '',
    file: '',
    reviewerId: '',
    reviewerUserNames: '',
    title: '',
    versionMissing: false,
    versionName: '',
    versions: [],
};

const toolbarOptions = [
    [{ font: [] }],
    [{ size: ['small', false, 'large', 'huge'] }],

    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],

    ['blockquote'],

    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],

    ['link', 'image'],
    ['clean'],
];

const modules = {
    toolbar: toolbarOptions,
};

export {
    formats,
    initialFieldValues,
    modules,
    toolbarOptions,
}
