import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { deviationActions } from '../../../redux/actions';
import BulkCreation from '../../BulkUpload/BulkCreation';
import { downloadHelper } from '../../../helpers';

const DeviationBulkCreation = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const linkDownload = downloadHelper.listDeviationsExample;
  const linkEntity = '/deviations';
  const text = {
    title: intl.formatMessage({ id: 'deviation.title', defaultMessage: 'Desvíos' }),
    bulkCreationTitle: intl.formatMessage({ id: 'deviation.bulkCreationTitle', defaultMessage: 'Carga múltiple de desvíos' }),
    bulkCreationBreadcrumbs: intl.formatMessage({ id: 'deviation.bulkCreationBreadcrumbs', defaultMessage: 'Carga múltiple' }),
    bulkCreationSubtitle: intl.formatMessage({ id: 'deviation.bulkCreationSubtitle', defaultMessage: 'Usted puede crear varios desvíos a la vez completando nuestra plantilla. ' }),
    bulkCreationSteps: intl.formatMessage({ id: 'deviation.bulkCreationSteps', defaultMessage: 'Pasos:' }),
    bulkCreationStep1: intl.formatMessage({ id: 'deviation.bulkCreationStep1', defaultMessage: '1. Descargue la plantilla de Excel.' }),
    bulkCreationStep2: intl.formatMessage({ id: 'deviation.bulkCreationStep2', defaultMessage: '2. Complete la plantilla con los datos de los desvíos deseados.' }),
    bulkCreationStep3: intl.formatMessage({ id: 'deviation.bulkCreationStep3', defaultMessage: '3. Cargue la plantilla en nuestro sistema aquí abajo.' }),
    downloadListExample: intl.formatMessage({ id: 'deviation.downloadUsersListExample', defaultMessage: 'Descargar plantilla' }),
    titlePageLayout: intl.formatMessage({ id: 'deviation.title', defaultMessage: 'Desvíos' }),
  };

  function handleDispatcher(formData) {
    return (
      dispatch(deviationActions.createBulkDeviations(formData))
    );
  }

  return (
    <BulkCreation
      text={text}
      linkEntity={linkEntity}
      linkDownload={linkDownload}
      handleDispatcher={handleDispatcher}
    />
  );
};

export default DeviationBulkCreation;
