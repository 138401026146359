import axios from '../axios/axios';
import { authHeader, userCompanySelectorHelper } from '../helpers';
import handler from './handler';

function getAllEvents() {
  const headers = authHeader();
  const company = userCompanySelectorHelper.getCurrentCompany();
  const getUrl = `companies/${company.id}/events`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getMonthlyEvents(startDate, endDate) {
  const headers = authHeader();
  const company = userCompanySelectorHelper.getCurrentCompany();
  const getUrl = `companies/${company.id}/events/${startDate}/${endDate}`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getEvents(eventType, eventId) {
  const headers = authHeader();
  const company = userCompanySelectorHelper.getCurrentCompany();
  const getUrl = `companies/${company.id}/events/${eventType}/${eventId}`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const calendarService = {
  getMonthlyEvents,
  getAllEvents,
  getEvents,
};

export default calendarService;
