import { objectiveConstants } from '../../constants';

function objective(state = {}, action) {
  switch (action.type) {
    case objectiveConstants.request.CREATE_REQUEST:
      return { creating: true };
    case objectiveConstants.request.CREATE_SUCCESS:
      return { created: true };
    case objectiveConstants.request.CREATE_FAILURE:
      return { error: true };

    case objectiveConstants.request.GET_ALL_REQUEST:
      return { requesting: true };
    case objectiveConstants.request.GET_ALL_SUCCESS:
      return { objectives: action.objective };
    case objectiveConstants.request.GET_ALL_FAILURE:
      return { error: true };

    case objectiveConstants.request.GET_REQUEST:
      return { requesting: true };
    case objectiveConstants.request.GET_SUCCESS:
      return { data: action.objective };
    case objectiveConstants.request.GET_FAILURE:
      return { error: true };

    case objectiveConstants.request.UPDATE_REQUEST:
      return { data: state.data, updating: true };
    case objectiveConstants.request.UPDATE_SUCCESS:
      return { updated: true };
    case objectiveConstants.request.UPDATE_FAILURE:
      return { data: state.data, error: true };

    case objectiveConstants.request.UPDATE_MEASURE_REQUEST:
      return { data: state.data, updatingMeasure: true };
    case objectiveConstants.request.UPDATE_MEASURE_SUCCESS:
      return { data: action.updatedObjective, updated: true };
    case objectiveConstants.request.UPDATE_MEASURE_FAILURE:
      return { data: state.data, error: true };

    case objectiveConstants.request.DELETE_REQUEST:
      return { data: state.data, deleting: true };
    case objectiveConstants.request.DELETE_SUCCESS:
      return { deleted: true };
    case objectiveConstants.request.DELETE_FAILURE:
      return { data: state.data, error: true };

    case objectiveConstants.request.CREATE_BULK_REQUEST:
      return { creating: true };
    case objectiveConstants.request.CREATE_BULK_SUCCESS:
      return { bulkCreationPreviewResult: action.bulkCreationPreviewResult };
    case objectiveConstants.request.CREATE_BULK_FAILURE:
      return { error: action.error };

    case objectiveConstants.request.CONFIRM_CREATE_BULK_REQUEST:
      return { ...state, confirming: true };
    case objectiveConstants.request.CONFIRM_CREATE_BULK_SUCCESS:
      return { bulkCreationPreviewResult: null, noLinkedProcesses: true };
    case objectiveConstants.request.CONFIRM_CREATE_BULK_FAILURE:
      return { error: action.error };

    default:
      return state;
  }
}

export default objective;
