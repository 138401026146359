import * as joint from 'jointjs';

export const OrgChartLink = joint.shapes.standard.Link.define('custom.OrgChartLink', {
  attrs: {
    line: {
      connection: true,
      stroke: '#696969',
      strokeWidth: 1,
      strokeLinejoin: 'round',
      targetMarker: {
        type: 'path',
        d: 'M 10 0 L 10 0 L 10 0 z',
      },
    },
    wrapper: {
      connection: true,
      strokeWidth: 10,
      strokeLinejoin: 'round',
    },
  },
}, {
  markup: [{
    tagName: 'path',
    selector: 'wrapper',
    attributes: {
      fill: 'none',
      cursor: 'pointer',
      stroke: 'transparent',
    },
  }, {
    tagName: 'path',
    selector: 'line',
    attributes: {
      fill: 'none',
      'pointer-events': 'none',
    },
  }],
});

export const OrgChartBox = joint.dia.Element.define('custom.OrgChartBox', {
  attrs: {
    root: {
      magnet: false,
    },
    body: {
      fill: '#FFFFFF',
      filter: 'url(#shadow)',
      height: 48,
      width: 162,
    },
    borderColor: {
      fill: '#F79A56',
      height: 48,
      width: 4,
    },
    name: {
      fill: '#333333',
      fontSize: 10,
      fontWeight: 600,
      ref: 'body',
      refX: '37%',
      refY: '35%',
      textAnchor: 'right',
      textVerticalAnchor: 'middle',
    },
    role: {
      fill: '#333333',
      fontSize: 10,
      fontWeight: 400,
      ref: 'body',
      refX: '37%',
      refY: '70%',
      textAnchor: 'right',
      textVerticalAnchor: 'middle',
    },
    image: {
      clipPath: 'url(#circleView)',
      height: 30,
      ref: 'body',
      refX: '11%',
      refY: '20%',
      width: 30,
    },
    tool: {
      event: 'customOrg:tool:pointerdown',
      fill: '#F8F8F8',
      magnet: false,
      r: 6,
      ref: 'body',
      refX: '50%',
      refY: '50%',
      stroke: '#F16C24',
      strokeWidth: 0,
    },
    dropShadow: {
      dx: '0',
      dy: '0',
      floodColor: '#BCBCBC',
      floodOpacity: '0.5',
      stdDeviation: '2',
    },
    circle: {
      cx: '15',
      cy: '15',
      r: '15',
    },
    person: {
      id: '',
      name: '',
      role: '',
      imageUrl: '',
      personId: '',
      isPersonalRole: false,
    },
  },
}, {
  markup: [{
    tagName: 'defs',
    children: [{
      tagName: 'filter',
      attributes: { id: 'shadow' },
      children: [{
        tagName: 'feDropShadow',
        selector: 'dropShadow',
      }],
    }],
  }, {
    tagName: 'rect',
    selector: 'body',
  }, {
    tagName: 'rect',
    selector: 'borderColor',
  }, {
    tagName: 'circle',
    selector: 'tool',
  }, {
    tagName: 'text',
    selector: 'role',
  }, {
    tagName: 'text',
    selector: 'name',
  }, {
    tagName: 'defs',
    children: [{
      tagName: 'clipPath',
      attributes: { id: 'circleView' },
      children: [{
        tagName: 'circle',
        selector: 'circle',
      }],
    }],
  }, {
    tagName: 'image',
    selector: 'image',
  }],
});

export function createPaper(canvas, graph) {
  return new joint.dia.Paper({
    el: canvas,
    width: '95%',
    height: 500,
    model: graph,
    cellViewNamespace: joint.shapes,
    gridSize: 5,
    snapLinks: true,
    linkPinning: true,
    embeddingMode: true,
    interactive: false,
    defaultLink() {
      return new OrgChartLink();
    },
    connectionStrategy(end) {
      return { id: end.id };
    },
    highlighting: {
      default: {
        name: 'stroke',
        options: {
          padding: 3,
          rx: 5,
          ry: 5,
          attrs: {
            'stroke-width': 2,
            stroke: '#F16C24',
          },
        },
      },
      embedding: {
        name: 'addClass',
        options: {
          className: 'highlighted-parent',
        },
      },
    },
    validateEmbedding(childView, parentView) {
      return parentView.model instanceof joint.shapes.devs.Coupled;
    },

    validateConnection(sourceView, sourceMagnet, targetView) {
      return sourceView !== targetView;
    },
  });
}

export const ProcessArea = joint.shapes.devs.Coupled.define('custom.ProcessArea', {
  attrs: {
    rect: {
      stroke: '#757575',
      fill: '#F8F8F8',
      strokeWidth: 0.2,
    },
    '.label': {
      'font-size': '18px',
      'font-weight': 'bold',
      style: '-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;',
    },
  },
  position: {
    x: 550,
    y: 50,
  },
  size: {
    width: 300,
    height: 300,
  },
});

export const ProcessBox = joint.shapes.basic.Rect.define('custom.ProcessBox', {
  attrs: {
    root: {
      magnet: false,
    },
    body: {
      strokeWidth: 0.7,
      stroke: '#757575',
      fill: '#F8F8F8',
      rx: 5,
      ry: 5,
      ref: 'label',
      cursor: 'default',
      visibility: 'visible',
      refX: '-12.5%',
      refY: '-50%',
      refWidth: '125%',
      refHeight: '200%',
    },
    label: {
      textVerticalAnchor: 'middle',
      textAnchor: 'middle',
      fontSize: 14,
      fill: 'black',
      style: '-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;',
      pointerEvents: 'none',
      visibility: 'visible',
    },
    tool: {
      event: 'processBox:tool:pointerdown',
      r: 6,
      fill: '#F8F8F8',
      strokeWidth: 0,
      stroke: '#F16C24',
      magnet: false,
    },
    process: {
      id: '',
      name: '',
      area: '',
    },
  },
}, {
  markup: [{
    tagName: 'rect',
    selector: 'body',
  }, {
    tagName: 'circle',
    selector: 'tool',
  }, {
    tagName: 'text',
    selector: 'label',
  }],
});
