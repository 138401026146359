/* eslint-disable max-len */
import React from 'react';
import {
  Box,
  Typography, makeStyles, Divider, Table, TableHead, TableRow, TableBody, TableCell,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { deviationTexts, deviationConstants } from '../../constants';

const useStyles = makeStyles((theme) => ({
  textSeparator: {
    marginLeft: '24px',
    marginTop: '40px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  historyTableDivider: {
    marginBottom: '16px',
    marginLeft: '24px',
  },
  table: {
    maxWidth: 900,
  },
  tableBody: {
    padding: '8px 16px 8px 16px',
    fontSize: '12px',
    cursor: 'default',
  },
  tableHead: {
    fontSize: '12px',
    paddingTop: '0px',
    fontWeight: 700,
    cursor: 'default',
  },
  tableLink: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontWeight: 600,
    cursor: 'pointer',
  },
}));

const DeviationVersionHistory = ({ deviationVersions, showDivider, deviationId }) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.textSeparator}>
        {deviationTexts.changeHistory}
      </Typography>
      {showDivider ? <Divider className={classes.historyTableDivider} /> : <Box mb={1.5} />}
      <Box px={3} pb={3}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead} size="small" style={{ paddingLeft: '0px' }}>{deviationTexts.state}</TableCell>
              <TableCell className={classes.tableHead} size="small">{deviationTexts.versionDate}</TableCell>
              <TableCell className={classes.tableHead} size="small">{deviationTexts.versionAuthor}</TableCell>
              <TableCell className={classes.tableHead} size="small" />
            </TableRow>
          </TableHead>
          <TableBody>
            {deviationVersions.map((version) => (
              <TableRow key={version.id}>
                <TableCell className={classes.tableBody} size="small" style={{ paddingLeft: '0px' }}>{deviationConstants.statusForm[version.currentState].label}</TableCell>
                <TableCell className={classes.tableBody} size="small">{moment(version.versionDate).format('DD-MM-YYYY HH:mm')}</TableCell>
                <TableCell className={classes.tableBody} size="small">{version.author}</TableCell>
                <TableCell className={`${classes.tableBody} ${classes.tableLink}`} size="small" component={Link} to={`/deviations/${deviationId}/${version.id}`}>{deviationTexts.viewVersionLink}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

DeviationVersionHistory.propTypes = {
  deviationVersions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  deviationId: PropTypes.string.isRequired,
  showDivider: PropTypes.bool,
};

DeviationVersionHistory.defaultProps = {
  showDivider: false,
};

export default DeviationVersionHistory;
