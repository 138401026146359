// functions extracted from the same mui-datatables package,
// https://github.com/gregnb/mui-datatables/blob/master/src/utils.js

function escapeDangerousCSVCharacters(row) {
  if (typeof row === 'string') {
    // Places single quote before the appearance of dangerous characters if they
    // are the first in the data string.
    return row.replace(/^\+|^-|^=|^@/g, "'$&");
  }

  return row;
}

const replaceDoubleQuoteInString = (columnData) => (typeof columnData === 'string' ? columnData.replace(/"/g, '""') : columnData);

const customBuildBody = (data, columns) => {
  if (!data.length) return '';
  return data
    .reduce((soFar, row) => `${soFar}"${
      row.data
        .map((columnData, index) => escapeDangerousCSVCharacters(replaceDoubleQuoteInString(
          columns[index].customDownloadBodyRender
            ? columns[index].customDownloadBodyRender(columnData)
            : columnData,
        )))
        .filter((_, index) => columns[index].download)
        .join('","')
    }"\r\n`, '')
    .trim();
};

const handleOnDownload = (buildHead, buildBody, columns, data, filename) => {
  const csvContent = buildHead(columns) + customBuildBody(data, columns);
  const a = document.createElement('a');
  const textFileAsBlob = new Blob(['\ufeff', csvContent], {
    type: 'text/csv;charset=UTF-8',
    encoding: 'UTF-8',
  });
  a.href = window.URL.createObjectURL(textFileAsBlob);
  a.download = filename;
  a.click();
  return false;
};

const dataTableHelper = {
  handleOnDownload,
};

export default dataTableHelper;
