import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions, Grid, InputAdornment, Typography,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PertixButton from '../controls/Button';
import { userActions } from '../../redux/actions';
import { validationConstants } from '../../constants';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    borderBottom: '1px solid #DADADA',
  },
  logo: {
    position: 'relative',
    width: '145px',
    height: '52px',
  },
  item: {
    fontSize: '11px',
    fontWeight: '400',
  },
  navbarItem: {
    fontSize: '11px',
    fontWeight: '500',
  },
}));

export default function SetNewPasswordModal(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.authentication.user);
  const updatingPassword = useSelector((state) => state.user.updating_password);
  const intl = useIntl();
  const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const saveButtonText = intl.formatMessage({ id: 'app.save', defaultMessage: 'Guardar' });
  const changePasswordText = intl.formatMessage({ id: 'app.password.change', defaultMessage: 'Modificar contraseña' });
  const passwordModalText = intl.formatMessage({ id: 'app.password.modalText', defaultMessage: 'Ingrese los datos correspondientes para modificar su contraseña' });
  const passwordCriteriaText = intl.formatMessage({ id: 'app.passwordCriteria', defaultMessage: 'La contraseña debe tener al menos 6 caracteres, 1 minúscula, 1 mayúscula, 1 caracter numérico y 1 caracter no alfanumérico' });
  const passUpdatedText = intl.formatMessage({ id: 'app.passwordUpdated', defaultMessage: 'La contraseña ha sido modificada correctamente' });
  const {
    isOpen, close, registration,
  } = props;
  const initialFieldValues = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    showPassword: false,
  };
  const [values, setValues] = useState(initialFieldValues);
  const {
    currentPassword, newPassword, showPassword,
  } = values;

  function handleSubmit(e) {
    e.preventDefault();
    setValues(initialFieldValues);
    if (user.mustChangePassword) {
      const userUpdated = { ...user, mustChangePassword: false };
      return dispatch(userActions.updatePasswordAndMustChange(
        user.email, currentPassword, newPassword, passUpdatedText, userUpdated,
      ));
    }
    return dispatch(userActions.updatePassword(
      user.email, currentPassword, newPassword, passUpdatedText,
    ));
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Dialog open={isOpen} onClose={close} fullWidth max-width="xs" aria-labelledby="form-dialog-title">

      <DialogTitle id="form-dialog-title">
        {changePasswordText}
      </DialogTitle>

      <ValidatorForm style={{ width: '100%' }} onSubmit={handleSubmit} instantValidate>

        <DialogContent>
          <DialogContentText>
            {passwordModalText}
          </DialogContentText>

          <Grid container>
            <Grid item xs={12}>
              <TextValidator
                value={currentPassword}
                onChange={handleChange}
                fullWidth
                margin="normal"
                name="currentPassword"
                label={<FormattedMessage id="app.currentPassword" defaultMessage="Contraseña actual" />}
                type={showPassword ? 'text' : 'password'}
                id="currentPassword"
                autoComplete="current-password"
                validators={['required']}
                errorMessages={[validationConstants.PASSWORD_REQUIRED]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        tabIndex="-1"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextValidator
                value={newPassword}
                onChange={handleChange}
                fullWidth
                margin="normal"
                name="newPassword"
                label={<FormattedMessage id="app.newPassword" defaultMessage="Nueva contraseña" />}
                type={showPassword ? 'text' : 'password'}
                id="newPassword"
                autoComplete="current-password"
                validators={['required', validationConstants.PASSWORD_REGEX]}
                errorMessages={
                  [
                    validationConstants.PASSWORD_REQUIRED,
                    validationConstants.PASSWORD_MATCH_PATTERN,
                  ]
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        tabIndex="-1"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography color="textSecondary" variant="caption">{passwordCriteriaText}</Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {!registration && (
            <PertixButton
              variant="outlined"
              text={cancelButtonText}
              onClick={close}
            />
          )}

          <PertixButton
            type="submit"
            className={classes.menuButton}
            text={saveButtonText}
            loading={updatingPassword}
          />
        </DialogActions>

      </ValidatorForm>
    </Dialog>
  );
}
