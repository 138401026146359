const initialDocumentState = {
  documentInfo: {
    content: '',
    fileType: '',
    title: '',
    uri: '',
  },
};

const selectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: '50vh',
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  getContentAnchorEl: null,
};

const getActualVersion = (document) => {
  const actualVersion = document.versions
    ? document.versions.find((version) => version.id === document.actualDocumentVersionId)
    : '';

  return actualVersion;
};

const handleClickOnDocument = ({
  document,
  setDocumentState,
  setIsPreviewModalOpen,
  version
}) => {
  const lastVersion = document?.lastVersion || version;
  const { editableHtml, extension: fileType } = lastVersion;
  const { blobUrl: uri, title } = document;

  setDocumentState({
    documentInfo: {
      content: editableHtml,
      fileType,
      title,
      uri,
    }
  });
  setIsPreviewModalOpen(true);
};

export {
  getActualVersion,
  handleClickOnDocument,
  initialDocumentState,
  selectMenuProps
};
