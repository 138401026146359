import React, { useEffect } from 'react';
import {
  Breadcrumbs, Typography, LinearProgress,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PageLayout from '../../PageLayout';
import providerActions from '../../../redux/actions/provider.actions';
import { providersTexts } from '../../../constants';
import ProviderViewDetail from './ProviderViewDetail';

const ProviderDetailsPage = ({ routeParams: { id: providerId } }) => {
  const dispatch = useDispatch();
  const provider = useSelector((state) => state.provider.data);

  useEffect(() => {
    dispatch(providerActions.get(providerId));
  }, [dispatch, providerId]);

  function ProviderDetailsBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/providers" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{providersTexts.providers}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{provider.name}</Typography>
      </Breadcrumbs>
    );
  }

  function ProviderDetailsLoadingBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/providers" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{providersTexts.providers}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{providersTexts.providersDetails}</Typography>
      </Breadcrumbs>
    );
  }

  return (
    <>
      {provider
        ? (
          <PageLayout title={provider.name} breadCrumbs={<ProviderDetailsBreadcrumbs />}>
            <ProviderViewDetail data={provider} />
          </PageLayout>
        )
        : (
          <PageLayout
            title={providersTexts.providersDetails}
            breadCrumbs={<ProviderDetailsLoadingBreadcrumbs />}
          >
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
};

ProviderDetailsPage.propTypes = {
  routeParams: PropTypes.shape(),
};

ProviderDetailsPage.defaultProps = {
  routeParams: {},
};

export default ProviderDetailsPage;
