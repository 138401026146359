import { personConstants } from '../../constants';
import { personService } from '../../services';
import { history } from '../../helpers';
import alertActions from './alert.actions';

function create(person, redirectUrl = null) {
  function request(personToCreate) {
    return {
      type: personConstants.CREATE_REQUEST, personToCreate,
    };
  }
  function success(createdPerson) {
    return {
      type: personConstants.CREATE_SUCCESS, createdPerson,
    };
  }
  function failure(error) { return { type: personConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(person));

    personService.create(person)
      .then(
        (createdPerson) => {
          dispatch(success(createdPerson));
          if (redirectUrl) { history.replace(`${redirectUrl}${createdPerson.id}`); }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getAll() {
  function request() { return { type: personConstants.GET_ALL_REQUEST }; }
  function success(people) { return { type: personConstants.GET_ALL_SUCCESS, people }; }
  function failure(error) { return { type: personConstants.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    personService.getAll()
      .then(
        (people) => {
          dispatch(success(people));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function get(id) {
  function request() { return { type: personConstants.GET_REQUEST }; }
  function success(person) { return { type: personConstants.GET_SUCCESS, person }; }
  function failure(error) { return { type: personConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    personService.get(id)
      .then(
        (person) => {
          dispatch(success(person));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
          history.push('/home');
        },
      );
  };
}

function update(person, personId, redirectUrl = null) {
  function request(personToUpdate) {
    return {
      type: personConstants.UPDATE_REQUEST, personToUpdate,
    };
  }
  function success(updatedPerson) {
    return {
      type: personConstants.UPDATE_SUCCESS, updatedPerson,
    };
  }
  function failure(error) { return { type: personConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(person));

    personService.update(person, personId)
      .then(
        (updatedPerson) => {
          dispatch(success(updatedPerson));
          if (redirectUrl) { history.push(`${redirectUrl}${personId}`); }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function deletePerson(personId) {
  function request() { return { type: personConstants.DELETE_REQUEST }; }
  function success() { return { type: personConstants.DELETE_SUCCESS }; }
  function failure() { return { type: personConstants.DELETE_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    personService.deletePerson(personId)
      .then(
        () => {
          dispatch(success());
          history.push('/people');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getWithDocuments(id) {
  function request() { return { type: personConstants.GET_REQUEST }; }
  function success(person) { return { type: personConstants.GET_SUCCESS, person }; }
  function failure(error) { return { type: personConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    personService.getWithDocuments(id)
      .then(
        (person) => {
          dispatch(success(person));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function uploadPersonalPhoto(fd, personId) {
  function request(personalPhoto) {
    return { type: personConstants.PERSONAL_PHOTO_REQUEST, personalPhoto };
  }
  function success(person) {
    return { type: personConstants.PERSONAL_PHOTO_SUCCESS, person };
  }
  function failure(error) {
    return { type: personConstants.PERSONAL_PHOTO_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request({ personId }));
    personService.uploadPersonalPhoto(fd, personId)
      .then(
        (person) => {
          dispatch(success(person));
          dispatch(alertActions.info('Se ha cargado la foto correctamente'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function linkUser(personId, userId) {
  function request() { return { type: personConstants.LINK_USER_REQUEST }; }
  function success(result) {
    return { type: personConstants.LINK_USER_SUCCESS, result };
  }
  function failure(error) {
    return { type: personConstants.LINK_USER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    personService.linkUser(personId, userId)
      .then(
        (result) => {
          dispatch(success(result));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function unlinkUser(personId) {
  function request() { return { type: personConstants.UNLINK_USER_REQUEST }; }
  function success(result) {
    return { type: personConstants.UNLINK_USER_SUCCESS, result };
  }
  function failure(error) {
    return { type: personConstants.UNLINK_USER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    personService.unlinkUser(personId)
      .then(
        (result) => {
          dispatch(success(result));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

const personActions = {
  create,
  get,
  getAll,
  update,
  deletePerson,
  getWithDocuments,
  uploadPersonalPhoto,
  linkUser,
  unlinkUser,
};

export default personActions;
