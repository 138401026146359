import React from 'react';
import { FormattedMessage } from 'react-intl';

const objectiveTexts = {
  edit: <FormattedMessage id="app.edit" defaultMessage="Editar" />,
  cancel: <FormattedMessage id="app.cancel" defaultMessage="Cancelar" />,
  backText: <FormattedMessage id="app.back" defaultMessage="Volver" />,
  saveText: <FormattedMessage id="app.save" defaultMessage="Guardar" />,
  createText: <FormattedMessage id="app.create" defaultMessage="Crear" />,
  titleLabel: <FormattedMessage id="app.title" defaultMessage="Título" />,
  deleteButton: <FormattedMessage id="app.delete" defaultMessage="Eliminar" />,
  title: <FormattedMessage id="objective.title" defaultMessage="Objetivos" />,
  titleObjective: <FormattedMessage id="objective" defaultMessage="Objetivo" />,
  addButtonText: <FormattedMessage id="objective.addButton" defaultMessage="+ Añadir objetivo" />,
  deleteObjectiveContentDialog: <FormattedMessage id="objective.deleteObjectiveContentModal" defaultMessage="¿Está seguro que quiere eliminar este objetivo?" />,
  deleteObjectiveTitleDialog: <FormattedMessage id="objective.deleteObjectiveTitleModal" defaultMessage="Eliminar objetivo" />,
  isRequired: <FormattedMessage id="objective.isRequired" defaultMessage="Este campo es requerido para continuar" />,
  updateObjectiveTitle: <FormattedMessage id="objective.updateObjective" defaultMessage="Actualizar objetivo" />,
  actionPlanForCompliance: <FormattedMessage id="objective.actionPlanForCompliance" defaultMessage="Plan de acción para el cumplimiento" />,
  annualTarget: <FormattedMessage id="objective.annualTarget" defaultMessage="Objetivo anual" />,
  descriptionOfObjective: <FormattedMessage id="objective.objectiveDescription" defaultMessage="Descripción del objetivo" />,
  evaluationMethod: <FormattedMessage id="objective.evaluationMethod" defaultMessage="Método de evaluación" />,
  frequency: <FormattedMessage id="objective.frequency" defaultMessage="Frecuencia de medición" />,
  indicator: <FormattedMessage id="objective.indicator" defaultMessage="Indicador" />,
  necessaryResources: <FormattedMessage id="objective.necessaryResources" defaultMessage="Recursos necesarios" />,
  noProcessesToSelect: <FormattedMessage id="objective.noProcessesToSelect" defaultMessage="No hay procesos para seleccionar" />,
  processes: <FormattedMessage id="objective.processes" defaultMessage="Procesos" />,
  processesLoading: <FormattedMessage id="objective.processesLoading" defaultMessage="Cargando procesos" />,
  responsibleForCompliance: <FormattedMessage id="objective.responsibleForCompliance" defaultMessage="Responsable de cumplimiento" />,
  selectOption: <FormattedMessage id="objective.selectOption" defaultMessage="Seleccione una opción" />,
  successfulDelete: <FormattedMessage id="objective.successfulDelete" defaultMessage="Se ha eliminado con éxito" />,
  successfulSave: <FormattedMessage id="objective.successfulSave" defaultMessage="Se ha creado con éxito" />,
  successfulUpdate: <FormattedMessage id="objective.successfulUpdate" defaultMessage="Se ha actualizado correctamente" />,
  objectiveTitleText: <FormattedMessage id="app.title" defaultMessage="Título" />,
  objectiveAnnualTargetText: <FormattedMessage id="objective.annualTarget" defaultMessage="Objetivo anual" />,
  objectiveProcessesText: <FormattedMessage id="objective.processes" defaultMessage="Procesos" />,
  objectiveFrequencyText: <FormattedMessage id="objective.frequency" defaultMessage="Frecuencia de medición" />,
  objectiveTableTitleText: <FormattedMessage id="objective.linkedObjectives" defaultMessage="Objetivos vinculados" />,
  objectiveLinkLabel: <FormattedMessage id="objective.see" defaultMessage="Ver objetivo" />,
  noProcessObjectiveText: <FormattedMessage id="objective.noProcessObjective" defaultMessage="No hay objetivos asociados al proceso" />,
  measurementDay: <FormattedMessage id="objective.measurementDay" defaultMessage="Día de medición" />,
  withoutMeasure: <FormattedMessage id="objective.withoutMeasure" defaultMessage="Sin medición" />,
  isAccumulativeObjective: <FormattedMessage id="objective.isAccumulativeObjective" defaultMessage="Objetivo acumulativo" />,
  accumulativeTotal: <FormattedMessage id="objective.accumulativeTotal" defaultMessage="Total acumulado" />,

  bulkCreationTitle: <FormattedMessage id="objective.bulkCreationTitle" defaultMessage="Carga múltiple de objetivos" />,
  bulkCreationBreadcrumbs: <FormattedMessage id="objective.bulkCreationBreadcrumbs" defaultMessage="Carga múltiple" />,
  downloadObjectivesListExample: <FormattedMessage id="objective.downloadObjectivesListExample" defaultMessage="Descargar plantilla" />,
  bulkCreationSubtitle: <FormattedMessage id="objective.bulkCreationSubtitle" defaultMessage="Usted puede crear varios objetivos a la vez completando nuestra plantilla. " />,
  bulkCreationSteps: <FormattedMessage id="objective.bulkCreationSteps" defaultMessage="Pasos:" />,
  bulkCreationStep1: <FormattedMessage id="objective.bulkCreationStep1" defaultMessage="1. Descargue la plantilla de Excel." />,
  bulkCreationStep2: <FormattedMessage id="objective.bulkCreationStep2" defaultMessage="2. Complete la plantilla con los datos de los objetivos deseadas." />,
  bulkCreationStep3: <FormattedMessage id="objective.bulkCreationStep3" defaultMessage="3. Cargue la plantilla en nuestro sistema aquí abajo." />,

  createObjective: <FormattedMessage id="objective.createBulkObjectives" defaultMessage="Crear objetivos" />,
  bulkObjectivesNotCreated: <FormattedMessage id="objective.bulkObjectivesNotCreated" defaultMessage="Estos objetivos no serán creados" />,
  bulkObjectivesUploadedSuccess: <FormattedMessage id="objective.bulkObjectivesUploadedSuccess" defaultMessage="Objetivos cargados correctamente:" />,
  onlySuccessUploadObjectivesWillBeCreated: <FormattedMessage id="objective.onlySuccessUploadObjectivesWillBeCreated" defaultMessage="Solo se crearán los objetivos cargados correctamente." />,
  emptyListSuccessfulUploadObjectives: <FormattedMessage id="objective.emptyListSuccessfulUploadObjectives" defaultMessage="No hay objetivos cargados correctamente, por favor vuelva a cargar los datos respetando el archivo de ejemplo." />,
  messageUploadedSuccess: <FormattedMessage id="objective.messageUploadedSuccess" defaultMessage="Los objetivos se han agregado correctamente" />,
  rowTitle: <FormattedMessage id="objective.row" defaultMessage="Fila" />,
  errorTitle: <FormattedMessage id="objective.error" defaultMessage="Error" />,
  yearTitle: <FormattedMessage id="objective.year" defaultMessage="Año" />,
  monthTitle: <FormattedMessage id="objective.month" defaultMessage="Mes" />,
  measureTitle: <FormattedMessage id="objective.measureTitle" defaultMessage="Medición" />,
  generalInformation: <FormattedMessage id="objective.generalInformation" defaultMessage="Información general" />,
  valueTitle: <FormattedMessage id="objective.value" defaultMessage="Valor" />,
  newMeasure: <FormattedMessage id="objective.newMeasure" defaultMessage="AGREGAR NUEVA MEDICIÓN" />,
  lastMeasure: <FormattedMessage id="objective.lastMeasure" defaultMessage="Última medición" />,

  january: <FormattedMessage id="objective.january" defaultMessage="Enero" />,
  february: <FormattedMessage id="objective.february" defaultMessage="Febrero" />,
  march: <FormattedMessage id="objective.march" defaultMessage="Marzo" />,
  april: <FormattedMessage id="objective.april" defaultMessage="Abril" />,
  may: <FormattedMessage id="objective.may" defaultMessage="Mayo" />,
  june: <FormattedMessage id="objective.june" defaultMessage="Junio" />,
  july: <FormattedMessage id="objective.july" defaultMessage="Julio" />,
  august: <FormattedMessage id="objective.august" defaultMessage="Agosto" />,
  september: <FormattedMessage id="objective.september" defaultMessage="Septiembre" />,
  october: <FormattedMessage id="objective.october" defaultMessage="Octubre" />,
  november: <FormattedMessage id="objective.november" defaultMessage="Noviembre" />,
  december: <FormattedMessage id="objective.december" defaultMessage="Diciembre" />,
  addMeasure: <FormattedMessage id="objective.addMeasure" defaultMessage="NUEVA MEDICIÓN" />,
  inputMeasure: <FormattedMessage id="objective.inputMeasure" defaultMessage="Ingrese la nueva medición correspondiente" />,
  measure: <FormattedMessage id="objective.measure" defaultMessage="Medir" />,
  seeMeasure: <FormattedMessage id="objective.seeMeasure" defaultMessage="Ver medición" />,
  complies: <FormattedMessage id="objective.complies" defaultMessage="Cumple" />,
  doesNotComply: <FormattedMessage id="objective.doesNotComply" defaultMessage="No cumple" />,
  booleanOperator: <FormattedMessage id="objective.booleanOperator" defaultMessage="Cumple / No cumple" />,
};

export default objectiveTexts;
