/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
  Box, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, FormControl, Grid, IconButton, LinearProgress, MenuItem,
  Typography, makeStyles, Select, InputLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/Clear';
import pickBy from 'lodash-es/pickBy';
import moment from 'moment';
import queryString from 'query-string';
import Button from '../controls/Button';
import { deviationActions, providerActions, workFieldEvaluationActions } from '../../redux/actions';
import { personService, processService } from '../../services';
import { deviationTexts, deviationConstants, workFieldEvaluationTexts } from '../../constants';
import DatePickerValidator from '../controls/DatePickerValidator';
import DeviationVersionHistory from './DeviationVersionHistory';
import DeviationDocuments from './DeviationDocuments';
import { history, workFieldHelper } from '../../helpers';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  getContentAnchorEl: null,
};

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  formControl: {
    width: '100%',
    marginTop: '16px',
    textAlign: 'left',
  },
  formControlStatus: {
    width: '100%',
    textAlign: 'left',
  },
  textArea: {
    '& .MuiInputBase-inputMultiline': {
      backgroundColor: 'rgba(255, 255, 255) ',
      minHeight: '75px',
      alignItems: 'flex-start',
      padding: 8,
    },
  },
  textSeparator: {
    marginLeft: '24px',
    marginTop: '40px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  textSeparatorWithoutPT: {
    marginLeft: '24px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  labelAreaText: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '12px',
    textAlign: 'left',
  },
  labelAreaTextPotentialCause: {
    fontSize: '12px',
    lineHeight: '12px',
    textAlign: 'left',
  },
  textLabel: {
    '& .MuiFormLabel-root': {
      fontSize: '12px',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px) scale(1)',
      textAlign: 'left',
    },
  },
  inputContainer: {
    padding: '0 18px',
  },
  leftDatePicker: {
    marginLeft: '18px',
    [theme.breakpoints.down('xs')]: {
      margin: '0 18px',
    },
  },
  rightDatePicker: {
    marginRight: '18px',
    [theme.breakpoints.down('xs')]: {
      margin: '0 18px',
    },
  },
  customInputLabel: {
    'label + &': { marginTop: '24px' },
  },
}));

const DeviationForm = ({ edit, data }) => {
  const initialFieldValues = {
    ...data,
    archive: false,
    archiveDeviationDialog: false,
    deleteDeviationDialog: false,
    processes: data.processes ? data.processes : [],
    detectionDate: data.detectionDate ? data.detectionDate : null,
    currentState: data.currentState ? data.currentState : 'documented',
    plannedImplementationDate: data.plannedImplementationDate ? data.plannedImplementationDate : null,
    effectiveImplementationDate: data.effectiveImplementationDate ? data.effectiveImplementationDate : null,
    expectedEffectivenessVerificationDate: data.expectedEffectivenessVerificationDate ? data.expectedEffectivenessVerificationDate : null,
    effectivenessVerificationDate: data.effectivenessVerificationDate ? data.effectivenessVerificationDate : null,
    effectivenessVerified: data.effectivenessVerificationDate ? data.effectivenessVerified : null,
    deviationVersions: data.deviationVersions ? data.deviationVersions : [],
    documents: data.documents ? data.documents : [],
    workFieldEvaluationId: data.workFieldEvaluationId ? data.workFieldEvaluationId : null,
    selectedProvider: null,
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const creatingDeviations = useSelector((state) => state.deviation.creating);
  const updatingDeviations = useSelector((state) => state.deviation.updating);
  const archivingDeviations = useSelector((state) => state.deviation.archiving);
  const deletingDeviation = useSelector((state) => state.deviation.deleting);
  const workFieldEvaluationUnapproved = useSelector((state) => state.workFieldEvaluation.workFieldEvaluations);
  const providers = useSelector((state) => state.provider.providers);
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const [deviationDetails, setDeviationDetails] = useState(initialFieldValues);
  const [people, setPeople] = useState([]);
  const [process, setProcess] = useState([]);
  const [archiveSelected, setArchiveSelected] = useState(false);
  const parsedQueryString = queryString.parse(history.location.search);
  const [queryParams] = useState({ providerId: parsedQueryString.providerId, evaluationId: parsedQueryString.evaluationId });

  useEffect(() => {
    const fetchData = async () => {
      const resPersonService = await personService.getAll();
      setPeople(resPersonService);
      const resProcessService = await processService.getAll();
      setProcess(resProcessService);
      dispatch(workFieldEvaluationActions.getAllUnapproved());
      dispatch(providerActions.getAll());
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (edit && data && data.workFieldEvaluationId && workFieldEvaluationUnapproved && providers) {
      const workFieldEvaluation = workFieldEvaluationUnapproved.find((item) => item.id === data.workFieldEvaluationId);
      if (workFieldEvaluation) {
        const provider = providers.find((p) => p.id === workFieldEvaluation.provider.id);
        if (provider) {
          setDeviationDetails((prev) => ({ ...prev, selectedProvider: provider.id }));
        }
      }
    }
    if (queryParams.providerId && providers && !deviationDetails.selectedProvider) {
      const selectedProvider = providers.find((p) => p.id.toString() === queryParams.providerId);
      if (selectedProvider) {
        setDeviationDetails((prev) => ({ ...prev, selectedProvider: selectedProvider.id }));
      }
    }
    if (queryParams.evaluationId && workFieldEvaluationUnapproved && !deviationDetails.workFieldEvaluationId) {
      const selectedEvaluation = workFieldEvaluationUnapproved.find((p) => p.id.toString() === queryParams.evaluationId);
      if (selectedEvaluation) {
        setDeviationDetails((prev) => ({ ...prev, workFieldEvaluationId: selectedEvaluation.id }));
      }
    }
  }, [edit, data, providers, workFieldEvaluationUnapproved, deviationDetails.selectedProvider, queryParams, deviationDetails.workFieldEvaluationId]);

  useEffect(() => {
    ValidatorForm.addValidationRule('needsToEffectivenessVerificationDate', (value) => !(deviationDetails.effectivenessVerificationDate && !value.toString()));
    ValidatorForm.addValidationRule('needsToEffectivenessVerified', (value) => !((deviationDetails.effectivenessVerified !== null) && !value));
    ValidatorForm.addValidationRule('passIfRuleIsUndefined', () => true);
  }, [deviationDetails.effectivenessVerificationDate, deviationDetails.effectivenessVerified]);

  function handleCreateDeviations() {
    const deviationsForm = pickBy(deviationDetails);
    dispatch(
      deviationActions.addDeviation(
        deviationsForm,
        deviationTexts.successfulSave,
      ),
    );
  }

  function handleSaveEditDeviations() {
    dispatch(
      deviationActions.updateDeviationForm(
        data.id,
        deviationDetails,
        deviationTexts.successfulUpdate,
      ),
    );
  }

  function handleChangeFormData(e) {
    const { name, value } = e.target;
    setDeviationDetails({ ...deviationDetails, [name]: value });
  }

  function handleChangeProvider(e) {
    const { value } = e.target;
    setDeviationDetails({ ...deviationDetails, selectedProvider: value, workFieldEvaluationId: null });
  }

  function handleDialogOpen(dialogName) {
    setDeviationDetails({ ...deviationDetails, [dialogName]: true });
  }

  function handleDialogClose(dialogName) {
    setDeviationDetails({ ...deviationDetails, [dialogName]: false });
  }

  function handleDeleteDeviations(id) {
    dispatch(deviationActions.deleteDeviations(id, deviationTexts.successfulDelete));
  }

  function handleSubmitForm(e) {
    e.preventDefault();
    if (archiveSelected) return handleDialogOpen('archiveDeviationDialog');
    if (edit) return handleSaveEditDeviations();
    return handleCreateDeviations();
  }

  function handleClearDate(e, nameDate) {
    e.stopPropagation();
    setDeviationDetails({ ...deviationDetails, [nameDate]: null });
  }

  function handleArchiveDeviations() {
    dispatch(
      deviationActions.archiveDeviation(
        data.id,
        { ...deviationDetails, currentState: deviationConstants.status.archived.key },
        deviationTexts.successfulArchive,
      ),
    );
  }

  return (
    <div className={classes.main}>
      {process ? (
        <ValidatorForm onSubmit={(e) => { handleSubmitForm(e); }} autoComplete="off" className={classes.textLabel} instantValidate>
          <Grid container spacing={3} align="center">
            <Grid item xs={12} md={6}>
              <Box className={classes.inputContainer}>
                <FormControl className={classes.formControlStatus}>
                  <SelectValidator
                    label={deviationTexts.state}
                    id="currentState-select"
                    name="currentState"
                    value={deviationDetails.currentState}
                    onChange={handleChangeFormData}
                    fullWidth
                    validators={['required']}
                    errorMessages={[deviationTexts.deviationStatusRequired]}
                  >
                    <MenuItem disabled style={{ fontSize: '12px' }} value="">
                      <em>{deviationTexts.selectOption}</em>
                    </MenuItem>
                    {Object.entries(deviationConstants.statusForm).map(([key, value], index) => {
                      if (value.key === deviationConstants.status.archived.key) {
                        if (data.currentState !== deviationConstants.status.archived.key) return null;
                        return (
                          <MenuItem style={{ fontSize: '12px' }} value={value.key}>
                            {value.label}
                          </MenuItem>
                        );
                      }
                      return (
                        <MenuItem style={{ fontSize: '12px' }} key={`select-${key + index}`} value={value.key}>
                          {value.label}
                        </MenuItem>
                      );
                    })}
                  </SelectValidator>
                </FormControl>
              </Box>
            </Grid>
            {providers && providers.length > 0 && (
              <Grid item xs={12} md={6}>
                <Box className={classes.inputContainer}>
                  <FormControl className={classes.formControlStatus}>
                    <InputLabel shrink={deviationDetails.selectedProvider} id="selectedProvider">{workFieldEvaluationTexts.relatedProviderOptional}</InputLabel>
                    <Select
                      disabled={edit}
                      id="selectedProvider"
                      name="selectedProvider"
                      labelId="selectedProvider"
                      value={deviationDetails.selectedProvider}
                      displayEmpty
                      onChange={handleChangeProvider}
                      MenuProps={MenuProps}
                      style={{ width: '100%' }}
                    >
                      <MenuItem disabled style={{ fontSize: '12px' }} value="">
                        <em>{workFieldEvaluationTexts.selectOption}</em>
                      </MenuItem>
                      {providers.map((provider) => (
                        <MenuItem key={provider.id} value={provider.id} style={{ fontSize: '12px' }}>
                          {provider.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            )}
            {deviationDetails.selectedProvider && (
              <>
                <Grid item xs={12} md={6} />
                <Grid item xs={12} md={6}>
                  <Box className={classes.inputContainer}>
                    <FormControl className={classes.formControlStatus}>
                      <InputLabel shrink={deviationDetails.workFieldEvaluationId} id="workFieldEvaluationId">{workFieldEvaluationTexts.relatedEvaluation}</InputLabel>
                      <Select
                        disabled={edit}
                        id="workFieldEvaluationId"
                        name="workFieldEvaluationId"
                        labelId="workFieldEvaluationId"
                        value={deviationDetails.workFieldEvaluationId}
                        displayEmpty
                        onChange={handleChangeFormData}
                        MenuProps={MenuProps}
                        style={{ width: '100%' }}
                      >
                        <MenuItem disabled style={{ fontSize: '12px' }} value="">
                          <Grid container>
                            <Grid item xs={4}><b><em>{workFieldEvaluationTexts.date}</em></b></Grid>
                            <Grid item xs={4}><b><em>{workFieldEvaluationTexts.workFieldEvaluated}</em></b></Grid>
                            <Grid item xs={4}><b><em>{workFieldEvaluationTexts.resultEvaluation}</em></b></Grid>
                          </Grid>
                        </MenuItem>
                        {workFieldEvaluationUnapproved
                          && workFieldEvaluationUnapproved
                            .filter((e) => e.provider.id === deviationDetails.selectedProvider)
                            .sort((a, b) => new Date(b.date) - new Date(a.date))
                            .map((evaluation) => (
                              <MenuItem key={evaluation.id} value={evaluation.id} style={{ fontSize: '12px' }}>
                                <Grid container>
                                  <Grid item xs={4}>{moment(evaluation.date).format('DD/MM/YYYY')}</Grid>
                                  <Grid item xs={4}>{evaluation.workField.name}</Grid>
                                  <Grid item xs={4}>{workFieldHelper.getEvaluationStatusLabel(evaluation.evaluationStatus)}</Grid>
                                </Grid>
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>

          <Typography className={deviationDetails.selectedProvider ? classes.textSeparatorWithoutPT : classes.textSeparator}>
            {deviationTexts.generalInformation}
          </Typography>
          <Grid container spacing={3} align="center">
            <Grid item xs={12} md={6}>
              <Box className={classes.inputContainer}>
                <TextValidator
                  fullWidth
                  id="title"
                  label={deviationTexts.title}
                  margin="normal"
                  name="title"
                  onChange={handleChangeFormData}
                  size="small"
                  value={deviationDetails.title}
                  validators={[deviationConstants.statusForm[deviationDetails.currentState].rules.title || 'passIfRuleIsUndefined']}
                  errorMessages={[deviationTexts.deviationTitleRequired]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.inputContainer}>
                <FormControl className={classes.formControl}>
                  <SelectValidator
                    fullWidth
                    id="origin-select"
                    label={deviationTexts.origin}
                    name="origin"
                    onChange={handleChangeFormData}
                    value={deviationDetails.origin}
                    validators={[deviationConstants.statusForm[deviationDetails.currentState].rules.origin || 'passIfRuleIsUndefined']}
                    errorMessages={[deviationTexts.deviationOriginRequired]}
                  >
                    <MenuItem style={{ fontSize: '12px' }} value="">
                      <em>{deviationTexts.selectOption}</em>
                    </MenuItem>
                    {Object.entries(deviationConstants.origin).map(([key, value], index) => (
                      <MenuItem style={{ fontSize: '12px' }} key={`select-${key + index}`} value={value.key}>
                        {value.label}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.inputContainer} align="flex-start">
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    filterSelectedOptions
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    id="processes-select"
                    multiple
                    noOptionsText={deviationTexts.noProcessesToSelect}
                    options={process}
                    size="small"
                    loading={!process.length}
                    loadingText={deviationTexts.processesLoading}
                    value={deviationDetails.processes}
                    onChange={(event, newValue) => {
                      setDeviationDetails({ ...deviationDetails, processes: [...newValue] });
                    }}
                    renderOption={(option) => <Typography noWrap style={{ fontSize: '12px' }}>{option.name}</Typography>}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        value={deviationDetails.processes}
                        variant="standard"
                        label={deviationTexts.processes}
                        placeholder="..."
                        style={{ fontSize: '12px' }}
                        validators={[deviationConstants.statusForm[deviationDetails.currentState].rules.processes || 'passIfRuleIsUndefined']}
                        errorMessages={[deviationTexts.deviationProcessesRequired]}
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.inputContainer}>
                <DatePickerValidator
                  cancelLabel={deviationTexts.cancel}
                  format="dd/MM/yyyy"
                  fullWidth
                  id="detectionDateId"
                  label={deviationTexts.detectionDate}
                  margin="normal"
                  minDateMessage={deviationTexts.incorrectDate}
                  name="detectionDate"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <IconButton style={{ padding: 5 }} onClick={(e) => handleClearDate(e, 'detectionDate')}>
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                  value={deviationDetails.detectionDate}
                  onChange={(value) => {
                    setDeviationDetails({ ...deviationDetails, detectionDate: value });
                  }}
                  validators={[deviationConstants.statusForm[deviationDetails.currentState].rules.detectionDate || 'passIfRuleIsUndefined']}
                  errorMessages={[deviationTexts.deviationDefaultRequired]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.inputContainer}>
                <Typography className={classes.labelAreaText}>
                  {deviationTexts.descriptionOfDeviation}
                </Typography>
                <TextValidator
                  classes={{ root: classes.textArea }}
                  fullWidth
                  id="descriptionId"
                  margin="normal"
                  multiline
                  name="description"
                  onChange={handleChangeFormData}
                  size="small"
                  style={{ marginTop: 8 }}
                  value={deviationDetails.description}
                  InputLabelProps={{ shrink: true }}
                  validators={[deviationConstants.statusForm[deviationDetails.currentState].rules.description || 'passIfRuleIsUndefined']}
                  errorMessages={[deviationTexts.deviationDefaultRequired]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.inputContainer}>
                <Typography className={classes.labelAreaText}>
                  {deviationTexts.immediateAction}
                </Typography>
                <TextValidator
                  classes={{ root: classes.textArea }}
                  style={{ marginTop: 8 }}
                  value={deviationDetails.immediateAction}
                  onChange={handleChangeFormData}
                  fullWidth
                  margin="normal"
                  id="immediateActionId"
                  name="immediateAction"
                  size="small"
                  multiline
                  InputLabelProps={{ shrink: true }}
                  validators={[deviationConstants.statusForm[deviationDetails.currentState].rules.immediateAction || 'passIfRuleIsUndefined']}
                  errorMessages={[deviationTexts.deviationDefaultRequired]}
                />
              </Box>
            </Grid>
          </Grid>
          <Typography className={classes.textSeparator} style={{ marginBottom: 12 }}>
            {deviationTexts.analysis}
          </Typography>
          <Grid container spacing={3} align="center">
            <Grid item xs={12} md={6}>
              <Box className={classes.inputContainer}>
                <Typography className={classes.labelAreaTextPotentialCause}>
                  {deviationTexts.potentialCauseBold}
                </Typography>
                <TextValidator
                  classes={{ root: classes.textArea }}
                  style={{ marginTop: 8 }}
                  value={deviationDetails.potentialCause}
                  onChange={handleChangeFormData}
                  fullWidth
                  margin="normal"
                  id="potentialCauseId"
                  name="potentialCause"
                  size="small"
                  multiline
                  InputLabelProps={{ shrink: true }}
                  validators={[deviationConstants.statusForm[deviationDetails.currentState].rules.potentialCause || 'passIfRuleIsUndefined']}
                  errorMessages={[deviationTexts.deviationDefaultRequired]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.inputContainer}>
                <Typography className={classes.labelAreaText}>
                  {deviationTexts.correctiveAction}
                </Typography>
                <TextValidator
                  classes={{ root: classes.textArea }}
                  style={{ marginTop: 8 }}
                  value={deviationDetails.correctiveAction}
                  onChange={handleChangeFormData}
                  fullWidth
                  margin="normal"
                  id="immediateAction"
                  name="correctiveAction"
                  size="small"
                  multiline
                  InputLabelProps={{ shrink: true }}
                  validators={[deviationConstants.statusForm[deviationDetails.currentState].rules.correctiveAction || 'passIfRuleIsUndefined']}
                  errorMessages={[deviationTexts.deviationDefaultRequired]}
                />
              </Box>
            </Grid>
          </Grid>
          <Typography className={classes.textSeparator}>
            {deviationTexts.implementationCorrectiveAction}
          </Typography>
          <Grid container spacing={3} align="center">
            <Grid item xs={12} md={6}>
              <Box className={classes.inputContainer}>
                <DatePickerValidator
                  cancelLabel={deviationTexts.cancel}
                  format="dd/MM/yyyy"
                  fullWidth
                  id="plannedImplementationDateId"
                  label={deviationTexts.plannedImplementationDate}
                  margin="normal"
                  name="plannedImplementationDate"
                  size="small"
                  value={deviationDetails.plannedImplementationDate}
                  onChange={(value) => {
                    setDeviationDetails({ ...deviationDetails, plannedImplementationDate: value });
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton style={{ padding: 5 }} onClick={(e) => handleClearDate(e, 'plannedImplementationDate')}>
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                  validators={[deviationConstants.statusForm[deviationDetails.currentState].rules.plannedImplementationDate || 'passIfRuleIsUndefined']}
                  errorMessages={[deviationTexts.deviationDefaultRequired]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.inputContainer}>
                <FormControl className={classes.formControl}>
                  <SelectValidator
                    label={deviationTexts.headOfImplementation}
                    id="headOfImplementation-select"
                    value={deviationDetails.headOfImplementation}
                    name="headOfImplementation"
                    onChange={handleChangeFormData}
                    fullWidth
                    validators={[deviationConstants.statusForm[deviationDetails.currentState].rules.headOfImplementation || 'passIfRuleIsUndefined']}
                    errorMessages={[deviationTexts.deviationDefaultRequired]}
                  >
                    <MenuItem style={{ fontSize: '12px' }} value={null}>
                      <em>{deviationTexts.selectOption}</em>
                    </MenuItem>
                    {people.map((item) => {
                      if (!item.names) return null;
                      return (
                        <MenuItem style={{ fontSize: '12px' }} key={`${item.id}${item.surnames}`} value={item.id}>
                          {`${item.names} ${item.surnames}`}
                        </MenuItem>
                      );
                    })}
                  </SelectValidator>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <Typography className={classes.textSeparator}>
            {deviationTexts.effectivenessVerificationTitle}
          </Typography>
          <Grid container spacing={3} align="center">
            <Grid item xs={12} sm={6} md={3}>
              <Box className={classes.leftDatePicker}>
                <DatePickerValidator
                  value={deviationDetails.effectiveImplementationDate}
                  onChange={(value) => {
                    setDeviationDetails(
                      { ...deviationDetails, effectiveImplementationDate: value },
                    );
                  }}
                  fullWidth
                  margin="normal"
                  id="effectiveImplementationDateId"
                  label={deviationTexts.effectiveImplementationDate}
                  name="effectiveImplementationDate"
                  size="small"
                  format="dd/MM/yyyy"
                  minDateMessage={deviationTexts.incorrectDate}
                  cancelLabel={deviationTexts.cancel}
                  InputProps={{
                    className: classes.customInputLabel,
                    endAdornment: (
                      <IconButton style={{ padding: 5 }} onClick={(e) => handleClearDate(e, 'effectiveImplementationDate')}>
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                  validators={[deviationConstants.statusForm[deviationDetails.currentState].rules.effectiveImplementationDate || 'passIfRuleIsUndefined']}
                  errorMessages={[deviationTexts.deviationDefaultRequired]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box className={classes.rightDatePicker}>
                <DatePickerValidator
                  cancelLabel={deviationTexts.cancel}
                  format="dd/MM/yyyy"
                  fullWidth
                  id="expectedEffectivenessVerificationDateId"
                  label={deviationTexts.expectedEffectivenessVerificationDate}
                  margin="normal"
                  minDateMessage={deviationTexts.incorrectDate}
                  name="expectedEffectivenessVerificationDate"
                  size="small"
                  value={deviationDetails.expectedEffectivenessVerificationDate}
                  onChange={(value) => {
                    setDeviationDetails(
                      { ...deviationDetails, expectedEffectivenessVerificationDate: value },
                    );
                  }}
                  InputProps={{
                    className: classes.customInputLabel,
                    endAdornment: (
                      <IconButton style={{ padding: 5 }} onClick={(e) => handleClearDate(e, 'expectedEffectivenessVerificationDate')}>
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                  validators={[deviationConstants.statusForm[deviationDetails.currentState].rules.expectedEffectivenessVerificationDate || 'passIfRuleIsUndefined']}
                  errorMessages={[deviationTexts.deviationDefaultRequired]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.inputContainer}>
                <FormControl className={classes.formControl}>
                  <SelectValidator
                    label={deviationTexts.headOfEffectivenessVerification}
                    id="headOfEffectivenessVerification-select"
                    value={deviationDetails.headOfEffectivenessVerification}
                    name="headOfEffectivenessVerification"
                    onChange={handleChangeFormData}
                    fullWidth
                    InputProps={{ className: classes.customInputLabel }}
                    validators={[deviationConstants.statusForm[deviationDetails.currentState].rules.headOfEffectivenessVerification || 'passIfRuleIsUndefined']}
                    errorMessages={[deviationTexts.deviationDefaultRequired]}
                  >
                    <MenuItem style={{ fontSize: '12px' }} value={null}>
                      <em>{deviationTexts.selectOption}</em>
                    </MenuItem>
                    {people.map((item) => {
                      if (!item.names) return null;
                      return (
                        <MenuItem style={{ fontSize: '12px' }} key={`${item.id}${item.surnames}`} value={item.id}>
                          {`${item.names} ${item.surnames}`}
                        </MenuItem>
                      );
                    })}
                  </SelectValidator>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.inputContainer}>
                <Typography className={classes.labelAreaText}>
                  {deviationTexts.effectivenessVerificationMethodology}
                </Typography>
                <TextValidator
                  classes={{ root: classes.textArea }}
                  style={{ marginTop: 8 }}
                  value={deviationDetails.effectivenessVerificationMethodology}
                  onChange={handleChangeFormData}
                  fullWidth
                  margin="normal"
                  id="effectivenessVerificationMethodology"
                  name="effectivenessVerificationMethodology"
                  size="small"
                  multiline
                  InputLabelProps={{ style: { fontSize: '12px' }, shrink: true }}
                  validators={['needsToEffectivenessVerified', 'needsToEffectivenessVerificationDate']}
                  errorMessages={[deviationTexts.deviationDefaultRequired, deviationTexts.deviationDefaultRequired]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box className={classes.leftDatePicker}>
                <DatePickerValidator
                  cancelLabel={deviationTexts.cancel}
                  format="dd/MM/yyyy"
                  fullWidth
                  id="effectivenessVerificationDateId"
                  label={deviationTexts.effectivenessVerificationDate}
                  margin="normal"
                  minDateMessage={deviationTexts.incorrectDate}
                  name="effectivenessVerificationDate"
                  size="small"
                  value={deviationDetails.effectivenessVerificationDate}
                  onChange={(value) => {
                    setDeviationDetails(
                      { ...deviationDetails, effectivenessVerificationDate: value },
                    );
                  }}
                  InputLabelProps={{ fontSize: '10px' }}
                  InputProps={{
                    endAdornment: (
                      <IconButton style={{ padding: 5 }} onClick={(e) => handleClearDate(e, 'effectivenessVerificationDate')}>
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                  validators={['needsToEffectivenessVerified']}
                  errorMessages={[deviationTexts.deviationEffectivenessVerificationDate]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box className={classes.rightDatePicker}>
                <FormControl className={classes.formControl}>
                  <SelectValidator
                    label={deviationTexts.effectivenessVerified}
                    id="effectivenessVerified-select"
                    value={deviationDetails.effectivenessVerified}
                    name="effectivenessVerified"
                    onChange={handleChangeFormData}
                    fullWidth
                    validators={['needsToEffectivenessVerificationDate']}
                    errorMessages={[deviationTexts.deviationEffectivenessVerified]}
                  >
                    <MenuItem style={{ fontSize: '12px' }} value={null}>
                      <em>{deviationTexts.selectOption}</em>
                    </MenuItem>
                    <MenuItem style={{ fontSize: '12px' }} key="select-effectivenessVerified-true" value>
                      {deviationTexts.positiveResult}
                    </MenuItem>
                    <MenuItem style={{ fontSize: '12px' }} key="select-effectivenessVerified-false" value={false}>
                      {deviationTexts.negativeResult}
                    </MenuItem>
                  </SelectValidator>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          {
            edit
            && <DeviationDocuments documents={deviationDetails.documents} deviationId={deviationDetails.id} />
          }

          {
            (edit
            && deviationDetails.deviationVersions.length)
              ? <DeviationVersionHistory deviationVersions={deviationDetails.deviationVersions} deviationId={deviationDetails.id} />
              : null
          }

          <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
            <Button
              component={Link}
              to="/deviations"
              variant="outlined"
              text={deviationTexts.backText}
              style={{ fontSize: '10px', fontWeight: 600 }}
            />
            {data.currentState !== deviationConstants.status.archived.key
              && (
                <>
                  <Button
                    type="submit"
                    onClick={() => { setArchiveSelected(true); }}
                    color="secondary"
                    text={deviationTexts.archive}
                    style={{ fontSize: '10px', fontWeight: 600 }}
                    disabled={!isAdmin}
                  />
                  <Dialog open={deviationDetails.archiveDeviationDialog} onClose={() => { handleDialogClose('archiveDeviationDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{deviationTexts.archiveDeviation}</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        {deviationTexts.archiveConfirm}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button text={deviationTexts.cancel} onClick={() => { handleDialogClose('archiveDeviationDialog'); }} />
                      <Button
                        className={classes.menuButton}
                        text={deviationTexts.archive}
                        color="secondary"
                        loading={archivingDeviations}
                        onClick={handleArchiveDeviations}
                      />
                    </DialogActions>
                  </Dialog>
                </>
              )}
            {edit
              ? (
                <>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => handleDialogOpen('deleteDeviationDialog')}
                    text={deviationTexts.deleteButton}
                    style={{ fontSize: '10px', fontWeight: 600 }}
                    disabled={!isAdmin}
                  />
                  <Button
                    type="submit"
                    onClick={() => { setArchiveSelected(false); }}
                    text={deviationTexts.saveText}
                    style={{ fontSize: '10px', fontWeight: 600 }}
                    loading={updatingDeviations}
                    disabled={!isAdmin}
                  />
                  <Dialog open={deviationDetails.deleteDeviationDialog} onClose={() => handleDialogClose('deleteDeviationDialog')} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{deviationTexts.deleteDeviationTitleDialog}</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        {deviationTexts.deleteDeviationContentDialog}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button text={deviationTexts.cancel} onClick={() => { handleDialogClose('deleteDeviationDialog'); }} />
                      <Button
                        text={deviationTexts.deleteButton}
                        color="secondary"
                        loading={deletingDeviation}
                        onClick={() => { handleDeleteDeviations(deviationDetails.id); }}
                      />
                    </DialogActions>
                  </Dialog>
                </>
              )
              : (
                <Button
                  type="submit"
                  onClick={() => { setArchiveSelected(false); }}
                  loading={creatingDeviations}
                  text={deviationTexts.saveText}
                  disabled={!isAdmin}
                />
              )}
          </Box>
        </ValidatorForm>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
};

DeviationForm.propTypes = {
  data: PropTypes.shape(),
  edit: PropTypes.bool,
};

DeviationForm.defaultProps = {
  data: {},
  edit: false,
};

export default DeviationForm;
