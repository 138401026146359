import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { Link } from 'react-router-dom';
import { TextValidator, ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import ChatIcon from '@material-ui/icons/Chat';
import Tooltip from '@material-ui/core/Tooltip';
import { trainingConstants, validationConstants } from '../../constants';
import { personActions, trainingActions } from '../../redux/actions';
import Button from '../controls/Button';
import TrainingDocuments from './TrainingDocuments';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  formControl: {
    width: '100%',
  },
  customInputLabel: {
    'label + &': { marginTop: '24px' },
  },
  formControlInputLabel: {
    paddingRight: '0px',
    '& .MuiInputLabel-marginDense': {
      transform: 'translate(0, 0px) scale(0.9)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, -9.5px) scale(0.75)',
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  getContentAnchorEl: null,
};

function TrainingForm({ edit, data }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const initialFieldValues = {
    title: data ? data.title : '',
    totalTime: data ? data.totalTime : '',
    dateFrom: data && data.dateFrom ? new Date(data.dateFrom) : new Date(),
    dateTo: data && data.dateTo ? new Date(data.dateTo) : new Date(),
    description: data.description ? data.description : '',
    inChargePersonName: data && data.inChargePersonName ? data.inChargePersonName : '',
    peopleToTrain: data && data.peopleToTrain ? data.peopleToTrain : [],
    comments: data && data.comments ? data.comments : '',
    dateFromHour: data && data.dateFrom ? new Date(data.dateFrom) : new Date(),
    dateToHour: data && data.dateTo ? new Date(data.dateTo) : new Date(),
    status: data ? data.status : trainingConstants.PLANNED,
    deleteTrainingDialog: false,
    effectivenessVerificationDate: data && data.effectivenessVerificationDate
      ? new Date(data.effectivenessVerificationDate)
      : null,
    effectivenessVerifiedDate: data && data.effectivenessVerifiedDate
      ? new Date(data.effectivenessVerifiedDate)
      : null,
    trainerType: data.trainerType,
  };

  const [values, setValues] = useState(initialFieldValues);
  const {
    title, totalTime, dateFrom, dateTo, description,
    inChargePersonName, comments, deleteTrainingDialog,
    dateFromHour, dateToHour, peopleToTrain, status, trainerType,
    effectivenessVerificationDate, effectivenessVerifiedDate,
  } = values;
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');

  const people = useSelector((state) => state.person.people);
  const creatingTraining = useSelector((state) => state.training.creating);
  const deletingTraining = useSelector((state) => state.training.deleting);
  const [checked, setChecked] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [personComment, setPersonComment] = useState('');
  const [inChargePersonId, setInChargePersonId] = useState(null);
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const [personIdToComment, setPersonIdToComment] = useState(null);
  const titleLabel = intl.formatMessage({ id: 'app.title', defaultMessage: 'Título' });
  const totalTimeLabel = intl.formatMessage({ id: 'training.totalTimeLabel', defaultMessage: 'Duración total en horas' });
  const inChargePersonLabel = intl.formatMessage({ id: 'training.inChargePerson', defaultMessage: 'Capacitador' });
  const peopleToTrainLabel = intl.formatMessage({ id: 'training.peopleToTrain', defaultMessage: 'Personas capacitadas' });
  const addPeopleToTrainLabel = intl.formatMessage({ id: 'training.addPeopleToTrain', defaultMessage: 'Agregar personas capacitadas' });
  const peopleListLabel = intl.formatMessage({ id: 'training.peopleToTrainList', defaultMessage: 'Listado de participantes' });
  const dateFromLabel = intl.formatMessage({ id: 'app.date_start', defaultMessage: 'Fecha de inicio' });
  const dateToLabel = intl.formatMessage({ id: 'app.date_end', defaultMessage: 'Fecha de fin' });
  const dateFromHourLabel = intl.formatMessage({ id: 'app.date_hour_start', defaultMessage: 'Hora de inicio' });
  const dateToHourLabel = intl.formatMessage({ id: 'app.date_hour_end', defaultMessage: 'Hora de fin' });
  const effectivenessVerificationDateLabel = intl.formatMessage({ id: 'training.effectivenessVerificationDate', defaultMessage: 'Fecha prevista de verificación de efectividad' });
  const effectivenessVerifiedDateLabel = intl.formatMessage({ id: 'training.effectivenessVerifiedDate', defaultMessage: 'Fecha efectiva de verificación de efectividad' });
  const invalidDateLabel = intl.formatMessage({ id: 'app.invalid_date', defaultMessage: 'Fecha incorrecta' });
  const deleteTrainingTitle = intl.formatMessage({ id: 'training.delete.title', defaultMessage: 'Eliminar capacitación' });
  const deleteTrainingButtonText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'Eliminar' });
  const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const deleteTrainingText = intl.formatMessage({ id: 'training.deleteTrainingText', defaultMessage: '¿Está seguro que quiere eliminar la capacitación?' });
  const deleteText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'eliminar' });
  const backText = intl.formatMessage({ id: 'app.back', defaultMessage: 'volver' });
  const saveText = intl.formatMessage({ id: 'app.save', defaultMessage: 'guardar' });
  const createText = intl.formatMessage({ id: 'app.create', defaultMessage: 'crear' });
  const nameAndSurnameText = intl.formatMessage({ id: 'app.nameAndSurname', defaultMessage: 'Nombre y apellido' });
  const assistanceText = intl.formatMessage({ id: 'app.assistance', defaultMessage: 'Asistencia' });
  const evaluationText = intl.formatMessage({ id: 'app.evaluation', defaultMessage: 'Evaluación' });
  const evaluationCommentText = intl.formatMessage({ id: 'app.evaluationComment', defaultMessage: 'Comentario de la evaluación' });
  const noPeopleToTrainText = intl.formatMessage({ id: 'training.noPeopleToTrain', defaultMessage: 'No hay personas vinculados a la capacitación' });
  const presentText = intl.formatMessage({ id: 'app.present', defaultMessage: 'Presente' });
  const absentText = intl.formatMessage({ id: 'app.absent', defaultMessage: 'Ausente' });
  const satisfactoryText = intl.formatMessage({ id: 'app.satisfactory', defaultMessage: 'Satisfactoria' });
  const unsatisfactoryText = intl.formatMessage({ id: 'app.unsatisfactory', defaultMessage: 'No satisfactoria' });
  const addCommentText = intl.formatMessage({ id: 'app.addComment', defaultMessage: 'Agregar comentario' });
  const commentLabel = intl.formatMessage({ id: 'app.comment', defaultMessage: 'Comentario' });
  const addText = intl.formatMessage({ id: 'app.add', defaultMessage: 'Agregar' });
  const trainerLabel = intl.formatMessage({ id: 'training.trainerType', defaultMessage: 'Tipo de capacitador' });

  useEffect(() => {
    const today = new Date();
    const isTrainingAtFutureDate = today.getTime() < dateFrom.getTime();
    if (isTrainingAtFutureDate) setIsDisabled(true);
    else setIsDisabled(false);
  }, [dateFrom]);

  useEffect(() => {
    if (!people) dispatch(personActions.getAll());
    if (edit && people) {
      const previousValues = [];
      people.forEach((value) => {
        const isPreviousValue = peopleToTrain.map((obj) => obj.id).indexOf(value.id) > -1;
        if (isPreviousValue) previousValues.push(value);
      });
      setChecked(previousValues);
    }
  }, [dispatch, people, edit, peopleToTrain]);

  const renderChip = (selected) => selected.map((value) => (
    <Chip
      key={`${value.names} ${value.surnames}`}
      label={`${value.names} ${value.surnames}`}
      className={classes.chip}
    />
  ));
  function updateValuesForPeopleToTrain(peopleToTrainEdited) {
    peopleToTrainEdited.forEach((element) => {
      const personAlreadyInArray = peopleToTrain.filter((x) => x.id === element.id).map((y) => y);
      if (personAlreadyInArray.length > 0) {
        // eslint-disable-next-line no-param-reassign
        element.wasPresent = personAlreadyInArray[0].wasPresent;
        // eslint-disable-next-line no-param-reassign
        element.evaluationResult = personAlreadyInArray[0].evaluationResult;
        // eslint-disable-next-line no-param-reassign
        element.comment = personAlreadyInArray[0].comment;
      }
    });
    return peopleToTrainEdited;
  }
  const handleChangePeopleToTrain = (event) => {
    const peopleToTrainEdited = updateValuesForPeopleToTrain(event.target.value);
    setChecked(peopleToTrainEdited);
    setValues({ ...values, peopleToTrain: peopleToTrainEdited });
  };

  function getDateFormatWithHour(isDateFrom = false) {
    let dateFormat;
    let hoursAndMinutes;
    if (isDateFrom) {
      dateFormat = new Date(dateFrom);
      hoursAndMinutes = new Date(dateFromHour);
    } else {
      dateFormat = new Date(dateTo);
      hoursAndMinutes = new Date(dateToHour);
    }
    dateFormat.setHours(hoursAndMinutes.getHours());
    dateFormat.setMinutes(hoursAndMinutes.getMinutes());
    return dateFormat.toISOString();
  }

  function handleSubmit(e) {
    e.preventDefault();
    const training = {
      title,
      peopleToTrain,
      inChargePersonName,
      inChargePersonId,
      description,
      comments,
      status,
      effectivenessVerificationDate,
      effectivenessVerifiedDate,
      trainerType,
    };
    training.totalTime = parseInt(totalTime, 10);
    training.dateFrom = getDateFormatWithHour(true);
    training.dateTo = getDateFormatWithHour();
    if (!edit) {
      dispatch(trainingActions.create(training, '/trainings/'));
    } else {
      training.id = data.id;
      dispatch(trainingActions.update(training, data.id, '/trainings/'));
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  }

  function handleTotalTime(e) {
    const { value } = e.target;
    setValues({ ...values, totalTime: value >= 0 ? value : 0 });
  }

  function handleClearDate(e, nameDate) {
    e.stopPropagation();
    setValues({ ...values, [nameDate]: null });
  }

  function handleChangeInputChargePerson(e, value = null) {
    if (value) {
      if (value.id) {
        setInChargePersonId(value.id);
        setValues({ ...values, inChargePersonName: `${value.names} ${value.surnames}` });
      }
    } else {
      setInChargePersonId(null);
      setValues({ ...values, inChargePersonName: e.target.value });
    }
  }

  function handleDialogOpen(dialogName) {
    setValues({
      ...values,
      [dialogName]: true,
    });
  }

  function handleDialogClose(dialogName) {
    setValues({
      ...values,
      [dialogName]: false,
    });
  }

  function deleteTraining() {
    dispatch(trainingActions.deleteTraining(data.id));
  }

  function handleRemovePersonToTrain(id) {
    const peopleDeleted = peopleToTrain.filter((person) => person.id !== id);
    setChecked(peopleDeleted);
    setValues({ ...values, peopleToTrain: peopleDeleted });
  }

  function handleCommentDialogOpen(id, comment = null) {
    if (comment) setPersonComment(comment);
    setPersonIdToComment(id);
    setOpenCommentDialog(true);
  }

  function handleCommentDialogClose() {
    setOpenCommentDialog(false);
  }

  function setCommentToPerson() {
    const peopleEdited = peopleToTrain.map((item) => {
      // eslint-disable-next-line no-param-reassign
      if (item.id === personIdToComment) item.comment = personComment;
      return item;
    });
    setValues({ ...values, peopleToTrain: peopleEdited });
    setOpenCommentDialog(false);
    setPersonComment(null);
  }

  const setPeopleToTrainValues = (peopleEdited) => {
    setValues({ ...values, peopleToTrain: peopleEdited });
    return peopleEdited;
  };

  function setWasPresent(id, value) {
    const peopleEdited = peopleToTrain.map((item) => {
      // eslint-disable-next-line no-param-reassign
      if (item.id === id) item.wasPresent = value;
      return item;
    });
    return setPeopleToTrainValues(peopleEdited);
  }

  function setEvaluationResult(id, value) {
    const peopleEdited = peopleToTrain.map((item) => {
      // eslint-disable-next-line no-param-reassign
      if (item.id === id) item.evaluationResult = value;
      return item;
    });
    return setPeopleToTrainValues(peopleEdited);
  }

  const handleChangeStatus = (event) => {
    setValues({ ...values, status: event.target.value });
  };

  return (
    <div className={classes.main}>
      <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Container>
              <TextValidator
                value={title}
                onChange={handleChange}
                fullWidth
                margin="normal"
                id="title"
                label={titleLabel}
                name="title"
                size="small"
                validators={['required']}
                errorMessages={[validationConstants.PERSON_TITLE_REQUIRED]}
              />
            </Container>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Container>
              <TextValidator
                value={totalTime}
                onChange={handleTotalTime}
                fullWidth
                margin="normal"
                id="totalTime"
                label={totalTimeLabel}
                name="totalTime"
                size="small"
                type="number"
                validators={['required']}
                errorMessages={[validationConstants.PERSON_TOTAL_TIME_REQUIRED]}
              />
            </Container>
          </Grid>
          <Grid item xs={12} sm={6}>
            {people && (
              <Container>
                <Autocomplete
                  id="inChargePersonName"
                  name="inChargePersonName"
                  freeSolo
                  label={inChargePersonLabel}
                  options={people}
                  inputValue={inChargePersonName}
                  getOptionLabel={(option) => (`${option.names} ${option.surnames}`)}
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...params}
                      label={inChargePersonLabel}
                      value={inChargePersonName}
                      onChange={(event) => { handleChangeInputChargePerson(event); }}
                      margin="normal"
                      validators={['required']}
                      errorMessages={[validationConstants.TRAINING_IN_CHARGE_PERSON_NAME_REQUIRED]}
                    />
                  )}
                  onChange={(event, value) => {
                    handleChangeInputChargePerson(event, value);
                  }}
                />
              </Container>
            )}
          </Grid>
          <Grid item xs={12} sm={3} style={{ marginTop: 5 }}>
            <Container>
              <DatePicker
                value={dateFrom}
                onChange={(value) => { setValues({ ...values, dateFrom: value }); }}
                fullWidth
                margin="normal"
                id="dateFrom"
                label={dateFromLabel}
                name="dateFrom"
                size="small"
                format="dd/MM/yyyy"
                minDateMessage={invalidDateLabel}
              />
            </Container>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Container>
              <TimePicker
                margin="normal"
                fullWidth
                id="dateFromHour"
                name="dateFromHour"
                label={dateFromHourLabel}
                value={dateFromHour}
                onChange={(value) => { setValues({ ...values, dateFromHour: value }); }}
              />
            </Container>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Container>
              <FormControl className={classes.formControl}>
                <SelectValidator
                  id="trainerType"
                  name="trainerType"
                  value={trainerType}
                  label={trainerLabel}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                  style={{ width: '100%', height: 45, marginTop: '16px' }}
                  validators={['required']}
                  errorMessages={[validationConstants.TRAINING_TRAINER_TYPE_REQUIRED]}
                  fullWidth
                >
                  <MenuItem key={0} value={0}>
                    <FormattedMessage id="training.trainerType.internal" defaultMessage="Interno" />
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    <FormattedMessage id="training.trainerType.external" defaultMessage="Externo" />
                  </MenuItem>
                </SelectValidator>
              </FormControl>
            </Container>
          </Grid>
          <Grid item xs={12} sm={3} style={{ marginTop: 8 }}>
            <Container>
              <DatePicker
                value={dateTo}
                onChange={(value) => { setValues({ ...values, dateTo: value }); }}
                fullWidth
                margin="normal"
                id="dateTo"
                label={dateToLabel}
                name="dateTo"
                size="small"
                format="dd/MM/yyyy"
                minDateMessage={invalidDateLabel}
                minDate={new Date(dateFrom)}
              />
            </Container>
          </Grid>
          <Grid item xs={12} sm={3} style={{ marginTop: 5 }}>
            <Container>
              <TimePicker
                margin="normal"
                fullWidth
                id="dateToHour"
                name="dateToHour"
                label={dateToHourLabel}
                value={dateToHour}
                onChange={(value) => { setValues({ ...values, dateToHour: value }); }}
              />
            </Container>
          </Grid>
          <Grid item xs={12} sm={6}>
            {people && (
              <Container>
                <FormControl className={classes.formControl}>
                  <InputLabel id="peopleToTrain">{peopleToTrainLabel}</InputLabel>
                  <Select
                    id="peopleToTrain"
                    name="peopleToTrain"
                    value={checked}
                    onChange={handleChangePeopleToTrain}
                    MenuProps={MenuProps}
                    renderValue={(selected) => renderChip(selected)}
                    style={{ width: '100%', height: 45 }}
                    fullWidth
                    multiple
                  >
                    <MenuItem disabled value="">
                      <em>{addPeopleToTrainLabel}</em>
                    </MenuItem>
                    {people.map((person) => (
                      <MenuItem
                        key={person.id}
                        value={person}
                      >
                        <Checkbox
                          color="primary"
                          checked={checked.indexOf(person) > -1}
                        />
                        {`${person.names} ${person.surnames}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Container>
            )}
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginTop: 16 }}>
            <Container>
              <FormControl className={classes.formControl}>
                <InputLabel id="status"><FormattedMessage id="app.status" defaultMessage="Estado" /></InputLabel>
                <Select
                  id="status"
                  name="status"
                  value={status}
                  onChange={handleChangeStatus}
                  MenuProps={MenuProps}
                  style={{ width: '100%', height: 45 }}
                  fullWidth
                >
                  <MenuItem key={0} value={0}>
                    <FormattedMessage id="training.planned" defaultMessage="Planificada" />
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    <FormattedMessage id="training.cancelled" defaultMessage="Cancelada" />
                  </MenuItem>
                  <MenuItem disabled={isDisabled} key={2} value={2}>
                    <FormattedMessage id="training.made" defaultMessage="Realizada" />
                  </MenuItem>
                  <MenuItem disabled={isDisabled} key={3} value={3}>
                    <FormattedMessage id="training.finished" defaultMessage="Finalizada" />
                  </MenuItem>
                </Select>
              </FormControl>
            </Container>
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginTop: 16 }}>
            <Container>
              <Typography variant="caption" color="textPrimary" style={{ weight: 600, fontSize: 12 }}>
                <strong><FormattedMessage id="app.generalComments" defaultMessage="Comentarios generales" /></strong>
              </Typography>
              <TextField
                value={comments}
                name="comments"
                id="comments"
                multiline
                rows={5}
                onChange={handleChange}
                fullWidth
                style={{ background: '#FFFFFF', margin: 2 }}
              />
            </Container>
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginTop: 16 }}>
            <Container>
              <Typography variant="caption" color="textPrimary" style={{ weight: 600, fontSize: 12 }}>
                <strong><FormattedMessage id="training.descriptionLabel" defaultMessage="Descripción de la capacitación | Vínculo" /></strong>
              </Typography>
              <TextField
                value={description}
                name="description"
                id="description"
                multiline
                rows={5}
                onChange={handleChange}
                fullWidth
                style={{ background: '#FFFFFF', margin: 2 }}
              />
            </Container>
          </Grid>
          {
            edit
            && (
            <Grid item xs={12} sm={6}>
              <Box pl={3}>
                <TrainingDocuments
                  documents={data.documents}
                  trainingId={data.id}
                />
              </Box>
            </Grid>
            )
          }
          <Grid item xs={12} sm={3} style={{ marginTop: 16 }}>
            <Container className={classes.formControlInputLabel}>
              <DatePicker
                fullWidth
                margin="normal"
                size="small"
                format="dd/MM/yyyy"
                InputProps={{
                  className: classes.customInputLabel,
                  endAdornment: (
                    <IconButton style={{ padding: 5 }} onClick={(e) => handleClearDate(e, 'effectivenessVerificationDate')}>
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                minDateMessage={invalidDateLabel}
                id="effectivenessVerificationDate"
                name="effectivenessVerificationDate"
                label={effectivenessVerificationDateLabel}
                value={effectivenessVerificationDate}
                onChange={(value) => {
                  setValues({ ...values, effectivenessVerificationDate: value });
                }}
              />
            </Container>
          </Grid>
          <Grid item xs={12} sm={3} style={{ marginTop: 16 }}>
            <Container className={classes.formControlInputLabel}>
              <DatePicker
                fullWidth
                margin="normal"
                size="small"
                format="dd/MM/yyyy"
                InputProps={{
                  className: classes.customInputLabel,
                  endAdornment: (
                    <IconButton style={{ padding: 5 }} onClick={(e) => handleClearDate(e, 'effectivenessVerifiedDate')}>
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                minDateMessage={invalidDateLabel}
                id="effectivenessVerifiedDate"
                name="effectivenessVerifiedDate"
                label={effectivenessVerifiedDateLabel}
                value={effectivenessVerifiedDate}
                onChange={(value) => {
                  setValues({ ...values, effectivenessVerifiedDate: value });
                }}
              />
            </Container>
          </Grid>
          { (edit
            || (!edit && status === trainingConstants.FINISHED && Boolean(peopleToTrain.length)))
            && (
            <Grid item xs={12} sm={12}>
              <Container maxWidth={false}>
                <Typography variant="caption" gutterBottom>
                  <strong style={{ fontSize: 12 }}>{peopleListLabel}</strong>
                </Typography>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell size="small" style={{ fontSize: 12 }}><strong>{nameAndSurnameText}</strong></TableCell>
                      <TableCell size="small" style={{ fontSize: 12 }}><strong>{assistanceText}</strong></TableCell>
                      <TableCell size="small" style={{ fontSize: 12 }}><strong>{evaluationText}</strong></TableCell>
                      <TableCell size="small" style={{ fontSize: 12 }}><strong>{evaluationCommentText}</strong></TableCell>
                      <TableCell size="small" />
                    </TableRow>
                    {peopleToTrain ? peopleToTrain.map((person) => (
                      <TableRow key={person.id}>
                        <TableCell size="small" style={{ fontSize: 12 }}>
                          {person.names}
                          {' '}
                          {person.surnames}
                        </TableCell>
                        <TableCell size="small" style={{ fontSize: 12 }}>
                          <Select
                            id="wasPresent"
                            name="wasPresent"
                            value={person.wasPresent}
                            onChange={(e) => { setWasPresent(person.id, e.target.value); }}
                            fullWidth
                            disabled={status < trainingConstants.DONE}
                          >
                            <MenuItem
                              key={0}
                              value={false}
                              selected={person.wasPresent === false}
                            >
                              {absentText}
                            </MenuItem>
                            <MenuItem key={1} value selected={person.wasPresent === true}>
                              {presentText}
                            </MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell size="small" style={{ fontSize: 12 }}>
                          <Select
                            id="wasPresent"
                            name="wasPresent"
                            value={person.evaluationResult}
                            onChange={(e) => { setEvaluationResult(person.id, e.target.value); }}
                            fullWidth
                            disabled={status < trainingConstants.FINISHED}
                          >
                            <MenuItem
                              key={0}
                              value={false}
                              selected={person.evaluationResult === false}
                            >
                              {unsatisfactoryText}
                            </MenuItem>
                            <MenuItem
                              key={1}
                              value
                              selected={person.evaluationResult === true}
                            >
                              {satisfactoryText}
                            </MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell size="small" style={{ fontSize: 12 }}>
                          <Box style={{
                            width: 200, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                          }}
                          >
                            { !person.comment && (
                              <Box
                                display="flex"
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                onClick={() => (status < trainingConstants.FINISHED ? '' : handleCommentDialogOpen(person.id))}
                                style={{ color: status < trainingConstants.FINISHED ? '#C4C4C4' : '#F16C24' }}
                              >
                                <ChatIcon style={{ width: 16, height: 16 }} />
                                <Typography
                                  variant="caption"
                                  style={{
                                    fontWeight: 400,
                                    fontSize: 11,
                                    textTransform: 'uppercase',
                                    paddingLeft: 5,
                                  }}
                                >
                                  {addCommentText}
                                </Typography>
                              </Box>
                            )}
                            { person.comment && (
                              <>
                                <Tooltip title={person.comment} placement="top">
                                  <Typography
                                    onClick={() => {
                                      handleCommentDialogOpen(person.id, person.comment);
                                    }}
                                    variant="caption"
                                    style={{
                                      fontWeight: 400,
                                      fontSize: 11,
                                      color: status > trainingConstants.DONE ? '#000000' : '#C4C4C4',
                                      textDecoration: 'underline',
                                    }}
                                  >
                                    {person.comment}
                                  </Typography>
                                </Tooltip>
                              </>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell size="small">
                          <IconButton onClick={() => { handleRemovePersonToTrain(person.id); }} style={{ textDecoration: 'none' }}>
                            <DeleteRoundedIcon style={{ width: 16, height: 16, color: '#727272' }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                      : (
                        <TableRow>
                          <TableCell size="small">
                            <Typography variant="subtitle1" gutterBottom style={{ fontSize: 12 }}>
                              {noPeopleToTrainText}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </Container>
            </Grid>
            )}
        </Grid>

        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
          <Button
            component={Link}
            to="/trainings"
            variant="outlined"
            text={backText}
            style={{ fontSize: '10px', fontWeight: 600 }}
          />
          {edit
            ? (
              <>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => { handleDialogOpen('deleteTrainingDialog'); }}
                  text={deleteText}
                  style={{ fontSize: '10px', fontWeight: 600 }}
                  disabled={!isAdmin}
                />
                <Button
                  type="submit"
                  text={saveText}
                  style={{ fontSize: '10px', fontWeight: 600 }}
                  disabled={!isAdmin}
                />
              </>
            )
            : (
              <Button
                type="submit"
                loading={creatingTraining}
                text={createText}
                disabled={!isAdmin}
              />
            )}

        </Box>
      </ValidatorForm>
      <Dialog open={deleteTrainingDialog} onClose={() => { handleDialogClose('deleteTrainingDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{deleteTrainingTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {deleteTrainingText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button text={cancelButtonText} onClick={() => { handleDialogClose('deleteTrainingDialog'); }} />
          <Button
            className={classes.menuButton}
            text={deleteTrainingButtonText}
            color="secondary"
            loading={deletingTraining}
            onClick={deleteTraining}
          />
        </DialogActions>
      </Dialog>
      <Dialog open={openCommentDialog} onClose={handleCommentDialogClose} fullWidth max-width="xs" aria-labelledby="form-dialog-title-comment">
        <DialogTitle id="form-dialog-title-comment" style={{ textTransform: 'uppercase' }}>{addCommentText}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              id="personComment"
              name="personComment"
              label={commentLabel}
              multiline
              rows={4}
              fullWidth
              style={{ paddingTop: 10 }}
              value={personComment}
              onChange={(e) => setPersonComment(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" text={cancelButtonText} onClick={handleCommentDialogClose} />
          <Button
            className={classes.menuButton}
            text={addText}
            onClick={setCommentToPerson}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

TrainingForm.propTypes = {
  data: PropTypes.shape(),
  edit: PropTypes.bool,
};

TrainingForm.defaultProps = {
  data: {},
  edit: false,
};

export default TrainingForm;
