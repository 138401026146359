import React from 'react';
import {
  Box, DialogContent, Grid,
  makeStyles, Typography,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import CalendarEventDetailPersonCard from '../CalendarEventDetailPersonCard';

const useStyles = makeStyles(() => ({
  flexCenterBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  dialogContent: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    margin: '20px 0px 10px',
    boxSizing: 'border-box',
  },
  infoTitle: { fontSize: '12px', fontWeight: 600 },
  statusContainer: {
    height: '84.5px',
    borderLeft: '1px solid #C4C4C4',
    paddingLeft: '27px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  participantsListTitle: { padding: '6px', borderBottom: '1px solid #EDEDED', width: '100%' },
  participantsContainer: {
    width: '272px',
    background: '#F8F8F8',
    borderRadius: '8px 8px 0px 0px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
    marginBottom: '24px',
  },
  objectivesParticipantsList: {
    height: '225px',
    overflowY: 'auto',
    overFlowX: 'hidden',
    scrollbarColor: '#EDEDED',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '0.3em',
      paddingLeft: '10px',
      height: '81px',
      borderRadius: '35px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(0,0,0,.4)',
    },
  },
}));

const STATUSES = [
  <FormattedMessage id="training.planned" defaultMessage="Planificada" />,
  <FormattedMessage id="training.cancelled" defaultMessage="Cancelada" />,
  <FormattedMessage id="training.made" defaultMessage="Realizada" />,
  <FormattedMessage id="training.finished" defaultMessage="Finalizada" />,
];

const CalendarEventDetailTrainingContent = ({ event }) => {
  const classes = useStyles();
  const intl = useIntl();
  const responsibleText = intl.formatMessage({ id: 'people.responsible', defaultMessage: 'Responsable' });
  const trainingStatusText = intl.formatMessage({ id: 'calendar.trainingStatusText', defaultMessage: 'Estado de capacitación' });
  const participantListText = intl.formatMessage({ id: 'training.peopleToTrainList', defaultMessage: 'Listado de participantes' });
  const { peopleToTrain, inChargePersonName, status } = event.eventInfo;
  const responsible = { fullName: inChargePersonName };

  return (
    <DialogContent className={classes.dialogContent}>
      <Box display="flex" alignItems="center">
        <Box className={classes.flexCenterBox}>
          <Typography variant="h5" className={classes.infoTitle}>
            {responsibleText}
          </Typography>
          <CalendarEventDetailPersonCard person={event.eventResponsible || responsible} />
        </Box>
        <Box className={classes.statusContainer}>
          <Typography variant="h5" className={classes.infoTitle} style={{ lineHeight: '12px', marginBottom: '6px' }}>
            {trainingStatusText}
          </Typography>
          <Typography variant="h6" style={{ fontSize: '12px', fontWeight: 400, lineHeight: '12px' }}>
            {STATUSES[status]}
          </Typography>
        </Box>
      </Box>

      <Box className={classes.participantsContainer}>
        <Box className={classes.participantsListTitle}>
          <Typography variant="h5" className={classes.infoTitle} style={{ lineHeight: '12px' }} align="center">
            {participantListText}
          </Typography>
        </Box>
        <Grid
          container
          className={classes.objectivesParticipantsList}
        >
          {peopleToTrain.map((person, index) => {
            const keyId = `keyId-${index}`;
            return (
              <Grid key={keyId} item xs={6}>
                <CalendarEventDetailPersonCard person={person} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </DialogContent>
  );
};

CalendarEventDetailTrainingContent.propTypes = {
  event: PropTypes.shape({
    eventInfo: PropTypes.shape({
      peopleToTrain: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        surnames: PropTypes.string,
        role: PropTypes.string,
      })),
      inChargePersonName: PropTypes.string,
      status: PropTypes.number,
    }),
    eventResponsible: PropTypes.shape({}),
  }).isRequired,
};

export default CalendarEventDetailTrainingContent;
