import axios from '../axios/axios';
import { authHeader, userCompanySelectorHelper } from '../helpers';
import handler from './handler';

function get(id) {
  const headers = authHeader();
  const getUrl = `companies/${id}/?companyId=${id}`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function update(company) {
  const headers = authHeader();
  const updateUrl = `companies/${company.id}/?companyId=${company.id}`;
  return axios.put(updateUrl, company, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function updateProcessMap(company) {
  const headers = authHeader();
  const updateUrl = `companies/${company.id}/processMap`;
  return axios.put(updateUrl, company, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function updateOrganizationChart(company) {
  const headers = authHeader();
  const updateUrl = `companies/${company.id}/organizationChart`;
  return axios.put(updateUrl, company, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getCards() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `companies/${company.id}/cards`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function setFirstLogin() {
  const headers = authHeader();
  const company = userCompanySelectorHelper.getCurrentCompany();
  const postUrl = `companies/${company.id}/first-login`;
  return axios.post(postUrl, {}, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const companyService = {
  get,
  update,
  getCards,
  setFirstLogin,
  updateProcessMap,
  updateOrganizationChart,
};

export default companyService;
