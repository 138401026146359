import colors from '../assets/colors';
import { workFieldEvaluationTexts } from '../constants';

const round2decimals = (v) => (v ? Math.round((v + Number.EPSILON) * 100) / 100 : v);

const getPercentage = (value, total) => {
  if (value === 0 || total === 0) {
    return 0;
  }
  return Math.floor((value / total) * 100);
};

const isLastItem = (index, array) => index === array.length - 1;

const getTotalWeightingPoints = (array) => array.reduce((acc, i) => acc + i.weightingPoint, 0);

const calcWeightedValue = (weightPercentage, value) => {
  if (value === 0 || weightPercentage === 0) {
    return 0;
  }
  return round2decimals((weightPercentage / 100) * value);
};

const statusEnum = {
  APPROVED: 0,
  CONDITIONAL: 1,
  PENDING: 2,
  REJECTED: 3,
};

const getResultEvaluation = (value) => {
  switch (true) {
    case value && value < 2:
      return statusEnum.REJECTED;
    case value && value >= 2 && value < 3:
      return statusEnum.CONDITIONAL;
    case value >= 3:
      return statusEnum.APPROVED;
    default:
      return statusEnum.PENDING;
  }
};

const getSum = (array) => array.reduce((acc, i) => acc + i, 0);

const getWorkFieldStatus = (workFieldItems, checked) => workFieldItems.map((workFieldItem) => {
  const indexPerson = checked.findIndex((item) => item.personId === workFieldItem.personId);
  const resultAnswers = workFieldItem.answers.map((element) => {
    const item = checked[indexPerson].answers.find((i) => i.name === element.name);
    if (!item) return false;
    const weightedValue = calcWeightedValue(item.score, item.weightPercentage);
    return weightedValue;
  });
  const resultEvaluation = resultAnswers.every((v) => v)
      && workFieldItem.answers.length === checked[indexPerson].answers.length
    ? round2decimals(getSum(resultAnswers))
    : null;
  return { personId: workFieldItem.personId, result: resultEvaluation };
});

const getAverage = (array) => {
  if (array.length === 0) return 0;
  const sum = array.reduce((acc, i) => acc + i, 0);
  return sum / array.length;
};

const getEvaluationStatus = (workFieldStatus) => (
  workFieldStatus.every((item) => item.result)
    ? getResultEvaluation(getAverage(workFieldStatus.map((item) => item.result)))
    : statusEnum.PENDING);

const getNameForResponsibility = (person) => {
  if (person) {
    return `${person.names} ${person.surnames}`;
  }
  return '';
};

const getEvaluationStatusLabel = (evaluationStatus) => {
  switch (evaluationStatus) {
    case statusEnum.REJECTED:
      return workFieldEvaluationTexts.rejected;
    case statusEnum.CONDITIONAL:
      return workFieldEvaluationTexts.conditional;
    case statusEnum.APPROVED:
      return workFieldEvaluationTexts.approved;
    default:
      return workFieldEvaluationTexts.pendingEvaluation;
  }
};

const getEvaluationStatusColor = (evaluationStatus) => {
  switch (evaluationStatus) {
    case statusEnum.REJECTED:
      return colors.red;
    case statusEnum.CONDITIONAL:
      return colors.orangeLight;
    case statusEnum.APPROVED:
      return colors.green;
    default:
      return colors.yellow;
  }
};

const sortCompare = (order) => (obj1, obj2) => {
  const orderComparisonValue = order === 'asc' ? 1 : -1;

  if (!obj1 || !obj1.data){
    return orderComparisonValue;
  }
  if (!obj2 || !obj2.data){
    return orderComparisonValue * -1;
  }
  
  const obj1Name = obj1.data.name;
  const obj2Name = obj2.data.name;
  const arr = [obj1Name, obj2Name].sort();

  if (arr[0] === obj1Name) {
    return orderComparisonValue;
  }

  return orderComparisonValue * -1;
};

const workFieldHelper = {
  calcWeightedValue,
  getAverage,
  getEvaluationStatus,
  getEvaluationStatusColor,
  getEvaluationStatusLabel,
  getNameForResponsibility,
  getPercentage,
  getResultEvaluation,
  getTotalWeightingPoints,
  getWorkFieldStatus,
  isLastItem,
  round2decimals,
  statusEnum,
  sortCompare,
};
export default workFieldHelper;
