import { impersonateConstants } from '../../constants';

const impersonator = JSON.parse(localStorage.getItem('impersonator'));
const initialState = impersonator ? { data: impersonator } : {};

function impersonate(state = initialState, action) {
  switch (action.type) {
    case impersonateConstants.POST_REQUEST:
      return { requesting: true };
    case impersonateConstants.POST_SUCCESS:
      return { data: action.impersonator };
    case impersonateConstants.POST_FAILURE:
      return {};

    case impersonateConstants.STOP_IMPERSONATING_REQUEST:
      return { requesting: true };
    case impersonateConstants.STOP_IMPERSONATING_SUCCESS:
      return {};
    case impersonateConstants.STOP_IMPERSONATING_FAILURE:
      return {};

    default:
      return state;
  }
}

export default impersonate;
