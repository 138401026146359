import alertActions from './alert.actions';
import companyActions from './company.actions';
import userActions from './user.actions';
import { history } from '../../helpers';
import { impersonateService } from '../../services';
import { impersonateConstants } from '../../constants';

function impersonateUser(impersonatedUserId, companyId, impersonatorUser) {
  function request() { return { type: impersonateConstants.POST_REQUEST }; }
  function saveImpersonator(impersonator) {
    return { type: impersonateConstants.POST_SUCCESS, impersonator };
  }
  function failure(error) { return { type: impersonateConstants.POST_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());
    impersonateService.impersonate(impersonatedUserId)
      .then(
        (impersonatedUser) => {
          dispatch(saveImpersonator(impersonatorUser));
          dispatch(userActions.setCompanySelectedForImpersonation(impersonatedUser, companyId));
          dispatch(companyActions.get(companyId));
          history.push('/home');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function stopImpersonating(impersonator) {
  function success() { return { type: impersonateConstants.STOP_IMPERSONATING_SUCCESS }; }

  return (dispatch) => {
    dispatch(success());
    dispatch(userActions.getOutCompanySelectedForImpersonation(
      impersonator,
      impersonator.company.id,
    ));
    dispatch(companyActions.get(impersonator.company.id));
    history.push('/home');
  };
}

const impersonateActions = {
  impersonateUser,
  stopImpersonating,
};

export default impersonateActions;
