import axios from '../axios/axios';
import { authHeader } from '../helpers';
import handler from './handler';

function impersonate(impersonatedUserId) {
  const headers = authHeader();
  const postUrl = `auth/impersonate/${impersonatedUserId}`;
  return axios.post(postUrl, {}, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const impersonateService = {
  impersonate,
};

export default impersonateService;
