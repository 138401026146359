import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { deviationConstants } from '../../../../constants';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 10,
    paddingLeft: 14,
  },
  title: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    minHeight: '40px',
  },
}));

const CustomLaneHeader = ({ id, style, isWithStyle }) => {
  const classes = useStyles();

  return (
    <header className={classes.root} style={isWithStyle ? { ...style } : { }}>
      <Typography className={classes.title}>{deviationConstants.status[id].label}</Typography>
    </header>
  );
};

CustomLaneHeader.propTypes = {
  id: PropTypes.string.isRequired,
  style: PropTypes.shape(),
  isWithStyle: PropTypes.bool,
};
CustomLaneHeader.defaultProps = {
  style: {},
  isWithStyle: false,
};

export default CustomLaneHeader;
