import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TextValidator } from 'react-material-ui-form-validator';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { taxIDs } from '../../../common/taxIDs';

const findTaxId = (taxIDType) => taxIDs.find(({ code }) => code === taxIDType) || null;

const TaxIdSelector = ({
  disablePortal = true,
  handleChange,
  minHeight,
  shrink = false,
  values,
}) => {
  const taxIdFound = findTaxId(values.taxIDType);
  const [selectedTaxID, setSelectedTaxID] = useState(taxIdFound);

  useEffect(() => {
    const newTaxIdFound = findTaxId(values.taxIDType);

    setSelectedTaxID(newTaxIdFound);
  }, [values.taxIDType]);

  return (
    <>
      <Grid item xs={6} style={{ position: "relative" }}>
        <Autocomplete
          disablePortal={disablePortal}
          getOptionLabel={(option) => `${option.country} ${option.code?.split("_").join(" ").toUpperCase()}`}
          getOptionSelected={(option, value) => (option && value) ? option.id === value.id : false}
          id="taxIDType"
          onChange={(_, value) => handleChange({ target: { name: "taxIDType", value: value?.id } })}
          options={taxIDs}
          renderInput={(params) => <TextField {...params} label="RUT / ID Fiscal" InputLabelProps={{ shrink }} />}
          renderOption={(props) => (
            <div {...props} value={props.id}>
              {props?.flag}
              <span style={{ fontSize: "14px", marginLeft: "10px" }}>
                {props?.code?.split("_").join(" ").toUpperCase()}
              </span>
              <span style={{ fontSize: "12px", marginLeft: "10px" }}>{props?.country}</span>
            </div>
          )}
          style={{ minHeight }}
          type="taxIDType"
          value={selectedTaxID}
        />
      </Grid>
      <Grid item xs={6} style={{ paddingLeft: "16px" }}>
        <TextValidator
          disabled={!values.taxIDType}
          errorMessages={[
            selectedTaxID && <FormattedMessage id={selectedTaxID?.errorMsg} defaultMessage={selectedTaxID?.errorMsg} />,
          ]}
          fullWidth
          id="taxID"
          label={<>{selectedTaxID?.example}</>}
          name="taxID"
          onChange={handleChange}
          style={{ minHeight }}
          validators={[`matchRegexp:${selectedTaxID?.regex}`]}
          value={values.taxID}
        />
      </Grid>
    </>
  )
};

export default TaxIdSelector;
