import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Breadcrumbs, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DataTable from '../../DataTable/DataTable';
import PageLayout from '../../PageLayout';
import Button from '../../controls/Button';
import { outlineEvaluationActions } from '../../../redux/actions';
import { history } from '../../../helpers';
import { outlineEvaluationConstants, videoTutorialId } from '../../../constants';
import VideoTutorialModal from '../../controls/VideoTutorialModal';

function OutlineEvaluationPage() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'outlineEvaluation.title', defaultMessage: 'Evaluaciones de personal' });
  const user = useSelector((state) => state.authentication.user);
  const isAdmin = user.roles.includes('Admin');
  const initialFieldValues = { outlineEvaluationId: '', deleteOutlineEvaluationDialog: false };
  const [values, setValues] = useState(initialFieldValues);
  const { outlineEvaluationId, deleteOutlineEvaluationDialog } = values;
  const deleteOutlineEvaluationTitle = intl.formatMessage({ id: 'outlineEvaluation.delete.title', defaultMessage: 'Eliminar evaluación' });
  const editOutlineEvaluationTitle = intl.formatMessage({ id: 'outlineEvaluation.edit.title', defaultMessage: 'Modificar evaluación' });
  const deleteOutlineEvaluationText = intl.formatMessage({ id: 'outlineEvaluation.deleteOutlineEvaluationText', defaultMessage: '¿Está seguro que quiere eliminar la evaluación?' });
  const deletingOutlineEvaluation = useSelector((state) => state.outlineEvaluation.deleting);
  const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const deletePersonButtonText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'Eliminar' });
  const addButtonText = intl.formatMessage({ id: 'outlineEvaluation.createOutlineEvaluation', defaultMessage: 'Crear evaluación' });

  const getEvaluationStatus = (evaluation) => {
    if (evaluation === outlineEvaluationConstants.FOR_REVISION) {
      return intl.formatMessage({ id: 'outlineEvaluation.forRevision', defaultMessage: 'Para revisión' });
    }
    if (evaluation === outlineEvaluationConstants.APPROVED) {
      return intl.formatMessage({ id: 'outlineEvaluation.approved', defaultMessage: 'Aprobada' });
    }
    return intl.formatMessage({ id: 'outlineEvaluation.pendingEvaluation', defaultMessage: 'Evaluación pendiente' });
  };

  function handleDialogOpen(dialogName, id = null) {
    if (id) {
      setValues({
        outlineEvaluationId: id,
        [dialogName]: true,
      });
    } else {
      setValues({
        ...values,
        [dialogName]: true,
      });
    }
  }

  function actionsButtons(link, id) {
    if (!isAdmin) return null;
    return (
      <>
        <IconButton onClick={() => { history.push(link); }} style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}>
          <Tooltip title={editOutlineEvaluationTitle}>
            <CreateIcon style={{ width: 16, height: 16, color: '#F79A56' }} />
          </Tooltip>
        </IconButton>

        <IconButton onClick={() => { handleDialogOpen('deleteOutlineEvaluationDialog', id); }} style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}>
          <Tooltip title={deleteOutlineEvaluationTitle}>
            <DeleteRoundedIcon style={{ width: 16, height: 16, color: '#727272' }} />
          </Tooltip>
        </IconButton>
      </>
    );
  }

  function actionsButtonsForEvaluatedPerson(link, id) {
    return (
      <IconButton onClick={ () => { history.push(link); }} style={{ textDecoration: 'none', padding: '0px' }}>
        <Tooltip title={editOutlineEvaluationTitle}>
          <RemoveRedEyeIcon style={{ width: 16, height: 16, color: '#727272' }} />
        </Tooltip>
      </IconButton>
    )
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: intl.formatMessage({ id: 'outlineEvaluation.person', defaultMessage: 'Persona evaluada' }),
      options: {
        customBodyRender: (person) => `${person.names} ${person.surnames}`,
        customDownloadBodyRender: (person) => `${person.names} ${person.surnames}`,
      },
    },
    {
      name: 'outline',
      label: intl.formatMessage({ id: 'outlineEvaluation.outline', defaultMessage: 'Perfil evaluado' }),
      options: {
        customBodyRender: (outline) => `${outline.name}`,
        customDownloadBodyRender: (outline) => `${outline.name}`,
      },
    },
    {
      name: 'evaluationStatus',
      label: intl.formatMessage({ id: 'outlineEvaluation.result', defaultMessage: 'Resultado de evaluación' }),
      options: {
        customBodyRender: (evaluationStatus) => {
          if (evaluationStatus === '-') return '-';
          return getEvaluationStatus(evaluationStatus);
        },
      },
    },
    {
      name: 'date',
      label: intl.formatMessage({ id: 'outlineEvaluation.date', defaultMessage: 'Fecha de evaluación' }),
      options: {
        customBodyRender: (date) => moment(date).format('DD/MM/YYYY'),
        customDownloadBodyRender: (date) => moment(date).format('DD/MM/YYYY'),
      },
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, meta) => {
          const isThePersonEvaluated = meta.rowData[1].email === user.email;
          const id = meta.rowData[0];
          const link = `/evaluations/${id}/edit`;
            return isThePersonEvaluated ? actionsButtonsForEvaluatedPerson(link, id) : actionsButtons(link, id);
        },
      },
    },
  ];

  const keys = ['id', 'person', 'outline', 'evaluationStatus', 'date'];
  const fetchUrl = `companies/${user.company.id}/OutlineEvaluation/`;
  const itemUrl = 'evaluations/';

  function handleDialogClose(dialogName) {
    setValues({
      ...values,
      [dialogName]: false,
    });
  }

  function deleteOutlineEvaluation(id) {
    dispatch(outlineEvaluationActions.deleteOutlineEvaluation(id));
    handleDialogClose('deleteOutlineEvaluationDialog');
  }

  function OutlineEvaluationPageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/evaluations" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{title}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  function ButtonGroup() {
    if (user.company.roles.includes('Admin')) {
      return (
        <Button
          text={addButtonText}
          component={Link}
          to="evaluations/new"
          style={{ fontSize: '11px', fontWeight: 600 }}
        />
      );
    }
    return null;
  }

  return (
    <PageLayout
      title={title}
      breadCrumbs={<OutlineEvaluationPageBreadcrumbs />}
      buttonGroup={<ButtonGroup />}
      iconVideoTutorial={(
        <VideoTutorialModal
          title={<FormattedMessage id="videoTutorial.evaluationsTitle" defaultMessage="Evaluación de perfiles" />}
          videoId={videoTutorialId.EVALUATIONS}
        />
      )}
    >
      <DataTable
        title={title}
        fetchUrl={fetchUrl}
        itemUrl={itemUrl}
        keys={keys}
        columns={columns}
        addButtonText={addButtonText}
        addButtonTo="evaluations/new"
      />
      <Dialog open={deleteOutlineEvaluationDialog} onClose={() => { handleDialogClose('deleteOutlineEvaluationDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{deleteOutlineEvaluationTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {deleteOutlineEvaluationText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button text={cancelButtonText} onClick={() => { handleDialogClose('deleteOutlineEvaluationDialog'); }} />
          <Button
            text={deletePersonButtonText}
            color="secondary"
            loading={deletingOutlineEvaluation}
            onClick={() => { deleteOutlineEvaluation(outlineEvaluationId); }}
          />
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
}

export default OutlineEvaluationPage;
