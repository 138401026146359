import { fodaConstants } from '../../constants';
import { fodaService } from '../../services';
import { history } from '../../helpers';
import alertActions from './alert.actions';
import companyActions from './company.actions';

function update(foda, selectedCompanyId) {
  function request(fodaToUpdate) {
    return {
      type: fodaConstants.UPDATE_REQUEST, fodaToUpdate,
    };
  }
  function success(updatedFoda) {
    return {
      type: fodaConstants.UPDATE_SUCCESS, updatedFoda,
    };
  }
  function failure(error) { return { type: fodaConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(foda));

    fodaService.update(foda)
      .then(
        (updatedFoda) => {
          dispatch(success(updatedFoda));
          dispatch(companyActions.get(selectedCompanyId));
          history.push('/foda');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

const fodaActions = {
  update,
};

export default fodaActions;
