import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Button as MuiButton,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  InsertDriveFile as InsertDriveFileIcon,
  LinkOff as LinkOffIcon,
  VisibilityOutlined as VisibilityIcon,
  Search as SearchIcon,
} from '@material-ui/icons';

import { CloseIcon, DocumentIcon } from '../../../../assets/icons';
import { documentActions } from '../../../../redux/actions';
import { getActualVersion, handleClickOnDocument, initialDocumentState } from '../../../../common/constants';
import Button from '../../../controls/Button';
import DocumentPreviewModal from '../../../controls/Modals/DocumentPreview';
import SetNewDocumentModal from '../../../Documents/SetNewDocumentModal/SetNewDocumentModal';
import useStyles from './useStyles';

const ProcessDocumentDetail = ({ documents, processId }) => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state.authentication.user);

  const [documentState, setDocumentState] = useState(initialDocumentState);
  const [documentToUnlink, setDocumentToUnlink] = useState();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [openUnlinkDialog, setOpenUnlinkDialog] = useState(false);
  const [searchResult, setSearchResult] = useState(documents);
  const [searchText, setSearchText] = useState('');

  const isAdmin = roles.includes('Admin');

  const addDocumentText = intl.formatMessage({ id: 'document.addToProcess', defaultMessage: 'AGREGAR DOCUMENTO AL PROCESO' });
  const documentsLabel = intl.formatMessage({ id: 'document.linkedDocument', defaultMessage: '{count, plural, =0 {No hay documentos asociados} one {# Documento} other {# Documentos}}' }, { count: documents.length });
  const previewDocument = intl.formatMessage({ id: 'document.preview', defaultMessage: 'Vista previa' });

  const handleTextChange = ({ target: { value } }) => setSearchText(value);

  const toggleOpenDocumentModal = () => setOpenDocumentModal((prevValue) => !prevValue);

  const toggleOpenUnlinkDialog = (id) => {
    setOpenUnlinkDialog(!openUnlinkDialog);
    setDocumentToUnlink(id);
  }

  const unlinkDocument = (documentId) => {
    dispatch(documentActions.unlinkProcess(processId, documentId));
    setOpenUnlinkDialog(!openUnlinkDialog);
  }

  useEffect(() => {
    if (searchText) {
      const filtered = documents.filter(
        (doc) => doc.title.toLowerCase().startsWith(searchText.toLowerCase()),
      );
      return setSearchResult(filtered);
    }
    return setSearchResult(documents);
  }, [searchText, documents]);

  return (
    <Box width={291} bgcolor="#FCFCFC" m={1} ml={0}>
      <Box className={classes.centeredContainer} height={105}>
        <InsertDriveFileIcon className={classes.iconHeader} />
        <Typography display="block" className={classes.textHeader}>
          {documentsLabel}
        </Typography>
      </Box>
      <Divider />
      <Box minHeight={263}>
        <Box bgcolor="#FFFFFF" borderRadius={18} mx="auto" mt={2} mb={2.5} width={250}>
          <InputBase
            classes={{ adornedStart: classes.iconSearch }}
            placeholder="Buscar"
            value={searchText}
            onChange={handleTextChange}
            startAdornment={(
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )}
          />
        </Box>
        <Box className={classes.resultContainer}>
          <Grid container className={classes.resultGrid}>
            {searchResult.map((document) => {
              const actualVersion = getActualVersion(document);
              const { id: actualVersionId, versionName: actualVersionName } = actualVersion;

              return (
                <Grid item key={document.id}>
                  <Box className={classes.centeredContainer} py={0.5}>
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                      classes={{ badge: classes.iconUnlink }}
                      badgeContent={(
                        <Tooltip title={previewDocument}>
                          <IconButton
                            classes={{ root: classes.iconButtonUnlink }}
                            onClick={() => handleClickOnDocument({
                              document,
                              setDocumentState,
                              setIsPreviewModalOpen,
                              version: actualVersion
                            })}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    >
                      <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        classes={{ badge: classes.iconUnlink }}
                        badgeContent={(
                          <Tooltip title={<FormattedMessage id="app.unlink" defaultMessage="DESVINCULAR" />}>
                            <IconButton
                              classes={{ root: classes.iconButtonUnlink }}
                              onClick={() => toggleOpenUnlinkDialog(document.id)}
                            >
                              <LinkOffIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      >
                        <Link to={`/documents/${document.id}/${actualVersionId}`} style={{ textDecoration: 'none' }}>
                          <InsertDriveFileIcon className={classes.iconBody} />
                        </Link>
                      </Badge>
                    </Badge>
                    <Typography display="block" className={classes.itemTitle}>
                      {document.title}
                    </Typography>
                    <Typography display="block" className={classes.itemVersion}>
                      {actualVersionName}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      {isAdmin && (
        <Box className={classes.centeredContainerButton}>
          <DocumentIcon className={classes.documentIcon} display="inline" />
          <MuiButton
            color="primary"
            onClick={toggleOpenDocumentModal}
            display="inline"
            className={classes.linkDocuments}
          >
            {addDocumentText}
          </MuiButton>
        </Box>
      )}
      <Dialog
        open={openDocumentModal}
        onClose={toggleOpenDocumentModal}
        aria-labelledby="form-dialog-title"
      >
        <SetNewDocumentModal
          isOpen={openDocumentModal}
          setIsOpen={toggleOpenDocumentModal}
          processId={processId}
        />
      </Dialog>
      <Dialog
        open={openUnlinkDialog}
        onClose={toggleOpenUnlinkDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <Box display="flex" justifyContent="flex-end">
            <CloseIcon className={classes.closeIcon} onClick={toggleOpenUnlinkDialog} />
          </Box>
          <Typography variant="subtitle1" className={classes.unlinkDocumentTitle}>
            <FormattedMessage id="document.unlinkDocument" defaultMessage="DESVINCULAR DOCUMENTO" />
          </Typography>
          <Box display="flex" align="center" justifyContent="center">
            <Typography variant="subtitle1" className={classes.unlinkDocumentContent}>
              <FormattedMessage id="document.unlinkProcessText" defaultMessage="Se dejará de utilizar el documento en éste proceso, pero no se eliminará del sistema, ni de los otros procesos donde esté asociado." />
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <Button
              onClick={toggleOpenUnlinkDialog}
              variant="outlined"
              text={<FormattedMessage id="app.cancel" defaultMessage="CANCELAR" />}
            />
            <Button
              onClick={() => unlinkDocument(documentToUnlink)}
              text={<FormattedMessage id="app.unlink" defaultMessage="DESVINCULAR" />}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <DocumentPreviewModal
        documentState={documentState}
        handleClose={() => setIsPreviewModalOpen(false)}
        isOpen={isPreviewModalOpen}
      />
    </Box>
  );
};

ProcessDocumentDetail.propTypes = {
  documents: PropTypes.arrayOf((PropTypes.shape())),
  processId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ProcessDocumentDetail.defaultProps = {
  documents: [],
  processId: null,
};

export default ProcessDocumentDetail;
