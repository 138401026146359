import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import impersonateActions from '../../redux/actions/impersonate.actions';

const useStyles = makeStyles((theme) => ({
  textImpersonate: {
    color: 'white',
    fontSize: '12px',
  },
  impersonateBar: {
    backgroundColor: theme.palette.primary.main,
    padding: '2px 0px',
  },
  buttonText: {
    fontSize: '12px',
    color: 'white',
    textTransform: 'none',
    padding: '0px 12px',
  },
}));

const ImpersonatingHeader = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);
  const impersonator = useSelector((state) => state.impersonate.data);
  const [names, setNames] = useState('');
  const getOutImpersonate = intl.formatMessage({ id: 'app.getOutImpersonate', defaultMessage: 'Salir del modo impersonar' });
  const impersonateNamesAndRole = <FormattedMessage id="app.impersonateNamesAndRole" defaultMessage="Está visualizando el sistema de otro usuario" values={{ names, roles: String(user.roles) }} />;

  useEffect(() => {
    if (user.company.person) { setNames(`${user.company.person.names} ${user.company.person.surnames}`); } else { setNames(user.email); }
  }, [user]);

  return (
    <Grid container justifyContent="space-between" alignItems="center" className={classes.impersonateBar}>
      <Grid item xs={3}>
        <Button
          className={classes.buttonText}
          startIcon={<ArrowBackIcon />}
          onClick={() => dispatch(impersonateActions.stopImpersonating(impersonator))}
        >
          {getOutImpersonate}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Typography align="center" className={classes.textImpersonate}>
          {impersonateNamesAndRole}
        </Typography>
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
};

export default ImpersonatingHeader;
