import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
    Box,
    Dialog,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MuiButton from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {
    VisibilityOutlined as VisibilityIcon
} from '@material-ui/icons';

import { DocumentIcon } from '../../../assets/icons/index';
import { getActualVersion, handleClickOnDocument, initialDocumentState } from '../../../common/constants';
import DocumentPreviewModal from '../../controls/Modals/DocumentPreview';
import DownloadDocument from '../../controls/DownloadDocument';
import SetNewDocumentModal from '../../Documents/SetNewDocumentModal/SetNewDocumentModal';
import useStyles from './useStyles';

const TrainingDocuments = ({ documents, showDivider, trainingId }) => {
    const classes = useStyles();
    const intl = useIntl();
    const { roles } = useSelector((state) => state.authentication.user);

    const [documentState, setDocumentState] = useState(initialDocumentState);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [documentModal, setDocumentModal] = useState(false);

    const isAdmin = roles.includes('Admin');

    const addDocumentText = intl.formatMessage({ id: 'document.addToTraining', defaultMessage: 'AGREGAR DOCUMENTO A LA CAPACITACIÓN' });
    const documentNameText = intl.formatMessage({ id: 'document.name', defaultMessage: 'Nombre' });
    const documentsLabel = intl.formatMessage({ id: 'documents.title_pl', defaultMessage: 'Documentos' });
    const documentVersionText = intl.formatMessage({ id: 'document.version', defaultMessage: 'Versión' });
    const noDocumentsLinkedToTraining = intl.formatMessage({ id: 'document.noDocumentsLinkedToTraining', defaultMessage: 'No hay documentos vinculados a la capacitación' });
    const previewDocument = intl.formatMessage({ id: 'document.preview', defaultMessage: 'Vista previa' });
    const seeDocumentText = intl.formatMessage({ id: 'document.see', defaultMessage: 'Ver Documento' });

    const handleDocumentModal = () => setDocumentModal(!documentModal);

    return (
        <>
            <Typography className={classes.textSeparator}>
                {documentsLabel}
            </Typography>
            {showDivider ? <Divider className={classes.historyTableDivider} /> : <Box mb={1.5} />}
            <Box pr={3} pb={3}>
                <Table className={classes.table}>
                    <TableBody>
                        {documents?.length > 0 ? (
                            <TableRow>
                                <TableCell className={classes.tableHead} size="small" style={{ paddingLeft: '0px' }}>{documentNameText}</TableCell>
                                <TableCell className={classes.tableHead} size="small">{documentVersionText}</TableCell>
                                <TableCell className={classes.tableHead} size="small" />
                                <TableCell className={classes.tableHead} size="small" />
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell className={classes.tableHeadEmpty} style={{ paddingLeft: '0px', borderBottom: '0px' }} size="small">{noDocumentsLinkedToTraining}</TableCell>
                            </TableRow>
                        )}
                        {documents?.length > 0 && documents.map((document) => {
                            const actualVersion = getActualVersion(document);

                            return (
                                <TableRow key={document.id}>
                                    <TableCell size="small" className={classes.tableBody} style={{ paddingLeft: '0px' }}>{document.title}</TableCell>
                                    <TableCell size="small" className={classes.tableBody}>{actualVersion.versionName}</TableCell>
                                    <TableCell size="small" className={classes.tableVisibilityIcon}>
                                        <IconButton className={classes.iconButton} onClick={() => handleClickOnDocument({
                                            document,
                                            setDocumentState,
                                            setIsPreviewModalOpen,
                                            version: actualVersion
                                        })}>
                                            <Tooltip title={previewDocument}>
                                                <VisibilityIcon className={classes.actionIcon} />
                                            </Tooltip>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell size="small" className={`${classes.tableBody} ${classes.tableLink}`} component={Link} to={`/documents/${document.id}/${actualVersion.id}`}>
                                        {seeDocumentText}
                                    </TableCell>
                                    <TableCell size="small">
                                        <DownloadDocument document={document} actualVersion={actualVersion} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
            {isAdmin && (
                <Box pr={3}>
                    <DocumentIcon className={classes.documentIcon} display="inline" />
                    <MuiButton
                        color="primary"
                        onClick={handleDocumentModal}
                        display="inline"
                        className={classes.linkDocuments}
                        style={{ paddingLeft: '10px', textDecoration: 'none' }}
                    >
                        {addDocumentText}
                    </MuiButton>
                </Box>
            )}
            <Box>
                <Dialog
                    open={documentModal}
                    onClose={handleDocumentModal}
                    aria-labelledby="form-dialog-title"
                >
                    <SetNewDocumentModal
                        isOpen={documentModal}
                        setIsOpen={handleDocumentModal}
                        trainingId={trainingId}
                    />
                </Dialog>
            </Box>
            <DocumentPreviewModal
                documentState={documentState}
                handleClose={() => setIsPreviewModalOpen(false)}
                isOpen={isPreviewModalOpen}
            />
        </>
    );
};

TrainingDocuments.propTypes = {
    documents: PropTypes.arrayOf(PropTypes.object).isRequired,
    showDivider: PropTypes.bool,
    trainingId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

TrainingDocuments.defaultProps = {
    showDivider: false,
    trainingId: '',
};

export default TrainingDocuments;
