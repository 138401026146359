function capitalizeFirstLetter(text) {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return text;
}

const textHelpers = {
  capitalizeFirstLetter,
};

export default textHelpers;
