import { workFieldEvaluationConstants } from '../../constants';
import { workFieldEvaluationService } from '../../services';
import { history } from '../../helpers';
import alertActions from './alert.actions';

function create(workFieldEvaluation, message) {
  function request() { return { type: workFieldEvaluationConstants.CREATE_REQUEST }; }
  function success(entity) { return { type: workFieldEvaluationConstants.CREATE_SUCCESS, entity }; }
  function failure(error) { return { type: workFieldEvaluationConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(workFieldEvaluation));

    workFieldEvaluationService.create(workFieldEvaluation)
      .then(
        (created) => {
          dispatch(success(created));
          dispatch(alertActions.success(message));
          history.push(`/workFieldEvaluations/${created.id}`);
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function get(id) {
  function request() { return { type: workFieldEvaluationConstants.GET_REQUEST }; }
  function success(workFieldEvaluation) {
    return { type: workFieldEvaluationConstants.GET_SUCCESS, workFieldEvaluation };
  }
  function failure(error) { return { type: workFieldEvaluationConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    workFieldEvaluationService.get(id)
      .then(
        (workFieldEvaluation) => {
          dispatch(success(workFieldEvaluation));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
          history.push('/workFieldEvaluations');
        },
      );
  };
}

function getAll() {
  function request() { return { type: workFieldEvaluationConstants.GET_ALL_REQUEST }; }
  function success(workFieldEvaluations) {
    return { type: workFieldEvaluationConstants.GET_ALL_SUCCESS, workFieldEvaluations };
  }
  function failure(error) { return { type: workFieldEvaluationConstants.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    workFieldEvaluationService.getAll()
      .then(
        (workFieldEvaluations) => {
          dispatch(success(workFieldEvaluations));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getAllUnapproved() {
  function request() { return { type: workFieldEvaluationConstants.GET_ALL_UNAPPROVED_REQUEST }; }
  function success(workFieldEvaluations) {
    return { type: workFieldEvaluationConstants.GET_ALL_UNAPPROVED_SUCCESS, workFieldEvaluations };
  }
  function failure(error) {
    return { type: workFieldEvaluationConstants.GET_ALL_UNAPPROVED_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    workFieldEvaluationService.getAllUnapproved()
      .then(
        (workFieldEvaluations) => {
          dispatch(success(workFieldEvaluations));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function update(workFieldEvaluation, message) {
  function request() { return { type: workFieldEvaluationConstants.UPDATE_REQUEST }; }
  function success(entity) { return { type: workFieldEvaluationConstants.UPDATE_SUCCESS, entity }; }
  function failure(error) { return { type: workFieldEvaluationConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(workFieldEvaluation));

    workFieldEvaluationService.update(workFieldEvaluation)
      .then(
        (updatedWorkFieldEvaluation) => {
          dispatch(success(updatedWorkFieldEvaluation));
          dispatch(alertActions.success(message));
          history.push(`/workFieldEvaluations/${updatedWorkFieldEvaluation.id}`);
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function deleteWorkFieldEvaluation(workFieldEvaluationId) {
  function request() { return { type: workFieldEvaluationConstants.DELETE_REQUEST }; }
  function success() { return { type: workFieldEvaluationConstants.DELETE_SUCCESS }; }
  function failure() { return { type: workFieldEvaluationConstants.DELETE_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    workFieldEvaluationService.deleteWorkFieldEvaluation(workFieldEvaluationId)
      .then(
        () => {
          dispatch(success());
          history.push('/workFieldEvaluations');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

const workFieldEvaluationActions = {
  create,
  get,
  getAll,
  getAllUnapproved,
  update,
  deleteWorkFieldEvaluation,
};

export default workFieldEvaluationActions;
