import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import WarningIcon from '@material-ui/icons/Warning';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import colors from '../../assets/colors';

const useStyles = makeStyles(() => ({
  textPaymentBar: {
    color: 'white',
    fontSize: '14px',
  },
  retryPaymentBar: {
    backgroundColor: colors.rustyRed,
    minHeight: '32px',
  },
  buttonText: {
    fontSize: '14px',
    color: 'white',
    textTransform: 'none',
    padding: '0px 12px',
  },
  containerText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.white,
    padding: '8px 20px',
  },
}));

const PaymentErrorHeader = () => {
  const intl = useIntl();
  const classes = useStyles();
  const contactText = intl.formatMessage({ id: 'stripe.problemsProcessingPayment', defaultMessage: 'No pudimos procesar tu pago, te recomendamos que lo intentes de nuevo con otro método.' });
  const retryPaymentText = intl.formatMessage({ id: 'stripe.retryPayment', defaultMessage: 'Reintentar pago' });

  return (
    <Grid container justifyContent="space-between" alignItems="center" className={classes.retryPaymentBar}>
      <Grid item xs={2}>
        <Button
          className={classes.buttonText}
          startIcon={<ArrowBackIcon />}
          component={Link}
          to="/company/configuration"
        >
          {retryPaymentText}
        </Button>
      </Grid>
      <Grid item xs={8}>
        <Box className={classes.containerText}>
          <WarningIcon style={{ marginRight: '7px', fontSize: '14px' }} />
          <Typography align="center" className={classes.textPaymentBar}>
            {contactText}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
};

export default PaymentErrorHeader;
