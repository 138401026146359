import React from 'react';
import { FormattedMessage } from 'react-intl';
import { fodaConstants } from '../constants';

const fortressLabel = <FormattedMessage id="foda.fortress" defaultMessage="Fortalezas" />;
const opportunitiesLabel = <FormattedMessage id="foda.opportunities" defaultMessage="Oportunidades" />;
const weaknessesLabel = <FormattedMessage id="foda.weaknesses" defaultMessage="Debilidades" />;
const threatsLabel = <FormattedMessage id="foda.threats" defaultMessage="Amenazas" />;

function getNameListByType(type) {
  switch (type) {
    case fodaConstants.FORTRESS_TYPE: return 'fortressList';
    case fodaConstants.OPPORTUNITIES_TYPE: return 'opportunitiesList';
    case fodaConstants.WEAKNESSES_TYPE: return 'weaknessesList';
    case fodaConstants.THREATS_TYPE: return 'threatsList';
    default: return '';
  }
}
function getNameByType(type) {
  switch (type) {
    case fodaConstants.FORTRESS_TYPE: return fortressLabel;
    case fodaConstants.OPPORTUNITIES_TYPE: return opportunitiesLabel;
    case fodaConstants.WEAKNESSES_TYPE: return weaknessesLabel;
    case fodaConstants.THREATS_TYPE: return threatsLabel;
    default: return '';
  }
}

const sortableHelper = {
  getNameListByType, getNameByType,
};

export default sortableHelper;
