import React, { useState } from 'react';
import {
  Box, Container, Grid, Typography, makeStyles,
} from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '../controls/Button';
import { affiliateActions } from '../../redux/actions';
import { affiliateText } from '../../constants';
import CheckboxValidator from '../controls/CheckboxValidator';
import colors from '../../assets/colors';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  textLabel: {
    '& .MuiFormLabel-root': {
      fontSize: '12px',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px) scale(1)',
      textAlign: 'left',
    },
  },
  textSeparator: {
    marginLeft: '24px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  textSeparatorMeasure: {
    marginTop: '32px',
    marginLeft: '24px',
    marginBottom: '24px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  textSubtitle: {
    fontSize: '12px',
    fontWeight: 600,
    marginRight: '16px',
  },
  flexRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  discountsInput: {
    width: '50px',
    height: '31px',
    display: 'flex',
    padding: '0px 12px',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: 'none',
    background: '#EEE',
    borderRadius: '2px',
    marginRight: '3px',
    '& .MuiInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
  referalDiscountWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
  },
  referalDiscount: {
    display: 'flex',
    flexDirection: 'column',
  },
  freeInitialMonthLabel: {
    fontSize: '12px',
    cursor: 'pointer',
    color: colors.darkGray,
  },
}));

const initialFieldValues = {
  names: '',
  email: '',
  discountCoupon: null,
  commission: null,
  includesFreeInitialMonth: false,
};
const AffiliateForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.authentication.user);
  const creatingAffiliates = useSelector((state) => state.affiliate.creating);
  const [affiliateDetails, setAffiliateDetails] = useState(initialFieldValues);

  function handleCreateAffiliates() {
    dispatch(affiliateActions.create(affiliateDetails, affiliateText.successfulSave));
  }

  function handleChangeFormData(e) {
    const { name, value } = e.target;
    return setAffiliateDetails({ ...affiliateDetails, [name]: value });
  }

  function handleChangeFormDiscounts(e) {
    let { value } = e.target;
    const { name } = e.target;
    if (value > 100) value = 100;
    if (value <= 0) value = 0.01;
    return setAffiliateDetails({ ...affiliateDetails, [name]: value });
  }

  function handleChangeCheckbox (e) {
    const { name, checked } = e.target;
    setAffiliateDetails({ ...affiliateDetails, [name]: checked });
  };

  function handleSubmitForm(e) {
    e.preventDefault();
    return handleCreateAffiliates();
  }

  if (!userAuth.isSuperAdmin) {
    return <Redirect to={{ pathname: '/home' }} />;
  }

  return (
    <div className={classes.main}>
      <ValidatorForm onSubmit={(e) => { handleSubmitForm(e); }} autoComplete="off" className={classes.textLabel}>
        <Typography className={classes.textSeparator}>
          {affiliateText.generalInformation}
        </Typography>
        <Grid container spacing={3} align="center">
          <Grid item xs={12} md={6}>
            <Container>
              <TextValidator
                fullWidth
                id="names"
                label={affiliateText.nameLabel}
                margin="normal"
                name="names"
                disabled={creatingAffiliates}
                onChange={handleChangeFormData}
                size="small"
                value={affiliateDetails.names}
                validators={['required']}
                errorMessages={[affiliateText.isRequired]}
              />
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container align="flex-start">
              <TextValidator
                fullWidth
                id="email"
                label={affiliateText.emailLabel}
                margin="normal"
                name="email"
                disabled={creatingAffiliates}
                onChange={handleChangeFormData}
                size="small"
                value={affiliateDetails.email}
                validators={['required']}
                errorMessages={[affiliateText.isRequired]}
              />
            </Container>
          </Grid>
        </Grid>

        <Typography className={classes.textSeparatorMeasure}>
          {affiliateText.discountsTitle}
        </Typography>

        <Grid item xs={12} md={6}>
          <Box className={classes.referalDiscountWrapper} mt={4} mb={3} ml={3} mr={3}>
            <Box className={classes.referalDiscount}>
              <Box className={classes.flexRowContainer}>
                <Typography className={classes.textSubtitle}>
                  {affiliateText.discountCoupon}
                </Typography>
                <TextValidator
                  fullWidth
                  id="discountCoupon"
                  margin="normal"
                  name="discountCoupon"
                  type="number"
                  min={0.01}
                  disabled={creatingAffiliates}
                  onChange={handleChangeFormDiscounts}
                  size="small"
                  value={affiliateDetails.discountCoupon}
                  validators={['required']}
                  errorMessages={[affiliateText.isRequired]}
                  className={classes.discountsInput}
                  InputProps={{ disableUnderline: true }}
                />
                <Typography>
                  %
                </Typography>
              </Box>
              <Typography style={{ fontSize: '10px', fontWeight: 200 }}>
                  *{affiliateText.onlyFirst3Months}
              </Typography>
            </Box>

            <Box mr={7.5}>
              <CheckboxValidator
                name="includesFreeInitialMonth"
                checked={affiliateDetails.includesFreeInitialMonth}
                value={affiliateDetails.includesFreeInitialMonth}
                onChange={handleChangeCheckbox}
                labelControl={(
                  <Typography className={classes.freeInitialMonthLabel}>
                    {affiliateText.includesFreeInitialMonth}
                  </Typography>
                )}
              />
            </Box>
          </Box>
        </Grid>

        <Box className={classes.flexRowContainer} mt={4} mb={3} ml={3} style={{ width: '280px' }}>
          <Typography className={classes.textSubtitle}>
            {affiliateText.paymentCommission}
          </Typography>
          <Box display="flex">
            <TextValidator
              fullWidth
              id="commission"
              margin="none"
              name="commission"
              type="number"
              min={0.01}
              onChange={handleChangeFormDiscounts}
              size="small"
              disabled={creatingAffiliates}
              value={affiliateDetails.commission}
              validators={['required']}
              errorMessages={[affiliateText.isRequired]}
              className={classes.discountsInput}
              InputProps={{ disableUnderline: true }}
            />
            <Typography display="inline" ml={1}>
              %
            </Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
          <Button
            component={Link}
            to="/affiliates"
            variant="outlined"
            text={affiliateText.backText}
            style={{ fontSize: '10px', fontWeight: 600 }}
            disabled={creatingAffiliates}
          />
          <Button
            type="submit"
            loading={creatingAffiliates}
            text={affiliateText.createText}
          />
        </Box>
      </ValidatorForm>
    </div>
  );
};

export default AffiliateForm;
