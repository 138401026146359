import React from 'react';
import { FormattedMessage } from 'react-intl';

const providersTexts = {
  back: <FormattedMessage id="app.back" defaultMessage="Volver" />,
  save: <FormattedMessage id="app.save" defaultMessage="Guardar" />,
  cancel: <FormattedMessage id="app.cancel" defaultMessage="Cancelar" />,
  edit: <FormattedMessage id="app.edit" defaultMessage="Editar" />,
  create: <FormattedMessage id="app.create" defaultMessage="Crear" />,
  title: <FormattedMessage id="app.title" defaultMessage="Título" />,
  delete: <FormattedMessage id="app.delete" defaultMessage="Eliminar" />,
  new: <FormattedMessage id="app.new" defaultMessage="Nuevo" />,
  modify: <FormattedMessage id="app.modify" defaultMessage="Modificar" />,
  addButton: <FormattedMessage id="providers.addButton" defaultMessage="+ Añadir proveedor" />,

  providers: <FormattedMessage id="providers.providers" defaultMessage="Proveedores" />,
  name: <FormattedMessage id="providers.name" defaultMessage="Nombre del proveedor" />,
  workField: <FormattedMessage id="providers.workFields" defaultMessage="Rubros" />,
  productsOrServicesOffered: <FormattedMessage id="providers.productsOrServicesOffered" defaultMessage="Producto o servicio ofrecido" />,
  cuit: <FormattedMessage id="providers.cuit" defaultMessage="CUIT" />,
  businessName: <FormattedMessage id="providers.businessName" defaultMessage="Razón social" />,
  address: <FormattedMessage id="providers.address" defaultMessage="Dirección" />,
  contactPerson: <FormattedMessage id="providers.contactPerson" defaultMessage="Persona (contacto)" />,
  email: <FormattedMessage id="providers.email" defaultMessage="Email (contacto)" />,
  newProvider: <FormattedMessage id="providers.newProvider" defaultMessage="Nuevo proveedor" />,
  modifyProvider: <FormattedMessage id="providers.modifyProvider" defaultMessage="Modificar proveedor" />,
  successfulUpdate: <FormattedMessage id="providers.successfulUpdate" defaultMessage="Se ha actualizado correctamente" />,
  successfulSave: <FormattedMessage id="providers.successfulSave" defaultMessage="Se ha guardado con éxito" />,
  successfulDelete: <FormattedMessage id="providers.successfulDelete" defaultMessage="Se ha eliminado con éxito" />,
  selectOption: <FormattedMessage id="providers.selectOption" defaultMessage="Seleccione una opción" />,
  deleteContentDialog: <FormattedMessage id="providers.deleteContentModal" defaultMessage="¿Está seguro que quiere eliminar este proveedor?" />,
  deleteTitleDialog: <FormattedMessage id="providers.deleteTitleModal" defaultMessage="Eliminar proveedor" />,
  recommended: <FormattedMessage id="providers.recommended" defaultMessage="Recomendado" />,
  updateProvider: <FormattedMessage id="providers.updateProvider" defaultMessage="Actualizar proveedor" />,
  providersDetails: <FormattedMessage id="providers.providersDetails" defaultMessage="Detalles del proveedor" />,
  generalInformation: <FormattedMessage id="providers.generalInformation" defaultMessage="Información general" />,

  bulkCreationTitle: <FormattedMessage id="providers.bulkCreationTitle" defaultMessage="Carga múltiple de proveedores" />,
  bulkCreationBreadcrumbs: <FormattedMessage id="providers.bulkCreationBreadcrumbs" defaultMessage="Carga múltiple" />,
  downloadProvidersListExample: <FormattedMessage id="providers.downloadProvidersListExample" defaultMessage="Descargar plantilla" />,
  bulkCreationSubtitle: <FormattedMessage id="providers.bulkCreationSubtitle" defaultMessage="Usted puede crear varios proveedores a la vez completando nuestra plantilla. " />,
  bulkCreationSteps: <FormattedMessage id="providers.bulkCreationSteps" defaultMessage="Pasos:" />,
  bulkCreationStep1: <FormattedMessage id="providers.bulkCreationStep1" defaultMessage="1. Descargue la plantilla de Excel." />,
  bulkCreationStep2: <FormattedMessage id="providers.bulkCreationStep2" defaultMessage="2. Complete la plantilla con los datos de los proveedores deseados." />,
  bulkCreationStep3: <FormattedMessage id="providers.bulkCreationStep3" defaultMessage="3. Cargue la plantilla en nuestro sistema aquí abajo." />,

  createProvider: <FormattedMessage id="providers.createBulkProviders" defaultMessage="Crear proveedores" />,
  bulkProvidersNotCreated: <FormattedMessage id="providers.bulkProvidersNotCreated" defaultMessage="Estos proveedores no serán creados" />,
  bulkProvidersUploadedSuccess: <FormattedMessage id="providers.bulkProvidersUploadedSuccess" defaultMessage="Proveedores cargados correctamente:" />,
  onlySuccessUploadProvidersWillBeCreated: <FormattedMessage id="providers.onlySuccessUploadProvidersWillBeCreated" defaultMessage="Solo se crearán los proveedores cargados correctamente." />,
  emptyListSuccessfulUploadProviders: <FormattedMessage id="providers.emptyListSuccessfulUploadProviders" defaultMessage="No hay proveedores cargados correctamente, por favor vuelva a cargar los datos respetando el archivo de ejemplo." />,
  messageUploadedSuccess: <FormattedMessage id="providers.messageUploadedSuccess" defaultMessage="Los proveedores se han agregado correctamente" />,

  evaluations: <FormattedMessage id="workFieldEvaluation.evaluations" defaultMessage="Evaluaciones" />,
  evaluateProvider: <FormattedMessage id="providers.evaluateProvider" defaultMessage="Evaluar proveedor" />,
  rejectedProviders: <FormattedMessage id="providers.rejectedProviders" defaultMessage="Proveedores rechazados" />,
  rejected: <FormattedMessage id="providers.rejected" defaultMessage="Rechazados" />,
  noLinkedWorkFielPopoverMessage: <FormattedMessage id="providers.noLinkedWorkFielPopoverMessage" defaultMessage="No hay rubros vinculados a este proveedor" />,
  noLinkedWorkFieldTitle: <FormattedMessage id="providers.noLinkedWorkFieldTitle" defaultMessage="Consejo" />,
  noLinkedWorkFieldBody: <FormattedMessage id="providers.noLinkedWorkFieldBody" defaultMessage="Si lo desea, a continuación puede editar los Proveedores creados para relacionarlos a uno o más rubros." />,
};

export default providersTexts;
