import React from 'react';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PageLayout from '../../PageLayout';
import ProviderForm from '../ProviderForm';
import { providersTexts } from '../../../constants';

const NewProviderPage = () => {
  function NewProviderBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/providers" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{providersTexts.providers}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{providersTexts.new}</Typography>
      </Breadcrumbs>
    );
  }

  return (
    <PageLayout title={providersTexts.newProvider} breadCrumbs={<NewProviderBreadcrumbs />}>
      <ProviderForm />
    </PageLayout>
  );
};

export default NewProviderPage;
