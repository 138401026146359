import React, { useState, useEffect } from 'react';
import {
  Box, Typography, FormGroup, FormControlLabel, Checkbox, makeStyles,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import colors from '../../../assets/colors';
import calendarHelper from '../../../helpers/calendarHelper';

const useStyles = makeStyles((theme) => ({
  formGroupDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  formGroupMobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    marginBottom: '12px',
  },
  filterTitle: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    color: colors.blackGrey,
  },
}));

const initialFieldValues = {
  evaluation: true,
  training: true,
  deviation: true,
  objective: true,
  risk: true,
  workfield_evaluation: true,
};

const eventTypes = {
  training: 'training',
  evaluation: 'evaluation',
  objective: 'objective',
  deviation: 'deviation',
  risk: 'risk',
  workfield_evaluation: 'workfield_evaluation',
};

const CalendarFilters = ({ eventsArray, setEvents }) => {
  const classes = useStyles();
  const [values, setValues] = useState(initialFieldValues);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setValues({ ...values, [name]: checked });
  };

  const renderCustomCheckbox = (eventType) => (
    <FormControlLabel
      style={{ marginBottom: '7px' }}
      control={(
        <Checkbox
          checked={values[eventType]}
          name={eventType}
          onChange={handleChange}
          style={{ color: calendarHelper.eventColorStyles[eventType], transform: 'scale(1.125)' }}
        />
      )}
      label={(
        <Typography variant="h6" style={{ fontSize: '16px', color: colors.blackGrey }}>
          <FormattedMessage id={`calendar.${eventType}s`} defaultMessage={eventType} />
        </Typography>
      )}
    />
  );

  useEffect(() => {
    if (eventsArray && eventsArray.length > 0) {
      const newEvents = eventsArray.filter((event) => values[event.type] !== false);
      setEvents(newEvents);
    }
  }, [values, eventsArray, setEvents]);

  return (
    <Box style={{ paddingTop: '52px' }}>
      <Box mb={2}>
        <Typography variant="h5" className={classes.filterTitle}>
          Filtros
        </Typography>
      </Box>

      <FormGroup className={classes.formGroupDesktop}>
        {renderCustomCheckbox(eventTypes.training)}
        {renderCustomCheckbox(eventTypes.evaluation)}
        {renderCustomCheckbox(eventTypes.objective)}
        {renderCustomCheckbox(eventTypes.deviation)}
        {renderCustomCheckbox(eventTypes.risk)}
        {renderCustomCheckbox(eventTypes.workfield_evaluation)}
      </FormGroup>

      <FormGroup className={classes.formGroupMobile}>
        <Box>
          {renderCustomCheckbox(eventTypes.training)}
          {renderCustomCheckbox(eventTypes.evaluation)}
          {renderCustomCheckbox(eventTypes.objective)}
        </Box>
        <Box>
          {renderCustomCheckbox(eventTypes.deviation)}
          {renderCustomCheckbox(eventTypes.risk)}
          {renderCustomCheckbox(eventTypes.workfield_evaluation)}
        </Box>
      </FormGroup>

    </Box>
  );
};

CalendarFilters.propTypes = {
  eventsArray: PropTypes.arrayOf(PropTypes.shape()),
  setEvents: PropTypes.func,
};

CalendarFilters.defaultProps = {
  eventsArray: [],
  setEvents: () => [],
};

export default CalendarFilters;
