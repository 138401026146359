import React from 'react';
import { SvgIcon } from "@material-ui/core";

function RisksIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M9.517 14.299C8.80507 14.299 8.22438 13.719 8.22438 13C8.22438 12.283 8.80507 11.701 9.517 11.701C10.2299 11.701 10.8096 12.283 10.8096 13C10.8096 13.719 10.2299 14.299 9.517 14.299ZM8.52176 4H10.5104V9.999H8.52176V4ZM13.2238 0H5.80917L0.568115 5.272V12.728L5.80917 17.999H13.2238L18.4668 12.728V5.272L13.2238 0Z" />
      </SvgIcon>
    );
  }

  export default RisksIcon

  