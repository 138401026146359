import { affiliateConstants } from '../../constants';

function affiliate(state = {}, action) {
  switch (action.type) {
    case affiliateConstants.CREATE_REQUEST:
      return { creating: true };
    case affiliateConstants.CREATE_SUCCESS:
      return { createdAffiliate: action.createdAffiliate };
    case affiliateConstants.CREATE_FAILURE:
      return { };

    case affiliateConstants.GET_REQUEST:
      return { getting: true };
    case affiliateConstants.GET_SUCCESS:
      return { data: action.affiliate };
    case affiliateConstants.GET_FAILURE:
      return { };

    case affiliateConstants.GET_ALL_REQUEST:
      return { getting: true };
    case affiliateConstants.GET_ALL_SUCCESS:
      return { data: action.affiliates };
    case affiliateConstants.GET_ALL_FAILURE:
      return { };

    case affiliateConstants.GET_PRICES_REQUEST:
      return { getting: true };
    case affiliateConstants.GET_PRICES_SUCCESS:
      return { prices: action.affiliate };
    case affiliateConstants.GET_PRICES_FAILURE:
      return { };

    case affiliateConstants.POST_REQUEST:
      return { affiliating: true };
    case affiliateConstants.POST_SUCCESS:
      return { affiliated: true };
    case affiliateConstants.POST_FAILURE:
      return { };

    default:
      return state;
  }
}

export default affiliate;
