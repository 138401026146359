import React from 'react';
import {
  Box, Chip, DialogContent, makeStyles, Typography,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import CalendarEventDetailPersonCard from '../CalendarEventDetailPersonCard';
import { outlineEvaluationConstants } from '../../../../constants';

const useStyles = makeStyles(() => ({
  flexCenterBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  dialogContent: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    margin: '20px 0px 10px',
    boxSizing: 'border-box',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  evaluatedPersonContainer: {
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
    borderRadius: '7px',
    background: '#F8F8F8',
  },
  eventStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
}));

const CalendarEventDetailEvaluationContent = ({ event }) => {
  const classes = useStyles();
  const intl = useIntl();
  const responsibleText = intl.formatMessage({ id: 'people.responsible', defaultMessage: 'Responsable' });
  const evaluatedPersonText = intl.formatMessage({ id: 'outlineEvaluation.person', defaultMessage: 'Persona evaluada' });
  const evaluatedProfileText = intl.formatMessage({ id: 'outlineEvaluation.outline', defaultMessage: 'Perfil Evaluado' });
  const evaluationStatusText = intl.formatMessage({ id: 'calendar.evaluationStatusText', defaultMessage: 'Estado de evaluación' });
  const { person, outline, evaluationStatus } = event.eventInfo;

  const getEvaluationStatus = (evaluation) => {
    if (evaluation === outlineEvaluationConstants.FOR_REVISION) {
      return intl.formatMessage({ id: 'outlineEvaluation.forRevision', defaultMessage: 'Para revisión' });
    }
    if (evaluation === outlineEvaluationConstants.APPROVED) {
      return intl.formatMessage({ id: 'outlineEvaluation.approved', defaultMessage: 'Aprobada' });
    }
    return intl.formatMessage({ id: 'outlineEvaluation.pendingEvaluation', defaultMessage: 'Evaluación pendiente' });
  };

  return (
    <DialogContent className={classes.dialogContent}>
      <Box className={classes.flexCenterBox}>
        <Typography variant="h5" style={{ fontSize: '12px', fontWeight: 600 }}>
          {responsibleText}
        </Typography>
        <CalendarEventDetailPersonCard person={event.eventResponsible} />
      </Box>

      <Box>
        <Box mb={1.8}>
          <Typography style={{ fontWeight: 600, fontSize: '12px', lineHeight: '12px' }} align="left">
            {evaluatedPersonText}
          </Typography>
        </Box>

        <Box className={classes.infoContainer}>
          <Box className={classes.evaluatedPersonContainer}>
            <CalendarEventDetailPersonCard person={person} />
          </Box>
          <Box ml={3.5}>
            <Box mb={2.6}>
              <Typography style={{
                fontWeight: 600, fontSize: '12px', lineHeight: '12px', marginBottom: '8px',
              }}
              >
                {evaluatedProfileText}
              </Typography>
              <Typography style={{ fontSize: '12px', lineHeight: '12px' }}>
                {outline.name}
              </Typography>
            </Box>
            <Box className={classes.eventStateContainer}>
              <Typography variant="h6" style={{ fontSize: '12px', lineHeight: '18px', fontWeight: 600 }}>
                {evaluationStatusText}
              </Typography>
              <Chip
                label={getEvaluationStatus(evaluationStatus)}
                style={evaluationStatus === outlineEvaluationConstants.APPROVED
                  ? { color: '#fff', backgroundColor: '#43A047', labelColor: '#fff' }
                  : { color: '#fff', backgroundColor: '#FF9800', labelColor: '#fff' }}
                icon={evaluationStatus === outlineEvaluationConstants.APPROVED
                  ? <CheckCircleIcon style={{ color: '#fff' }} />
                  : <ErrorIcon style={{ color: '#fff' }} />}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </DialogContent>
  );
};

CalendarEventDetailEvaluationContent.propTypes = {
  event: PropTypes.shape({
    eventInfo: PropTypes.shape({
      person: PropTypes.shape({
        names: PropTypes.string,
        surnames: PropTypes.string,
        role: PropTypes.string,
      }),
      outline: PropTypes.shape({
        name: PropTypes.string,
      }),
      evaluationStatus: PropTypes.number,
      evaluator: PropTypes.string,
    }),
    eventResponsible: PropTypes.shape({}),
  }).isRequired,
};

export default CalendarEventDetailEvaluationContent;
