import React from 'react';
import {
  Box, Grid, Typography,
  DialogTitle, IconButton, makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { useIntl } from 'react-intl';
import { calendarHelper } from '../../../helpers';

const useStyles = makeStyles(() => ({
  classTopColor: {
    height: '11px',
    maxWidth: '452px',
    width: '100%',
  },
  textTitleDialog: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '18px',
    textTransform: 'uppercase',
  },
  closeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
  },
  eventTitleContainer: {
    padding: '18px 0px 0px',
  },
  dateSubtitle: {
    fontSize: '12px',
    lineHeight: '12px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.6)',
  },
}));

const CalendarEventDetailTitle = ({
  eventDate,
  eventTitle,
  eventType,
  toggleDialog,
  eventSubtype,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const pompadourTexts = {
    objective: intl.formatMessage({ id: 'calendar.objectivePompadour', defaultMessage: 'Medición de' }),
    dateFrom: intl.formatMessage({ id: 'calendar.trainingStartPompadour', defaultMessage: 'Inicio de' }),
    effectivenessVerificationDate: intl.formatMessage({ id: 'calendar.effectivenessVerificationDatePompadour', defaultMessage: 'Verificación de efectividad para' }),
    evaluation: intl.formatMessage({ id: 'calendar.evaluationPompadour', defaultMessage: 'Evaluación de' }),
    plannedImplementationDate: intl.formatMessage({ id: 'calendar.plannedImplementationDatePompadour', defaultMessage: 'Implementación de acción correctiva para' }),
    expectedEffectivenessVerificationDate: intl.formatMessage({ id: 'calendar.expectedEffectivenessVerificationDatePompadour', defaultMessage: 'Verificación de efectividad para' }),
    risk: intl.formatMessage({ id: 'calendar.riskPompadour', defaultMessage: 'Evaluación de' }),
    workfield_evaluation: intl.formatMessage({ id: 'calendar.workfield_evaluationPompadour', defaultMessage: 'Evaluación del proveedor' }),
  };

  const getTitleText = (type, subtype) => {
    if (subtype && type !== 'objective') return pompadourTexts[subtype];
    return pompadourTexts[type];
  };

  const ModalTopColorStripe = () => (
    <Box
      className={classes.classTopColor}
      style={{ background: calendarHelper.eventColorStyles[eventType] }}
    />
  );

  return (
    <>
      <ModalTopColorStripe />

      <Grid container className={classes.eventTitleContainer}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Typography variant="h4" style={{ fontSize: '12px', marginBottom: '2px' }} align="center">
            {getTitleText(eventType, eventSubtype)}
          </Typography>
          <DialogTitle disableTypography style={{ paddingTop: 0, paddingBottom: 2 }}>
            <Box display="flex" justifyContent="center">
              <Typography align="center" className={classes.textTitleDialog} variant="body1">
                {eventTitle}
              </Typography>
            </Box>
          </DialogTitle>
          <Box mt={0.5}>
            <Typography variant="h5" className={classes.dateSubtitle}>
              {calendarHelper.formatDateForTitle(eventDate)}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={2} className={classes.closeContainer}>
          <IconButton aria-label="close" onClick={toggleDialog}>
            <CloseIcon style={{ transform: 'scale(1.50)' }} />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

CalendarEventDetailTitle.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  eventTitle: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
  eventDate: PropTypes.string.isRequired,
  eventSubtype: PropTypes.string,
};

CalendarEventDetailTitle.defaultProps = {
  eventSubtype: '',
};

export default CalendarEventDetailTitle;
