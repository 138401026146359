import { fodaConstants } from '../../constants';

function foda(state = {}, action) {
  switch (action.type) {
    case fodaConstants.UPDATE_REQUEST:
      return { updating: true };
    case fodaConstants.UPDATE_SUCCESS:
      return { data: action.updatedFoda };
    case fodaConstants.UPDATE_FAILURE:
    default:
      return state;
  }
}

export default foda;
