import React, { useState, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { deviationHelper } from '../../../helpers';
import CustomLaneHeader from '../../Deviation/DeviationBoardPage/Component/CustomLaneHeader';
import ProcessDeviationCustomCard from './ProcessDeviationCustomCard';

const useStyles = makeStyles(() => ({
  laneStyle: {
    border: '2px solid #DADADA',
    borderRadius: '8px',
    width: 'calc(100% / 4)',
    maxWidth: '270px',
  },
  collapsedView: {
    maxHeight: '345px',
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarColor: '#999999',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '0.3em',
      paddingLeft: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(0,0,0,.4)',
    },
  },
}));

const initialBoardDataValues = {
  lanes: [
    { id: 'documented', cards: [] },
    { id: 'analyzed', cards: [] },
    { id: 'correctiveActionDefined', cards: [] },
    { id: 'correctiveActionImplemented', cards: [] },
  ],
};

const ProcessDeviationDetail = ({ deviations }) => {
  const classes = useStyles();
  const [boardData, setBoardData] = useState(initialBoardDataValues);

  useEffect(() => {
    if (deviations) setBoardData(deviationHelper.toBoardData(deviations));
  }, [deviations]);

  return (
    <>
      {Boolean(deviations.length) && (
      <Box display="flex">
        {boardData.lanes.map((lane) => (
          <Box key={lane.id} mx={1} className={classes.laneStyle}>
            <CustomLaneHeader id={lane.id} style={{ paddingRight: '14px' }} isWithStyle />
            <Box className={classes.collapsedView}>
              {Boolean(lane.cards.length)
                && lane.cards.map((card) => {
                  const customProps = {
                    ...card,
                    laneId: card.currentState,
                  };
                  return <ProcessDeviationCustomCard key={card.id} {...customProps} />;
                })}
            </Box>
          </Box>
        ))}
      </Box>
      )}
    </>
  );
};

ProcessDeviationDetail.propTypes = {
  deviations: PropTypes.arrayOf((PropTypes.shape())),
};

ProcessDeviationDetail.defaultProps = {
  deviations: [],
};

export default ProcessDeviationDetail;
