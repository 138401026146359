import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles,
  Box, Grid, Container,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { validationConstants } from '../../constants';
import { processActions } from '../../redux/actions';
import Button from '../controls/Button';
import { history } from '../../helpers';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
}));

function ProcessForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { edit, data } = props;
  const initialFieldValues = {
    processName: (data && Object.keys(data).length) ? data.name : '',
    area: (data && Object.keys(data).length) ? data.area : '',
    deleteProcessDialog: false,
  };
  const [values, setValues] = useState(initialFieldValues);
  const { processName, area, deleteProcessDialog } = values;
  const creatingProcess = useSelector((state) => state.process.creating);
  const deletingProcess = useSelector((state) => state.process.deleting);
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const nameLabel = intl.formatMessage({ id: 'processes.name', defaultMessage: 'Nombre' });
  const areaLabel = intl.formatMessage({ id: 'processes.area', defaultMessage: 'Area' });
  const deleteProcessTitle = intl.formatMessage({ id: 'processes.delete.title', defaultMessage: 'Eliminar proceso' });
  const deleteProcessButtonText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'Eliminar' });
  const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const deleteProcessText = intl.formatMessage({ id: 'processes.deleteProcessText', defaultMessage: 'Esta seguro que quiere eliminar el proceso?' });
  const deleteText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'eliminar' });
  const backText = intl.formatMessage({ id: 'app.back', defaultMessage: 'volver' });
  const saveText = intl.formatMessage({ id: 'app.save', defaultMessage: 'guardar' });
  const createText = intl.formatMessage({ id: 'app.create', defaultMessage: 'crear' });

  function handleSubmit(e) {
    e.preventDefault();
    const process = { name: processName, area };

    if (data) { process.id = data.id; }

    if (!edit) {
      dispatch(processActions.create(process, '/processes/'));
    } else {
      dispatch(processActions.update(process, '/processes/'));
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  }

  function handleDialogOpen(dialogName) {
    setValues({
      ...values,
      [dialogName]: true,
    });
  }

  function handleDialogClose(dialogName) {
    setValues({
      ...values,
      [dialogName]: false,
    });
  }

  function deleteProcess() {
    dispatch(processActions.deleteProcess(data.id, '/processes'));
    history.push('/processes');
  }

  return (
    <div className={classes.main}>
      <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Container>
              <TextValidator
                value={processName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                id="processName"
                label={nameLabel}
                name="processName"
                size="small"
                validators={['required']}
                errorMessages={[validationConstants.PROCESS_NAME_REQUIRED]}
              />
            </Container>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Container>
              <TextValidator
                value={area}
                onChange={handleChange}
                fullWidth
                margin="normal"
                id="area"
                label={areaLabel}
                name="area"
                size="small"
                validators={['required']}
                errorMessages={[validationConstants.PROCESS_AREA_REQUIRED]}
              />
            </Container>
          </Grid>
        </Grid>

        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
          <Button
            component={Link}
            to="/processes"
            variant="outlined"
            text={backText}
            style={{ fontSize: '10px', fontWeight: 600 }}
          />
          {edit
            ? (
              <>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => { handleDialogOpen('deleteProcessDialog'); }}
                  text={deleteText}
                  style={{ fontSize: '10px', fontWeight: 600 }}
                  disabled={!isAdmin}
                />
                <Button
                  type="submit"
                  text={saveText}
                  style={{ fontSize: '10px', fontWeight: 600 }}
                  disabled={!isAdmin}
                />
                <Dialog open={deleteProcessDialog} onClose={() => { handleDialogClose('deleteProcessDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">{deleteProcessTitle}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {deleteProcessText}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button text={cancelButtonText} onClick={() => { handleDialogClose('deleteProcessDialog'); }} />
                    <Button
                      className={classes.menuButton}
                      text={deleteProcessButtonText}
                      color="secondary"
                      loading={deletingProcess}
                      onClick={deleteProcess}
                    />
                  </DialogActions>
                </Dialog>
              </>
            )
            : (
              <>
                <Button
                  type="submit"
                  loading={creatingProcess}
                  text={createText}
                  disabled={!isAdmin}
                />
              </>
            )}

        </Box>
      </ValidatorForm>
    </div>
  );
}

ProcessForm.propTypes = {
  data: PropTypes.shape(PropTypes.string),
  edit: PropTypes.bool,
};

ProcessForm.defaultProps = {
  data: {},
  edit: false,
};

export default ProcessForm;
