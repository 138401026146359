import React, { useEffect } from 'react';
import {
  Box, Breadcrumbs, Typography, Grid, LinearProgress, Button as MuiButton,
  List, ListItem, Chip, makeStyles, Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  CheckCircle, Error, ExpandMore, Cancel,
} from '@material-ui/icons';
import { history, workFieldHelper } from '../../../helpers';
import PageLayout from '../../PageLayout';
import { workFieldEvaluationActions, personActions } from '../../../redux/actions';
import Button from '../../controls/Button';
import CustomRatingWorkField from '../../controls/CustomRatingWorkField';
import { workFieldEvaluationTexts, workFieldTexts } from '../../../constants';
import { NonConformityIcon, RisksIcon } from '../../../assets/icons/index';
import WorkFieldEvaluationDocuments from '../WorkFieldEvaluationDocuments';

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontSize: '14px',
    fontWeight: 700,
  },
  contentText: {
    fontSize: '14px',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  textTitleItems: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '18px',
  },
  textSubtitleItems: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  breadcrumbs: {
    textDecoration: 'none',
    fontWeight: 400,
    fontSize: 11,
  },
  accordionDetails: {
    display: 'block',
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '10px',
    fontWeight: 600,
    textTransform: 'uppercase',
    textDecoration: 'none',
  },
  icon: {
    color: theme.palette.primary.main,
    height: '16px',
    width: '16px',
  },
}));

const WorkFieldEvaluationDetailsPage = ({ routeParams: { id: workFieldEvaluationId } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state.authentication.user);
  const people = useSelector((state) => state.person.people);
  const isAdmin = roles.includes('Admin');
  const workFieldEvaluation = useSelector((state) => state.workFieldEvaluation.data);
  const workFieldItemsParsed = workFieldEvaluation && JSON.parse(workFieldEvaluation.answers);

  useEffect(() => {
    if (!people) {
      dispatch(personActions.getAll());
    }
    if (!workFieldEvaluation
      || (workFieldEvaluation && workFieldEvaluation.id.toString() !== workFieldEvaluationId)) {
      dispatch(workFieldEvaluationActions.get(workFieldEvaluationId));
    }
  }, [dispatch, workFieldEvaluationId, people, workFieldEvaluation]);

  const resultAllEvaluation = workFieldItemsParsed && workFieldHelper
    .getWorkFieldStatus(workFieldItemsParsed, workFieldItemsParsed);

  const allEvaluationWithStatus = resultAllEvaluation && workFieldItemsParsed
    && workFieldItemsParsed.map((itemForPerson) => {
      const resultEvaluation = resultAllEvaluation.find(
        (item) => item.personId === itemForPerson.personId,
      );
      const statusEvaluation = workFieldHelper.getResultEvaluation(resultEvaluation.result);
      return { ...itemForPerson, status: statusEvaluation };
    });

  const totalEvaluationResult = resultAllEvaluation && workFieldHelper.round2decimals(
    workFieldHelper.getAverage(resultAllEvaluation.map((item) => item.result)),
  );

  function WorkFieldEvaluationDetailsBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography component={Link} to="/providers" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {workFieldEvaluationTexts.providers}
        </Typography>
        <Typography component={Link} to="/workFieldEvaluations" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {workFieldEvaluationTexts.evaluations}
        </Typography>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{`${workFieldEvaluation.provider.name}: ${workFieldEvaluation.workField.name}`}</Typography>
      </Breadcrumbs>
    );
  }

  function WorkFieldEvaluationDetailsLoadingBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography component={Link} to="/providers" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {workFieldEvaluationTexts.providers}
        </Typography>
        <Typography component={Link} to="/workFieldEvaluations" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {workFieldEvaluationTexts.evaluations}
        </Typography>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{workFieldEvaluationTexts.workFieldEvaluationDetails}</Typography>
      </Breadcrumbs>
    );
  }

  const IconStatus = ({ status, ...rest }) => {
    switch (status) {
      case workFieldHelper.statusEnum.APPROVED:
        return <CheckCircle {...rest} />;
      case workFieldHelper.statusEnum.REJECTED:
        return <Cancel {...rest} />;
      case workFieldHelper.statusEnum.CONDITIONAL:
        return <Error {...rest} />;
      default:
        return <Error {...rest} />;
    }
  };
  IconStatus.propTypes = {
    status: PropTypes.number.isRequired,
  };

  const EvaluationListAccordion = ({ itemForPerson }) => {
    const resultEvaluation = resultAllEvaluation
      .find((item) => item.personId === itemForPerson.personId);
    const statusEvaluation = workFieldHelper.getResultEvaluation(resultEvaluation.result);
    const colorStatus = workFieldHelper.getEvaluationStatusColor(statusEvaluation);
    return (
      <Accordion
        key={itemForPerson.personId}
        defaultExpanded={statusEvaluation === workFieldHelper.statusEnum.PENDING}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <ListItem disableGutters>
            <Typography className={classes.textTitleItems} style={{ paddingTop: '8px', paddingBottom: '8px', minWidth: '280px' }}>
              {people && workFieldHelper.getNameForResponsibility(
                people.find((person) => person.id === itemForPerson.personId),
              )}
            </Typography>
            <Typography className={classes.textSubtitleItems} style={{ width: '240px' }}>
              <IconStatus status={statusEvaluation} style={{ color: colorStatus, verticalAlign: 'top', marginRight: 4 }} />
              {workFieldHelper.getEvaluationStatusLabel(statusEvaluation)}
              {resultEvaluation.result && ` - ${resultEvaluation.result}`}
            </Typography>
          </ListItem>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.accordionDetails }}>

          <ListItem disableGutters>
            <Typography className={classes.textTitleItems} style={{ minWidth: '280px' }}>
              {workFieldTexts.itemsToBeEvaluated}
            </Typography>
            <Typography className={classes.textTitleItems} style={{ width: '240px' }}>
              {workFieldTexts.weightingPercentage}
            </Typography>
            <Typography className={classes.textTitleItems} style={{ width: '260px' }}>
              {workFieldTexts.resultEvaluation}
            </Typography>
          </ListItem>
          {itemForPerson.answers.map((item) => (
            <ListItem key={`${item.name}-${itemForPerson.personId}`} disableGutters>
              <Typography className={classes.textTitleItems} style={{ minWidth: '280px' }}>{item.name}</Typography>
              <Typography className={classes.textSubtitleItems} style={{ width: '240px' }}>{`${item.weightPercentage}%`}</Typography>
              <CustomRatingWorkField
                itemName={item.name}
                itemScore={item.score}
                itemToPersonId={itemForPerson.personId}
                readOnly
              />
            </ListItem>
          ))}
        </AccordionDetails>
      </Accordion>
    );
  };
  EvaluationListAccordion.propTypes = {
    itemForPerson: PropTypes.shape({
      personId: PropTypes.number,
      answers: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        score: PropTypes.number,
        weightPercentage: PropTypes.number,
      })),
      status: PropTypes.number,
    }).isRequired,
  };

  return (
    <>
      {workFieldEvaluation
        ? (
          <PageLayout
            title={workFieldEvaluationTexts.workFieldEvaluationDetails}
            breadCrumbs={<WorkFieldEvaluationDetailsBreadcrumbs />}
          >
            <Box pl={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Chip
                    label={workFieldHelper
                      .getEvaluationStatusLabel(workFieldEvaluation.evaluationStatus)}
                    style={{
                      color: '#fff',
                      labelColor: '#fff',
                      backgroundColor: workFieldHelper
                        .getEvaluationStatusColor(workFieldEvaluation.evaluationStatus),
                    }}
                    icon={<IconStatus status={workFieldEvaluation.evaluationStatus} style={{ color: '#fff' }} />}
                  />
                </Grid>

              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.titleText} display="block" variant="caption" gutterBottom>
                    {workFieldEvaluationTexts.providerEvaluated}
                  </Typography>
                  <Typography component={Link} to={`/providers/${workFieldEvaluation.provider.id}`} variant="subtitle1" gutterBottom className={classes.contentText}>
                    {workFieldEvaluation.provider.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.titleText} variant="caption" gutterBottom>
                    {workFieldTexts.totalEvaluationResult}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom className={classes.contentText} style={{ display: 'flex', alignItems: 'center' }}>
                    {workFieldHelper.getEvaluationStatusLabel(workFieldEvaluation.evaluationStatus)}
                    {
                      workFieldEvaluation.evaluationStatus !== workFieldHelper.statusEnum.PENDING
                        && ` - ${totalEvaluationResult}`
                      }
                  </Typography>
                  {(workFieldEvaluation.evaluationStatus === workFieldHelper.statusEnum.REJECTED
                || workFieldEvaluation.evaluationStatus === workFieldHelper.statusEnum.CONDITIONAL)
                  && (
                    <Box>
                      <Box display="flex" alignItems="center" justifyContent="flex-start">
                        <NonConformityIcon className={classes.icon} display="inline" />
                        <MuiButton
                          color="primary"
                          component={Link}
                          to={workFieldEvaluation.deviations[0]
                            ? `/deviations/${workFieldEvaluation.deviations[0].id}`
                            : `/deviations/new?providerId=${workFieldEvaluation.provider.id}&evaluationId=${workFieldEvaluation.id}`}
                          display="inline"
                          className={classes.link}
                          style={{ paddingLeft: '10px', textDecoration: 'none' }}
                        >
                          {workFieldEvaluation.deviations[0]
                            ? workFieldEvaluationTexts.seeDeviation
                            : workFieldEvaluationTexts.createDeviation}
                        </MuiButton>
                      </Box>
                      <Box display="flex" alignItems="center" justifyContent="flex-start">
                        <RisksIcon className={classes.icon} display="inline" />
                        <MuiButton
                          color="primary"
                          component={Link}
                          to={workFieldEvaluation.risks[0]
                            ? `/risks/${workFieldEvaluation.risks[0].id}`
                            : `/risks/new?providerId=${workFieldEvaluation.provider.id}&evaluationId=${workFieldEvaluation.id}`}
                          display="inline"
                          className={classes.link}
                          style={{ paddingLeft: '10px', textDecoration: 'none' }}
                        >
                          {workFieldEvaluation.risks[0]
                            ? workFieldEvaluationTexts.seeRisk
                            : workFieldEvaluationTexts.createRisk}
                        </MuiButton>
                      </Box>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.titleText} display="block" variant="caption" gutterBottom>
                    {workFieldEvaluationTexts.workFieldEvaluated}
                  </Typography>
                  <Typography component={Link} to={`/workFields/${workFieldEvaluation.workField.id}`} variant="subtitle1" gutterBottom className={classes.contentText}>
                    {workFieldEvaluation.workField.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.titleText} variant="caption" gutterBottom>
                    {workFieldEvaluationTexts.date}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom className={classes.contentText}>
                    {moment(workFieldEvaluation.date).format('DD/MM/YYYY')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.titleText} display="block" variant="caption" gutterBottom>
                    {workFieldEvaluationTexts.workFieldEvaluationInChargePerson}
                  </Typography>
                  {workFieldEvaluation
                      && workFieldEvaluation.responsiblePeople
                      && workFieldEvaluation.responsiblePeople.map((person) => (
                        <Typography variant="subtitle1" display="block" className={classes.contentText} key={person.id} component={Link} to={`/people/${person.id}`}>
                          {workFieldHelper.getNameForResponsibility(person)}
                        </Typography>
                      ))}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.titleText} display="block" variant="caption" gutterBottom>
                    {workFieldEvaluationTexts.scoresReference}
                  </Typography>
                  <Typography variant="subtitle1" display="block" className={classes.contentText}>
                    {workFieldEvaluationTexts.approved}
                    : 3 - 5
                  </Typography>
                  <Typography variant="subtitle1" display="block" className={classes.contentText}>
                    {workFieldEvaluationTexts.conditional}
                    : 2 - 2.99
                  </Typography>
                  <Typography variant="subtitle1" display="block" className={classes.contentText}>
                    {workFieldEvaluationTexts.rejected}
                    : 1 - 1.99
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={10} sm={10}>
                  <Typography className={classes.titleText} variant="caption" gutterBottom>
                    {workFieldEvaluationTexts.evaluations}
                  </Typography>

                  <List dense>
                    {allEvaluationWithStatus
                      .filter((item) => item.status === workFieldHelper.statusEnum.PENDING)
                      .map((item) => (
                        <EvaluationListAccordion key={item.personId} itemForPerson={item} />
                      ))}
                  </List>

                  <List dense>
                    {allEvaluationWithStatus
                      .filter((item) => item.status !== workFieldHelper.statusEnum.PENDING)
                      .map((item) => (
                        <EvaluationListAccordion key={item.personId} itemForPerson={item} />
                      ))}
                  </List>

                </Grid>

              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <WorkFieldEvaluationDocuments
                    documents={workFieldEvaluation.documents}
                    workFieldEvaluationId={workFieldEvaluation.id}
                    showDivider
                  />
                </Grid>
              </Grid>

            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
              <Button
                onClick={history.goBack}
                variant="outlined"
                text={workFieldEvaluationTexts.back}
              />
              <Button
                component={Link}
                to={`${workFieldEvaluationId}/edit`}
                type="submit"
                text={workFieldEvaluationTexts.edit}
                disabled={!isAdmin}
              />
            </Box>
          </PageLayout>
        )
        : (
          <PageLayout
            title={workFieldEvaluationTexts.workFieldEvaluationDetails}
            breadCrumbs={<WorkFieldEvaluationDetailsLoadingBreadcrumbs />}
          >
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
};
WorkFieldEvaluationDetailsPage.propTypes = {
  routeParams: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default WorkFieldEvaluationDetailsPage;
