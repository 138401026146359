import { taxIDs } from '../common/taxIDs';

export const handleTaxIDType = ({
  name,
  setValues,
  value
}) => {
  if (name === 'taxIDType') {
    const newValues = [null, undefined].includes(value)
      ? { taxID: '', taxIDType: '' }
      : { taxIDType: taxIDs[value]?.code };

    setValues((oldValues) => ({ ...oldValues, ...newValues }));
  };

  if (name === 'taxID') {
    const newValues = !value ? { taxID: '' } : { taxID: value };
    setValues((oldValues) => ({ ...oldValues, ...newValues }));
  };
};
