import React, { useEffect, useState } from 'react';
import {
  Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid,
  makeStyles, MenuItem, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../controls/Button';
import {
  documentConstants, providersTexts, validationConstants, workFieldTexts,
} from '../../constants';
import { providerActions, workFieldActions } from '../../redux/actions';
import ProviderDocuments from './ProviderDocuments';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  getContentAnchorEl: null,
};

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  textLabel: {
    '& .MuiFormLabel-root': {
      fontSize: '12px',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px) scale(1)',
      textAlign: 'left',
    },
  },
  inputContainer: {
    padding: '0 18px',
    textAlign: 'left',
  },
  textValue: {
    paddingTop: '8px',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'left',
  },
}));

const ProviderForm = ({ edit, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state.authentication.user);
  const creatingProvider = useSelector((state) => state.provider.creating);
  const updatingProvider = useSelector((state) => state.provider.updating);
  const deletingProvider = useSelector((state) => state.provider.deleting);
  const allWorkField = useSelector((state) => state.workField.data);
  const isAdmin = roles.includes('Admin');

  useEffect(() => {
    dispatch(workFieldActions.getAll());
  }, [dispatch]);

  const getWorkFieldsId = (workFieldToProvider) => workFieldToProvider.map((wf) => wf.workFieldId);

  const initialFieldValues = {
    ...data,
    name: (data && data.name) ? data.name : '',
    workFieldId: (data && data.workFields) ? getWorkFieldsId(data.workFields) : [],
    productsOrServicesOffered: (data && data.productsOrServicesOffered) ? data.productsOrServicesOffered : '',
    cuit: (data && data.cuit) ? data.cuit : '',
    businessName: (data && data.businessName) ? data.businessName : '',
    address: (data && data.address) ? data.address : '',
    contactPerson: (data && data.contactPerson) ? data.contactPerson : '',
    email: (data && data.email) ? data.email : '',
  };

  const [values, setValues] = useState(initialFieldValues);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  function handleChangeWorkFields(e) {
    const { value } = e.target;
    setValues((prev) => ({ ...prev, workFieldId: value }));
  }

  function toggleDeleteDialog() {
    setOpenDeleteDialog(!openDeleteDialog);
  }

  function handleDeleteProviders(id) {
    dispatch(providerActions.deleteProvider(id, providersTexts.successfulDelete));
  }

  function handleCreateProviders(workField) {
    dispatch(providerActions.create(workField, providersTexts.successfulSave));
  }

  function handleSaveEditProviders(workField) {
    dispatch(providerActions.update(data.id, workField, providersTexts.successfulUpdate));
  }

  function handleSubmitForm(e) {
    e.preventDefault();
    const { workFields, ...workFieldToSend } = values;
    if (edit) return handleSaveEditProviders(workFieldToSend);
    return handleCreateProviders(workFieldToSend);
  }

  function handleChangeFormData(e) {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  }

  return (
    <div className={classes.main}>
      <ValidatorForm onSubmit={handleSubmitForm} autoComplete="off" className={classes.textLabel} instantValidate>
        <Grid container spacing={3} align="center">
          <Grid item xs={12} md={6}>
            <Box className={classes.inputContainer}>
              <TextValidator
                fullWidth
                id="name"
                label={providersTexts.name}
                margin="normal"
                name="name"
                onChange={handleChangeFormData}
                size="small"
                value={values.name}
                validators={['required']}
                errorMessages={[validationConstants.PROVIDER_NAME]}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box className={classes.inputContainer}>
              {allWorkField && allWorkField.length
                ? (
                  <SelectValidator
                    SelectProps={{ multiple: true, MenuProps }}
                    fullWidth
                    id="workFields"
                    label={workFieldTexts.addWorkField}
                    margin="normal"
                    name="workFields"
                    onChange={handleChangeWorkFields}
                    size="small"
                    value={values.workFieldId}
                    validators={['required']}
                    errorMessages={[validationConstants.PROVIDER_WORK_FIELD]}
                  >
                    <MenuItem disabled value="" style={{ fontSize: '12px' }}>
                      <em>{workFieldTexts.addWorkField}</em>
                    </MenuItem>
                    {allWorkField.map((workField) => (
                      <MenuItem key={workField.id} value={workField.id} style={{ fontSize: '12px' }}>
                        {workField.name}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                )
                : (
                  <Box mt={4}>
                    <Typography className={classes.textValue} display="inline">
                      {workFieldTexts.noWorkField}
                    </Typography>
                    <Typography component={Link} to="/workFields/new" className={classes.textValue} display="inline" style={{ color: '#F16C24', textDecoration: 'none' }}>
                      {workFieldTexts.createLink}
                    </Typography>
                  </Box>
                )}
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box className={classes.inputContainer}>
              <TextValidator
                fullWidth
                id="productsOrServicesOffered"
                label={providersTexts.productsOrServicesOffered}
                margin="normal"
                name="productsOrServicesOffered"
                onChange={handleChangeFormData}
                size="small"
                value={values.productsOrServicesOffered}
                validators={['required']}
                errorMessages={[validationConstants.PROVIDER_OFFERED]}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box className={classes.inputContainer}>
              <TextValidator
                fullWidth
                id="cuit"
                label={providersTexts.cuit}
                margin="normal"
                name="cuit"
                onChange={handleChangeFormData}
                size="small"
                value={values.cuit}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box className={classes.inputContainer}>
              <TextValidator
                fullWidth
                id="businessName"
                label={providersTexts.businessName}
                margin="normal"
                name="businessName"
                onChange={handleChangeFormData}
                size="small"
                value={values.businessName}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box className={classes.inputContainer}>
              <TextValidator
                fullWidth
                id="address"
                label={providersTexts.address}
                margin="normal"
                name="address"
                onChange={handleChangeFormData}
                size="small"
                value={values.address}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box className={classes.inputContainer}>
              <TextValidator
                fullWidth
                id="contactPerson"
                label={providersTexts.contactPerson}
                margin="normal"
                name="contactPerson"
                onChange={handleChangeFormData}
                size="small"
                value={values.contactPerson}
                helperText={providersTexts.recommended}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box className={classes.inputContainer}>
              <TextValidator
                fullWidth
                id="email"
                label={providersTexts.email}
                margin="normal"
                name="email"
                autoComplete="email"
                onChange={handleChangeFormData}
                size="small"
                value={values.email}
                validators={['isEmail']}
                errorMessages={[validationConstants.EMAIL_INVALID]}
                helperText={providersTexts.recommended}
              />
            </Box>
          </Grid>

          {
            edit && (
              <Grid item xs={12} sm={6}>
                <Box pl={3}>
                  <ProviderDocuments
                    documents={data.documents}
                    providerId={data.id}
                  />
                </Box>
              </Grid>
            )
          }

        </Grid>
        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
          <Button
            component={Link}
            to="/providers"
            variant="outlined"
            text={providersTexts.back}
            style={{ fontSize: '10px', fontWeight: 600 }}
          />
          {edit
            ? (
              <>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => { toggleDeleteDialog(); }}
                  text={providersTexts.delete}
                  style={{ fontSize: '10px', fontWeight: 600 }}
                  disabled={!isAdmin}
                />
                <Button
                  type="submit"
                  text={providersTexts.save}
                  style={{ fontSize: '10px', fontWeight: 600 }}
                  disabled={!isAdmin}
                  loading={updatingProvider}
                />
              </>
            )
            : (
              <Button
                type="submit"
                loading={creatingProvider}
                text={providersTexts.create}
                disabled={!isAdmin}
              />
            )}
        </Box>
      </ValidatorForm>

      <Dialog open={openDeleteDialog} onClose={() => toggleDeleteDialog()} fullWidth maxWidth="xs" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{providersTexts.deleteTitleDialog}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {providersTexts.deleteContentDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button text={providersTexts.cancel} onClick={() => toggleDeleteDialog()} />
          <Button
            text={providersTexts.delete}
            color="secondary"
            loading={deletingProvider}
            onClick={() => { handleDeleteProviders(data.id); }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

ProviderForm.propTypes = {
  edit: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    workFields: PropTypes.arrayOf(PropTypes.shape({
      workFieldId: PropTypes.number,
      workField: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        workFieldItems: PropTypes.string,
      }),
    })),
    productsOrServicesOffered: PropTypes.string,
    cuit: PropTypes.string,
    businessName: PropTypes.string,
    address: PropTypes.string,
    contactPerson: PropTypes.string,
    email: PropTypes.string,
    documents: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      type: PropTypes.oneOf([documentConstants.PROVIDER_DOCUMENT]),
      actualDocumentVersionId: PropTypes.number,
      usage: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        entityName: PropTypes.string,
      })),
    })).isRequired,
  }),
};

ProviderForm.defaultProps = {
  edit: false,
  data: {},
};

export default ProviderForm;
