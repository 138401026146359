import React from 'react';
import { FormattedMessage } from 'react-intl';

const eventColorStyles = {
  risk: '#BF1919',
  objective: '#71A923',
  deviation: '#F16C24',
  evaluation: '#1A3D78',
  training: '#1FCFF2',
  workfield_evaluation: '#415CF1',
};

function setFrontPropsToEvents(events) {
  return events.map((e) => {
    let eventWithProps = {
      ...e,
      background: eventColorStyles[e.type],
      borderColor: eventColorStyles[e.type],
    };
    if (e.type !== 'evaluation') eventWithProps = { ...eventWithProps, allDay: true };
    return eventWithProps;
  });
}

function formatDateForTitle(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const monthsArray = {
    0: <FormattedMessage id="calendar.januaryForTitle" defaultMessage={`${day} de enero ${year}`} values={{ day, year }} />,
    1: <FormattedMessage id="calendar.februaryForTitle" defaultMessage={`${day} de febrero ${year}`} values={{ day, year }} />,
    2: <FormattedMessage id="calendar.marchForTitle" defaultMessage={`${day} de marzo ${year}`} values={{ day, year }} />,
    3: <FormattedMessage id="calendar.aprilForTitle" defaultMessage={`${day} de abril ${year}`} values={{ day, year }} />,
    4: <FormattedMessage id="calendar.mayForTitle" defaultMessage={`${day} de mayo ${year}`} values={{ day, year }} />,
    5: <FormattedMessage id="calendar.juneForTitle" defaultMessage={`${day} de junio ${year}`} values={{ day, year }} />,
    6: <FormattedMessage id="calendar.julyForTitle" defaultMessage={`${day} de julio ${year}`} values={{ day, year }} />,
    7: <FormattedMessage id="calendar.augustForTitle" defaultMessage={`${day} de agosto ${year}`} values={{ day, year }} />,
    8: <FormattedMessage id="calendar.septemberForTitle" defaultMessage={`${day} de septiembre ${year}`} values={{ day, year }} />,
    9: <FormattedMessage id="calendar.octoberForTitle" defaultMessage={`${day} de octubre ${year}`} values={{ day, year }} />,
    10: <FormattedMessage id="calendar.novemberForTitle" defaultMessage={`${day} de noviembre ${year}`} values={{ day, year }} />,
    11: <FormattedMessage id="calendar.decemberForTitle" defaultMessage={`${day} de diciembre ${year}`} values={{ day, year }} />,
  };

  return monthsArray[month];
}

const calendarHelper = {
  eventColorStyles,
  setFrontPropsToEvents,
  formatDateForTitle,
};

export default calendarHelper;
