import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Box,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  Create as CreateIcon,
  VisibilityOutlined as VisibilityIcon
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { getEntityLabel, getEntityUrl, keys, itemUrl } from './constants';
import { handleClickOnDocument, initialDocumentState } from '../../../common/constants';
import DataTable from '../../DataTable/DataTable';
import DocumentPreviewModal from '../../controls/Modals/DocumentPreview';
import DocumentsPageBreadcrumbs from './components/DocumentsPageBreadcrumbs';
import DownloadDocument from '../../controls/DownloadDocument';
import PageLayout from '../../PageLayout';
import useStyles from './useStyles';

const DocumentsPage = () => {
  const classes = useStyles();
  const intl = useIntl();
  const userAuth = useSelector((state) => state.authentication.user);

  const [documentState, setDocumentState] = useState(initialDocumentState);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const isAdmin = userAuth.roles.includes('Admin');

  const fetchUrl = `companies/${userAuth.company.id}/Documents/`;

  const createNewVersionDocument = intl.formatMessage({ id: 'document.newVersion', defaultMessage: 'Crear nueva version' });
  const previewDocument = intl.formatMessage({ id: 'document.preview', defaultMessage: 'Vista previa' });
  const title = intl.formatMessage({ id: 'document.documentsTitle', defaultMessage: 'Documentos' });

  const getProcessesFormatted = (entity, type) => {
    if (!entity.length || !type) {
      return '-';
    }
    if (entity.length === 1) {
      return (
        <Typography display="inline" className={classes.text} component={Link} to={getEntityUrl(type, entity[0].id)}>
          {entity[0].entityName}
        </Typography>
      );
    }
    if (entity.length === 2) {
      return (
        <Typography className={classes.text} style={{ maxWidth: 240 }}>
          <Typography display="inline" className={classes.internalText} component={Link} to={getEntityUrl(type, entity[0].id)}>
            {`${entity[0].entityName}, `}
          </Typography>
          <Typography display="inline" className={classes.internalText} component={Link} to={getEntityUrl(type, entity[1].id)}>
            {`${entity[1].entityName}`}
          </Typography>
        </Typography>
      );
    }

    return (
      <Typography className={classes.text} style={{ maxWidth: 240 }}>
        <Typography display="inline" className={classes.internalText} component={Link} to={getEntityUrl(type, entity[0].id)}>
          {`${entity[0].entityName}, `}
        </Typography>
        <Typography display="inline" className={classes.internalText} component={Link} to={getEntityUrl(type, entity[1].id)}>
          {`${entity[1].entityName}, `}
        </Typography>
        <FormattedMessage
          id="document.remainingEntities"
          defaultMessage="y varios más"
          values={{ count: (entity.length - 2) }}
        />
      </Typography>
    );
  };

  const actionsButtons = (link, document) => (
    <Box display="flex">
      <IconButton
        className={classes.iconButton}
        onClick={() => handleClickOnDocument({
          document,
          setDocumentState,
          setIsPreviewModalOpen
        })}
      >
        <Tooltip title={previewDocument}>
          <VisibilityIcon className={classes.actionIcon} />
        </Tooltip>
      </IconButton>
      <IconButton
        component={Link}
        to={link}
        className={classes.iconButton}
      >
        <Tooltip title={createNewVersionDocument}>
          <CreateIcon className={classes.iconUpdate} />
        </Tooltip>
      </IconButton>
      <DownloadDocument
        document={document}
        actualVersion={document.lastVersion}
        iconClassName={classes.actionIcon}
      />
    </Box>
  );

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'title',
      label: intl.formatMessage({ id: 'document.name', defaultMessage: 'Nombre' }),
      options: {
        customBodyRender: (value, meta) => {
          const titleId = meta.rowData[0];
          return (
            <Typography className={`${classes.text} ${classes.textBold}`} component={Link} to={`/documents/${titleId}`}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'type',
      label: intl.formatMessage({ id: 'document.entity', defaultMessage: 'Entidad' }),
      options: {
        display: 'excluded',
        customBodyRender: (value, meta) => {
          const id = meta.rowData[0];
          return (
            <Typography className={classes.text} component={Link} to={`/documents/${id}`}>
              {getEntityLabel(value)}
            </Typography>
          );
        },
        customDownloadRender: {
          renderValue: (value) => getEntityLabel(value),
        },
        filterOptions: {
          renderValue: (value) => getEntityLabel(value),
        },
        customFilterListOptions: {
          render: (value) => getEntityLabel(value),
        },
      },
    },
    {
      name: 'relatedEntities',
      label: intl.formatMessage({ id: 'document.relatedEntities', defaultMessage: 'Entidades relacionadas' }),
      options: {
        filter: false,
        customBodyRender: (entity, meta) => getProcessesFormatted(entity, meta.rowData[2]),
      },
    },
    {
      name: 'creatorUserNames',
      label: intl.formatMessage({ id: 'document.creatorUserNames', defaultMessage: 'Creador del documento' }),
    },
    {
      name: 'lastVersion',
      label: intl.formatMessage({ id: 'document.lastVersionName', defaultMessage: 'Última versión' }),
      options: {
        customBodyRender: (value) => value.versionName,
      },
    },
    {
      name: 'lastVersion',
      label: intl.formatMessage({ id: 'document.reviewerUserNames', defaultMessage: 'Revisor del documento' }),
      options: {
        customBodyRender: (value) => value.reviewerUserNames,
      },
    },
    {
      name: 'lastVersion',
      label: intl.formatMessage({ id: 'document.lastVersionDate', defaultMessage: 'Fecha de última versión' }),
      options: {
        customBodyRender: (value) => {
          if (value.date === '-') return value.date;
          return moment(value.date).format('DD/MM/YYYY');
        },
      },
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_, meta) => {
          if (!isAdmin) return null;

          const document = {
            blobUrl: meta.rowData[8],
            id: meta.rowData[0],
            lastVersion: meta.rowData[5],
            title: meta.rowData[1]
          };
          const link = `/documents/${document.id}/new`;

          return actionsButtons(link, document);
        },
      },
    }];

  return (
    <PageLayout
      breadCrumbs={<DocumentsPageBreadcrumbs />}
      title={title}
    >
      <DataTable
        columns={columns}
        customRenderForFixedFilterColumnIndex={getEntityLabel}
        fetchUrl={fetchUrl}
        itemUrl={itemUrl}
        keys={keys}
        showFixedFilterForColumnIndex={2}
        title={title}
      />
      <DocumentPreviewModal
        documentState={documentState}
        handleClose={() => setIsPreviewModalOpen(false)}
        isOpen={isPreviewModalOpen}
      />
    </PageLayout>
  );
};

export default DocumentsPage;
