import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import Button from '../../controls/Button';
import AuthLayout from '../AuthLayout/AuthLayout';
import { userActions, companyActions, alertActions } from '../../../redux/actions';
import { history } from '../../../helpers';
import { selectMenuProps } from '../../../common/constants';

function AuthSelectCompany() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const successfulAffiliated = {
    title: intl.formatMessage({ id: 'affiliates.congratulations', defaultMessage: '¡Felicitaciones!' }),
    messagePart1: intl.formatMessage({ id: 'affiliates.theAgreementSuccessfullyAppliedPart1', defaultMessage: 'Se aplicó con éxito en tu cuenta el convenio con ' }),
    messagePart2: intl.formatMessage({ id: 'affiliates.theAgreementSuccessfullyAppliedPart2', defaultMessage: 'Contás con un plazo de 60 días para obtener un descuento sobre la suscripción al plan que prefieras.' }),
    button: intl.formatMessage({ id: 'affiliates.seeDiscountedPlans', defaultMessage: 'Ver planes con descuento' }),
    linkTo: '/stripe/plans',
    buttonLeft: intl.formatMessage({ id: 'affiliates.maybeLater', defaultMessage: 'Quizás luego' }),
  };

  useEffect(() => {
    document.title = intl.formatMessage({
      id: 'auth.companyTitle',
      defaultMessage: 'Pertix',
    });
  }, [dispatch, intl]);

  const handleChangeCompany = (event) => {
    setSelectedCompanyId(event.target.value);
  };

  const handleSubmit = () => {
    dispatch(userActions.setCompanySelected(user, selectedCompanyId, successfulAffiliated));
    dispatch(companyActions.get(selectedCompanyId));
    history.push('/home');
  };

  if (!user) { return <Redirect to={{ pathname: '/' }} />; }

  if (user.companies && !user.companies.length) {
    const companyErrorText = intl.formatMessage({ id: 'auth.companyError', defaultMessage: 'Su usuario no tiene asociado ninguna compañía' });
    dispatch(userActions.logout());
    dispatch(alertActions.error(companyErrorText));
    return <Redirect to={{ pathname: '/home' }} />;
  }

  if (user.companies && user.companies.length < 2) {
    dispatch(userActions.setCompanySelected(user, user.companies[0].id, successfulAffiliated));
    dispatch(companyActions.get(user.companies[0].id));
    return <Redirect to={{ pathname: '/home' }} />;
  }
  return (
    <AuthLayout
      pageTitle={<FormattedMessage id="auth.company" defaultMessage="Select which company you want to login with" />}
    >
      <FormControl style={{ width: '100%' }}>
        <Grid container>
          <Grid item xs={12}>
            <InputLabel id="company-select-label">Empresa</InputLabel>
            <Select
              labelId="company-select-label"
              id="company-select"
              value={selectedCompanyId}
              onChange={handleChangeCompany}
              fullWidth
              MenuProps={selectMenuProps}
            >
              {
               user.companies && user.companies.map((company) => (
                 <MenuItem key={`${company.id}-${company.name}`} value={company.id}>
                   {company.name}
                 </MenuItem>
               ))
              }
            </Select>
          </Grid>
        </Grid>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={2}
          mb={3}
        >
          <Button
            style={{ fontSize: '11px', fontWeight: 600 }}
            type="submit"
            disabled={!selectedCompanyId}
            text={(
              <FormattedMessage
                id="app.continue"
                defaultMessage="Continue"
              />
            )}
            onClick={handleSubmit}
          />
        </Box>
      </FormControl>
    </AuthLayout>
  );
}

export default AuthSelectCompany;
