import axios from '../axios/axios';
import { authHeader, userCompanySelectorHelper } from '../helpers';
import handler from './handler';

function create(risk) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `/companies/${company.id}/risks`;
  return axios.post(postUrl, risk, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function get(id) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/risks/${id}`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAll() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/risks`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function update(risk) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const putUrl = `/companies/${company.id}/risks/${risk.id}`;
  return axios.put(putUrl, risk, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function riskEvaluationUpdate(evaluation) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const putUrl = `/companies/${company.id}/Risks/evaluation/${evaluation.id}`;
  return axios.put(putUrl, evaluation, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function updateNonVersionableData(risk) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const putUrl = `/companies/${company.id}/Risks/${risk.id}/non-versionable-data`;
  return axios.put(putUrl, risk, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function deleteRisk(riskId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const deleteUrl = `/companies/${company.id}/risks/${riskId}`;
  return axios.delete(deleteUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function createBulkRisk(risksExcelFile) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `companies/${company.id}/risks/bulk`;
  return axios.post(postUrl, risksExcelFile, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function confirmCreateBulkRisk(id) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `companies/${company.id}/risks/bulk/${id}`;
  return axios.post(postUrl, {}, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const riskService = {
  confirmCreateBulkRisk,
  create,
  createBulkRisk,
  deleteRisk,
  get,
  getAll,
  riskEvaluationUpdate,
  update,
  updateNonVersionableData,
};

export default riskService;
