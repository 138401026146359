import { deviationConstants } from '../constants';

function toBoardData(data) {
  const initialBoard = {
    documented: [], analyzed: [], correctiveActionDefined: [], correctiveActionImplemented: [],
  };
  const items = Object.entries(
    data.reduce((acc, { currentState, ...rest }) => {
      if (currentState === deviationConstants.status.archived.key) return acc;
      acc[currentState] = [...(acc[currentState] || []), { ...rest, currentState }];
      return acc;
    }, initialBoard),
  ).map(([currentState, content]) => ({
    id: currentState,
    title: '',
    cards: content,
  }));
  return { lanes: items };
}

const deviationHelper = {
  toBoardData,
};

export default deviationHelper;
