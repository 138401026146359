/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import {
  FormControl, IconButton, MenuItem,
  Typography, makeStyles, Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import ClearIcon from '@material-ui/icons/Clear';
import { deviationActions } from '../../../../redux/actions';
import { personService } from '../../../../services';
import { deviationTexts, deviationConstants } from '../../../../constants';
import DatePickerValidator from '../../../controls/DatePickerValidator';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    textAlign: 'justify',
  },
  textArea: {
    '& .MuiInputBase-inputMultiline': {
      backgroundColor: '#F9F9F9',
      minHeight: '75px',
      alignItems: 'flex-start',
      padding: 8,
    },
  },
  labelAreaText: {
    fontSize: '12px',
    lineHeight: '12px',
    textAlign: 'justify',
  },
  textLabel: {
    '& .MuiFormLabel-root': {
      fontSize: '12px',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px) scale(1)',
    },
    padding: '0px 40px',
  },
  requiredField: {
    fontSize: '12px',
    lineHeight: '12px',
    textAlign: 'justify',
    paddingBottom: '8px',
  },
  textLabelRequired: {
    fontSize: '12px',
  },
}));

const BoardModalCorrectiveActionImplementedDeviationForm = ({
  data, target, setRefToTheForm, eventBus,
}) => {
  const initialFieldValues = {
    ...data.cardDetails,
    currentState: target,
    effectiveImplementationDate: data.cardDetails.effectiveImplementationDate ? data.cardDetails.effectiveImplementationDate : null,
    expectedEffectivenessVerificationDate: data.cardDetails.expectedEffectivenessVerificationDate ? data.cardDetails.expectedEffectivenessVerificationDate : null,
    effectivenessVerificationDate: data.cardDetails.effectivenessVerificationDate ? data.cardDetails.effectivenessVerificationDate : null,
    effectivenessVerified: data.cardDetails.effectivenessVerificationDate ? data.cardDetails.effectivenessVerified : null,
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deviationDetails, setDeviationDetails] = useState(initialFieldValues);
  const [people, setPeople] = useState([]);
  const formEl = useRef();
  setRefToTheForm(formEl);

  useEffect(() => {
    const fetchData = async () => {
      const resPersonService = await personService.getAll();
      setPeople(resPersonService);
    };
    fetchData();
  }, []);

  useEffect(() => {
    ValidatorForm.addValidationRule('hasEffectivenessVerificationDate', (value) => !(deviationDetails.effectivenessVerificationDate && !value.toString()));
    ValidatorForm.addValidationRule('hasEffectivenessVerified', (value) => !((deviationDetails.effectivenessVerified !== null) && !value));
    ValidatorForm.addValidationRule('passIfRuleIsUndefined', () => true);
  }, [deviationDetails.effectivenessVerificationDate, deviationDetails.effectivenessVerified]);

  function handleSaveEditDeviations() {
    dispatch(
      deviationActions.updateDeviation(
        data.cardDetails.id,
        deviationDetails,
        deviationTexts.successfulUpdate,
      ),
    );
  }

  function handleSubmitForm() {
    handleSaveEditDeviations();
    const newCurrentCard = {
      ...data, cardDetails: { ...deviationDetails },
    };
    eventBus.publish({
      type: 'MOVE_CARD',
      index: 0,
      ...newCurrentCard,
    });
    eventBus.publish({
      type: 'UPDATE_CARD',
      laneId: target,
      card: deviationDetails,
    });
  }

  function handleChangeFormData(e) {
    const { name, value } = e.target;
    setDeviationDetails({ ...deviationDetails, [name]: value });
  }

  function handleClearDate(e, nameDate) {
    e.stopPropagation();
    setDeviationDetails({ ...deviationDetails, [nameDate]: null });
  }

  const effectiveImplementationDateText = (
    <Typography className={classes.textLabelRequired}>
      {deviationTexts.effectiveImplementationDate}
      {deviationConstants.statusForm[target].rules.effectiveImplementationDate && <span style={{ color: 'red' }}> *</span>}
    </Typography>
  );
  const expectedEffectivenessVerificationDateText = (
    <Typography className={classes.textLabelRequired}>
      {deviationTexts.expectedEffectivenessVerificationDate}
      {deviationConstants.statusForm[target].rules.expectedEffectivenessVerificationDate && <span style={{ color: 'red' }}> *</span>}
    </Typography>
  );
  const headOfEffectivenessVerificationText = (
    <Typography className={classes.textLabelRequired}>
      {deviationTexts.headOfEffectivenessVerification}
      {deviationConstants.statusForm[target].rules.headOfEffectivenessVerification && <span style={{ color: 'red' }}> *</span>}
    </Typography>
  );

  const effectivenessVerificationDateText = (
    <Typography className={classes.textLabelRequired}>
      {deviationTexts.effectivenessVerificationDate}
      {deviationConstants.statusForm[target].rules.effectivenessVerificationDate && <span style={{ color: 'red' }}> *</span>}
    </Typography>
  );

  const effectivenessVerifiedText = (
    <Typography className={classes.textLabelRequired}>
      {deviationTexts.effectivenessVerified}
      {deviationConstants.statusForm[target].rules.effectivenessVerified && <span style={{ color: 'red' }}> *</span>}
    </Typography>
  );

  return (
    <ValidatorForm onSubmit={() => handleSubmitForm()} autoComplete="off" className={classes.textLabel} instantValidate ref={formEl}>
      <Typography className={classes.requiredField}>
        Campos obligatorios
        <span style={{ color: 'red' }}> *</span>
      </Typography>
      <Box py={1}>
        <DatePickerValidator
          style={{ marginTop: 0 }}
          value={deviationDetails.effectiveImplementationDate}
          onChange={(value) => {
            setDeviationDetails(
              { ...deviationDetails, effectiveImplementationDate: value },
            );
          }}
          fullWidth
          margin="normal"
          id="effectiveImplementationDateId"
          label={effectiveImplementationDateText}
          name="effectiveImplementationDate"
          size="small"
          format="dd/MM/yyyy"
          minDateMessage={deviationTexts.incorrectDate}
          cancelLabel={deviationTexts.cancel}
          InputProps={{
            endAdornment: (
              <IconButton style={{ padding: 5 }} onClick={(e) => handleClearDate(e, 'effectiveImplementationDate')}>
                <ClearIcon />
              </IconButton>
            ),
          }}
          validators={[deviationConstants.statusForm[target].rules.effectiveImplementationDate || 'passIfRuleIsUndefined']}
          errorMessages={[deviationTexts.deviationDefaultRequired]}
        />
      </Box>
      <Box py={1}>
        <DatePickerValidator
          style={{ marginTop: 0 }}
          cancelLabel={deviationTexts.cancel}
          format="dd/MM/yyyy"
          fullWidth
          id="expectedEffectivenessVerificationDateId"
          label={expectedEffectivenessVerificationDateText}
          margin="normal"
          minDateMessage={deviationTexts.incorrectDate}
          name="expectedEffectivenessVerificationDate"
          size="small"
          value={deviationDetails.expectedEffectivenessVerificationDate}
          onChange={(value) => {
            setDeviationDetails(
              { ...deviationDetails, expectedEffectivenessVerificationDate: value },
            );
          }}
          InputProps={{
            endAdornment: (
              <IconButton style={{ padding: 5 }} onClick={(e) => handleClearDate(e, 'expectedEffectivenessVerificationDate')}>
                <ClearIcon />
              </IconButton>
            ),
          }}
          validators={[deviationConstants.statusForm[target].rules.expectedEffectivenessVerificationDate || 'passIfRuleIsUndefined']}
          errorMessages={[deviationTexts.deviationDefaultRequired]}
        />
      </Box>
      <Box py={1}>
        <FormControl className={classes.formControl}>
          <SelectValidator
            label={headOfEffectivenessVerificationText}
            id="headOfEffectivenessVerification-select"
            value={deviationDetails.headOfEffectivenessVerification}
            name="headOfEffectivenessVerification"
            onChange={handleChangeFormData}
            fullWidth
            validators={[deviationConstants.statusForm[target].rules.headOfEffectivenessVerification || 'passIfRuleIsUndefined']}
            errorMessages={[deviationTexts.deviationDefaultRequired]}
          >
            <MenuItem style={{ fontSize: '12px' }} value="">
              <em>{deviationTexts.selectOption}</em>
            </MenuItem>
            {people.map((item) => {
              if (!item.names) return null;
              return (
                <MenuItem style={{ fontSize: '12px' }} key={`${item.id}${item.surnames}`} value={item.id}>
                  {`${item.names} ${item.surnames}`}
                </MenuItem>
              );
            })}
          </SelectValidator>
        </FormControl>
      </Box>
      <Box py={1}>
        <Box mt={2}>
          <Typography className={classes.labelAreaText}>
            {deviationTexts.effectivenessVerificationMethodology}
            {deviationConstants.statusForm[target].rules.effectivenessVerificationMethodology && <span style={{ fontWeight: 'normal', color: 'red' }}> *</span>}
          </Typography>
          <TextValidator
            classes={{ root: classes.textArea }}
            style={{ marginTop: 8 }}
            value={deviationDetails.effectivenessVerificationMethodology}
            onChange={handleChangeFormData}
            fullWidth
            margin="normal"
            id="effectivenessVerificationMethodology"
            name="effectivenessVerificationMethodology"
            size="small"
            multiline
            InputLabelProps={{ style: { fontSize: '12px' }, shrink: true }}
            validators={['hasEffectivenessVerified', 'hasEffectivenessVerificationDate']}
            errorMessages={[deviationTexts.deviationDefaultRequired, deviationTexts.deviationDefaultRequired]}
          />
        </Box>
      </Box>
      <Box py={1}>
        <DatePickerValidator
          style={{ marginTop: 0 }}
          cancelLabel={deviationTexts.cancel}
          format="dd/MM/yyyy"
          fullWidth
          id="effectivenessVerificationDateId"
          label={effectivenessVerificationDateText}
          margin="normal"
          minDateMessage={deviationTexts.incorrectDate}
          name="effectivenessVerificationDate"
          size="small"
          value={deviationDetails.effectivenessVerificationDate}
          onChange={(value) => {
            setDeviationDetails(
              { ...deviationDetails, effectivenessVerificationDate: value },
            );
          }}
          InputProps={{
            endAdornment: (
              <IconButton style={{ padding: 5 }} onClick={(e) => handleClearDate(e, 'effectivenessVerificationDate')}>
                <ClearIcon />
              </IconButton>
            ),
          }}
          validators={['hasEffectivenessVerified']}
          errorMessages={[deviationTexts.deviationEffectivenessVerificationDate]}
        />
      </Box>
      <Box py={1}>
        <FormControl className={classes.formControl}>
          <SelectValidator
            label={effectivenessVerifiedText}
            id="effectivenessVerified-select"
            value={deviationDetails.effectivenessVerified}
            name="effectivenessVerified"
            onChange={handleChangeFormData}
            fullWidth
            validators={['hasEffectivenessVerificationDate']}
            errorMessages={[deviationTexts.deviationEffectivenessVerified]}
          >
            <MenuItem style={{ fontSize: '12px' }} value={null}>
              <em>{deviationTexts.selectOption}</em>
            </MenuItem>
            <MenuItem style={{ fontSize: '12px' }} key="select-effectivenessVerified-true" value>
              {deviationTexts.positiveResult}
            </MenuItem>
            <MenuItem style={{ fontSize: '12px' }} key="select-effectivenessVerified-false" value={false}>
              {deviationTexts.negativeResult}
            </MenuItem>
          </SelectValidator>
        </FormControl>
      </Box>

    </ValidatorForm>
  );
};

BoardModalCorrectiveActionImplementedDeviationForm.propTypes = {
  data: PropTypes.shape().isRequired,
  eventBus: PropTypes.shape().isRequired,
  setRefToTheForm: PropTypes.func.isRequired,
  target: PropTypes.string.isRequired,
};

export default BoardModalCorrectiveActionImplementedDeviationForm;
