import React, { useState } from 'react';
import {
  Box, Breadcrumbs, Grid, makeStyles, Typography,
} from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { validationConstants } from '../../../constants';
import { superAdminActions } from '../../../redux/actions';
import Button from '../../controls/Button';
import PageLayout from '../../PageLayout';

const useStyles = makeStyles(() => ({
  container: {
    padding: '0px 24px',
  },
}));

const NewCompanyPage = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);
  const creatingCompany = useSelector((state) => state.superAdmin.creating);
  const [newCompany, setNewCompany] = useState({ name: '' });
  const backText = intl.formatMessage({ id: 'app.back', defaultMessage: 'Volver' });
  const createText = intl.formatMessage({ id: 'app.create', defaultMessage: 'Crear' });
  const companyLabel = intl.formatMessage({ id: 'register.company', defaultMessage: 'Nombre de la empresa' });
  const companiesText = intl.formatMessage({ id: 'superAdmin.companies', defaultMessage: 'Empresas' });
  const newCompanyText = intl.formatMessage({ id: 'superAdmin.new.title', defaultMessage: 'Nuevo' });
  const createTitleText = intl.formatMessage({ id: 'superAdmin.newCompany', defaultMessage: 'Nueva empresa' });

  if (!user.isSuperAdmin) {
    return <Redirect to={{ pathname: '/company/configuration' }} />;
  }

  function handleChange(e) {
    e.preventDefault();
    setNewCompany({ name: e.target.value });
  }

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(superAdminActions.createCompany(newCompany));
  }

  const NewCompanyBreadcrumbs = () => (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      <Link color="inherit" to="/companies" style={{ textDecoration: 'none' }}>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{companiesText}</Typography>
      </Link>
      <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{newCompanyText}</Typography>
    </Breadcrumbs>
  );

  return (
    <PageLayout title={createTitleText} breadCrumbs={<NewCompanyBreadcrumbs />}>
      <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
        <Grid item xs={12} md={6} className={classes.container}>
          <TextValidator
            value={newCompany.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            id="newCompany"
            label={companyLabel}
            name="newCompany"
            size="small"
            validators={['required']}
            errorMessages={[validationConstants.SUPERADMIN_NEW_COMPANY]}
          />
        </Grid>
        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={3}>
          <Button
            component={Link}
            to="/companies"
            variant="outlined"
            text={backText}
            style={{ fontSize: '10px', fontWeight: 600 }}
          />
          <Button
            type="submit"
            loading={creatingCompany}
            text={createText}
          />
        </Box>
      </ValidatorForm>
    </PageLayout>
  );
};

export default NewCompanyPage;
