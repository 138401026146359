import axios from '../axios/axios';
import { authHeader, userCompanySelectorHelper } from '../helpers';
import handler from './handler';

function create(workFieldEvaluation) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `/companies/${company.id}/WorkFieldEvaluations`;
  return axios.post(postUrl, workFieldEvaluation, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function get(id) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/WorkFieldEvaluations/${id}`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAll() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/WorkFieldEvaluations`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAllUnapproved() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/WorkFieldEvaluations/Unapproved`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function update(workFieldEvaluation) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const putUrl = `/companies/${company.id}/WorkFieldEvaluations/${workFieldEvaluation.id}`;
  return axios.put(putUrl, workFieldEvaluation, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function deleteWorkFieldEvaluation(workFieldEvaluationId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const deleteUrl = `/companies/${company.id}/WorkFieldEvaluations/${workFieldEvaluationId}`;
  return axios.delete(deleteUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const workFieldEvaluationService = {
  create,
  get,
  getAll,
  getAllUnapproved,
  update,
  deleteWorkFieldEvaluation,
};

export default workFieldEvaluationService;
