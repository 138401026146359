/* eslint-disable no-console */
import alertActions from './alert.actions';
import { affiliateConstants } from '../../constants';
import { history } from '../../helpers';
import { affiliateService } from '../../services';
import modalAlertActions from './modalAlert.actions';

function create(affiliate, message) {
  function request() { return { type: affiliateConstants.CREATE_REQUEST }; }
  function success(createdAffiliate) {
    return { type: affiliateConstants.CREATE_SUCCESS, createdAffiliate };
  }
  function failure() { return { type: affiliateConstants.CREATE_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    affiliateService.create(affiliate)
      .then(
        (response) => {
          dispatch(success(response));
          dispatch(alertActions.success(message));
          history.push('/affiliates');
        },
        (error) => {
          dispatch(failure());
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getAll() {
  function request() { return { type: affiliateConstants.GET_ALL_REQUEST }; }
  function success(affiliates) { return { type: affiliateConstants.GET_ALL_SUCCESS, affiliates }; }
  function failure() { return { type: affiliateConstants.GET_ALL_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    affiliateService.getAll()
      .then(
        (response) => {
          dispatch(success(response));
        },
        (error) => {
          dispatch(failure());
          dispatch(alertActions.error(error));
        },
      );
  };
}

function get(id) {
  function request() { return { type: affiliateConstants.GET_REQUEST }; }
  function success(affiliate) { return { type: affiliateConstants.GET_SUCCESS, affiliate }; }
  function failure() { return { type: affiliateConstants.GET_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    affiliateService.get(id)
      .then(
        (response) => {
          dispatch(success(response));
        },
        (error) => {
          dispatch(failure());
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getPrices() {
  function request() { return { type: affiliateConstants.GET_PRICES_REQUEST }; }
  function success(affiliate) { return { type: affiliateConstants.GET_PRICES_SUCCESS, affiliate }; }
  function failure() { return { type: affiliateConstants.GET_PRICES_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    affiliateService.getPrices()
      .then(
        (response) => {
          dispatch(success(response));
        },
        (error) => {
          dispatch(failure());
          dispatch(alertActions.error(error));
        },
      );
  };
}

function resetUserPassword(userId, successfulText) {
  function request() { return { type: affiliateConstants.POST_RESET_PASSWORD_REQUEST }; }
  function success(user) { return { type: affiliateConstants.POST_RESET_PASSWORD_SUCCESS, user }; }
  function failure(error) {
    return { type: affiliateConstants.POST_RESET_PASSWORD_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    affiliateService.resetUserPassword(userId)
      .then(
        (user) => {
          dispatch(success(user));
          dispatch(alertActions.info(successfulText));
        },
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        },
      );
  };
}

function setAffiliate(affiliateToken, companyId, successMsj) {
  function request() { return { type: affiliateConstants.POST_REQUEST }; }
  function success() { return { type: affiliateConstants.POST_SUCCESS }; }
  function failure() { return { type: affiliateConstants.POST_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    affiliateService.setAffiliate(affiliateToken, companyId)
      .then(
        (affiliate) => {
          dispatch(success());
          localStorage.removeItem('affiliate');
          const message = {
            ...successMsj,
            message: `${successMsj.messagePart1} ${affiliate.names}. ${successMsj.messagePart2} ${affiliate.includesFreeInitialMonth ? ` ${successMsj.messagePart3}` : ''}`,
          };
          dispatch(modalAlertActions.infoSubscription(message));
        },
        (error) => {
          console.error('Affiliate:', error);
          dispatch(alertActions.error(error));
          dispatch(failure());
          localStorage.removeItem('affiliate');
        },
      );
  };
}

function getAffiliateData(affiliateToken, successMsj) {
  function request() { return { type: affiliateConstants.POST_REQUEST }; }
  function success() { return { type: affiliateConstants.POST_SUCCESS }; }
  function failure() { return { type: affiliateConstants.POST_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    affiliateService.getAffiliateData(affiliateToken)
      .then(
        (affiliate) => {
          dispatch(success());
          const message = {
            ...successMsj,
            message: `${successMsj.messagePart1} ${affiliate.names} ${successMsj.messagePart2}`,
          };
          dispatch(modalAlertActions.customInfo(message));
        },
        (error) => {
          console.error('Affiliate:', error);
          dispatch(alertActions.error(error));
          dispatch(failure());
        },
      );
  };
}

function clearAffiliate() {
  return { type: affiliateConstants.CLEAN };
}

const affiliateAction = {
  create,
  get,
  getAll,
  getAffiliateData,
  getPrices,
  resetUserPassword,
  setAffiliate,
  clearAffiliate,
};

export default affiliateAction;
