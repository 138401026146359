import { superAdminConstants } from '../../constants';

function superAdmin(state = {}, action) {
  switch (action.type) {
    case superAdminConstants.COMPANY_POST_REQUEST:
      return { creating: true };
    case superAdminConstants.COMPANY_POST_SUCCESS:
      return { data: action.company };
    case superAdminConstants.COMPANY_POST_FAILURE:
      return { error: action.error };

    case superAdminConstants.COMPANY_DELETE_REQUEST:
      return { deleting: true };
    case superAdminConstants.COMPANY_DELETE_SUCCESS:
      return { };
    case superAdminConstants.COMPANY_DELETE_FAILURE:
      return { error: action.error };

    case superAdminConstants.RESET_PASS_REQUEST:
      return { loading: true };
    case superAdminConstants.RESET_PASS_SUCCESS:
      return { data: action.user };
    case superAdminConstants.RESET_PASS_FAILURE:
      return { error: action.error };

    case superAdminConstants.CLEAN_SUPER_ADMIN:
      return { };

    default:
      return state;
  }
}

export default superAdmin;
