import React from 'react';
import reactStringReplace from 'react-string-replace';
import { regexConstants } from '../constants';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost'
    // [::1] is the IPv6 localhost address.
    || window.location.hostname === '[::1]'
    // 127.0.0.0/8 are considered localhost for IPv4.
    || window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);
function removePreviousSlash(url) {
  if (url.charAt(0) === '/') return url.substring(1);
  return url;
}
function getBaseUrl() {
  if (isLocalhost) return process.env.REACT_APP_LOCAL_URL;
  return process.env.PUBLIC_URL;
}
function parseUrl(urlToParse) {
  return `${getBaseUrl()}${removePreviousSlash(urlToParse)}`;
}

function textToLink(url) {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {url}
    </a>
  );
}

function parseLinksInText(text) {
  if (typeof text === 'string') {
    return reactStringReplace(text, regexConstants.urlRegex, textToLink);
  }
  return text;
}

const urlHelper = {
  parseUrl, textToLink, parseLinksInText,
};

export default urlHelper;
