import React from 'react';
import { FormattedMessage } from 'react-intl';

const boldFont = { b: (chunks) => <b>{chunks}</b> };
const linkAppPertix = { a: (chunks) => <a href="https://app.pertixtech.com" aria-labelledby="https://app.pertixtech.com" target="_blank" rel="noopener noreferrer">{chunks}</a> };
const itemList = { li: (chunks) => <li>{chunks}</li> };

const termsAndConditionsText = {
  accept: <FormattedMessage id="termsAndConditions.accept" defaultMessage="Términos y condiciones" />,
  readAndAgree: <FormattedMessage id="termsAndConditions.IHaveReadAndAgree" defaultMessage="He leído y acepto los" />,
  title: <FormattedMessage id="termsAndConditions.title" defaultMessage="Términos y condiciones de uso de la plataforma “Pertix Tech”" />,
  subTitles: [],
  texts: [
    <FormattedMessage
      id="termsAndConditions.firstPointTitle"
      defaultMessage="Los presentes términos y condiciones (en adelante, el “Acuerdo” o “Términos y Condiciones” o “Términos”) constituyen un contrato entre usted (en adelante, el “Usuario”) y “Pertix Tech LLC” (en adelante, “Pertix” y, junto con el Usuario, las “Partes”), respecto del uso del servicio de la aplicación móvil <a>app.pertixtech.com</a> (en adelante, la “Plataforma”). Excepto en caso de que resulten expresamente aplicables otros términos y condiciones especiales, los Términos y Condiciones regirán los derechos y obligaciones de las Partes respecto de cualquier producto y/o servicio ofrecido a través de la Plataforma (en adelante, los “Servicios”). El Usuario se compromete a leer, comprender y aceptar todas las condiciones establecidas en este Acuerdo, previo a su registro como Usuario de la Plataforma. En caso de no encontrarse de acuerdo con los Términos y Condiciones, por favor, absténgase de utilizar los Servicios. Los presentes Términos y Condiciones se considerarán aceptados desde el momento en que el Usuario cree una Cuenta de Usuario o utilice los Servicios, lo que ocurra primero."
      values={linkAppPertix}
    />,
    <br />,
    <FormattedMessage id="termsAndConditions.secondPointTitle" defaultMessage="<b>1.-SERVICIOS</b>" values={boldFont} />,
    <FormattedMessage
      id="termsAndConditions.secondPointFirstSubpoint"
      defaultMessage="La Plataforma permite al usuario administrar y gestionar los servicios brindados por Pertix a través de su aplicación <a>https://app.pertixtech.com</a>, entre los cuales se encuentran a título meramente enunciativo los siguientes:"
      values={linkAppPertix}
    />,
    <ul>
      <FormattedMessage id="termsAndConditions.secondPointSecondSubpoint" defaultMessage="<li>Confección y operación del mapa de interacción de procesos de la Organización;</li>" values={itemList} />
      <FormattedMessage id="termsAndConditions.secondPointThirdSubpoint" defaultMessage="<li>Carga y gestión de la estructura de personal asociado a los procesos, su capacitación y evaluación periódica;</li>" values={itemList} />
      <FormattedMessage id="termsAndConditions.secondPointFourthSubpoint" defaultMessage="<li>Documentación del análisis situacional (FODA) de la compañía;</li>" values={itemList} />
      <FormattedMessage id="termsAndConditions.secondPointFifthSubpoint" defaultMessage="<li>Gestión de la documentación y registros asociados a los procesos;</li>" values={itemList} />
      <FormattedMessage id="termsAndConditions.secondPointSixthSubpoint" defaultMessage="<li>Análisis de riesgos de los procesos;</li>" values={itemList} />
      <FormattedMessage id="termsAndConditions.secondPointSeventhSubpoint" defaultMessage="<li>Documentación y tratamiento de los desvíos de procesos, y</li>" values={itemList} />
      <FormattedMessage id="termsAndConditions.secondPointEighthSubpoint" defaultMessage="<li>Gestión de indicadores de gestión, entre otros.</li>" values={itemList} />
    </ul>,
    <br />,
    <FormattedMessage id="termsAndConditions.thirdPointTitle" defaultMessage="<b>2.- REQUISITOS PARA LA UTILIZACIÓN DE LA PLATAFORMA</b>" values={boldFont} />,
    <FormattedMessage id="termsAndConditions.thirdPointFirstSubpoint" defaultMessage="El Usuario deberá contar con un dispositivo con acceso a Internet. Asimismo, el Usuario podrá operar desde cualquier dispositivo de escritorio o notebook (laptop) en el cual se pueda conectar a Internet, y haberse registrado como usuario en la Plataforma por los medios previstos a tal fin." />,
    <br />,
    <FormattedMessage id="termsAndConditions.fourthPointTitle" defaultMessage="<b>3.- ACTIVACIÓN DEL SERVICIO - REGISTRO DE USUARIO</b>" values={boldFont} />,
    <FormattedMessage id="termsAndConditions.fourthPointFirstSubpoint" defaultMessage="A los fines de contar con acceso a los servicios básicos de la Plataforma, el usuario, una vez descargada la misma, deberá previamente generar un registro de usuario y aceptar los presentes términos y condiciones de uso. A los fines de dicho registro se le requerirán los siguientes datos: nombre completo, DNI, dirección de Email y teléfono. Completada dicha información se le solicitará al usuario el ingreso de un nombre de usuario y una contraseña de uso privado, lo cual constituirán los datos necesarios para el acceso o login al sistema." />,
    <FormattedMessage id="termsAndConditions.fourthPointSecondSubpoint" defaultMessage="A estos efectos Pertix declara encontrarse comprendida y cumplir con las normativas y buenas prácticas de seguridad informática, como así también con la totalidad de las normativas actuales y en las que en el futuro dispongan el Banco Central de la República Argentina (BCRA) y la Unidad de Información Financiera (UIF), en lo particular con las leyes 21.526, 25.246, 24.240 y resolución N° 21 MERCOSUR." />,
    <FormattedMessage id="termsAndConditions.fourthPointThirdSubpoint" defaultMessage="El usuario declara y asume: " />,
    <ol type="i">
      <FormattedMessage id="termsAndConditions.fourthPointFourthSubpoint" defaultMessage="<li>En carácter de declaración jurada, que los datos y documentación ingresados en el proceso de registro correspondiente son verdaderos, exactos y completos.;</li>" values={itemList} />
      <FormattedMessage id="termsAndConditions.fourthPointFifthSubpoint" defaultMessage="<li>Que la Clave Privada para acceder al sistema es secreta e intransferible y por lo tanto asume las consecuencias y/o riesgos de su divulgación a terceros, liberando a Pertix de toda responsabilidad que de ello se derive. Queda establecido que el uso de la Clave Privada y su usuario de acceso será siempre imputable al Usuario, sin perjuicio de que este ofrezca prueba de que estos no fueron utilizados por él. Pertix no responderá por uso indebido alguno de la Clave Privada, siendo el Usuario el único responsable de su utilización. En el caso de que un Usuario considere que su Clave Privada puede haber sido conocida por un tercero, deberá cambiarla inmediatamente, ingresando al Sitio y reemplazándola por otra siguiendo las instrucciones allí publicadas.;</li>" values={itemList} />
    </ol>,
    <br />,
    <FormattedMessage id="termsAndConditions.fifthPointTitle" defaultMessage="<b>4.- OBLIGACIONES DE LOS USUARIOS</b>" values={boldFont} />,
    <FormattedMessage id="termsAndConditions.fifthPointFirstSubpoint" defaultMessage="El Usuario se obliga a usar la aplicación y los contenidos encontrados en ella de una manera diligente, correcta, lícita y en especial, se compromete a NO realizar las conductas descritas a continuación:" />,
    <ol type="a">
      <FormattedMessage id="termsAndConditions.fifthPointSecondSubpoint" defaultMessage="<li>NO utilizar los contenidos de forma, sin fines o efectos contrarios a la ley, a la moral y a las buenas costumbres generalmente aceptadas o al orden público;</li>" values={itemList} />
      <FormattedMessage id="termsAndConditions.fifthPointThirdSubpoint" defaultMessage="<li>Reproducir, copiar, representar, utilizar, distribuir, transformar o modificar los contenidos de la aplicación, por cualquier procedimiento o sobre cualquier soporte, total o parcial, o permitir el acceso del público a través de cualquier modalidad de comunicación pública;</li>" values={itemList} />
      <FormattedMessage id="termsAndConditions.fifthPointFourthSubpoint" defaultMessage="<li>Utilizar los contenidos de cualquier manera que importe un riesgo de daño o inutilización de la aplicación o de los contenidos o de terceros;</li>" values={itemList} />
      <FormattedMessage id="termsAndConditions.fifthPointFifthSubpoint" defaultMessage="<li>Suprimir, eludir o manipular el derecho de autor y demás datos identificativos de los derechos de autor incorporados a los contenidos, así como los dispositivos técnicos de protección, o cualesquiera mecanismos de información que pudieren tener los contenidos;</li>" values={itemList} />
      <FormattedMessage id="termsAndConditions.fifthPointSixthSubpoint" defaultMessage="<li>Emplear los contenidos y, en particular, la información de cualquier clase obtenida a través de la aplicación para distribuir, transmitir, remitir, modificar, rehusar o reportar la publicidad o los contenidos de esta con fines de venta directa o con cualquier otra clase de finalidad comercial, mensajes no solicitados dirigidos a una pluralidad de personas con independencia de su finalidad, así como comercializar o divulgar de cualquier modo dicha información;</li>" values={itemList} />
      <FormattedMessage id="termsAndConditions.fifthPointSeventhSubpoint" defaultMessage="<li>Permitir que terceros ajenos al usuario usen la plataforma con su clave;</li>" values={itemList} />
      <FormattedMessage id="termsAndConditions.fifthPointEighthSubpoint" defaultMessage="<li>Utilizar la aplicación y los contenidos con fines lícitos y/o ilícitos, contrarios a lo establecido en estos Términos y Condiciones, o al uso mismo de la aplicación, que sean lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar la aplicación y los contenidos o impedir la normal utilización o disfrute de esta y de los contenidos por parte de los usuarios.</li>" values={itemList} />
    </ol>,
    <FormattedMessage id="termsAndConditions.fifthPointNinthSubpoint" defaultMessage="Pertix se reserva el derecho de suspender temporal o definitivamente al usuario que incumpla con las obligaciones de uso y con los presentes términos y condiciones." />,

    <br />,
    <FormattedMessage id="termsAndConditions.sixthPointTitle" defaultMessage="<b>5.- GESTIÓN DE PAGO</b>" values={boldFont} />,
    <FormattedMessage id="termsAndConditions.sixthPointFirstSubpoint" defaultMessage="Para la realización de los pagos por la suscripción a la Plataforma se utilizará el Gateway de pagos vigente, y deberá operarse en función de los precios de las suscripciones formalmente comunicados por Pertix Tech y operados por el proveedor del servicio de pagos." />,
    <FormattedMessage id="termsAndConditions.sixthPointSecondSubpoint" defaultMessage="Pertix Tech se compromete a no realizar cambios sobre el valor de la suscripción elegida por el usuario y correspondiente a su estructura, tamaño o tipo de servicio requerido, sin informarle previamente por los medios disponibles." />,
    <br />,
    <FormattedMessage id="termsAndConditions.seventhPointTitle" defaultMessage="<b>6.-PROPIEDAD INDUSTRIAL E INTELECTUAL</b>" values={boldFont} />,
    <FormattedMessage id="termsAndConditions.seventhPointFirstSubpoint" defaultMessage="Los contenidos de la Plataforma, tales como texto, información, gráficos, imágenes, logos, marcas, programas de computación, bases de datos, diseños, APIs, arquitectura funcional y cualquier otro material están protegidos por las leyes vigentes en Estados Unidos de América y la República Argentina, incluyendo, pero sin limitación, las leyes sobre derechos de autor, patentes, marcas, modelos de utilidad, diseños industriales y nombres de dominio. Todo el Contenido es propiedad de Pertix Tech LLC y/o de cualquier otra sociedad vinculada y/o de sus proveedores de contenido. La compilación, interconexión, operatividad y disposición de los contenidos de la Plataforma es de propiedad exclusiva de Pertix Tech LLC y/o de sus empresas vinculadas. El uso, adaptación, reproducción y/o comercialización no autorizada del Contenido puede encontrarse penado por la legislación vigente en la República Argentina." />,
    <FormattedMessage id="termsAndConditions.seventhPointSecondSubpoint" defaultMessage="El Usuario no copiará ni adaptará el código de programación desarrollado por, o por cuenta de Pertix Tech LLC para generar y operar la Plataforma o los Servicios, el cual se encuentra protegido por la legislación aplicable y vigente en los Estados Unidos de América y la República Argentina." />,
    <FormattedMessage id="termsAndConditions.seventhPointThirdSubpoint" defaultMessage="Queda prohibida la utilización de todo o parte de este sitio y Plataforma, su contenido o los elementos que lo componen, para crear, completar en todo o en parte otro sitio de Internet, o para vincular el mismo a cualquier otro sitio, sin previa autorización expresa de Pertix Tech LLC." />,
    <br />,
    <FormattedMessage id="termsAndConditions.eighthPointTitle" defaultMessage="<b>7.- INTEGRACIÓN CON OTRAS APLICACIONES</b>" values={boldFont} />,
    <FormattedMessage id="termsAndConditions.eighthPointFirstSubpoint" defaultMessage="Los links de enlaces externos, Facebook®, Instagram®, Twitter® o la propia ComunidadPertix.com entre otros, que los usuarios suban en esta aplicación pueden mostrar contenido que no están bajo el control de Pertix." />,
    <FormattedMessage id="termsAndConditions.eighthPointSecondSubpoint" defaultMessage="Aunque esta Plataforma pueda suministrar links solamente a sitios y aplicaciones de terceros que cumplan con las leyes y regulaciones aplicables y las normas de Pertix, el Usuario reconoce expresamente que Pertix no tiene control sobre la naturaleza y el contenido de esos sitios y no está recomendando estos sitios, la información que contienen ni los productos o servicios de terceros." />,
    <FormattedMessage id="termsAndConditions.eighthPointThirdSubpoint" defaultMessage="Pertix no acepta responsabilidad por el contenido del sitio de un tercero con el cual existe un link de hipertexto o hipervínculo y no ofrece garantía (explícita o implícita) en cuanto al contenido de la información en esos sitios, ya que no recomienda estos sitios." />,
    <FormattedMessage id="termsAndConditions.eighthPointFourthSubpoint" defaultMessage="El Usuario debe verificar las secciones de términos y condiciones, política legal y de privacidad de los sitios con los cuales se enlaza." />,
    <FormattedMessage id="termsAndConditions.eighthPointFifthSubpoint" defaultMessage="Pertix no asume ninguna responsabilidad por pérdida directa, indirecta o consecuencial por el uso de un sitio de un tercero." />,
    <br />,
    <FormattedMessage id="termsAndConditions.ninthPointTitle" defaultMessage="<b>8.- MODIFICACIONES</b>" values={boldFont} />,
    <FormattedMessage id="termsAndConditions.ninthPointFirstSubpoint" defaultMessage="Pertix podrá, sin necesidad de aviso previo o comunicación alguna, modificar el contenido y alcance de los presentes Términos y Condiciones en el momento que lo considere necesario." />,
    <br />,
    <FormattedMessage id="termsAndConditions.tenthPointTitle" defaultMessage="<b>9.- DATOS PERSONALES</b>" values={boldFont} />,
    <FormattedMessage id="termsAndConditions.tenthPointFirstSubpoint" defaultMessage="MEDIANTE LA ACEPTACIÓN DE ESTOS TÉRMINOS Y CONDICIONES DEL SISTEMA, EL USUARIO CONFIRMA Y ACEPTA QUE PRESTA SU CONSENTIMIENTO PREVIO, EXPRESO E INFORMADO PARA QUE EL EMISOR DEL SERVICIO PERTIX PUEDA CEDER/TRANSFERIR NACIONALMENTE LOS DATOS PERSONALES QUE HUBIERAN SIDO PROVISTOS AL MOMENTO DE REALIZAR EL ALTA DE CONFORMIDAD CON LAS POLITICAS DE PRIVACIDAD Y LA LEGISLACION VIGENTE. A FIN VERIFICAR LAS OPERACIONES TRANSACCIONES REALIZADAS Y A MODO DE RELEVAMIENTO ESTADÍSTICO CONFORME LO DISPUESTO POR ARTÍCULO 11 DE LA LEY 25.326. PERTIX ALMACENARÁ TODOS LOS DATOS PERSONALES, EN UNA BASE DE DATOS DEBIDAMENTE REGISTRADA A SU NOMBRE ANTE LA DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES. LAS EMPRESAS MENCIONADAS ANTERIORMENTE ALMACENARÁN TODOS LOS DATOS PERSONALES, EN UNA BASE DE DATOS DEBIDAMENTE REGISTRADA A SUS NOMBRES ANTE LA DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES CONFORME LO DISPUESTO EN EL ARTÍCULO 11 DE LA LEY 25.326. EL USUARIO PODRÁ EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN, ACTUALIZACIÓN, SUPRESIÓN Y/O BLOQUEO SOBRE SUS DATOS PERSONALES CONFORME LO DISPUESTO POR LA LEY 25.326." />,
    <FormattedMessage id="termsAndConditions.tenthPointSecondSubpoint" defaultMessage="EL USUARIO MANIFIESTA ADICIONALMENTE QUE LOS DATOS PERSONALES PROPORCIONADOS SON ADECUADOS Y COMPLETOS Y QUE ENTIENDE QUE PROPORCIONAR DATOS INADECUADOS O INCOMPLETOS, O NO PROPORCIONARLOS, PODRÍA IMPOSIBILITAR SU USO DEL SISTEMA PERTX AÚN SIN NOTIFICACIÓN PREVIA) O QUE EL EMISOR Y/O LA ADMINISTRADORA SE CONTACTE CONSIGO EN EL FUTURO." />,
    <FormattedMessage id="termsAndConditions.tenthPointThirdSubpoint" defaultMessage="CONFORME LO DISPUESTO POR LAS NORMAS APLICABLES, SE INFORMA AL SOLICITANTE QUE EL TITULAR DE LOS DATOS PERSONALES TIENE LA FACULTAD DE EJERCER EL DERECHO DE ACCESO A LOS MISMOS EN FORMA GRATUITA A INTERVALOS NO INFERIORES A SEIS MESES, SALVO QUE SE ACREDITE UN INTERÉS LEGÍTIMO AL EFECTO CONFORME LO ESTABLECIDO EN EL ARTÍCULO 14, INCISO 3 DE LA LEY Nº 25.326. LA DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES, ÓRGANO DE CONTROL DE LA LEY Nº 25.326, TIENE LA ATRIBUCIÓN DE ATENDER LAS DENUNCIAS Y RECLAMOS QUE SE INTERPONGAN CON RELACIÓN AL INCUMPLIMIENTO DE LAS NORMAS SOBRE PROTECCIÓN DE DATOS PERSONALES." />,
    <br />,
    <FormattedMessage id="termsAndConditions.eleventhPointTitle" defaultMessage="<b>10.- TÉRMINOS DE LA ACEPTACIÓN</b>" values={boldFont} />,
    <FormattedMessage id="termsAndConditions.eleventhPointFirstSubpoint" defaultMessage="La aceptación de los presentes términos y condiciones importa conformidad por parte del usuario a la totalidad de los mismos, junto con la política de protección de datos y eventualmente aquellos que se especifiquen, complementen o modifiquen a futuro." />,
    <br />,
    <FormattedMessage id="termsAndConditions.twelfthPointTitle" defaultMessage="<b>12.- LEY APLICABLE. COMPETENCIA</b>" values={boldFont} />,
    <FormattedMessage id="termsAndConditions.twelfthPointFirstSubpoint" defaultMessage="Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la Argentina o USA, a decisión de Pertix. Cualquier controversia derivada del presente acuerdo, su existencia, validez, interpretación, alcance o cumplimiento, será sometida a los tribunales del estado de Delaware (USA) o los tribunales nacionales en lo comercial de la Ciudad de Buenos Aires (AR)." />,
    <br />,
  ],
};

export default termsAndConditionsText;
