import React, { useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CalendarEventDetailTitle from './CalendarEventDetailTitle';
import CalendarEventDetailObjectiveContent from './CalendarEventDetailModalContents/CalendarEventDetailObjectiveContent';
import CalendarEventDetailDeviationContent from './CalendarEventDetailModalContents/CalendarEventDetailDeviationContent';
import CalendarEventDetailEvaluationContent from './CalendarEventDetailModalContents/CalendarEventDetailEvaluationContent';
import CalendarEventDetailRiskContent from './CalendarEventDetailModalContents/CalendarEventDetailRiskContent';
import CalendarEventDetailTrainingContent from './CalendarEventDetailModalContents/CalendarEventDetailTrainingContent';
import CalendarEventDetailProviderContent from './CalendarEventDetailModalContents/CalendarEventDetailProviderContent';
import CalendarEventDetailButton from './CalendarEventDetailButton';
import { calendarEventActions } from '../../../redux/actions';

const EVENT_TYPES = {
  risk: 'risk',
  deviation: 'deviation',
  objective: 'objective',
  training: 'training',
  evaluation: 'evaluation',
  workfield_evaluation: 'workfield_evaluation',
};

const paperStyle = {
  borderRadius: '8px',
  width: '100%',
  maxWidth: '452px',
  display: 'flex',
  maxHeight: '546px',
  height: 'auto',
  boxShadow: '6px 6px 8px rgba(61, 61, 61, 0.1)',
};

const CalendarEventDetailModal = ({
  toggleDialog,
  openDialog,
  eventType,
  eventId,
}) => {
  const dispatch = useDispatch();
  const event = useSelector((state) => state.calendarEvent.data);

  useEffect(() => {
    if (eventId) dispatch(calendarEventActions.getEvent(eventType, eventId));
  }, [dispatch, eventId, eventType]);

  return (
    <Dialog
      open={openDialog}
      onClose={toggleDialog}
      aria-labelledby="alert-dialog-event-title"
      aria-describedby="alert-dialog-event-description"
      PaperProps={{ style: paperStyle }}
    >
      {event && (
        <>
          <CalendarEventDetailTitle
            eventType={event.type}
            eventTitle={event.title}
            toggleDialog={toggleDialog}
            eventDate={event.date}
            eventSubtype={event.subtype}
          />

            {event.type === EVENT_TYPES.risk
            && ((<CalendarEventDetailRiskContent event={event} />))}

            {event.type === EVENT_TYPES.deviation
            && (<CalendarEventDetailDeviationContent event={event} />)}

            {event.type === EVENT_TYPES.objective
            && (<CalendarEventDetailObjectiveContent event={event} />)}

            {event.type === EVENT_TYPES.training
            && (<CalendarEventDetailTrainingContent event={event} />)}

            {event.type === EVENT_TYPES.evaluation
            && (<CalendarEventDetailEvaluationContent event={event} />)}

            {event.type === EVENT_TYPES.workfield_evaluation
            && (<CalendarEventDetailProviderContent event={event} />)}

          <CalendarEventDetailButton
            eventType={event.type}
            eventId={event.eventInfo.id}
          />
        </>
      )}
    </Dialog>
  );
};

CalendarEventDetailModal.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  openDialog: PropTypes.bool.isRequired,
  eventId: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
};

export default CalendarEventDetailModal;
