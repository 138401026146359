import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import * as joint from 'jointjs';
import {
  AppBar, Box, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Divider, Fab, LinearProgress, makeStyles, Menu, MenuItem, Toolbar,
  Tooltip, Typography,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CachedIcon from '@material-ui/icons/Cached';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Clear';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import svgPanZoom from 'svg-pan-zoom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { history } from '../../../helpers';
import Button from '../../controls/Button';
import {
  ProcessLink, ProcessBox, ProcessArea, createPaper,
} from '../../jointjs/CustomElements';
import { validationConstants, processConstants } from '../../../constants';
import { processActions, companyActions, exampleCompanyActions } from '../../../redux/actions';
import { processService } from '../../../services';
import SetExampleProcessMapModal from '../../Home/ExampleProcessMap/SetExampleProcessMapModal';
import TutorialInfo from '../../controls/TutorialInfo';
import tutorialActions from '../../../redux/actions/tutorial.actions';

const useStyles = makeStyles((theme) => ({
  main: {
  },
  canvas: {
    width: '100%',
    marginLeft: 40,
    marginBottom: 20,
    backgroundColor: '#FFFFFF',
    cursor: 'pointer',
  },
  toolbar: {
    backgroundColor: '#F8F8F8',
    padding: '0 0 0 16px',
  },
  toolbarTitle: {
    color: '#F79A56',
    fontWeight: 700,
  },
  saveMessage: {
    color: '#757575',
  },
  saveIcon: {
    color: '#757575',
  },
  zoomButton: {
    margin: theme.spacing(1),
    boxShadow: 'none',
  },
  tutorialInfoStepOneWrapper: {
    animation: '$disappear 4s ease-out',
  },
  '@keyframes disappear': {
    '0%': {
        opacity: 1,
    },
    '75%': {
        opacity: 1,
    },
    '100%': {
        opacity: 0,
    },
},
}));

const initialFieldValues = {
  newProcessName: '',
  newProcessAreaName: '',
  newAreaName: '',
  processDialog: false,
  areaDialog: false,
  newNameDialog: false,
  deleteProcessDialog: false,
  editMode: false,
  deleteAreaDialog: false,
  newNameAreaDialog: false,
  changeAreaName: false,
};

const initialContextMenu = {
  mouseX: null,
  mouseY: null,
};

function ProcessMap({ setIsEditModeForBreadcrumbs }) {
  const classes = useStyles();
  const [contextMenu, setContextMenu] = useState(initialContextMenu);
  const [selectedCell, setSelectedCell] = useState();
  const [values, setValues] = useState(initialFieldValues);
  const [positionXNewProcess, setPositionXNewProcess] = useState(300);
  const [positionYNewProcess, setPositionYNewProcess] = useState(100);
  const [openSetExampleProcessMapModal, setOpenSetExampleProcessMapModal] = useState(false);
  const {
    newProcessName, newProcessAreaName, newAreaName, processDialog, areaDialog,
    newNameDialog, deleteProcessDialog, editMode, deleteAreaDialog, newNameAreaDialog,
    changeAreaName,
  } = values;
  const canvas = useRef(null);
  const paper = useRef(null);
  const svgPan = useRef(null);
  const graph = useRef(null);
  const intl = useIntl();
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const createdProcess = useSelector((state) => state.process.createdProcess);
  const userCompanyId = useSelector(
    (state) => (state.authentication.user.company ? state.authentication.user.company.id : null),
  );
  const company = useSelector((state) => state.company);
  const processMapFromScratch = useSelector((state) => state.modals.processMapFromScratch);
  const showWelcomeModal = useSelector((state) => state.modals.showWelcomeModal);
  const { mustChangePassword } = useSelector((state) => state.authentication.user);
  const step = useSelector((state) => state.tutorial.step);
  const [updatingGraph, setUpdatingGraph] = useState(false);
  const [graphLoadComplete, setGraphLoadComplete] = useState(false);
  const [hasProcesses, setHasProcesses] = useState(false);
  const dispatch = useDispatch();
  const processMapText = intl.formatMessage({ id: 'processMap.title', defaultMessage: 'Mapa de procesos' });
  const addProcessButtonText = intl.formatMessage({ id: 'processMap.addProcess', defaultMessage: 'Agregar Proceso' });
  const addAreaButtonText = intl.formatMessage({ id: 'processMap.addArea', defaultMessage: 'Agregar Area' });
  const doneButtonText = intl.formatMessage({ id: 'app.done', defaultMessage: 'Finalizar' });
  const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const addNewProcessText = intl.formatMessage({ id: 'processMap.addNewProcess', defaultMessage: 'Agregar nuevo proceso' });
  const enterNewProcessNameText = intl.formatMessage({ id: 'processMap.enterNewProcessName', defaultMessage: 'Ingrese el nombre y area del nuevo proceso' });
  const newProcessNameText = intl.formatMessage({ id: 'processMap.newProcessName', defaultMessage: 'Nombre del nuevo proceso' });
  const addNewAreaText = intl.formatMessage({ id: 'processMap.addNewArea', defaultMessage: 'Agregar nueva area' });
  const newProcessAreaNameText = intl.formatMessage({ id: 'processMap.newProcessAreaName', defaultMessage: 'Area del nuevo proceso' });
  const enterNewAreaNameText = intl.formatMessage({ id: 'processMap.enterNewAreaName', defaultMessage: 'Ingrese el nombre de nueva area' });
  const newAreaNameText = intl.formatMessage({ id: 'processMap.newAreaName', defaultMessage: 'Nombre de nueva area' });
  const toProcessText = intl.formatMessage({ id: 'processMap.toProcess', defaultMessage: 'Ir al proceso' });
  const renameText = intl.formatMessage({ id: 'processMap.renameProcess', defaultMessage: 'Cambiar nombre' });
  const deleteText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'Eliminar' });
  const deleteProcessConfirmationText = intl.formatMessage({ id: 'processes.deleteProcessText', defaultMessage: 'Esta seguro que quiere eliminar el proceso?' });
  const deleteAreaConfirmationText = intl.formatMessage({ id: 'processes.deleteAreaText', defaultMessage: 'Esta seguro que quiere eliminar el Area?' });
  const newNameTitle = intl.formatMessage({ id: 'processMap.newNameTitle', defaultMessage: 'Cambiar nombre de proceso' });
  const newNameAreaTitle = intl.formatMessage({ id: 'processMap.newNameAreaTitle', defaultMessage: 'Cambiar nombre de área' });
  const enterNewNameText = intl.formatMessage({ id: 'processMap.enterNewName', defaultMessage: 'Ingrese el nuevo nombre del proceso' });
  const enterNewAreaName = intl.formatMessage({ id: 'processMap.enterNewAreaName', defaultMessage: 'Ingrese el nombre del nuevo area' });
  const newNameText = intl.formatMessage({ id: 'processMap.newNameText', defaultMessage: 'Nuevo nombre' });
  const deleteProcessTitle = intl.formatMessage({ id: 'processes.delete.title', defaultMessage: 'Eliminar proceso' });
  const deleteAreaTitle = intl.formatMessage({ id: 'processes.delete.title', defaultMessage: 'Eliminar Area' });
  const deleteProcessButtonText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'Eliminar' });
  const editMapText = intl.formatMessage({ id: 'processMap.editMap', defaultMessage: 'Editar mapa' });
  const saveResultFailedText = intl.formatMessage({ id: 'processMap.saveResultFailed', defaultMessage: 'No se pudieron guardar los cambios' });
  const saveResultOkText = intl.formatMessage({ id: 'processMap.saveResultOk', defaultMessage: 'Todos los cambios están guardados' });
  const syncResultOkText = intl.formatMessage({ id: 'processMap.syncResultOk', defaultMessage: 'Todos los cambios están sincronizados' });
  const syncResultFailedText = intl.formatMessage({ id: 'processMap.syncResultFailed', defaultMessage: 'No se pudieron sicronizar los cambios' });

  const debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const handleClick = (event) => {
    event.preventDefault();
    if (svgPan.current) svgPan.current.disablePan();
  };

  const handleCloseContextMenu = () => {
    setContextMenu(initialContextMenu);
    if (svgPan.current) svgPan.current.enablePan();
  };

  function handleDialogOpen(dialogName) {
    if (dialogName === 'processDialog') {
      setOpenSetExampleProcessMapModal(false);
      dispatch(exampleCompanyActions.closeWelcomeModal());
    }
    setValues({ ...values, [dialogName]: true });
  }

  function handleDialogClose(dialogName) {
    if (dialogName === 'processDialog') {
      setOpenSetExampleProcessMapModal(false);
      dispatch(exampleCompanyActions.closeWelcomeModal());
    }
    setValues({ ...values, [dialogName]: false });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  }
  function endEditing() {
    paper.current.setInteractivity(false);
    paper.current.off('cell:pointerup');
    paper.current.off('cell:pointerdown');
    paper.current.off('cell:mouseenter');
    paper.current.off('cell:mouseleave');
    paper.current.off('link:mouseenter');
    paper.current.off('link:mouseleave');
    graph.current.off('all');
    setValues({ ...values, editMode: false });
  }

  function saveGraph() {
    const graphJson = graph.current.toJSON();
    const companyToUpdate = company.data;
    companyToUpdate.processMap = JSON.stringify(graphJson);
    dispatch(companyActions.updateProcessMap(companyToUpdate));
    const hasProcessesOrAddRecently = graphJson.cells.length > 0 || newProcessName;
    setHasProcesses(hasProcessesOrAddRecently);
  }

  const saveGraphDebounced = debounce(saveGraph, 2000);

  const addProcess = useCallback((process) => {
    const processBox = new ProcessBox();
    processBox.position(positionXNewProcess, positionYNewProcess);
    processBox.attr('label/text', process.name);
    processBox.attr('process/id', process.id);
    processBox.attr('process/name', process.name);
    processBox.attr('process/area', process.area);
    graph.current.addCell(processBox);
    setPositionXNewProcess(positionXNewProcess + 10);
    setPositionYNewProcess(positionYNewProcess + 10);
    return processBox;
  }, [positionXNewProcess, positionYNewProcess]);

  function newProcess() {
    const name = newProcessName || 'New process';
    const process = { name, area: newProcessAreaName };
    dispatch(processActions.create(process));
    setValues({
      ...values, processDialog: false, newProcessName: '', newProcessAreaName: '',
    });
    saveGraph();
  }

  function renameByModel(model, newName, label) {
    model.attr(label, newName);
    model.attr('process/name', newName);

    return model;
  }

  function handleRenameArea() {
    renameByModel(selectedCell.model, changeAreaName, '.label/text');
    setValues({ ...values, newNameAreaDialog: false, changeAreaName: '' });
    saveGraph();
  }

  function handleRenameProcess() {
    const processBox = renameByModel(selectedCell.model, newProcessName, 'label/text');

    const id = processBox.attr('process/id');
    const name = processBox.attr('process/name');
    const area = processBox.attr('process/area');
    const renamedProcess = { id, name, area };
    dispatch(processActions.update(renamedProcess));
    setValues({ ...values, newNameDialog: false, newProcessName: '' });
    saveGraph();
  }

  function deleteByModel(model) {
    const cellId = model.id;
    graph.current.getCell(cellId).remove();
  }

  function handleDeleteProcess() {
    const processId = selectedCell.model.attr('process/id');
    deleteByModel(selectedCell.model);
    dispatch(processActions.deleteProcess(processId));
    handleDialogClose('deleteProcessDialog');
    saveGraph();
  }

  function handleDeleteArea() {
    deleteByModel(selectedCell.model);
    handleDialogClose('deleteAreaDialog');
    saveGraph();
  }

  function handleAreaSizeBasedOnText(title, area) {
    const breakTextAreaName = joint.util.breakText(title, { width: 230 });
    area.attr('.label/text', breakTextAreaName);

    const areaTitleRows = (breakTextAreaName.length * 12) / 300;
    area.resize(300, 300 + areaTitleRows * 22.5);
  }

  function addArea(areaName) {
    const processArea = new ProcessArea();
    handleAreaSizeBasedOnText(areaName, processArea);
    graph.current.addCells([processArea]);

    const processAreaView = processArea.findView(paper.current);
    processAreaView.addTools(new joint.dia.ToolsView({
      tools: [
        new joint.elementTools.Remove({
          focusOpacity: 1,
          rotate: true,
          action(evt, shape, toolView) {
            shape.model.remove({ ui: true, tool: toolView.cid });
            saveGraph();
          },
        }),
      ],
    }));
    processAreaView.hideTools();
    const dialogName = 'areaDialog';
    setValues({ ...values, [dialogName]: false });

    return processArea;
  }

  function newArea() {
    const name = newAreaName || 'New area';
    const area = addArea(name);
    setValues({ ...values, areaDialog: false, newAreaName: '' });

    return area;
  }

  function handleZoomIn() {
    if (svgPan.current) svgPan.current.zoomIn();
  }

  function handleZoomOut() {
    if (svgPan.current) svgPan.current.zoomOut();
  }

  function handleLinkMouseHover() {
    paper.current.on('link:mouseenter', (linkView) => {
      if (!linkView._toolsView) {
        const toolsView = new joint.dia.ToolsView({
          tools: [
            new joint.linkTools.Remove({ focusOpacity: 0.5, rotate: true }),
            new joint.linkTools.Vertices(),
            new joint.linkTools.Segments(),
            new joint.linkTools.Boundary(),
            new joint.linkTools.TargetArrowhead(),
            new joint.linkTools.Remove(),
            new joint.linkTools.Remove({
              action() {
                linkView.model.remove({ ui: true, tool: linkView.cid });
                saveGraph();
              },
            }),
          ],
        });
        linkView.addTools(toolsView);
      }
      linkView.showTools();
    });

    paper.current.on('link:mouseleave', (linkView) => {
      linkView.hideTools();
    });
  }

  function handleCellMouseHover() {
    paper.current.on('cell:mouseenter', (cellView) => {
      const cellType = cellView.model.attributes.type;
      if (cellType === 'custom.ProcessBox') { cellView.model.attr('tool/strokeWidth', 2); }

      if (cellType !== 'custom.ProcessLink' && cellType !== 'custom.ProcessBox' && !cellView._toolsView) {
        cellView.addTools(new joint.dia.ToolsView({
          tools: [
            new joint.elementTools.Remove({
              focusOpacity: 1,
              rotate: true,
              action(evt, shape, toolView) {
                shape.model.remove({ ui: true, tool: toolView.cid });
              },
            }),
          ],
        }));
      }

      cellView.showTools();
    });

    paper.current.on('cell:mouseleave', (cellView) => {
      if (cellView.model.attributes.type === 'custom.ProcessBox') { cellView.model.attr('tool/strokeWidth', 0); }

      cellView.hideTools();
    });
  }

  function handleCellClick() {
    paper.current.on('cell:pointerdown',
      (cellView) => {
        if (cellView.model.attributes.type === 'custom.ProcessBox') { cellView.model.attr('tool/strokeWidth', 0); }
        cellView.hideTools();
        cellView.highlight(cellView.model.id);
        if (svgPan.current) svgPan.current.disablePan();
      });

    paper.current.on('cell:pointerup',
      (cellView) => {
        if (cellView.model.attributes.type === 'custom.ProcessBox') { cellView.model.attr('tool/strokeWidth', 2); }
        cellView.showTools();
        cellView.unhighlight(cellView.model.id);
        if (svgPan.current) svgPan.current.enablePan();
      });
  }

  function handleCanvas() {
    svgPan.current = svgPanZoom('#canvas svg', {
      center: true,
      mouseWheelZoomEnabled: false,
      zoomEnabled: true,
      panEnabled: true,
      fit: false,
      minZoom: 0.5,
      maxZoom: 2,
      zoomScaleSensitivity: 0.1,
    });
    const { x: initialHorizontalPosition } = svgPan.current.getPan();
    const { cells } = graph.current.toJSON();
    const verticalPositions = cells.map((cell) => cell.position && cell.position.y).filter(Number);
    const firstElement = Math.min(...verticalPositions);
    const marginTop = 25;
    const initialVerticalPosition = (-1 * firstElement) + marginTop;
    svgPan.current.pan({ x: initialHorizontalPosition, y: initialVerticalPosition });
  }

  function handleContextMenu() {
    paper.current.on('cell:contextmenu', (cellView, event) => {
      if (cellView.model.attributes.type === 'custom.ProcessBox' || cellView.model.attributes.type === 'custom.ProcessArea') {
        setContextMenu({
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        });
      }

      setSelectedCell(cellView);
    });
  }

  function redirectToProcess() {
    const processId = selectedCell.model.attr('process/id');
    history.push(`/processes/${processId}`);

    handleCloseContextMenu();
  }

  function openDeleteProcessDialog() {
    handleDialogOpen('deleteProcessDialog');
    handleCloseContextMenu();
  }

  function openNewProcessNameDialog() {
    const newProcessNameIndex = 'newProcessName';
    const selectedProcessName = selectedCell.model.attr('label/text');
    setValues({ ...values, [newProcessNameIndex]: selectedProcessName, newNameDialog: true });
    handleCloseContextMenu();
  }

  function openNewProcessAreaNameDialog() {
    const newProcessAreaNameIndex = 'changeAreaName';
    const selectedProcessAreaName = selectedCell.model.attr('.label/text');
    setValues({
      ...values,
      [newProcessAreaNameIndex]: selectedProcessAreaName,
      newNameAreaDialog: true,
    });
    handleCloseContextMenu();
  }

  function handleGraphChanges() {
    const excludedEvents = [
      'change:attrs', 'change', 'add', 'sort', 'update', 'batch:start', 'batch:stop', 'remove',
    ];
    graph.current.on('all', (eventName) => {
      if (!excludedEvents.includes(eventName)) {
        setUpdatingGraph(true);
        saveGraphDebounced();
      }
    });
  }

  function enableEditMode() {
    setValues({ ...values, editMode: true });
    handleLinkMouseHover();
    handleCellMouseHover();
    handleCellClick();
    handleGraphChanges();
    paper.current.setInteractivity(true);
  }

  useEffect(() => {
    setIsEditModeForBreadcrumbs(values.editMode);
  }, [values.editMode, setIsEditModeForBreadcrumbs]);

  useEffect(() => {
    if (processMapFromScratch && !hasProcesses && graphLoadComplete) {
      handleDialogOpen('processDialog');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processMapFromScratch, hasProcesses, graphLoadComplete]);

  useEffect(() => {
    function syncProcesses(processes) {
      const elements = graph.current.getElements();
      const graphProcesses = elements.filter((element) => element.attributes.type === 'custom.ProcessBox');

      processes.forEach((process) => {
        const foundProcess = graphProcesses.find((graphProcess) => {
          const processData = graphProcess.attributes.attrs.process;

          if (process.id === processData.id) {
            return true;
          }
          return false;
        });
        if (!foundProcess) {
          const processBox = addProcess(process);
          processBox.translate(200, 0);
        } else {
          const foundProcessName = foundProcess.attributes.attrs.process.name;
          if (process.name !== foundProcessName) {
            renameByModel(foundProcess, process.name, 'label/text');
          }
        }
      });

      graphProcesses.forEach((graphProcess) => {
        const processData = graphProcess.attributes.attrs.process;
        const foundProcess = processes.find((process) => {
          if (process.id === processData.id) {
            return true;
          }
          return false;
        });
        if (!foundProcess) {
          deleteByModel(graphProcess);
        }
      });
    }

    function initCanvas(processes) {
      joint.shapes.custom = {};
      joint.shapes.custom.ProcessLink = ProcessLink;
      joint.shapes.custom.ProcessBox = ProcessBox;
      joint.shapes.custom.ProcessArea = ProcessArea;

      graph.current = new joint.dia.Graph([], { cellNamespace: joint.shapes });
      paper.current = createPaper(canvas.current, graph.current, { width: '94.5%', height: '75vh' });
      let positionY = 0;

      if (company.data.processMap) {
        graph.current.fromJSON(JSON.parse(company.data.processMap));
        syncProcesses(processes);
      } else {
        Object.values(processes).forEach((process) => {
          const processBox = addProcess(process);
          processBox.translate(0, positionY);
          positionY += 60;
        });
      }
      if (processes.length > 0 && canvas.current) handleCanvas();
      handleContextMenu();
      setGraphLoadComplete(true);
      setHasProcesses(processes.length > 0);
      paper.current.unfreeze();
    }

    const fetchData = async () => {
      const response = await processService.getAll();
      if (response) initCanvas(response);
    };

    if (!paper.current && !company.data && userCompanyId) {
      dispatch(companyActions.get(userCompanyId));
    }

    if (!paper.current && company.data) {
      fetchData();
    }

    if (createdProcess) {
      addProcess(createdProcess);
      dispatch({ type: processConstants.CLEAN_CREATED_PROCESS });
    }

    if (company.saveResult) { setUpdatingGraph(false); }
  }, [dispatch, userCompanyId, createdProcess, company.data, company.saveResult, addProcess]);

  function showEmptyCanvas() {
    return (
      <Box
        mt={5}
        style={{
          margin: 'auto', marginTop: 100, marginBottom: 40, maxWidth: 408, maxHeight: 121,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 600, fontSize: 14 }}>
            <strong>¡Empezá a crear tu mapa de proceso! </strong>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
          <Typography
            variant="caption"
            color="textPrimary"
            style={{
              textAlign: 'center', width: 400, fontWeight: 400, fontSize: 11,
            }}
          >
            Podés comenzar generando los procesos desde aquí o agregándolos desde el panel izquierdo
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
          <Button
            onClick={() => {
              if (processMapFromScratch) {
                handleDialogOpen('processDialog');
              } else {
                setOpenSetExampleProcessMapModal(true);
              }
            }}
            text={<FormattedMessage id="processMap.create" defaultMessage="CREAR MAPA" />}
            style={{ fontSize: 10, fontWeight: 600 }}
          />
        </Box>
      </Box>
    );
  }

  useEffect(() => {
    if (step === 1 && !company?.data?.isFirstLogin && !showWelcomeModal && !mustChangePassword) {
      setTimeout(() => {
        dispatch(tutorialActions.setTutorialStep(2));
      }, 4000);
    }
  }, [dispatch, step, company, showWelcomeModal, mustChangePassword]);

  return (
    <div className={classes.main} onContextMenu={handleClick} style={{ cursor: 'context-menu' }}>
      <AppBar elevation={0} className={classes.toolbar} position="static">
        <Toolbar>
          <Box style={{ flexGrow: 1 }}>
            <Typography className={classes.toolbarTitle} display="inline" variant="h6">
              {processMapText}
            </Typography>
          </Box>
          <Box flexGrow={1}>
            {(step === 1 && !company?.data?.isFirstLogin && !showWelcomeModal && !mustChangePassword) && (
              <Box className={classes.tutorialInfoStepOneWrapper}>
                <TutorialInfo step={1} />
              </Box>
            )}
          </Box>
          <Box display="flex" alignItems="center" position="relative">
            {updatingGraph && (
              <>
                <CachedIcon className={classes.saveIcon} fontSize="small" />
                <Typography className={classes.saveMessage} variant="caption">
                  Guardando...
                </Typography>
              </>
            )}
            {company.syncResult && company.syncResult.sync && !updatingGraph && (
            <>
              <DoneIcon className={classes.saveIcon} fontSize="small" />
              <Typography className={classes.saveMessage} variant="caption">
                {syncResultOkText}
              </Typography>

            </>
            )}
            {company.syncResult && hasProcesses && !company.syncResult.sync && !updatingGraph && (
              <>
                <CloseIcon className={classes.saveIcon} fontSize="small" />
                <Typography className={classes.saveMessage} variant="caption">
                  {syncResultFailedText}
                </Typography>
              </>
            )}
            {company.saveResult && company.saveResult.success && !updatingGraph && (
              <>
                <DoneIcon className={classes.saveIcon} fontSize="small" />
                <Tooltip title={`${company.saveResult.date}   ${company.saveResult.time}`}>
                  <Typography className={classes.saveMessage} variant="caption">
                    {saveResultOkText}
                  </Typography>
                </Tooltip>

              </>
            )}
            {company.saveResult && !company.saveResult.success && !updatingGraph && (
              <>
                <CloseIcon className={classes.saveIcon} fontSize="small" />
                <Typography className={classes.saveMessage} variant="caption">
                  {saveResultFailedText}
                </Typography>
              </>
            )}
            {editMode && hasProcesses && (
              <>
                <Fab
                  id="zoomin"
                  onClick={handleZoomIn}
                  size="small"
                  color="default"
                  aria-label="add"
                  className={classes.zoomButton}
                >
                  <AddIcon />
                </Fab>
                <Fab
                  id="zoomout"
                  onClick={handleZoomOut}
                  size="small"
                  color="default"
                  aria-label="add"
                  className={classes.zoomButton}
                >
                  <RemoveIcon />
                </Fab>
                <Button
                  variant="outlined"
                  color="primary"
                  text={addProcessButtonText}
                  onClick={() => { handleDialogOpen('processDialog'); }}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  text={addAreaButtonText}
                  onClick={() => { handleDialogOpen('areaDialog'); }}
                />

                <Dialog open={newNameDialog} onClose={() => { handleDialogClose('newNameDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
                  <ValidatorForm style={{ width: '100%' }} onSubmit={handleRenameProcess}>
                    <DialogTitle id="form-dialog-title">{newNameTitle}</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        {enterNewNameText}
                      </DialogContentText>
                      <TextValidator
                        value={newProcessName}
                        onChange={handleChange}
                        fullWidth
                        margin="dense"
                        id="newProcessName"
                        label={newNameText}
                        name="newProcessName"
                        autoFocus
                        validators={['required']}
                        errorMessages={[
                          validationConstants.PROCESS_NAME_REQUIRED,
                        ]}
                      />

                    </DialogContent>
                    <DialogActions>
                      <Button text={cancelButtonText} onClick={() => { handleDialogClose('newNameDialog'); }} />
                      <Button
                        className={classes.menuButton}
                        text={renameText}
                        type="submit"
                      />
                    </DialogActions>
                  </ValidatorForm>
                </Dialog>

                <Dialog open={newNameAreaDialog} onClose={() => { handleDialogClose('newNameAreaDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
                  <ValidatorForm style={{ width: '100%' }} onSubmit={handleRenameArea}>
                    <DialogTitle id="form-dialog-title">{newNameAreaTitle}</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        {enterNewAreaName}
                      </DialogContentText>
                      <TextValidator
                        value={changeAreaName}
                        onChange={handleChange}
                        fullWidth
                        margin="dense"
                        id="changeAreaName"
                        label={newNameText}
                        name="changeAreaName"
                        autoFocus
                        validators={['required']}
                        errorMessages={[
                          validationConstants.PROCESS_AREA_NAME_REQUIRED,
                        ]}
                      />

                    </DialogContent>
                    <DialogActions>
                      <Button text={cancelButtonText} onClick={() => { handleDialogClose('newNameAreaDialog'); }} />
                      <Button
                        className={classes.menuButton}
                        text={renameText}
                        type="submit"
                      />
                    </DialogActions>
                  </ValidatorForm>
                </Dialog>

                <Dialog open={areaDialog} onClose={() => { handleDialogClose('areaDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
                  <ValidatorForm style={{ width: '100%' }} onSubmit={newArea}>
                    <DialogTitle id="form-dialog-title">{addNewAreaText}</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        {enterNewAreaNameText}
                      </DialogContentText>

                      <TextValidator
                        value={newAreaName}
                        onChange={handleChange}
                        fullWidth
                        margin="dense"
                        id="newAreaName"
                        label={newAreaNameText}
                        name="newAreaName"
                        autoFocus
                        validators={['required']}
                        errorMessages={[
                          validationConstants.PROCESS_AREA_NAME_REQUIRED,
                        ]}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button text={cancelButtonText} onClick={() => { handleDialogClose('areaDialog'); }} />
                      <Button
                        className={classes.menuButton}
                        text={addAreaButtonText}
                        type="submit"
                      />
                    </DialogActions>
                  </ValidatorForm>
                </Dialog>

                <Dialog open={deleteProcessDialog} onClose={() => { handleDialogClose('deleteProcessDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">{deleteProcessTitle}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {deleteProcessConfirmationText}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button text={cancelButtonText} onClick={() => { handleDialogClose('deleteProcessDialog'); }} />
                    <Button
                      className={classes.menuButton}
                      text={deleteProcessButtonText}
                      color="secondary"
                      onClick={handleDeleteProcess}
                    />
                  </DialogActions>
                </Dialog>

                <Dialog open={deleteAreaDialog} onClose={() => { handleDialogClose('deleteAreaDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">{deleteAreaTitle}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {deleteAreaConfirmationText}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button text={cancelButtonText} onClick={() => { handleDialogClose('deleteAreaDialog'); }} />
                    <Button
                      className={classes.menuButton}
                      text={deleteProcessButtonText}
                      color="secondary"
                      onClick={handleDeleteArea}
                    />
                  </DialogActions>
                </Dialog>

                <Button
                  className={classes.menuButton}
                  text={doneButtonText}
                  onClick={endEditing}
                />
              </>
            )}
            <Dialog open={processDialog} onClose={() => { handleDialogClose('processDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
              <ValidatorForm style={{ width: '100%' }} onSubmit={newProcess}>
                <DialogTitle id="form-dialog-title">{addNewProcessText}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {enterNewProcessNameText}
                  </DialogContentText>
                  <TextValidator
                    value={newProcessName}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    id="newProcessName"
                    label={newProcessNameText}
                    name="newProcessName"
                    autoFocus
                    validators={['required']}
                    errorMessages={[
                      validationConstants.PROCESS_NAME_REQUIRED,
                    ]}
                  />
                  <TextValidator
                    value={newProcessAreaName}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    id="newProcessAreaName"
                    label={newProcessAreaNameText}
                    name="newProcessAreaName"
                    validators={['required']}
                    errorMessages={[
                      validationConstants.PROCESS_AREA_NAME_REQUIRED,
                    ]}
                  />

                </DialogContent>
                <DialogActions>
                  <Button text={cancelButtonText} onClick={() => { handleDialogClose('processDialog'); }} />
                  <Button
                    className={classes.menuButton}
                    text={addProcessButtonText}
                    type="submit"
                  />
                </DialogActions>
              </ValidatorForm>
            </Dialog>

            {openSetExampleProcessMapModal && <SetExampleProcessMapModal />}

            {isAdmin && !editMode && hasProcesses && (
              <Button
                className={classes.menuButton}
                text={editMapText}
                loading={!company}
                onClick={enableEditMode}
              />
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {!(graphLoadComplete) && (
        <LinearProgress />
      ) }
      { !hasProcesses && graphLoadComplete && showEmptyCanvas() }
      <div
        className={classes.canvas}
        ref={canvas}
        id="canvas"
        data-filter="all"
        style={{
          backgroundColor: hasProcesses ? '#FFFFFF' : '#F8F8F8',
          height: hasProcesses ? 500 : 1,
        }}
      />

      <Menu
        keepMounted
        open={contextMenu.mouseY !== null && selectedCell && selectedCell.model.attributes.type === 'custom.ProcessBox'}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu.mouseY !== null && contextMenu.mouseX !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <div>
          <MenuItem onClick={redirectToProcess}>{toProcessText}</MenuItem>
          { editMode && (
            <div>
              <Divider />
              <MenuItem onClick={openNewProcessNameDialog}>{renameText}</MenuItem>
              <Divider />
              <MenuItem onClick={openDeleteProcessDialog}>{deleteText}</MenuItem>
            </div>
          )}
        </div>
      </Menu>

      <Menu
        keepMounted
        open={contextMenu.mouseY !== null && selectedCell && selectedCell.model.attributes.type === 'custom.ProcessArea' && editMode}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
            contextMenu.mouseY !== null && contextMenu.mouseX !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
      >
        <div>
          <div>
            <MenuItem onClick={openNewProcessAreaNameDialog}>{ renameText }</MenuItem>
            <Divider />
            <MenuItem onClick={() => { handleDialogOpen('deleteAreaDialog'); handleCloseContextMenu(); }}>{ deleteText }</MenuItem>
          </div>
        </div>
      </Menu>
    </div>
  );
}

ProcessMap.propTypes = {
  setIsEditModeForBreadcrumbs: PropTypes.func.isRequired,
};

export default ProcessMap;
