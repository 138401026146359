import React from 'react';
import Grow from '@material-ui/core/Grow';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';

const defaultSearchStyles = (theme) => ({
  main: {
    width: '250px',
    position: 'relative',
    borderRadius: '18px',
    backgroundColor: '#FFFFFF',
    marginLeft: 0,
  },
  clearIcon: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#757575',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    width: '250px',
  },
});

class CustomSearchRender extends React.Component {
  render() {
    const { classes, options, searchText } = this.props;

    const handleTextChange = (event) => {
      this.props.onSearch(event.target.value);
    };
    return (
      <Grow appear in timeout={300}>
        <div className={classes.main} ref={(el) => (this.rootRef = el)}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder={options.textLabels.toolbar.search}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{
              'aria-label': options.textLabels.toolbar.search,
            }}
            value={searchText || ''}
            onChange={handleTextChange}
          />
        </div>

      </Grow>
    );
  }
}

export default withStyles(defaultSearchStyles, { name: 'CustomSearchRender' })(CustomSearchRender);
