import { outlineConstants } from '../../constants';
import { outlineService } from '../../services';
import { history } from '../../helpers';
import alertActions from './alert.actions';

function create(outline, redirectUrl = null) {
  function request(outlineToCreate) {
    return {
      type: outlineConstants.CREATE_REQUEST, outlineToCreate,
    };
  }
  function success(createdOutline) {
    return {
      type: outlineConstants.CREATE_SUCCESS, createdOutline,
    };
  }
  function failure(error) { return { type: outlineConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(outline));

    outlineService.create(outline)
      .then(
        (createdOutline) => {
          dispatch(success(createdOutline));
          if (redirectUrl) { history.replace(`${redirectUrl}${createdOutline.id}`); }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function get(id) {
  function request() { return { type: outlineConstants.GET_REQUEST }; }
  function success(outline) { return { type: outlineConstants.GET_SUCCESS, outline }; }
  function failure(error) { return { type: outlineConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    outlineService.get(id)
      .then(
        (outline) => {
          dispatch(success(outline));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
          history.push('/home');
        },
      );
  };
}

function getAll() {
  function request() { return { type: outlineConstants.GET_ALL_REQUEST }; }
  function success(outlines) { return { type: outlineConstants.GET_ALL_SUCCESS, outlines }; }
  function failure(error) { return { type: outlineConstants.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    outlineService.getAll()
      .then(
        (outlines) => {
          dispatch(success(outlines));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function update(outline, redirectUrl = null) {
  function request(outlineToUpdate) {
    return {
      type: outlineConstants.UPDATE_REQUEST, outlineToUpdate,
    };
  }
  function success(updatedOutline) {
    return {
      type: outlineConstants.UPDATE_SUCCESS, updatedOutline,
    };
  }
  function failure(error) { return { type: outlineConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(outline));

    outlineService.update(outline)
      .then(
        (updatedOutline) => {
          dispatch(success(updatedOutline));
          if (redirectUrl) { history.replace(`${redirectUrl}${updatedOutline.id}`); }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function deleteOutline(outlineId) {
  function request() { return { type: outlineConstants.DELETE_REQUEST }; }
  function success() { return { type: outlineConstants.DELETE_SUCCESS }; }
  function failure() { return { type: outlineConstants.DELETE_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    outlineService.deleteOutline(outlineId)
      .then(
        () => {
          dispatch(success());
          history.push('/outlines');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function linkPerson(personId, outlineId) {
  function request(outline) { return { type: outlineConstants.LINK_REQUEST, outline }; }
  function success(outline) { return { type: outlineConstants.LINK_SUCCESS, outline }; }
  function failure(error) { return { type: outlineConstants.LINK_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());
    outlineService.linkPerson(personId, outlineId)
      .then(
        (outline) => {
          dispatch(success(outline));
          dispatch(alertActions.info('Se ha vinculado el perfil correctamente.'));
          window.location.reload(false);
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function unlinkPerson(personId, outlineId) {
  function request(outline) { return { type: outlineConstants.UNLINK_REQUEST, outline }; }
  function success(outline) { return { type: outlineConstants.UNLINK_SUCCESS, outline }; }
  function failure(error) { return { type: outlineConstants.UNLINK_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());
    outlineService.unlinkPerson(personId, outlineId)
      .then(
        (outline) => {
          dispatch(success(outline));
          dispatch(alertActions.info('Se ha desvinculado el perfil correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

const outlineActions = {
  create,
  get,
  getAll,
  update,
  deleteOutline,
  linkPerson,
  unlinkPerson,
};

export default outlineActions;
