import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,

    headers: {
        'Content-Type': 'application/json'
    }
});

instance.interceptors.request.use((config) => {
    const impersonator = localStorage.getItem('impersonator');
    if (impersonator) config.params = { ...config.params, impersonator: JSON.parse(impersonator).id }
    return config;
});

export default instance;