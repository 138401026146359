import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { FormattedMessage, useIntl } from 'react-intl';
import { userActions } from '../../../redux/actions';
import AuthLayout from '../AuthLayout/AuthLayout';
import Button from '../../controls/Button';
import { validationConstants } from '../../../constants';
import { history } from '../../../helpers';

const initialFieldValues = {
  email: '',
};

function ForgotPasswordPage() {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = React.useState(false);
  const [values, setValues] = useState(initialFieldValues);
  const { email } = values;
  const intl = useIntl();

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'forgot.docTitle', defaultMessage: 'Pertix - Olvido de contraseña' });
  }, [intl]);

  function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);
    dispatch(userActions.forgotPassword(email));
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  }

  return (
    <AuthLayout
      pageTitle={(
        <FormattedMessage
          id="forgot.title"
          defaultMessage="Cambiar contraseña"
        />
)}
      pageSubtitle={(
        <FormattedMessage
          id="forgot.subTitle"
          defaultMessage="Se le enviara un mail para reestablecer su contraseña"
        />
)}
    >
      <ValidatorForm style={{ width: '100%' }} onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <TextValidator
              value={email}
              onChange={handleChange}
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              size="small"
              validators={['required', 'isEmail']}
              errorMessages={[
                validationConstants.EMAIL_REQUIRED,
                validationConstants.EMAIL_INVALID,
              ]}
              autoFocus
            />
          </Grid>
        </Grid>
        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
          <Button
            onClick={history.goBack}
            variant="outlined"
            text={<FormattedMessage id="app.back" defaultMessage="volver" />}
          />
          <Button
            type="submit"
            loading={submitted}
            text={<FormattedMessage id="forgot.send" defaultMessage="Enviar" />}
          />
        </Box>

      </ValidatorForm>
    </AuthLayout>
  );
}

export default ForgotPasswordPage;
