import React from 'react';
import { FormattedMessage } from 'react-intl';

function translateTrainerType(context) {
  switch (context) {
    case 0: return <FormattedMessage id="training.trainerType.internal" defaultMessage="Interno" />;
    case 1: return <FormattedMessage id="training.trainerType.external" defaultMessage="Externo" />;
    default: return 'error';
  }
}

const trainingHelper = {
  translateTrainerType,
};

export default trainingHelper;
