import React from 'react';
import { FormattedMessage } from 'react-intl';

const boldFont = { b: (chunks) => <b>{chunks}</b> };
const linkAppPertix = { a: (chunks) => <a href="https://app.pertixtech.com" aria-labelledby="https://app.pertixtech.com" target="_blank" rel="noopener noreferrer">{chunks}</a> };
const itemList = { li: (chunks) => <li>{chunks}</li> };

const privacyPolicyText = {
  accept: <FormattedMessage id="privacyPolicy.accept" defaultMessage="Política de privacidad" />,
  readAndAgree: <FormattedMessage id="privacyPolicy.IHaveReadAndAgree" defaultMessage="He leído y acepto la" />,
  title: <FormattedMessage id="privacyPolicy.privacyPolicy" defaultMessage="Política de Privacidad" />,
  subTitles: [],
  texts: [
    <FormattedMessage
      id="privacyPolicy.firstPointTitle"
      defaultMessage="La presente Política de Privacidad describe los tipos de datos de carácter personal que Pertix Tech LLC, a través de este sitio y su Plataforma <a>https://app.pertixtech.com</a> (en adelante la “Plataforma”), cómo son usados los datos de los usuarios, a quién se los comunicamos y los derechos y opciones que se ponen a disposición de los usuarios de nuestra Plataforma en lo que respecta al uso que hacemos de sus datos personales y al ejercicio de los derechos de los titulares de los datos. También describimos las medidas implementadas para proteger la seguridad de los datos personales recabados y la forma en que los usuarios pueden contactarse con nosotros para informarse sobre nuestras prácticas en materia de privacidad."
      values={linkAppPertix}
    />,
    <FormattedMessage id="privacyPolicy.firstPointFirstSubpoint" defaultMessage="El usuario de la Plataforma consiente expresa, informada, voluntaria e inequívocamente a la inclusión de todos sus datos personales facilitados a la Pertix Tech a través de la Plataforma en las bases de datos propiedad y responsabilidad de Pertix Tech, así como al tratamiento de todos sus datos personales por medios automatizados y no automatizados para los fines descritos en los siguientes apartados de este documento y con sujeción a los términos y condiciones establecidos en el mismo." />,
    <FormattedMessage id="privacyPolicy.firstPointSecondSubpoint" defaultMessage="Asimismo, al utilizar nuestra Plataforma usted acepta y presta su consentimiento libre, expreso e informado con los términos de la presente Política de Privacidad. En consecuencia, usted debe leer esta Política de Privacidad con atención." />,
    <FormattedMessage id="privacyPolicy.firstPointThirdSubpoint" defaultMessage="Al acceder, conectarse o de cualquier otra forma utilizar la Plataforma, usted confirma que ha leído, entendido y concuerda con las disposiciones presentes en esta política de privacidad. Si usted no está de acuerdo con los términos de esta política, usted no debe utilizar el sitio Plataforma." />,
    <FormattedMessage id="privacyPolicy.firstPointFourthSubpoint" defaultMessage="Pertix Tech manifiesta que realizará todos los esfuerzos necesarios para no transferir los datos recolectados a países en los que no se cumplan con los estándares exigidos por la normativa argentina sobre Protección de Datos Personales y, en caso de llevar a cabo tales transferencias, cumplirá y hará cumplir a sus destinatarios todos los requisitos determinados por la Ley Argentina de Protección de Datos Personales." />,
    <br />,

    <FormattedMessage id="privacyPolicy.secondPointTitle" defaultMessage="<b>1. Datos recolectados</b>" values={boldFont} />,
    <FormattedMessage id="privacyPolicy.secondPointFirstSubpoint" defaultMessage="A través de nuestra Plataforma recabamos determinados datos, incluidos datos de carácter personal. La información de carácter personal es información que pueden identificarlo a usted, como su nombre, domicilio postal, número telefónico y dirección de correo electrónico. Recopilamos información personal acerca de su persona cuando usted nos brinda información directamente a nosotros, incluso cuando se registra o cuando interactúa con nosotros a través de nuestra Plataforma. Usted es responsable de la exactitud de la información personal que usted proporciona a Pertix Tech, y será responsable de las consecuencias de proporcionar información falsa o inexacta." />,
    <FormattedMessage id="privacyPolicy.secondPointSecondSubpoint" defaultMessage="Entre los tipos de datos de carácter personal que recolectamos se encuentran los siguientes: " />,
    <ul>
      <FormattedMessage id="privacyPolicy.secondPointThirdSubpoint" defaultMessage="<li>Datos de contacto (tales como nombre, dirección postal, dirección de correo electrónico y número de teléfono);</li>" values={itemList} />
      <FormattedMessage id="privacyPolicy.secondPointFourthSubpoint" defaultMessage="<li>Credenciales de acceso;</li>" values={itemList} />
      <FormattedMessage id="privacyPolicy.secondPointFifthSubpoint" defaultMessage="<li>Datos demográficos, incluida edad y género.</li>" values={itemList} />
    </ul>,
    <br />,

    <FormattedMessage id="privacyPolicy.thirdPointTitle" defaultMessage="<b>2. Tratamiento de los datos personales</b>" values={boldFont} />,
    <FormattedMessage id="privacyPolicy.thirdPointFirstSubpoint" defaultMessage="Los datos que recolectamos pueden ser utilizados para los siguientes fines:" />,
    <ul>
      <FormattedMessage id="privacyPolicy.thirdPointSecondSubpoint" defaultMessage="<li>Comunicarnos con el usuario;</li>" values={itemList} />
      <FormattedMessage id="privacyPolicy.thirdPointThirdSubpoint" defaultMessage="<li>Enviar al usuario materiales promocionales y otras comunicaciones;</li>" values={itemList} />
      <FormattedMessage id="privacyPolicy.thirdPointFourthSubpoint" defaultMessage="<li>Responder a las consultas del usuario;</li>" values={itemList} />
      <FormattedMessage id="privacyPolicy.thirdPointFifthSubpoint" defaultMessage="<li>Personalizar las preferencias y visitas a nuestra Plataforma de nuestros usuarios y ofrecer contenidos adaptados a sus intereses y a la forma en que navegan e interactúan con nuestra Plataforma;</li>" values={itemList} />
      <FormattedMessage id="privacyPolicy.thirdPointSixthSubpoint" defaultMessage="<li>Operar, evaluar y mejorar nuestros productos y servicios; y Cumplir y hacer cumplir los requisitos legales aplicables, las normas correspondientes del sector, las obligaciones contractuales y nuestras políticas.</li>" values={itemList} />
    </ul>,
    <FormattedMessage id="privacyPolicy.thirdPointSeventhSubpoint" defaultMessage="A través de nuestra Plataforma, podemos además usar servicios analíticos de terceros. Los proveedores de servicios de análisis de Plataformas que administran dichos servicios utilizan tecnologías tales como cookies, registros de servidores de red y balizas Plataforma que nos ayudan a analizar el uso que los usuarios hacen de nuestra Plataforma. La información recogida por estos medios (incluida la dirección IP) puede ponerse en conocimiento de los proveedores de tales servicios analíticos, así como de otros terceros que vayan a hacer un uso de la información recabada para, entre otros fines, evaluar el uso de la Plataforma." />,
    <br />,

    <FormattedMessage id="privacyPolicy.fourthPointTitle" defaultMessage="<b>3. Información que compartimos</b>" values={boldFont} />,
    <FormattedMessage id="privacyPolicy.fourthPointFirstSubpoint" defaultMessage="Los datos personales de los usuarios que recabamos a través de la Plataforma, podrán revelarse a terceros únicamente de acuerdo con lo descrito en la presente Política de Privacidad. Los datos personales recolectados a través de la Plataforma podrán ser comunicados a nuestras filiales y entidades asociadas para los fines anteriormente descritos. También comunicaremos dichos datos personales a proveedores de servicios que presten servicios en nuestro nombre de conformidad con nuestras instrucciones y con las finalidades detalladas en estas Políticas de Privacidad. No autorizamos a dichos proveedores de servicios a revelar o divulgar los datos personales de los usuarios salvo para aquellos usos que sean estrictamente necesarios para la prestación de los servicios en nuestro nombre, o para dar cumplimiento a obligaciones legales." />,
    <FormattedMessage id="privacyPolicy.fourthPointSecondSubpoint" defaultMessage="Pertix Tech comprende la importancia de mantener la confidencialidad de todos los datos que pudieran ser recolectados. En consecuencia, Pertix Tech se compromete, con el fin de garantizar dicha confidencialidad, a suscribir convenios de confidencialidad con cada una de las partes que lleven a cabo un tratamiento de datos personales. Pertix Tech y quienes intervengan en cualquier fase del tratamiento de datos personales están obligados al secreto profesional respecto de los mismos. Tal obligación subsistirá aun después de finalizada su relación con el titular de la base de datos." />,
    <FormattedMessage id="privacyPolicy.fourthPointThirdSubpoint" defaultMessage="Además, podremos revelar datos personales de los usuarios (i) si estamos obligados a hacerlo por ley o en el marco de un procedimiento legal, (ii) a las fuerzas y cuerpos de seguridad o a otros funcionarios públicos cuando lo soliciten legítimamente, (iii) cuando consideremos que dicha revelación es necesaria o conveniente para evitar daños o pérdidas económicas, o (iv) en el marco de una investigación de actividades fraudulentas o ilegales, o que se presuman fraudulentas o ilegales. Asimismo, los titulares de los datos autorizan a Pertix Tech a transferir datos personales de los usuarios en caso de venta o transmisión de la totalidad o de una parte de nuestra empresa o de nuestros activos (incluyendo los casos de reestructuración, disolución o liquidación)." />,
    <br />,

    <FormattedMessage id="privacyPolicy.fifthPointTitle" defaultMessage="<b>4. Los derechos y elecciones del usuario</b>" values={boldFont} />,
    <FormattedMessage id="privacyPolicy.fifthPointFirstSubpoint" defaultMessage="Usted podrá revisar, modificar, eliminar y actualizar sus datos personales en el momento que desee, contactándose con nosotros conforme a lo indicado en la sección titulada “Información de Contacto” del presente documento." />,
    <FormattedMessage id="privacyPolicy.fifthPointSecondSubpoint" defaultMessage="Asimismo, y de conformidad con la legislación vigente, usted podrá solicitar el ejercicio de su derecho de acceso, rectificación o supresión de sus datos. De esta manera, usted tiene la opción de enviar un correo electrónico a info@pertixtech.com para solicitar que se actualicen sus datos o para solicitar una copia de los datos que hemos recolectado en línea." />,
    <FormattedMessage id="privacyPolicy.fifthPointThirdSubpoint" defaultMessage="El derecho de acceso a los datos podrá ser ejercido en forma gratuita por el interesado en la medida que la correspondiente información sea solicitada en intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto, conforme artículo 14, inciso 3 de la Ley 25.326." />,
    <FormattedMessage id="privacyPolicy.fifthPointFourthSubpoint" defaultMessage="Si los datos son incorrectos, desea actualizarlos y/o suprimirlos, nosotros corregiremos, actualizaremos y/o suprimiremos esa información a su requerimiento sin costo alguno, conforme artículo 16 de la Ley 25.326. Para ello, por favor póngase en contacto con nosotros conforme a lo indicado en la sección titulada “Información de Contacto” del presente documento." />,
    <FormattedMessage id="privacyPolicy.fifthPointFifthSubpoint" defaultMessage="La Agencia de Acceso a la Información Pública, Órgano de Control de la Ley 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales." />,
    <FormattedMessage id="privacyPolicy.fifthPointSixthSubpoint" defaultMessage="Frente a cualquier solicitud, usted deberá suministrar a Pertix Tech un medio para poder identificarlo y así contactarlo de ser necesario. Pertix Tech responderá a las solicitudes apropiadas dentro de los 10 (diez) días hábiles desde la recepción de la solicitud en caso de tratarse de una solicitud de acceso, y en un plazo de 5 (cinco) días hábiles en caso de tratarse de una solicitud de rectificación, actualización o supresión de sus datos." />,
    <br />,

    <FormattedMessage id="privacyPolicy.sixthPointTitle" defaultMessage="<b>5. Transferencia de datos</b>" values={boldFont} />,
    <FormattedMessage id="privacyPolicy.sixthPointFirstSubpoint" defaultMessage="Sus datos personales recabados a través de la Plataforma pueden ser transferidos para los fines anteriormente descritos a nuestras filiales, entidades asociadas y proveedores de servicios, ya sea en Estados Unidos o en otros países. Dichos países pueden no tener la misma normativa de protección de datos que la que existe en Argentina. Cuando transfiramos sus datos personales a otros países, los protegeremos en la forma descrita en este documento y de acuerdo con la Ley de Protección de Datos Personales." />,
    <FormattedMessage id="privacyPolicy.sixthPointSecondSubpoint" defaultMessage="Para los residentes en Argentina, de conformidad con la legislación aplicable hemos implementado salvaguardas que garantizan un nivel adecuado de protección de los datos que se transfieren fuera de sus fronteras. Dichas salvaguardas incluyen las Cláusulas Contractuales Modelo de la Agencia de Acceso a la Información Pública o cláusulas contractuales conforme lineamientos de aquel organismo de control. Así, el tratamiento internacional de sus datos personales se realiza en cumplimiento con la Ley 25.326 y su normativa complementaria." />,
    <br />,

    <FormattedMessage id="privacyPolicy.seventhPointTitle" defaultMessage="<b>6. Seguridad de sus datos personales</b>" values={boldFont} />,
    <FormattedMessage id="privacyPolicy.seventhPointFirstSubpoint" defaultMessage="Mantenemos medidas de seguridad administrativas, técnicas y físicas estrictamente diseñadas para proteger los datos personales que el usuario nos proporciona frente a su destrucción, pérdida, alteración, acceso, comunicación o uso accidental, ilegal o no autorizado." />,
    <FormattedMessage id="privacyPolicy.seventhPointSecondSubpoint" defaultMessage="Así, para prevenir el acceso no autorizado, mantener la precisión de los datos y asegurar el uso correcto de los datos de carácter personal que el usuario nos proporciona, Pertix Tech ha implementado ciertos medios físicos, electrónicos, administrativos y procedimientos de seguridad para resguardar y asegurar los datos de carácter personal que recopilamos en línea. Nosotros resguardamos los datos de carácter personal de acuerdo a estándares y procedimientos de seguridad establecidos y continuamente evaluamos nueva tecnología para proteger esa información. Pertix Tech garantiza que los procesos internos propios de las bases de datos cumplen con las obligaciones legales de seguridad y confidencialidad impuestas por la Ley de Protección de Datos Personales N° 25.326." />,
    <FormattedMessage id="privacyPolicy.seventhPointThirdSubpoint" defaultMessage="Sin embargo, usted reconoce que los medios técnicos existentes que brindan seguridad no son inexpugnables, y que aun cuando se adopten todos los recaudos razonables de seguridad es posible sufrir manipulaciones, destrucción y/o pérdida de información. Finalmente, si usted cree que su interacción con nosotros ya no es segura (por ejemplo, si cree que la seguridad de una cuenta que usted tiene con nosotros se ha puesto en riesgo), notifíquenos inmediatamente del problema usando las vías de comunicación de la sección “Información de Contacto”." />,
    <br />,

    <FormattedMessage id="privacyPolicy.eighthPointTitle" defaultMessage="<b>7. Enlaces a otros sitios Plataforma</b>" values={boldFont} />,
    <FormattedMessage id="privacyPolicy.eighthPointFirstSubpoint" defaultMessage="Nuestro sitio web o Plataforma puede contener enlaces a otros sitios o plataformas para conveniencia e información de los usuarios. Esos sitios o plataformas pueden estar operados por compañías que no estén vinculadas a nosotros. Habitualmente, los sitios Plataforma enlazados tienen sus propias políticas o avisos de privacidad, los cuales recomendamos que el usuario lea si visitan cualquiera de tales sitios Plataforma enlazados. No asumimos ninguna responsabilidad sobre del contenido de los sitios Plataforma distintos al nuestro, así como tampoco respecto del uso que se lleve a cabo de tales sitios Plataforma, ni de las prácticas de privacidad de los mismos." />,
    <br />,

    <FormattedMessage id="privacyPolicy.ninthPointTitle" defaultMessage="<b>8. Actualizaciones de esta política de privacidad</b>" values={boldFont} />,
    <FormattedMessage id="privacyPolicy.ninthPointFirstSubpoint" defaultMessage="Esta Política de Privacidad podrá ser objeto de actualización periódica y sin previo aviso para reflejar los cambios introducidos en nuestras prácticas en materia de datos personales. Cuando se trate de cambios sustanciales, lo notificaremos mediante un aviso visible en nuestra Plataforma, indicando en la parte inferior de la Política de Privacidad la fecha de su última actualización. Toda modificación de esta Política de Privacidad entrará en vigencia cuando publiquemos la actualización de la Política de Privacidad. Su interacción con nosotros después de la introducción de estas modificaciones significara que usted acepta dichas modificaciones." />,
    <br />,

    <FormattedMessage id="privacyPolicy.tenthPointTitle" defaultMessage="<b>9. Información de contacto</b>" values={boldFont} />,
    <FormattedMessage id="privacyPolicy.tenthPointFirstSubpoint" defaultMessage="Los usuarios pueden ponerse en contacto con nosotros para actualizar sus datos o ejercer los derechos que les corresponden de conformidad con esta Política de Privacidad enviando un correo electrónico a info@pertixtech.com." />,
    <br />,
  ],
};

export default privacyPolicyText;
