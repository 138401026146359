import axios from '../axios/axios';
import { authHeader, userCompanySelectorHelper } from '../helpers';
import handler from './handler';

function create(outline) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `/companies/${company.id}/outline`;
  return axios.post(postUrl, outline, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function get(id) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/outline/${id}`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAll() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/outline`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function update(outline) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const putUrl = `/companies/${company.id}/outline/${outline.id}`;
  return axios.put(putUrl, outline, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function deleteOutline(outlineId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const deleteUrl = `/companies/${company.id}/outline/${outlineId}`;
  return axios.delete(deleteUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function linkPerson(personId, outlineId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/People/${personId}/Outline/${outlineId}/link`;
  const data = {
    id: personId,
    outlineId,
  };
  return axios.post(getUrl, data, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function unlinkPerson(personId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/People/${personId}/outline/unlink`;
  const data = {
    id: personId,
  };
  return axios.post(getUrl, data, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const outlineService = {
  create,
  get,
  getAll,
  update,
  deleteOutline,
  linkPerson,
  unlinkPerson,
};

export default outlineService;
