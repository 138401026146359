import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Breadcrumbs, LinearProgress, makeStyles, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProcessForm from '../ProcessForm';
import { processActions } from '../../../redux/actions';
import PageLayout from '../../PageLayout';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  toolbar: {
    backgroundColor: '#F8F8F8',
    zIndex: 1,
  },
  toolbarTitle: {
    color: '#F79A56',
    flexGrow: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    backgroundColor: '#F8F8F8',
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(2),
    },
  },
}));

function EditProcessPage(props) {
  const classes = useStyles();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'processes.edit.title', defaultMessage: 'Modificar Proceso' });
  const processesText = intl.formatMessage({ id: 'processes.title', defaultMessage: 'Procesos' });
  const modifyText = intl.formatMessage({ id: 'app.modify', defaultMessage: 'Modificar' });
  const dispatch = useDispatch();
  const processId = props.match.params.id;
  const process = useSelector((state) => state.process.data);

  useEffect(() => {
    dispatch(processActions.get(processId));
  }, [dispatch, processId]);

  function EditProcessBreadcrumbs() {
    const processLink = `/processes/${processId}`;

    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/processes" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{processesText}</Typography>
        </Link>
        <Link color="inherit" to={processLink} style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{process.name}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{modifyText}</strong></Typography>
      </Breadcrumbs>
    );
  }

  function EditProcessLoadingBreadcrumbs() {
    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/processes" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{processesText}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{modifyText}</strong></Typography>
      </Breadcrumbs>
    );
  }
  return (
    <>
      {process
        ? (
          <PageLayout title={title} breadCrumbs={<EditProcessBreadcrumbs />}>
            <ProcessForm edit data={process} />
          </PageLayout>
        )
        : (
          <PageLayout title={title} breadCrumbs={<EditProcessLoadingBreadcrumbs />}>
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
}

export default EditProcessPage;
