import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box, Dialog, DialogContent,
  Fab, Grid, makeStyles, Typography, CircularProgress, RadioGroup,
  FormControlLabel, Radio, LinearProgress,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import { Check, Clear } from '@material-ui/icons';
import PaymentIcon from '@material-ui/icons/Payment';
import { orange } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import Button from '../../controls/Button';
import {
  stripeActions, modalAlertActions, alertActions, companyActions,
} from '../../../redux/actions';
import CardSection from '../../Stripe/Component/CardSection';
import axios from '../../../axios/axios';
import { authHeader, textHelpers, userCompanySelectorHelper } from '../../../helpers';
import handler from '../../../services/handler';
import { stripePlanSpecs } from '../../../constants';
import colors from '../../../assets/colors';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: '208px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    color: orange[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconMoreInformation: {
    height: 14,
    width: 14,
    color: colors.red,
    margin: '0 4px 0 6px',
  },
  main: {
    padding: '36px 24px 24px',
    backgroundColor: theme.palette.background.paper,
    height: '100%',
  },
  containerCard: {
    margin: '16px 8px',
  },
  containerPaymentMethods: {
    minWidth: '601px',
  },
}));

function CompanySubscriptionConfig({ company }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const stripe = useStripe();
  const elements = useElements();
  const cardsData = useSelector((state) => state.stripe.cardsData);
  const userPlanText = intl.formatMessage({ id: 'user.plan', defaultMessage: 'Plan' });
  const subscriptionDataText = intl.formatMessage({ id: 'stripe.subscriptionData', defaultMessage: 'Datos de suscripción' });
  const addPaymentMethodText = intl.formatMessage({ id: 'stripe.addPaymentMethod', defaultMessage: 'Agregar método de pago' });
  const paymentMethodsText = intl.formatMessage({ id: 'company.paymentMethods', defaultMessage: 'Métodos de pago' });
  const defaultPaymentMethodText = intl.formatMessage({ id: 'company.defaultPaymentMethod', defaultMessage: 'método de pago por defecto' });
  const changeDefaultPaymentMethodText = intl.formatMessage({ id: 'company.changeDefaultPaymentMethod', defaultMessage: 'Cambiar método de pago por defecto' });
  const retryInvoiceText = intl.formatMessage({ id: 'stripe.unprocessedPayment', defaultMessage: 'Hubo un problema a la hora de procesar el pago' });
  const retryPaymentText = intl.formatMessage({ id: 'stripe.retryPayment', defaultMessage: 'Reintentar pago' });

  const [userPlan, setUserPlan] = useState(null);
  const [newPaymentMethodDialog, setNewPaymentMethodDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingRetryPayInvoice, setLoadingRetryPayInvoice] = useState(false);
  const [openSubscriptionDialog, setOpenSubscriptionDialog] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [selectedCard, setSelectedCard] = useState('');
  const [defaultCard, setDefaultCard] = useState('');

  const problemsPaymentMessage = {
    title: intl.formatMessage({ id: 'stripe.problemsPayment', defaultMessage: 'PROBLEMAS CON TU PAGO' }),
    message: intl.formatMessage({ id: 'stripe.problemsPaymentMessage', defaultMessage: 'Estamos teniendo problemas para procesar tu pago. Te recomendamos que pruebes con otra tarjeta o contactes a tu entidad bancaria para resolver este problema.' }),
    button: intl.formatMessage({ id: 'stripe.retryPayment', defaultMessage: 'Reintentar pago' }),
    linkTo: '/company/configuration',
  };
  const successfulRetryMessage = {
    title: intl.formatMessage({ id: 'stripe.congratulations', defaultMessage: '¡FELICIDADES!' }),
    message: intl.formatMessage({ id: 'stripe.successfulRetryMessage', defaultMessage: 'La operación se concretó correctamente. Puede continuar usando el sistema con normalidad.' }),
  };

  function searchPlan(planId) {
    const plan = stripePlanSpecs.searchPlan(planId);
    if (plan) setUserPlan(plan);
  }

  function onChangeCreditCardComplete() {
    setLoading(false);
    setConfirmed(true);
    dispatch(stripeActions.getCards());
    setTimeout(() => { setOpenSubscriptionDialog(false); }, 2000);
  }

  function changeCreditCard(paymentMethodId) {
    const headers = authHeader();
    const currentCompany = userCompanySelectorHelper.getCurrentCompany();
    const postUrl = `companies/${currentCompany.id}/subscription/change-credit-card/${paymentMethodId}`;
    return (
      axios.post(postUrl, null, { headers })
        .then(onChangeCreditCardComplete)
        .then(() => {
          setDefaultCard(paymentMethodId);
          setSelectedCard(paymentMethodId);
          setNewPaymentMethodDialog(false);
        })
        .catch(handler.handleError)
    );
  }

  const retryInvoiceWithNewPaymentMethod = async (paymentMethodId) => {
    setLoadingRetryPayInvoice(true);
    const headers = authHeader();
    const currentCompany = userCompanySelectorHelper.getCurrentCompany();
    const postUrl = `companies/${currentCompany.id}/subscription/retry-pay-invoice/${paymentMethodId}`;
    return (
      axios.post(postUrl, {}, { headers })
        .then((result) => {
          dispatch(companyActions.get(currentCompany.id));
          dispatch(modalAlertActions.infoAndReload(successfulRetryMessage));
          return result.data;
        })
        .catch((error) => {
          handler.handleError(error)
            .catch((err) => dispatch(alertActions.error(err)));
          setLoading(false);
          setOpenSubscriptionDialog(false);
          dispatch(modalAlertActions.infoSubscription(problemsPaymentMessage));
        })
        .finally(() => {
          setLoadingRetryPayInvoice(false);
        })
    );
  };

  const handleNewPaymentMethod = async (event, retryPayment) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    setOpenSubscriptionDialog(true);
    const cardElement = elements.getElement(CardElement);

    let paymentMethodId = selectedCard;
    let errorPaymentMethod = false;
    if (cardElement && newPaymentMethodDialog) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      paymentMethodId = paymentMethod ? paymentMethod.id : '';
      errorPaymentMethod = error;
    }

    if (errorPaymentMethod || !paymentMethodId) {
      setLoading(false);
      setOpenSubscriptionDialog(false);
      return;
    }
    if (retryPayment) {
      await retryInvoiceWithNewPaymentMethod(paymentMethodId);
      return;
    }
    await changeCreditCard(paymentMethodId);
  };

  const handleRadioChange = (event) => {
    setSelectedCard(event.target.value);
    setNewPaymentMethodDialog(false);
  };

  useEffect(() => {
    if (!userPlan && !company.requesting) {
      setUserPlan('requesting');
      dispatch(stripeActions.getCards());
    }
    if (company.data) searchPlan(company.data.plan);
    if (cardsData && (cardsData.cards.length > 0)) {
      setSelectedCard(cardsData.defaultCard);
      setDefaultCard(cardsData.defaultCard);
    }
  }, [setUserPlan, dispatch, company, userPlan, cardsData, defaultCard]);

  const isPlanFree = (planId) => [stripePlanSpecs.plans.PertixFree.key, stripePlanSpecs.plans.PertixStarterFree.key].includes(planId);

  function handleCardSectionDisplay() {
    setSelectedCard('');
    setNewPaymentMethodDialog((prev) => !prev);
  }

  return (
    <>
      {(company.data && userPlan) ? (
        <>
          <Box pl={3}>
            {
              company.data.stripeCustomerTaxType && (
                <Box>
                  <Typography className={classes.toolbarTitle} variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                    <span>{company.data?.stripeCustomerTaxType?.split("_").join(" ").toUpperCase()} | {company.data?.stripeCustomerTaxId}</span>
                  </Typography>
                </Box>
              )
            }
            <Typography variant="h6" style={{ fontSize: '18px', fontWeight: 700 }}>
              {subscriptionDataText}
            </Typography>
          </Box>
          <Box pl={4} mt={1}>

            <Grid container spacing={2}>

              <Grid item xs={6}>
                <Typography variant="caption" gutterBottom style={{ fontSize: '14px', fontWeight: 700 }}>
                  {userPlanText}
                </Typography>
                {stripePlanSpecs.subscriptionStatusInvalid(company.data.subscriptionStatus)
                  ? (
                    <Box className={classes.rowContainer}>
                      <Typography variant="subtitle1" style={{ fontSize: '12px' }}>
                        {userPlan.label}
                      </Typography>
                      <InfoIcon className={classes.iconMoreInformation} />
                      <Typography variant="subtitle1" style={{ fontSize: '12px', color: colors.red, fontWeight: 600 }}>
                        {retryInvoiceText}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                      {userPlan.label}
                    </Typography>
                  )}
              </Grid>

              {isPlanFree(userPlan.key) && (
                <Grid item xs={6}>
                  <Button
                    text="Obtener plan"
                    component={Link}
                    to="/stripe/plans"
                    variant="outlined"
                    style={{ fontSize: '11px', fontWeight: 600 }}
                  />
                </Grid>
              )}

              {!isPlanFree(userPlan.key) && (
              <Grid item xs={6} className={classes.rowContainer}>
                <Button
                  component={Link}
                  to="/stripe/plans"
                  text="Cambiar plan"
                  variant="outlined"
                  style={{ fontSize: '11px', fontWeight: 600 }}
                />
              </Grid>
              )}

              <Grid item sm={12} md={6} className={classes.containerPaymentMethods}>
                <ValidatorForm className={classes.main}>
                  <Grid item xs={12}>
                    <Typography variant="caption" gutterBottom style={{ fontSize: '14px', fontWeight: 700 }}>
                      {paymentMethodsText}
                    </Typography>

                    {cardsData && (cardsData.cards.length > 0) && (
                      <RadioGroup aria-label="quiz" name="quiz" value={selectedCard} onChange={handleRadioChange}>
                        {cardsData.cards.map((paymentMethod) => (
                          <Box key={paymentMethod.id}>
                            <FormControlLabel
                              value={paymentMethod.id}
                              control={<Radio className={classes.radio} />}
                              size="small"
                              label={(
                                <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                                  {`${textHelpers.capitalizeFirstLetter(paymentMethod.card.brand)} terminada en ${paymentMethod.card.last4}`}
                                  {(defaultCard === paymentMethod.id) ? ` (${defaultPaymentMethodText})` : null}
                                </Typography>
                              )}
                            />
                          </Box>
                        ))}
                      </RadioGroup>
                    )}
                  </Grid>

                  {!cardsData && userPlan
                  && (
                    <Grid item xs={12}>
                      <CircularProgress />
                    </Grid>
                  )}

                  <Grid container>
                    <Grid item>
                      <Button
                        text={addPaymentMethodText}
                        onClick={() => handleCardSectionDisplay()}
                        variant="outlined"
                        style={{ fontSize: '11px', fontWeight: 600 }}
                      />
                    </Grid>

                    {newPaymentMethodDialog
                      && (
                        <Grid item xs={12} className={classes.containerCard}>
                          <CardSection />
                        </Grid>
                      )}

                    {stripePlanSpecs.subscriptionStatusInvalid(company.data.subscriptionStatus)
                      && (
                        <Grid item>
                          <Button
                            onClick={(e) => {
                              const isRetryPayment = true;
                              handleNewPaymentMethod(e, isRetryPayment);
                            }}
                            text={retryPaymentText}
                            variant="contained"
                            style={{ fontSize: '11px', fontWeight: 600, background: colors.rustyRed }}
                            loading={loadingRetryPayInvoice}
                          />
                        </Grid>
                      )}
                    {(
                      defaultCard !== selectedCard
                      || (newPaymentMethodDialog && cardsData && !cardsData.cards.length)
                    )
                      && (
                      <Grid item>
                        <Button
                          text={changeDefaultPaymentMethodText}
                          onClick={(e) => {
                            const isRetryPayment = false;
                            handleNewPaymentMethod(e, isRetryPayment);
                          }}
                          style={{ fontSize: '11px', fontWeight: 600 }}
                        />
                      </Grid>
                      )}
                  </Grid>
                </ValidatorForm>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : <LinearProgress />}

      <Dialog classes={{ paper: classes.dialogPaper }} open={openSubscriptionDialog} onClose={() => { setOpenSubscriptionDialog(false); }} aria-labelledby="form-dialog-title">
        <DialogContent>
          <Typography color="primary" variant="h4" align="center">
            <FormattedMessage id="subscription.verifyingCard" defaultMessage="Verificando tarjeta" />
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center" height="70%" mt={2}>
            <Typography variant="subtitle2" align="center">
              <FormattedMessage id="subscription.wait" defaultMessage="Por favor aguarde un momento... No cierre esta pantalla" />
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
            <div className={classes.wrapper}>
              <Fab
                aria-label="save"
                color="primary"
                component={Link}
                to={`/companies/${company.id}`}
              >
                {(() => {
                  if (loading) {
                    return <PaymentIcon />;
                  }
                  if (confirmed && !loading) {
                    return <Check />;
                  }
                  return <Clear />;
                })()}
              </Fab>
              {loading && (
                <CircularProgress
                  size={68}
                  className={classes.fabProgress}
                />
              )}
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

CompanySubscriptionConfig.propTypes = {
  company: PropTypes.shape().isRequired,
};

export default CompanySubscriptionConfig;
