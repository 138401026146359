import { combineReducers } from 'redux';
import { alert } from './alert.reducer';
import authentication from './authentication.reducer';
import company from './company.reducer';
import calendarAll from './calendarAll.reducer';
import calendarEvent from './calendarEvent.reducer';
import document from './document.reducer';
import foda from './foda.reducer';
import outline from './outline.reducer';
import outlineEvaluation from './outlineEvaluation.reducer';
import workFieldEvaluation from './workFieldEvaluation.reducer';
import person from './person.reducer';
import process from './process.reducer';
import registration from './registration.reducer';
import risk from './risk.reducer';
import stripe from './stripe.reducer';
import training from './training.reducer';
import user from './user.reducer';
import deviation from './deviation.reducer';
import impersonate from './impersonate.reducer';
import objective from './objective.reducer';
import superAdmin from './superAdmin.reducer';
import modalAlert from './modalAlert.reducer';
import affiliate from './affiliate.reducer';
import provider from './provider.reducer';
import exampleCompany from './exampleCompany.reducer';
import modals from './modals.reducer';
import workField from './workField.reducer';
import personRole from './personRole.reducer';
import tutorial from './tutorial.reducer';

const combinedReducer = combineReducers({
  alert,
  authentication,
  company,
  calendarAll,
  calendarEvent,
  document,
  foda,
  outline,
  outlineEvaluation,
  person,
  process,
  registration,
  risk,
  stripe,
  training,
  user,
  deviation,
  impersonate,
  objective,
  superAdmin,
  modalAlert,
  affiliate,
  provider,
  exampleCompany,
  modals,
  workField,
  workFieldEvaluation,
  personRole,
  tutorial,
});

export default combinedReducer;
