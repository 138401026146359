/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import PageLayout from '../PageLayout';
import { modalAlertActions } from '../../redux/actions';

const PageSubscriptionError = ({ subscriptionStatus }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    intl.formatMessage({ id: 'home.pertix', defaultMessage: 'Pertix' });
  }, [intl]);

  useEffect(() => {
    const messageStatus = {
      past_due: {
        title: intl.formatMessage({ id: 'stripe.problemsPayment', defaultMessage: 'PROBLEMAS CON TU PAGO' }),
        message: intl.formatMessage({ id: 'stripe.problemsPaymentMessage', defaultMessage: 'Estamos teniendo problemas para procesar tu pago. Te recomendamos que pruebes con otra tarjeta o contactes a tu entidad bancaria para resolver este problema.' }),
        button: intl.formatMessage({ id: 'stripe.plans', defaultMessage: 'Planes' }),
      },
      unpaid: {
        title: intl.formatMessage({ id: 'stripe.subscriptionCanceled', defaultMessage: 'SUSCRIPCIÓN CANCELADA' }),
        message: intl.formatMessage({ id: 'stripe.subscriptionCanceledMessage', defaultMessage: 'Lamentablemente, no pudimos resolver los problemas con tu pago y tu plan fue dado de baja. ¡Pero no te preocupes! La información de tu empresa se mantendrá en el sistema y podrás acceder a ella de nuevo una vez renueves tu plan.' }),
        button: intl.formatMessage({ id: 'stripe.renewPlan', defaultMessage: 'Renovar plan' }),
      },
      default: {
        title: intl.formatMessage({ id: 'stripe.problemsPayment', defaultMessage: 'PROBLEMAS CON TU PAGO' }),
        message: intl.formatMessage({ id: 'stripe.problemsPaymentMessage', defaultMessage: 'Estamos teniendo problemas para procesar tu pago. Te recomendamos que pruebes con otra tarjeta o contactes a tu entidad bancaria para resolver este problema.' }),
        button: intl.formatMessage({ id: 'stripe.plans', defaultMessage: 'Planes' }),
      },
    };

    const getMessage = (status) => messageStatus[status] || messageStatus.default;

    if (subscriptionStatus) {
      dispatch(modalAlertActions.infoSubscription(getMessage(subscriptionStatus)));
    }
  }, [subscriptionStatus, dispatch, intl]);

  return (
    <PageLayout>
      <LinearProgress />
    </PageLayout>
  );
};

export default PageSubscriptionError;
