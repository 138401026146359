import React from 'react';
import { SvgIcon } from "@material-ui/core";

function NonConformityIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M10.5169 0C5.02828 0 0.57373 4.48 0.57373 10C0.57373 15.52 5.02828 20 10.5169 20C16.0055 20 20.4601 15.52 20.4601 10C20.4601 4.48 16.0055 0 10.5169 0ZM10.5169 18C6.12203 18 2.56237 14.42 2.56237 10C2.56237 8.15 3.18879 6.45 4.24276 5.1L15.3891 16.31C14.0467 17.37 12.3564 18 10.5169 18ZM16.7911 14.9L5.64475 3.69C6.98708 2.63 8.67742 2 10.5169 2C14.9118 2 18.4715 5.58 18.4715 10C18.4715 11.85 17.845 13.55 16.7911 14.9Z" />
      </SvgIcon>
    );
  }

  export default NonConformityIcon

  