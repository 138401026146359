import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Breadcrumbs,
  makeStyles,
  Typography,
  LinearProgress,
  Grid,
  Box,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PageLayout from '../../PageLayout';
import sortableHelper from '../../../helpers/sortableHelper';
import Button from '../../controls/Button';
import VideoTutorialModal from '../../controls/VideoTutorialModal';
import { videoTutorialId } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  fodaSection: {
    minHeight: 200,
  },
  fortressSection: {
    borderBottom: '1px solid #757575',
    borderRight: '1px solid #757575',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
    },
  },
  weaknessesSection: {
    borderRight: '1px solid #757575',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
      borderBottom: '1px solid #757575',
    },
  },
}));

function FodaPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const company = useSelector((state) => state.company);
  const user = useSelector((state) => state.authentication.user);
  const isAdmin = user.roles.includes('Admin');
  const [fortressData, setFortressData] = React.useState([]);
  const [weaknessesData, setWeaknessesData] = React.useState([]);
  const [opportunitiesData, setOpportunitiesData] = React.useState([]);
  const [threatsData, setThreatsData] = React.useState([]);

  const swotTypes = {
    weaknessesType: 2,
    threatsType: 3,
    fortressType: 0,
    opportunitiesType: 1,
  };

  const title = intl.formatMessage({ id: 'foda.title', defaultMessage: 'FODA' });
  const lastModifiedLabel = intl.formatMessage({ id: 'foda.lastModified', defaultMessage: 'Última modificación' });

  function compare(a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }
  function orderList(list = []) {
    if (list.length > 0) return list.sort(compare);
    return [];
  }

  useEffect(() => {
    if (company.data) {
      const fortress = [];
      const weaknesses = [];
      const opportunities = [];
      const threats = [];

      company.data.swotItems.forEach((element) => {
        if (element.type === swotTypes.fortressType) fortress.push(element);
        if (element.type === swotTypes.weaknessesType) weaknesses.push(element);
        if (element.type === swotTypes.opportunitiesType) opportunities.push(element);
        if (element.type === swotTypes.threatsType) threats.push(element);
      });

      setFortressData(orderList(fortress));
      setOpportunitiesData(orderList(opportunities));
      setWeaknessesData(orderList(weaknesses));
      setThreatsData(orderList(threats));
    }
    // eslint-disable-next-line
  }, [dispatch, company]);

  function FodaBreadcrumbs() {
    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/foda" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{title}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }
  function getLastModificationDate() {
    if (company.data && company.data.swotLastModificationDate) return `${lastModifiedLabel} ${moment(company.data.swotLastModificationDate).format('DD/MM/YYYY - H:mm')}  hs.`;
    return '';
  }
  function lastModifiedText() {
    return (
      <Typography
        variant="caption"
        style={{ fontWeight: 400, fontSize: 11, color: '#757575' }}
      >
        {getLastModificationDate()}
      </Typography>
    );
  }

  function showListItems(list) {
    return (
      <Box display="flex" alignItems="flex-start" justifyContent="flex-start" mt={2} ml={1.5}>
        <ul style={{ padding: 0, margin: 0 }}>
          {list.map((item) => (
            <li key={item.id} style={{ color: '#F16C24', paddingBottom: 16 }}>
              <span style={{ position: 'relative', left: '-5px' }}>
                <Typography variant="caption" gutterBottom style={{ color: '#373435', fontSize: 11 }}>
                  {item.description}
                </Typography>
              </span>
            </li>
          ))}
        </ul>
      </Box>
    );
  }

  return (
    <>
      {company.data
        ? (
          <PageLayout
            title={title}
            breadCrumbs={<FodaBreadcrumbs />}
            buttonGroup={lastModifiedText()}
            iconVideoTutorial={(
              <VideoTutorialModal
                title={<FormattedMessage id="videoTutorial.fodaTitle" defaultMessage="Carga de FODA" />}
                body={<FormattedMessage id="videoTutorial.fodaBody" defaultMessage="Como cargar el análisis situacional en la plataforma" />}
                videoId={videoTutorialId.FODA}
              />
            )}
          >
            <Box pl={3}>
              <Grid container spacing={6}>
                <Grid item xs={0} md={1} />
                <Grid item xs={12} md={5} className={`${classes.fortressSection} ${classes.fodaSection}`}>
                  <Typography variant="caption" gutterBottom style={{ fontSize: 13, fontWeight: 600, marginLeft: '6px' }}>
                    {sortableHelper.getNameByType(swotTypes.fortressType)}
                  </Typography>
                  {showListItems(fortressData)}
                </Grid>
                <Grid item xs={12} md={5} className={classes.fodaSection} style={{ borderBottom: '1px solid #757575' }}>
                  <Typography variant="caption" gutterBottom style={{ fontSize: 13, fontWeight: 600, marginLeft: '6px' }}>
                    {sortableHelper.getNameByType(swotTypes.opportunitiesType)}
                  </Typography>
                  {showListItems(opportunitiesData)}
                </Grid>
                <Grid item xs={0} md={1} />
                <Grid item xs={0} md={1} />
                <Grid item xs={12} md={5} className={`${classes.weaknessesSection} ${classes.fodaSection}`}>
                  <Typography variant="caption" gutterBottom style={{ fontSize: 13, fontWeight: 600, marginLeft: '6px' }}>
                    {sortableHelper.getNameByType(swotTypes.weaknessesType)}
                  </Typography>
                  {showListItems(weaknessesData)}
                </Grid>
                <Grid item xs={12} md={5} className={classes.fodaSection}>
                  <Typography variant="caption" gutterBottom style={{ fontSize: 13, fontWeight: 600, marginLeft: '6px' }}>
                    {sortableHelper.getNameByType(swotTypes.threatsType)}
                  </Typography>
                  {showListItems(threatsData)}
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end" mt={2}>
              <Button
                component={Link}
                to="foda/edit"
                text={<FormattedMessage id="app.edit" defaultMessage="editar" />}
                disabled={!isAdmin}
              />
            </Box>
          </PageLayout>
        )
        : (
          <PageLayout title={title} breadCrumbs={<FodaBreadcrumbs />}>
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
}

export default FodaPage;
