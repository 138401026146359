import React from 'react';
import {
  Box, DialogContent, makeStyles, Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import CalendarEventDetailPersonCard from '../CalendarEventDetailPersonCard';
import { deviationConstants } from '../../../../constants';
import CalendarEventDetailProcessList from '../CalendarEventDetailProcessList';

const useStyles = makeStyles(() => ({
  flexCenterBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  dialogContent: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    margin: '20px 0px 10px',
    boxSizing: 'border-box',
  },
  deviationStatusContainer: {
    height: '84.5px',
    borderLeft: '1px solid #C4C4C4',
    paddingLeft: '27px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  datesContainer: {
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
    background: '#F8F8F8',
    borderRadius: '8px',
    width: '302px',
    padding: '16px 21px',
  },
  statusTitle: { fontSize: '12px', fontWeight: 600 },
  dateTitle: { fontSize: '11px', fontWeight: 600 },
  dateText: { fontSize: '11px', fontWeight: 400 },
}));

const CalendarEventDetailDeviationContent = ({ event }) => {
  const classes = useStyles();
  const intl = useIntl();
  const responsibleText = intl.formatMessage({ id: 'people.responsible', defaultMessage: 'Responsable' });
  const deviationStatusText = intl.formatMessage({ id: 'calendar.deviationStatusText', defaultMessage: 'Estado de desvío' });
  const detectionDateText = intl.formatMessage({ id: 'risks.label.detectionDate', defaultMessage: 'Fecha de detección' });
  const plannedImplementationDateText = intl.formatMessage({ id: 'deviation.form.plannedImplementationDate', defaultMessage: 'Fecha de implementación prevista' });
  const effectiveImplementationDateText = intl.formatMessage({ id: 'deviation.form.effectiveImplementationDate', defaultMessage: 'Fecha de implementación efectiva' });
  const expectedEffectivenessVerificationDateText = intl.formatMessage({ id: 'deviation.form.expectedEffectivenessVerificationDate', defaultMessage: 'Fecha prevista de verificación de efectividad' });
  const effectivenessVerificationDateText = intl.formatMessage({ id: 'deviation.form.effectivenessVerificationDate', defaultMessage: 'Fecha de verificación efectiva' });
  const {
    currentState, processes, detectionDate,
    plannedImplementationDate, effectiveImplementationDate,
    expectedEffectivenessVerificationDate,
    effectivenessVerificationDate,
  } = event.eventInfo;

  const getFormattedDate = (date) => moment(date).format('DD/MM/YYYY');

  return (
    <DialogContent className={classes.dialogContent}>
      <Box display="flex" alignItems="center">
        <Box className={classes.flexCenterBox}>
          <Typography variant="h5" className={classes.statusTitle}>
            {responsibleText}
          </Typography>
          <CalendarEventDetailPersonCard person={event.eventResponsible} />
        </Box>
        <Box className={classes.deviationStatusContainer}>
          <Typography variant="h5" className={classes.statusTitle} style={{ lineHeight: '12px', marginBottom: '6px' }}>
            {deviationStatusText}
          </Typography>
          <Typography variant="h6" style={{ fontSize: '12px', fontWeight: 400, lineHeight: '12px' }}>
            {deviationConstants.statusForm[currentState].label}
          </Typography>
          <CalendarEventDetailProcessList processes={processes} />
        </Box>
      </Box>

      <Box className={classes.datesContainer}>
        <Box mb={1}>
          <Typography variant="h5" className={classes.dateTitle}>
            {detectionDateText}
          </Typography>
          <Typography variant="h6" className={classes.dateText}>
            {getFormattedDate(detectionDate)}
          </Typography>
        </Box>
        {plannedImplementationDate
        && (
          <Box mb={1}>
            <Typography variant="h5" className={classes.dateTitle}>
              {plannedImplementationDateText}
            </Typography>
            <Typography variant="h6" className={classes.dateText}>
              {getFormattedDate(plannedImplementationDate)}
            </Typography>
          </Box>
        )}
        {effectiveImplementationDate
        && (
          <Box mb={1}>
            <Typography variant="h5" className={classes.dateTitle}>
              {effectiveImplementationDateText}
            </Typography>
            <Typography variant="h6" className={classes.dateText}>
              {getFormattedDate(effectiveImplementationDate)}
            </Typography>
          </Box>
        )}
        {expectedEffectivenessVerificationDate
        && (
          <Box mb={1}>
            <Typography variant="h5" className={classes.dateTitle}>
              {expectedEffectivenessVerificationDateText}
            </Typography>
            <Typography variant="h6" className={classes.dateText}>
              {getFormattedDate(expectedEffectivenessVerificationDate)}
            </Typography>
          </Box>
        )}
        {effectivenessVerificationDate
        && (
          <Box>
            <Typography variant="h5" className={classes.dateTitle}>
              {effectivenessVerificationDateText}
            </Typography>
            <Typography variant="h6" className={classes.dateText}>
              {getFormattedDate(effectivenessVerificationDate)}
            </Typography>
          </Box>
        )}

      </Box>
    </DialogContent>
  );
};

CalendarEventDetailDeviationContent.propTypes = {
  event: PropTypes.shape({
    eventInfo: PropTypes.shape({
      processes: PropTypes.arrayOf(PropTypes.any),
      currentState: PropTypes.string,
      detectionDate: PropTypes.string,
      plannedImplementationDate: PropTypes.string,
      effectiveImplementationDate: PropTypes.string,
      expectedEffectivenessVerificationDate: PropTypes.string,
      effectivenessVerificationDate: PropTypes.string,
      headOfImplementation: PropTypes.number,
      headOfEffectivenessVerification: PropTypes.number,
    }),
    eventResponsible: PropTypes.shape({}),
  }).isRequired,
};

export default CalendarEventDetailDeviationContent;
