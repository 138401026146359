/* eslint-disable max-len */
import { modalAlertConstants } from '../../constants';

function modalAlert(state = { data: [] }, action) {
  function addMessageIfNotExist(payload) {
    const messageAlreadyAdded = state.data.find((d) => d.message === payload.message);
    if (!messageAlreadyAdded) {
      state.data.push(payload);
    }
  }

  switch (action.type) {
    case modalAlertConstants.INFO:
      addMessageIfNotExist({
        title: action.title, message: action.message, button: action.button, linkTo: action.linkTo,
      });
      return { data: state.data };

    case modalAlertConstants.CUSTOM_INFO:
      addMessageIfNotExist({
        showButtonToAll: true,
        leftButtonFunction: action.leftButtonFunction,
        ...action.data,
      });
      return { data: state.data };

    case modalAlertConstants.INFO_GOTO_HOME:
      addMessageIfNotExist({
        title: action.title, message: action.message, button: action.button, goToHome: true,
      });
      return { data: state.data };

    case modalAlertConstants.INFO_RELOAD:
      addMessageIfNotExist({
        title: action.title, message: action.message, button: action.button, reload: true,
      });
      return { data: state.data };

    case modalAlertConstants.CLEAR:
      state.data.shift();
      return { data: state.data };

    default:
      return state;
  }
}

export default modalAlert;
