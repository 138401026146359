import React, { useState, useEffect } from 'react';
import {
  Drawer, makeStyles, Toolbar, List, ListItem, MenuItem, Menu,
  ListItemIcon, ListItemText, Collapse, ListItemSecondaryAction, IconButton, useTheme, Divider, Box,
} from '@material-ui/core';
import {
  Business, ExpandLess, ExpandMore, People,
} from '@material-ui/icons';
import MapIcon from '@material-ui/icons/Map';
import { Link } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AddBoxIcon from '@material-ui/icons/AddBox';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TableChartIcon from '@material-ui/icons/TableChart';
import CloseIcon from '@material-ui/icons/Clear';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ProviderIcon from '@material-ui/icons/BusinessCenter';
import WorkIcon from '@material-ui/icons/Work';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  PeopleIcon, FodaIcon,
  NonConformityIcon, DraftsIcon, RisksIcon, ProcessIcon, OrgChartIcon,
} from '../../assets/icons/index';
import { history, userCompanySelectorHelper } from '../../helpers';
import { companyActions, userActions } from '../../redux/actions';
import CustomListItem from '../controls/CustomListItem';
import { stripePlanSpecs } from '../../constants';
import TutorialTooltip from '../controls/TutorialTooltip';
import { STEPS } from '../../constants/tutorial.constants';

const drawerWidth = 209;
const useStyles = makeStyles((theme) => ({

  drawerContainer: {
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingLeft: 20,
    height: '100%',
  },
  nested: {
    paddingLeft: theme.spacing(4),
    fontSize: '12px',
    fontWeight: 400,
  },
  itemList: {
    fontSize: '12px',
    fontWeight: 400,
  },
  iconContainer: {
    minWidth: '30px',
  },
  icon: {
    width: '16px',
    height: '16px',
  },
  title: {
    fontSize: '14px',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  containerSuperAdmin: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
}));

const companyConfigurationItems = ['users', 'configuration'];
const superAdminItems = ['companies', 'superadmin'];
const peopleItems = ['people', 'outlines', 'evaluations', 'trainings', 'orgchart'];
const risksItems = ['risks', 'risks/new', 'risks/edit'];
const providersItems = ['providers', 'providers/new', 'providers/edit'];
const workFieldsItems = ['workFields', 'workFields/new', 'workFields/edit'];
const workFieldEvaluation = ['workFieldEvaluations', 'workFieldEvaluations/new', 'workFieldEvaluations/edit'];

const hasManyCompaniesThe = (userSelected) => userSelected.companies.length > 1;

export default function PertixDrawer() {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);
  const impersonator = useSelector((state) => state.impersonate.data);
  const currentCompany = useSelector((state) => state.company.data);
  const step = useSelector((state) => state.tutorial.step);
  const isTutorialStepTwo = step === 2;
  const [isTutorialTooltipOpen, setIsTutorialTooltipOpen] = useState(isTutorialStepTwo);
  const selectedCompany = userCompanySelectorHelper.getCurrentCompany();
  const location = history.location.pathname;
  const includesItem = (item) => location.includes(item);
  const [openProcesses, setOpenProcesses] = useState(includesItem('processes'));
  const [openDeviation, setOpenDeviation] = useState(includesItem('deviation'));
  const [openObjective, setOpenObjective] = useState(includesItem('objective'));
  const [openProviders, setOpenProviders] = useState(includesItem('providers') || includesItem('workFields') || includesItem('workFieldEvaluations'));
  const [openPeople, setOpenPeople] = useState(peopleItems.some(includesItem));
  const [openConfig, setOpenConfig] = useState(companyConfigurationItems.some(includesItem));
  const [anchorElCompany, setAnchorElCompany] = useState(null);
  const processesLabel = intl.formatMessage({ id: 'processes.title', defaultMessage: 'Procesos' });
  const processMapLabel = intl.formatMessage({ id: 'processMap.map', defaultMessage: 'Mapa' });
  const processListLabel = intl.formatMessage({ id: 'processes.list.title', defaultMessage: 'Listado' });
  const deviationListLabel = intl.formatMessage({ id: 'deviation.list.title', defaultMessage: 'Listado' });
  const deviationLabel = intl.formatMessage({ id: 'deviation.title', defaultMessage: 'Desvíos' });
  const deviationBoardLabel = intl.formatMessage({ id: 'deviation.board', defaultMessage: 'Tablero' });
  const newDeviationLabel = intl.formatMessage({ id: 'deviation.new.title', defaultMessage: 'Nuevo' });
  const objectiveListLabel = intl.formatMessage({ id: 'objective.listTitle', defaultMessage: 'Listado' });
  const objectiveLabel = intl.formatMessage({ id: 'objective.title', defaultMessage: 'Objetivos' });
  const newObjectiveLabel = intl.formatMessage({ id: 'app.new', defaultMessage: 'Nuevo' });
  const newProcessLabel = intl.formatMessage({ id: 'processes.new.title', defaultMessage: 'Nuevo' });
  const risksLabel = intl.formatMessage({ id: 'risks.title', defaultMessage: 'Riesgos' });
  const peopleListLabel = intl.formatMessage({ id: 'people.list.title', defaultMessage: 'Listado' });
  const peopleLabel = intl.formatMessage({ id: 'people.title', defaultMessage: 'Personas' });
  const configLabel = intl.formatMessage({ id: 'company.configuration', defaultMessage: 'Configuración' });
  const usersLabel = intl.formatMessage({ id: 'user.usersTitle', defaultMessage: 'Usuarios' });
  const fodaLabel = intl.formatMessage({ id: 'foda.title', defaultMessage: 'FODA' });
  const companyLabel = intl.formatMessage({ id: 'user.company', defaultMessage: 'Empresa' });
  const documentsLabel = intl.formatMessage({ id: 'documents.title_pl', defaultMessage: 'Documentos' });
  const calendarLabel = intl.formatMessage({ id: 'calendar.title', defaultMessage: 'Calendario' });
  const outlinesLabel = intl.formatMessage({ id: 'outline.outlinesText', defaultMessage: 'Perfiles' });
  const outlineEvaluationsLabel = intl.formatMessage({ id: 'outlineEvaluation.evaluationsText', defaultMessage: 'Evaluaciones' });
  const trainingsLabel = intl.formatMessage({ id: 'training.trainingsText', defaultMessage: 'Capacitaciones' });
  const orgChartLabel = intl.formatMessage({ id: 'orgChart.title', defaultMessage: 'Organigrama' });
  const superAdminLabel = intl.formatMessage({ id: 'superAdmin.label', defaultMessage: 'Superadmin' });
  const providersLabel = intl.formatMessage({ id: 'providers.providers', defaultMessage: 'Proveedores' });
  const providerListLabel = intl.formatMessage({ id: 'providers.list', defaultMessage: 'Listado' });
  const rejectedProviderListLabel = intl.formatMessage({ id: 'providers.rejected', defaultMessage: 'Rechazados' });
  const workFieldListLabel = intl.formatMessage({ id: 'workField.workFields', defaultMessage: 'Rubros' });
  const workFieldEvaluationListLabel = intl.formatMessage({ id: 'workFieldEvaluation.evaluations', defaultMessage: 'Evaluaciones' });

  const isAdmin = user.roles.includes('Admin');

  const handleChangeCompany = (event) => {
    const successfulAffiliated = {
      title: intl.formatMessage({ id: 'affiliates.congratulations', defaultMessage: '¡Felicitaciones!' }),
      messagePart1: intl.formatMessage({ id: 'affiliates.theAgreementSuccessfullyAppliedPart1', defaultMessage: 'Se aplicó con éxito en tu cuenta el convenio con ' }),
      messagePart2: intl.formatMessage({ id: 'affiliates.theAgreementSuccessfullyAppliedPart2', defaultMessage: 'Contás con un plazo de 60 días para obtener un descuento sobre la suscripción al plan que prefieras.' }),
      button: intl.formatMessage({ id: 'affiliates.seeDiscountedPlans', defaultMessage: 'Ver planes con descuento' }),
      linkTo: '/stripe/plans',
      buttonLeft: intl.formatMessage({ id: 'affiliates.maybeLater', defaultMessage: 'Quizás luego' }),
    };
    dispatch(userActions.setCompanySelected(user, event.target.value, successfulAffiliated));
    dispatch(companyActions.get(event.target.value));
    history.push('/home');
  };

  const toggleMenuChangeCompany = (event) => {
    if (anchorElCompany) setAnchorElCompany(null);
    else setAnchorElCompany(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    setOpenConfig(true);
  };

  const handleDrawerClose = () => {
    setOpenConfig(false);
  };

  const handleOpenProcesses = () => {
    setOpenProcesses(!openProcesses);
    if (openPeople) { setOpenPeople(false); }
    if (openDeviation) { setOpenDeviation(false); }
    if (openObjective) { setOpenObjective(false); }
    if (openProviders) { setOpenProviders(false); }
  };

  const handleOpenPeople = () => {
    setOpenPeople(!openPeople);
    if (openProcesses) { setOpenProcesses(false); }
    if (openDeviation) { setOpenDeviation(false); }
    if (openObjective) { setOpenObjective(false); }
    if (openProviders) { setOpenProviders(false); }
  };

  const handleOpenDeviation = () => {
    setOpenDeviation(!openDeviation);
    if (openPeople) { setOpenPeople(false); }
    if (openProcesses) { setOpenProcesses(false); }
    if (openObjective) { setOpenObjective(false); }
    if (openProviders) { setOpenProviders(false); }
  };

  const handleOpenObjective = () => {
    setOpenObjective(!openObjective);
    if (openPeople) { setOpenPeople(false); }
    if (openProcesses) { setOpenProcesses(false); }
    if (openDeviation) { setOpenDeviation(false); }
    if (openProviders) { setOpenProviders(false); }
  };

  const handleOpenProviders = () => {
    setOpenProviders(!openProviders);
    if (openPeople) { setOpenPeople(false); }
    if (openProcesses) { setOpenProcesses(false); }
    if (openDeviation) { setOpenDeviation(false); }
    if (openObjective) { setOpenObjective(false); }
  };

  useEffect(() => {
    if (isTutorialStepTwo) setIsTutorialTooltipOpen(true);
  }, [isTutorialStepTwo]);

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{ paper: classes.drawerPaper }}
    >
      {
        currentCompany
        && stripePlanSpecs.subscriptionStatusInvalid(currentCompany.subscriptionStatus)
        && <Box pt={4} />
      }
      {!!impersonator && <Box pt={3.25} />}
      <Toolbar />
      <div className={classes.drawerContainer}>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={openConfig}
          classes={{ paper: classes.drawerPaper }}
        >
          {
            currentCompany
            && stripePlanSpecs.subscriptionStatusInvalid(currentCompany.subscriptionStatus)
            && <Box pt={4} />
          }
          {!!impersonator && <Box pt={3.25} />}
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem>
              <ListItemText
                disableTypography
                className={classes.title}
                primary={<strong>{configLabel}</strong>}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={handleDrawerClose} edge="end" aria-label="delete">
                  <ChevronLeftIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem
              selected={location === '/company/configuration'}
              button
              component={Link}
              to="/company/configuration"
            >
              <ListItemIcon className={classes.iconContainer}>
                <Business className={classes.icon} />
              </ListItemIcon>
              <ListItemText disableTypography className={classes.itemList} primary={companyLabel} />
            </ListItem>
            { user.isSuperAdmin && (
            <ListItem selected={location === '/users'} button component={Link} to="/users">
              <ListItemIcon className={classes.iconContainer}>
                <PeopleIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText disableTypography className={classes.itemList} primary={usersLabel} />
            </ListItem>
            )}
          </List>
        </Drawer>
        <Box className={classes.containerSuperAdmin}>
          <List>
            <ListItem
              button={hasManyCompaniesThe(user)}
              aria-label="current user's company"
              aria-controls="menu-pertix-drawer"
              aria-haspopup="true"
              onClick={hasManyCompaniesThe(user) ? toggleMenuChangeCompany : undefined}
            >
              <ListItemText
                disableTypography
                className={classes.title}
                primary={hasManyCompaniesThe(user) ? (
                  <Box display="flex">
                    <strong>
                      {selectedCompany.name}
                    </strong>
                    <Box style={{ paddingLeft: 5, paddingTop: 2 }}>
                      {anchorElCompany
                        ? <ExpandLess style={{ color: '#757575' }} />
                        : <ExpandMore style={{ color: '#757575' }} />}
                    </Box>
                  </Box>
                ) : (
                  <strong>
                    {user.companies[0].name}
                  </strong>
                )}
              />
              <Menu
                id="menu-pertix-drawer"
                anchorEl={anchorElCompany}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={!!anchorElCompany}
                onClose={toggleMenuChangeCompany}
                PaperProps={{
                  style: {
                    maxHeight: 200,
                  },
                }}
              >
                {user.companies.map((company) => (
                  <MenuItem onClick={handleChangeCompany} key={`${company.id}-${company.name}`} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Menu>
              { isAdmin && (
              <ListItemSecondaryAction>
                <IconButton onClick={handleDrawerOpen} edge="end" aria-label="delete">
                  <SettingsIcon />
                </IconButton>
              </ListItemSecondaryAction>
              )}
            </ListItem>

            <CustomListItem button onClick={handleOpenProcesses}>
              <ListItemIcon className={classes.iconContainer}>
                <ProcessIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.itemList}
                primary={processesLabel}
              />

              {openProcesses ? <ExpandLess /> : <ExpandMore />}

              <TutorialTooltip
                open={isTutorialTooltipOpen}
                placement="right"
                arrow
                interactive
                sx={{ width: '230px' }}
                title={
                  <Box display="flex">
                    <Box>
                      {STEPS[1].text}
                    </Box>
                    <Box display="flex" alignItems="center">
                      <IconButton style={{ padding: 0, color: '#FFF' }}>
                        <CloseIcon onClick={(e) => {
                          e.stopPropagation();
                          setIsTutorialTooltipOpen(false)
                          }} />
                      </IconButton>
                    </Box>
                  </Box>
                }
              >
                <div />
              </TutorialTooltip>
            </CustomListItem>

            <Collapse in={openProcesses} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <CustomListItem selected={location === '/processes/map'} button className={classes.nested} toolName="processMap" component={Link} to="/processes/map">
                  <ListItemIcon className={classes.iconContainer}>
                    <MapIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.itemList}
                    primary={processMapLabel}
                  />
                </CustomListItem>
                <CustomListItem selected={location === '/processes'} button className={classes.nested} toolName="processManager" component={Link} to="/processes">
                  <ListItemIcon className={classes.iconContainer}>
                    <ListAltIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.itemList}
                    primary={processListLabel}
                  />
                </CustomListItem>
                { isAdmin
                  && (
                  <CustomListItem 
                    selected={location === '/processes/new'} button className={classes.nested} toolName="processManager" component={Link} to="/processes/new" isProcessesNew>
                    <ListItemIcon className={classes.iconContainer}>
                      <AddBoxIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      className={classes.itemList}
                      primary={newProcessLabel}
                    />
                  </CustomListItem>
                  )}
              </List>
            </Collapse>
            <CustomListItem button onClick={handleOpenPeople}>
              <ListItemIcon className={classes.iconContainer}>
                <People className={classes.icon} />
              </ListItemIcon>
              <ListItemText disableTypography className={classes.itemList} primary={peopleLabel} />
              {openPeople ? <ExpandLess /> : <ExpandMore />}
            </CustomListItem>
            <Collapse in={openPeople} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <CustomListItem selected={location === '/orgchart'} button className={classes.nested} toolName="organizationChart" component={Link} to="/orgchart">
                  <ListItemIcon className={classes.iconContainer}>
                    <OrgChartIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.itemList}
                    primary={orgChartLabel}
                  />
                </CustomListItem>
                <CustomListItem selected={location === '/people'} button className={classes.nested} toolName="peopleManager" component={Link} to="/people">
                  <ListItemIcon className={classes.iconContainer}>
                    <ListAltIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.itemList}
                    primary={peopleListLabel}
                  />
                </CustomListItem>
                <CustomListItem selected={location === '/outlines'} button className={classes.nested} toolName="outlines" component={Link} to="/outlines">
                  <ListItemIcon className={classes.iconContainer}>
                    <WorkOutlineIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.itemList}
                    primary={outlinesLabel}
                  />
                </CustomListItem>
                <CustomListItem selected={location === '/evaluations'} button className={classes.nested} toolName="evaluationManager" component={Link} to="/evaluations">
                  <ListItemIcon className={classes.iconContainer}>
                    <AssignmentIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.itemList}
                    primary={outlineEvaluationsLabel}
                  />
                </CustomListItem>
                <CustomListItem selected={location === '/trainings'} button className={classes.nested} toolName="trainingManager" component={Link} to="/trainings">
                  <ListItemIcon className={classes.iconContainer}>
                    <MenuBookIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.itemList}
                    primary={trainingsLabel}
                  />
                </CustomListItem>
              </List>
            </Collapse>
            <CustomListItem button onClick={handleOpenObjective}>
              <ListItemIcon className={classes.iconContainer}>
                <FormatListBulletedIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.itemList}
                primary={objectiveLabel}
              />
              {openObjective ? <ExpandLess /> : <ExpandMore />}
            </CustomListItem>
            <Collapse in={openObjective} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <CustomListItem selected={location === '/objectives'} button className={classes.nested} component={Link} to="/objectives" toolName="objectives">
                  <ListItemIcon className={classes.iconContainer}>
                    <ListAltIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.itemList}
                    primary={objectiveListLabel}
                  />
                </CustomListItem>
                {isAdmin && (
                <CustomListItem selected={location === '/objectives/new'} button className={classes.nested} component={Link} to="/objectives/new" toolName="objectives">
                  <ListItemIcon className={classes.iconContainer}>
                    <AddBoxIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.itemList}
                    primary={newObjectiveLabel}
                  />
                </CustomListItem>
                )}
              </List>
            </Collapse>
            <CustomListItem selected={location === '/foda' || location === '/foda/edit'} button toolName="foda" component={Link} to="/foda">
              <ListItemIcon className={classes.iconContainer}>
                <FodaIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText disableTypography className={classes.itemList} primary={fodaLabel} />
            </CustomListItem>
            <CustomListItem button onClick={handleOpenProviders}>
              <ListItemIcon className={classes.iconContainer}>
                <ProviderIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.itemList}
                primary={providersLabel}
              />
              {openProviders ? <ExpandLess /> : <ExpandMore />}
            </CustomListItem>
            <Collapse in={openProviders} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <CustomListItem selected={providersItems.some(includesItem) && location !== '/providers/rejected'} button className={classes.nested} toolName="provider" component={Link} to="/providers">
                  <ListItemIcon className={classes.iconContainer}>
                    <ListAltIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.itemList}
                    primary={providerListLabel}
                  />
                </CustomListItem>
                <CustomListItem selected={workFieldsItems.some(includesItem)} button className={classes.nested} toolName="provider" component={Link} to="/workFields">
                  <ListItemIcon className={classes.iconContainer}>
                    <WorkIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.itemList}
                    primary={workFieldListLabel}
                  />
                </CustomListItem>
                <CustomListItem selected={workFieldEvaluation.some(includesItem)} button className={classes.nested} toolName="workFieldEvaluation" component={Link} to="/workFieldEvaluations">
                  <ListItemIcon className={classes.iconContainer}>
                    <AssignmentIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.itemList}
                    primary={workFieldEvaluationListLabel}
                  />
                </CustomListItem>
                <CustomListItem selected={location === '/providers/rejected'} button className={classes.nested} toolName="provider" component={Link} to="/providers/rejected">
                  <ListItemIcon className={classes.iconContainer}>
                    <ListAltIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.itemList}
                    primary={rejectedProviderListLabel}
                  />
                </CustomListItem>
              </List>
            </Collapse>
            <CustomListItem button onClick={handleOpenDeviation}>
              <ListItemIcon className={classes.iconContainer}>
                <NonConformityIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.itemList}
                primary={deviationLabel}
              />
              {openDeviation ? <ExpandLess /> : <ExpandMore />}
            </CustomListItem>
            <Collapse in={openDeviation} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <CustomListItem selected={location === '/deviations/board'} button className={classes.nested} toolName="deviationDashboard" component={Link} to="/deviations/board">
                  <ListItemIcon className={classes.iconContainer}>
                    <TableChartIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.itemList}
                    primary={deviationBoardLabel}
                  />
                </CustomListItem>
                <CustomListItem selected={location === '/deviations'} button className={classes.nested} toolName="deviationManager" component={Link} to="/deviations">
                  <ListItemIcon className={classes.iconContainer}>
                    <ListAltIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.itemList}
                    primary={deviationListLabel}
                  />
                </CustomListItem>
                {isAdmin && (
                <CustomListItem selected={location === '/deviations/new'} button className={classes.nested} toolName="deviationManager" component={Link} to="/deviations/new">
                  <ListItemIcon className={classes.iconContainer}>
                    <AddBoxIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.itemList}
                    primary={newDeviationLabel}
                  />
                </CustomListItem>
                )}
              </List>
            </Collapse>
            <CustomListItem selected={location === '/documents'} button component={Link} to="/documents">
              <ListItemIcon className={classes.iconContainer}>
                <DraftsIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.itemList}
                primary={documentsLabel}
              />
            </CustomListItem>
            <CustomListItem selected={risksItems.some(includesItem)} button toolName="riskManager" component={Link} to="/risks">
              <ListItemIcon className={classes.iconContainer}>
                <RisksIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText disableTypography className={classes.itemList} primary={risksLabel} />
            </CustomListItem>
            <CustomListItem selected={location === '/calendar'} button component={Link} to="/calendar" toolName="eventCalendar">
              <ListItemIcon className={classes.iconContainer}>
                <CalendarTodayIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.itemList}
                primary={calendarLabel}
              />
            </CustomListItem>
          </List>
          {user.isSuperAdmin && (
          <List>
            <ListItem selected={superAdminItems.some(includesItem)} button component={Link} to="/superadmin">
              <ListItemIcon className={classes.iconContainer}>
                <VpnKeyIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.itemList}
                primary={superAdminLabel}
              />
            </ListItem>
          </List>
          )}
        </Box>
      </div>
    </Drawer>
  );
}
