import React, { useEffect } from 'react';
import {
  Breadcrumbs, LinearProgress, makeStyles, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PageLayout from '../../PageLayout';
import WorkFieldForm from '../WorkFieldForm';
import { workFieldActions } from '../../../redux/actions';
import { providersTexts, workFieldTexts } from '../../../constants';

const useStyles = makeStyles(() => ({
  breadcrumbs: {
    textDecoration: 'none',
    fontWeight: 400,
    fontSize: 11,
  },
}));

const EditWorkFieldPage = ({ routeParams: { id: workFieldId } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const workField = useSelector((state) => state.workField.data);

  useEffect(() => {
    dispatch(workFieldActions.get(workFieldId));
  }, [dispatch, workFieldId]);

  function EditWorkFieldBreadcrumbs() {
    const workFieldLink = `/workFields/${workFieldId}`;

    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography component={Link} to="/providers" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {providersTexts.providers}
        </Typography>
        <Typography component={Link} to="/workFields" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {workFieldTexts.workFields}
        </Typography>
        <Typography component={Link} to={workFieldLink} variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {workField.name}
        </Typography>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{workFieldTexts.modifyWorkField}</Typography>
      </Breadcrumbs>
    );
  }

  function EditWorkFieldLoadingBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography component={Link} to="/providers" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {providersTexts.providers}
        </Typography>
        <Typography component={Link} to="/workFields" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {workFieldTexts.workFields}
        </Typography>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{workFieldTexts.modifyWorkField}</Typography>
      </Breadcrumbs>
    );
  }

  return (
    <>
      {workField
        ? (
          <PageLayout
            title={workField.name}
            breadCrumbs={<EditWorkFieldBreadcrumbs />}
          >
            <WorkFieldForm edit data={workField} />
          </PageLayout>
        )
        : (
          <PageLayout
            title={workFieldTexts.modifyWorkField}
            breadCrumbs={<EditWorkFieldLoadingBreadcrumbs />}
          >
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
};

EditWorkFieldPage.propTypes = {
  routeParams: PropTypes.shape(),
};

EditWorkFieldPage.defaultProps = {
  routeParams: {},
};

export default EditWorkFieldPage;
