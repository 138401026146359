const personRoleConstants = {
  CREATE_REQUEST: 'PERSON_ROLE_CREATE_REQUEST',
  CREATE_SUCCESS: 'PERSON_ROLE_CREATE_SUCCESS',
  CREATE_FAILURE: 'PERSON_ROLE_CREATE_FAILURE',

  GET_REQUEST: 'PERSON_ROLE_GET_REQUEST',
  GET_SUCCESS: 'PERSON_ROLE_GET_SUCCESS',
  GET_FAILURE: 'PERSON_ROLE_GET_FAILURE',

  GET_ALL_REQUEST: 'PERSON_ROLE_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'PERSON_ROLE_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'PERSON_ROLE_GET_ALL_FAILURE',

  UPDATE_REQUEST: 'PERSON_ROLE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PERSON_ROLE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PERSON_ROLE_UPDATE_FAILURE',

  DELETE_REQUEST: 'PERSON_ROLE_DELETE_REQUEST',
  DELETE_SUCCESS: 'PERSON_ROLE_DELETE_SUCCESS',
  DELETE_FAILURE: 'PERSON_ROLE_DELETE_FAILURE',

  CLEAN_CREATED_PERSON_ROLE: 'CLEAN_CREATED_PERSON_ROLE',
};

export default personRoleConstants;
