import { personRoleConstants } from '../../constants';

function personRole(state = {}, action) {
  switch (action.type) {
    case personRoleConstants.CREATE_REQUEST:
      return { creating: true };
    case personRoleConstants.CREATE_SUCCESS:
      return { created: true };
    case personRoleConstants.CREATE_FAILURE:
      return { error: true };

    case personRoleConstants.GET_ALL_REQUEST:
      return { requesting: true };
    case personRoleConstants.GET_ALL_SUCCESS:
      return { personRoles: action.personRole };
    case personRoleConstants.GET_ALL_FAILURE:
      return { error: true };

    case personRoleConstants.GET_REQUEST:
      return { requesting: true };
    case personRoleConstants.GET_SUCCESS:
      return { data: action.personRole };
    case personRoleConstants.GET_FAILURE:
      return { error: true };

    case personRoleConstants.UPDATE_REQUEST:
      return { data: state.data, updating: true };
    case personRoleConstants.UPDATE_SUCCESS:
      return { data: state.data, updated: true };
    case personRoleConstants.UPDATE_FAILURE:
      return { data: state.data, error: true };

    case personRoleConstants.DELETE_REQUEST:
      return { data: state.data, deleting: true };
    case personRoleConstants.DELETE_SUCCESS:
      return { deleted: true };
    case personRoleConstants.DELETE_FAILURE:
      return { data: state.data, error: true };

    default:
      return state;
  }
}

export default personRole;
