import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Rating } from '@material-ui/lab';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import colors from '../../assets/colors';
import { workFieldEvaluationTexts } from '../../constants';

const useStyles = makeStyles(() => ({
  labelPercentage: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    marginRight: '16px',
  },
}));

const CustomRatingWorkField = ({
  itemName, itemScore, withColor, handleOnChangeScoreWorkFieldItem, readOnly,
  weightPercentage, itemToPersonId,
}) => {
  const classes = useStyles();

  const getResultEvaluation = (value) => {
    switch (true) {
      case value && value < 2:
        return workFieldEvaluationTexts.rejected;
      case value && value >= 2 && value < 3:
        return workFieldEvaluationTexts.conditional;
      case value >= 3:
        return workFieldEvaluationTexts.approved;
      default:
        return workFieldEvaluationTexts.noRatedItems;
    }
  };
  const colorToDisplayScore = (score) => {
    switch (true) {
      case score && score < 2:
        return colors.red;
      case score && score >= 2 && score < 3:
        return colors.orangeLight;
      case score >= 3:
        return colors.green;
      default:
        return colors.blackGrey;
    }
  };

  return (
    <>
      {weightPercentage && (
        <Typography variant="body1" className={classes.labelPercentage}>
          {`${weightPercentage}%`}
        </Typography>
      )}
      <Rating
        name={`${itemName}-${itemToPersonId}`}
        value={itemScore}
        onChange={(event, newValue) => {
          handleOnChangeScoreWorkFieldItem(newValue, itemName, itemToPersonId);
        }}
        readOnly={readOnly}
        emptyIcon={<StarBorderIcon fontSize="inherit" />}
        style={{ color: withColor ? colorToDisplayScore(itemScore) : colors.blackGrey }}
      />
      <Typography variant="body2" style={{ marginLeft: 8, color: colors.grey, width: '92px' }}>
        {getResultEvaluation(itemScore)}
      </Typography>
    </>
  );
};

CustomRatingWorkField.propTypes = {
  itemName: PropTypes.string.isRequired,
  itemToPersonId: PropTypes.number.isRequired,
  itemScore: PropTypes.number,
  handleOnChangeScoreWorkFieldItem: PropTypes.func,
  withColor: PropTypes.bool,
  readOnly: PropTypes.bool,
  weightPercentage: PropTypes.string,
};

CustomRatingWorkField.defaultProps = {
  itemScore: 0,
  withColor: false,
  readOnly: false,
  weightPercentage: '',
  handleOnChangeScoreWorkFieldItem: () => {},
};

export default CustomRatingWorkField;
