import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { GoogleLogin } from '@react-oauth/google';
import userActions from '../../redux/actions/user.actions';

function GoogleButton({ register }) {
  const dispatch = useDispatch();

  function handleLoginSuccess(response) {
    if (response.credential) {
      dispatch(userActions.googleLogin(response.credential));
    }
  }

  function handleLoginFailure(response) {
  }

  return (
    <GoogleLogin
      onSuccess={handleLoginSuccess}
      onError={handleLoginFailure}
      text={register ? 'signup_with' : 'signin_with'}
      type="standard"
      theme="outline"
      size="large"
      width="320px"
      logo_alignment="left"
    />
  );
}

GoogleButton.propTypes = {
  register: PropTypes.bool,
};

GoogleButton.defaultProps = {
  register: false,
};

export default GoogleButton;
