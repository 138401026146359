import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography, Box, DialogContent, makeStyles, Select, MenuItem, CircularProgress,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../../controls/Button';
import { CloseIcon } from '../../../assets/icons';
import { documentActions } from '../../../redux/actions';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '14px',
    fontWeight: 700,
    paddingTop: '25px',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '12px',
  },
  closeIcon: {
    width: '14px',
    height: '13px',
    cursor: 'pointer',
  },
}));

const initialFieldValues = {
  documentId: '',
};

function SetNewDocumentModal({
  isOpen, setIsOpen, processId, personId, deviationId, trainingId, providerId,
  workFieldEvaluationId,
}) {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const process = useSelector((state) => state.process.data);
  const person = useSelector((state) => state.person.data);
  const deviation = useSelector((state) => state.deviation.data);
  const training = useSelector((state) => state.training.data);
  const provider = useSelector((state) => state.provider.data);
  const workFieldEvaluation = useSelector((state) => state.workFieldEvaluation.data);
  const documents = useSelector((state) => state.document.data);
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const chooseText = intl.formatMessage({ id: 'document.choose', defaultMessage: 'Elija el documento que desea agregar, también puede' });
  const createLink = intl.formatMessage({ id: 'document.create', defaultMessage: 'crear un nuevo documento.' });
  const noDocumentsText = intl.formatMessage({ id: 'document.noDocuments', defaultMessage: 'No hay documentos para agregar, puede ' });
  const addDocumentText = intl.formatMessage({ id: 'document.addDocument', defaultMessage: 'Agregar documento' });
  const [values, setValues] = useState(initialFieldValues);
  const { documentId } = values;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    getContentAnchorEl: null,
  };

  useEffect(() => {
    if (processId) { dispatch(documentActions.getAllFromProcess()); }
    if (personId) { dispatch(documentActions.getAllFromPerson()); }
    if (deviationId) { dispatch(documentActions.getAllFromDeviation()); }
    if (trainingId) { dispatch(documentActions.getAllFromTraining()); }
    if (providerId) { dispatch(documentActions.getAllFromProvider()); }
    if (workFieldEvaluationId) { dispatch(documentActions.getAllFromWorkFieldEvaluation()); }
  }, [dispatch, process, person, processId, personId, provider, providerId,
    deviation, deviationId, training, trainingId, workFieldEvaluation, workFieldEvaluationId]);

  function handleSubmit(e) {
    e.preventDefault();
    if (personId) { dispatch(documentActions.linkPerson(person.id, documentId)); }
    if (processId) { dispatch(documentActions.linkProcess(process.id, documentId)); }
    if (deviationId) { dispatch(documentActions.linkDeviation(deviation.id, documentId)); }
    if (trainingId) { dispatch(documentActions.linkTraining(training.id, documentId)); }
    if (providerId) { dispatch(documentActions.linkProvider(provider.id, documentId)); }
    if (workFieldEvaluationId) {
      dispatch(documentActions.linkWorkFieldEvaluation(workFieldEvaluation.id, documentId));
    }
    setIsOpen(!isOpen);
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  }

  function handleClose() {
    return setIsOpen(!isOpen);
  }

  function allDocumentsLinked() {
    let linkedDocuments = [];
    if (processId) { linkedDocuments = process.documents; }
    if (personId) { linkedDocuments = person.documents; }
    if (deviationId) { linkedDocuments = deviation.documents || []; }
    if (trainingId) { linkedDocuments = training.documents || []; }
    if (providerId) { linkedDocuments = provider.documents || []; }
    if (workFieldEvaluationId) { linkedDocuments = workFieldEvaluation.documents || []; }

    const isLinkedToProcess = (document) => linkedDocuments.some(
      (linkedDocument) => linkedDocument.id === document.id,
    );
    const allLinked = documents.every(isLinkedToProcess);
    return allLinked;
  }

  const showDocumentsNotLinked = (entity) => {
    const documentsNotLinked = documents.map((document) => {
      if (entity.documents && !entity.documents.some(
        (linkedDocument) => linkedDocument.id === document.id,
      )) {
        return (
          <MenuItem key={document.id} value={document.id}>{document.title}</MenuItem>
        );
      }
      return null;
    });
    return documentsNotLinked;
  };

  return (

    <DialogContent>
      <Box display="flex" justifyContent="flex-end">
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </Box>

      <Typography align="center" className={classes.title}>
        <FormattedMessage id="document.add" defaultMessage="AGREGAR DOCUMENTO" />
      </Typography>

      <Box pt={2} align="center">
        {!documents && <CircularProgress />}

        {documents && documents.length > 0 && !(allDocumentsLinked()) && (
          <>
            <Box mb={3} pl={5} pr={5} ml={5} mr={5} align="center">
              <Typography className={classes.subtitle} align="center" display="inline">
                {' '}
                {chooseText}
                {' '}
              </Typography>

              {processId && (
              <Link to={`/documents/new?entity=process&entityId=${processId}`} style={{ textDecoration: 'none' }}>
                <Typography className={classes.subtitle} align="center" display="inline" style={{ color: '#F16C24' }}>
                  {' '}
                  {createLink}
                  {' '}
                </Typography>
              </Link>
              )}

              {personId && (
              <Link to={`/documents/new?entity=person&entityId=${personId}`} style={{ textDecoration: 'none' }}>
                <Typography className={classes.subtitle} align="center" display="inline" style={{ color: '#F16C24' }}>
                  {' '}
                  {createLink}
                  {' '}
                </Typography>
              </Link>
              )}

              {deviationId && (
              <Link to={`/documents/new?entity=deviation&entityId=${deviationId}`} style={{ textDecoration: 'none' }}>
                <Typography className={classes.subtitle} align="center" display="inline" style={{ color: '#F16C24' }}>
                  {' '}
                  {createLink}
                  {' '}
                </Typography>
              </Link>
              )}

              {trainingId && (
              <Link to={`/documents/new?entity=training&entityId=${trainingId}`} style={{ textDecoration: 'none' }}>
                <Typography className={classes.subtitle} align="center" display="inline" style={{ color: '#F16C24' }}>
                  {' '}
                  {createLink}
                  {' '}
                </Typography>
              </Link>
              )}

              {providerId && (
              <Link to={`/documents/new?entity=provider&entityId=${providerId}`} style={{ textDecoration: 'none' }}>
                <Typography className={classes.subtitle} align="center" display="inline" style={{ color: '#F16C24' }}>
                  {' '}
                  {createLink}
                  {' '}
                </Typography>
              </Link>
              )}

              {
                workFieldEvaluationId && (
                  <Link to={`/documents/new?entity=workFieldEvaluation&entityId=${workFieldEvaluationId}`} style={{ textDecoration: 'none' }}>
                    <Typography className={classes.subtitle} align="center" display="inline" style={{ color: '#F16C24' }}>
                      {' '}
                      {createLink}
                      {' '}
                    </Typography>
                  </Link>
                )
              }

            </Box>
            <Select
              id="documentId"
              name="documentId"
              value={documentId}
              displayEmpty
              onChange={handleChange}
              MenuProps={MenuProps}
              style={{ width: '100%' }}
            >
              {(processId || personId || deviationId || trainingId || providerId
                || workFieldEvaluationId) && (
                <MenuItem disabled value="">
                  <em>{addDocumentText}</em>
                </MenuItem>
              ) }
              { processId && showDocumentsNotLinked(process)}
              { personId && showDocumentsNotLinked(person)}
              { deviationId && showDocumentsNotLinked(deviation)}
              { trainingId && showDocumentsNotLinked(training)}
              { providerId && showDocumentsNotLinked(provider)}
              { workFieldEvaluationId && showDocumentsNotLinked(workFieldEvaluation)}
            </Select>
          </>
        )}
        { documents && (!(documents.length > 0) || allDocumentsLinked()) && (
          <Box mt={5} pl={5} pr={5} ml={5} mr={5} align="center">
            <Typography className={classes.subtitle} align="center" display="inline">
              {' '}
              {noDocumentsText}
              {' '}
            </Typography>

            {processId && (
            <Link to={`/documents/new?entity=process&entityId=${processId}`} style={{ textDecoration: 'none' }}>
              <Typography className={classes.subtitle} align="center" display="inline" style={{ color: '#F16C24' }}>
                {' '}
                {createLink}
                {' '}
              </Typography>
            </Link>
            )}

            {personId && (
            <Link to={`/documents/new?entity=person&entityId=${personId}`} style={{ textDecoration: 'none' }}>
              <Typography className={classes.subtitle} align="center" display="inline" style={{ color: '#F16C24' }}>
                {' '}
                {createLink}
                {' '}
              </Typography>
            </Link>
            )}

            {deviationId && (
            <Link to={`/documents/new?entity=deviation&entityId=${deviationId}`} style={{ textDecoration: 'none' }}>
              <Typography className={classes.subtitle} align="center" display="inline" style={{ color: '#F16C24' }}>
                {' '}
                {createLink}
                {' '}
              </Typography>
            </Link>
            )}

            {trainingId && (
            <Link to={`/documents/new?entity=training&entityId=${trainingId}`} style={{ textDecoration: 'none' }}>
              <Typography className={classes.subtitle} align="center" display="inline" style={{ color: '#F16C24' }}>
                {' '}
                {createLink}
                {' '}
              </Typography>
            </Link>
            )}

            {providerId && (
            <Link to={`/documents/new?entity=provider&entityId=${providerId}`} style={{ textDecoration: 'none' }}>
              <Typography className={classes.subtitle} align="center" display="inline" style={{ color: '#F16C24' }}>
                {' '}
                {createLink}
                {' '}
              </Typography>
            </Link>
            )}

            {
              workFieldEvaluationId && (
                <Link to={`/documents/new?entity=workFieldEvaluation&entityId=${workFieldEvaluationId}`} style={{ textDecoration: 'none' }}>
                  <Typography className={classes.subtitle} align="center" display="inline" style={{ color: '#F16C24' }}>
                    {' '}
                    {createLink}
                    {' '}
                  </Typography>
                </Link>
              )
            }
          </Box>
        )}

      </Box>
      <Box display="flex" alignItems="right" justifyContent="flex-end" mt={5}>
        <Button
          onClick={handleClose}
          variant="outlined"
          text={<FormattedMessage id="app.cancel" defaultMessage="Cancelar" />}
        />
        <Button
          onClick={handleSubmit}
          type="submit"
          disabled={!documentId || !isAdmin}
          text={<FormattedMessage id="app.add" defaultMessage="Agregar" />}
        />
      </Box>
    </DialogContent>

  );
}

SetNewDocumentModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  processId: PropTypes.string,
  personId: PropTypes.string,
  deviationId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  trainingId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  providerId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  workFieldEvaluationId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

SetNewDocumentModal.defaultProps = {
  isOpen: false,
  setIsOpen: null,
  processId: '',
  personId: '',
  deviationId: '',
  trainingId: '',
  providerId: '',
  workFieldEvaluationId: '',
};

export default SetNewDocumentModal;
