import axios from '../axios/axios';
import handler from './handler';
import { authHeader } from '../helpers';

function get(url) {
  const headers = authHeader();
  return axios.get(url, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}
const dataTableService = { get };

export default dataTableService;
