import React from 'react';
import { FormattedMessage } from 'react-intl';
import dispatch from './dispatch';
import { store } from './store';
import { userActions, modalAlertActions } from '../redux/actions';

const resendingEmail = (email) => userActions.requestResendEmail(email);

const message = {
  title: <FormattedMessage id="app.resendEmailTitle" defaultMessage="Confirmación de email" />,
  message: <FormattedMessage
    id="app.resendEmailMessage"
    defaultMessage="<p>El correo electrónico aún no está confirmado. {br} ¿Desea que se le vuelva a enviar el link de confirmación de registro a su casilla?</p>"
    values={{
      p: (...chunks) => <p>{chunks}</p>,
      br: <br />,
    }}
  />,
  buttonLeft: <FormattedMessage id="app.resendLink" defaultMessage="Reenviar link" />,
  button: <FormattedMessage id="app.close" defaultMessage="Cerrar" />,
  linkTo: '/',
};

function proposeResendEmail() {
  const { authentication } = store.getState();
  if (authentication.user) {
    return dispatch(modalAlertActions.customInfo(
      message, resendingEmail(authentication.user.username),
    ));
  }
  return null;
}

const roles = ['Admin', 'User'];

const userHelper = {
  roles,
  proposeResendEmail,
};
export default userHelper;
