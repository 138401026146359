import React from 'react';
import { FormattedMessage } from 'react-intl';

const affiliateText = {
  nameLabel: <FormattedMessage id="app.name" defaultMessage="Nombre" />,
  emailLabel: <FormattedMessage id="user.email" defaultMessage="Email" />,
  title: <FormattedMessage id="affiliates.title" defaultMessage="Afiliados" />,
  addButtonText: <FormattedMessage id="affiliates.addButton" defaultMessage="Crear Afiliado" />,
  isRequired: <FormattedMessage id="affiliates.isRequired" defaultMessage="Este campo es requerido para continuar" />,
  backText: <FormattedMessage id="app.back" defaultMessage="Volver" />,
  createText: <FormattedMessage id="app.create" defaultMessage="Crear" />,
  administratorPanel: <FormattedMessage id="affiliates.administratorPanel" defaultMessage="Panel de administrador del afiliado" />,
  discountsTitle: <FormattedMessage id="affiliates.discountsTitle" defaultMessage="Información de descuento y comisión" />,
  generalInformation: <FormattedMessage id="affiliates.generalInformation" defaultMessage="Información general" />,
  referralInformationTitle: <FormattedMessage id="affiliates.referralInformationTitle" defaultMessage="Información de referidos" />,
  affiliateUrlForReferral: <FormattedMessage id="affiliates.affiliateUrlForReferral" defaultMessage="URL para referidos" />,
  discountsAndCommissionTitle: <FormattedMessage id="affiliates.discountsAndCommissionTitle" defaultMessage="Descuento de referidos y comisión de afiliado" />,
  paymentCommission: <FormattedMessage id="affiliates.paymentCommission" defaultMessage="Comisión por pago" />,
  resetPassword: <FormattedMessage id="affiliates.resetPassword" defaultMessage="Reiniciar contraseña" />,
  resetPasswordSubtext: <FormattedMessage id="affiliates.resetPasswordSubtext" defaultMessage="Se creará una nueva contraseña de manera automática." />,
  discountCoupon: <FormattedMessage id="affiliates.discountCoupon" defaultMessage="Descuento a referidos" />,
  referalSystemEnabled: <FormattedMessage id="affiliates.referalSystemEnabled" defaultMessage="Sistema de referidos" />,
  activated: <FormattedMessage id="affiliates.activated" defaultMessage="Activado" />,
  disabled: <FormattedMessage id="affiliates.disabled" defaultMessage="Desactivado" />,
  successfulSave: <FormattedMessage id="affiliates.successfulSave" defaultMessage="Se ha creado con éxito" />,
  successfulResetPassword: <FormattedMessage id="affiliates.successfulResetPassword" defaultMessage="Se le envió un link para restablecer su contraseña a su correo" />,
  copiedToClipboard: <FormattedMessage id="affiliates.copiedToClipboard" defaultMessage="URL copiada al portapapeles" />,
  copy: <FormattedMessage id="affiliates.copy" defaultMessage="Copiar" />,
  onlyFirst3Months: <FormattedMessage id="affiliates.onlyFirst3Months" defaultMessage="Precio especial solo para los primeros 3 meses" />,
  first3Months: <FormattedMessage id="affiliates.first3Months" defaultMessage="primeros 3 meses" />,
  firstYear: <FormattedMessage id="affiliates.firstYear" defaultMessage="primer año" />,
  includesFreeInitialMonth: <FormattedMessage id="affiliates.includesFreeInitialMonth" defaultMessage="30 días gratis" />,
};

export default affiliateText;
