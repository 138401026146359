import { userConstants } from '../../constants';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case userConstants.GOOGLE_LOGIN_REQUEST:
      return {
        loggingIn: true,
        googleToken: action.token,
      };
    case userConstants.COMPANY_REQUEST:
      return {
        logginIn: true,
        company: action.company,
      };
    case userConstants.LOGIN_SUCCESS:
    case userConstants.GOOGLE_LOGIN_SUCCESS:
    case userConstants.COMPANY_SUCCESS:
    case userConstants.UPDATE_MUST_CHANGE_PASSWORD_SUCCESS:
    case userConstants.UPDATE_FIRST_LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
    case userConstants.GOOGLE_LOGIN_FAILURE:
    case userConstants.COMPANY_FAILURE:
      return {};
    case userConstants.UPDATE_TUTORIAL_COMPLETE:
      return { ...state, user: { ...state.user, isFirstLogin: false } }
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}
