import { deviationConstants } from '../../constants';

const empty = {
  deviations: [],
};

function deviation(state = empty, action) {
  switch (action.type) {
    case deviationConstants.request.CREATE_REQUEST:
      return { creating: true };
    case deviationConstants.request.CREATE_SUCCESS:
      return { };
    case deviationConstants.request.CREATE_FAILURE:
      return { error: true };

    case deviationConstants.request.GET_ALL_REQUEST:
      return { requesting: true, deviations: state.deviations };
    case deviationConstants.request.GET_ALL_SUCCESS:
      return { deviations: action.deviations, getAllSuccess: true };
    case deviationConstants.request.GET_ALL_FAILURE:
      return { error: true, deviations: state.deviations };

    case deviationConstants.request.GET_REQUEST:
      return { requesting: true };
    case deviationConstants.request.GET_SUCCESS:
      return { data: action.deviation };
    case deviationConstants.request.GET_FAILURE:
      return { error: true };

    case deviationConstants.request.UPDATE_REQUEST:
      return { updating: true, deviations: state.deviations };
    case deviationConstants.request.UPDATE_SUCCESS:
      return { deviations: state.deviations, updated: true, getAllSuccess: true };
    case deviationConstants.request.UPDATE_FAILURE:
      return { error: true, deviations: state.deviations };

    case deviationConstants.request.UPDATE_FORM_REQUEST:
      return { updating: true, data: state.data || { } };
    case deviationConstants.request.UPDATE_FORM_SUCCESS:
      return { data: state.data };
    case deviationConstants.request.UPDATE_FORM_FAILURE:
      return { error: true, data: state.data || { } };

    case deviationConstants.request.ARCHIVE_REQUEST:
      return { archiving: true, data: state.data || { } };
    case deviationConstants.request.ARCHIVE_SUCCESS:
      return { data: state.data };
    case deviationConstants.request.ARCHIVE_FAILURE:
      return { error: true, data: state.data || { } };

    case deviationConstants.request.CREATE_BULK_REQUEST:
      return { creating: true };
    case deviationConstants.request.CREATE_BULK_SUCCESS:
      return { bulkCreationPreviewResult: action.bulkCreationPreviewResult };
    case deviationConstants.request.CREATE_BULK_FAILURE:
      return { error: action.error };

    case deviationConstants.request.CONFIRM_CREATE_BULK_REQUEST:
      return { ...state, confirming: true };
    case deviationConstants.request.CONFIRM_CREATE_BULK_SUCCESS:
      return { bulkCreationPreviewResult: null, noLinkedProcesses: true };
    case deviationConstants.request.CONFIRM_CREATE_BULK_FAILURE:
      return { error: action.error };

    case deviationConstants.request.DELETE_REQUEST:
      return { data: state.data, deleting: true };
    case deviationConstants.request.DELETE_SUCCESS:
      return { deleted: true };
    case deviationConstants.request.DELETE_FAILURE:
      return { data: state.data, error: true };

    default:
      return state;
  }
}

export default deviation;
