import React from 'react';
import { SvgIcon } from '@material-ui/core';

function AffiliatesIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 76 76">
      <path d="M41.75 18.375V12H53L49.25 6.4125L53 0.75H34.25V18.375L0.5 42V79.5H26.75V60.75L38.1125 53.25L49.25 60.75V79.5H75.5V42L41.75 18.375Z" />
    </SvgIcon>
  );
}

export default AffiliatesIcon;
