import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Breadcrumbs, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../DataTable/DataTable';
import PageLayout from '../../PageLayout';
import Button from '../../controls/Button';
import { outlineActions } from '../../../redux/actions';
import { history } from '../../../helpers';

const useStyles = makeStyles(() => ({
  textIconButton: {
    textDecoration: 'none',
    padding: '0px',
    marginRight: '16px',
  },
}));

function OutlinePage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'outline.outlinesText', defaultMessage: 'Perfiles' });
  const user = useSelector((state) => state.authentication.user);
  const isAdmin = user.roles.includes('Admin');
  const initialFieldValues = { outlineId: '', deleteOutlineDialog: false };
  const [values, setValues] = useState(initialFieldValues);
  const { outlineId, deleteOutlineDialog } = values;
  const deleteOutlineTitle = intl.formatMessage({ id: 'outline.delete.title', defaultMessage: 'Eliminar perfil' });
  const editOutlineTitle = intl.formatMessage({ id: 'outline.edit.title', defaultMessage: 'Modificar perfil' });
  const deleteOutlineText = intl.formatMessage({ id: 'outline.deleteOutlineText', defaultMessage: '¿Está seguro que quiere eliminar el perfil?' });
  const deletingOutline = useSelector((state) => state.outline.deleting);
  const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const deletePersonButtonText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'Eliminar' });
  const addButtonText = intl.formatMessage({ id: 'outline.createOutline', defaultMessage: 'Crear perfil' });
  const noRequirementsOutline = intl.formatMessage({ id: 'outline.noRequirements', defaultMessage: 'El perfil no posee requerimientos' });

  function handleDialogOpen(dialogName, id = null) {
    if (id) {
      setValues({
        outlineId: id,
        [dialogName]: true,
      });
    } else {
      setValues({
        ...values,
        [dialogName]: true,
      });
    }
  }

  function actionsButtons(link, id) {
    if (!isAdmin) return null;
    return (
      <>
        <IconButton onClick={() => { history.push(link); }} className={classes.textIconButton}>
          <Tooltip title={editOutlineTitle}>
            <CreateIcon style={{ width: 16, height: 16, color: '#F79A56' }} />
          </Tooltip>
        </IconButton>

        <IconButton onClick={() => { handleDialogOpen('deleteOutlineDialog', id); }} lassName={classes.textIconButton}>
          <Tooltip title={deleteOutlineTitle}>
            <DeleteRoundedIcon style={{ width: 16, height: 16, color: '#727272' }} />
          </Tooltip>
        </IconButton>
      </>
    );
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: intl.formatMessage({ id: 'outline.name', defaultMessage: 'Nombre' }),
    },
    {
      name: 'requiredKnowledges',
      label: intl.formatMessage({ id: 'outline.requiredKnowledges', defaultMessage: 'Conocimientos requeridos' }),
      options: {
        customBodyRender: (requiredKnowledges) => {
          const parsedRequiredKnowledges = JSON.parse(requiredKnowledges);
          if (parsedRequiredKnowledges.length > 0) {
            const requiredKnowledgesString = parsedRequiredKnowledges.map((requiredKnowledge) => (` ${requiredKnowledge.name}`));
            return requiredKnowledgesString.join();
          }
          return noRequirementsOutline;
        },
        customDownloadBodyRender: (requiredKnowledges) => {
          const parsedRequiredKnowledges = JSON.parse(requiredKnowledges);
          if (parsedRequiredKnowledges.length > 0) {
            const requiredKnowledgesString = parsedRequiredKnowledges.map((requiredKnowledge) => (` ${requiredKnowledge.name}`));
            return requiredKnowledgesString.join();
          }
          return noRequirementsOutline;
        },
      },
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, meta) => {
          const id = meta.rowData[0];
          const link = `/outlines/${id}/edit`;
          return actionsButtons(link, id);
        },
      },
    },
  ];

  const keys = ['id', 'name', 'requiredKnowledges'];
  const fetchUrl = `companies/${user.company.id}/outline/`;
  const itemUrl = 'outlines/';

  function handleDialogClose(dialogName) {
    setValues({
      ...values,
      [dialogName]: false,
    });
  }

  function deleteOutline(id) {
    dispatch(outlineActions.deleteOutline(id));
    handleDialogClose('deleteOutlineDialog');
  }

  function OutlinePageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/outlines" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{title}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  function ButtonGroup() {
    if (user.company.roles.includes('Admin')) {
      return (
        <Button
          text={addButtonText}
          component={Link}
          to="outlines/new"
          style={{ fontSize: '11px', fontWeight: 600 }}
        />
      );
    }
    return null;
  }

  return (
    <PageLayout
      title={title}
      breadCrumbs={<OutlinePageBreadcrumbs />}
      buttonGroup={<ButtonGroup />}
    >
      <DataTable
        title={title}
        fetchUrl={fetchUrl}
        itemUrl={itemUrl}
        keys={keys}
        columns={columns}
        addButtonText={addButtonText}
        addButtonTo="outlines/new"
      />
      <Dialog open={deleteOutlineDialog} onClose={() => { handleDialogClose('deleteOutlineDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{deleteOutlineTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {deleteOutlineText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button text={cancelButtonText} onClick={() => { handleDialogClose('deleteOutlineDialog'); }} />
          <Button
            className={classes.menuButton}
            text={deletePersonButtonText}
            color="secondary"
            loading={deletingOutline}
            onClick={() => { deleteOutline(outlineId); }}
          />
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
}

export default OutlinePage;
