import { tutorialConstants } from '../../constants';

let step = JSON.parse(localStorage.getItem('step'));
step = parseInt(step);
const initialState = step && typeof step === 'number' && (step > 0 && step <= 8)
? { step } : {};

export default function tutorial(state = initialState, action) {
	switch (action.type) {
		case tutorialConstants.SET_TUTORIAL_STEP:
			return { step: action.step };
		case tutorialConstants.SET_STEPS_AS_COMPLETED:
			return {};
		default:
			return state
	}
}
