import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography, Box, DialogContent, makeStyles, TextField, Dialog, IconButton, MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '../../controls/Button';
import { CloseIcon } from '../../../assets/icons';
import { objectiveTexts } from '../../../constants';
import { objectiveActions } from '../../../redux/actions';
import { objectiveHelper } from '../../../helpers';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '14px',
    fontWeight: 700,
    paddingTop: '25px',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '12px',
  },
  closeIcon: {
    width: '14px',
    height: '13px',
    cursor: 'pointer',
  },
  accumulatedMeasure: {
    fontWeight: 400,
    fontSize: '10px',
  },
  menuItem: {
    fontSize: '12px',
  },
}));

function SetNewMeasureModal({
  openNewMeasureModal, toggleNewMeasureModal, measure, isBooleanOperator,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const objective = useSelector((state) => state.objective.data);
  const updatedObjective = useSelector((state) => state.objective.updated);
  const updatingObjectives = useSelector((state) => state.objective.updatingMeasure);
  const [measuresList, setMeasuresList] = useState({ ...objective.measure });
  const [accumulativeObjective, setAccumulativeObjective] = useState(0);
  const invalidData = [null, undefined, NaN, ''];

  useEffect(() => {
    if (measuresList) {
      setAccumulativeObjective(objectiveHelper.calculateAccObjective(measuresList));
    }
  }, [measuresList]);

  useEffect(() => {
    if (updatedObjective) toggleNewMeasureModal(false);
  }, [updatedObjective, toggleNewMeasureModal]);

  function handleSubmit(e) {
    e.preventDefault();
    const processId = objective.processes.map((processF) => processF.id);
    dispatch(objectiveActions.updateMeasure(
      objective.id,
      { ...objective, processes: processId, measure: measuresList },
      objectiveTexts.successfulUpdate,
    ));
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setMeasuresList((prevMeasuresList) => ({
      ...prevMeasuresList,
      [name]: Number(value),
    }));
  }

  function handleClearValue(e, name) {
    e.stopPropagation();
    setMeasuresList((prevMeasuresList) => ({
      ...prevMeasuresList,
      [name]: null,
    }));
  }

  const ClearValueButton = () => (
    <IconButton
      style={{ padding: 5 }}
      onClick={(e) => handleClearValue(e, measure)}
    >
      <ClearIcon />
    </IconButton>
  );

  return (
    <Dialog
      open={openNewMeasureModal}
      onClose={() => toggleNewMeasureModal(false)}
      aria-labelledby="form-dialog-title"
    >

      <DialogContent>
        <Box display="flex" justifyContent="flex-end">
          <CloseIcon className={classes.closeIcon} onClick={() => toggleNewMeasureModal(false)} />
        </Box>

        <Typography align="center" className={classes.title}>
          {objectiveTexts.addMeasure}
        </Typography>

        <Box mb={3} px={5} mx={6} align="center">
          <Typography className={classes.subtitle} align="center" display="inline">
            {objectiveTexts.inputMeasure}
          </Typography>
        </Box>
        <Box px={12} align="left">
          {isBooleanOperator
            ? (
              <TextField
                fullWidth
                id="title"
                label={objectiveTexts[measure]}
                name={measure}
                onChange={handleChange}
                value={invalidData.includes(measuresList[measure]) ? '' : measuresList[measure]}
                margin="normal"
                size="small"
                select
                SelectProps={{ IconComponent: () => null }}
                InputProps={{ endAdornment: (<ClearValueButton />) }}
              >
                <MenuItem disabled className={classes.menuItem} value="">
                  <em>{objectiveTexts.booleanOperator}</em>
                </MenuItem>
                <MenuItem className={classes.menuItem} value={1}>
                  {objectiveTexts.complies}
                </MenuItem>
                <MenuItem className={classes.menuItem} value={0}>
                  {objectiveTexts.doesNotComply}
                </MenuItem>
              </TextField>
            )
            : (
              <TextField
                fullWidth
                type="number"
                id="title"
                label={objectiveTexts[measure]}
                margin="normal"
                name={measure}
                onChange={handleChange}
                size="small"
                value={invalidData.includes(measuresList[measure]) ? '' : measuresList[measure]}
                InputProps={{ endAdornment: (<ClearValueButton />) }}
              />
            )}

          {objective.isAccumulativeObjective && (
            <Typography className={classes.accumulatedMeasure} align="left" display="inline">
              {objectiveTexts.accumulativeTotal}
              {': '}
              <b>{accumulativeObjective}</b>
            </Typography>
          )}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={5}>
          <Button
            onClick={() => toggleNewMeasureModal(false)}
            variant="outlined"
            text={objectiveTexts.cancel}
          />
          <Button
            onClick={handleSubmit}
            type="submit"
            disabled={!objective}
            loading={updatingObjectives}
            text={objectiveTexts.saveText}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

SetNewMeasureModal.propTypes = {
  openNewMeasureModal: PropTypes.bool,
  toggleNewMeasureModal: PropTypes.func,
  measure: PropTypes.string,
  isBooleanOperator: PropTypes.bool,
};

SetNewMeasureModal.defaultProps = {
  openNewMeasureModal: false,
  toggleNewMeasureModal: null,
  measure: null,
  isBooleanOperator: false,
};

export default SetNewMeasureModal;
