import React from 'react';

const countryFlags = {
  Andorra: (
    <img
      src="https://flagcdn.com/w20/ad.png"
      srcSet="https://flagcdn.com/w40/ad.png 2x"
      width="20"
      alt="Andorra"
    />
  ),
  Argentina: (
    <img
      src="https://flagcdn.com/w20/ar.png"
      srcSet="https://flagcdn.com/w40/ar.png 2x"
      width="20"
      alt="Argentina"
    />
  ),
  Australia: (
    <img
      src="https://flagcdn.com/w20/au.png"
      srcSet="https://flagcdn.com/w40/au.png 2x"
      width="20"
      alt="Australia"
    />
  ),
  Austria: (
    <img
      src="https://flagcdn.com/w20/at.png"
      srcSet="https://flagcdn.com/w40/at.png 2x"
      width="20"
      alt="Austria"
    />
  ),
  Belgium: (
    <img
      src="https://flagcdn.com/w20/be.png"
      srcSet="https://flagcdn.com/w40/be.png 2x"
      width="20"
      alt="Belgium"
    />
  ),
  Bolivia: (
    <img
      src="https://flagcdn.com/w20/bo.png"
      srcSet="https://flagcdn.com/w40/bo.png 2x"
      width="20"
      alt="Bolivia"
    />
  ),
  Brazil: (
    <img
      src="https://flagcdn.com/w20/br.png"
      srcSet="https://flagcdn.com/w40/br.png 2x"
      width="20"
      alt="Brazil"
    />
  ),
  Bulgaria: (
    <img
      src="https://flagcdn.com/w20/bg.png"
      srcSet="https://flagcdn.com/w40/bg.png 2x"
      width="20"
      alt="Bulgaria"
    />
  ),
  Canada: (
    <img
      src="https://flagcdn.com/w20/ca.png"
      srcSet="https://flagcdn.com/w40/ca.png 2x"
      width="20"
      alt="Canada"
    />
  ),
  Chile: (
    <img
      src="https://flagcdn.com/w20/cl.png"
      srcSet="https://flagcdn.com/w40/cl.png 2x"
      width="20"
      alt="Chile"
    />
  ),
  China: (
    <img
      src="https://flagcdn.com/w20/cn.png"
      srcSet="https://flagcdn.com/w40/cn.png 2x"
      width="20"
      alt="China"
    />
  ),
  Colombia: (
    <img
      src="https://flagcdn.com/w20/co.png"
      srcSet="https://flagcdn.com/w40/co.png 2x"
      width="20"
      alt="Colombia"
    />
  ),
  'Costa Rica': (
    <img
      src="https://flagcdn.com/w20/cr.png"
      srcSet="https://flagcdn.com/w40/cr.png 2x"
      width="20"
      alt="Costa Rica"
    />
  ),
  Croatia: (
    <img
      src="https://flagcdn.com/w20/hr.png"
      srcSet="https://flagcdn.com/w40/hr.png 2x"
      width="20"
      alt="Croatia"
    />
  ),
  Cyprus: (
    <img
      src="https://flagcdn.com/w20/cy.png"
      srcSet="https://flagcdn.com/w40/cy.png 2x"
      width="20"
      alt="Cyprus"
    />
  ),
  'Czech Republic': (
    <img
      src="https://flagcdn.com/w20/cz.png"
      srcSet="https://flagcdn.com/w40/cz.png 2x"
      width="20"
      alt="Czech Republic"
    />
  ),
  Denmark: (
    <img
      src="https://flagcdn.com/w20/dk.png"
      srcSet="https://flagcdn.com/w40/dk.png 2x"
      width="20"
      alt="Denmark"
    />
  ),
  'Dominican Republic': (
    <img
      src="https://flagcdn.com/w20/do.png"
      srcSet="https://flagcdn.com/w40/do.png 2x"
      width="20"
      alt="Dominican Republic"
    />
  ),
  Ecuador: (
    <img
      src="https://flagcdn.com/w20/ec.png"
      srcSet="https://flagcdn.com/w40/ec.png 2x"
      width="20"
      alt="Ecuador"
    />
  ),
  Egypt: (
    <img
      src="https://flagcdn.com/w20/eg.png"
      srcSet="https://flagcdn.com/w40/eg.png 2x"
      width="20"
      alt="Egypt"
    />
  ),
  'El Salvador': (
    <img
      src="https://flagcdn.com/w20/sv.png"
      srcSet="https://flagcdn.com/w40/sv.png 2x"
      width="20"
      alt="El Salvador"
    />
  ),
  Estonia: (
    <img
      src="https://flagcdn.com/w20/ee.png"
      srcSet="https://flagcdn.com/w40/ee.png 2x"
      width="20"
      alt="Estonia"
    />
  ),
  EU: (
    <img
      src="https://flagcdn.com/w20/eu.png"
      srcSet="https://flagcdn.com/w40/eu.png 2x"
      width="20"
      alt="EU"
    />
  ),
  Finland: (
    <img
      src="https://flagcdn.com/w20/fi.png"
      srcSet="https://flagcdn.com/w40/fi.png 2x"
      width="20"
      alt="Finland"
    />
  ),
  France: (
    <img
      src="https://flagcdn.com/w20/fr.png"
      srcSet="https://flagcdn.com/w40/fr.png 2x"
      width="20"
      alt="France"
    />
  ),
  Georgia: (
    <img
      src="https://flagcdn.com/w20/ge.png"
      srcSet="https://flagcdn.com/w40/ge.png 2x"
      width="20"
      alt="Georgia"
    />
  ),
  Germany: (
    <img
      src="https://flagcdn.com/w20/de.png"
      srcSet="https://flagcdn.com/w40/de.png 2x"
      width="20"
      alt="Germany"
    />
  ),
  Greece: (
    <img
      src="https://flagcdn.com/w20/gr.png"
      srcSet="https://flagcdn.com/w40/gr.png 2x"
      width="20"
      alt="Greece"
    />
  ),
  'Hong Kong': (
    <img
      src="https://flagcdn.com/w20/hk.png"
      srcSet="https://flagcdn.com/w40/hk.png 2x"
      width="20"
      alt="Hong Kong"
    />
  ),
  Hungary: (
    <img
      src="https://flagcdn.com/w20/hu.png"
      srcSet="https://flagcdn.com/w40/hu.png 2x"
      width="20"
      alt="Hungary"
    />
  ),
  Iceland: (
    <img
      src="https://flagcdn.com/w20/is.png"
      srcSet="https://flagcdn.com/w40/is.png 2x"
      width="20"
      alt="Iceland"
    />
  ),
  India: (
    <img
      src="https://flagcdn.com/w20/in.png"
      srcSet="https://flagcdn.com/w40/in.png 2x"
      width="20"
      alt="India"
    />
  ),
  Indonesia: (
    <img
      src="https://flagcdn.com/w20/id.png"
      srcSet="https://flagcdn.com/w40/id.png 2x"
      width="20"
      alt="Indonesia"
    />
  ),
  Ireland: (
    <img
      src="https://flagcdn.com/w20/ie.png"
      srcSet="https://flagcdn.com/w40/ie.png 2x"
      width="20"
      alt="Ireland"
    />
  ),
  Israel: (
    <img
      src="https://flagcdn.com/w20/il.png"
      srcSet="https://flagcdn.com/w40/il.png 2x"
      width="20"
      alt="Israel"
    />
  ),
  Italy: (
    <img
      src="https://flagcdn.com/w20/it.png"
      srcSet="https://flagcdn.com/w40/it.png 2x"
      width="20"
      alt="Italy"
    />
  ),
  Japan: (
    <img
      src="https://flagcdn.com/w20/jp.png"
      srcSet="https://flagcdn.com/w40/jp.png 2x"
      width="20"
      alt="Japan"
    />
  ),
  Kenya: (
    <img
      src="https://flagcdn.com/w20/ke.png"
      srcSet="https://flagcdn.com/w40/ke.png 2x"
      width="20"
      alt="Kenya"
    />
  ),
  Latvia: (
    <img
      src="https://flagcdn.com/w20/lv.png"
      srcSet="https://flagcdn.com/w40/lv.png 2x"
      width="20"
      alt="Latvia"
    />
  ),
  Liechtenstein: (
    <img
      src="https://flagcdn.com/w20/li.png"
      srcSet="https://flagcdn.com/w40/li.png 2x"
      width="20"
      alt="Liechtenstein"
    />
  ),
  Lithuania: (
    <img
      src="https://flagcdn.com/w20/lt.png"
      srcSet="https://flagcdn.com/w40/lt.png 2x"
      width="20"
      alt="Lithuania"
    />
  ),
  Luxembourg: (
    <img
      src="https://flagcdn.com/w20/lu.png"
      srcSet="https://flagcdn.com/w40/lu.png 2x"
      width="20"
      alt="Luxembourg"
    />
  ),
  Malaysia: (
    <img
      src="https://flagcdn.com/w20/my.png"
      srcSet="https://flagcdn.com/w40/my.png 2x"
      width="20"
      alt="Malaysia"
    />
  ),
  Malta: (
    <img
      src="https://flagcdn.com/w20/mt.png"
      srcSet="https://flagcdn.com/w40/mt.png 2x"
      width="20"
      alt="Malta"
    />
  ),
  Mexico: (
    <img
      src="https://flagcdn.com/w20/mx.png"
      srcSet="https://flagcdn.com/w40/mx.png 2x"
      width="20"
      alt="Mexico"
    />
  ),
  Netherlands: (
    <img
      src="https://flagcdn.com/w20/nl.png"
      srcSet="https://flagcdn.com/w40/nl.png 2x"
      width="20"
      alt="Netherlands"
    />
  ),
  'New Zealand': (
    <img
      src="https://flagcdn.com/w20/nz.png"
      srcSet="https://flagcdn.com/w40/nz.png 2x"
      width="20"
      alt="New Zealand"
    />
  ),
  Norway: (
    <img
      src="https://flagcdn.com/w20/no.png"
      srcSet="https://flagcdn.com/w40/no.png 2x"
      width="20"
      alt="Norway"
    />
  ),
  Peru: (
    <img
      src="https://flagcdn.com/w20/pe.png"
      srcSet="https://flagcdn.com/w40/pe.png 2x"
      width="20"
      alt="Peru"
    />
  ),
  Philippines: (
    <img
      src="https://flagcdn.com/w20/ph.png"
      srcSet="https://flagcdn.com/w40/ph.png 2x"
      width="20"
      alt="Philippines"
    />
  ),
  Poland: (
    <img
      src="https://flagcdn.com/w20/pl.png"
      srcSet="https://flagcdn.com/w40/pl.png 2x"
      width="20"
      alt="Poland"
    />
  ),
  Portugal: (
    <img
      src="https://flagcdn.com/w20/pt.png"
      srcSet="https://flagcdn.com/w40/pt.png 2x"
      width="20"
      alt="Portugal"
    />
  ),
  Romania: (
    <img
      src="https://flagcdn.com/w20/ro.png"
      srcSet="https://flagcdn.com/w40/ro.png 2x"
      width="20"
      alt="Romania"
    />
  ),
  Russia: (
    <img
      src="https://flagcdn.com/w20/ru.png"
      srcSet="https://flagcdn.com/w40/ru.png 2x"
      width="20"
      alt="Russia"
    />
  ),
  'Saudi Arabia': (
    <img
      src="https://flagcdn.com/w20/sa.png"
      srcSet="https://flagcdn.com/w40/sa.png 2x"
      width="20"
      alt="Saudi Arabia"
    />
  ),
  Serbia: (
    <img
      src="https://flagcdn.com/w20/rs.png"
      srcSet="https://flagcdn.com/w40/rs.png 2x"
      width="20"
      alt="Serbia"
    />
  ),
  Singapore: (
    <img
      src="https://flagcdn.com/w20/sg.png"
      srcSet="https://flagcdn.com/w40/sg.png 2x"
      width="20"
      alt="Singapore"
    />
  ),
  Slovakia: (
    <img
      src="https://flagcdn.com/w20/sk.png"
      srcSet="https://flagcdn.com/w40/sk.png 2x"
      width="20"
      alt="Slovakia"
    />
  ),
  Slovenia: (
    <img
      src="https://flagcdn.com/w20/si.png"
      srcSet="https://flagcdn.com/w40/si.png 2x"
      width="20"
      alt="Slovenia"
    />
  ),
  'South Africa': (
    <img
      src="https://flagcdn.com/w20/za.png"
      srcSet="https://flagcdn.com/w40/za.png 2x"
      width="20"
      alt="South Africa"
    />
  ),
  'South Korea': (
    <img
      src="https://flagcdn.com/w20/kr.png"
      srcSet="https://flagcdn.com/w40/kr.png 2x"
      width="20"
      alt="South Korea"
    />
  ),
  Spain: (
    <img
      src="https://flagcdn.com/w20/es.png"
      srcSet="https://flagcdn.com/w40/es.png 2x"
      width="20"
      alt="Spain"
    />
  ),
  Sweden: (
    <img
      src="https://flagcdn.com/w20/se.png"
      srcSet="https://flagcdn.com/w40/se.png 2x"
      width="20"
      alt="Sweden"
    />
  ),
  Switzerland: (
    <img
      src="https://flagcdn.com/w20/ch.png"
      srcSet="https://flagcdn.com/w40/ch.png 2x"
      width="20"
      alt="Switzerland"
    />
  ),
  Taiwan: (
    <img
      src="https://flagcdn.com/w20/tw.png"
      srcSet="https://flagcdn.com/w40/tw.png 2x"
      width="20"
      alt="Taiwan"
    />
  ),
  Thailand: (
    <img
      src="https://flagcdn.com/w20/th.png"
      srcSet="https://flagcdn.com/w40/th.png 2x"
      width="20"
      alt="Thailand"
    />
  ),
  Turkey: (
    <img
      src="https://flagcdn.com/w20/tr.png"
      srcSet="https://flagcdn.com/w40/tr.png 2x"
      width="20"
      alt="Turkey"
    />
  ),
  Ukraine: (
    <img
      src="https://flagcdn.com/w20/ua.png"
      srcSet="https://flagcdn.com/w40/ua.png 2x"
      width="20"
      alt="Ukraine"
    />
  ),
  'United Arab Emirates': (
    <img
      src="https://flagcdn.com/w20/ae.png"
      srcSet="https://flagcdn.com/w40/ae.png 2x"
      width="20"
      alt="United Arab Emirates"
    />
  ),
  'United Kingdom': (
    <img
      src="https://flagcdn.com/w20/gb.png"
      srcSet="https://flagcdn.com/w40/gb.png 2x"
      width="20"
      alt="United Kingdom"
    />
  ),
  'United States': (
    <img
      src="https://flagcdn.com/w20/us.png"
      srcSet="https://flagcdn.com/w40/us.png 2x"
      width="20"
      alt="United States"
    />
  ),
  Uruguay: (
    <img
      src="https://flagcdn.com/w20/uy.png"
      srcSet="https://flagcdn.com/w40/uy.png 2x"
      width="20"
      alt="Uruguay"
    />
  ),
  Venezuela: (
    <img
      src="https://flagcdn.com/w20/ve.png"
      srcSet="https://flagcdn.com/w40/ve.png 2x"
      width="20"
      alt="Venezuela"
    />
  ),
  Vietnam: (
    <img
      src="https://flagcdn.com/w20/vn.png"
      srcSet="https://flagcdn.com/w40/vn.png 2x"
      width="20"
      alt="Vietnam"
    />
  ),
};

export const taxIDs = [
  {
    id: 0,
    country: 'Andorra',
    code: 'ad_nrt',
    description: 'Andorran NRT number',
    example: 'A-123456-Z',
    regex: '^([A-Z])-(\\d{6})-([A-Z])$',
    flag: countryFlags['Andorra'],
    errorMsg: 'NET no valido para Andorra',
  },
  {
    id: 1,
    country: 'Argentina',
    code: 'ar_cuit',
    description: 'Argentinian tax ID number',
    example: '12-3456789-01',
    regex: '^(\\d{2})-(\\d{7,8})-(\\d{1})$',
    flag: countryFlags['Argentina'],
    errorMsg: 'CUIT no valido para Argentina',
  },
  {
    id: 2,
    country: 'Australia',
    code: 'au_abn',
    description: 'Australian Business Number (AU ABN)',
    example: '12345678912',
    regex: '^(\\d{2})s(\\d{3})s(\\d{3})s(\\d{3})$',
    flag: countryFlags['Australia'],
    errorMsg: 'ABN no valido para Australia',
  },
  {
    id: 3,
    country: 'Australia',
    code: 'au_arn',
    description: 'Australian Taxation Office Reference Number',
    example: '123456789123',
    regex: '^\\d{12}$',
    flag: countryFlags['Australia'],
    errorMsg: 'ARN no valido para Australia',
  },
  {
    id: 4,
    country: 'Austria',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'ATU12345678',
    regex: '^ATU(\\d{8})$',
    flag: countryFlags['Austria'],
    errorMsg: 'VAT no valido para Austria',
  },
  {
    id: 5,
    country: 'Belgium',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'BE0123456789',
    regex: '^BE(\\d{9})$',
    flag: countryFlags['Belgium'],
    errorMsg: 'VAT no valido para Belgium',
  },
  {
    id: 6,
    country: 'Bolivia',
    code: 'bo_tin',
    description: 'Bolivian tax ID',
    example: '123456789',
    regex: '^\\d{9}$',
    flag: countryFlags['Bolivia'],
    errorMsg: 'TIN no valido para Bolivia',
  },
  {
    id: 7,
    country: 'Brazil',
    code: 'br_cnpj',
    description: 'Brazilian CNPJ number',
    example: '01.234.456/5432-10',
    regex: '^(\\d{2}).(\\d{3}).(\\d{3})/(\\d{4})-(\\d{2})$',
    flag: countryFlags['Brazil'],
    errorMsg: 'CNPJ no valido para Brazil',
  },
  {
    id: 8,
    country: 'Brazil',
    code: 'br_cpf',
    description: 'Brazilian CPF number',
    example: '123.456.789-87',
    regex: '^(\\d{3}).(\\d{3}).(\\d{3})-(\\d{2})$',
    flag: countryFlags['Brazil'],
    errorMsg: 'CPF no valido para Brazil',
  },
  {
    id: 9,
    country: 'Bulgaria',
    code: 'bg_uic',
    description: 'Bulgaria Unified Identification Code',
    example: '123456789',
    regex: '^(\\d{9})$',
    flag: countryFlags['Bulgaria'],
    errorMsg: 'UIC no valido para Bulgaria',
  },
  {
    id: 10,
    country: 'Bulgaria',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'BG0123456789',
    regex: '^BG(\\d{9,10})$',
    flag: countryFlags['Bulgaria'],
    errorMsg: 'VAT no valido para Bulgaria',
  },
  {
    id: 11,
    country: 'Canada',
    code: 'ca_bn',
    description: 'Canadian BN',
    example: '123456789',
    regex: '^(\\d{9})$',
    flag: countryFlags['Canada'],
    errorMsg: 'BN no valido para Canada',
  },
  {
    id: 12,
    country: 'Canada',
    code: 'ca_gst_hst',
    description: 'Canadian GST/HST number',
    example: '123456789RT0002',
    regex: '^(\\d{9})RT\\d{4}$',
    flag: countryFlags['Canada'],
    errorMsg: 'GST/HST no valido para Canada',
  },
  {
    id: 13,
    country: 'Canada',
    code: 'ca_pst_bc',
    description: 'Canadian PST number (British Columbia)',
    example: 'PST-1234-5678',
    regex: '^PST-(\\d{4})-(\\d{4})$',
    flag: countryFlags['Canada'],
    errorMsg: 'PST no valido para Canada',
  },
  {
    id: 14,
    country: 'Canada',
    code: 'ca_pst_mb',
    description: 'Canadian PST number (Manitoba)',
    example: '123456-7',
    regex: '^(\\d{6})-(\\d{1})$',
    flag: countryFlags['Canada'],
    errorMsg: 'PST no valido para Canada',
  },
  {
    id: 15,
    country: 'Canada',
    code: 'ca_pst_sk',
    description: 'Canadian PST number (Saskatchewan)',
    example: '1234567',
    regex: '^(\\d{7})$',
    flag: countryFlags['Canada'],
    errorMsg: 'PST no valido para Canada',
  },
  {
    id: 16,
    country: 'Canada',
    code: 'ca_qst',
    description: 'Canadian QST number (Québec)',
    example: '1234567890TQ1234',
    regex: '^(\\d{10})TQ\\d{4}$',
    flag: countryFlags['Canada'],
    errorMsg: 'QST no valido para Canada',
  },
  {
    id: 17,
    country: 'Chile',
    code: 'cl_tin',
    description: 'Chilean TIN',
    example: '12.345.678-K',
    regex: '^(\\d{2}).(\\d{3}).(\\d{3})-K$',
    flag: countryFlags['Chile'],
    errorMsg: 'TIN no valido para Chile',
  },
  {
    id: 18,
    country: 'China',
    code: 'cn_tin',
    description: 'Chinese tax ID',
    example: '123456789012345678',
    regex: '^(\\d{18})$',
    flag: countryFlags['China'],
    errorMsg: 'TIN no valido para China',
  },
  {
    id: 19,
    country: 'Colombia',
    code: 'co_nit',
    description: 'Colombian NIT number',
    example: '123.456.789-0',
    regex: '^(\\d{3}).(\\d{3}).(\\d{3})-(\\d{1})$',
    flag: countryFlags['Colombia'],
    errorMsg: 'NIT no valido para Colombia',
  },
  {
    id: 20,
    country: 'Costa Rica',
    code: 'cr_tin',
    description: 'Costa Rican tax ID',
    example: '1-234-567890',
    regex: '^(\\d{1})-(\\d{3})-(\\d{6})$',
    flag: countryFlags['Costa Rica'],
    errorMsg: 'TIN no valido para Costa Rica',
  },
  {
    id: 21,
    country: 'Croatia',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'HR12345678912',
    regex: '^HR(\\d{11})$',
    flag: countryFlags['Croatia'],
    errorMsg: 'VAT no valido para Croatia',
  },
  {
    id: 22,
    country: 'Cyprus',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'CY12345678Z',
    regex: '^CY(\\d{8})[A-Z]$',
    flag: countryFlags['Cyprus'],
    errorMsg: 'VAT no valido para Cyprus',
  },
  {
    id: 23,
    country: 'Czech Republic',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'CZ1234567890',
    regex: '^CZ(\\d{8,10})$',
    flag: countryFlags['Czech Republic'],
    errorMsg: 'VAT no valido para Czech Republic',
  },
  {
    id: 24,
    country: 'Denmark',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'DK12345678',
    regex: '^DK(\\d{8})$',
    flag: countryFlags['Denmark'],
    errorMsg: 'VAT no valido para Denmark',
  },
  {
    id: 25,
    country: 'Dominican Republic',
    code: 'do_rcn',
    description: 'Dominican RCN number',
    example: '123-4567890-1',
    regex: '^(\\d{3})-(\\d{7})-(\\d{1})$',
    flag: countryFlags['Dominican Republic'],
    errorMsg: 'RCN no valido para Dominican Republic',
  },
  {
    id: 26,
    country: 'Ecuador',
    code: 'ec_ruc',
    description: 'Ecuadorian RUC number',
    example: '1234567890001',
    regex: '^(\\d{13})$',
    flag: countryFlags['Ecuador'],
    errorMsg: 'RUC no valido para Ecuador',
  },
  {
    id: 27,
    country: 'Egypt',
    code: 'eg_tin',
    description: 'Egyptian Tax Identification Number',
    example: '123456789',
    regex: '^(\\d{9})$',
    flag: countryFlags['Egypt'],
    errorMsg: 'TIN no valido para Egypt',
  },
  {
    id: 28,
    country: 'El Salvador',
    code: 'sv_nit',
    description: 'El Salvadorian NIT number',
    example: '1234-567890-123-4',
    regex: '^(\\d{4})-(\\d{6,9})-(\\d{3})-(\\d{1})$',
    flag: countryFlags['El Salvador'],
    errorMsg: 'NIT no valido para El Salvador',
  },
  {
    id: 29,
    country: 'Estonia',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'EE123456789',
    regex: '^EE(\\d{9})$',
    flag: countryFlags['Estonia'],
    errorMsg: 'VAT no valido para Estonia',
  },
  {
    id: 30,
    country: 'EU',
    code: 'eu_oss_vat',
    description: 'European One Stop Shop VAT number for non-Union scheme',
    example: 'EU123456789',
    regex: '^EU(\\d{9})$',
    flag: countryFlags['EU'],
    errorMsg: 'VAT no valido para EU',
  },
  {
    id: 31,
    country: 'Finland',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'FI12345678',
    regex: '^FI(\\d{8})$',
    flag: countryFlags['Finland'],
    errorMsg: 'VAT no valido para Finland',
  },
  {
    id: 32,
    country: 'France',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'FRAB123456789',
    regex: '^FR[A-Z]{2}(\\d{9})$',
    flag: countryFlags['France'],
    errorMsg: 'VAT no valido para France',
  },
  {
    id: 33,
    country: 'Georgia',
    code: 'ge_vat',
    description: 'Georgian VAT',
    example: '123456789',
    regex: '^(\\d{9})$',
    flag: countryFlags['Georgia'],
    errorMsg: 'VAT no valido para Georgia',
  },
  {
    id: 34,
    country: 'Germany',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'DE123456789',
    regex: '^DE(\\d{9})$',
    flag: countryFlags['Germany'],
    errorMsg: 'VAT no valido para Germany',
  },
  {
    id: 35,
    country: 'Greece',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'EL123456789',
    regex: '^EL(\\d{9})$',
    flag: countryFlags['Greece'],
    errorMsg: 'VAT no valido para Greece',
  },
  {
    id: 36,
    country: 'Hong Kong',
    code: 'hk_br',
    description: 'Hong Kong BR number',
    example: '12345678',
    regex: '^(\\d{8})$',
    flag: countryFlags['Hong Kong'],
    errorMsg: 'BR no valido para Hong Kong',
  },
  {
    id: 37,
    country: 'Hungary',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'HU12345678',
    regex: '^HU(\\d{8})$',
    flag: countryFlags['Hungary'],
    errorMsg: 'VAT no valido para Hungary',
  },
  {
    id: 38,
    country: 'Hungary',
    code: 'hu_tin',
    description: 'Hungary tax number (adószám)',
    example: '12345678-1-23',
    regex: '^(\\d{8})-(\\d{1})-(\\d{2})$',
    flag: countryFlags['Hungary'],
    errorMsg: 'TIN no valido para Hungary',
  },
  {
    id: 39,
    country: 'Iceland',
    code: 'is_vat',
    description: 'Icelandic VAT',
    example: '123456',
    regex: '^(\\d{6})$',
    flag: countryFlags['Iceland'],
    errorMsg: 'VAT no valido para Iceland',
  },
  {
    id: 40,
    country: 'India',
    code: 'in_gst',
    description: 'Indian GST number',
    example: '12ABCDE3456FGZH',
    regex:
      '^(\\d{2})([A-Z]{5})(\\d{4})([A-Z]{1})([A-Z\\d]{1})([A-Z\\d]{1})([A-Z\\d]{1})$',
    flag: countryFlags['India'],
    errorMsg: 'GST no valido para India',
  },
  {
    id: 41,
    country: 'Indonesia',
    code: 'id_npwp',
    description: 'Indonesian NPWP number',
    example: '12.345.678.9-012.345',
    regex: '^(\\d{2}).(\\d{3}).(\\d{3}).(\\d{1})-(\\d{3}).(\\d{3})$',
    flag: countryFlags['Indonesia'],
    errorMsg: 'NPWP no valido para Indonesia',
  },
  {
    id: 42,
    country: 'Ireland',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'IE1234567AB',
    regex: '^IE(\\d{7}[A-Z]{1,2})$',
    flag: countryFlags['Ireland'],
    errorMsg: 'VAT no valido para Ireland',
  },
  {
    id: 43,
    country: 'Israel',
    code: 'il_vat',
    description: 'Israel VAT',
    example: '000012345',
    regex: '^(\\d{9})$',
    flag: countryFlags['Israel'],
    errorMsg: 'VAT no valido para Israel',
  },
  {
    id: 44,
    country: 'Italy',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'IT12345678912',
    regex: '^IT(\\d{11})$',
    flag: countryFlags['Italy'],
    errorMsg: 'VAT no valido para Italy',
  },
  {
    id: 45,
    country: 'Japan',
    code: 'jp_cn',
    description: 'Japanese Corporate Number (Hōjin Bangō)',
    example: '1234567891234',
    regex: '^\\d{13}$',
    flag: countryFlags['Japan'],
    errorMsg: 'CN no valido para Japan',
  },
  {
    id: 46,
    country: 'Japan',
    code: 'jp_rn',
    description:
      "Japanese Registered Foreign Businesses' Registration Number (Tōroku Kokugai Jigyōsha no Tōroku Bangō)",
    example: '12345',
    regex: '^\\d{5}$',
    flag: countryFlags['Japan'],
    errorMsg: 'RN no valido para Japan',
  },
  {
    id: 47,
    country: 'Japan',
    code: 'jp_trn',
    description: 'Japanese Tax Registration Number (Tōroku Bangō)',
    example: 'T1234567891234',
    regex: '^T\\d{13}$',
    flag: countryFlags['Japan'],
    errorMsg: 'TRN no valido para Japan',
  },
  {
    id: 48,
    country: 'Kenya',
    code: 'ke_pin',
    description: 'Kenya Revenue Authority Personal Identification Number',
    example: 'P000111111A',
    regex: '^P\\d{9}[A-Z]$',
    flag: countryFlags['Kenya'],
    errorMsg: 'PIN no valido para Kenya',
  },
  {
    id: 49,
    country: 'Latvia',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'LV12345678912',
    regex: '^[A-Z]{2}\\d{11}$',
    flag: countryFlags['Latvia'],
    errorMsg: 'VAT no valido para Latvia',
  },
  {
    id: 50,
    country: 'Liechtenstein',
    code: 'li_uid',
    description: 'Liechtenstein UID number',
    example: '12345',
    regex: '^\\d{5}$',
    flag: countryFlags['Liechtenstein'],
    errorMsg: 'UID no valido para Liechtenstein',
  },
  {
    id: 51,
    country: 'Lithuania',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'LT123456789123',
    regex: '^[A-Z]{2}\\d{12}$',
    flag: countryFlags['Lithuania'],
    errorMsg: 'VAT no valido para Lithuania',
  },
  {
    id: 52,
    country: 'Luxembourg',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'LU12345678',
    regex: '^[A-Z]{2}\\d{8}$',
    flag: countryFlags['Luxembourg'],
    errorMsg: 'VAT no valido para Luxembourg',
  },
  {
    id: 53,
    country: 'Malaysia',
    code: 'my_frp',
    description: 'Malaysian FRP number',
    example: '12345678',
    regex: '^\\d{8}$',
    flag: countryFlags['Malaysia'],
    errorMsg: 'FRP no valido para Malaysia',
  },
  {
    id: 54,
    country: 'Malaysia',
    code: 'my_itn',
    description: 'Malaysian ITN',
    example: 'C 1234567890',
    regex: '^[A-Z]s\\d{10}$',
    flag: countryFlags['Malaysia'],
    errorMsg: 'ITN no valido para Malaysia',
  },
  {
    id: 55,
    country: 'Malaysia',
    code: 'my_sst',
    description: 'Malaysian SST number',
    example: 'A12-3456-78912345',
    regex: '^[A-Z]\\d{2}-\\d{4}-\\d{8}$',
    flag: countryFlags['Malaysia'],
    errorMsg: 'SST no valido para Malaysia',
  },
  {
    id: 56,
    country: 'Malta',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'MT12345678',
    regex: '^[A-Z]{2}\\d{8}$',
    flag: countryFlags['Malta'],
    errorMsg: 'VAT no valido para Malta',
  },
  {
    id: 57,
    country: 'Mexico',
    code: 'mx_rfc',
    description: 'Mexican RFC number',
    example: 'ABC010203AB9',
    regex: '^[A-Z]{3}[A-Z0-9]{6}[A-Z0-9]{3}$',
    flag: countryFlags['Mexico'],
    errorMsg: 'RFC no valido para Mexico',
  },
  {
    id: 58,
    country: 'Netherlands',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'NL123456789B12',
    regex: '^[A-Z]{2}\\d{9}[A-Z]{2}\\d{2}$',
    flag: countryFlags['Netherlands'],
    errorMsg: 'VAT no valido para Netherlands',
  },
  {
    id: 59,
    country: 'New Zealand',
    code: 'nz_gst',
    description: 'New Zealand GST number',
    example: '123456789',
    regex: '^\\d{9}$',
    flag: countryFlags['New Zealand'],
    errorMsg: 'GST no valido para New Zealand',
  },
  {
    id: 60,
    country: 'Norway',
    code: 'no_vat',
    description: 'Norwegian VAT number',
    example: '123456789MVA',
    regex: '^\\d{9}[A-Z]{3}$',
    flag: countryFlags['Norway'],
    errorMsg: 'VAT no valido para Norway',
  },
  {
    id: 61,
    country: 'Peru',
    code: 'pe_ruc',
    description: 'Peruvian RUC number',
    example: '12345678901',
    regex: '^\\d{11}$',
    flag: countryFlags['Peru'],
    errorMsg: 'RUC no valido para Peru',
  },
  {
    id: 62,
    country: 'Philippines',
    code: 'ph_tin',
    description: 'Philippines Tax Identification Number',
    example: '123456789012',
    regex: '^\\d{12}$',
    flag: countryFlags['Philippines'],
    errorMsg: 'TIN no valido para Philippines',
  },
  {
    id: 63,
    country: 'Poland',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'PL1234567890',
    regex: '^[A-Z]{2}\\d{10}$',
    flag: countryFlags['Poland'],
    errorMsg: 'VAT no valido para Poland',
  },
  {
    id: 64,
    country: 'Portugal',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'PT123456789',
    regex: '^[A-Z]{2}\\d{9}$',
    flag: countryFlags['Portugal'],
    errorMsg: 'VAT no valido para Portugal',
  },
  {
    id: 65,
    country: 'Romania',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'RO1234567891',
    regex: '^[A-Z]{2}\\d{10}$',
    flag: countryFlags['Romania'],
    errorMsg: 'VAT no valido para Romania',
  },
  {
    id: 66,
    country: 'Romania',
    code: 'ro_tin',
    description: 'Romanian tax ID number',
    example: '1234567890123',
    regex: '^\\d{13}$',
    flag: countryFlags['Romania'],
    errorMsg: 'TIN no valido para Romania',
  },
  {
    id: 67,
    country: 'Russia',
    code: 'ru_inn',
    description: 'Russian INN',
    example: '1234567891',
    regex: '^\\d{10}$',
    flag: countryFlags['Russia'],
    errorMsg: 'INN no valido para Russia',
  },
  {
    id: 68,
    country: 'Russia',
    code: 'ru_kpp',
    description: 'Russian KPP',
    example: '123456789',
    regex: '^\\d{9}$',
    flag: countryFlags['Russia'],
    errorMsg: 'KPP no valido para Russia',
  },
  {
    id: 69,
    country: 'Saudi Arabia',
    code: 'sa_vat',
    description: 'Saudi Arabia VAT',
    example: '123456789012345',
    regex: '^\\d{15}$',
    flag: countryFlags['Saudi Arabia'],
    errorMsg: 'VAT no valido para Saudi Arabia',
  },
  {
    id: 70,
    country: 'Serbia',
    code: 'rs_pib',
    description: 'Serbian PIB number',
    example: '123456789',
    regex: '^\\d{9}$',
    flag: countryFlags['Serbia'],
    errorMsg: 'PIB no valido para Serbia',
  },
  {
    id: 71,
    country: 'Singapore',
    code: 'sg_gst',
    description: 'Singaporean GST',
    example: 'M12345678X',
    regex: '^[A-Z]{1}\\d{7}[A-Z]{1}$',
    flag: countryFlags['Singapore'],
    errorMsg: 'GST no valido para Singapore',
  },
  {
    id: 72,
    country: 'Singapore',
    code: 'sg_uen',
    description: 'Singaporean UEN',
    example: '123456789F',
    regex: '^\\d{9}[A-Z]{1}$',
    flag: countryFlags['Singapore'],
    errorMsg: 'UEN no valido para Singapore',
  },
  {
    id: 73,
    country: 'Slovakia',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'SK1234567891',
    regex: '^[A-Z]{2}\\d{10}$',
    flag: countryFlags['Slovakia'],
    errorMsg: 'VAT no valido para Slovakia',
  },
  {
    id: 74,
    country: 'Slovenia',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'SI12345678',
    regex: '^[A-Z]{2}\\d{8}$',
    flag: countryFlags['Slovenia'],
    errorMsg: 'VAT no valido para Slovenia',
  },
  {
    id: 75,
    country: 'Slovenia',
    code: 'si_tin',
    description: 'Slovenia tax number (davčna številka)',
    example: '12345678',
    regex: '^\\d{8}$',
    flag: countryFlags['Slovenia'],
    errorMsg: 'TIN no valido para Slovenia',
  },
  {
    id: 76,
    country: 'South Africa',
    code: 'za_vat',
    description: 'South African VAT number',
    example: '4123456789',
    regex: '^\\d{10}$',
    flag: countryFlags['South Africa'],
    errorMsg: 'VAT no valido para South Africa',
  },
  {
    id: 77,
    country: 'South Korea',
    code: 'kr_brn',
    description: 'Korean BRN',
    example: '123-45-67890',
    regex: '^\\d{3}-\\d{2}-\\d{5}$',
    flag: countryFlags['South Korea'],
    errorMsg: 'BRN no valido para South Korea',
  },
  {
    id: 78,
    country: 'Spain',
    code: 'es_cif',
    description: 'Spanish NIF number (previously Spanish CIF number)',
    example: 'A12345678',
    regex: '^[A-Z]\\d{8}$',
    flag: countryFlags['Spain'],
    errorMsg: 'CIF no valido para Spain',
  },
  {
    id: 79,
    country: 'Spain',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'ESA1234567Z',
    regex: '^[A-Z]{2}[A-Z0-9]$',
    flag: countryFlags['Spain'],
    errorMsg: 'VAT no valido para Spain',
  },
  {
    id: 80,
    country: 'Sweden',
    code: 'eu_vat',
    description: 'European VAT number',
    example: 'SE123456789123',
    regex: '^[A-Z]{2}\\d{12}$',
    flag: countryFlags['Sweden'],
    errorMsg: 'VAT no valido para Sweden',
  },
  {
    id: 81,
    country: 'Switzerland',
    code: 'ch_vat',
    description: 'Switzerland VAT number',
    example: 'CHE-123.456.789 MWST',
    regex: '^CHE-\\d{3}.\\d{3}.\\d{3} MWST$',
    flag: countryFlags['Switzerland'],
    errorMsg: 'VAT no valido para Switzerland',
  },
  {
    id: 82,
    country: 'Taiwan',
    code: 'tw_vat',
    description: 'Taiwanese VAT',
    example: '12345678',
    regex: '^\\d{8}$',
    flag: countryFlags['Taiwan'],
    errorMsg: 'VAT no valido para Taiwan',
  },
  {
    id: 83,
    country: 'Thailand',
    code: 'th_vat',
    description: 'Thai VAT',
    example: '1234567891234',
    regex: '^\\d{13}$',
    flag: countryFlags['Thailand'],
    errorMsg: 'VAT no valido para Thailand',
  },
  {
    id: 84,
    country: 'Turkey',
    code: 'tr_tin',
    description: 'Turkish Tax Identification Number',
    example: '0123456789',
    regex: '^\\d{10}$',
    flag: countryFlags['Turkey'],
    errorMsg: 'TIN no valido para Turkey',
  },
  {
    id: 85,
    country: 'Ukraine',
    code: 'ua_vat',
    description: 'Ukrainian VAT',
    example: '123456789',
    regex: '^\\d{9}$',
    flag: countryFlags['Ukraine'],
    errorMsg: 'VAT no valido para Ukraine',
  },
  {
    id: 86,
    country: 'United Arab Emirates',
    code: 'ae_trn',
    description: 'United Arab Emirates TRN',
    example: '123456789012345',
    regex: '^\\d{15}$',
    flag: countryFlags['United Arab Emirates'],
    errorMsg: 'TRN no valido para United Arab Emirates',
  },
  {
    id: 87,
    country: 'United Kingdom',
    code: 'eu_vat',
    description: 'Northern Ireland VAT number',
    example: 'XI123456789',
    regex: '^XI\\d{9}$',
    flag: countryFlags['United Kingdom'],
    errorMsg: 'VAT no valido para United Kingdom',
  },
  {
    id: 88,
    country: 'United Kingdom',
    code: 'gb_vat',
    description: 'United Kingdom VAT number',
    example: 'GB123456789',
    regex: '^GB\\d{9}$',
    flag: countryFlags['United Kingdom'],
    errorMsg: 'VAT no valido para United Kingdom',
  },
  {
    id: 89,
    country: 'United States',
    code: 'us_ein',
    description: 'United States EIN',
    example: '12-3456789',
    regex: '^\\d{2}-\\d{7}$',
    flag: countryFlags['United States'],
    errorMsg: 'EIN no valido para United States',
  },
  {
    id: 90,
    country: 'Uruguay',
    code: 'uy_ruc',
    description: 'Uruguayan RUC number',
    example: '123456789012',
    regex: '^\\d{12}$',
    flag: countryFlags['Uruguay'],
    errorMsg: 'RUC no valido para Uruguay',
  },
  {
    id: 91,
    country: 'Venezuela',
    code: 've_rif',
    description: 'Venezuelan RIF number',
    example: 'A-12345678-9',
    regex: '^A\\d{8}-\\d$',
    flag: countryFlags['Venezuela'],
    errorMsg: 'RIF no valido para Venezuela',
  },
  {
    id: 92,
    country: 'Vietnam',
    code: 'vn_tin',
    description: 'Vietnamese tax ID number',
    example: '1234567890',
    regex: '^\\d{10}$',
    flag: countryFlags['Vietnam'],
    errorMsg: 'TIN no valido para Vietnam',
  },
];
