import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    actionIcon: {
        color: '#373435',
        height: 16,
        width: 16
    },
    documentIcon: {
        color: theme.palette.primary.main,
        height: '11px',
        width: '9px',
    },
    linkDocuments: {
        color: theme.palette.primary.main,
        fontSize: '10px',
        fontWeight: 600,
        textTransform: 'uppercase',
    },
    textSeparator: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '24px',
        marginLeft: '24px',
        marginTop: '40px',
    },
    historyTableDivider: {
        marginBottom: '16px',
        marginLeft: '24px',
    },
    table: {
        maxWidth: 525,
    },
    tableBody: {
        cursor: 'default',
        fontSize: '12px',
        padding: '8px 16px',
    },
    tableHeadEmpty: {
        cursor: 'default',
        fontSize: '12px',
        paddingTop: '0px',
    },
    tableHead: {
        cursor: 'default',
        fontSize: '12px',
        fontWeight: 700,
        paddingTop: '0px',
    },
    tableLink: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontWeight: 600,
        textAlign: 'center',
        textDecoration: 'none',
        textTransform: 'uppercase',
        textWrap: 'nowrap'
    },
    tableVisibilityIcon: {
        padding: '0px',
    }
}));

export default useStyles;
