import alertActions from './alert.actions';
import { superAdminConstants } from '../../constants';
import { superAdminService } from '../../services';
import { history } from '../../helpers';

function createCompany(newCompany) {
  function request() { return { type: superAdminConstants.COMPANY_POST_REQUEST }; }
  function success(company) { return { type: superAdminConstants.COMPANY_POST_SUCCESS, company }; }
  function failure(error) { return { type: superAdminConstants.COMPANY_POST_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    superAdminService.createCompany(newCompany)
      .then(
        (company) => {
          dispatch(success(company));
          history.push('/companies');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function deleteCompany(id) {
  function request() { return { type: superAdminConstants.COMPANY_DELETE_REQUEST }; }
  function success() { return { type: superAdminConstants.COMPANY_DELETE_SUCCESS }; }
  function failure(error) { return { type: superAdminConstants.COMPANY_DELETE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    superAdminService.deleteCompany(id)
      .then(
        () => {
          dispatch(success());
          history.push('/companies');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function resetUserPassword(userId) {
  function request() { return { type: superAdminConstants.RESET_PASS_REQUEST }; }
  function success(user) { return { type: superAdminConstants.RESET_PASS_SUCCESS, user }; }
  function failure(error) { return { type: superAdminConstants.RESET_PASS_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    superAdminService.resetUserPassword(userId)
      .then(
        (user) => {
          dispatch(success(user));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function clearSuperAdmin() {
  return { type: superAdminConstants.CLEAN_SUPER_ADMIN };
}

const superAdminActions = {
  createCompany,
  deleteCompany,
  resetUserPassword,
  clearSuperAdmin,
};

export default superAdminActions;
