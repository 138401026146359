import React, { useState } from 'react';
import {
  Box, Breadcrumbs, Dialog, DialogContent,
  DialogTitle, Divider, IconButton, makeStyles, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Link, Redirect } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';
import PageLayout from '../PageLayout';
import Button from '../controls/Button';
import { errorCodeHelper } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  tableTitle: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '24px',
  },
  tableItem: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    paddingTop: 16,
    paddingBottom: 16,
    paddingRight: 0,
  },
  tableItemError: {
    color: '#F55B4F',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  tableTitleError: {
    minWidth: 60,
  },
  tableTitleCount: {
    color: '#F79A56',
    flexGrow: 1,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
    paddingBottom: '6px',
  },
  tableFooter: {
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '24px',
    marginTop: 8,
  },
  titleFooter: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  textDialog: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    maxWidth: '374px',
  },
  textTitleDialog: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '18px',
    textTransform: 'uppercase',
  },
  closeButton: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  processedResultActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const BulkCreationResult = ({
  handleConfirmResult, text, linkRedirect, entity, tableErrors, tableLoaded, linkEntity,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [openDialogCancel, setOpenDialogCancel] = useState(false);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const invalidData = [null, undefined, NaN, ''];

  const toggleDialogConfirm = () => {
    setOpenDialogConfirm(!openDialogConfirm);
  };
  const toggleDialogCancel = () => {
    setOpenDialogCancel(!openDialogCancel);
  };

  const backText = intl.formatMessage({ id: 'app.toBack', defaultMessage: 'Volver' });
  const cancelText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const errorTitle = intl.formatMessage({ id: 'app.error', defaultMessage: 'Error' });
  const saveText = intl.formatMessage({ id: 'app.continue', defaultMessage: 'Guardar' });
  const bulkResultTitle = intl.formatMessage({ id: 'bulkCreation.bulkResultTitle', defaultMessage: 'Resultado del procesamiento' });
  const bulkTitleErrors = intl.formatMessage({ id: 'bulkCreation.bulkTitleErrors', defaultMessage: 'Errores:' });
  const confirmCancel = intl.formatMessage({ id: 'bulkCreation.confirmCancel', defaultMessage: 'Salir sin guardar' });
  const confirmation = intl.formatMessage({ id: 'bulkCreation.confirmation', defaultMessage: 'Confirmación' });
  const textDialogConfirm = intl.formatMessage({ id: 'bulkCreation.textDialogConfirm', defaultMessage: '¿Desea confirmar esta acción?' });
  const textDialogWarningBulk = intl.formatMessage({ id: 'bulkCreation.textDialogWarningBulk', defaultMessage: 'Usted no ha finalizado el proceso de creación. ¿Está seguro que desea salir de esta pestaña? Todo el progreso se perderá.' });
  const warning = intl.formatMessage({ id: 'bulkCreation.warning', defaultMessage: 'Advertencia' });

  function NewObjectiveBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to={linkEntity} style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{text.title}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{text.bulkCreationBreadcrumbs}</Typography>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{bulkResultTitle}</Typography>
      </Breadcrumbs>
    );
  }

  if (!entity.bulkCreationPreviewResult) {
    return <Redirect to={{ pathname: linkRedirect }} />;
  }

  const CustomTableCellBody = ({ tableData, values }) => {
    if (tableData.options && typeof tableData.options.customBodyRender === 'function') {
      return (
        <TableCell
          className={classes.tableItem}
          classes={{ root: tableData.classesBody }}
        >
          {invalidData.includes(values[tableData.row])
            ? tableData.options.customBodyRender('-')
            : tableData.options.customBodyRender(values[tableData.row])}
        </TableCell>
      );
    }
    return (
      <TableCell
        className={classes.tableItem}
        classes={{ root: tableData.classesBody }}
      >
        {values[tableData.row] || '-'}
      </TableCell>
    );
  };

  CustomTableCellBody.propTypes = {
    values: PropTypes.shape().isRequired,
    tableData: PropTypes.shape({
      id: PropTypes.number.isRequired,
      row: PropTypes.string.isRequired,
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape(),
      ]).isRequired,
      classesHead: PropTypes.string,
      classesBody: PropTypes.string,
      options: PropTypes.shape(),
    }).isRequired,
  };

  return (
    <PageLayout title={bulkResultTitle} breadCrumbs={<NewObjectiveBreadcrumbs />}>
      <Box px={3} height="60vh" style={{ overflowY: 'auto' }}>
        {entity.bulkCreationPreviewResult.errors.length > 0 && (
          <>
            <Typography className={classes.tableTitleCount} variant="h6">
              {bulkTitleErrors}
              {' '}
              {entity.bulkCreationPreviewResult.errors.length}
            </Typography>
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    {tableErrors.map((table) => (
                      <TableCell
                        className={classes.tableTitle}
                        classes={{ root: table.classesHead }}
                        key={table.id}
                      >
                        {table.name}
                      </TableCell>
                    ))}
                    <TableCell className={`${classes.tableTitle} ${classes.tableTitleError}`}>{errorTitle}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entity.bulkCreationPreviewResult.errors.map((itemError) => (
                    <TableRow key={itemError.row}>
                      {tableErrors.map((table) => (
                        <CustomTableCellBody key={table.id} tableData={table} values={itemError} />
                      ))}
                      <TableCell>
                        {itemError.errors.map((item) => (
                          <Typography key={itemError.row} className={classes.tableItemError}>
                            {errorCodeHelper.errorTexts(item)}
                          </Typography>
                        ))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography className={classes.tableFooter} variant="body1">
              {text.bulkNotCreated}
            </Typography>
          </>
        )}
        {entity.bulkCreationPreviewResult.loadedItems.length > 0 && (
          <>
            <Typography className={classes.tableTitleCount} style={{ paddingTop: 24 }} variant="h6">
              {text.bulkUploadedSuccess}
              {' '}
              {entity.bulkCreationPreviewResult.loadedItems.length}
            </Typography>
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    {tableLoaded.map((table) => (
                      <TableCell
                        className={classes.tableTitle}
                        classes={{ root: table.classesHead }}
                        key={table.id}
                      >
                        {table.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entity.bulkCreationPreviewResult.loadedItems.map((item, index) => {
                    const customKey = `${index}key`;
                    return (
                      <TableRow key={customKey}>
                        {tableLoaded.map((table) => (
                          <CustomTableCellBody key={table.id} tableData={table} values={item} />
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
      <Divider variant="fullWidth" />
      <Box pl={3} mt={2}>
        <Box className={classes.processedResultActions}>
          <Typography className={classes.titleFooter} variant="body1">
            {entity.bulkCreationPreviewResult.loadedItems.length > 0
              ? text.onlySuccessUploadWillBeCreated
              : text.emptyListSuccessfulUpload}
          </Typography>
        </Box>
        <Box className={classes.processedResultActions}>
          {entity.bulkCreationPreviewResult.loadedItems.length > 0
            ? <Button variant="outlined" text={cancelText} onClick={toggleDialogCancel} />
            : <Button variant="outlined" text={backText} component={Link} to={linkRedirect} />}
          <Button
            text={saveText}
            onClick={toggleDialogConfirm}
            disabled={entity.bulkCreationPreviewResult.loadedItems.length < 1}
          />
        </Box>
      </Box>

      <Dialog
        open={openDialogConfirm}
        onClose={toggleDialogConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton aria-label="close" className={classes.closeButton} onClick={toggleDialogConfirm}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogTitle disableTypography style={{ paddingTop: 0, padingBottom: 8 }}>
          <Box display="flex" justifyContent="center">
            <Typography align="center" className={classes.textTitleDialog} variant="body1">
              {confirmation}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography align="center" display="block" className={classes.textDialog} variant="body1">
            {text.onlySuccessUploadWillBeCreated}
          </Typography>
          <Typography align="center" display="block" className={classes.textDialog} variant="body1">
            {textDialogConfirm}
          </Typography>
        </DialogContent>
        <Box pt={1} pb={3} display="flex" justifyContent="center">
          <Button onClick={toggleDialogConfirm} variant="outlined" text={cancelText} />
          <Button
            loading={!!entity.confirming}
            text={text.create}
            onClick={handleConfirmResult}
          />
        </Box>
      </Dialog>

      <Dialog
        open={openDialogCancel}
        onClose={toggleDialogCancel}
        aria-labelledby="alert-dialog-cancel-title"
        aria-describedby="alert-dialog-cancel-description"
      >
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton aria-label="close" className={classes.closeButton} onClick={toggleDialogCancel}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogTitle disableTypography style={{ paddingTop: 0, padingBottom: 8 }}>
          <Box pb={2} display="flex" justifyContent="center">
            <WarningIcon color="primary" fontSize="large" />
          </Box>
          <Box display="flex" justifyContent="center">
            <Typography align="center" className={classes.textTitleDialog} variant="body1">
              {warning}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography align="center" display="block" className={classes.textDialog} variant="body1">
            {textDialogWarningBulk}
          </Typography>
        </DialogContent>
        <Box pt={1} pb={3} display="flex" justifyContent="center">
          <Button component={Link} to={linkRedirect} text={confirmCancel} />
          <Button onClick={toggleDialogCancel} variant="outlined" text={cancelText} />
        </Box>
      </Dialog>
    </PageLayout>
  );
};

BulkCreationResult.propTypes = {
  entity: PropTypes.shape(),
  text: PropTypes.shape().isRequired,
  linkEntity: PropTypes.string.isRequired,
  linkRedirect: PropTypes.string.isRequired,
  handleConfirmResult: PropTypes.func.isRequired,
  tableErrors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tableLoaded: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

BulkCreationResult.defaultProps = {
  entity: null,
};

export default BulkCreationResult;
