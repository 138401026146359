import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Dialog, Box, IconButton, CircularProgress,
  makeStyles, Typography, Toolbar, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import Tooltip from '@material-ui/core/Tooltip';
import MuiButton from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from '../../controls/Button';
import DataTable from '../../DataTable/DataTable';
import ImpersonateIcon from '../../../assets/icons/ImpersonateIcon';
import LockResetIcon from '../../../assets/icons/LockResetIcon';
import { history } from '../../../helpers';
import impersonateActions from '../../../redux/actions/impersonate.actions';
import { superAdminActions } from '../../../redux/actions';
import colors from '../../../assets/colors';

const useStyles = makeStyles((theme) => ({
  toolbarTitle: {
    color: '#F79A56',
    flexGrow: 1,
    fontSize: '18px',
    fontWeight: 700,
  },
  linkPerson: {
    fontSize: '12px',
    color: theme.palette.primary.main,
    paddingLeft: '0px',
    textTransform: 'none',
  },
  icon: {
    width: 16,
    height: 16,
    color: '#F79A56',
  },
  circularProgress: {
    color: '#F79A56',
  },
  tableBody: {
    fontSize: '12px',
  },
  titleDialog: {
    fontSize: '14px',
    fontWeight: 500,
  },
  subtitleDialog: {
    color: colors.darkGray,
    fontSize: '12px',
    fontWeight: 300,
    paddingLeft: '4px',
    paddingTop: '8px',
  },
}));

const CompanyUsersDataTable = ({ companyId }) => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);
  const deletingCompany = useSelector((state) => state.superAdmin.deleting);
  const requestingPasswordChange = useSelector((state) => state.superAdmin.loading);
  const userChanges = useSelector((state) => state.superAdmin.data);
  const impersonatorLoading = useSelector((state) => state.impersonate.requesting);
  const [selectedUser, setSelectedUser] = useState({ userId: null, email: null });
  const [deleteCompanyDialog, setDeleteCompanyDialog] = useState(false);
  const [userChangePassDialog, setUserChangePassDialog] = useState(false);
  const impersonarUserTitle = intl.formatMessage({ id: 'user.impersonate.title', defaultMessage: 'Impersonar usuario' });
  const userTitle = intl.formatMessage({ id: 'user.usersTitle', defaultMessage: 'Usuarios' });
  const backText = intl.formatMessage({ id: 'app.back', defaultMessage: 'Volver' });
  const deleteCompanyButtonText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'Eliminar' });
  const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const doneButtonText = intl.formatMessage({ id: 'app.done', defaultMessage: 'Finalizar' });
  const nextButtonText = intl.formatMessage({ id: 'app.next', defaultMessage: 'Siguiente' });
  const deleteText = intl.formatMessage({ id: 'superAdmin.deleteCompany', defaultMessage: 'Eliminar Empresa' });
  const deleteCompanyText = intl.formatMessage({ id: 'superAdmin.deleteCompanyContentModal', defaultMessage: '¿Está seguro que quiere eliminar la empresa?' });
  const hasNoPersonLabel = intl.formatMessage({ id: 'user.doesNotHave', defaultMessage: 'No posee' });
  const activateAccount = intl.formatMessage({ id: 'superAdmin.activateAccount', defaultMessage: 'La cuenta del usuario pasará a estar confirmada en caso de que este aún no la haya confirmado.' });
  const newPasswordSendText = intl.formatMessage({ id: 'superAdmin.newPasswordSend', defaultMessage: 'Se envió al usuario un correo con su nueva contraseña provisoria.' });
  const temporaryPasswordText = intl.formatMessage({ id: 'superAdmin.temporaryPassword', defaultMessage: 'Contraseña provisoria: ' });
  const userChangePassBody = intl.formatMessage({ id: 'superAdmin.userChangePassBody', defaultMessage: '¿Está seguro que quiere restablecer la contraseña del usuario?' });
  const userChangePasswordTitle = intl.formatMessage({ id: 'superAdmin.userChangePassword', defaultMessage: 'Restablecer contraseña del usuario' });

  function toggleDeleteDialog() {
    setDeleteCompanyDialog(!deleteCompanyDialog);
  }

  function toggleUserChangePassDialog() {
    setUserChangePassDialog((prev) => !prev);
  }

  function deleteCompany() {
    dispatch(superAdminActions.deleteCompany(companyId));
  }

  function resetUserPassword() {
    if (userChanges) {
      dispatch(superAdminActions.clearSuperAdmin());
      return toggleUserChangePassDialog();
    }
    return dispatch(superAdminActions.resetUserPassword(selectedUser.userId, ''));
  }

  function actionsButtonImpersonate(userId) {
    return (impersonatorLoading && selectedUser.userId === userId)
      ? (
        <Box p={1}>
          <CircularProgress size={20} className={classes.circularProgress} />
        </Box>
      )
      : (
        <Tooltip title={impersonarUserTitle}>
          <IconButton
            onClick={() => {
              setSelectedUser({ userId });
              dispatch(impersonateActions.impersonateUser(userId, companyId, user));
            }}
          >
            <ImpersonateIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      );
  }

  function actionsToolsButton(userId, email) {
    if (requestingPasswordChange) {
      return (
        <Box p={1}>
          <CircularProgress size={20} className={classes.circularProgress} />
        </Box>
      );
    }
    return (
      <Tooltip title={userChangePasswordTitle}>
        <IconButton
          onClick={() => {
            setSelectedUser({ userId, email });
            toggleUserChangePassDialog();
          }}
        >
          <LockResetIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    );
  }

  const columns = [
    {
      name: 'id',
      options: { display: 'excluded', filter: false },
    },
    {
      name: 'username',
      label: intl.formatMessage({ id: 'user.email', defaultMessage: 'Email' }),
      options: {
        customBodyRender: (value) => <Typography className={classes.tableBody}>{value}</Typography>,
      },
    },
    {
      name: 'roles',
      label: intl.formatMessage({ id: 'user.role', defaultMessage: 'Rol' }),
      options: {
        filter: false,
        sort: false,

        customBodyRender: (companies) => {
          const company = companies.find((item) => String(item.id) === String(companyId));
          return <Typography className={classes.tableBody}>{String(company.roles)}</Typography>;
        },
        customDownloadBodyRender: (companies) => {
          const company = companies.find((item) => String(item.id) === String(companyId));
          return company.roles;
        },
      },
    },
    {
      name: 'linkedPerson',
      label: intl.formatMessage({ id: 'user.linkedPerson', defaultMessage: 'Persona asociada' }),
      options: {
        filter: false,
        sort: false,

        customBodyRender: (companies, meta) => {
          const company = companies.find((item) => String(item.id) === String(companyId));
          if (company.person !== null) {
            const isOwnerOfCompany = meta.rowData[0] === meta.rowData[5];
            return (
              <MuiButton
                display="inline"
                className={classes.linkPerson}
                startIcon={isOwnerOfCompany && (<BusinessIcon />)}
              >
                {`${company.person.names} ${company.person.surnames}`}
              </MuiButton>
            );
          }
          return <Typography className={classes.tableBody}>{hasNoPersonLabel}</Typography>;
        },
        customDownloadBodyRender: (companies) => {
          const company = companies.find((item) => String(item.id) === String(companyId));
          if (company.person !== null) {
            return `${company.person.names} ${company.person.surnames}`;
          }
          return hasNoPersonLabel;
        },
      },
    },
    {
      name: 'lastUsageDatetime',
      label: intl.formatMessage({ id: 'superAdmin.lastUsageDateTimeUser', defaultMessage: 'Última fecha de acceso' }),
      options: {
        customBodyRender: (date) => {
          if (date === '-') return <Typography className={classes.tableBody}>{date}</Typography>;
          return <Typography className={classes.tableBody}>{moment(date).format('DD/MM/YYYY HH:mm [hs.]')}</Typography>;
        },
        filterOptions: {
          renderValue: (date) => moment(date).format('DD/MM/YYYY HH:mm [hs.]'),
        },
        customFilterListOptions: {
          render: (date) => moment(date).format('DD/MM/YYYY HH:mm [hs.]'),
        },
        customDownloadBodyRender: (date) => moment(date).format('DD/MM/YYYY HH:mm [hs.]'),
      },
    },
    {
      name: 'impersonate',
      label: intl.formatMessage({ id: 'user.impersonate', defaultMessage: 'Impersonar' }),
      filter: false,
      sort: false,
      options: {
        filter: false,
        sort: false,

        download: false,
        customBodyRender: (value, meta) => {
          const userId = meta.rowData[0];
          if (user.id === userId) return null;
          return actionsButtonImpersonate(userId);
        },
      },
    },
    {
      name: 'tools',
      label: intl.formatMessage({ id: 'superAdmin.tools', defaultMessage: 'Herramientas' }),
      filter: false,
      sort: false,
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, meta) => {
          const userId = meta.rowData[0];
          const email = meta.rowData[1];
          return actionsToolsButton(userId, email);
        },
      },
    },
  ];

  const keys = ['id', 'email', 'companies', 'companies', 'lastUsageDatetime'];
  const fetchUrl = `companies/${companyId}/with-users`;
  const itemUrl = '/users/';

  return (
    <>
      <Toolbar>
        <Typography className={classes.toolbarTitle} variant="h6">
          {userTitle}
        </Typography>
      </Toolbar>
      <DataTable
        title={userTitle}
        fetchUrl={fetchUrl}
        itemUrl={itemUrl}
        keys={keys}
        columns={columns}
        property="users"
        propertyToInject="creatorUser"
      />
      <Box display="flex" alignItems="center" justifyContent="flex-end" mt={3} mr={4}>
        <Button
          onClick={() => history.push('/companies')}
          variant="outlined"
          text={backText}
        />
        <Button
          type="button"
          color="secondary"
          onClick={() => toggleDeleteDialog()}
          text={deleteText}
          style={{ fontSize: '10px', fontWeight: 600 }}
        />
      </Box>
      <Dialog open={deleteCompanyDialog} onClose={() => toggleDeleteDialog()} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{deleteText}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {deleteCompanyText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            text={cancelButtonText}
            onClick={() => { toggleDeleteDialog(); }}
            disabled={deletingCompany}
          />
          <Button
            className={classes.menuButton}
            text={deleteCompanyButtonText}
            color="secondary"
            loading={deletingCompany}
            onClick={deleteCompany}
          />
        </DialogActions>
      </Dialog>

      <Dialog open={userChangePassDialog} disableBackdropClick onClose={() => toggleUserChangePassDialog()} fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{userChangePasswordTitle}</DialogTitle>
        <DialogContent>
          {userChanges
            ? (
              <>
                <Typography align="left" className={classes.titleDialog}>
                  {newPasswordSendText}
                </Typography>
                <Typography align="left" className={classes.titleDialog} style={{ marginTop: 12 }}>
                  Email:
                  {' '}
                  <b>{userChanges.email}</b>
                </Typography>
                <Typography align="left" className={classes.titleDialog}>
                  {temporaryPasswordText}
                  {' '}
                  <b>{userChanges.password}</b>
                </Typography>
              </>
            )
            : (
              <>
                <Typography align="left" className={classes.titleDialog}>
                  {userChangePassBody}
                </Typography>
                <Typography align="left" className={classes.titleDialog} style={{ paddingLeft: 4, paddingTop: 8 }}>
                  Email:
                  {' '}
                  <b>{selectedUser.email}</b>
                </Typography>
                <Typography align="left" className={classes.subtitleDialog}>
                  {activateAccount}
                </Typography>
              </>
            )}
        </DialogContent>
        <DialogActions>
          {!userChanges && (
            <Button
              text={cancelButtonText}
              onClick={() => { toggleUserChangePassDialog(); }}
              variant="outlined"
              disabled={requestingPasswordChange}
            />
          )}
          <Button
            text={userChanges ? doneButtonText : nextButtonText}
            loading={requestingPasswordChange}
            onClick={resetUserPassword}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

CompanyUsersDataTable.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default CompanyUsersDataTable;
