import React from 'react';
import { Box } from '@material-ui/core';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import ReactHtmlParser from 'react-html-parser';

import { authHeader } from '../../../helpers';

import './styles.css';

const DocumentViewer = ({
    content = '',
    fileType = '',
    uri = ''
}) => {
    if (content?.length) {
        return (
            <Box display="flex" flexDirection="column">
                {ReactHtmlParser(content)}
            </Box>
        )
    }

    return (
        <DocViewer
            config={{
                header: {
                    disableHeader: true,
                },
                pdfVerticalScrollByDefault: true,
            }}
            documents={[{ fileType, uri }]}
            pluginRenderers={DocViewerRenderers}
            prefetchMethod="GET"
            requestHeaders={authHeader()}
        />
    );
};

export default DocumentViewer;
