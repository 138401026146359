import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Breadcrumbs, Typography } from '@material-ui/core';

const LoadingCompanyUsersPageBreadcrumbs = () => {
    const intl = useIntl();

    const titleLabel = intl.formatMessage({ id: 'superAdmin.systemAdmin', defaultMessage: 'Administración del sistema' });
    const titleDataTable = intl.formatMessage({ id: 'superAdmin.companies', defaultMessage: 'Empresas' });

    return (
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/superadmin" style={{ textDecoration: 'none' }}>
                <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{titleLabel}</Typography>
            </Link>
            <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{titleDataTable}</Typography>
        </Breadcrumbs>
    );
};

export default LoadingCompanyUsersPageBreadcrumbs;
