import React from 'react';
import {
  Router, Switch, Route, Redirect,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import * as Pages from '../components';
import NotFound from '../components/ErrorPages/NotFound/NotFound';
import { history } from '../helpers';
import theme from '../assets/themeConfig';
import { PrivateRouteWithParamsAndSubscription, LoggedInRoute } from '../components';
import SnackbarAlert from '../components/controls/SnackbarAlert';
import ModalStripeAlert from '../components/controls/ModalStripeAlert';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert />
      <ModalStripeAlert />
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={Pages.IndexPage} />
          <LoggedInRoute path="/login" exact component={Pages.AuthPage} />
          <LoggedInRoute path="/company" exact component={Pages.AuthSelectCompany} />
          <Route path="/logout" exact component={Pages.AuthPage} />
          <Route path="/register" exact component={Pages.RegisterPage} />
          <Route path="/auth/confirmation" exact component={Pages.EmailConfirmationPage} />
          <Route path="/auth/password/forgot" exact component={Pages.ForgotPasswordPage} />
          <Route path="/auth/password/reset" exact component={Pages.ResetPasswordPage} />
          <Redirect exact from="/home" to="/processes/map" />
          <Redirect exact from="/users/upload" to="/people/upload" />
          <PrivateRouteWithParamsAndSubscription path="/calendar" exact component={Pages.CalendarPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/processes" toolName="processManager" component={Pages.ProcessPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/processes/map" toolName="processMap" component={Pages.ProcessMapPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/processes/new" toolName="processManager" component={Pages.NewProcessPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/processes/:id" toolName="processManager" component={Pages.ProcessDetailsPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/processes/:id/edit" toolName="processManager" component={Pages.EditProcessPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/documents" component={Pages.DocumentsPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/documents/new" component={Pages.NewDocumentPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/documents/:id/new" component={Pages.NewVersionDocumentPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/documents/:id/:id_version" component={Pages.DocumentDetailsPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/documents/:id" component={Pages.DocumentDetailsPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/stripe/plans" toolName="subscription" component={Pages.StripePage} />
          <PrivateRouteWithParamsAndSubscription exact path="/people" toolName="peopleManager" component={Pages.PersonPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/people/new" toolName="peopleManager" component={Pages.NewPersonPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/people/upload" component={Pages.UserBulkCreation} />
          <PrivateRouteWithParamsAndSubscription exact path="/people/result" component={Pages.UserBulkCreationResult} />
          <PrivateRouteWithParamsAndSubscription exact path="/people/:id" toolName="peopleManager" component={Pages.PersonDetailsPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/people/:id/edit" toolName="peopleManager" component={Pages.EditPersonPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/user/profile" component={Pages.UserProfilePage} />
          <PrivateRouteWithParamsAndSubscription exact path="/users" component={Pages.UserPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/users/new" component={Pages.NewUserPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/users/:id" component={Pages.UserDetailsPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/users/:id/edit" component={Pages.EditUserPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/company/configuration" toolName="subscription" component={Pages.CompanyConfigPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/superadmin" component={Pages.SuperAdminPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/companies" component={Pages.CompaniesPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/companies/new" component={Pages.NewCompanyPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/companies/:id" component={Pages.CompanyUsersPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/outlines" component={Pages.OutlinePage} />
          <PrivateRouteWithParamsAndSubscription exact path="/outlines/new" component={Pages.NewOutlinePage} />
          <PrivateRouteWithParamsAndSubscription exact path="/outlines/:id" component={Pages.OutlineDetailsPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/outlines/:id/edit" component={Pages.EditOutlinePage} />
          <PrivateRouteWithParamsAndSubscription exact path="/evaluations" toolName="evaluationManager" component={Pages.OutlineEvaluationPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/evaluations/new" toolName="evaluationManager" component={Pages.NewOutlineEvaluationPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/evaluations/:id/edit" toolName="evaluationManager" component={Pages.EditOutlineEvaluationPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/evaluations/:id" toolName="evaluationManager" component={Pages.OutlineEvaluationDetailsPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/trainings" toolName="trainingManager" component={Pages.TrainingPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/trainings/new" toolName="trainingManager" component={Pages.NewTrainingPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/trainings/:id" toolName="trainingManager" component={Pages.TrainingDetailsPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/trainings/:id/edit" toolName="trainingManager" component={Pages.EditTrainingPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/foda" toolName="foda" component={Pages.FodaPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/foda/edit" toolName="foda" component={Pages.FodaFormPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/risks" toolName="riskManager" component={Pages.RiskPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/risks/new" toolName="riskManager" component={Pages.NewRiskPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/risks/upload" toolName="riskManager" component={Pages.RiskBulkCreation} />
          <PrivateRouteWithParamsAndSubscription exact path="/risks/result" toolName="riskManager" component={Pages.RiskBulkCreationResult} />
          <PrivateRouteWithParamsAndSubscription exact path="/risks/:id/new" toolName="riskManager" component={Pages.RiskEvaluationForm} />
          <PrivateRouteWithParamsAndSubscription exact path="/risks/:id/evaluation/:evaluationId/edit" toolName="riskManager" component={Pages.EditEvaluationPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/risks/:id/edit" toolName="riskManager" component={Pages.EditRiskPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/risks/:id" toolName="riskManager" component={Pages.RiskDetailsPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/deviations" toolName="deviationManager" component={Pages.DeviationPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/deviations/board" toolName="deviationDashboard" component={Pages.DeviationBoardPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/deviations/new" toolName="deviationManager" component={Pages.NewDeviationPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/deviations/upload" toolName="deviationManager" component={Pages.DeviationBulkCreation} />
          <PrivateRouteWithParamsAndSubscription exact path="/deviations/result" toolName="deviationManager" component={Pages.DeviationBulkCreationResult} />
          <PrivateRouteWithParamsAndSubscription exact path="/deviations/:id" toolName="deviationManager" component={Pages.DeviationDetailPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/deviations/:id/edit" toolName="deviationManager" component={Pages.EditDeviationDetailPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/deviations/:id/:versionId" toolName="deviationManager" component={Pages.DeviationVersionDetailPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/objectives" component={Pages.ObjectivePage} />
          <PrivateRouteWithParamsAndSubscription exact path="/objectives/new" component={Pages.NewObjectivePage} />
          <PrivateRouteWithParamsAndSubscription exact path="/objectives/upload" component={Pages.ObjectiveBulkCreation} />
          <PrivateRouteWithParamsAndSubscription exact path="/objectives/result" component={Pages.ObjectiveBulkCreationResult} />
          <PrivateRouteWithParamsAndSubscription exact path="/objectives/:id" component={Pages.ObjectiveDetailPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/objectives/:id/edit" component={Pages.EditObjectiveDetailPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/orgchart" toolName="organizationChart" component={Pages.OrgChartPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/affiliates" component={Pages.AffiliatePage} />
          <PrivateRouteWithParamsAndSubscription exact path="/affiliates/new" component={Pages.NewAffiliatePage} />
          <PrivateRouteWithParamsAndSubscription exact path="/affiliates/:id" component={Pages.AffiliateDetailPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/providers" component={Pages.ProviderPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/providers/new" component={Pages.NewProviderPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/providers/upload" component={Pages.ProviderBulkCreation} />
          <PrivateRouteWithParamsAndSubscription exact path="/providers/result" component={Pages.ProviderBulkCreationResult} />
          <PrivateRouteWithParamsAndSubscription exact path="/providers/rejected" component={Pages.ProviderRejectedPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/providers/:id" component={Pages.ProviderDetailPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/providers/:id/edit" component={Pages.EditProviderPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/workFields" component={Pages.WorkFieldPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/workFields/new" component={Pages.NewWorkFieldPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/workFields/:id" component={Pages.WorkFieldDetailPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/workFields/:id/edit" component={Pages.EditWorkFieldPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/workFieldEvaluations" toolName="workFieldEvaluation" component={Pages.WorkFieldEvaluationPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/workFieldEvaluations/new" toolName="workFieldEvaluation" component={Pages.NewWorkFieldEvaluationPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/workFieldEvaluations/:id/edit" toolName="workFieldEvaluation" component={Pages.EditWorkFieldEvaluationPage} />
          <PrivateRouteWithParamsAndSubscription exact path="/workFieldEvaluations/:id" toolName="workFieldEvaluation" component={Pages.WorkFieldEvaluationDetailsPage} />
          <Route path="*" exact component={NotFound} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
