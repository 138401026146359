import React, { useEffect, useRef } from 'react';
import * as joint from 'jointjs';
import { Box, Fab, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import svgPanZoom from 'svg-pan-zoom';
import PropTypes from 'prop-types';
import {
  ProcessLink, ProcessBox, ProcessArea, createPaper,
} from '../../jointjs/CustomElements';

const useStyles = makeStyles((theme) => ({
  canvas: {
    width: '100%',
    marginLeft: 40,
    marginBottom: 20,
    backgroundColor: '#FFFFFF',
    cursor: 'pointer',
  },
  zoomButton: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    boxShadow: 'none',
  },
  main: {
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderRadius: '8px',
    marginTop: '16px',
  },
}));

function PreviewProcessMap({ exampleProcessMap }) {
  const classes = useStyles();
  const canvasPreview = useRef(null);
  const paperPreview = useRef(null);
  const svgPanPreview = useRef(null);
  const graphPreview = useRef(null);

  function handleZoomIn() {
    if (svgPanPreview.current) svgPanPreview.current.zoomIn();
  }

  function handleZoomOut() {
    if (svgPanPreview.current) svgPanPreview.current.zoomOut();
  }

  function handleCanvas() {
    svgPanPreview.current = svgPanZoom('#canvasPreview svg', {
      center: true,
      mouseWheelZoomEnabled: false,
      zoomEnabled: true,
      panEnabled: true,
      fit: true,
      minZoom: 0.5,
      maxZoom: 2,
      zoomScaleSensitivity: 0.1,
    });
    const { x: initialHorizontalPosition } = svgPanPreview.current.getPan();
    const { cells } = graphPreview.current.toJSON();
    const verticalPositions = cells.map((cell) => cell.position && cell.position.y).filter(Number);
    const firstElement = Math.min(...verticalPositions);
    const marginTop = 25;
    const initialVerticalPosition = (-1 * firstElement) + marginTop;
    svgPanPreview.current.pan({ x: initialHorizontalPosition, y: initialVerticalPosition });
  }

  useEffect(() => {
    if (exampleProcessMap) {
      joint.shapes.custom = {};
      joint.shapes.custom.ProcessLink = ProcessLink;
      joint.shapes.custom.ProcessBox = ProcessBox;
      joint.shapes.custom.ProcessArea = ProcessArea;

      graphPreview.current = new joint.dia.Graph([], { cellNamespace: joint.shapes });
      paperPreview.current = createPaper(canvasPreview.current, graphPreview.current, { width: '94.5%', minHeight: 270, height: '45vh' });

      graphPreview.current.fromJSON(JSON.parse(exampleProcessMap));
      if (canvasPreview.current) handleCanvas();
      paperPreview.current.unfreeze();
    }
  }, [exampleProcessMap]);

  return (
    <Box style={{ cursor: 'context-menu' }} className={classes.main}>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Fab
          id="zoomin"
          onClick={handleZoomIn}
          size="small"
          color="default"
          aria-label="add"
          className={classes.zoomButton}
        >
          <AddIcon />
        </Fab>
        <Fab
          id="zoomout"
          onClick={handleZoomOut}
          size="small"
          color="default"
          aria-label="add"
          className={classes.zoomButton}
        >
          <RemoveIcon />
        </Fab>
      </Box>
      <div
        className={classes.canvas}
        ref={canvasPreview}
        id="canvasPreview"
        data-filter="all"
        style={{ backgroundColor: '#FFFFFF', height: 500 }}
      />
    </Box>
  );
}

PreviewProcessMap.propTypes = {
  exampleProcessMap: PropTypes.string.isRequired,
};

export default PreviewProcessMap;
