import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Breadcrumbs, LinearProgress, makeStyles, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OutlineEvaluationForm from '../OutlineEvaluationForm';
import { outlineEvaluationActions, personActions } from '../../../redux/actions';
import PageLayout from '../../PageLayout';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  toolbar: {
    backgroundColor: '#F8F8F8',
    zIndex: 1,
  },
  toolbarTitle: {
    color: '#F79A56',
    flexGrow: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    backgroundColor: '#F8F8F8',
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(2),
    },
  },
}));

function EditOutlineEvaluationPage(props) {
  const classes = useStyles();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'outlineEvaluation.edit.title', defaultMessage: 'Modificar evaluación de perfil' });
  const evaluationsText = intl.formatMessage({ id: 'outlineEvaluation.evaluationsText', defaultMessage: 'Evaluaciones' });
  const modifyText = intl.formatMessage({ id: 'app.modify', defaultMessage: 'Modificar' });
  const dispatch = useDispatch();
  const outlineEvaluationId = props.match.params.id;
  const outlineEvaluation = useSelector((state) => state.outlineEvaluation.data);
  const people = useSelector((state) => state.person.people);

  useEffect(() => {
    if (!outlineEvaluation
          || (outlineEvaluation && JSON.stringify(outlineEvaluation.id) !== outlineEvaluationId)) {
      dispatch(outlineEvaluationActions.get(outlineEvaluationId));
    }
    if (!people) {
      dispatch(personActions.getAll());
    }
  }, [dispatch, outlineEvaluationId, outlineEvaluation, people]);

  function EditOutlineEvaluationBreadcrumbs() {
    const outlineEvaluationLink = `/evaluations/${outlineEvaluationId}`;

    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/evaluations" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{evaluationsText}</Typography>
        </Link>
        <Link color="inherit" to={outlineEvaluationLink} style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{`${outlineEvaluation.person.names} ${outlineEvaluation.person.surnames} : ${outlineEvaluation.outline.name}`}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{modifyText}</strong></Typography>
      </Breadcrumbs>
    );
  }

  function EditOutlineEvaluationLoadingBreadcrumbs() {
    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/evaluations" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{evaluationsText}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{modifyText}</strong></Typography>
      </Breadcrumbs>
    );
  }

  return (
    <>
      {outlineEvaluation
        ? (
          <PageLayout title={title} breadCrumbs={<EditOutlineEvaluationBreadcrumbs />}>
            <OutlineEvaluationForm edit data={outlineEvaluation} people={people} />
          </PageLayout>
        )
        : (
          <PageLayout title={title} breadCrumbs={<EditOutlineEvaluationLoadingBreadcrumbs />}>
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
}

export default EditOutlineEvaluationPage;
