import { providerConstants } from '../../constants';

function provider(state = {}, action) {
  switch (action.type) {
    case providerConstants.CREATE_REQUEST:
      return { creating: true };
    case providerConstants.CREATE_SUCCESS:
      return { data: action.created, created: true };
    case providerConstants.CREATE_FAILURE:
      return { creating: false };

    case providerConstants.GET_REQUEST:
      return { requesting: true };
    case providerConstants.GET_SUCCESS:
      return { data: action.provider };
    case providerConstants.GET_FAILURE:
      return {};

    case providerConstants.GET_ALL_REQUEST:
      return { requesting: true };
    case providerConstants.GET_ALL_SUCCESS:
      return { providers: action.provider };
    case providerConstants.GET_ALL_FAILURE:
      return {};

    case providerConstants.UPDATE_REQUEST:
      return { data: state.data, updating: true };
    case providerConstants.UPDATE_SUCCESS:
      return { data: action.updatedProvider, updated: true };
    case providerConstants.UPDATE_FAILURE:
      return {};

    case providerConstants.DELETE_REQUEST:
      return { data: state.data, deleting: true };
    case providerConstants.DELETE_SUCCESS:
      return { deleted: true };
    case providerConstants.DELETE_FAILURE:
      return {};

    case providerConstants.CREATE_BULK_REQUEST:
      return { creating: true };
    case providerConstants.CREATE_BULK_SUCCESS:
      return { bulkCreationPreviewResult: action.bulkCreationPreviewResult };
    case providerConstants.CREATE_BULK_FAILURE:
      return { error: action.error };

    case providerConstants.CONFIRM_CREATE_BULK_REQUEST:
      return { ...state, confirming: true };
    case providerConstants.CONFIRM_CREATE_BULK_SUCCESS:
      return { bulkCreationPreviewResult: null, noLinkedWorkField: true };
    case providerConstants.CONFIRM_CREATE_BULK_FAILURE:
      return { error: action.error };

    default:
      return state;
  }
}

export default provider;
