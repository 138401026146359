import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { providerActions } from '../../../redux/actions';
import BulkCreation from '../../BulkUpload/BulkCreation';
import { downloadHelper } from '../../../helpers';

const ProviderBulkCreation = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const linkDownload = downloadHelper.listProvidersExample;
  const linkEntity = '/providers';
  const text = {
    title: intl.formatMessage({ id: 'providers.title', defaultMessage: 'Proveedores' }),
    bulkCreationTitle: intl.formatMessage({ id: 'providers.bulkCreationTitle', defaultMessage: 'Carga múltiple de proveedores' }),
    bulkCreationBreadcrumbs: intl.formatMessage({ id: 'providers.bulkCreationBreadcrumbs', defaultMessage: 'Carga múltiple' }),
    bulkCreationSubtitle: intl.formatMessage({ id: 'providers.bulkCreationSubtitle', defaultMessage: 'Usted puede crear varios proveedores a la vez completando nuestra plantilla. ' }),
    bulkCreationSteps: intl.formatMessage({ id: 'providers.bulkCreationSteps', defaultMessage: 'Pasos:' }),
    bulkCreationStep1: intl.formatMessage({ id: 'providers.bulkCreationStep1', defaultMessage: '1. Descargue la plantilla de Excel.' }),
    bulkCreationStep2: intl.formatMessage({ id: 'providers.bulkCreationStep2', defaultMessage: '2. Complete la plantilla con los datos de los proveedores deseados.' }),
    bulkCreationStep3: intl.formatMessage({ id: 'providers.bulkCreationStep3', defaultMessage: '3. Cargue la plantilla en nuestro sistema aquí abajo.' }),
    downloadListExample: intl.formatMessage({ id: 'providers.downloadListExample', defaultMessage: 'Descargar plantilla' }),
    titlePageLayout: intl.formatMessage({ id: 'providers.title', defaultMessage: 'Proveedores' }),
  };

  function handleDispatcher(formData) {
    return (
      dispatch(providerActions.createBulk(formData))
    );
  }

  return (
    <BulkCreation
      text={text}
      linkEntity={linkEntity}
      linkDownload={linkDownload}
      handleDispatcher={handleDispatcher}
    />
  );
};

export default ProviderBulkCreation;
