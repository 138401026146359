import React from 'react';
import { FormattedMessage } from 'react-intl';
import { documentConstants } from '../../../constants';

const documentLabels = {
    [documentConstants.PROCESS_DOCUMENT]: <FormattedMessage id="document.entityProcess" defaultMessage="Procesos" />,
    [documentConstants.PERSON_DOCUMENT]: <FormattedMessage id="document.entityPerson" defaultMessage="Personas" />,
    [documentConstants.DEVIATION_DOCUMENT]: <FormattedMessage id="document.entityDeviation" defaultMessage="Desvíos" />,
    [documentConstants.TRAINING_DOCUMENT]: <FormattedMessage id="document.entityTraining" defaultMessage="Capacitaciones" />,
    [documentConstants.PROVIDER_DOCUMENT]: <FormattedMessage id="document.entityProvider" defaultMessage="Proveedores" />,
    [documentConstants.WORKFIELD_EVALUATION_DOCUMENT]: <FormattedMessage id="document.entityWorkFieldEvaluation" defaultMessage="Evaluaciones de rubro" />,
};

const documentUrls = {
    [documentConstants.PROCESS_DOCUMENT]: (id) => `/processes/${id}`,
    [documentConstants.PERSON_DOCUMENT]: (id) => `/people/${id}`,
    [documentConstants.DEVIATION_DOCUMENT]: (id) => `/deviations/${id}`,
    [documentConstants.TRAINING_DOCUMENT]: (id) => `/trainings/${id}`,
    [documentConstants.PROVIDER_DOCUMENT]: (id) => `/providers/${id}`,
    [documentConstants.WORKFIELD_EVALUATION_DOCUMENT]: (id) => `/workFieldEvaluations/${id}`,
};

const itemUrl = 'documents/';

const keys = ['id', 'title', 'type', 'relatedEntities', 'creatorUserNames', 'lastVersion', 'lastVersion', 'lastVersion', 'blobUrl'];

const getEntityLabel = (type) => documentLabels[type] || '-';
const getEntityUrl = (type, id) => (documentUrls[type] ? documentUrls[type](id) : '-');

export {
    getEntityLabel,
    getEntityUrl,
    itemUrl,
    keys,
}