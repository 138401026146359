import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs, Typography,
} from '@material-ui/core';
import PageLayout from '../../PageLayout';
import AffiliateForm from '../AffiliateForm';

const NewAffiliatePage = () => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'affiliates.title', defaultMessage: 'Afiliados' });
  const newAffiliate = intl.formatMessage({ id: 'app.new', defaultMessage: 'Nuevo' });

  function NewAffiliatePageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/affiliates" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{title}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{newAffiliate}</Typography>
      </Breadcrumbs>
    );
  }

  return (
    <PageLayout
      title={title}
      breadCrumbs={<NewAffiliatePageBreadcrumbs />}
    >
      <AffiliateForm />
    </PageLayout>
  );
};

export default NewAffiliatePage;
