/* eslint-disable react/prop-types */
import React from 'react';
import MuiListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { stripePlanSpecs } from '../../constants';
import tutorialActions from '../../redux/actions/tutorial.actions';
import { useDispatch } from 'react-redux';

const ListItem = withStyles({
  root: {
    '&.Mui-disabled': { pointerEvents: 'auto' },
  },
})(MuiListItem);

const ListItemWithTooltip = ({
  disabled, onClick, component, toolName, ...other
}) => {
  const company = useSelector((state) => state.company.data);
  const listEnabledTools = useSelector((state) => state.stripe.data) || {};
  const currentPlan = company 
    && company.plan 
    && stripePlanSpecs.getCurrentPlan(company.plan);
  const neededPlan = disabled
    && listEnabledTools
    && stripePlanSpecs.getNeededPlan(listEnabledTools[toolName].plan);

  const tooltipText = (
    <FormattedMessage
      id="stripe.disabledTools.UpdatePlan"
      defaultMessage={`El plan ${currentPlan} no incluye esta funcionalidad. Actualiza tu cuenta al plan starter para poder acceder`}
      values={{
        currentPlan: (<b>{currentPlan}</b>),
        neededPlan: (<b>{neededPlan}</b>),
        link: (
          <b>
            <a style={{ textDecoration: 'none', color: 'inherit' }} href="/stripe/plans" target="_blank" rel="noopener noreferrer">
              Actualiza tu cuenta
            </a>
          </b>
        ),
      }}
    />
  );

  const adjustedProps = {
    disabled,
    component: disabled ? 'div' : component,
    onClick: disabled ? undefined : onClick,
  };

  return (
    <Tooltip
      interactive
      title={tooltipText}
      disableHoverListener={!disabled}
    >
      <ListItem {...other} {...adjustedProps} />
    </Tooltip>
  );
};

const CustomListItem = ({ children, toolName, isProcessesNew, onClick, ...other }) => {
  const listEnabledTools = useSelector((state) => state.stripe.data) || {};
  const step = useSelector((state) => state.tutorial.step) || null;
  const user = useSelector((state) => state.authentication.user);
  const dispatch = useDispatch();

  const isDisabled = (tool) => {
    if (!tool || (listEnabledTools[tool] === undefined)) return false;
    return !listEnabledTools[tool].isValid;
  };

  const handleOnClick = () => {
    if (!step) {
      return;
    }

    if (step === 2 && isProcessesNew) {
      dispatch(tutorialActions.setTutorialStep(3))
      return;
    } else {
      if (user) dispatch(tutorialActions.setTutorialAsCompleted(8, user.id));
    };
  };

  return (
    <ListItemWithTooltip disabled={isDisabled(toolName)} toolName={toolName} onClick={onClick || handleOnClick} {...other}>
      {children}
    </ListItemWithTooltip>
  );
};

export default CustomListItem;
