import React from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography
} from '@material-ui/core';

import { CloseIcon } from '../../../../assets/icons';
import DocumentViewer from '../../DocumentViewer';
import useStyles from './useStyles';

const DocumentPreviewModal = ({
    documentState,
    handleClose,
    isOpen,
}) => {
    const classes = useStyles();
    const isPDF = !['docx', 'doc'].includes(documentState.documentInfo?.fileType);

    return (
        <Dialog classes={{ paper: classes.dialog }} fullWidth maxWidth="md" open={isOpen}>
            <DialogTitle >
                <Typography align="center" variant="body1">
                    {documentState.documentInfo.title}
                </Typography>
            </DialogTitle>
            <Box className={classes.closeIconContainer}>
                <IconButton aria-label="close-modal" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon className={classes.closeIcon} />
                </IconButton>
            </Box>
            <DialogContent style={{ overflow: isPDF ? 'auto' : 'hidden' }}>
                <DocumentViewer
                    content={documentState.documentInfo.content}
                    fileType={documentState.documentInfo.fileType}
                    uri={documentState.documentInfo.uri}
                />
            </DialogContent>
        </Dialog>
    );
};

export default DocumentPreviewModal;
