import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog, DialogTitle, Typography, Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { exampleCompanyActions } from '../../../redux/actions';
import ContentCopyOutlined from '../../../assets/icons/ContentCopyOutlined';
import EditOutlined from '../../../assets/icons/EditOutlined';
import { exampleCompanyConstants } from '../../../constants';

const useStyles = makeStyles(() => ({
  item: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: 109,
    justifyContent: 'center',
    margin: '0px 10px',
    textDecoration: 'none',
    width: 125,
    transition: 'transform .5s, box-shadow 1s',
    '&:hover': {
      transform: 'scale(1.02) perspective(0px)',
    },
  },
  icon: {
    color: '#DADADA',
    height: '37px',
    width: '37px',
  },
  textHeader: {
    fontSize: '11px',
    fontWeight: 600,
    padding: '8px 12px 0px',
    textAlign: 'center',
  },
  welcomeTitle: {
    fontSize: '14px',
    fontWeight: 700,
    paddingTop: '40px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  welcomeBodyText: {
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'center',
  },
  welcomeBodyText2: {
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center',
  },

}));

const ScreenWelcomeModal = ({ handleChangeScreen }) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const exampleCompanies = useSelector((state) => state.exampleCompany.data);
  const showWelcomeModal = useSelector((state) => state.modals.showWelcomeModal);

  const processMapText = intl.formatMessage({ id: 'processMap.title', defaultMessage: 'Mapa de procesos' });
  const welcomeTitleLabel = intl.formatMessage({ id: 'exampleCompany.welcomeTitle', defaultMessage: '¡Bienvenido a Pertix!' });
  const welcomeBody1Label = intl.formatMessage({ id: 'exampleCompany.welcomeBody1', defaultMessage: 'Los mapas de procesos son una herramienta de gestión' });
  const welcomeBody2Label = intl.formatMessage({ id: 'exampleCompany.welcomeBody2', defaultMessage: 'que se utiliza para visualizar el flujo de trabajo.' });
  const selectOptionLabel = intl.formatMessage({ id: 'exampleCompany.selectOption', defaultMessage: 'Seleccioná una opción para continuar:' });
  const loadPreMadeMapLabel = intl.formatMessage({ id: 'exampleCompany.loadPreMadeMap', defaultMessage: 'Cargar un mapa prediseñado' });
  const startFromZeroLabel = intl.formatMessage({ id: 'exampleCompany.startFromZero', defaultMessage: 'Comenzar de cero' });

  function setShowWelcomeModal() {
    if (showWelcomeModal) {
      dispatch(exampleCompanyActions.closeWelcomeModal());
    } else {
      dispatch(exampleCompanyActions.fromScratch());
    }
  }

  return (
    <Dialog open={Boolean(exampleCompanies)} fullWidth maxWidth="xs" aria-labelledby="dialog-screen-welcome">
      <DialogTitle id="dialog-screen-welcome" className={classes.welcomeTitle} disableTypography>
        {showWelcomeModal ? welcomeTitleLabel : processMapText}
      </DialogTitle>

      <Box display="grid" justifyContent="center">
        <Typography className={classes.welcomeBodyText}>{welcomeBody1Label}</Typography>
        <Typography className={classes.welcomeBodyText}>{welcomeBody2Label}</Typography>
        <Typography className={classes.welcomeBodyText2}>{selectOptionLabel}</Typography>

        <Box display="flex" alignItems="center" justifyContent="center" px={3} pt={3} pb={6}>
          <Box
            boxShadow={1}
            className={classes.item}
            onClick={() => handleChangeScreen(exampleCompanyConstants.SCREEN_OPTIONS)}
          >
            <ContentCopyOutlined className={classes.icon} />
            <Typography display="block" className={classes.textHeader}>{loadPreMadeMapLabel}</Typography>
          </Box>
          <Box boxShadow={1} className={classes.item} onClick={setShowWelcomeModal}>
            <EditOutlined className={classes.icon} />
            <Typography display="block" className={classes.textHeader}>{startFromZeroLabel}</Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

ScreenWelcomeModal.propTypes = {
  handleChangeScreen: PropTypes.func.isRequired,
};

export default ScreenWelcomeModal;
