const InputProps = { disableUnderline: true };

const probabilityImpactValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const riskResultColors = {
  veryHigh: '#BF1919',
  high: '#FB9435',
  mid: '#FDC41C',
  low: '#43A047',
};

const riskResultLimits = {
  veryHigh: 7,
  high: 6,
  mid: 4,
};

const getRiskResult = (riskProbability, riskImpact) => (riskProbability + riskImpact) / 2;

const getRiskColor = (riskCoefficient) => {
  if (riskCoefficient >= riskResultLimits.veryHigh) { return riskResultColors.veryHigh; }
  if (riskCoefficient >= riskResultLimits.high) { return riskResultColors.high; }
  if (riskCoefficient >= riskResultLimits.mid) { return riskResultColors.mid; }
  return riskResultColors.low;
}

export {
  getRiskColor,
  getRiskResult,
  probabilityImpactValues,
  riskResultColors,
  riskResultLimits,
  InputProps,
}