import { makeStyles } from '@material-ui/core';

import colors from '../../../assets/colors';

const useStyles = makeStyles((theme) => ({
    actionIcon: {
        color: '#373435',
        height: 20,
        width: 20
    },
    closeButton: {
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2)
    },
    closeIcon: {
        height: 24,
        width: 24
    },
    closeIconContainer: {
        margin: '10px',
        position: 'absolute',
        right: 0,
        top: 0
    },
    dialog: {
        padding: '20px 60px 20px 60px',
    },
    iconButton: {
        marginRight: '16px',
        padding: '0px',
        textDecoration: 'none'
    },
    iconUpdate: {
        color: colors.orangeLight,
        height: 20,
        width: 20
    },
    internalText: {
        color: 'black',
        fontSize: '12px',
        textDecoration: 'none'
    },
    text: {
        color: 'black',
        display: '-webkit-box',
        fontSize: '12px',
        overflow: 'hidden',
        textDecoration: 'none',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical'
    },
    textBold: {
        fontWeight: 600
    },
}));

export default useStyles;
