import React from 'react';
import {
  Box, Chip, DialogContent, makeStyles, Typography,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CalendarEventDetailPersonCard from '../CalendarEventDetailPersonCard';
import { workFieldEvaluationTexts } from '../../../../constants';
import { workFieldHelper } from '../../../../helpers';
import IconStatus from '../../../WorkFieldEvaluation/IconStatus';

const useStyles = makeStyles(() => ({
  flexCenterBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  dialogContent: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    margin: '20px 0px 10px',
    boxSizing: 'border-box',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    overflow: 'auto',
    scrollbarColor: '#999999',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      height: '0.4em',
      paddingTop: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(0,0,0,.4)',
    },
  },
  title: {
    fontWeight: 600, fontSize: '12px', lineHeight: '12px',
  },
  subtitle: {
    fontWeight: 400, fontSize: '12px', lineHeight: '12px',
  },
}));

const CalendarEventDetailProviderContent = ({ event: { eventResponsible, eventInfo } }) => {
  const classes = useStyles();

  const workFieldItemsParsed = eventInfo && JSON.parse(eventInfo.answers);
  const workFieldItems = eventInfo && eventInfo.workField && eventInfo.workField.workFieldItems
    ? JSON.parse(eventInfo.workField.workFieldItems)
    : [];
  const resultAllEvaluation = workFieldItemsParsed && workFieldHelper
    .getWorkFieldStatus(workFieldItemsParsed, workFieldItemsParsed);

  const totalEvaluationResult = resultAllEvaluation && workFieldHelper.round2decimals(
    workFieldHelper.getAverage(resultAllEvaluation.map((item) => item.result)),
  );

  return (
    <DialogContent className={classes.dialogContent}>
      <Box className={classes.flexCenterBox}>
        <Typography variant="h5" style={{ fontSize: '12px', fontWeight: 600 }}>
          <FormattedMessage id="calendar.responsible" defaultMessage="Responsables" />
        </Typography>
      </Box>
      <Box maxWidth={380}>
        <Box className={classes.infoContainer}>
          {eventResponsible
          && eventResponsible.map((person) => (
            <Box minWidth={128}>
              <CalendarEventDetailPersonCard person={person} />
            </Box>
          ))}
        </Box>
      </Box>

      <Box mt={2}>

        <Box className={classes.infoContainer}>
          <Box>
            <Typography className={classes.title} align="left">
              {workFieldEvaluationTexts.evaluationStatus}
            </Typography>
            <Box mt={1}>
              <Chip
                label={workFieldHelper.getEvaluationStatusLabel(eventInfo.evaluationStatus)}
                style={{
                  color: '#fff',
                  labelColor: '#fff',
                  backgroundColor: workFieldHelper
                    .getEvaluationStatusColor(eventInfo.evaluationStatus),
                }}
                icon={<IconStatus status={eventInfo.evaluationStatus} style={{ color: '#fff' }} />}
              />
            </Box>
          </Box>
          <Box ml={3.5}>
            <Box mb={2.6}>
              <Typography className={classes.title} style={{ marginBottom: '8px' }}>
                {workFieldEvaluationTexts.workFieldEvaluated}
              </Typography>
              <Typography className={classes.subtitle}>
                {eventInfo.workField.name}
              </Typography>
            </Box>
            <Box mb={2.6}>
              <Typography className={classes.title} style={{ marginBottom: '8px' }}>
                {workFieldEvaluationTexts.itemsToBeEvaluated}
              </Typography>
              <Typography className={classes.subtitle}>
                {workFieldItems.map((item) => item.name).join(', ')}
              </Typography>
            </Box>
            <Box mb={2.6}>
              <Typography className={classes.title} style={{ marginBottom: '8px' }}>
                {workFieldEvaluationTexts.finalResult}
              </Typography>
              <Typography className={classes.subtitle}>
                {workFieldHelper.getEvaluationStatusLabel(eventInfo.evaluationStatus)}
                {eventInfo.evaluationStatus !== workFieldHelper.statusEnum.PENDING
                   && ` - ${totalEvaluationResult}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </DialogContent>
  );
};

const eventResponsibleProps = PropTypes.shape({
  address: PropTypes.string,
  birthdate: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.number,
  image: PropTypes.string,
  imageUrl: PropTypes.string,
  names: PropTypes.string,
  personnelFile: PropTypes.string,
  phoneNumber: PropTypes.string,
  role: PropTypes.string,
  surnames: PropTypes.string,
});

CalendarEventDetailProviderContent.propTypes = {
  event: PropTypes.shape({
    eventInfo: PropTypes.shape({
      id: PropTypes.number,
      answers: PropTypes.string,
      date: PropTypes.string,
      evaluationStatus: PropTypes.number,
      provider: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        workFields: PropTypes.arrayOf(PropTypes.shape({
          workFieldId: PropTypes.number,
          workField: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            workFieldItems: PropTypes.string,
          }),
        })),
        productsOrServicesOffered: PropTypes.string,
        cuit: PropTypes.string,
        businessName: PropTypes.string,
        address: PropTypes.string,
        contactPerson: PropTypes.string,
        email: PropTypes.string,
      }),
      workField: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        workFieldItems: PropTypes.string,
        frequency: PropTypes.string,
      }),
      responsiblePeople: PropTypes.arrayOf(eventResponsibleProps),
    }),
    eventResponsible: PropTypes.arrayOf(eventResponsibleProps),
  }).isRequired,
};

export default CalendarEventDetailProviderContent;
