import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import affiliateAction from '../../redux/actions/affiliate.actions';

const IndexPage = ({ location }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const parsedQueryString = queryString.parse(location.search);
  if (parsedQueryString.affiliate) {
    localStorage.setItem('affiliate', parsedQueryString.affiliate);
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      const reminderUserHaveText = intl.formatMessage({ id: 'stripe.reminderUserHave', defaultMessage: 'Recordá que tenés' });
      const thirtyDaysFreeText = intl.formatMessage({ id: 'stripe.30DaysFree', defaultMessage: '30 días gratis' });
      const aboutAnyOfHisPlansText = intl.formatMessage({ id: 'stripe.aboutAnyOfHisPlans', defaultMessage: 'sobre cualquiera de nuestros planes.' });
    
      const successfulAffiliated = {
        title: intl.formatMessage({ id: 'affiliates.congratulations', defaultMessage: '¡Felicitaciones!' }),
        messagePart1: intl.formatMessage({ id: 'affiliates.theAgreementSuccessfullyAppliedPart1', defaultMessage: 'Se aplicó con éxito en tu cuenta el convenio con ' }),
        messagePart2: intl.formatMessage({ id: 'affiliates.theAgreementSuccessfullyAppliedPart2', defaultMessage: 'Contás con un plazo de 60 días para obtener un descuento sobre la suscripción al plan que prefieras.' }),
        messagePart3: `${reminderUserHaveText} ${thirtyDaysFreeText} ${aboutAnyOfHisPlansText}`,
        button: intl.formatMessage({ id: 'affiliates.seeDiscountedPlans', defaultMessage: 'Ver planes con descuento' }),
        linkTo: '/stripe/plans',
        buttonLeft: intl.formatMessage({ id: 'affiliates.maybeLater', defaultMessage: 'Quizás luego' }),
      };
      dispatch(affiliateAction.setAffiliate(
        parsedQueryString.affiliate,
        user.company.id,
        successfulAffiliated,
      ));
    } else {
      const messageTokenValid = {
        title: intl.formatMessage({ id: 'stripe.welcome', defaultMessage: '¡Bienvenido a Pertix!' }),
        messagePart1: intl.formatMessage({ id: 'affiliates.takeAdvantageOfDiscountsPart1', defaultMessage: 'Para aprovechar los descuentos acordados con ' }),
        messagePart2: intl.formatMessage({ id: 'affiliates.takeAdvantageOfDiscountsPart2', defaultMessage: 'creá una cuenta nueva o inicia sesión si ya contás con una.' }),
        button: intl.formatMessage({ id: 'affiliates.createAccount', defaultMessage: 'Crear cuenta' }),
        linkTo: '/register',
        buttonLeft: intl.formatMessage({ id: 'app.login', defaultMessage: 'Iniciar sesión' }),
      };
      dispatch(affiliateAction.getAffiliateData(
        parsedQueryString.affiliate,
        messageTokenValid,
      ));
    }
  }
  if (parsedQueryString.utm_source) {
    localStorage.setItem('utm_source', parsedQueryString.utm_source);
    if (parsedQueryString.utm_source === 'telecom') return <Redirect to={{ pathname: '/register' }} />;
  }
  
  return <Redirect to={{ pathname: '/login' }} />;
};

IndexPage.propTypes = {
  location: PropTypes.shape(),
};

IndexPage.defaultProps = {
  location: {},
};

export default IndexPage;
