const riskConstants = {
  CREATE_REQUEST: 'RISK_CREATE_REQUEST',
  CREATE_SUCCESS: 'RISK_CREATE_SUCCESS',
  CREATE_FAILURE: 'RISK_CREATE_FAILURE',

  GET_REQUEST: 'RISK_GET_REQUEST',
  GET_SUCCESS: 'RISK_GET_SUCCESS',
  GET_FAILURE: 'RISK_GET_FAILURE',

  GET_ALL_REQUEST: 'RISK_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'RISK_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'RISK_GET_ALL_FAILURE',

  UPDATE_REQUEST: 'RISK_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'RISK_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'RISK_UPDATE_FAILURE',

  DELETE_REQUEST: 'RISK_DELETE_REQUEST',
  DELETE_SUCCESS: 'RISK_DELETE_SUCCESS',
  DELETE_FAILURE: 'RISK_DELETE_FAILURE',

  CLEAN_CREATED_RISK: 'CLEAN_CREATED_RISK',

  CREATE_BULK_REQUEST: 'RISK_CREATE_BULK_REQUEST',
  CREATE_BULK_SUCCESS: 'RISK_CREATE_BULK_SUCCESS',
  CREATE_BULK_FAILURE: 'RISK_CREATE_BULK_FAILURE',

  CONFIRM_CREATE_BULK_REQUEST: 'RISK_CONFIRM_CREATE_BULK_REQUEST',
  CONFIRM_CREATE_BULK_SUCCESS: 'RISK_CONFIRM_CREATE_BULK_SUCCESS',
  CONFIRM_CREATE_BULK_FAILURE: 'RISK_CONFIRM_CREATE_BULK_FAILURE',
};

export default riskConstants;
