import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, makeStyles, Box, Grid, TextField,
  Typography, Container, MenuItem, Select, List, ListItem,
  ListItemText, IconButton, LinearProgress, InputLabel, FormControl,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import ClearIcon from '@material-ui/icons/Clear';
import queryString from 'query-string';
import { history } from '../../helpers';
import {
  outlineEvaluationActions, outlineActions,
} from '../../redux/actions';
import Button from '../controls/Button';
import CustomRatingOutline from '../controls/CustomRatingOutline';
import { outlineEvaluationConstants } from '../../constants';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  formControl: {
    minWidth: '100%',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  getContentAnchorEl: null,
};

const OutlineEvaluationForm = ({ edit, data, people }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const initialFieldValues = {
    date: (data && data.date) ? data.date : new Date(),
    answers: (data && data.person) ? JSON.parse(data.answers) : [],
    person: (data && data.person) ? data.person : '',
    evaluatedOutline: (data && data.outline) ? data.outline : null,
    deleteOutlineEvaluationDialog: false,
    inChargePersonId: (data && data.inChargePersonId) ? data.inChargePersonId : null,
    outlineSelected: (data && data.outline) ? data.outline : '',
    evaluatorComments: (data && data.evaluatorComments) ? data.evaluatorComments : '',
    feedback: (data && data.feedback) ? data.feedback : '',
    evaluationStatus: (data && data.evaluationStatus) ? data.evaluationStatus : 0,
  };
  const creatingOutlineEvaluation = useSelector((state) => state.outlineEvaluation.creating);
  const deletingOutlineEvaluation = useSelector((state) => state.outlineEvaluation.deleting);
  const outline = useSelector((state) => state.outline.data);
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const user = useSelector((state) => state.authentication.user);
  const [values, setValues] = useState(initialFieldValues);
  const [isUserAbleToEdit] = useState(values.person.email === user.email);
  const [checked, setChecked] = useState([]);
  const parsedQueryString = queryString.parse(history.location.search);
  const [queryParams] = useState({ personId: parsedQueryString.personId });
  const deleteOutlineEvaluationTitle = intl.formatMessage({ id: 'outlineEvaluation.delete.title', defaultMessage: 'Eliminar evaluación' });
  const deleteOutlineEvaluationButtonText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'Eliminar' });
  const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const deleteOutlineEvaluationText = intl.formatMessage({ id: 'outlineEvaluation.deleteOutlineEvaluationText', defaultMessage: '¿Está seguro que quiere eliminar la evaluación?' });
  const requiredKnowledgesLabel = intl.formatMessage({ id: 'outline.requiredKnowledges', defaultMessage: 'Conocimientos requeridos' });
  const outlineEvaluationPersonLabel = intl.formatMessage({ id: 'outlineEvaluation.person', defaultMessage: 'Persona evaluada' });
  const outlineEvaluationInChargePersonLabel = intl.formatMessage({ id: 'outlineEvaluation.inChargePerson', defaultMessage: 'Responsable de la evaluación' });
  const selectPersonRequiredKnowledges = intl.formatMessage({ id: 'outlineEvaluation.selectPerson', defaultMessage: 'Seleccione una persona para visualizar los conocimientos requeridos del perfil asignado a la misma' });
  const dateLabel = intl.formatMessage({ id: 'outlineEvaluation.date', defaultMessage: 'Fecha de evaluación' });
  const invalidDateLabel = intl.formatMessage({ id: 'app.invalid_date', defaultMessage: 'Fecha incorrecta' });
  const deleteText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'eliminar' });
  const backText = intl.formatMessage({ id: 'app.back', defaultMessage: 'volver' });
  const saveText = intl.formatMessage({ id: 'app.save', defaultMessage: 'guardar' });
  const createText = intl.formatMessage({ id: 'app.create', defaultMessage: 'crear' });
  const noOutlineText = intl.formatMessage({ id: 'outlineEvaluation.noOutline', defaultMessage: 'La persona seleccionada no tiene ningún perfil asignado, puede asignarle un perfil desde ' });
  const createLink = intl.formatMessage({ id: 'outlineEvaluation.editPerson', defaultMessage: 'edición de persona.' });
  const checkApprovedKnowledgesLabel = intl.formatMessage({ id: 'outlineEvaluation.checkApprovedKnowledges', defaultMessage: 'Indique el grado de cumplimiento de cada requisito' });
  const responsibleRequiredErrorMessage = intl.formatMessage({ id: 'outlineEvaluation.responsibleRequiredErrorMessage', defaultMessage: 'Responsable de la evaluación requerido ' });
  const outlineEvaluationOutlineLabel = intl.formatMessage({ id: 'outlineEvaluation.outline', defaultMessage: 'Perfil evaluado' });
  const selectOutlineToEvaluateLabel = intl.formatMessage({ id: 'outlineEvaluation.selectOutlineToEvaluate', defaultMessage: 'Seleccione el perfil de la persona a evaluar' });
  const selectOptionLabel = intl.formatMessage({ id: 'outlineEvaluation.selectOption', defaultMessage: 'Seleccione una opción' });
  const personHasNotOutlineLabel = intl.formatMessage({ id: 'outlineEvaluation.personHasNotOutline', defaultMessage: 'La persona no tiene un perfil asignado' });
  const selectPersonRequiredLabel = intl.formatMessage({ id: 'outlineEvaluation.selectPersonRequired', defaultMessage: 'Seleccione una persona' });
  const evaluatorCommentsLabel = intl.formatMessage({ id: 'outlineEvaluation.evaluatorComments', defaultMessage: 'Comentarios del evaluador' });
  const feedbackLabel = intl.formatMessage({ id: 'outlineEvaluation.feedbackLabel', defaultMessage: 'Feedback del evaluado' });

  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);

  useEffect(() => {
    const personHasOutline = (
      values.person
        && values.outlineSelected
        && values.outlineSelected.id !== 0
    );

    if (queryParams.personId && people && !values.person) {
      const personToSelect = people.find((p) => p.id.toString() === queryParams.personId);
      setValues((prev) => ({ ...prev, person: personToSelect }));
    }

    if (values.person && personHasOutline && people && !values.evaluatedOutline) {
      dispatch(outlineActions.get(values.outlineSelected.id));
      if (!checked.length) {
        const auxKnowledges = JSON.parse(values.outlineSelected.requiredKnowledges)
          .map((knowledge) => ({ ...knowledge, score: 0 }));
        setChecked(auxKnowledges);
      }
    }

    if (edit && values.answers.length) {
      setChecked(values.answers);
    }
  }, [people, values.answers, values.person, values.evaluatedOutline, edit,
    dispatch, queryParams.personId, checked.length, values.outlineSelected]);

  function checkAllApproved(requiredKnowledges) {
    return (
      requiredKnowledges.length === checked.length
      && requiredKnowledges
        .map((element) => {
          const checkedFound = checked.find((knowledge) => knowledge.name === element.name);
          return Boolean(checkedFound && (checkedFound.score >= element.minRequired));
        })
        .every((v) => v === true)
    );
  }
  const getEvaluationStatus = (answers, isPending) => {
    if (isPending) {
      return outlineEvaluationConstants.PENDING;
    }
    return checkAllApproved(answers)
      ? outlineEvaluationConstants.APPROVED
      : outlineEvaluationConstants.FOR_REVISION;
  };

  function handleSubmit(e) {
    e.preventDefault();
    const isPending = checked.every((item) => !item.score);
    let evaluationStatus = outlineEvaluationConstants.FOR_REVISION;
    if (outline) {
      evaluationStatus = getEvaluationStatus(JSON.parse(outline.requiredKnowledges), isPending);
      values.answers = [...checked];
    }
    if (values.evaluatedOutline) {
      evaluationStatus = getEvaluationStatus(values.answers, isPending);
    }

    const outlineEvaluation = {
      date: new Date(values.date).toISOString(),
      answers: JSON.stringify(values.answers),
      evaluationStatus,
      person: { id: values.person.id },
      outline: values.evaluatedOutline ? { id: values.evaluatedOutline.id } : { id: outline.id },
      inChargePersonId: values.inChargePersonId,
      evaluatorComments: values.evaluatorComments,
      feedback: values.feedback,
    };

    if (data) { outlineEvaluation.id = data.id; }

    if (!edit) {
      dispatch(outlineEvaluationActions.create(outlineEvaluation, '/evaluations/'));
    } else {
      dispatch(outlineEvaluationActions.update(outlineEvaluation, '/evaluations/'));
    }
  }

  function handleChangeSelectedPerson(e) {
    const { name, value } = e.target;
    setValues({ ...values, outlineSelected: '', [name]: value });
    setChecked([]);
  }

  function handleChangeSelect(e) {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setChecked([]);
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  }

  function handleDialogOpen(dialogName) {
    setValues({ ...values, [dialogName]: true });
  }

  function handleDialogClose(dialogName) {
    setValues({ ...values, [dialogName]: false });
  }

  function deleteOutlineEvaluation() {
    dispatch(outlineEvaluationActions.deleteOutlineEvaluation(data.id, '/evaluations'));
  }

  function handleClr(e) {
    e.stopPropagation();
    setValues({ ...values, date: null });
  }

  const handleOnChangeScoreKnowledge = (newScore, knowledgeName) => {
    const indexFound = values.answers.findIndex((item) => item.name === knowledgeName);
    const indexOutline = checked.findIndex(
      (item) => item.name === knowledgeName,
    );

    if (indexFound !== -1) {
      values.answers[indexFound].score = newScore;
    } else {
      const auxNewKnowledge = {
        name: knowledgeName,
        score: newScore,
        minRequired: checked[indexOutline].minRequired,
      };
      values.answers.push(auxNewKnowledge);
    }
    checked[indexOutline].score = newScore;
    setValues((prev) => ({ ...prev }));
  };

  const submitButtonDisabled = () => {
    const selectedPerson = values.person;
    const personSelectedWithoutEvaluation = selectedPerson && !values.outlineSelected;
    const allEmptyAnswer = checked.every((item) => !item.score);
    const allEvaluated = values.answers.length === checked.length
      && values.answers
        .map((element) => Boolean(element.score))
        .every((v) => v === true);

    return (!selectedPerson
            || personSelectedWithoutEvaluation
            || !(allEvaluated || allEmptyAnswer)
            || !isAdmin
    );
  };

  const saveButtonDisabled = () => {
    const allEmptyAnswer = checked.every((item) => !item.score);
    const allEvaluated = values.answers.length === checked.length
      && values.answers
        .map((element) => Boolean(element.score))
        .every((v) => v === true);
    return !isAdmin || !(allEvaluated || allEmptyAnswer);
  };

  const getMessageSelectOutlineOptions = (selectedPerson) => {
    if (selectedPerson) {
      const personHasOutline = selectedPerson.outlines.length;
      return personHasOutline
        ? selectOptionLabel
        : personHasNotOutlineLabel;
    }
    return selectPersonRequiredLabel;
  };

  return (
    <div className={classes.main}>
      {((people && people.length !== 0) || edit) ? (
        <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} style={{ marginTop: '16px' }}>
              <Container>
                {edit ? (
                  <>
                    <Typography variant="caption" gutterBottom>
                      <strong style={{ fontSize: '14px' }}>{outlineEvaluationPersonLabel}</strong>
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                      {`${values.person.names} ${values.person.surnames}`}
                    </Typography>
                  </>
                ) : (
                  <FormControl className={classes.formControl}>
                    <InputLabel id="person">{outlineEvaluationPersonLabel}</InputLabel>
                    <Select
                      id="person"
                      name="person"
                      defaultValue={values.person}
                      value={values.person}
                      displayEmpty
                      onChange={handleChangeSelectedPerson}
                      MenuProps={MenuProps}
                      style={{ width: '100%' }}
                    >

                      {people && people.map((personToSelect) => {
                        if (values.person && (personToSelect.id !== values.person.id)) {
                          return (
                            <MenuItem key={personToSelect.id} value={personToSelect}>
                              {`${personToSelect.names} ${personToSelect.surnames}`}
                            </MenuItem>
                          );
                        } if (values.person) {
                          return (
                            <MenuItem key={values.person.id} value={values.person}>
                              {`${values.person.names} ${values.person.surnames}`}
                            </MenuItem>
                          );
                        }
                        if (!values.person) {
                          return (
                            <MenuItem key={personToSelect.id} value={personToSelect}>
                              {`${personToSelect.names} ${personToSelect.surnames}`}
                            </MenuItem>
                          );
                        }
                        return null;
                      })}
                    </Select>
                  </FormControl>
                )}

              </Container>
            </Grid>
            <Grid item xs={12} md={6}>
              <Container>
                <DatePicker
                  value={values.date}
                  onChange={(value) => { setValues({ ...values, date: value }); }}
                  fullWidth
                  margin="normal"
                  id="date"
                  label={dateLabel}
                  disabled={isUserAbleToEdit}
                  name="date"
                  size="small"
                  format="dd/MM/yyyy"
                  minDateMessage={invalidDateLabel}
                  InputProps={{
                    endAdornment: (
                      <IconButton style={{ padding: 5 }} onClick={(e) => handleClr(e)}>
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Container>
            </Grid>
            <Grid item xs={12} md={6}>
              <Container>
                {edit
                  ? (
                    <>
                      <Typography variant="caption" gutterBottom style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {outlineEvaluationOutlineLabel}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                        {values.outlineSelected.name}
                      </Typography>
                    </>
                  )
                  : (
                    <FormControl className={classes.formControl}>
                      <InputLabel id="outlineSelected">{outlineEvaluationOutlineLabel}</InputLabel>
                      <Select
                        id="outlineSelected"
                        name="outlineSelected"
                        defaultValue={values.outlineSelected}
                        value={values.outlineSelected}
                        displayEmpty
                        onChange={handleChangeSelect}
                        MenuProps={MenuProps}
                        style={{ width: '100%' }}
                      >
                        <MenuItem value={null} disabled>
                          <em>{ getMessageSelectOutlineOptions(values.person) }</em>
                        </MenuItem>
                        {
                          values.person && values.person.outlines.map((item) => (
                            <MenuItem key={item.outlineId} value={item.outline}>
                              {item.outline.name}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  )}
              </Container>
            </Grid>
            <Grid item xs={12} md={6}>
              <Container>
                <FormControl className={classes.formControl}>
                  <SelectValidator
                    id="inChargePersonId-select"
                    label={outlineEvaluationInChargePersonLabel}
                    disabled={isUserAbleToEdit}
                    name="inChargePersonId"
                    value={values.inChargePersonId}
                    onChange={handleChangeSelect}
                    fullWidth
                    MenuProps={MenuProps}
                    validators={['required']}
                    errorMessages={responsibleRequiredErrorMessage}
                  >
                    {people && people.map((item) => {
                      if (!item.names) return null;
                      return (
                        <MenuItem key={`${item.id}${item.surnames}`} value={item.id}>
                          {`${item.names} ${item.surnames}`}
                        </MenuItem>
                      );
                    })}
                  </SelectValidator>
                </FormControl>
              </Container>
            </Grid>
            <Grid item xs={12} md={6}>
              <Container>
                <Typography variant="caption" color="textPrimary" style={{ fontWeight: 600, fontSize: 12 }}>
                  {evaluatorCommentsLabel}
                </Typography>
                <TextField
                  value={values.evaluatorComments}
                  name="evaluatorComments"
                  id="evaluatorComments"
                  multiline
                  disabled={isUserAbleToEdit}
                  rows={4}
                  onChange={handleChange}
                  fullWidth
                  style={{ background: '#FFFFFF', margin: 2 }}
                />
              </Container>
            </Grid>

            {edit && (
              <Grid item xs={12} md={6}>
                <Container>
                  <Typography variant="caption" color="textPrimary" style={{ fontWeight: 600, fontSize: 12 }}>
                    {feedbackLabel}
                  </Typography>
                  <TextField
                    value={values.feedback}
                    name="feedback"
                    id="feedback"
                    multiline
                    disabled={isUserAbleToEdit}
                    rows={4}
                    onChange={handleChange}
                    fullWidth
                    style={{ background: '#FFFFFF', margin: 2 }}
                  />
                </Container>
              </Grid>
            )}

            {!edit && <Grid item xs={12} md={6} />}

            <Grid item xs={12} md={6}>
              <Container>
                {((!values.person && !values.evaluatedOutline)
                || (values.person && !values.outlineSelected)) && (
                <Typography variant="caption" display="block" gutterBottom style={{ fontSize: '14px', fontWeight: 'bold' }}>
                  {requiredKnowledgesLabel}
                </Typography>
                )}

                {!values.person && !values.evaluatedOutline && (
                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                    {selectPersonRequiredKnowledges}
                  </Typography>
                )}

                {values.person && !values.person.outlines.length && !edit && (
                  <>
                    <Typography variant="subtitle1" display="inline" gutterBottom style={{ fontSize: '12px' }}>
                      {noOutlineText}
                    </Typography>
                    <Link to={`/people/${values.person.id}/edit`} style={{ textDecoration: 'none' }}>
                      <Typography variant="subtitle1" display="inline" gutterBottom style={{ fontSize: '12px', color: '#F16C24' }}>
                        {createLink}
                      </Typography>
                    </Link>
                  </>
                )}

                {values.person && !!values.person.outlines.length && !values.outlineSelected && (
                  <Typography variant="subtitle1" display="inline" gutterBottom style={{ fontSize: '12px' }}>
                    {selectOutlineToEvaluateLabel}
                  </Typography>
                )}

                {values.person
                && (values.outlineSelected && values.outlineSelected.id !== 0)
                && (outline || values.evaluatedOutline) && (
                <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                  {checkApprovedKnowledgesLabel}
                </Typography>
                )}

                {values.person
                  && (values.outlineSelected
                  && values.outlineSelected.id !== 0)
                  && outline && !values.evaluatedOutline && (
                  <List dense>
                    {checked.map((item) => (
                      <ListItem key={item.name} disableGutters>
                        <ListItemText primary={item.name} />
                        <CustomRatingOutline
                          knowledgeName={item.name}
                          knowledgeScore={item.score}
                          handleOnChangeScoreKnowledge={handleOnChangeScoreKnowledge}
                        />
                      </ListItem>
                    ))}
                  </List>
                ) }

                {values.person && values.evaluatedOutline && (
                  <List dense>
                    {values.answers.map((item) => (
                      <ListItem key={item.name} disableGutters>
                        <ListItemText primary={item.name} />
                        <CustomRatingOutline
                          knowledgeName={item.name}
                          knowledgeScore={item.score}
                          handleOnChangeScoreKnowledge={handleOnChangeScoreKnowledge}
                        />
                      </ListItem>
                    ))}
                  </List>
                ) }

              </Container>
            </Grid>

          </Grid>

          <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
            <Button
              component={Link}
              to="/evaluations"
              variant="outlined"
              text={backText}
              style={{ fontSize: '10px', fontWeight: 600 }}
            />
            {edit
              ? (
                <>
                  {
                    !isUserAbleToEdit && (
                      <>
                        <Button
                          type="button"
                          color="secondary"
                          onClick={() => { handleDialogOpen('deleteOutlineEvaluationDialog'); }}
                          text={deleteText}
                          style={{ fontSize: '10px', fontWeight: 600 }}
                          disabled={!isAdmin}
                        />
                        <Button
                          type="submit"
                          text={saveText}
                          style={{ fontSize: '10px', fontWeight: 600 }}
                          disabled={saveButtonDisabled()}
                        />
                      </>
                    )
                  }
                  <Dialog open={values.deleteOutlineEvaluationDialog} onClose={() => { handleDialogClose('deleteOutlineEvaluationDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{deleteOutlineEvaluationTitle}</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        {deleteOutlineEvaluationText}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button text={cancelButtonText} onClick={() => { handleDialogClose('deleteOutlineEvaluationDialog'); }} />
                      <Button
                        text={deleteOutlineEvaluationButtonText}
                        color="secondary"
                        loading={deletingOutlineEvaluation}
                        onClick={deleteOutlineEvaluation}
                      />
                    </DialogActions>
                  </Dialog>
                </>
              )
              : (
                <Button
                  type="submit"
                  loading={creatingOutlineEvaluation}
                  disabled={submitButtonDisabled()}
                  text={createText}
                />
              )}

          </Box>
        </ValidatorForm>
      ) : <LinearProgress />}

    </div>
  );
};

OutlineEvaluationForm.propTypes = {
  data: PropTypes.shape(),
  edit: PropTypes.bool,
  people: PropTypes.arrayOf(PropTypes.shape()),
};

OutlineEvaluationForm.defaultProps = {
  data: {},
  edit: false,
  people: [],
};

export default OutlineEvaluationForm;
