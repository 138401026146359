import React, { useEffect } from 'react';
import {
  Breadcrumbs, LinearProgress, makeStyles, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import WorkFieldEvaluationForm from '../WorkFieldEvaluationForm';
import { workFieldEvaluationActions, personActions, providerActions } from '../../../redux/actions';
import PageLayout from '../../PageLayout';
import { providersTexts, workFieldEvaluationTexts } from '../../../constants';

const useStyles = makeStyles(() => ({
  breadcrumbs: {
    textDecoration: 'none',
    fontWeight: 400,
    fontSize: 11,
  },
}));

function EditWorkFieldEvaluationPage({ routeParams: { id: workFieldEvaluationId } }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const people = useSelector((state) => state.person.people);
  const providers = useSelector((state) => state.provider.providers);
  const workFieldEvaluation = useSelector((state) => state.workFieldEvaluation.data);

  useEffect(() => {
    if (!workFieldEvaluation || JSON.stringify(workFieldEvaluation.id) !== workFieldEvaluationId) {
      dispatch(workFieldEvaluationActions.get(workFieldEvaluationId));
    }
    if (!people) {
      dispatch(personActions.getAll());
    }
    if (!providers) {
      dispatch(providerActions.getAll());
    }
  }, [dispatch, workFieldEvaluationId, workFieldEvaluation, people, providers]);

  function EditWorkFieldEvaluationBreadcrumbs() {
    const workFieldEvaluationLink = `/workFieldEvaluations/${workFieldEvaluationId}`;

    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Typography component={Link} to="/providers" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {providersTexts.providers}
        </Typography>
        <Typography component={Link} to="/workFieldEvaluations" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {workFieldEvaluationTexts.evaluations}
        </Typography>
        <Typography component={Link} to={workFieldEvaluationLink} variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {`${workFieldEvaluation.provider.name} : ${workFieldEvaluation.workField.name}`}
        </Typography>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{workFieldEvaluationTexts.modify}</Typography>
      </Breadcrumbs>
    );
  }

  function EditWorkFieldEvaluationLoadingBreadcrumbs() {
    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Typography component={Link} to="/providers" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {providersTexts.providers}
        </Typography>
        <Typography component={Link} to="/workFieldEvaluations" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {workFieldEvaluationTexts.evaluations}
        </Typography>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{workFieldEvaluationTexts.modify}</Typography>
      </Breadcrumbs>
    );
  }

  return (
    <>
      {workFieldEvaluation ? (
        <PageLayout
          title={workFieldEvaluationTexts.editTitle}
          breadCrumbs={<EditWorkFieldEvaluationBreadcrumbs />}
        >
          <WorkFieldEvaluationForm
            edit
            data={workFieldEvaluation}
            people={people}
            providers={providers}
          />
        </PageLayout>
      ) : (
        <PageLayout
          title={workFieldEvaluationTexts.editTitle}
          breadCrumbs={<EditWorkFieldEvaluationLoadingBreadcrumbs />}
        >
          <LinearProgress />
        </PageLayout>
      )}
    </>
  );
}
EditWorkFieldEvaluationPage.propTypes = {
  routeParams: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

EditWorkFieldEvaluationPage.defaultProps = {
  routeParams: {},
};

export default EditWorkFieldEvaluationPage;
