import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box, Breadcrumbs, makeStyles, Typography, Grid, LinearProgress, List, ListItem, ListItemText,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { history } from '../../../helpers';
import PageLayout from '../../PageLayout';
import { outlineActions } from '../../../redux/actions';
import Button from '../../controls/Button';
import CustomRatingOutline from '../../controls/CustomRatingOutline';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    backgroundColor: '#F8F8F8',
    position: 'relative',
    overflow: 'auto',
    height: 200,
    maxHeight: 200,
    maxWidth: 500,
  },
}));

const OutlineDetailsPage = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const outline = useSelector((state) => state.outline.data);
  const outlineId = match.params.id;
  const parsedRequiredKnowledges = outline ? JSON.parse(outline.requiredKnowledges) : [];
  const outlineNameLabel = intl.formatMessage({ id: 'outline.name', defaultMessage: 'Nombre del perfil' });
  const outlineRequiredKnowledgesLabel = intl.formatMessage({ id: 'outline.requiredKnowledges', defaultMessage: 'Conocimientos requeridos' });
  const outlinesTitle = intl.formatMessage({ id: 'outline.outlinesText', defaultMessage: 'Perfiles' });
  const editLink = `${outlineId}/edit`;
  const outlineDetailsText = intl.formatMessage({ id: 'outline.details', defaultMessage: 'Detalle de perfil' });
  const noRequirementsOutline = intl.formatMessage({ id: 'outline.noRequirements', defaultMessage: 'El perfil no posee requerimientos' });
  let keyCount = 1;

  useEffect(() => {
    dispatch(outlineActions.get(outlineId));
  }, [dispatch, outlineId]);

  function OutlineDetailsBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/outlines" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{outlinesTitle}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{outline.name}</strong></Typography>
      </Breadcrumbs>
    );
  }

  function OutlineDetailsLoadingBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/outlines" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{outlinesTitle}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{outlineDetailsText}</strong></Typography>
      </Breadcrumbs>
    );
  }

  function getKey() {
    keyCount += 1;
    return keyCount;
  }

  return (
    <>
      {outline
        ? (
          <PageLayout title={outline.name} breadCrumbs={<OutlineDetailsBreadcrumbs />}>
            <Box pl={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: '14px' }}>{outlineNameLabel}</strong>
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                    {outline.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: '14px' }}>{outlineRequiredKnowledgesLabel}</strong>
                  </Typography>
                  <List className={classes.root}>
                    {parsedRequiredKnowledges.length > 0
                      ? (parsedRequiredKnowledges.map((item) => (
                        <ListItem key={getKey()} disableGutters>
                          <ListItemText primary={`${item.name}`} />
                          <CustomRatingOutline
                            knowledgeName={item.name}
                            knowledgeScore={item.minRequired}
                            readOnly
                          />
                        </ListItem>
                      )))
                      : noRequirementsOutline}
                  </List>
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
              <Button
                onClick={history.goBack}
                variant="outlined"
                text={<FormattedMessage id="app.back" defaultMessage="volver" />}
              />
              <Button
                component={Link}
                to={editLink}
                type="submit"
                text={<FormattedMessage id="app.edit" defaultMessage="editar" />}
                disabled={!isAdmin}
              />
            </Box>
          </PageLayout>
        )
        : (
          <PageLayout title={outlineDetailsText} breadCrumbs={<OutlineDetailsLoadingBreadcrumbs />}>
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
};

OutlineDetailsPage.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default OutlineDetailsPage;
