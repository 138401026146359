import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  LinearProgress, Select, MenuItem, Box, Grow, Typography, makeStyles,
} from '@material-ui/core';
import { uniqBy } from 'lodash-es';
import dataTableService from '../../services/dataTableService';
import { history, dataTableHelper } from '../../helpers';
import CustomSearchRender from './CustomSearchRender';

const useStyles = makeStyles(() => ({
  selectIcon: {
    top: 'auto',
    marginRight: '8px',
  },
}));
function DataTable({
  fetchUrl, itemUrl, keys, columns, title, property, propertyToInject,
  showFixedFilterForColumnIndex,
  customRenderForFixedFilterColumnIndex,
}) {
  const classes = useStyles();
  const [data, setData] = useState();
  const intl = useIntl();
  const [columnsToDisplay, setColumnsToDisplay] = useState(columns);
  const initialSelectedFilter = (showFixedFilterForColumnIndex && columns[showFixedFilterForColumnIndex].options) ? columns[showFixedFilterForColumnIndex].options.filterList : 'all';
  const [selectedFilter, setSelectedFilter] = useState(initialSelectedFilter);
  const allText = intl.formatMessage({ id: 'dataTable.all', defaultMessage: 'TODOS' });
  const filterByText = intl.formatMessage({ id: 'dataTable.filterBy', defaultMessage: 'Filtrar por' });
  const filename = `${title}.csv`;

  const onFilter = ({ target: { value } }) => {
    setSelectedFilter(value);
    const filteredCols = [...columnsToDisplay];
    let filterList = [];
    if (value !== 'all') {
      filterList = [value];
    }
    if (!filteredCols[showFixedFilterForColumnIndex].options) {
      filteredCols[showFixedFilterForColumnIndex].options = filterList;
    }
    filteredCols[showFixedFilterForColumnIndex].options.filterList = filterList;
    setColumnsToDisplay(filteredCols);
  };
  const options = {
    textLabels: {
      body: {
        noMatch: intl.formatMessage({ id: 'dataTable.noMatch', defaultMessage: 'No hay datos para mostrar' }),
        toolTip: intl.formatMessage({ id: 'dataTable.toolTip', defaultMessage: 'Ordenar' }),
        columnHeaderTooltip: (column) => `${intl.formatMessage({ id: 'dataTable.sortTooltip', defaultMessage: 'Ordenamiento para' })} ${column.label}`,
      },
      pagination: {
        next: intl.formatMessage({ id: 'dataTable.next', defaultMessage: 'Página siguiente' }),
        previous: intl.formatMessage({ id: 'dataTable.previous', defaultMessage: 'Página anterior' }),
        rowsPerPage: intl.formatMessage({ id: 'dataTable.rowsPerPage', defaultMessage: 'Resultados:' }),
        displayRows: intl.formatMessage({ id: 'dataTable.displayRows', defaultMessage: 'de' }),
      },
      toolbar: {
        search: intl.formatMessage({ id: 'dataTable.search', defaultMessage: 'Buscar' }),
        downloadCsv: intl.formatMessage({ id: 'dataTable.downloadCSV', defaultMessage: 'Descargar CSV' }),
        filterTable: intl.formatMessage({ id: 'dataTable.filterTable', defaultMessage: 'Filtrar tabla' }),
      },
      filter: {
        all: intl.formatMessage({ id: 'dataTable.all', defaultMessage: 'TODOS' }),
        title: (<b>{intl.formatMessage({ id: 'dataTable.filters', defaultMessage: 'Filtros' })}</b>),
        reset: intl.formatMessage({ id: 'dataTable.reset', defaultMessage: 'REINICIAR' }),
      },
      viewColumns: {
        title: intl.formatMessage({ id: 'dataTable.showColumns', defaultMessage: 'Mostrar columnas' }),
        titleAria: intl.formatMessage({ id: 'dataTable.showTableColumns', defaultMessage: 'Mostrar/Ocultar Columnas de la tabla' }),
      },
    },
    downloadOptions: {
      filename,
    },
    selectableRows: 'none',
    print: false,
    viewColumns: false,
    searchOpen: true,
    onCellClick: (colData, cellMeta) => {
      if (!colData.$$typeof) {
        const redirectUrl = itemUrl + data[cellMeta.dataIndex][0];
        history.push(redirectUrl);
      }
    },
    customSearchRender: (searchText, handleSearch, hideSearch, opt) => (
      <Box display="flex">
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={opt}
        />
        {(showFixedFilterForColumnIndex !== false) && (
          <Grow appear in timeout={300}>
            <Box pl={4} display="flex" alignItems="center">
              <Typography variant="caption" style={{ fontWeight: 700, fontSize: 11, paddingRight: '16px' }}>
                {filterByText}
                {' '}
                {columns[showFixedFilterForColumnIndex].label}
              </Typography>
              <Select
                disableUnderline
                onChange={onFilter}
                value={selectedFilter}
                style={{
                  borderRadius: '18px',
                  backgroundColor: '#FFFFFF',
                  height: '100%',
                }}
                SelectDisplayProps={{ style: { paddingLeft: '16px', borderRadius: '18px' } }}
                classes={{ icon: classes.selectIcon }}
              >
                <MenuItem value="all">{allText}</MenuItem>
                {uniqBy(data, (item) => item[showFixedFilterForColumnIndex]).map((column) => (
                  <MenuItem
                    key={column[showFixedFilterForColumnIndex]}
                    value={column[showFixedFilterForColumnIndex]}
                  >
                    {customRenderForFixedFilterColumnIndex
                      ? customRenderForFixedFilterColumnIndex(column[showFixedFilterForColumnIndex])
                      : column[showFixedFilterForColumnIndex]}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grow>
        )}
      </Box>
    ),

    onFilterChipClose: (index) => {
      if (index === showFixedFilterForColumnIndex) {
        onFilter({ target: { value: 'all' } });
      }
    },

    onDownload: (buildHead, buildBody, colName, rows) => dataTableHelper
      .handleOnDownload(buildHead, buildBody, colName, rows, filename),

  };

  useEffect(() => {
    const transformResponseData = (responseData, extraData) => {
      const transformedData = [];
      let row = [];
      const invalidData = [null, undefined, NaN, ''];

      Object.values(responseData).forEach((value) => {
        keys.forEach((key) => {
          if (invalidData.includes(value[key])) {
            row.push('-');
          } else {
            row.push(value[key]);
          }
        });

        if (!invalidData.includes[extraData] && typeof extraData === 'string') {
          row.push(extraData);
        }

        transformedData.push(row);
        row = [];
      });

      return transformedData;
    };

    const fetchData = async () => {
      await dataTableService.get(fetchUrl)
        .then((response) => {
          if (property) {
            setData(transformResponseData(response[property], response[propertyToInject] ?? ''));
          } else setData(transformResponseData(response));
        });
    };

    fetchData();
  }, [fetchUrl, keys, property, propertyToInject]);

  return (
    <>
      {data ? (
        <MUIDataTable
          data={data}
          columns={columnsToDisplay}
          options={options}
        />
      ) : <LinearProgress />}

    </>

  );
}

DataTable.propTypes = {
  fetchUrl: PropTypes.string,
  itemUrl: PropTypes.string,
  title: PropTypes.string,
  keys: PropTypes.arrayOf(PropTypes.string),
  columns: PropTypes.arrayOf((PropTypes.shape())),
  property: PropTypes.string,
  propertyToInject: PropTypes.string,
  showFixedFilterForColumnIndex: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  customRenderForFixedFilterColumnIndex: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

DataTable.defaultProps = {
  fetchUrl: '',
  itemUrl: '',
  title: '',
  keys: [],
  columns: [],
  property: null,
  propertyToInject: null,
  showFixedFilterForColumnIndex: false,
  customRenderForFixedFilterColumnIndex: false,
};

export default DataTable;
