import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { riskActions } from '../../../redux/actions';
import BulkCreation from '../../BulkUpload/BulkCreation';
import { downloadHelper } from '../../../helpers';

const DeviationBulkCreation = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const linkDownload = downloadHelper.listRisksExample;
  const linkEntity = '/risks';
  const text = {
    title: intl.formatMessage({ id: 'risks.title', defaultMessage: 'Riesgos' }),
    bulkCreationTitle: intl.formatMessage({ id: 'risks.bulkCreationTitle', defaultMessage: 'Carga múltiple de riesgos' }),
    bulkCreationBreadcrumbs: intl.formatMessage({ id: 'risks.bulkCreationBreadcrumbs', defaultMessage: 'Carga múltiple' }),
    bulkCreationSubtitle: intl.formatMessage({ id: 'risks.bulkCreationSubtitle', defaultMessage: 'Usted puede crear varios riesgos a la vez completando nuestra plantilla. ' }),
    bulkCreationSteps: intl.formatMessage({ id: 'risks.bulkCreationSteps', defaultMessage: 'Pasos:' }),
    bulkCreationStep1: intl.formatMessage({ id: 'risks.bulkCreationStep1', defaultMessage: '1. Descargue la plantilla de Excel.' }),
    bulkCreationStep2: intl.formatMessage({ id: 'risks.bulkCreationStep2', defaultMessage: '2. Complete la plantilla con los datos de los riesgos deseados.' }),
    bulkCreationStep3: intl.formatMessage({ id: 'risks.bulkCreationStep3', defaultMessage: '3. Cargue la plantilla en nuestro sistema aquí abajo.' }),
    downloadListExample: intl.formatMessage({ id: 'risks.downloadListExample', defaultMessage: 'Descargar plantilla' }),
    titlePageLayout: intl.formatMessage({ id: 'risks.title', defaultMessage: 'Riesgos' }),
  };

  function handleDispatcher(formData) {
    return (
      dispatch(riskActions.createBulkRisks(formData))
    );
  }

  return (
    <BulkCreation
      text={text}
      linkEntity={linkEntity}
      linkDownload={linkDownload}
      handleDispatcher={handleDispatcher}
    />
  );
};

export default DeviationBulkCreation;
