import { Tooltip, withStyles } from "@material-ui/core";

const TutorialTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: '#F16C24',
      color: '#FFF',
      boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
      fontSize: 12,
      zIndex: 9000,
      maxWidth: '460px',
    },
    arrow: {
        color: '#F16C24',
    }
}))(Tooltip);

export default TutorialTooltip;