import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.css';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import DateFnsUtils from '@date-io/date-fns';
import spanish from 'date-fns/locale/es';
import english from 'date-fns/locale/en-US';
import italian from 'date-fns/locale/it';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { store } from './helpers';
import es from './languages/es-AR.json';
import en from './languages/en-US.json';
import it from './languages/it-IT.json';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const local = navigator.language;
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

function getLocalLanguage() {
  if (local === 'en-US') return en;
  if (local === 'it-IT') return it;
  return es;
}
function getLocaleProvidedToPickers() {
  if (local === 'en-US') return english;
  if (local === 'it-IT') return italian;
  return spanish;
}

const language = getLocalLanguage();
const localeForPickers = getLocaleProvidedToPickers();
ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <Elements stripe={stripePromise}>

        <IntlProvider locale={local} messages={language}>
          <Provider store={store}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeForPickers}>
              <App />
            </MuiPickersUtilsProvider>
          </Provider>
        </IntlProvider>

      </Elements>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
