import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box, Grid, InputAdornment, InputBase, makeStyles, Typography, Divider,
  Button as MuiButton, Dialog, Avatar, Badge, DialogContent, IconButton, Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonIcon from '@material-ui/icons/Person';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Button from '../../controls/Button';
import LinkPeopleModal from '../LinkPeopleModal/LinkPeopleModal';
import { CloseIcon } from '../../../assets/icons';
import { processActions } from '../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  addLink: {
    color: '#FFFFFF',
    fontSize: '10px',
    fontWeight: 600,
    paddingLeft: '10px',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  iconAdd: {
    color: '#FFFFFF',
    height: '13px',
    width: '13px',
  },
  iconHeader: {
    color: '#DADADA',
    height: '51px',
    width: '51px',
  },
  iconBody: {
    color: '#FFF',
    backgroundColor: '#DADADA',
    height: '44px',
    width: '44px',
    margin: '3px',
  },
  textHeader: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 700,
    letterSpacing: '0.15px',
  },
  itemName: {
    fontWeight: 700,
    fontSize: '11px',
    letterSpacing: '0.15px',
    textAlign: 'center',
    marginTop: '4px',
  },
  itemRole: {
    fontWeight: 400,
    fontSize: '11px',
    letterSpacing: '0.15px',
    textAlign: 'center',
  },
  resultContainer: {
    maxHeight: '216px',
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarColor: '#999999',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '0.3em',
      paddingLeft: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(0,0,0,.4)',
    },
  },
  resultGrid: {
    display: 'grid',
    gridGap: '6px',
    gridTemplateColumns: 'repeat(auto-fill, 120px)',
    justifyContent: 'space-evenly',
  },
  iconSearch: {
    marginLeft: '8px',
  },
  iconUnlink: {
    background: '#FFF',
    padding: '0px',
    '&:hover': {
      backgroundColor: '#F22525',
    },
  },
  iconButtonUnlink: {
    padding: '0px',
    '&:hover': {
      color: '#FFF',
    },
  },
  closeIcon: {
    width: '14px',
    height: '13px',
    cursor: 'pointer',
  },
  unlinkPersonContent: {
    width: '280px',
    paddingTop: '20px',
    fontSize: '12px',
    fontWeight: 400,
  },
  unlinkPersonTitle: {
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
  },
  centeredContainer: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'grid',
    justifyContent: 'center',
    justifyItems: 'center',
  },
  centeredContainerButton: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    height: '32px',
    justifyContent: 'center',
  },
}));

const initialFieldValues = {
  interestedPeople: '',
  responsiblePeople: '',
};

const initialFieldValuesModal = {
  interestedPeople: false,
  responsiblePeople: false,
  people: false,
};

const ProcessPeopleDetail = ({ responsiblePeople, interestedPeople, processId }) => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const [searchText, setSearchText] = useState(initialFieldValues);
  const [searchResult, setSearchResult] = useState({ interestedPeople, responsiblePeople });
  const [openModal, setOpenModal] = useState(initialFieldValuesModal);
  const [openUnlinkPersonDialog, setOpenUnlinkPersonDialog] = useState(false);
  const [interestedToUnlink, setInterestedToUnlink] = useState(null);
  const [responsibleToUnlink, setResponsibleToUnlink] = useState(null);
  const label = {
    interestedPeople: intl.formatMessage({ id: 'people.interestedPeople', defaultMessage: 'Personas interesadas' }, { count: interestedPeople.length }),
    responsiblePeople: intl.formatMessage({ id: 'people.responsiblePeople', defaultMessage: 'Personas responsables' }, { count: responsiblePeople.length }),
  };
  const addLink = {
    interestedPeople: intl.formatMessage({ id: 'people.linkInterest', defaultMessage: 'AGREGAR INTERESADO AL PROCESO' }),
    responsiblePeople: intl.formatMessage({ id: 'people.linkResponsible', defaultMessage: 'AGREGAR RESPONSABLE AL PROCESO' }),
  };

  useEffect(() => {
    if (searchText.interestedPeople) {
      const filtered = interestedPeople.filter(
        (doc) => {
          const fullName = `${doc.names} ${doc.surnames}`;
          return fullName.toLowerCase().includes(searchText.interestedPeople.toLowerCase());
        },
      );
      return setSearchResult((prev) => ({ ...prev, interestedPeople: filtered }));
    }
    return setSearchResult((prev) => ({ ...prev, interestedPeople }));
  }, [searchText.interestedPeople, interestedPeople]);

  useEffect(() => {
    if (searchText.responsiblePeople) {
      const filtered = responsiblePeople.filter(
        (doc) => {
          const fullName = `${doc.names} ${doc.surnames}`;
          return fullName.toLowerCase().includes(searchText.responsiblePeople.toLowerCase());
        },
      );
      return setSearchResult((prev) => ({ ...prev, responsiblePeople: filtered }));
    }
    return setSearchResult((prev) => ({ ...prev, responsiblePeople }));
  }, [searchText.responsiblePeople, responsiblePeople]);

  function handleTextChange({ target: { value } }, name) {
    setSearchText((prev) => ({ ...prev, [name]: value }));
  }

  function toggleOpenPeopleModal(resourceName) {
    if (resourceName === 'interestedPeople') {
      setOpenModal((prev) => ({ ...prev, interestedPeople: true }));
      setOpenModal((prev) => ({ ...prev, responsiblePeople: false }));
    }
    if (resourceName === 'responsiblePeople') {
      setOpenModal((prev) => ({ ...prev, responsiblePeople: true }));
      setOpenModal((prev) => ({ ...prev, interestedPeople: false }));
    }
    setOpenModal((prev) => ({ ...prev, people: !prev.people }));
  }

  function handleUnlinkPersonDialog(id, type) {
    if (type === 'interestedPeople') { setInterestedToUnlink(id); }
    if (type === 'responsiblePeople') { setResponsibleToUnlink(id); }

    setOpenUnlinkPersonDialog((prevValue) => !prevValue);
  }

  function unlinkPerson() {
    if (interestedToUnlink) {
      dispatch(processActions.unlinkInterested(processId, interestedToUnlink));
      setInterestedToUnlink(null);
    }
    if (responsibleToUnlink) {
      dispatch(processActions.unlinkResponsible(processId, responsibleToUnlink));
      setResponsibleToUnlink(null);
    }

    setOpenUnlinkPersonDialog((prevValue) => !prevValue);
  }

  const peopleDetails = (resourceName) => (
    <Box width={291} bgcolor="#FCFCFC" m={1}>
      <Box className={classes.centeredContainer} height={105}>
        <PersonIcon className={classes.iconHeader} />
        <Typography display="block" className={classes.textHeader}>
          {label[resourceName]}
        </Typography>
      </Box>
      <Divider />
      <Box minHeight={263}>
        <Box bgcolor="#FFFFFF" borderRadius={18} mx="auto" mt={2} mb={2.5} width={250}>
          <InputBase
            classes={{ adornedStart: classes.iconSearch }}
            placeholder="Buscar"
            value={searchText[resourceName]}
            onChange={(e) => handleTextChange(e, resourceName)}
            startAdornment={(
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )}
          />
        </Box>
        <Box className={classes.resultContainer}>
          <Grid container className={classes.resultGrid}>
            {searchResult[resourceName].map((people) => (
              <Grid item key={people.id}>
                <Box className={classes.centeredContainer} pb={0.5}>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    classes={{ badge: classes.iconUnlink }}
                    badgeContent={(
                      <Tooltip title={<FormattedMessage id="app.unlink" defaultMessage="DESVINCULAR" />}>
                        <IconButton
                          classes={{ root: classes.iconButtonUnlink }}
                          onClick={() => handleUnlinkPersonDialog(people.id, resourceName)}
                        >
                          <LinkOffIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  >
                    <Avatar
                      className={classes.iconBody}
                      style={{ textDecoration: 'none' }}
                      alt={`${people.names} ${people.surnames}`}
                      src={people.imageUrl}
                      component={Link}
                      to={`/people/${people.id}`}
                    >
                      {people.names.charAt(0)}
                      {people.surnames.charAt(0)}
                    </Avatar>
                  </Badge>
                  <Typography display="block" className={classes.itemName}>
                    {`${people.names} ${people.surnames}`}
                  </Typography>
                  <Typography display="block" className={classes.itemRole}>
                    {people.role}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      {isAdmin && (
      <Box className={classes.centeredContainerButton}>
        <PersonAddIcon className={classes.iconAdd} display="inline" />
        <MuiButton
          className={classes.addLink}
          color="primary"
          display="inline"
          onClick={() => toggleOpenPeopleModal(resourceName)}
        >
          {addLink[resourceName]}
        </MuiButton>
      </Box>
      )}
    </Box>
  );
  return (
    <Box display="flex">
      {peopleDetails('interestedPeople')}
      {peopleDetails('responsiblePeople')}

      <Dialog
        open={openModal.people}
        onClose={() => toggleOpenPeopleModal()}
        aria-labelledby="form-dialog-people"
        maxWidth="sm"
        fullWidth
      >
        <LinkPeopleModal
          isOpen={openModal.people}
          setIsOpen={() => toggleOpenPeopleModal()}
          processId={processId}
          processResponsiblePeople={responsiblePeople}
          responsibleModal={openModal.responsiblePeople}
          processInterestedPeople={interestedPeople}
          interestedModal={openModal.interestedPeople}
        />
      </Dialog>
      <Dialog
        open={openUnlinkPersonDialog}
        onClose={handleUnlinkPersonDialog}
        aria-labelledby="form-dialog-unlinked"
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <Box display="flex" justifyContent="flex-end">
            <CloseIcon className={classes.closeIcon} onClick={handleUnlinkPersonDialog} />
          </Box>
          <Typography variant="subtitle1" className={classes.unlinkPersonTitle}>
            <FormattedMessage id="people.unlinkPerson" defaultMessage="DESVINCULAR PERSONA" />
          </Typography>
          <Box display="flex" align="center" justifyContent="center">
            <Typography variant="subtitle1" className={classes.unlinkPersonContent}>
              <FormattedMessage id="people.unlinkPersonText" defaultMessage="La persona dejara de estar vinculada al proceso, pero no se eliminará del sistema, ni de los otros procesos donde esté vinculada." />
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <Button
              onClick={handleUnlinkPersonDialog}
              variant="outlined"
              text={<FormattedMessage id="app.cancel" defaultMessage="CANCELAR" />}
            />
            <Button
              onClick={unlinkPerson}
              text={<FormattedMessage id="app.unlink" defaultMessage="DESVINCULAR" />}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

ProcessPeopleDetail.propTypes = {
  responsiblePeople: PropTypes.arrayOf((PropTypes.shape())),
  interestedPeople: PropTypes.arrayOf((PropTypes.shape())),
  processId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ProcessPeopleDetail.defaultProps = {
  responsiblePeople: [],
  interestedPeople: [],
  processId: null,
};

export default ProcessPeopleDetail;
