import React from 'react';
import { useIntl } from 'react-intl';
import { Link, Redirect } from 'react-router-dom';
import {
  Breadcrumbs, Typography, Box, makeStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import BusinessIcon from '@material-ui/icons/Business';
import AffiliatesIcon from '../../../assets/icons/AffiliatesIcon';
import PageLayout from '../../PageLayout';

const useStyles = makeStyles((theme) => ({
  item: {
    backgroundColor: 'white',
    cursor: 'pointer',
    height: 222,
    width: 222,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '20px',
    textDecoration: 'none',
    transition: 'transform .5s, box-shadow 1s',
    '&:hover': {
      transform: 'scale(1.02) perspective(0px)',
    },
  },
  icon: {
    color: '#DADADA',
    height: '76px',
    width: '76px',
  },
  textHeader: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 700,
    letterSpacing: '0.15px',
  },
}));

const SuperAdminPage = () => {
  const classes = useStyles();
  const intl = useIntl();
  const user = useSelector((state) => state.authentication.user);
  const titleLabel = intl.formatMessage({ id: 'superAdmin.systemAdmin', defaultMessage: 'Administración del sistema' });
  const companyLabel = intl.formatMessage({ id: 'superAdmin.companies', defaultMessage: 'Empresas' });
  const affiliatesLabel = intl.formatMessage({ id: 'superAdmin.affiliates', defaultMessage: 'Afiliados' });

  if (!user.isSuperAdmin) {
    return <Redirect to={{ pathname: '/company/configuration' }} />;
  }

  const SuperAdminPageBreadcrumbs = () => (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      <Link color="inherit" to="/superadmin" style={{ textDecoration: 'none' }}>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{titleLabel}</Typography>
      </Link>
    </Breadcrumbs>
  );

  return (
    <PageLayout
      title={titleLabel}
      breadCrumbs={<SuperAdminPageBreadcrumbs />}
    >
      <Box display="flex" px={3}>
        <Box component={Link} to="/companies" boxShadow={1} className={classes.item}>
          <BusinessIcon className={classes.icon} />
          <Typography display="block" className={classes.textHeader}>{companyLabel}</Typography>
        </Box>
        <Box component={Link} to="/affiliates" boxShadow={1} className={classes.item}>
          <AffiliatesIcon className={classes.icon} />
          <Typography display="block" className={classes.textHeader}>{affiliatesLabel}</Typography>
        </Box>
      </Box>

    </PageLayout>
  );
};

export default SuperAdminPage;
