import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import defaultPhotoActions from '../../../assets/defaultPhotoPerfil.png';

const useStyles = makeStyles(() => ({
  personCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '14px 6px',
    boxSizing: 'border-box',
  },
  personImg: {
    width: '50px',
    height: '50px',
    marginBottom: '6px',
  },
}));

const CalendarEventDetailPersonCard = ({ person, withSpace }) => {
  const classes = useStyles();
  const personImageUrl = (person && person.imageUrl) || defaultPhotoActions;
  return (
    <Box className={classes.personCard} style={withSpace ? { width: '128px' } : {}}>
      <img src={personImageUrl} alt="profile person" className={classes.personImg} />
      <Typography
        variant="h5"
        style={{
          fontWeight: 700, fontSize: '11px', lineHeight: '12px', textAlign: 'center',
        }}
      >
        {person && person.fullName && (person.fullName)}
        {person && person.names && (`${person.names} ${person.surnames}`)}
      </Typography>
      <Typography variant="h6" style={{ fontSize: '11px', lineHeight: '16px', textAlign: 'center' }}>
        {person && person.role && (person.role)}
      </Typography>
    </Box>
  );
};

CalendarEventDetailPersonCard.propTypes = {
  person: PropTypes.shape({
    fullName: PropTypes.string,
    role: PropTypes.string,
    imageUrl: PropTypes.string,
    names: PropTypes.string,
    surnames: PropTypes.string,
  }),
  withSpace: PropTypes.bool,
};

CalendarEventDetailPersonCard.defaultProps = {
  person: {},
  withSpace: true,
};
export default CalendarEventDetailPersonCard;
