import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    linkDocuments: {
        color: '#FFFFFF',
        fontSize: '10px',
        fontWeight: 600,
        paddingLeft: '10px',
        textDecoration: 'none',
        textTransform: 'uppercase',
    },
    documentIcon: {
        color: '#FFFFFF',
        height: '13px',
        width: '13px',
    },
    iconHeader: {
        color: '#DADADA',
        height: '51px',
        width: '51px',
    },
    iconBody: {
        color: '#DADADA',
        height: '45px',
        width: '45px',
    },
    textHeader: {
        color: theme.palette.primary.main,
        fontSize: '14px',
        fontWeight: 700,
        letterSpacing: '0.15px',
    },
    itemTitle: {
        fontWeight: 700,
        fontSize: '11px',
        letterSpacing: '0.15px',
        textAlign: 'center',
        marginTop: '4px',
    },
    itemVersion: {
        fontWeight: 400,
        fontSize: '11px',
        letterSpacing: '0.15px',
        textAlign: 'center',
    },
    resultContainer: {
        maxHeight: '219px',
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollbarColor: '#999999',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: '0.3em',
            paddingLeft: '10px',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.2)',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(0,0,0,.4)',
        },
    },
    resultGrid: {
        display: 'grid',
        gridGap: '6px',
        gridTemplateColumns: 'repeat(auto-fill, 120px)',
        justifyContent: 'space-evenly',
    },
    iconSearch: {
        marginLeft: '8px',
    },
    unlinkDocumentContent: {
        width: '280px',
        paddingTop: '20px',
        fontSize: '12px',
        fontWeight: 400,
    },
    iconUnlink: {
        background: '#FFF',
        padding: '0px',
        '&:hover': {
            backgroundColor: '#F22525',
        },
    },
    iconButtonUnlink: {
        padding: '0px',
        '&:hover': {
            color: '#FFF',
        },
    },
    closeIcon: {
        width: '14px',
        height: '13px',
        cursor: 'pointer',
    },
    unlinkDocumentTitle: {
        fontSize: '14px',
        fontWeight: 700,
        textAlign: 'center',
    },
    centeredContainer: {
        alignContent: 'center',
        alignItems: 'center',
        display: 'grid',
        justifyContent: 'center',
        justifyItems: 'center',
    },
    centeredContainerButton: {
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        height: '32px',
        justifyContent: 'center',
    },
}));

export default useStyles;
