import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box, Breadcrumbs, Grid, LinearProgress, makeStyles, Typography,
} from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PageLayout from '../../PageLayout';
import SortableList from '../SortableComponent/SortableList';
import Button from '../../controls/Button';
import { fodaActions, alertActions } from '../../../redux/actions';
import { fodaConstants } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  paper: {
    backgroundColor: '#F8F8F8',
    minHeight: 200,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(2),
    },
  },
  closeIcon: {
    width: '14px',
    height: '13px',
    cursor: 'pointer',
  },
  dialogPaper: {
    width: '468px',
    height: '328px',
  },
}));

function FodaFormPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const company = useSelector((state) => state.company.data);
  const user = useSelector((state) => state.authentication.user);
  const isAdmin = user.roles.includes('Admin');
  const initialFieldValues = {
    foda: { swotItems: [] },
    weaknessesList: [],
    threatsList: [],
    fortressList: [],
    opportunitiesList: [],
  };
  const [values, setValues] = useState(initialFieldValues);
  const modifyText = intl.formatMessage({ id: 'app.modify', defaultMessage: 'Modificar' });
  const types = {
    weaknessesType: 2,
    threatsType: 3,
    fortressType: 0,
    opportunitiesType: 1,
  };

  const {
    foda, weaknessesList, threatsList, fortressList, opportunitiesList,
  } = values;

  const title = intl.formatMessage({ id: 'foda.title', defaultMessage: 'FODA' });
  const lastModifiedLabel = intl.formatMessage({ id: 'foda.lastModified', defaultMessage: 'Última modificación' });
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    function emptyLists() {
      const arrayList = [fortressList, opportunitiesList, weaknessesList, threatsList];
      return arrayList.every((element) => element.length === 0);
    }
    if (company) {
      setValues({ ...values, foda: { swotItems: company.swotItems } });
      if (company.swotItems && company.swotItems.length > 0 && emptyLists()) {
        company.swotItems.sort((a, b) => a.order - b.order);
        company.swotItems.forEach((swotItem) => {
          switch (swotItem.type) {
            case fodaConstants.FORTRESS_TYPE: fortressList.push(swotItem); break;
            case fodaConstants.OPPORTUNITIES_TYPE: opportunitiesList.push(swotItem); break;
            case fodaConstants.WEAKNESSES_TYPE: weaknessesList.push(swotItem); break;
            case fodaConstants.THREATS_TYPE: threatsList.push(swotItem); break;
            default: break;
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [dispatch, company]);

  function FodaBreadcrumbs() {
    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/foda" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{title}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{modifyText}</strong></Typography>
      </Breadcrumbs>
    );
  }

  function lastModifiedText() {
    return (
      <Typography
        variant="caption"
        style={{ fontWeight: 400, fontSize: 11, color: '#757575' }}
      >
        {lastModifiedLabel}
        {' '}
        {moment(foda.lastModifiedDate).format('DD/MM/YYYY - H:mm')}
        {' '}
        hs.
      </Typography>
    );
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (e.target.keyCode !== 13) {
      setSubmitted(true);
      const savedFoda = {
        swotItems:
          fortressList
            .concat(opportunitiesList)
            .concat(weaknessesList)
            .concat(threatsList),
      };
      dispatch(fodaActions.update(JSON.stringify(savedFoda), company.id));
    }
  }

  if (!isAdmin) {
    const message = intl.formatMessage({ id: 'error.UserCanNotPerformTheAction', defaultMessage: 'El usuario no puede realizar la acción debido a sus roles asignados.' });
    dispatch(alertActions.error(message));
    return <Redirect to={{ pathname: '/home' }} />;
  }

  return (
    <>
      {foda && company
        ? (
          <PageLayout
            title={title}
            breadCrumbs={<FodaBreadcrumbs />}
            buttonGroup={lastModifiedText()}
          >
            <Box pl={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Box display="flex" alignItems="align-start" justifyContent="align-start" mt={2} mb={2}>
                    <SortableList
                      type={types.weaknessesType}
                      values={values}
                      listItems={weaknessesList}
                      setValues={setValues}
                      addItemText={intl.formatMessage({ id: 'foda.addWeaknesses', defaultMessage: 'Agregar debilidad' })}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box display="flex" alignItems="align-start" justifyContent="align-start" mt={2} mb={2}>
                    <SortableList
                      type={types.threatsType}
                      values={values}
                      listItems={threatsList}
                      setValues={setValues}
                      addItemText={intl.formatMessage({ id: 'foda.addThreats', defaultMessage: 'Agregar amenaza' })}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box display="flex" alignItems="align-start" justifyContent="align-start" mt={2} mb={2}>
                    <SortableList
                      type={types.fortressType}
                      values={values}
                      listItems={fortressList}
                      setValues={setValues}
                      addItemText={intl.formatMessage({ id: 'foda.addFortress', defaultMessage: 'Agregar fortaleza' })}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box display="flex" alignItems="align-start" justifyContent="align-start" mt={2} mb={2}>
                    <SortableList
                      type={types.opportunitiesType}
                      values={values}
                      listItems={opportunitiesList}
                      setValues={setValues}
                      addItemText={intl.formatMessage({ id: 'foda.addOpportunities', defaultMessage: 'Agregar oportunidad' })}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end" mt={2}>
              <Button
                component={Link}
                to="/foda"
                variant="outlined"
                text={<FormattedMessage id="app.back" defaultMessage="volver" />}
              />
              <Button
                onClick={handleSubmit}
                loading={submitted}
                text={<FormattedMessage id="app.save" defaultMessage="Guardar" />}
                disabled={!isAdmin}
              />
            </Box>
          </PageLayout>
        )
        : (
          <PageLayout title={title} breadCrumbs={<FodaBreadcrumbs />}>
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
}

export default FodaFormPage;
