import React, { useEffect } from 'react';
import {
  Box, Grid, makeStyles, Typography, CircularProgress,
} from '@material-ui/core';
import Dropzone from 'react-dropzone-uploader';
import PropTypes from 'prop-types';
import PublishIcon from '@material-ui/icons/Publish';
import { useIntl } from 'react-intl';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import { useSelector } from 'react-redux';
import FileIcon from '../../assets/icons/FileIcon.png';
import { renderFileSize } from '../../helpers';

const useStyles = makeStyles(() => ({
  uploadTextButton: {
    fontSize: 9,
    textTransform: 'uppercase',
    lineHeight: '15px',
  },
  main: {
    backgroundColor: '#F8F8F8',
    maxWidth: 550,
    paddingTop: 32,
  },
  fileIcon: {
    width: '45px',
    height: '54px',
  },
  successAlertContainer: {
    width: 209,
    height: 23,
    marginTop: 8,
  },
  uploadButton: {
    width: '137px',
    height: '25px',
    backgroundColor: '#F16C24',
    color: '#fff',
    cursor: 'pointer',
    padding: '5px 15px',
    borderRadius: '30px',
  },
  uploadContainer: {
    textAlign: 'center',
  },
  justifyButton: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const BulkCreationDropzone = ({ fileUpload, setFileUpload, handleDispatcher }) => {
  const intl = useIntl();
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const uploadRestrictionText = intl.formatMessage({ id: 'user.documentRestrictionExcelFile', defaultMessage: 'Un archivo máximo de 10 MB, formatos permitidos xlsx' });

  const InputLoaded = (meta) => {
    const fileExtension = fileUpload ? fileUpload.name.split('.').pop() : '';

    useEffect(() => {
      if (user.error) {
        meta.fileWithMeta.remove();
        setFileUpload(null);
      }
    }, [meta.fileWithMeta]);

    return (
      <div className={classes.uploadContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} pt={5} mt={5}>
            <img className={classes.fileIcon} src={FileIcon} alt="File" />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{
              fontSize: '10px', color: '#000000', fontWeight: '500', lineHeight: '18px',
            }}
            >
              {fileUpload && fileUpload.name}
            </Typography>
            <Typography style={{
              fontSize: '8px', color: '#757575', fontWeight: '500', lineHeight: '18px', textAlign: 'center',
            }}
            >
              {renderFileSize(fileUpload && fileUpload.size, fileExtension)}
            </Typography>
            <Box display="flex" justifyContent="center" className={classes.successAlertContainer}>
              {user.creating && <CircularProgress size={24} />}
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  };
  const Input = ({
    // eslint-disable-next-line react/prop-types
    onFiles, file, getFilesFromEvent,
  }) => {
    const uploadText = intl.formatMessage({ id: 'app.upload_file', defaultMessage: 'SUBIR ARCHIVO' });

    function handleChangeFile(e) {
      getFilesFromEvent(e).then((chosenFiles) => {
        onFiles(chosenFiles);
        setFileUpload(chosenFiles[0]);
      });
    }

    return (
      <div className={classes.uploadContainer}>
        <Grid container>
          <Grid item xs={12} pt={5} mt={5}>
            <img className={classes.fileIcon} src={FileIcon} alt="File" />
          </Grid>
          <Grid item xs={12} className={classes.justifyButton}>
            <label htmlFor="file">
              <Box display="flex" justifyContent="center" className={classes.uploadButton}>
                <PublishIcon display="inline" style={{ fontSize: 14, marginRight: 8 }} />
                <Typography display="inline" className={classes.uploadTextButton}>{uploadText}</Typography>
                <input
                  style={{ display: 'none' }}
                  id="file"
                  name="file"
                  value={file}
                  type="file"
                  onChange={handleChangeFile}
                />
              </Box>
            </label>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" style={{ fontSize: '9px', color: '#757575' }}>
              {uploadRestrictionText}
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  };

  const getFilesFromEvent = (e) => new Promise((resolve) => {
    getDroppedOrSelectedFiles(e).then((chosenFiles) => {
      resolve(chosenFiles.map((f) => f.fileObject));
    });
  });

  function handleChangeStatus({ file }, status) {
    if (status === 'done') {
      setFileUpload(file);
      const fd = new FormData();
      fd.append('title', 'ListUser');
      fd.append('file', file);
      handleDispatcher(fd);
    }
  }

  return (
    <Box p={3} className={classes.main}>
      <Dropzone
        style={{ height: '150px' }}
        InputComponent={Input}
        PreviewComponent={InputLoaded}
        getFilesFromEvent={getFilesFromEvent}
        onChangeStatus={handleChangeStatus}
        maxFiles={1}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
    </Box>
  );
};

BulkCreationDropzone.propTypes = {
  fileUpload: PropTypes.shape(),
  setFileUpload: PropTypes.func.isRequired,
  handleDispatcher: PropTypes.func.isRequired,
};

BulkCreationDropzone.defaultProps = {
  fileUpload: null,
};

export default BulkCreationDropzone;
