import React from 'react';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

const DocumentsPageBreadcrumbs = () => {
    const intl = useIntl();

    const title = intl.formatMessage({ id: 'document.documentsTitle', defaultMessage: 'Documentos' });

    return (
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/documents" style={{ textDecoration: 'none' }}>
                <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{title}</Typography>
            </Link>
        </Breadcrumbs>
    );
};

export default DocumentsPageBreadcrumbs;
