/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  makeStyles, Typography, Box, Grid, Container, Divider, Hidden, IconButton, MenuItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PublishIcon from '@material-ui/icons/Publish';
import PropTypes from 'prop-types';
import { validationConstants } from '../../constants';
import Button from '../controls/Button';
import { documentActions } from '../../redux/actions';
import FileIcon from '../../assets/icons/FileIcon.png';
import { QuestionIcon } from '../../assets/icons';
import { renderFileSize } from '../../helpers';
import { personService } from '../../services';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  paper: {
    backgroundColor: '#EEEEEE',
    padding: theme.spacing(5),
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
  },
  questionIcon: {
    width: '15px',
    height: '15px',
    color: '#F16C24',
  },
  fileIcon: {
    width: '45px',
    height: '54px',
  },
  clearIcon: {
    color: '#F16C24',
  },
  successAlertContainer: {
    backgroundColor: '#43A047',
    width: 209,
    height: 23,
    marginTop: 18,
  },
  checkIcon: {
    width: 13,
    height: 13,
    color: '#FFFFFF',
    display: 'inline',
    margin: 'auto',
  },
  successAlert: {
    paddingLeft: 5,
    margin: 'auto',
    fontSize: 10,
    fontWeight: 500,
    lineHeight: '24px',
    display: 'inline',
    color: '#FFFFFF',
  },
  quill: {
    height: 120,
  },
  uploadButton: {
    width: '130px',
    height: '25px',
    backgroundColor: '#F16C24',
    color: '#fff',
    cursor: 'pointer',
    padding: '5px 15px',
    borderRadius: '30px',
  },
  uploadContainer: {
    textAlign: '-webkit-center',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.4,
  },
  chooseFile: {
    fontSize: '12px',
    fontWeight: 'normal',
    textAlign: 'center',
    lineHeight: '15px',
    color: '#373435',
    position: 'absolute',
    top: '30%',
    width: 367,
  },
  chooseQuill: {
    fontSize: 12,
    color: '#373435',
    position: 'relative',
    top: '-40px',
    width: 367,
  },
  uploadTextButton: {
    fontSize: 9,
    textTransform: 'uppercase',
    lineHeight: '15px',
  },
  formControl: {
    marginTop: '0px',
  },
}));

const initialFieldValues = {
  title: '',
  name: '',
  file: '',
  content: '',
  reviewerId: '',
};

function DocumentForm({ entity, entityId }) {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const [values, setValues] = useState(initialFieldValues);
  const [people, setPeople] = useState([]);
  const creatingDocument = useSelector((state) => state.document.creating);
  const [usingEditor, setUsingEditor] = useState(null);
  const {
    title, name, content, file, reviewerId,
  } = values;
  const titleLabel = intl.formatMessage({ id: 'document.titleForm', defaultMessage: 'Título del documento' });
  const nameLabel = intl.formatMessage({ id: 'document.nameForm', defaultMessage: 'Nombre versión (opcional)' });
  const reviewerUserNamesLabel = intl.formatMessage({ id: 'document.reviewerUserNames', defaultMessage: 'Revisor' });
  const selectOption = intl.formatMessage({ id: 'document.selectOption', defaultMessage: 'Seleccione una opción' });
  const helpLabel = intl.formatMessage({ id: 'document.help_label', defaultMessage: '¿Cómo crear un nuevo documento?' });
  const helpText = intl.formatMessage({ id: 'document.help_text', defaultMessage: 'Puede crear el documento desde el editor de texto o suba un archivo desde su computadora en caso de tenerlo.' });
  const cancelText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const saveText = intl.formatMessage({ id: 'app.save', defaultMessage: 'Guardar' });
  const uploadRestrictionText = intl.formatMessage({ id: 'document.restriction', defaultMessage: 'Un archivo máximo de 10 MB, formatos permitidos pdf, txt, doc' });

  useEffect(() => {
    const fetchData = async () => {
      const resPersonService = await personService.getAll();
      setPeople(resPersonService);
    };
    fetchData();
  }, []);

  function handleUseFile() {
    setUsingEditor(false);
  }

  const InputDisabled = () => (
    <Box display="flex" justifyContent="center">

      <Typography variant="caption" className={classes.chooseFile}>
        <FormattedMessage id="document.chooseFileText" defaultMessage="Usted eligió el editor de texto, si ya tiene un documento puede" />
        <Link style={{ paddingLeft: 5, color: '#F16C24' }} onClick={handleUseFile}>
          <FormattedMessage id="document.chooseFile" defaultMessage="subir el archivo" />
        </Link>
      </Typography>
    </Box>
  );

  const InputLoaded = (meta) => {
    const fileExtension = file ? file.name.split('.').pop() : '';
    const handleClearFile = () => {
      meta.fileWithMeta.remove();
      setValues({ ...values, file: '' });
    };
    return (
      <div className={classes.uploadContainer}>
        <Grid container spacing={2} className={(usingEditor) ? classes.disabled : ''}>
          <Grid item xs={12} pt={5} mt={5}>
            <img className={classes.fileIcon} src={FileIcon} alt="File" />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{
              fontSize: '10px', color: '#000000', fontWeight: '500', lineHeight: '18px',
            }}
            >
              {file.name}
              <IconButton onClick={handleClearFile} style={{ textDecoration: 'none', padding: '0px' }}>
                <ClearIcon className={classes.clearIcon} />
              </IconButton>
            </Typography>
            <Typography style={{
              fontSize: '8px', color: '#757575', fontWeight: '500', lineHeight: '18px', textAlign: 'center',
            }}
            >
              {renderFileSize(file.size, fileExtension)}
            </Typography>
            <Box display="flex" className={classes.successAlertContainer}>
              <CheckCircleIcon className={classes.checkIcon} />
              <Typography className={classes.successAlert}>
                {intl.formatMessage({ id: 'document.upload_success', defaultMessage: 'El archivo se subió correctamente' })}
              </Typography>
            </Box>
          </Grid>

        </Grid>
        { (usingEditor) && <InputDisabled />}

      </div>
    );
  };
  const Input = ({
    // eslint-disable-next-line react/prop-types
    onFiles, file: fileInput, getFilesFromEvent: getFilesFromEventInput,
  }) => {
    const uploadText = intl.formatMessage({ id: 'app.upload_file', defaultMessage: 'SUBIR ARCHIVO' });

    function handleChangeFile(e) {
      getFilesFromEventInput(e).then((chosenFiles) => {
        onFiles(chosenFiles);
        setValues({
          ...values, file: chosenFiles[0],
        });
      });
    }

    return (
      <div className={classes.uploadContainer}>
        <Grid container spacing={2} className={(usingEditor) ? classes.disabled : ''}>
          <Grid item xs={12} pt={5} mt={5}>
            <img className={classes.fileIcon} src={FileIcon} alt="File" />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="file">
              <Box display="flex" justifyContent="center" className={classes.uploadButton}>
                <PublishIcon display="inline" style={{ fontSize: 14, marginRight: 8 }} />
                <Typography display="inline" className={classes.uploadTextButton}>{uploadText}</Typography>
                <input
                  style={{ display: 'none' }}
                  id="file"
                  name="file"
                  value={fileInput}
                  type="file"
                  onChange={handleChangeFile}
                />
              </Box>
            </label>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" style={{ fontSize: '9px', color: '#757575' }}>
              {uploadRestrictionText}
            </Typography>
          </Grid>
        </Grid>
        { (usingEditor) && <InputDisabled />}

      </div>
    );
  };

  const getFilesFromEvent = (e) => new Promise((resolve) => {
    getDroppedOrSelectedFiles(e).then((chosenFiles) => {
      resolve(chosenFiles.map((f) => f.fileObject));
    });
  });

  function handleSubmit(e) {
    e.preventDefault();
    const fd = new FormData();
    fd.append('title', title);
    fd.append('reviewerId', Number(reviewerId));
    if (usingEditor) {
      fd.append('editableHtml', content);
    } else {
      fd.append('file', file);
    }
    fd.append('versionName', name);
    if (entity === 'process') { dispatch(documentActions.createFromProcess(fd, entityId)); }
    if (entity === 'person') { dispatch(documentActions.createFromPerson(fd, entityId)); }
    if (entity === 'deviation') { dispatch(documentActions.createFromDeviation(fd, entityId)); }
    if (entity === 'training') { dispatch(documentActions.createFromTraining(fd, entityId)); }
    if (entity === 'provider') { dispatch(documentActions.createFromProvider(fd, entityId)); }
    if (entity === 'workFieldEvaluation') { dispatch(documentActions.createFromWorkFieldEvaluation(fd, entityId)); }
  }

  function handleChange(e) {
    const { name: nameChanged, value } = e.target;
    setValues({
      ...values,
      [nameChanged]: value,
    });
  }

  function handleChangeQuill(html) {
    if (html === '<p><br></p>') {
      setValues({
        ...values, content: '',
      });
    } else {
      setValues({
        ...values, content: html,
      });
      setUsingEditor(true);
    }
  }

  function handleUseEditor() {
    setUsingEditor(true);
  }

  function handleChangeStatus({ file: fileChanged }, status) {
    if (status === 'done') {
      setValues({
        ...values, file: fileChanged,
      });
      setUsingEditor(false);
    }
  }

  const toolbarOptions = [
    [{ font: [] }],
    [{ size: ['small', false, 'large', 'huge'] }],

    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],

    ['blockquote'],

    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],

    ['link', 'image'],
    ['clean'],
  ];

  const modules = {
    toolbar: toolbarOptions,
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color', 'background', 'font', 'size',
  ];

  return (
    <div className={classes.main}>
      <ValidatorForm onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={4}>
            <Container>
              <TextValidator
                value={title}
                onChange={handleChange}
                fullWidth
                margin="normal"
                id="title"
                label={titleLabel}
                name="title"
                autoComplete="title"
                size="small"
                validators={['required']}
                errorMessages={[validationConstants.DOCUMENT_TITLE_REQUIRED]}
              />
            </Container>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Container>
              <TextValidator
                value={name}
                onChange={handleChange}
                fullWidth
                margin="normal"
                id="name"
                label={nameLabel}
                name="name"
                autoComplete="name"
                size="small"
              />
            </Container>
          </Grid>
          <Hidden smDown>
            <Grid item md={1} />
          </Hidden>
          <Grid item xs={12} sm={6} md={4}>
            <Container>
              <SelectValidator
                className={classes.formControl}
                onChange={handleChange}
                value={reviewerId}
                fullWidth
                id="reviewerId"
                label={reviewerUserNamesLabel}
                name="reviewerId"
                size="small"
                validators={['required']}
                errorMessages={[validationConstants.DOCUMENT_REVIEWER_REQUIRED]}
              >
                <MenuItem style={{ fontSize: '12px' }} value={null}>
                  <em>{selectOption}</em>
                </MenuItem>
                {people.map((item) => {
                  if (!item.names) return null;
                  return (
                    <MenuItem style={{ fontSize: '12px' }} key={item.id} value={String(item.id)}>
                      {`${item.names} ${item.surnames}`}
                    </MenuItem>
                  );
                })}
              </SelectValidator>
            </Container>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <Container className={(!usingEditor && !(usingEditor === null)) ? classes.disabled : ''}>
              <ReactQuill modules={modules} formats={formats} className={classes.quill} value={content} name="content" id="content" onChange={handleChangeQuill} />
            </Container>
            <Box display="flex" justifyContent="center" textAlign="center">
              { (!usingEditor && !(usingEditor === null))
                ? (
                  <Typography variant="caption" className={classes.chooseQuill}>
                    <FormattedMessage id="document.chooseQuillText" defaultMessage="Usted eligió subir un archivo, si no tiene el documento puede" />
                    <Link style={{ paddingLeft: 5, color: '#F16C24' }} onClick={handleUseEditor}>
                      <FormattedMessage id="document.chooseQuill" defaultMessage="crearlo desde el editor" />
                    </Link>
                  </Typography>
                ) : '' }
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item xs={6} sm={6} md={3} className={classes.paper} style={{ padding: '24px' }}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <QuestionIcon className={classes.questionIcon} display="inline" />
                <Typography variant="caption" color="textPrimary" display="inline" style={{ paddingLeft: 1 }}><strong>{helpLabel}</strong></Typography>
              </Box>
              <Box xs={12} style={{ paddingTop: '12px' }}>
                <Typography variant="caption" color="textPrimary" style={{ fontSize: 12, fontWeight: 400 }}>{helpText}</Typography>
              </Box>
            </Grid>
          </Hidden>
          <Grid item xs={false} sm={12} md={9} style={{ paddingBottom: 0 }}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Divider variant="middle" display="inline" style={{ width: '45%' }} />
              <Typography style={{ fontWeight: 500, fontSize: '14px', color: '#757575' }} display="inline"> O </Typography>
              <Divider variant="middle" display="inline" style={{ width: '45%' }} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <Container>
              <Dropzone
                style={{ height: '150px' }}
                InputComponent={Input}
                PreviewComponent={InputLoaded}
                getFilesFromEvent={getFilesFromEvent}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
              />

            </Container>
          </Grid>
        </Grid>

        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
          <Button component={Link} to="/home" variant="outlined" text={cancelText} />
          <Button loading={creatingDocument} type="submit" text={saveText} disabled={!isAdmin} />
        </Box>
      </ValidatorForm>
    </div>
  );
}

DocumentForm.propTypes = {
  entity: PropTypes.string,
  entityId: PropTypes.string,
};

DocumentForm.defaultProps = {
  entity: '',
  entityId: '',
};

export default DocumentForm;
