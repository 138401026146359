import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, Box, Grid, Typography, InputAdornment, IconButton, Divider, makeStyles
} from '@material-ui/core/';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { FormattedMessage, useIntl } from 'react-intl';
import AuthLayout from '../AuthLayout/AuthLayout';
import Button from '../../controls/Button';
import GoogleButton from '../../controls/GoogleButton';
import { userActions } from '../../../redux/actions';
import {
  privacyPolicyText, termsAndConditionsText, validationConstants, videoTutorialId,
} from '../../../constants';
import VideoTutorialModal from '../../controls/VideoTutorialModal';
import MuiPhoneNumberValidator from '../../controls/MuiPhoneNumberValidator';
import CheckboxValidator from '../../controls/CheckboxValidator';
import colors from '../../../assets/colors';
import TermsAndConditionsModal from '../../controls/TermsAndConditionsModal';
import { validatorHelper } from '../../../helpers';
import TaxIdSelector from '../TaxIdSelector/TaxIdSelector';
import { handleChange } from '../../../common/handlers';

const useStyles = makeStyles(() => ({
  contract: {
    fontSize: '12px',
    cursor: 'pointer',
    color: colors.darkGray,
  },
}));

const initialFieldValues = {
  email: '',
  firstName: '',
  lastName: '',
  companyName: '',
  password: '',
  phoneNumber: '',
  showPassword: false,
  termsAndConditions: false,
  privacyPolicy: false,
  taxIDType: '',
  taxID: '',
};
const initialModalState = {
  openTermsAndConditions: false,
  openPrivacyPolicy: false,
};

function RegisterPage() {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const registering = useSelector((state) => state.registration.registering);
  const loggingIn = useSelector((state) => state.authentication.loggingIn);
  const [values, setValues] = useState(initialFieldValues);
  const [modalState, setModalState] = useState(initialModalState);

  const passwordCriteriaText = intl.formatMessage({ id: 'app.passwordCriteria', defaultMessage: 'La contraseña debe tener al menos 6 caracteres, 1 minúscula, 1 mayúscula, 1 caracter numérico y 1 caracter no alfanumérico' });
  const phoneNumberLabel = intl.formatMessage({ id: 'people.phoneNumber', defaultMessage: 'Número de teléfono' });

  const titleWithTutorialVideo = (
    <Box style={{ flexGrow: 1 }}>
      <FormattedMessage
        id="register.title"
        defaultMessage="Complete sus datos para ingresar al sistema"
      />
      <VideoTutorialModal
        title={<FormattedMessage id="videoTutorial.userRegistrationTitle" defaultMessage="Registro de usuario" />}
        body={<FormattedMessage id="videoTutorial.userRegistrationBody" defaultMessage="Aprende a generar tu usuario en forma clara y segura" />}
        videoId={videoTutorialId.USER_UPLOAD}
        isRegister
      />
    </Box>
  );

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'register.docTitle', defaultMessage: 'Pertix - Registro' });
    ValidatorForm.addValidationRule('isTruthy', (value) => value);
    userActions.logout();
  }, [dispatch, intl]);

  function handleSubmit(e) {
    e.preventDefault();
    const user = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      companyName: values.companyName,
      password: values.password,
      phoneNumber: validatorHelper.phoneNumberFormatted(values.phoneNumber),
      stripeCustomerTaxType: values.taxIDType,
      stripeCustomerTaxId: values.taxID,
    };
    dispatch(userActions.register(user));
  }

  function handleChangePhoneNumber(value) {
    setValues({ ...values, phoneNumber: value });
  }

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    setValues({ ...values, [name]: checked });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function handleModalState(name, value) {
    setModalState({ ...modalState, [name]: value });
  }

  return (
    <AuthLayout
      pageTitle={titleWithTutorialVideo}
    >
      <ValidatorForm style={{ width: '100%' }} onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={6} style={{ paddingRight: '8px' }}>
            <TextValidator
              value={values.firstName}
              onChange={(event) => handleChange({ event, setValues, values })}
              fullWidth
              margin="normal"
              id="firstName"
              label={<FormattedMessage id="register.firstName" defaultMessage="Nombre" />}
              name="firstName"
              validators={['required']}
              errorMessages={[validationConstants.FIRST_NAME_REQUIRED]}
              autoFocus
            />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: '16px' }}>
            <TextValidator
              value={values.lastName}
              onChange={(event) => handleChange({ event, setValues, values })}
              fullWidth
              margin="normal"
              id="lastName"
              label={<FormattedMessage id="register.lastName" defaultMessage="Apellido" />}
              name="lastName"
              validators={['required']}
              errorMessages={[validationConstants.LAST_NAME_REQUIRED]}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingRight: '8px' }}>
            <TextValidator
              value={values.email}
              onChange={(event) => handleChange({ event, setValues, values })}
              fullWidth
              margin="normal"
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              validators={['required', 'isEmail']}
              errorMessages={[
                validationConstants.EMAIL_REQUIRED,
                validationConstants.EMAIL_INVALID,
              ]}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: '16px' }}>
            <MuiPhoneNumberValidator
              autoFormat={false}
              value={values.phoneNumber}
              onChange={handleChangePhoneNumber}
              defaultCountry="ar"
              fullWidth
              margin="normal"
              label={phoneNumberLabel}
              validators={['required', 'minStringLength:6']}
              errorMessages={[
                validationConstants.PERSON_PHONE_REQUIRED,
                validationConstants.PERSON_PHONE_INVALID,
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              value={values.companyName}
              onChange={(event) => handleChange({ event, setValues, values })}
              fullWidth
              margin="normal"
              name="companyName"
              label={<FormattedMessage id="register.company" defaultMessage="Empresa" />}
              type="companyName"
              id="companyName"
              validators={['required']}
              errorMessages={[validationConstants.COMPANY_REQUIRED]}
            />
          </Grid>
          <TaxIdSelector values={values} handleChange={(event) => handleChange({ event, setValues, values })} />
          <Grid item xs={12}>
            <TextValidator
              value={values.password}
              onChange={(event) => handleChange({ event, setValues, values })}
              fullWidth
              margin="normal"
              name="password"
              label={<FormattedMessage id="app.password" defaultMessage="Contraseña" />}
              type={values.showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              validators={['required', validationConstants.PASSWORD_REGEX]}
              errorMessages={
                [validationConstants.PASSWORD_REQUIRED, validationConstants.PASSWORD_MATCH_PATTERN]
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      tabIndex="-1"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography color="textSecondary" variant="caption">{passwordCriteriaText}</Typography>
          </Grid>
          <Grid item xs={12}>
            <CheckboxValidator
              name="termsAndConditions"
              checked={values.termsAndConditions}
              value={values.termsAndConditions}
              onChange={handleChangeCheckbox}
              labelControl={(
                <Typography className={classes.contract}>
                  {termsAndConditionsText.readAndAgree}
                </Typography>
              )}
              labelRight={(
                <Typography className={classes.contract} onClick={() => handleModalState('openTermsAndConditions', true)}>
                  <b>{termsAndConditionsText.accept}</b>
                </Typography>
              )}
              validators={['isTruthy']}
              errorMessages={[validationConstants.TYC_INVALID]}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxValidator
              name="privacyPolicy"
              checked={values.privacyPolicy}
              value={values.privacyPolicy}
              onChange={handleChangeCheckbox}
              labelControl={(
                <Typography className={classes.contract}>
                  {privacyPolicyText.readAndAgree}
                </Typography>
              )}
              labelRight={(
                <Typography className={classes.contract} onClick={() => handleModalState('openPrivacyPolicy', true)}>
                  <b>{privacyPolicyText.accept}</b>
                </Typography>
              )}
              validators={['isTruthy']}
              errorMessages={[validationConstants.PP_INVALID]}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="center" mt={2} mb={3}>
              <Button
                type="submit"
                text={<FormattedMessage id="register.createAccount" defaultMessage="Crear cuenta" />}
                loading={registering || loggingIn}
              />
            </Box>

            <Box display="flex" alignItems="center" justifyContent="center">
              <Divider variant="middle" display="inline" style={{ width: '40%' }} />
              <Typography style={{ fontWeight: 500, fontSize: '14px', color: '#757575' }} display="inline"> O </Typography>
              <Divider variant="middle" display="inline" style={{ width: '40%' }} />
            </Box>

            <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
              <GoogleButton register />
            </Box>

            <Box mt={2}>
              <Typography variant="body2" color="textSecondary" align="center">
                <FormattedMessage id="register.alreadyHave" defaultMessage="Ya tengo " />
                <Link href="/">
                  <FormattedMessage id="app.account" defaultMessage="cuenta" />
                </Link>
                {' '}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <TermsAndConditionsModal
          open={modalState.openTermsAndConditions}
          setOpen={(value) => handleModalState('openTermsAndConditions', value)}
          onChange={handleChangeCheckbox}
          name="termsAndConditions"
          constants={termsAndConditionsText}
        />
        <TermsAndConditionsModal
          open={modalState.openPrivacyPolicy}
          setOpen={(value) => handleModalState('openPrivacyPolicy', value)}
          onChange={handleChangeCheckbox}
          name="privacyPolicy"
          constants={privacyPolicyText}
        />
      </ValidatorForm>
    </AuthLayout>
  );
}

export default RegisterPage;
