import React from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import { ValidatorComponent } from 'react-material-ui-form-validator';

class MuiPhoneNumberValidator extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      errorMessages,
      validators,
      requiredError,
      helperText,
      validatorListener,
      ...rest
    } = this.props;
    const { isValid } = this.state;
    return (
      <MuiPhoneNumber
        {...rest}
        disableAreaCodes
        error={!isValid}
        helperText={(!isValid && this.getErrorMessage()) || helperText}
      />
    );
  }
}

export default MuiPhoneNumberValidator;
