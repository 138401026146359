import alertActions from './alert.actions';
import { objectiveConstants } from '../../constants';
import { history } from '../../helpers';
import { objectiveService } from '../../services';

function addObjective(objective, message) {
  function request() { return { type: objectiveConstants.request.CREATE_REQUEST }; }
  function success() { return { type: objectiveConstants.request.CREATE_SUCCESS }; }
  function failure(error) { return { type: objectiveConstants.request.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    objectiveService.addObjective(objective)
      .then(
        () => {
          dispatch(success());
          dispatch(alertActions.success(message));
          history.push('/objectives');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getAllObjectives() {
  function request() { return { type: objectiveConstants.request.GET_ALL_REQUEST }; }
  function success(objective) {
    return { type: objectiveConstants.request.GET_ALL_SUCCESS, objective };
  }
  function failure(error) { return { type: objectiveConstants.request.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    objectiveService.getAllObjectives()
      .then(
        (response) => {
          dispatch(success(response));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getObjectives(id) {
  function request() { return { type: objectiveConstants.request.GET_REQUEST }; }
  function success(objective) {
    return { type: objectiveConstants.request.GET_SUCCESS, objective };
  }
  function failure(error) { return { type: objectiveConstants.request.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    objectiveService.getObjectives(id)
      .then(
        (response) => {
          dispatch(success(response));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function updateObjective(id, objective, message) {
  function request() {
    return { type: objectiveConstants.request.UPDATE_REQUEST };
  }
  function success() {
    return { type: objectiveConstants.request.UPDATE_SUCCESS };
  }
  function failure(error) {
    return { type: objectiveConstants.request.UPDATE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    objectiveService.updateObjective(id, objective)
      .then(
        () => {
          dispatch(success());
          dispatch(alertActions.success(message));
          history.push('/objectives');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function updateMeasure(id, objective, message) {
  function request() {
    return { type: objectiveConstants.request.UPDATE_MEASURE_REQUEST };
  }
  function success(updatedObjective) {
    return { type: objectiveConstants.request.UPDATE_MEASURE_SUCCESS, updatedObjective };
  }
  function failure(error) {
    return { type: objectiveConstants.request.UPDATE_MEASURE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    objectiveService.updateMeasure(id, objective)
      .then(
        (response) => {
          dispatch(success(response));
          dispatch(alertActions.success(message));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function deleteObjective(objectiveId, message) {
  function request() { return { type: objectiveConstants.request.DELETE_REQUEST }; }
  function success() { return { type: objectiveConstants.request.DELETE_SUCCESS }; }
  function failure() { return { type: objectiveConstants.request.DELETE_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    objectiveService.deleteObjective(objectiveId)
      .then(
        () => {
          dispatch(success());
          dispatch(alertActions.success(message));
          history.push('/objectives');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function createBulkObjectives(objectivesExcelFile) {
  function request() { return { type: objectiveConstants.request.CREATE_BULK_REQUEST }; }
  function success(bulkCreationPreviewResult) {
    return {
      type: objectiveConstants.request.CREATE_BULK_SUCCESS,
      bulkCreationPreviewResult,
    };
  }
  function failure(error) {
    return { type: objectiveConstants.request.CREATE_BULK_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    objectiveService.createBulkObjectives(objectivesExcelFile)
      .then(
        (bulkCreationPreviewResult) => {
          dispatch(success(bulkCreationPreviewResult));
          history.push('/objectives/result');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function confirmCreateBulkObjectives(id, messageSuccess) {
  function request() { return { type: objectiveConstants.request.CONFIRM_CREATE_BULK_REQUEST }; }
  function success() { return { type: objectiveConstants.request.CONFIRM_CREATE_BULK_SUCCESS }; }
  function failure(error) {
    return { type: objectiveConstants.request.CONFIRM_CREATE_BULK_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    objectiveService.confirmCreateBulkObjectives(id)
      .then(
        () => {
          dispatch(success());
          dispatch(alertActions.success(messageSuccess));
          history.replace('/objectives');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

const objectiveActions = {
  addObjective,
  getAllObjectives,
  getObjectives,
  updateObjective,
  updateMeasure,
  deleteObjective,
  createBulkObjectives,
  confirmCreateBulkObjectives,
};

export default objectiveActions;
