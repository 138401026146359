import axios from '../axios/axios';
import { authHeader, userCompanySelectorHelper } from '../helpers';
import handler from './handler';

function update(foda) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  return axios.post(`/companies/${company.id}/swots`, foda, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const fodaService = {
  update,
};

export default fodaService;
