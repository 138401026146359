import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog, Box, TextField, Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { exampleCompanyActions } from '../../../redux/actions';
import PreviewProcessMap from './PreviewProcessMap';
import Button from '../../controls/Button';
import colors from '../../../assets/colors';
import { userCompanySelectorHelper } from '../../../helpers';
import { exampleCompanyConstants } from '../../../constants';

const useStyles = makeStyles(() => ({
  selectRubro: {
    fontWeight: 700,
    fontSize: 14,
    marginTop: 8,
  },
  toolbarTitle: {
    color: colors.orangeLight,
    fontSize: '24px',
    fontWeight: 700,
    marginBottom: '16px',
  },
  toolbarText: {
    width: '460px',
    fontWeight: 400,
    fontSize: 12,
  },
}));

const ScreenOptionsModal = ({ handleChangeScreen }) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const exampleCompanies = useSelector((state) => state.exampleCompany.data);
  const requesting = useSelector((state) => state.exampleCompany.requesting);
  const [values, setValues] = useState({});
  const currentCompany = userCompanySelectorHelper.getCurrentCompany();
  const processMapIsSelected = values && values.processMap;

  const rubroLabel = intl.formatMessage({ id: 'exampleCompany.rubro', defaultMessage: 'Rubros' });
  const selectRubroLabel = intl.formatMessage({ id: 'exampleCompany.selectRubro', defaultMessage: 'Seleccioná un mapa de procesos según tu rubro' });
  const goBackToPreviousStepLabel = intl.formatMessage({ id: 'exampleCompany.goBackToPreviousStep', defaultMessage: 'Volver al paso anterior' });
  const confirmSelectionLabel = intl.formatMessage({ id: 'exampleCompany.confirmSelection', defaultMessage: 'Confirmar selección' });
  const processMapExampleLabel = intl.formatMessage({ id: 'exampleCompany.processMapExample', defaultMessage: 'Mapa de proceso de muestra' });
  const toolbarTextLabel = (
    <FormattedMessage
      id="exampleCompany.toolbarText"
      defaultMessage="Una vez confirmada la selección del mapa se va a guardar en tu cuenta y <b>vas a poder modificar todos los procesos que quieras</b> para poder adaptarlo mejor a tu negocio."
      values={{ b: (chunks) => <b>{chunks}</b> }}
    />
  );

  function handleConfirmProcessMap() {
    dispatch(exampleCompanyActions.confirmProcessMap(values.id, currentCompany.id));
    setValues({});
  }

  function handleChangeCompany(e, newValue) {
    setValues(newValue);
  }

  const handleGoBack = () => {
    handleChangeScreen(exampleCompanyConstants.SCREEN_WELCOME);
    setValues({});
  };

  return (
    <Dialog open={Boolean(exampleCompanies)} fullWidth maxWidth={processMapIsSelected ? 'lg' : 'sm'} aria-labelledby="dialog-screen-options">
      <Box px={4.5} pt={processMapIsSelected ? 3 : 7} pb={processMapIsSelected ? 3 : 7}>

        <Box display="flex" justifyContent={processMapIsSelected ? 'space-between' : 'center'}>
          {processMapIsSelected && (
            <Box>
              <Typography className={classes.toolbarTitle}>{processMapExampleLabel}</Typography>
              <Typography className={classes.toolbarText}>{toolbarTextLabel}</Typography>
            </Box>
          )}

          <Box>
            <Typography className={classes.selectRubro}>{selectRubroLabel}</Typography>
            <Box py={1} display="flex" justifyContent={processMapIsSelected ? 'flex-end' : 'center'}>
              <Autocomplete
                id="exampleCompany"
                getOptionLabel={(option) => option.name}
                onChange={handleChangeCompany}
                options={exampleCompanies || []}
                renderInput={(params) => (<TextField {...params} label={rubroLabel} variant="standard" />)}
                style={{ width: 326 }}
                value={values}
              />
            </Box>
          </Box>
        </Box>

        {processMapIsSelected && <PreviewProcessMap exampleProcessMap={processMapIsSelected} />}

        <Box pt={3} display="flex" justifyContent={processMapIsSelected ? 'flex-end' : 'center'}>
          <Button
            text={goBackToPreviousStepLabel}
            variant="outlined"
            onClick={handleGoBack}
          />
          <Button
            text={confirmSelectionLabel}
            loading={requesting}
            disabled={!processMapIsSelected}
            onClick={() => handleConfirmProcessMap()}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

ScreenOptionsModal.propTypes = {
  handleChangeScreen: PropTypes.func.isRequired,
};

export default ScreenOptionsModal;
