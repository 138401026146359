import { documentConstants } from '../../constants';
import { documentService } from '../../services';
import alertActions from './alert.actions';
import processActions from './process.actions';
import { history } from '../../helpers';
import deviationActions from './deviation.actions';
import trainingActions from './training.actions';
import providerActions from './provider.actions';
import workFieldEvaluationActions from './workFieldEvaluation.actions';

function createFromPerson(fd, entityId) {
  function request(document) { return { type: documentConstants.CREATE_REQUEST, document }; }
  function success(document) { return { type: documentConstants.CREATE_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.createFromPerson(fd, entityId)
      .then(
        (document) => {
          dispatch(success(document));
          history.push(`/documents/${document.id}`);
          dispatch(alertActions.info('Se ha creado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}
function createFromProcess(fd, entityId) {
  function request(document) { return { type: documentConstants.CREATE_REQUEST, document }; }
  function success(document) { return { type: documentConstants.CREATE_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.createFromProcess(fd, entityId)
      .then(
        (document) => {
          dispatch(success(document));
          history.push(`/documents/${document.id}`);
          dispatch(alertActions.info('Se ha creado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function createFromDeviation(fd, entityId) {
  function request(document) { return { type: documentConstants.CREATE_REQUEST, document }; }
  function success(document) { return { type: documentConstants.CREATE_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.createFromDeviation(fd, entityId)
      .then(
        (document) => {
          dispatch(success(document));
          history.push(`/documents/${document.id}`);
          dispatch(alertActions.info('Se ha creado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function createFromTraining(fd, entityId) {
  function request(document) { return { type: documentConstants.CREATE_REQUEST, document }; }
  function success(document) { return { type: documentConstants.CREATE_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.createFromTraining(fd, entityId)
      .then(
        (document) => {
          dispatch(success(document));
          history.push(`/documents/${document.id}`);
          dispatch(alertActions.info('Se ha creado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function createFromProvider(fd, entityId) {
  function request(document) { return { type: documentConstants.CREATE_REQUEST, document }; }
  function success(document) { return { type: documentConstants.CREATE_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.createFromProvider(fd, entityId)
      .then(
        (document) => {
          dispatch(success(document));
          history.push(`/documents/${document.id}`);
          dispatch(alertActions.info('Se ha creado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function createFromWorkFieldEvaluation(fd, entityId) {
  function request(document) { return { type: documentConstants.CREATE_REQUEST, document }; }
  function success(document) { return { type: documentConstants.CREATE_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.createFromWorkFieldEvaluation(fd, entityId)
      .then(
        (document) => {
          dispatch(success(document));
          history.push(`/documents/${document.id}`);
          dispatch(alertActions.info('Se ha creado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function get(id) {
  function request() { return { type: documentConstants.GET_REQUEST }; }
  function success(document) { return { type: documentConstants.GET_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(id));
    documentService.get(id)
      .then(
        (document) => {
          dispatch(success(document));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
          history.push('/home');
        },
      );
  };
}

function getAll() {
  function request() { return { type: documentConstants.GET_REQUEST }; }
  function success(document) { return { type: documentConstants.GET_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());
    documentService.getAll()
      .then(
        (document) => {
          dispatch(success(document));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function unlinkProcess(processId, documentId) {
  function request(document) { return { type: documentConstants.UNLINK_REQUEST, document }; }
  function success(document) { return { type: documentConstants.UNLINK_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.UNLINK_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.unlinkProcess(processId, documentId)
      .then(
        (document) => {
          dispatch(success(document));
          dispatch(processActions.getWithDocuments(processId));
          dispatch(alertActions.info('Se ha desvinculado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function unlinkDeviation(deviationId, documentId) {
  function request(document) { return { type: documentConstants.UNLINK_REQUEST, document }; }
  function success(document) { return { type: documentConstants.UNLINK_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.UNLINK_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.unlinkDeviation(deviationId, documentId)
      .then(
        (document) => {
          dispatch(success(document));
          dispatch(deviationActions.getWithDocuments(deviationId));
          dispatch(alertActions.info('Se ha desvinculado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function unlinkTraining(trainingId, documentId) {
  function request(document) { return { type: documentConstants.UNLINK_REQUEST, document }; }
  function success(document) { return { type: documentConstants.UNLINK_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.UNLINK_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.unlinkTraining(trainingId, documentId)
      .then(
        (document) => {
          dispatch(success(document));
          dispatch(trainingActions.getWithDocuments(trainingId));
          dispatch(alertActions.info('Se ha desvinculado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function unlinkProvider(providerId, documentId) {
  function request(document) { return { type: documentConstants.UNLINK_REQUEST, document }; }
  function success(document) { return { type: documentConstants.UNLINK_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.UNLINK_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.unlinkProvider(providerId, documentId)
      .then(
        (document) => {
          dispatch(success(document));
          dispatch(providerActions.getWithDocuments(providerId));
          dispatch(alertActions.info('Se ha desvinculado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}
function unlinkWorkFieldEvaluation(workFieldEvaluationId, documentId) {
  function request(document) { return { type: documentConstants.UNLINK_REQUEST, document }; }
  function success(document) { return { type: documentConstants.UNLINK_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.UNLINK_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.unlinkWorkFieldEvaluation(workFieldEvaluationId, documentId)
      .then(
        (document) => {
          dispatch(success(document));
          dispatch(workFieldEvaluationActions.getWithDocuments(workFieldEvaluationId));
          dispatch(alertActions.info('Se ha desvinculado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function unlinkPerson(personId, documentId) {
  function request(document) { return { type: documentConstants.UNLINK_REQUEST, document }; }
  function success(document) { return { type: documentConstants.UNLINK_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.UNLINK_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.unlinkPerson(personId, documentId)
      .then(
        (document) => {
          dispatch(success(document));
          dispatch(alertActions.info('Se ha desvinculado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function deleteDocument(documentId) {
  function request(document) { return { type: documentConstants.DELETE_REQUEST, document }; }
  function success(document) { return { type: documentConstants.DELETE_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.DELETE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.deleteDocument(documentId)
      .then(
        (document) => {
          dispatch(success(document));
          dispatch(alertActions.info('Se ha eliminado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function download(id, contentType, filename) {
  function request(document) { return { type: documentConstants.DOWNLOAD_REQUEST, document }; }
  function success(document) { return { type: documentConstants.DOWNLOAD_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.DOWNLOAD_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.download(id, contentType, filename)
      .then(
        (document) => {
          dispatch(success(document));
          dispatch(alertActions.info('Se ha descargado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function newVersion(fd, documentId, type) {
  function request(document) { return { type: documentConstants.NEW_VERSION_REQUEST, document }; }
  function success(document) { return { type: documentConstants.NEW_VERSION_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.NEW_VERSION_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.newVersion(fd, documentId, type)
      .then(
        (document) => {
          dispatch(success(document));
          dispatch(alertActions.info('Se ha creado una nueva versión del documento correctamente.'));
          history.push(`/documents/${document.id}`);
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getAllFromProcess() {
  function request(documents) { return { type: documentConstants.GET_ALL_REQUEST, documents }; }
  function success(documents) { return { type: documentConstants.GET_ALL_SUCCESS, documents }; }
  function failure(error) { return { type: documentConstants.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.getAllFromProcess()
      .then(
        (documents) => {
          dispatch(success(documents));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getAllFromDeviation() {
  function request(documents) { return { type: documentConstants.GET_ALL_REQUEST, documents }; }
  function success(documents) { return { type: documentConstants.GET_ALL_SUCCESS, documents }; }
  function failure(error) { return { type: documentConstants.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.getAllFromDeviation()
      .then(
        (documents) => {
          dispatch(success(documents));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getAllFromTraining() {
  function request(documents) { return { type: documentConstants.GET_ALL_REQUEST, documents }; }
  function success(documents) { return { type: documentConstants.GET_ALL_SUCCESS, documents }; }
  function failure(error) { return { type: documentConstants.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.getAllFromTraining()
      .then(
        (documents) => {
          dispatch(success(documents));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getAllFromProvider() {
  function request(documents) { return { type: documentConstants.GET_ALL_REQUEST, documents }; }
  function success(documents) { return { type: documentConstants.GET_ALL_SUCCESS, documents }; }
  function failure(error) { return { type: documentConstants.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.getAllFromProvider()
      .then(
        (documents) => {
          dispatch(success(documents));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getAllFromWorkFieldEvaluation() {
  function request(documents) { return { type: documentConstants.GET_ALL_REQUEST, documents }; }
  function success(documents) { return { type: documentConstants.GET_ALL_SUCCESS, documents }; }
  function failure(error) { return { type: documentConstants.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.getAllFromWorkFieldEvaluation()
      .then(
        (documents) => {
          dispatch(success(documents));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getAllFromPerson() {
  function request(documents) { return { type: documentConstants.GET_ALL_REQUEST, documents }; }
  function success(documents) { return { type: documentConstants.GET_ALL_SUCCESS, documents }; }
  function failure(error) { return { type: documentConstants.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.getAllFromPerson()
      .then(
        (documents) => {
          dispatch(success(documents));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function linkProcess(processId, documentId) {
  function request(document) { return { type: documentConstants.LINK_REQUEST, document }; }
  function success(document) { return { type: documentConstants.LINK_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.LINK_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.linkProcess(processId, documentId)
      .then(
        (document) => {
          dispatch(success(document));
          dispatch(processActions.get(processId));
          dispatch(alertActions.info('Se ha vinculado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function linkDeviation(deviationId, documentId) {
  function request(document) { return { type: documentConstants.LINK_REQUEST, document }; }
  function success(document) { return { type: documentConstants.LINK_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.LINK_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.linkDeviation(deviationId, documentId)
      .then(
        (document) => {
          dispatch(success(document));
          dispatch(deviationActions.getDeviations(deviationId));
          dispatch(alertActions.info('Se ha vinculado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function linkTraining(trainingId, documentId) {
  function request(document) { return { type: documentConstants.LINK_REQUEST, document }; }
  function success(document) { return { type: documentConstants.LINK_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.LINK_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.linkTraining(trainingId, documentId)
      .then(
        (document) => {
          dispatch(success(document));
          dispatch(trainingActions.get(trainingId));
          dispatch(alertActions.info('Se ha vinculado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function linkProvider(providerId, documentId) {
  function request(document) { return { type: documentConstants.LINK_REQUEST, document }; }
  function success(document) { return { type: documentConstants.LINK_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.LINK_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.linkProvider(providerId, documentId)
      .then(
        (document) => {
          dispatch(success(document));
          dispatch(providerActions.get(providerId));
          dispatch(alertActions.info('Se ha vinculado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function linkWorkFieldEvaluation(workFieldEvaluationId, documentId) {
  function request(document) { return { type: documentConstants.LINK_REQUEST, document }; }
  function success(document) { return { type: documentConstants.LINK_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.LINK_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.linkWorkFieldEvaluation(workFieldEvaluationId, documentId)
      .then(
        (document) => {
          dispatch(success(document));
          dispatch(workFieldEvaluationActions.get(workFieldEvaluationId));
          dispatch(alertActions.info('Se ha vinculado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function linkPerson(personId, documentId) {
  function request(document) { return { type: documentConstants.LINK_REQUEST, document }; }
  function success(document) { return { type: documentConstants.LINK_SUCCESS, document }; }
  function failure(error) { return { type: documentConstants.LINK_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    documentService.linkPerson(personId, documentId)
      .then(
        (document) => {
          dispatch(success(document));
          window.location.reload(false);
          dispatch(alertActions.info('Se ha vinculado el documento correctamente.'));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

const documentActions = {
  createFromPerson,
  createFromProcess,
  createFromDeviation,
  unlinkProcess,
  unlinkPerson,
  unlinkDeviation,
  deleteDocument,
  download,
  newVersion,
  get,
  getAll,
  getAllFromProcess,
  getAllFromPerson,
  getAllFromDeviation,
  linkProcess,
  linkPerson,
  linkDeviation,
  createFromTraining,
  linkTraining,
  getAllFromTraining,
  unlinkTraining,
  createFromProvider,
  linkProvider,
  getAllFromProvider,
  unlinkProvider,
  createFromWorkFieldEvaluation,
  linkWorkFieldEvaluation,
  getAllFromWorkFieldEvaluation,
  unlinkWorkFieldEvaluation,
};

export default documentActions;
