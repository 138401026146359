import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    dialogActions: {
        padding: '30px 0px'
    },
    dialogContent: {
        overflow: 'hidden',
        padding: '0px 20px'
    },
    dialogContentIsClosing: {
        padding: '0px 80px'
    },
    dialogPaper: {
        minHeight: '391px',
        minWidth: '600px',
    },
    dialogPaperIsClosing: {
        minHeight: '269px',
        minWidth: '600px',
    },
    isClosingMessage: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px',
        textAlign: 'center',
    },
    textTitleDialog: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '18px',
        paddingBottom: '10px',
        textTransform: 'uppercase'
    },
    textTitleDialogIsClosing: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '18px',
        paddingTop: '30px',
        textAlign: 'center',
        textTransform: 'uppercase'
    }
}));

export default useStyles;
