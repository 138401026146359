import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography, Box, DialogContent, makeStyles, Select, MenuItem, CircularProgress,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../../controls/Button';
import { CloseIcon } from '../../../assets/icons';
import { personActions } from '../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  title: {
    fontSize: '14px',
    fontWeight: 700,
    paddingTop: '25px',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '12px',
  },
  closeIcon: {
    width: '14px',
    height: '13px',
    cursor: 'pointer',
  },
}));

function SetNewPersonModal(props) {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const people = useSelector((state) => state.person.people);
  const linkingPerson = useSelector((state) => state.person.linking);
  const chooseText = intl.formatMessage({ id: 'user.choosePerson', defaultMessage: 'Elija la persona que desea asociar, también puede' });
  const createLink = intl.formatMessage({ id: 'user.createPerson', defaultMessage: 'crear una nueva persona.' });
  const noPersonsText = intl.formatMessage({ id: 'user.noPeople', defaultMessage: 'No hay personas para agregar, puede ' });
  const linkPersonText = intl.formatMessage({ id: 'user.linkPerson', defaultMessage: 'Asociar persona' });
  const nonePersonLabel = intl.formatMessage({ id: 'person.none', defaultMessage: 'Ninguna' });
  const {
    isOpen, setIsOpen, userId, linkedPersonId,
  } = props;
  const [personId, setPersonId] = useState(linkedPersonId || '');
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    getContentAnchorEl: null,
  };

  useEffect(() => {
    if (userId) { dispatch(personActions.getAll()); }
    if (linkingPerson) { setIsOpen(false); }
  }, [dispatch, setIsOpen, linkingPerson, userId]);

  function handleClose() {
    return setIsOpen(!isOpen);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (personId) {
      dispatch(personActions.linkUser(personId, userId));
    } else if (linkedPersonId) {
      dispatch(personActions.unlinkUser(linkedPersonId));
    }
  }

  function handleChange(e) {
    const { value } = e.target;
    setPersonId(value);
  }

  return (

    <DialogContent>
      <Box display="flex" justifyContent="flex-end">
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </Box>

      <Typography align="center" className={classes.title}>
        {linkPersonText.toUpperCase()}
      </Typography>

      <Box pt={2} align="center">
        {!people && <CircularProgress />}

        {people && people.length > 0 && (
          <>
            <Box mb={3} pl={5} pr={5} ml={5} mr={5} align="center">
              <Typography className={classes.subtitle} align="center" display="inline">
                {' '}
                {chooseText}
                {' '}
              </Typography>

              <Link to="/people/new" style={{ textDecoration: 'none' }}>
                <Typography className={classes.subtitle} align="center" display="inline" style={{ color: '#F16C24' }}>
                  {' '}
                  {createLink}
                  {' '}
                </Typography>
              </Link>

            </Box>
            <Select
              id="personId"
              name="personId"
              value={personId}
              displayEmpty
              onChange={handleChange}
              MenuProps={MenuProps}
              style={{ width: '100%' }}
            >
              <MenuItem disabled value="">
                <em>{linkPersonText}</em>
              </MenuItem>
              <MenuItem value="">
                <em>{nonePersonLabel}</em>
              </MenuItem>
              { people.map((person) => (
                <MenuItem key={person.id} value={person.id}>{`${person.names} ${person.surnames}`}</MenuItem>
              ))}
            </Select>
          </>
        )}
        { people && (!(people.length > 0)) && (
          <Box mt={5} pl={5} pr={5} ml={5} mr={5} align="center">
            <Typography className={classes.subtitle} align="center" display="inline">
              {' '}
              {noPersonsText}
              {' '}
            </Typography>

            <Link to="/people/new" style={{ textDecoration: 'none' }}>
              <Typography className={classes.subtitle} align="center" display="inline" style={{ color: '#F16C24' }}>
                {' '}
                {createLink}
                {' '}
              </Typography>
            </Link>
          </Box>
        )}

      </Box>
      <Box display="flex" alignItems="right" justifyContent="flex-end" mt={5}>
        <Button
          onClick={handleClose}
          variant="outlined"
          text={<FormattedMessage id="app.cancel" defaultMessage="Cancelar" />}
        />
        <Button
          onClick={handleSubmit}
          type="submit"
          loading={linkingPerson}
          text={<FormattedMessage id="app.link" defaultMessage="Asociar" />}
        />
      </Box>
    </DialogContent>

  );
}

SetNewPersonModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  userId: PropTypes.number,
  linkedPersonId: PropTypes.number,
};

SetNewPersonModal.defaultProps = {
  isOpen: false,
  setIsOpen: null,
  userId: 0,
  linkedPersonId: 0,
};

export default SetNewPersonModal;
