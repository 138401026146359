/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
  Box, Container, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Grid, Typography, makeStyles, Divider,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Button from '../../controls/Button';
import { deviationActions, workFieldEvaluationActions } from '../../../redux/actions';
import { personService } from '../../../services';
import { deviationTexts, deviationConstants, workFieldEvaluationTexts } from '../../../constants';
import DeviationVersionHistory from '../DeviationVersionHistory';
import DeviationDocuments from '../DeviationDocuments';
import { workFieldHelper } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  textSeparator: {
    marginLeft: '24px',
    marginTop: '40px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  textSeparatorWithoutPT: {
    marginLeft: '24px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  textTitle: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '12px',
    textAlign: 'left',
  },
  textTitlePotentialCause: {
    fontSize: '12px',
    lineHeight: '12px',
    textAlign: 'left',
  },
  textValue: {
    paddingTop: '8px',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'left',
    textDecoration: 'none',
  },
  divider: {
    marginBottom: '16px',
    marginLeft: '24px',
  },
  linkDocuments: {
    color: theme.palette.primary.main,
    fontSize: '10px',
    fontWeight: 600,
    textTransform: 'uppercase',
    textDecoration: 'none',
  },
}));

const DeviationViewDetail = ({ data }) => {
  const initialFieldValues = {
    archiveDeviationDialog: false,
    ...data,
    deviationVersions: data.deviationVersions ? data.deviationVersions : [],
    documents: data.documents ? data.documents : [],
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const updatingDeviations = useSelector((state) => state.deviation.updating);
  const { roles } = useSelector((state) => state.authentication.user);
  const workFieldEvaluations = useSelector((state) => state.workFieldEvaluation.workFieldEvaluations);
  const isAdmin = roles.includes('Admin');
  const [deviationDetails, setDeviationDetails] = useState(initialFieldValues);
  const [people, setPeople] = useState([]);
  const workFieldEvaluation = workFieldEvaluations && deviationDetails.workFieldEvaluationId
                                && workFieldEvaluations.find((wfe) => wfe.id === deviationDetails.workFieldEvaluationId);

  useEffect(() => {
    const fetchData = async () => {
      const resPersonService = await personService.getAll();
      setPeople(resPersonService);
      dispatch(workFieldEvaluationActions.getAll());
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function handleDialogOpen(dialogName) {
    setDeviationDetails({
      ...deviationDetails,
      [dialogName]: true,
    });
  }

  function handleDialogClose(dialogName) {
    setDeviationDetails({
      ...deviationDetails,
      [dialogName]: false,
    });
  }

  function handleArchiveDeviations() {
    dispatch(
      deviationActions.archiveDeviation(
        data.id,
        { ...data, currentState: deviationConstants.status.archived.key },
        deviationTexts.successfulArchive,
      ),
    );
  }

  return (
    <div className={classes.main}>
      <Grid container spacing={3} align="center">
        <Grid item xs={12} sm={6}>
          <Container>
            <Typography className={classes.textTitle}>
              {deviationTexts.state}
            </Typography>
            <Typography className={classes.textValue}>
              {deviationConstants.statusForm[deviationDetails.currentState].label}
            </Typography>
          </Container>
        </Grid>

        {deviationDetails.workFieldEvaluationId && workFieldEvaluation
          && (
          <>
            <Grid item xs={12} sm={6}>
              <Container>
                <Typography className={classes.textTitle}>
                  {workFieldEvaluationTexts.relatedProvider}
                </Typography>
                <Typography className={classes.textValue}>
                  {workFieldEvaluation.provider.name}
                </Typography>
              </Container>
            </Grid>
            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={6}>
              <Container>
                <Typography className={classes.textTitle} style={{ marginBottom: 12 }}>
                  {workFieldEvaluationTexts.relatedEvaluation}
                </Typography>
                <Typography className={classes.textTitle}>
                  <Grid container>
                    <Grid item xs={4}>{workFieldEvaluationTexts.date}</Grid>
                    <Grid item xs={4}>{workFieldEvaluationTexts.workFieldEvaluated}</Grid>
                    <Grid item xs={4}>{workFieldEvaluationTexts.resultEvaluation}</Grid>
                  </Grid>
                </Typography>
                <Typography className={classes.textValue}>
                  <Grid container>
                    <Grid item xs={4}>{moment(workFieldEvaluation.date).format('DD/MM/YYYY')}</Grid>
                    <Grid item xs={4}>{workFieldEvaluation.workField.name}</Grid>
                    <Grid item xs={4}>{workFieldHelper.getEvaluationStatusLabel(workFieldEvaluation.evaluationStatus)}</Grid>
                  </Grid>
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="flex-start" pt={1}>
                  <Typography
                    className={classes.linkDocuments}
                    color="primary"
                    component={Link}
                    display="inline"
                    to={`/workFieldEvaluations/${workFieldEvaluation.id}`}
                  >
                    {workFieldEvaluationTexts.seeEvaluation}
                  </Typography>
                </Box>
              </Container>
            </Grid>
          </>
          )}
      </Grid>

      <Typography className={workFieldEvaluation ? classes.textSeparatorWithoutPT : classes.textSeparator}>
        {deviationTexts.generalInformation}
      </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3} align="center">

        <Grid item xs={12} sm={6}>
          <Container align="flex-start">
            <Typography className={classes.textTitle}>
              {deviationTexts.processes}
            </Typography>

            <Typography className={classes.textValue}>
              {deviationDetails.processes.map((item, index) => {
                if ((deviationDetails.processes.length - 1) !== index) return (`${item.name}, `);
                return (`${item.name}`);
              })}
            </Typography>

          </Container>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Container>
            <Typography className={classes.textTitle}>
              {deviationTexts.descriptionOfDeviation}
            </Typography>
            <Typography className={classes.textValue}>
              {deviationDetails.description}
            </Typography>
          </Container>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Container>
            <Typography className={classes.textTitle}>
              {deviationTexts.origin}
            </Typography>
            <Typography className={classes.textValue}>
              {deviationConstants.origin[deviationDetails.origin].label}
            </Typography>
          </Container>
        </Grid>

        {deviationDetails.immediateAction && (
          <Grid item xs={12} sm={6}>
            <Container>
              <Typography className={classes.textTitle}>
                {deviationTexts.immediateAction}
              </Typography>
              <Typography className={classes.textValue}>
                {deviationDetails.immediateAction}
              </Typography>
            </Container>
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <Container>
            <Typography className={classes.textTitle}>
              {deviationTexts.detectionDate}
            </Typography>
            <Typography className={classes.textValue}>
              {moment(deviationDetails.detectionDate).format('DD/MM/YYYY')}
            </Typography>
          </Container>
        </Grid>

      </Grid>

      {(deviationDetails.correctiveAction || deviationDetails.potentialCause) && (
        <>
          <Typography className={classes.textSeparator}>
            {deviationTexts.analysis}
          </Typography>
          <Divider className={classes.divider} />
          <Grid container spacing={3} align="center">

            {deviationDetails.potentialCause && (
            <Grid item xs={12} sm={6}>
              <Container>
                <Typography className={classes.textTitlePotentialCause}>
                  {deviationTexts.potentialCauseBold}
                </Typography>
                <Typography className={classes.textValue}>
                  {deviationDetails.potentialCause}
                </Typography>
              </Container>
            </Grid>
            )}

            {deviationDetails.correctiveAction && (
              <Grid item xs={12} sm={6}>
                <Container>
                  <Typography className={classes.textTitle}>
                    {deviationTexts.correctiveAction}
                  </Typography>
                  <Typography className={classes.textValue}>
                    {deviationDetails.correctiveAction}
                  </Typography>
                </Container>
              </Grid>
            )}

          </Grid>
        </>
      )}

      {(deviationDetails.plannedImplementationDate || deviationDetails.headOfImplementation) && (
        <>
          <Typography className={classes.textSeparator}>
            {deviationTexts.implementationCorrectiveAction}
          </Typography>
          <Divider className={classes.divider} />
          <Grid container spacing={3} align="center">

            {deviationDetails.plannedImplementationDate && (
            <Grid item xs={12} sm={6}>
              <Container>
                <Typography className={classes.textTitle}>
                  {deviationTexts.plannedImplementationDate}
                </Typography>
                <Typography className={classes.textValue}>
                  {moment(deviationDetails.plannedImplementationDate).format('DD/MM/YYYY')}
                </Typography>
              </Container>
            </Grid>
            )}

            {deviationDetails.headOfImplementation && (
            <Grid item xs={12} sm={6}>
              <Container>
                <Typography className={classes.textTitle}>
                  {deviationTexts.headOfImplementation}
                </Typography>
                <Typography className={classes.textValue}>
                  {people.map((item) => {
                    if (item.id === deviationDetails.headOfImplementation) return (` ${item.names} ${item.surnames} `);
                    return null;
                  })}
                </Typography>
              </Container>
            </Grid>
            )}

          </Grid>
        </>
      )}

      {(deviationDetails.effectiveImplementationDate
        || deviationDetails.expectedEffectivenessVerificationDate
        || deviationDetails.headOfEffectivenessVerification)
        && (
          <>
            <Typography className={classes.textSeparator}>
              {deviationTexts.effectivenessVerificationTitle}
            </Typography>
            <Divider className={classes.divider} />

            <Grid container spacing={3} align="center">

              {deviationDetails.effectiveImplementationDate && (
              <Grid item xs={12} sm={3}>
                <Box pl={3}>
                  <Typography className={classes.textTitle}>
                    {deviationTexts.effectiveImplementationDate}
                  </Typography>
                  <Typography className={classes.textValue}>
                    {moment(deviationDetails.effectiveImplementationDate).format('DD/MM/YYYY')}
                  </Typography>
                </Box>
              </Grid>
              )}

              {deviationDetails.expectedEffectivenessVerificationDate && (
              <Grid item xs={12} sm={3}>
                <Box pl={3}>
                  <Typography className={classes.textTitle}>
                    {deviationTexts.expectedEffectivenessVerificationDate}
                  </Typography>
                  <Typography className={classes.textValue}>
                    {moment(deviationDetails.expectedEffectivenessVerificationDate).format('DD/MM/YYYY')}
                  </Typography>
                </Box>
              </Grid>
              )}

              {deviationDetails.headOfEffectivenessVerification && (
              <Grid item xs={12} sm={6}>
                <Container>
                  <Typography className={classes.textTitle}>
                    {deviationTexts.headOfEffectivenessVerification}
                  </Typography>
                  <Typography className={classes.textValue}>
                    {people.map((item) => {
                      if (item.id === deviationDetails.headOfEffectivenessVerification) return (`${item.names} ${item.surnames}`);
                      return null;
                    })}
                  </Typography>
                </Container>
              </Grid>
              )}

              {deviationDetails.effectivenessVerificationMethodology && (
                <Grid item xs={12} sm={6}>
                  <Container>
                    <Typography className={classes.textTitle}>
                      {deviationTexts.effectivenessVerificationMethodology}
                    </Typography>
                    <Typography className={classes.textValue}>
                      {deviationDetails.effectivenessVerificationMethodology}
                    </Typography>
                  </Container>
                </Grid>
              )}

              {deviationDetails.effectivenessVerificationDate && (
                <>
                  <Grid item xs={12} sm={6}>
                    <Container>
                      <Typography className={classes.textTitle}>
                        {deviationTexts.effectivenessVerificationDate}
                      </Typography>
                      <Typography className={classes.textValue}>
                        {moment(deviationDetails.effectivenessVerificationDate).format('DD/MM/YYYY')}
                      </Typography>
                    </Container>
                  </Grid>

                  <Grid item xs={12} sm={6} />
                  <Grid item xs={12} sm={6}>
                    <Container>
                      <Typography className={classes.textTitle}>
                        {deviationTexts.effectivenessVerified}
                      </Typography>
                      <Typography className={classes.textValue}>
                        {
                          deviationDetails.effectivenessVerified
                            ? deviationTexts.positiveResult
                            : deviationTexts.negativeResult
                        }
                      </Typography>
                    </Container>
                  </Grid>
                </>
              )}

            </Grid>
          </>
        )}

      <DeviationDocuments documents={deviationDetails.documents} deviationId={deviationDetails.id} showDivider />

      {deviationDetails.deviationVersions.length
        ? <DeviationVersionHistory deviationVersions={deviationDetails.deviationVersions} deviationId={deviationDetails.id} showDivider />
        : ''}

      <Box display="flex" alignItems="center" justifyContent="flex-end" mt={3}>
        <Button
          component={Link}
          to="/deviations"
          variant="outlined"
          text={deviationTexts.backText}
          style={{ fontSize: '10px', fontWeight: 600 }}
        />
        {deviationDetails.currentState !== deviationConstants.status.archived.key
          && (
            <Button
              type="button"
              color="secondary"
              onClick={() => { handleDialogOpen('archiveDeviationDialog'); }}
              text={deviationTexts.archive}
              style={{ fontSize: '10px', fontWeight: 600 }}
              disabled={!isAdmin}
            />
          )}
        <Button
          text={deviationTexts.edit}
          style={{ fontSize: '10px', fontWeight: 600 }}
          component={Link}
          to={`/deviations/${data.id}/edit`}
          disabled={!isAdmin}
        />
        <Dialog open={deviationDetails.archiveDeviationDialog} onClose={() => { handleDialogClose('archiveDeviationDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{deviationTexts.archiveDeviation}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {deviationTexts.archiveConfirm}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button text={deviationTexts.cancel} onClick={() => { handleDialogClose('archiveDeviationDialog'); }} />
            <Button
              className={classes.menuButton}
              text={deviationTexts.archive}
              color="secondary"
              loading={updatingDeviations}
              onClick={handleArchiveDeviations}
            />
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};

DeviationViewDetail.propTypes = {
  data: PropTypes.shape(),
};

DeviationViewDetail.defaultProps = {
  data: {},
};

export default DeviationViewDetail;
