import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Breadcrumbs, Typography, Box, LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import themeConfig from '../../assets/themeConfig';
import PageLayout from '../PageLayout';
import CalendarMuiAdapter from './CalendarMuiAdapter/CalendarMuiAdapter';
import CalendarFilters from './CalendarFilters/CalendarFilters';
import Calendar from './Calendar/Calendar';
import { calendarAllActions } from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  calendarContainer: {
    padding: '0 24px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  calendar: {
    width: '100%',
  },
  calendarFilters: {
    marginRight: '24px',
  },
}));

const CalendarPage = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const title = intl.formatMessage({ id: 'calendar.title', defaultMessage: 'Calendario' });
  const userEvents = useSelector((state) => state.calendarAll.data);
  const [events, setEvents] = useState([]);

  function CalendarPageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/calendar" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{title}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  useEffect(() => {
    dispatch(calendarAllActions.getAllEvents());
  }, [dispatch]);

  return (
    <PageLayout
      title={title}
      breadCrumbs={<CalendarPageBreadcrumbs />}
    >
      {userEvents
        ? (
          <>
            <CalendarMuiAdapter theme={themeConfig} />
            <Box className={classes.calendarContainer}>
              <Box className={classes.calendarFilters}>
                <CalendarFilters
                  setEvents={setEvents}
                  eventsArray={userEvents}
                />
              </Box>
              <Box className={classes.calendar}>
                <Calendar events={events} />
              </Box>
            </Box>
          </>
        )
        : <LinearProgress />}
    </PageLayout>
  );
};

export default CalendarPage;
