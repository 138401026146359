import React from 'react';
import { SvgIcon } from "@material-ui/core";

function ProcessIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M14.4886 13L10.5113 9V5.82C11.6647 5.4 12.4999 4.3 12.4999 3C12.4999 1.34 11.1675 0 9.51698 0C7.86641 0 6.53402 1.34 6.53402 3C6.53402 4.3 7.36925 5.4 8.52266 5.82V9L4.54539 13H0.568115V18H5.53971V14.95L9.51698 10.75L13.4943 14.95V18H18.4658V13H14.4886Z" />
      </SvgIcon>
    );
  }

  export default ProcessIcon