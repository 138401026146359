import React, { useEffect, useState } from 'react';
import {
  Box, Grid, Typography, makeStyles, Divider, Chip,
  Dialog, DialogTitle, DialogContent, DialogActions,
} from '@material-ui/core';
import { CheckCircle, Error, FileCopyOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Button from '../../controls/Button';
import { affiliateText } from '../../../constants';
import { affiliateHelpers } from '../../../helpers';
import affiliateAction from '../../../redux/actions/affiliate.actions';
import { alertActions } from '../../../redux/actions';
import colors from '../../../assets/colors';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  textTitle: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '12px',
    textAlign: 'left',
  },
  textValue: {
    paddingTop: '8px',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'left',
  },
  textSeparator: {
    marginLeft: '24px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  textSeparatorMeasure: {
    marginTop: '32px',
    marginLeft: '24px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  divider: {
    marginBottom: '16px',
    marginLeft: '24px',
  },
  referalSystemEnabledChip: {
    color: '#fff',
    labelColor: '#fff',
    marginTop: '8px',
  },
  urlButtonWrapper: {
    borderRadius: '38px',
    display: 'flex',
    flexDirection: 'row',
    minHeight: '32px',
    width: '100%',
    marginTop: '8px',
  },
  urlButtonCopyContainer: {
    alignItems: 'center',
    background: colors.orangeLight,
    marginLeft: '24px',
    borderRadius: '33px',
    color: '#FFF',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    justifyContent: 'center',
    width: '120px',
  },
  urlButtonTextContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  titleDialog: {
    fontSize: '14px',
    fontWeight: 500,
  },
  subtitleDialog: {
    color: colors.darkGray,
    fontSize: '12px',
    fontWeight: 300,
    paddingLeft: '4px',
    paddingTop: '8px',
  },
}));

const AffiliateViewDetail = ({ data: affiliateDetails }) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const requesting = useSelector((state) => state.registration.requesting);
  const requestingPasswordChange = useSelector((state) => state.registration.loading);
  const userChanges = useSelector((state) => state.registration.data);
  const [selectedUser, setSelectedUser] = useState({ userId: null, email: null });
  const [userChangePassDialog, setUserChangePassDialog] = useState(false);

  const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const doneButtonText = intl.formatMessage({ id: 'app.done', defaultMessage: 'Finalizar' });
  const nextButtonText = intl.formatMessage({ id: 'app.next', defaultMessage: 'Siguiente' });
  const activateAccount = intl.formatMessage({ id: 'superAdmin.activateAccount', defaultMessage: 'La cuenta del usuario pasará a estar confirmada en caso de que este aún no la haya confirmado.' });
  const newPasswordSendText = intl.formatMessage({ id: 'superAdmin.newPasswordSend', defaultMessage: 'Se envió al usuario un correo con su nueva contraseña provisoria.' });
  const temporaryPasswordText = intl.formatMessage({ id: 'superAdmin.temporaryPassword', defaultMessage: 'Contraseña provisoria: ' });
  const userChangePassBody = intl.formatMessage({ id: 'superAdmin.userChangePassBody', defaultMessage: '¿Está seguro que quiere restablecer la contraseña del usuario?' });
  const userChangePasswordTitle = intl.formatMessage({ id: 'superAdmin.userChangePassword', defaultMessage: 'Restablecer contraseña del usuario' });

  function copyURLtoClipboard() {
    navigator.clipboard.writeText(
      affiliateHelpers.getAffiliateURL(affiliateDetails.couponId),
    );
    dispatch(alertActions.success(affiliateText.copiedToClipboard));
  }

  function toggleUserChangePassDialog() {
    setUserChangePassDialog((prev) => !prev);
  }

  function resetUserPassword() {
    if (userChanges) {
      dispatch(affiliateAction.clearAffiliate());
      return toggleUserChangePassDialog();
    }
    return dispatch(affiliateAction.resetUserPassword(
      affiliateDetails.userId, affiliateText.successfulResetPassword,
    ));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={classes.main}>
      <Typography className={classes.textSeparator}>
        {affiliateText.generalInformation}
      </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3} align="center">
        <Grid item xs={12} sm={6}>
          <Box px={3}>
            <Typography className={classes.textTitle}>
              {affiliateText.nameLabel}
            </Typography>
            <Typography className={classes.textValue}>
              {affiliateDetails.names}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box px={3}>
            <Typography className={classes.textTitle}>
              {affiliateText.emailLabel}
            </Typography>
            <Typography className={classes.textValue}>
              {affiliateDetails.email}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Typography className={classes.textSeparatorMeasure}>
        {affiliateText.referralInformationTitle}
      </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3} align="center">

        <Grid item xs={12} sm={6}>
          <Box px={3} pt={2}>
            <Typography className={classes.textTitle}>
              {affiliateText.referalSystemEnabled}
            </Typography>
            <Box display="flex" justifyContent="start">
              <Chip
                label={affiliateDetails.referalSystemEnabled
                  ? affiliateText.activated
                  : affiliateText.disabled}
                className={classes.referalSystemEnabledChip}
                style={affiliateDetails.referalSystemEnabled
                  ? { backgroundColor: '#43A047' }
                  : { backgroundColor: '#FF9800' }}
                icon={affiliateDetails.referalSystemEnabled
                  ? <CheckCircle style={{ color: '#fff' }} />
                  : <Error style={{ color: '#fff' }} />}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box pl={3} pt={2}>
            <Typography className={classes.textTitle}>
              {affiliateText.affiliateUrlForReferral}
            </Typography>
            {affiliateDetails.couponId
              ? (
                <Box className={classes.urlButtonWrapper} ml={0}>
                  <Box className={classes.urlButtonTextContainer}>
                    <Typography className={classes.textValue} style={{ paddingTop: '0px' }}>
                      {affiliateHelpers.getAffiliateURL(affiliateDetails.couponId)}
                    </Typography>
                  </Box>
                  <Box
                    className={classes.urlButtonCopyContainer}
                    onClick={copyURLtoClipboard}
                  >
                    <FileCopyOutlined style={{ fontSize: '12px', marginRight: '4px' }} />
                    <Typography ml={1.5} className={classes.textValue} style={{ paddingTop: '0px' }}>
                      {affiliateText.copy}
                    </Typography>
                  </Box>
                </Box>
              )
              : (<Typography className={classes.textValue}>-</Typography>)}
          </Box>
        </Grid>

      </Grid>

      <Typography className={classes.textSeparatorMeasure}>
        {affiliateText.discountsAndCommissionTitle}
      </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3} align="center">

        <Grid item xs={12} sm={6}>
          <Box px={3} pt={2}>
            <Typography className={classes.textTitle}>
              {affiliateText.discountCoupon}
            </Typography>
            <Typography className={classes.textValue}>
              {affiliateDetails.discountCoupon}
              %
              {' - '}
              {affiliateText.onlyFirst3Months}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box pl={3} pt={2}>
            <Typography className={classes.textTitle}>
              {affiliateText.paymentCommission}
            </Typography>
            <Typography className={classes.textValue}>
              {affiliateDetails.commission}
              %
            </Typography>
          </Box>
        </Grid>

      </Grid>

      <Box pl={3} pt={2} mt={3.5}>
        <Typography className={classes.textTitle} style={{ fontSize: '14px', marginBottom: '10px' }}>
          {affiliateText.resetPassword}
        </Typography>
        <Typography className={classes.textValue} style={{ marginBottom: '24px' }}>
          {affiliateText.resetPasswordSubtext}
        </Typography>
        <Button
          type="button"
          color="secondary"
          text={affiliateText.resetPassword}
          style={{ fontSize: '10px', fontWeight: 600, marginLeft: '-8px' }}
          onClick={() => {
            setSelectedUser({ userId: affiliateDetails.userId, email: affiliateDetails.email });
            toggleUserChangePassDialog();
          }}
          loading={requesting}
        />
      </Box>

      <Box display="flex" alignItems="center" justifyContent="flex-end" mt={3}>
        <Button
          component={Link}
          to="/affiliates"
          variant="outlined"
          text={affiliateText.backText}
          style={{ fontSize: '10px', fontWeight: 600 }}
        />
      </Box>
      <Dialog open={userChangePassDialog} disableBackdropClick onClose={() => toggleUserChangePassDialog()} fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{userChangePasswordTitle}</DialogTitle>
        <DialogContent>
          {userChanges
            ? (
              <>
                <Typography align="left" className={classes.titleDialog}>
                  {newPasswordSendText}
                </Typography>
                <Typography align="left" className={classes.titleDialog} style={{ marginTop: 12 }}>
                  Email:
                  {' '}
                  <b>{userChanges.email}</b>
                </Typography>
                <Typography align="left" className={classes.titleDialog}>
                  {temporaryPasswordText}
                  {' '}
                  <b>{userChanges.password}</b>
                </Typography>
              </>
            )
            : (
              <>
                <Typography align="left" className={classes.titleDialog}>
                  {userChangePassBody}
                </Typography>
                <Typography align="left" className={classes.titleDialog} style={{ paddingLeft: 4, paddingTop: 8 }}>
                  Email:
                  {' '}
                  <b>{selectedUser.email}</b>
                </Typography>
                <Typography align="left" className={classes.subtitleDialog}>
                  {activateAccount}
                </Typography>
              </>
            )}
        </DialogContent>
        <DialogActions>
          {!userChanges && (
            <Button
              text={cancelButtonText}
              onClick={() => { toggleUserChangePassDialog(); }}
              variant="outlined"
              disabled={requestingPasswordChange}
            />
          )}
          <Button
            text={userChanges ? doneButtonText : nextButtonText}
            loading={requestingPasswordChange}
            onClick={resetUserPassword}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

AffiliateViewDetail.propTypes = {
  data: PropTypes.shape(),
};

AffiliateViewDetail.defaultProps = {
  data: { },
};

export default AffiliateViewDetail;
