import React from 'react';
import { FormattedMessage } from 'react-intl';

const request = {
  CREATE_REQUEST: 'DEVIATION_CREATE_REQUEST',
  CREATE_SUCCESS: 'DEVIATION_CREATE_SUCCESS',
  CREATE_FAILURE: 'DEVIATION_CREATE_FAILURE',

  GET_ALL_REQUEST: 'DEVIATION_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'DEVIATION_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'DEVIATION_GET_ALL_FAILURE',

  GET_REQUEST: 'DEVIATION_GET_REQUEST',
  GET_SUCCESS: 'DEVIATION_GET_SUCCESS',
  GET_FAILURE: 'DEVIATION_GET_FAILURE',

  UPDATE_REQUEST: 'DEVIATION_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'DEVIATION_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'DEVIATION_UPDATE_FAILURE',

  UPDATE_FORM_REQUEST: 'DEVIATION_UPDATE_FORM_REQUEST',
  UPDATE_FORM_SUCCESS: 'DEVIATION_UPDATE_FORM_SUCCESS',
  UPDATE_FORM_FAILURE: 'DEVIATION_UPDATE_FORM_FAILURE',

  ARCHIVE_REQUEST: 'DEVIATION_ARCHIVE_REQUEST',
  ARCHIVE_SUCCESS: 'DEVIATION_ARCHIVE_SUCCESS',
  ARCHIVE_FAILURE: 'DEVIATION_ARCHIVE_FAILURE',

  CREATE_BULK_REQUEST: 'DEVIATION_CREATE_BULK_REQUEST',
  CREATE_BULK_SUCCESS: 'DEVIATION_CREATE_BULK_SUCCESS',
  CREATE_BULK_FAILURE: 'DEVIATION_CREATE_BULK_FAILURE',

  CONFIRM_CREATE_BULK_REQUEST: 'DEVIATION_CONFIRM_CREATE_BULK_REQUEST',
  CONFIRM_CREATE_BULK_SUCCESS: 'DEVIATION_CONFIRM_CREATE_BULK_SUCCESS',
  CONFIRM_CREATE_BULK_FAILURE: 'DEVIATION_CONFIRM_CREATE_BULK_FAILURE',

  DELETE_REQUEST: 'DEVIATION_DELETE_REQUEST',
  DELETE_SUCCESS: 'DEVIATION_DELETE_SUCCESS',
  DELETE_FAILURE: 'DEVIATION_DELETE_FAILURE',
};

const origin = {
  internalAudit: {
    key: 'internalAudit',
    color: '#F2AC20',
    label: <FormattedMessage id="deviation.card.internalAudit" defaultMessage="Auditoría Interna" />,
  },
  externalAudit: {
    key: 'externalAudit',
    color: '#F2AC20',
    label: <FormattedMessage id="deviation.card.externalAudit" defaultMessage="Auditoría Externa" />,
  },
  claim: {
    key: 'claim',
    color: '#BF1919',
    label: <FormattedMessage id="deviation.card.claim" defaultMessage="Reclamo" />,
  },
  internal: {
    key: 'internal',
    color: '#1FCFF2',
    label: <FormattedMessage id="deviation.card.internal" defaultMessage="Interno" />,
  },
};

const status = {
  documented: {
    key: 'documented',
    label: <FormattedMessage id="deviation.card.documented" defaultMessage="Documentados" />,
  },
  analyzed: {
    key: 'analyzed',
    label: <FormattedMessage id="deviation.card.analyzed" defaultMessage="Analizados" />,
  },
  correctiveActionDefined: {
    key: 'correctiveActionDefined',
    label: <FormattedMessage id="deviation.card.correctiveActionDefined" defaultMessage="Acción correctiva definida" />,
  },
  correctiveActionImplemented: {
    key: 'correctiveActionImplemented',
    label: <FormattedMessage id="deviation.card.correctiveActionImplemented" defaultMessage="Acción correctiva implementada" />,
  },
  loading: {
    key: 'loading',
    label: <FormattedMessage id="deviation.card.loading" defaultMessage="Cargando..." />,
  },
  archived: {
    key: 'archived',
    label: <FormattedMessage id="deviation.card.archived" defaultMessage="Archivados" />,
  },
};

const documentedRules = {
  title: 'required',
  detectionDate: 'required',
  origin: 'required',
  processes: 'required',
  description: 'required',
  currentState: 'required',
};

const analyzedRules = {
  ...documentedRules,
  potentialCause: 'required',
};

const correctiveActionDefinedRules = {
  ...analyzedRules,
  immediateAction: 'required',
  correctiveAction: 'required',
  plannedImplementationDate: 'required',
  headOfImplementation: 'required',
};

const correctiveActionImplementedRules = {
  ...correctiveActionDefinedRules,
  effectiveImplementationDate: 'required',
  expectedEffectivenessVerificationDate: 'required',
  headOfEffectivenessVerification: 'required',
};

const statusForm = {
  documented: {
    key: 'documented',
    label: <FormattedMessage id="deviation.form.documented" defaultMessage="Documentado" />,
    rules: documentedRules,
  },
  analyzed: {
    key: 'analyzed',
    label: <FormattedMessage id="deviation.form.analyzed" defaultMessage="Analizado" />,
    rules: analyzedRules,
  },
  correctiveActionDefined: {
    key: 'correctiveActionDefined',
    label: <FormattedMessage id="deviation.form.correctiveActionDefined" defaultMessage="Acción correctiva definida" />,
    rules: correctiveActionDefinedRules,
  },
  correctiveActionImplemented: {
    key: 'correctiveActionImplemented',
    label: <FormattedMessage id="deviation.form.correctiveActionImplemented" defaultMessage="Acción correctiva implementada" />,
    rules: correctiveActionImplementedRules,
  },
  archived: {
    key: 'archived',
    label: <FormattedMessage id="deviation.form.archived" defaultMessage="Archivado" />,
    rules: documentedRules,
  },
};

const deviationConstants = {
  request, origin, status, statusForm,
};

export default deviationConstants;
