import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Breadcrumbs, Typography, Grid,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PageLayout from '../../PageLayout';
import DocumentForm from '../DocumentForm';
import {
  deviationActions, personActions, processActions, trainingActions, providerActions,
  workFieldEvaluationActions,
} from '../../../redux/actions';

function NewDocumentPage({ location }) {
  const intl = useIntl();
  const newText = intl.formatMessage({ id: 'app.new', defaultMessage: 'Nuevo' });
  const newDocumentText = intl.formatMessage({ id: 'document.new', defaultMessage: 'Nuevo documento' });
  const documentText = intl.formatMessage({ id: 'documents.title_pl', defaultMessage: 'Documentos' });
  const parsedQueryString = queryString.parse(location.search);
  const [queryParams] = useState({
    entity: parsedQueryString.entity,
    entityId: parsedQueryString.entityId,
  });

  const { entity, entityId } = queryParams;

  const dispatch = useDispatch();
  const person = useSelector((state) => state.person.data);
  const deviation = useSelector((state) => state.deviation.data);
  const training = useSelector((state) => state.training.data);
  const provider = useSelector((state) => state.provider.data);
  const process = useSelector((state) => state.process.data);
  const workFieldEvaluation = useSelector((state) => state.workFieldEvaluation.data);

  const entitiesUrls = {
    person: 'people',
    deviation: 'deviations',
    process: 'processes',
    training: 'trainings',
    provider: 'providers',
    workFieldEvaluation: 'workFieldEvaluations',
  };

  const entitiesLabels = {
    person: 'Personas',
    deviation: 'Desviaciones',
    process: 'Procesos',
    training: 'Capacitaciones',
    provider: 'Proveedores',
    workFieldEvaluation: 'Evaluaciones de rubro',
  };

  useEffect(() => {
    if (entity === 'person') {
      dispatch(personActions.get(entityId));
    }
    if (entity === 'process') {
      dispatch(processActions.get(entityId));
    }
    if (entity === 'deviation') {
      dispatch(deviationActions.getDeviations(entityId));
    }
    if (entity === 'training') {
      dispatch(trainingActions.get(entityId));
    }
    if (entity === 'provider') {
      dispatch(providerActions.get(entityId));
    }
    if (entity === 'workFieldEvaluation') {
      dispatch(workFieldEvaluationActions.get(entityId));
    }
  }, [dispatch, entityId, entity]);

  function getEntityLabel() {
    let label = '';
    if (entity === 'person' && person) label = `${person.names} ${person.surnames}`;
    if (entity === 'process' && process) label = process.name;
    if (entity === 'deviation' && deviation) label = deviation.title;
    if (entity === 'training' && training) label = training.title;
    if (entity === 'provider' && provider) label = provider.name;
    if (entity === 'workFieldEvaluation' && workFieldEvaluation) label = workFieldEvaluation.title;
    return label;
  }

  function NewDocumentBreadcrumbs() {
    const entityLabel = getEntityLabel();
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to={`/${entitiesUrls[entity]}`} style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{entitiesLabels[entity]}</Typography>
        </Link>
        <Link color="inherit" to={`/${entitiesUrls[entity]}/${entityId}`} style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{entityLabel}</Typography>
        </Link>
        <Link color="inherit" to="/documents" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{documentText}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{newText}</strong></Typography>
      </Breadcrumbs>
    );
  }

  function NewDocumentLoadingBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to={`/${entitiesUrls[entity]}`} style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{entitiesLabels[entity]}</Typography>
        </Link>
        <Link color="inherit" to="/" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{documentText}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{newText}</strong></Typography>
      </Breadcrumbs>
    );
  }

  return (
    <>
      {person || process || deviation || training || provider || workFieldEvaluation
        ? (
          <PageLayout title={newDocumentText} breadCrumbs={<NewDocumentBreadcrumbs />}>
            <Grid container spacing={0}>
              <DocumentForm entity={entity} entityId={entityId} />
            </Grid>
          </PageLayout>
        )
        : (
          <PageLayout title={newDocumentText} breadCrumbs={<NewDocumentLoadingBreadcrumbs />}>
            <Grid container spacing={0}>
              <DocumentForm entity={entity} entityId={entityId} />
            </Grid>
          </PageLayout>
        )}
    </>
  );
}

NewDocumentPage.propTypes = {
  location: PropTypes.shape(),
};

NewDocumentPage.defaultProps = {
  location: {},
};

export default NewDocumentPage;
