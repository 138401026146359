/* eslint-disable max-len */
import React from 'react';
import { FormattedMessage } from 'react-intl';

const maxDaysOfDelayPayment = 7;
const MB_PER_GB = 1024;
const KB_PER_MB = 1024;
const B_PER_KB = 1024;

const subscriptionStatusInvalid = (status) => ['past_due', 'unpaid'].includes(status);

const convertGbToBytes = (gb) => gb * MB_PER_GB * KB_PER_MB * B_PER_KB;

const plans = {
  PertixFree: {
    maxUsers: 20,
    maxStorageInBytes: convertGbToBytes(2),
    key: 'Free',
    name: <FormattedMessage id="stripe.Free" defaultMessage="Free" />,
    frequency: <FormattedMessage id="stripe.annual" defaultMessage="año" />,
    label: <FormattedMessage id="stripe.Free" defaultMessage="Free" />,
  },
  PertixFreeMonth: {
    maxUsers: 20,
    maxStorageInBytes: convertGbToBytes(2),
    key: 'Free',
    name: <FormattedMessage id="stripe.Free" defaultMessage="Free" />,
    frequency: <FormattedMessage id="stripe.monthly" defaultMessage="mes" />,
    label: <FormattedMessage id="stripe.Free" defaultMessage="Free" />,
  },
  PertixStarterYear: {
    isAnnual: true,
    maxUsers: 50,
    maxStorageInBytes: convertGbToBytes(5),
    key: 'StarterYear',
    name: <FormattedMessage id="stripe.Starter" defaultMessage="Starter" />,
    frequency: <FormattedMessage id="stripe.annual" defaultMessage="año" />,
    label: <FormattedMessage id="stripe.StarterAnnual" defaultMessage="Starter anual" />,
    subLabel: <FormattedMessage id="stripe.affiliates" defaultMessage="AFFILIATES" />,
    toSetStrikeThroughPrice: true,
  },
  PertixStarterMonth: {
    maxUsers: 50,
    maxStorageInBytes: convertGbToBytes(5),
    key: 'StarterMonth',
    name: <FormattedMessage id="stripe.Starter" defaultMessage="Starter" />,
    frequency: <FormattedMessage id="stripe.monthly" defaultMessage="mes" />,
    label: <FormattedMessage id="stripe.StarterMonthly" defaultMessage="Starter mensual" />,
    subLabel: <FormattedMessage id="stripe.affiliates" defaultMessage="AFFILIATES" />,
    toSetStrikeThroughPrice: true,
  },
  PertixStarterFree: {
    maxUsers: 5, //For starter free, the limit of users is used for persons.
    maxStorageInBytes: convertGbToBytes(5),
    key: 'StarterFree',
    name: <FormattedMessage id="stripe.StarterFree" defaultMessage="Starter free" />,
    frequency: <FormattedMessage id="stripe.annual" defaultMessage="año" />,
    label: <FormattedMessage id="stripe.StarterFree" defaultMessage="Starter free" />,
  },
  PertixStarterFreeMonth: {
    maxUsers: 5, //For starter free, the limit of users is used for persons.
    maxStorageInBytes: convertGbToBytes(5),
    key: 'StarterFree',
    name: <FormattedMessage id="stripe.StarterFree" defaultMessage="Starter free" />,
    frequency: <FormattedMessage id="stripe.monthly" defaultMessage="mes" />,
    label: <FormattedMessage id="stripe.StarterFree" defaultMessage="Starter free" />,
  },
  PertixAdvancedYear: {
    isAnnual: true,
    maxUsers: 100,
    maxStorageInBytes: convertGbToBytes(10),
    key: 'AdvancedYear',
    name: <FormattedMessage id="stripe.Advanced" defaultMessage="Advanced" />,
    frequency: <FormattedMessage id="stripe.annual" defaultMessage="año" />,
    label: <FormattedMessage id="stripe.AdvancedAnnual" defaultMessage="Advanced anual" />,
    subLabel: <FormattedMessage id="stripe.affiliates" defaultMessage="AFFILIATES" />,
    toSetStrikeThroughPrice: true,
  },
  PertixAdvancedMonth: {
    maxUsers: 100,
    maxStorageInBytes: convertGbToBytes(10),
    key: 'AdvancedMonth',
    name: <FormattedMessage id="stripe.Advanced" defaultMessage="Advanced" />,
    frequency: <FormattedMessage id="stripe.monthly" defaultMessage="mes" />,
    label: <FormattedMessage id="stripe.AdvancedMonthly" defaultMessage="Advanced mensual" />,
    subLabel: <FormattedMessage id="stripe.affiliates" defaultMessage="AFFILIATES" />,
    toSetStrikeThroughPrice: true,
  },
};

const licensesText = <FormattedMessage id="stripe.licenses" defaultMessage="Licencias" />;
const noLicensesText = <FormattedMessage id="stripe.noLicenses" defaultMessage="Sin licencias" />;
const maximumPayrollText = <FormattedMessage id="stripe.maximumPayroll" defaultMessage="Nómina máxima " />;
const employees5Text = <FormattedMessage id="stripe.5Employees" defaultMessage="5 empleados" />;
const employees20Text = <FormattedMessage id="stripe.20Employees" defaultMessage="20 empleados" />;
const employees50Text = <FormattedMessage id="stripe.50Employees" defaultMessage="50 empleados" />;
const employees100Text = <FormattedMessage id="stripe.100Employees" defaultMessage="100 empleados" />;
const usersQuantityText = <FormattedMessage id="stripe.usersQuantity" defaultMessage="Cantidad de Usuarios " />;
const unlimitedText = <FormattedMessage id="stripe.unlimited" defaultMessage="Ilimitado" />;
const storageText = <FormattedMessage id="stripe.storage" defaultMessage="Almacenamiento " />;
const regularUpdatesText = <FormattedMessage id="stripe.regularUpdates" defaultMessage="Actualizaciones periódicas" />;
const freeText = <FormattedMessage id="stripe.free" defaultMessage="Gratis" />;
const integratedVideoTutorialsText = <FormattedMessage id="stripe.integratedVideoTutorials" defaultMessage="Video Tutoriales integrados" />;
const processMapPlotterText = <FormattedMessage id="stripe.processMapPlotter" defaultMessage="Graficador de mapa de procesos" />;
const processManagerText = <FormattedMessage id="stripe.processManager" defaultMessage="Administrador de procesos" />;
const organizationChartText = <FormattedMessage id="stripe.organizationChart" defaultMessage="Organigrama" />;
const situationalAnalysisText = <FormattedMessage id="stripe.situationalAnalysis" defaultMessage="Análisis situacional" />;
const staffPayrollText = <FormattedMessage id="stripe.staffPayroll" defaultMessage="Nómina de personal" />;
const trainingManagerText = <FormattedMessage id="stripe.trainingManager" defaultMessage="Administrador de capacitaciones" />;
const staffAssessmentText = <FormattedMessage id="stripe.staffAssessment" defaultMessage="Evaluación de personal" />;
const comprehensiveRiskManagerText = <FormattedMessage id="stripe.comprehensiveRiskManager" defaultMessage="Gestor de riesgos completo" />;
const deviationAndNonText = <FormattedMessage id="stripe.deviationAndNon-ConformityManager" defaultMessage="Gestor de desvíos y no conformes" />;
const indicatorsDashboardText = <FormattedMessage id="stripe.indicatorsDashboard" defaultMessage="Tablero de control de indicadores" />;
const workFieldEvaluationText = <FormattedMessage id="stripe.workFieldEvaluation" defaultMessage="Evaluación de proveedores" />;
const infrastructureManagerText = <FormattedMessage id="stripe.infrastructureManager" defaultMessage="Gestor de Infraestructura" />;
const internalAuditReportText = <FormattedMessage id="stripe.internalAuditReport" defaultMessage="Reporte de auditoria interna" />;
const systemReviewReportText = <FormattedMessage id="stripe.systemReviewReport" defaultMessage="Reporte de revisión del sistema" />;
const formBuilderText = <FormattedMessage id="stripe.formBuilder" defaultMessage="Generador de formularios" />;

const listAvailableTools = ({starterFreeInsteadOfFree = false}) => [
  { name: licensesText, values: [noLicensesText, noLicensesText, noLicensesText] },
  { name: maximumPayrollText, values: [starterFreeInsteadOfFree ? employees5Text : employees20Text, employees50Text, employees100Text] },
  { name: usersQuantityText, values: [unlimitedText, unlimitedText, unlimitedText] },
  { name: storageText, values: [starterFreeInsteadOfFree ? '5GB' : '2GB', '5GB', '10GB'] },
  { name: regularUpdatesText, values: [freeText, freeText, freeText] },
];

const expandedListAvailableTools = ({starterFreeInsteadOfFree = false}) => {
  let expandedList = [
    { name: integratedVideoTutorialsText, values: [true, true, true] },
    { name: processMapPlotterText, values: [true, true, true] },
    { name: processManagerText, values: [true, true, true] },
    { name: organizationChartText, values: [true, true, true] },
    { name: situationalAnalysisText, values: [true, true, true] },
    { name: staffPayrollText, values: [true, true, true] },
    { name: trainingManagerText, values: [false, true, true] },
    { name: staffAssessmentText, values: [false, true, true] },
    { name: comprehensiveRiskManagerText, values: [false, true, true] },
    { name: deviationAndNonText, values: [false, true, true] },
    { name: indicatorsDashboardText, values: [false, true, true] },
    { name: workFieldEvaluationText, values: [false, false, true] },
    { name: infrastructureManagerText, values: [false, false, true] },
    { name: internalAuditReportText, values: [false, false, true] },
    { name: systemReviewReportText, values: [false, false, true] },
    { name: formBuilderText, values: [false, false, true] },
  ];
  if (starterFreeInsteadOfFree)
    expandedList = expandedList.map((tool) => {
      tool.values[0] = tool.values[1];
      return tool;
    });
  return [...listAvailableTools({starterFreeInsteadOfFree}), ...expandedList];
};

const searchPlan = (planId) => Object.values(plans).find((plan) => plan.key.toString() === planId.toString());

const plansThatAcceptCoupon = [
  plans.PertixStarterYear.key,
  plans.PertixStarterMonth.key,
  plans.PertixAdvancedYear.key,
  plans.PertixAdvancedMonth.key,
];

const getCurrentPlan = (plan) => plans[`Pertix${plan}`].name;

const getNeededPlan = (toolPlan) => {
  if (toolPlan === 3) return <FormattedMessage id="stripe.Advanced" defaultMessage="Advanced" />;
  if (toolPlan === 2) return <FormattedMessage id="stripe.Starter" defaultMessage="Starter" />;
  return <FormattedMessage id="stripe.Free" defaultMessage="Free" />;
};

const stripePlanSpecs = {
  getCurrentPlan,
  getNeededPlan,
  plans,
  listAvailableTools,
  expandedListAvailableTools,
  maxDaysOfDelayPayment,
  searchPlan,
  subscriptionStatusInvalid,
  plansThatAcceptCoupon,
};

export default stripePlanSpecs;
