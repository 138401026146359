import React from 'react';
import { useIntl } from 'react-intl';
import { Link, Redirect } from 'react-router-dom';
import {
  Breadcrumbs, Typography, makeStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PageLayout from '../../PageLayout';
import DataTable from '../../DataTable/DataTable';
import { stripePlanSpecs } from '../../../constants';
import Button from '../../controls/Button';

const useStyles = makeStyles(() => ({
  tableBody: {
    color: 'black',
    textDecoration: 'none',
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
}));

const CompaniesPage = () => {
  const classes = useStyles();
  const intl = useIntl();
  const user = useSelector((state) => state.authentication.user);
  const titleDataTable = intl.formatMessage({ id: 'superAdmin.companies', defaultMessage: 'Empresas' });
  const title = intl.formatMessage({ id: 'superAdmin.systemAdmin', defaultMessage: 'Administración del sistema' });
  const addButtonText = intl.formatMessage({ id: 'superAdmin.addCompany.title', defaultMessage: '+ Agregar Empresa' });

  if (!user.isSuperAdmin) {
    return <Redirect to={{ pathname: '/company/configuration' }} />;
  }

  const columns = [{
    name: 'id',
    options: {
      display: 'excluded',
      filter: false,
    },
  }, {
    name: 'name',
    label: intl.formatMessage({ id: 'company.name', defaultMessage: 'Nombre' }),
    options: {
      customBodyRender: (value, meta) => {
        const companyId = meta.rowData[0];
        const textTitle = (
          <Typography component={Link} to={`/companies/${companyId}`} className={classes.tableBody}>
            {value}
          </Typography>
        );
        return textTitle;
      },
    },
  }, {
    name: 'plan',
    label: intl.formatMessage({ id: 'user.plan', defaultMessage: 'Plan' }),
    options: {
      customBodyRender: (value) => {
        const plan = stripePlanSpecs.searchPlan(value);
        return plan ? plan.label : 'Error';
      },
    },
  }, {
    name: 'registeredWithTelecomPromo',
    label: intl.formatMessage({ id: 'company.referredByTelecom', defaultMessage: 'Referido por Telecom' }),
    options: {
      customBodyRender: (value) => (
        <Typography className={classes.tableBody}>
          {value ? "Sí" : "No"}
        </Typography>
      )
    },
  }, {
    name: 'usersQuantity',
    label: intl.formatMessage({ id: 'company.users', defaultMessage: 'Usuarios' }),
  }, {
    name: 'affiliateData',
    label: intl.formatMessage({ id: 'affiliates.title', defaultMessage: 'Affiliates' }),
    options: {
      customBodyRender: (value) => (value.names ? value.names : '-'),
    },
  }, {
    name: 'creationDate',
    label: intl.formatMessage({ id: 'superAdmin.companyCreationDate', defaultMessage: 'Fecha de inscripción' }),
    options: {
      customBodyRender: (date) => {
        if (date === '-') return date;
        return moment(date).format('DD/MM/YYYY');
      },
    },
  }, {
    name: 'lastUsageDatetime',
    label: intl.formatMessage({ id: 'superAdmin.lastUsageDateTimeCompany', defaultMessage: 'Última fecha de acceso' }),
    options: {
      customBodyRender: (date) => {
        if (date === '-') return date;
        return moment(date).format('DD/MM/YYYY HH:mm [hs.]');
      },
    },
  }, {
    name: 'stripeCustomerId',
    label: 'CustomerId',
  }
  ];

  const keys = ['id', 'name', 'plan', 'registeredWithTelecomPromo', 'usersQuantity', 'affiliateData', 'creationDate', 'lastUsageDatetime', 'stripeCustomerId'];
  const fetchUrl = 'companies/';
  const itemUrl = 'companies/';

  const CompaniesPageBreadcrumbs = () => (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      <Link color="inherit" to="/superadmin" style={{ textDecoration: 'none' }}>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{title}</Typography>
      </Link>
      <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{titleDataTable}</Typography>
    </Breadcrumbs>
  );

  const ButtonGroup = () => (
    <Button
      text={addButtonText}
      component={Link}
      to="companies/new"
      style={{ fontSize: '11px', fontWeight: 600 }}
    />
  );

  return (
    <PageLayout
      title={titleDataTable}
      breadCrumbs={<CompaniesPageBreadcrumbs />}
      buttonGroup={<ButtonGroup />}
    >
      <DataTable
        title={titleDataTable}
        fetchUrl={fetchUrl}
        itemUrl={itemUrl}
        keys={keys}
        columns={columns}
      />
    </PageLayout>
  );
};

export default CompaniesPage;
