import React from 'react';
import { FormattedMessage } from 'react-intl';

const workFieldEvaluationTexts = {
  back: <FormattedMessage id="app.back" defaultMessage="Volver" />,
  save: <FormattedMessage id="app.save" defaultMessage="Guardar" />,
  cancel: <FormattedMessage id="app.cancel" defaultMessage="Cancelar" />,
  edit: <FormattedMessage id="app.edit" defaultMessage="Editar" />,
  create: <FormattedMessage id="app.create" defaultMessage="Crear" />,
  title: <FormattedMessage id="app.title" defaultMessage="Título" />,
  delete: <FormattedMessage id="app.delete" defaultMessage="Eliminar" />,
  new: <FormattedMessage id="app.new" defaultMessage="Nuevo" />,
  modify: <FormattedMessage id="app.modify" defaultMessage="Modificar" />,
  successfulUpdate: <FormattedMessage id="app.successfulUpdate" defaultMessage="Se ha actualizado correctamente" />,
  successfulSave: <FormattedMessage id="app.successfulSave" defaultMessage="Se ha guardado con éxito" />,
  successfulDelete: <FormattedMessage id="app.successfulDelete" defaultMessage="Se ha eliminado con éxito" />,
  successfulCreate: <FormattedMessage id="app.successfulCreate" defaultMessage="Se ha creado con éxito" />,

  checkApprovedWorkFieldItems: <FormattedMessage id="workFieldEvaluation.checkApprovedWorkFieldItems" defaultMessage="Indique el grado de cumplimiento de cada item" />,
  createLink: <FormattedMessage id="workFieldEvaluation.editPerson" defaultMessage="edición de proveedores." />,
  date: <FormattedMessage id="workFieldEvaluation.date" defaultMessage="Fecha de evaluación" />,
  deleteWorkFieldEvaluation: <FormattedMessage id="workFieldEvaluation.deleteWorkFieldEvaluation" defaultMessage="¿Está seguro que quiere eliminar la evaluación?" />,
  deleteWorkFieldEvaluationTitle: <FormattedMessage id="workFieldEvaluation.deleteTitle" defaultMessage="Eliminar evaluación" />,
  noWorkField: <FormattedMessage id="workFieldEvaluation.noWorkField" defaultMessage="El proveedor seleccionado no tiene ningún rubro asignado, puede asignarle un rubro desde " />,
  providerHasNotWorkField: <FormattedMessage id="workFieldEvaluation.providerHasNotWorkField" defaultMessage="El proveedor no tiene un rubro asignado" />,
  responsibleRequiredErrorMessage: <FormattedMessage id="workFieldEvaluation.responsibleRequiredErrorMessage" defaultMessage="Seleccione al menos un responsable de evaluación" />,
  selectOption: <FormattedMessage id="workFieldEvaluation.selectOption" defaultMessage="Seleccione una opción" />,
  selectPersonRequired: <FormattedMessage id="workFieldEvaluation.selectPersonRequired" defaultMessage="Seleccione un proveedor" />,
  selectPersonRequiredWorkFieldItems: <FormattedMessage id="workFieldEvaluation.selectPerson" defaultMessage="Seleccione un proveedor para visualizar los items a evaluar del rubro asignado al mismo" />,
  selectWorkFieldToEvaluate: <FormattedMessage id="workFieldEvaluation.selectWorkFieldToEvaluate" defaultMessage="Seleccione el rubro del proveedor a evaluar" />,
  workFieldEvaluationInChargePerson: <FormattedMessage id="workFieldEvaluation.inChargePerson" defaultMessage="Responsables de la evaluación" />,
  providerEvaluated: <FormattedMessage id="workFieldEvaluation.provider" defaultMessage="Proveedor evaluado" />,
  workFieldEvaluated: <FormattedMessage id="workFieldEvaluation.workField" defaultMessage="Rubro evaluado" />,
  workFieldItems: <FormattedMessage id="workFieldEvaluation.workFieldItems" defaultMessage="Items a evaluar" />,
  evaluator: <FormattedMessage id="workFieldEvaluation.evaluator" defaultMessage="Evaluador" />,
  evaluations: <FormattedMessage id="workFieldEvaluation.evaluations" defaultMessage="Evaluaciones" />,

  newTitle: <FormattedMessage id="workFieldEvaluation.newTitle" defaultMessage="Nueva evaluación del rubro" />,
  editTitle: <FormattedMessage id="workFieldEvaluation.editTitle" defaultMessage="Modificar evaluación" />,

  approved: <FormattedMessage id="workFieldEvaluation.approved" defaultMessage="Aprobada" />,
  conditional: <FormattedMessage id="workFieldEvaluation.conditional" defaultMessage="Condicional" />,
  addButton: <FormattedMessage id="workFieldEvaluation.createWorkFieldEvaluation" defaultMessage="Crear evaluación" />,
  cancelButton: <FormattedMessage id="app.cancel" defaultMessage="Cancelar" />,
  deletePersonButton: <FormattedMessage id="app.delete" defaultMessage="Eliminar" />,
  editWorkFieldEvaluationTitle: <FormattedMessage id="workFieldEvaluation.editTitle" defaultMessage="Modificar evaluación" />,
  pendingEvaluation: <FormattedMessage id="workFieldEvaluation.pendingEvaluation" defaultMessage="Evaluación pendiente" />,
  rejected: <FormattedMessage id="workFieldEvaluation.rejected" defaultMessage="Rechazado" />,
  evaluationProvider: <FormattedMessage id="workFieldEvaluation.title" defaultMessage="Evaluaciones de proveedores" />,

  providers: <FormattedMessage id="providers.providers" defaultMessage="Proveedores" />,

  workFieldEvaluationDetails: <FormattedMessage id="workFieldEvaluation.details" defaultMessage="Detalles de evaluación" />,
  itemsEvaluated: <FormattedMessage id="workFieldEvaluation.itemsEvaluated" defaultMessage="Items evaluados" />,
  resultEvaluation: <FormattedMessage id="workFieldEvaluation.resultEvaluation" defaultMessage="Resultado de la evaluación" />,
  noEvaluations: <FormattedMessage id="workFieldEvaluation.noEvaluations" defaultMessage="No se realizó ninguna evaluación a este proveedor" />,
  noRatedItems: <FormattedMessage id="workFieldEvaluation.noRatedItems" defaultMessage="Sin evaluar" />,
  scoresReference: <FormattedMessage id="workFieldEvaluation.scoresReference" defaultMessage="Referencia de puntajes" />,

  relatedProviderOptional: <FormattedMessage id="workFieldEvaluation.relatedProviderOptional" defaultMessage="Proveedor relacionado (opcional)" />,
  relatedProvider: <FormattedMessage id="workFieldEvaluation.relatedProvider" defaultMessage="Proveedor relacionado" />,
  relatedEvaluation: <FormattedMessage id="workFieldEvaluation.relatedEvaluation" defaultMessage="Evaluación relacionada" />,
  selectEvaluation: <FormattedMessage id="workFieldEvaluation.selectEvaluation" defaultMessage="Seleccione una evaluación" />,
  seeEvaluation: <FormattedMessage id="workFieldEvaluation.seeEvaluation" defaultMessage="Ver evaluación" />,
  evaluationStatus: <FormattedMessage id="workFieldEvaluation.evaluationStatus" defaultMessage="Estado de la evaluación" />,
  createDeviation: <FormattedMessage id="workFieldEvaluation.createDeviation" defaultMessage="Crear desvío" />,
  seeDeviation: <FormattedMessage id="workFieldEvaluation.seeDeviation" defaultMessage="Ver desvío" />,
  createRisk: <FormattedMessage id="workFieldEvaluation.createRisk" defaultMessage="Crear riesgo" />,
  seeRisk: <FormattedMessage id="workFieldEvaluation.seeRisk" defaultMessage="Ver riesgo" />,
  itemsToBeEvaluated: <FormattedMessage id="workField.itemsToBeEvaluated" defaultMessage="Ítems a evaluar" />,
  finalResult: <FormattedMessage id="workField.finalResult" defaultMessage="Resultado final" />,
};

export default workFieldEvaluationTexts;
