import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const LoggedInRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      !localStorage.getItem('user')
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/processes/map', state: { from: props.location } }} />
    )}
  />
);

export default LoggedInRoute;
