import React, { useState, useEffect } from 'react';
import {
  Box, Grid, Typography, makeStyles, Divider, Dialog, DialogTitle, DialogContent,
  DialogContentText, DialogActions, Table, TableBody, TableRow, TableCell,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import Button from '../../controls/Button';
import { providerActions } from '../../../redux/actions';
import {
  deviationConstants, documentConstants, providersTexts, workFieldEvaluationTexts,
} from '../../../constants';
import { history, workFieldHelper } from '../../../helpers';
import ProviderDocuments from '../ProviderDocuments';

const CustomTableCell = withStyles(() => ({
  root: {
    fontSize: '14px',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  textSeparator: {
    marginTop: '24px',
    marginLeft: '24px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  textTitle: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '12px',
    textAlign: 'left',
  },
  textValue: {
    paddingTop: '8px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'left',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  divider: {
    marginBottom: '16px',
    marginLeft: '24px',
  },
  itemContainer: {
    padding: '0 24px',
  },
}));

const ProviderViewDetail = ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const deletingProvider = useSelector((state) => state.provider.deleting);
  const { roles } = useSelector((state) => state.authentication.user);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const isAdmin = roles.includes('Admin');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function toggleDeleteDialog() {
    setOpenDeleteDialog(!openDeleteDialog);
  }

  function handleDeleteProviders(id) {
    dispatch(providerActions.deleteProvider(id, providersTexts.successfulDelete));
  }

  function getWorkFieldsName(providerWorkFields) {
    return providerWorkFields
      .map((workField, index) => {
        const keyId = `workField-${index}`;
        const entityLink = `/workFields/${workField.workFieldId}`;
        return (
          <Typography key={keyId} className={classes.textValue} display="inline" component={Link} to={entityLink}>
            {workField.workField.name}
            {index < providerWorkFields.length - 1 ? ', ' : ''}
          </Typography>
        );
      });
  }

  return (
    <div className={classes.main}>

      <Grid container spacing={3} align="center">
        <Grid item xs={12} sm={6}>
          <Box className={classes.itemContainer}>
            <Typography className={classes.textTitle}>
              {providersTexts.workField}
            </Typography>
            <Typography className={classes.textValue}>
              {
                data.workFields && data.workFields.length
                  ? getWorkFieldsName(data.workFields)
                  : '-'
              }
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={classes.itemContainer}>
            <Typography className={classes.textTitle}>
              {providersTexts.productsOrServicesOffered}
            </Typography>
            <Typography className={classes.textValue}>
              {data.productsOrServicesOffered}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Typography className={classes.textSeparator}>
        {providersTexts.generalInformation}
      </Typography>
      <Divider className={classes.divider} />

      <Grid container spacing={3} align="center">
        <Grid item xs={12} sm={6}>
          <Box className={classes.itemContainer}>
            <Typography className={classes.textTitle}>
              {providersTexts.cuit}
            </Typography>
            <Typography className={classes.textValue}>
              {data.cuit || '-'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={classes.itemContainer}>
            <Typography className={classes.textTitle}>
              {providersTexts.businessName}
            </Typography>
            <Typography className={classes.textValue}>
              {data.businessName || '-'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={classes.itemContainer}>
            <Typography className={classes.textTitle}>
              {providersTexts.contactPerson}
            </Typography>
            <Typography className={classes.textValue}>
              {data.contactPerson || '-'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={classes.itemContainer}>
            <Typography className={classes.textTitle}>
              {providersTexts.address}
            </Typography>
            <Typography className={classes.textValue}>
              {data.address || '-'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={classes.itemContainer}>
            <Typography className={classes.textTitle}>
              {providersTexts.email}
            </Typography>
            <Typography className={classes.textValue}>
              {data.email || '-'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.itemContainer}>
            <ProviderDocuments
              documents={data.documents}
              providerId={data.id}
              showDivider
            />
          </Box>
        </Grid>
      </Grid>

      <Typography className={classes.textSeparator}>
        {providersTexts.evaluations}
      </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3} align="flex-start">
        <Grid item xs={12} sm={9}>
          <Box className={classes.itemContainer}>
            <Table>
              <TableBody>
                {data.workFieldEvaluations && data.workFieldEvaluations.length > 0
                  ? (
                    <>
                      <TableRow>
                        <CustomTableCell width="40%" size="small" style={{ fontWeight: 700 }}>{workFieldEvaluationTexts.date}</CustomTableCell>
                        <CustomTableCell width="30%" size="small" style={{ fontWeight: 700 }}>{workFieldEvaluationTexts.workFieldEvaluated}</CustomTableCell>
                        <CustomTableCell width="30%" size="small" style={{ fontWeight: 700 }}>{workFieldEvaluationTexts.resultEvaluation}</CustomTableCell>
                      </TableRow>
                      {data.workFieldEvaluations.map((workFieldEvaluation) => {
                        const evaluationStatusLabel = workFieldHelper
                          .getEvaluationStatusLabel(workFieldEvaluation.evaluationStatus);
                        return (
                          <TableRow key={workFieldEvaluation.id} component={Link} to={`/workFieldEvaluations/${workFieldEvaluation.id}`} style={{ textDecoration: 'none' }}>
                            <CustomTableCell width="40%" size="small">{moment(workFieldEvaluation.date).format('DD/MM/YYYY')}</CustomTableCell>
                            <CustomTableCell width="30%" size="small">{workFieldEvaluation.workField.name}</CustomTableCell>
                            <CustomTableCell width="30%" size="small">{evaluationStatusLabel}</CustomTableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  )
                  : (
                    <TableRow>
                      <CustomTableCell size="small">
                        <Typography variant="subtitle1" gutterBottom style={{ fontSize: '14px' }}>
                          {workFieldEvaluationTexts.noEvaluations}
                        </Typography>
                      </CustomTableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
            <Box pt={2}>
              <Button
                component={Link}
                to={`/workFieldEvaluations/new?providerId=${data.id}`}
                variant="contained"
                text={providersTexts.evaluateProvider}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Typography className={classes.textSeparator}>
        <FormattedMessage id="risks.title" defaultMessage="Riesgos" />
      </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3} align="flex-start">
        <Grid item xs={12} sm={9}>
          <Box className={classes.itemContainer}>
            <Table>
              <TableBody>
                {data.risks && data.risks.length > 0
                  ? (
                    <>
                      <TableRow>
                        <CustomTableCell width="40%" size="small" style={{ fontWeight: 700 }}><FormattedMessage id="risks.label.title" defaultMessage="Título" /></CustomTableCell>
                        <CustomTableCell width="30%" size="small" style={{ fontWeight: 700 }}><FormattedMessage id="risks.swotAssociated" defaultMessage="Contexto de la organización" /></CustomTableCell>
                        <CustomTableCell width="30%" size="small" style={{ fontWeight: 700 }}><FormattedMessage id="risks.label.detectionDate" defaultMessage="Fecha de detección" /></CustomTableCell>
                      </TableRow>
                      {data.risks.map((risk) => (
                        <TableRow key={risk.id} component={Link} to={`/risks/${risk.id}`} style={{ textDecoration: 'none' }}>
                          <CustomTableCell width="40%" size="small">{risk.title}</CustomTableCell>
                          <CustomTableCell width="30%" size="small">{risk.swotItemName}</CustomTableCell>
                          <CustomTableCell width="30%" size="small">{moment(risk.detectionDate).format('DD/MM/YYYY')}</CustomTableCell>
                        </TableRow>
                      ))}
                    </>
                  )
                  : (
                    <TableRow>
                      <CustomTableCell size="small">
                        <Typography variant="subtitle1" gutterBottom style={{ fontSize: '14px' }}>
                          <FormattedMessage id="risks.noProviderRisks" defaultMessage="No hay riesgos asociados al proveedor" />
                        </Typography>
                      </CustomTableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </Box>
        </Grid>
      </Grid>

      <Typography className={classes.textSeparator}>
        <FormattedMessage id="deviation.title" defaultMessage="Desvíos" />
      </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3} align="flex-start">
        <Grid item xs={12} sm={9}>
          <Box className={classes.itemContainer}>
            <Table>
              <TableBody>
                {data.deviations && data.deviations.length > 0
                  ? (
                    <>
                      <TableRow>
                        <CustomTableCell width="40%" size="small" style={{ fontWeight: 700 }}><FormattedMessage id="deviation.name" defaultMessage="Título" /></CustomTableCell>
                        <CustomTableCell width="30%" size="small" style={{ fontWeight: 700 }}><FormattedMessage id="deviation.currentState" defaultMessage="Estado actual" /></CustomTableCell>
                        <CustomTableCell width="30%" size="small" style={{ fontWeight: 700 }}><FormattedMessage id="deviation.form.detectionDate" defaultMessage="Fecha de detección" /></CustomTableCell>
                      </TableRow>
                      {data.deviations.map((deviation) => (
                        <TableRow key={deviation.id} component={Link} to={`/deviations/${deviation.id}`} style={{ textDecoration: 'none' }}>
                          <CustomTableCell width="40%" size="small">{deviation.title}</CustomTableCell>
                          <CustomTableCell width="30%" size="small">{deviationConstants.statusForm[deviation.currentState].label}</CustomTableCell>
                          <CustomTableCell width="30%" size="small">{moment(deviation.detectionDate).format('DD/MM/YYYY')}</CustomTableCell>
                        </TableRow>
                      ))}
                    </>
                  )
                  : (
                    <TableRow>
                      <CustomTableCell size="small">
                        <Typography variant="subtitle1" gutterBottom style={{ fontSize: '14px' }}>
                          <FormattedMessage id="deviation.noProviderDeviation" defaultMessage="No hay desvíos asociados al proveedor" />
                        </Typography>
                      </CustomTableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </Box>
        </Grid>
      </Grid>

      <Box display="flex" alignItems="flex-end" justifyContent="flex-end" mt={3}>
        <Button
          onClick={history.goBack}
          variant="outlined"
          text={providersTexts.back}
        />
        <Button
          type="button"
          color="secondary"
          onClick={() => { toggleDeleteDialog(); }}
          text={providersTexts.delete}
          style={{ fontSize: '10px', fontWeight: 600 }}
          disabled={!isAdmin}
        />
        <Button
          component={Link}
          to={`${data.id}/edit`}
          type="submit"
          text={providersTexts.edit}
          disabled={!isAdmin}
        />
      </Box>
      <Dialog open={openDeleteDialog} onClose={() => toggleDeleteDialog()} fullWidth maxWidth="xs" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{providersTexts.deleteTitleDialog}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {providersTexts.deleteContentDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button text={providersTexts.cancel} onClick={() => toggleDeleteDialog()} />
          <Button
            text={providersTexts.delete}
            color="secondary"
            loading={deletingProvider}
            onClick={() => { handleDeleteProviders(data.id); }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

ProviderViewDetail.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    workFields: PropTypes.arrayOf(PropTypes.shape({
      workFieldId: PropTypes.number,
      workField: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        workFieldItems: PropTypes.string,
      }),
    })),
    productsOrServicesOffered: PropTypes.string,
    cuit: PropTypes.string,
    businessName: PropTypes.string,
    address: PropTypes.string,
    contactPerson: PropTypes.string,
    email: PropTypes.string,
    workFieldEvaluations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      date: PropTypes.string,
      evaluationStatus: PropTypes.number,
      answers: PropTypes.string,
    })),
    documents: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      type: PropTypes.oneOf([documentConstants.PROVIDER_DOCUMENT]),
      actualDocumentVersionId: PropTypes.number,
      usage: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        entityName: PropTypes.string,
      })),
    })).isRequired,
    deviations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      currentState: PropTypes.string,
      detectionDate: PropTypes.string,
    })),
    risks: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      swotItemName: PropTypes.string,
      detectionDate: PropTypes.string,
    })),
  }),
};

ProviderViewDetail.defaultProps = {
  data: {},
};

export default ProviderViewDetail;
