/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { ValidatorForm } from 'react-material-ui-form-validator';
import {
  Box, CircularProgress, Button as MuiButton,
  Dialog, DialogActions, DialogContent, Fab, Grid, makeStyles, Typography, Divider,
  RadioGroup, FormControlLabel, Radio, IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { orange } from '@material-ui/core/colors';
import { Check, Clear, Close } from '@material-ui/icons';
import PaymentIcon from '@material-ui/icons/Payment';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CardSection from './Component/CardSection';
import Button from '../controls/Button';
import axios from '../../axios/axios';
import {
  authHeader, errorCodeHelper, textHelpers, userCompanySelectorHelper,
} from '../../helpers';
import { stripePlanSpecs } from '../../constants';
import {
  stripeActions, alertActions, companyActions, modalAlertActions,
} from '../../redux/actions';
import handler from '../../services/handler';
import { stripeService } from '../../services';

const useStyles = makeStyles((theme) => ({
  main: {
    padding: '36px 36px 24px',
    backgroundColor: theme.palette.background.paper,
    height: '100%',
  },
  dialogPaper: {
    minHeight: '208px',
    minWidth: '500px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    color: orange[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  changeButton: {
    borderRadius: 30,
    color: theme.palette.primary.main,
    fontSize: 9,
    fontWeight: 600,
    textDecoration: 'underline',
  },
  priceText: { fontSize: 14, fontWeight: 500, textTransform: 'uppercase' },
  closeButton: {
    height: '25px',
    padding: '0',
    width: '25px',
  },
  divider: { marginTop: '16px' },
}));

const CheckoutForm = ({
  plan, handleGoBack, currentPlanId, currentPlanWithPrice, includesFreeInitialMonth
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const cardsData = useSelector((state) => state.stripe.cardsData);
  const currentCompany = userCompanySelectorHelper.getCurrentCompany();
  const selectedPlanIsFree = [stripePlanSpecs.plans.PertixFree.key, stripePlanSpecs.plans.PertixStarterFree.key].includes(plan.key);
  const currentPlanIsFree =  [stripePlanSpecs.plans.PertixFree.key, stripePlanSpecs.plans.PertixStarterFree.key].includes(currentPlanId);
  const [confirmed, setConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSubscriptionDialog, setOpenSubscriptionDialog] = useState(false);
  const [newPaymentMethodDialog, setNewPaymentMethodDialog] = useState(false);
  const [confirmPlanChangeDialog, setConfirmPlanChangeDialog] = useState(false);
  const [selectedCard, setSelectedCard] = useState('');
  const [defaultCard, setDefaultCard] = useState('');
  const cardDeclinedText = intl.formatMessage({ id: 'stripe.cardDeclined', defaultMessage: 'Tu tarjeta ha sido rechazada' });
  const subscribeText = intl.formatMessage({ id: 'stripe.subscribe', defaultMessage: 'Suscribirse' });
  const selectedPlanText = intl.formatMessage({ id: 'stripe.selectedPlan', defaultMessage: 'Plan seleccionado' });
  const termsServicePart1 = intl.formatMessage({ id: 'stripe.termsServicePart1', defaultMessage: 'Aplican' });
  const termsServicePart2 = intl.formatMessage({ id: 'stripe.termsServicePart2', defaultMessage: 'términos y condiciones' });
  const termsServicePart3 = intl.formatMessage({ id: 'stripe.termsServicePart3', defaultMessage: '+ impuestos aplicables' });
  const addPaymentMethodText = intl.formatMessage({ id: 'stripe.addPaymentMethod', defaultMessage: 'Agregar método de pago' });
  const paymentMethodsText = intl.formatMessage({ id: 'company.paymentMethods', defaultMessage: 'Métodos de pago' });
  const defaultPaymentMethodText = intl.formatMessage({ id: 'company.defaultPaymentMethod', defaultMessage: 'método de pago por defecto' });
  const changePlanText = intl.formatMessage({ id: 'stripe.changePlan', defaultMessage: 'Cambiar de plan' });
  const problemsPaymentTitle = intl.formatMessage({ id: 'stripe.problemsPayment', defaultMessage: 'PROBLEMAS CON TU PAGO' });
  const problemsPaymentMessage = intl.formatMessage({ id: 'stripe.problemsPaymentMessage', defaultMessage: 'Estamos teniendo problemas para procesar tu pago. Te recomendamos que pruebes con otra tarjeta o contactes a tu entidad bancaria para resolver este problema.' });
  const congratulationsTitle = intl.formatMessage({ id: 'stripe.congratulations', defaultMessage: '¡FELICIDADES!' });
  const reminderUserHaveText = intl.formatMessage({ id: 'stripe.reminderUserHave', defaultMessage: 'Recordá que tenés' });
  const thirtyDaysFreeText = intl.formatMessage({ id: 'stripe.30DaysFree', defaultMessage: '30 días gratis' });
  const aboutAnyOfHisPlansText = intl.formatMessage({ id: 'stripe.aboutAnyOfHisPlans', defaultMessage: 'sobre cualquiera de nuestros planes.' });
  const successfulPlanChangeMessage = (
    <>
      <Typography variant="subtitle2" align="left" style={{ marginBottom: '8px' }}>
        <FormattedMessage id="stripe.successfulPlanChange" defaultMessage="La operación se concretó correctamente. Su nuevo plan es el siguiente:" />
      </Typography>
      <Typography variant="subtitle2" align="left" style={{ marginBottom: '8px' }}>
        <b>{plan.name}</b>
        {' - '}
        {`USD $${plan.price} al`}
        {' '}
        {plan.frequency}
      </Typography>
      {includesFreeInitialMonth && <Typography variant="subtitle2" align="left">
          {reminderUserHaveText}
          {' '}
          <span style={{ fontWeight: 600 }}>{thirtyDaysFreeText}</span>
          {' '}
          {aboutAnyOfHisPlansText}
        </Typography>
      }
    </>
  );

  function handleRadioChange(event) {
    setSelectedCard(event.target.value);
    setNewPaymentMethodDialog(false);
  }

  function handleCardSectionDisplay() {
    setSelectedCard('');
    setNewPaymentMethodDialog((prev) => !prev);
  }

  function onSubscriptionComplete({ subscription, paymentIntent }) {
    const isActiveSubscription = subscription && (subscription.status === 'active' || subscription.status === 'trialing');
    const isSucceededPaymentSubscription = paymentIntent && paymentIntent.status === 'succeeded';
    if (isActiveSubscription || isSucceededPaymentSubscription) {
      if (localStorage.getItem('latestInvoicePaymentIntentStatus')) localStorage.removeItem('latestInvoicePaymentIntentStatus');
      if (localStorage.getItem('latestInvoiceId')) localStorage.removeItem('latestInvoiceId');

      setConfirmed(true);
      setLoading(false);
      setTimeout(() => {
        setOpenSubscriptionDialog(false);
        dispatch(modalAlertActions.infoAndReload(
          congratulationsTitle, successfulPlanChangeMessage,
        ));
      }, 1000);
    }
  }

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    if (subscription && (subscription.status === 'active' || subscription.status === 'trialing')) {
      return { subscription, priceId, paymentMethodId };
    }

    const paymentIntent = invoice
      ? invoice.paymentIntent
      : subscription.latest_invoice.paymentIntent;

    if (paymentIntent.status === 'requires_action'
        || (isRetry && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.clientSecret, { payment_method: paymentMethodId })
        .then((result) => {
          if (result.error) {
            const messageError = errorCodeHelper.getStripeErrorMessage(
              { declineCode: result.error.decline_code, message: result.error.message },
            );
            setLoading(false);
            setOpenSubscriptionDialog(false);
            dispatch(modalAlertActions.infoSubscription(
              { title: problemsPaymentTitle, message: problemsPaymentMessage },
            ));
            return dispatch(alertActions.error(messageError));
          }
          if (result.paymentIntent.status === 'succeeded') {
            return {
              subscription,
              priceId,
              paymentMethodId,
              invoice,
              paymentIntent: result.paymentIntent,
            };
          }
          return result;
        })
        .catch((error) => {
          handler.handleError(error)
            .catch((err) => dispatch(alertActions.error(err)));
        });
    }
    return { subscription, priceId, paymentMethodId };
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    if (subscription.status === 'active' || subscription.status === 'trialing') {
      return { subscription, priceId, paymentMethodId };
    }
    if (subscription.latest_invoice.paymentIntent.status === 'requires_payment_method') {
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.paymentIntent.status,
      );
      const errorMessage = { error: { message: cardDeclinedText } };
      dispatch(alertActions.error(cardDeclinedText));
      throw errorMessage;
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function createSubscription({ paymentMethodId }) {
    const headers = authHeader();
    const subscriptionResource = { CardId: paymentMethodId, price: plan.key };
    const company = userCompanySelectorHelper.getCurrentCompany();
    const postUrl = `companies/${company.id}/subscription`;
    return (
      axios.post(postUrl, subscriptionResource, { headers })
        .then((result) => ({
          paymentMethodId,
          priceId: plan.key,
          subscription: result.data,
        }))
        .then(handlePaymentThatRequiresCustomerAction)
        .then(handleRequiresPaymentMethod)
        .then(onSubscriptionComplete)
        .catch((error) => {
          handler.handleError(error)
            .catch((err) => dispatch(alertActions.error(err)));
          setLoading(false);
          setOpenSubscriptionDialog(false);
          dispatch(modalAlertActions.infoSubscription(
            { title: problemsPaymentTitle, message: problemsPaymentMessage },
          ));
        })
    );
  }

  function updateSubscription({ paymentMethodId }) {
    const headers = authHeader();
    const subscriptionResource = { CardId: paymentMethodId, price: plan.key };
    const company = userCompanySelectorHelper.getCurrentCompany();
    const postUrl = `companies/${company.id}/subscription/update`;
    return (
      axios.post(postUrl, subscriptionResource, { headers })
        .then((result) => ({
          paymentMethodId,
          priceId: plan.key,
          subscription: result.data,
        }))
        .then(handlePaymentThatRequiresCustomerAction)
        .then(handleRequiresPaymentMethod)
        .then(onSubscriptionComplete)
        .catch((error) => {
          handler.handleError(error)
            .catch((err) => {
              dispatch(alertActions.error(err));
            });
          setLoading(false);
          setOpenSubscriptionDialog(false);
          dispatch(modalAlertActions.infoSubscription(
            { title: problemsPaymentTitle, message: problemsPaymentMessage },
          ));
        })
    );
  }

  function retryInvoiceWithNewPaymentMethod({ paymentMethodId, priceId, invoiceId }) {
    const headers = authHeader();
    const subscriptionResource = {
      CardId: paymentMethodId,
      invoice: invoiceId,
    };
    const company = userCompanySelectorHelper.getCurrentCompany();
    const postUrl = `companies/${company.id}/subscription/retry-invoice`;
    return (
      axios.post(postUrl, subscriptionResource, { headers })
        .then((result) => ({
          invoice: result.data,
          paymentMethodId,
          priceId,
          isRetry: true,
        }))
        .then(handlePaymentThatRequiresCustomerAction)
        .then(onSubscriptionComplete)
        .catch((error) => {
          handler.handleError(error)
            .catch((err) => dispatch(alertActions.error(err)));
          setLoading(false);
          setOpenSubscriptionDialog(false);
          dispatch(modalAlertActions.infoSubscription(
            { title: problemsPaymentTitle, message: problemsPaymentMessage },
          ));
        })
    );
  }

  function cancelSubscription() {
    setLoading(true);
    const titleStripe = intl.formatMessage({ id: 'stripe.cancelSuccessfulPlanTitle', defaultMessage: 'Cancelación exitosa' });
    const messageStripe = intl.formatMessage({ id: 'stripe.cancelSuccessfulPlanBody', defaultMessage: 'Su plan ha sido dado de baja con éxito. Recuerde que puede volver a suscribirse en cuanto lo desee. Su información no será borrada.' });
    const buttonStripe = intl.formatMessage({ id: 'stripe.seePlan', defaultMessage: 'Ver Planes' });
    stripeService.unsubscribe()
      .then(() => {
        window.location.reload();
        dispatch(companyActions.get(currentCompany.id));
        dispatch(modalAlertActions.info(titleStripe, messageStripe, buttonStripe));
      })
      .catch((err) => {
        dispatch(alertActions.error(err));
      })
      .finally(() => { setLoading(false); });
  }

  function handleConfirmChange() {
    setConfirmPlanChangeDialog((prev) => !prev);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    setOpenSubscriptionDialog(true);
    const cardElement = elements.getElement(CardElement);
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      'latestInvoicePaymentIntentStatus',
    );
    const invoiceId = localStorage.getItem('latestInvoiceId');
    let paymentMethodId = selectedCard;
    let errorPaymentMethod = false;
    if (cardElement && newPaymentMethodDialog) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      paymentMethodId = paymentMethod ? paymentMethod.id : '';
      errorPaymentMethod = error;
    }

    if (errorPaymentMethod || !paymentMethodId) {
      setLoading(false);
      setOpenSubscriptionDialog(false);
      return;
    }

    if (latestInvoicePaymentIntentStatus === 'requires_payment_method' && paymentMethodId) {
      await retryInvoiceWithNewPaymentMethod({ paymentMethodId, priceId: plan.key, invoiceId });
      return;
    }

    if (selectedPlanIsFree) {
      cancelSubscription();
      return;
    }

    if (currentPlanIsFree) {
      await createSubscription({ paymentMethodId, price: plan.key });
      return;
    }

    await updateSubscription({ paymentMethodId, price: plan.key });
  };

  const handleClose = () => {
    setOpenSubscriptionDialog(false);
  };

  useEffect(() => {
    if (!cardsData) {
      dispatch(stripeActions.getCards());
    }
    if (cardsData && cardsData.cards.length) {
      setSelectedCard(cardsData.defaultCard);
      setDefaultCard(cardsData.defaultCard);
    }
    if (cardsData && !cardsData.cards.length) {
      setNewPaymentMethodDialog(true);
    }
  }, [dispatch, cardsData]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={12} md={7}>
          <ValidatorForm
            onSubmit={currentPlanIsFree ? handleSubmit : handleConfirmChange}
            className={classes.main}
          >
            {!selectedPlanIsFree && (
              <Grid item xs={12}>
                {Boolean(cardsData && cardsData.cards.length) && (
                <>
                  <Typography variant="body1" gutterBottom style={{ fontSize: '14px' }}>
                    {paymentMethodsText}
                  </Typography>
                  <RadioGroup aria-label="quiz" name="quiz" value={selectedCard} onChange={handleRadioChange}>
                    {cardsData.cards.map((paymentMethod) => (
                      <Box key={paymentMethod.id}>
                        <FormControlLabel
                          value={paymentMethod.id}
                          control={<Radio />}
                          size="small"
                          label={(
                            <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                              {`${textHelpers.capitalizeFirstLetter(paymentMethod.card.brand)}  terminada en ${paymentMethod.card.last4}`}
                              {(defaultCard === paymentMethod.id) ? ` (${defaultPaymentMethodText})` : null}
                            </Typography>
                              )}
                        />
                      </Box>
                    ))}
                  </RadioGroup>
                  <Button
                    text={addPaymentMethodText}
                    onClick={() => handleCardSectionDisplay()}
                    variant="outlined"
                  />
                </>
                )}

                {!cardsData && <CircularProgress />}

                {newPaymentMethodDialog && <CardSection />}

              </Grid>
            )}

            {selectedPlanIsFree && (
              <Box minHeight={130}>
                <Typography variant="body1" gutterBottom style={{ fontSize: '14px' }}>
                  <FormattedMessage id="stripe.changeToPlanFreeTitle" defaultMessage="Plan Free" />
                </Typography>
                <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                  <FormattedMessage id="stripe.changeToPlanFreeBody" defaultMessage="Al volver al Plan Free, su información no será borrada pero las funcionalidades quedaran limitadas al espacio disponible restante." />
                </Typography>
              </Box>
            )}

            <Box display="flex" alignItems="center" justifyContent="flex-start" mt={2}>
              <Button
                text={<FormattedMessage id="app.back" defaultMessage="volver" />}
                variant="outlined"
                color="primary"
                onClick={handleGoBack}
              />
              <Button
                type="submit"
                text={currentPlanIsFree ? subscribeText : changePlanText}
                disabled={!stripe}
              />
            </Box>
          </ValidatorForm>
        </Grid>
        <Grid item sm={12} md={5}>
          <Box className={classes.main}>
            <Typography variant="body1" style={{ fontSize: 14, letterSpacing: '0.16px' }}>
              {selectedPlanText}
            </Typography>
            <Box display="flex" mt={3}>
              <Typography variant="body1" style={{ fontSize: 16, fontWeight: 700, letterSpacing: '0.16px' }}>
                {plan.label}
              </Typography>
              <MuiButton variant="text" className={classes.changeButton} onClick={handleGoBack}>
                <FormattedMessage id="app.change" defaultMessage="Cambiar" />
              </MuiButton>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1" style={{ fontSize: 12 }}>
                <FormattedMessage id="app.total" defaultMessage="Total" />
              </Typography>
              <Box display="flex" style={plan.strikethroughPrice ? { textDecorationLine: 'line-through' } : {}}>
                <Typography variant="body1" className={classes.priceText}>
                  {`USD $${plan.strikethroughPrice ? plan.strikethroughPrice : plan.price}/`}
                </Typography>
                <Typography variant="body1" className={classes.priceText}>
                  {plan.frequency}
                </Typography>
              </Box>
            </Box>
            {plan.strikethroughPrice && (
            <Box mt={0.5} display="flex" justifyContent="space-between">
              <Typography variant="body1" style={{ fontSize: 12 }}>
                {plan.isAnnual
                  ? <FormattedMessage id="stripe.totalWithDiscount" defaultMessage="El primer año" />
                  : <FormattedMessage id="stripe.totalFirst3Months" defaultMessage="Los primeros 3 meses" />}
              </Typography>
              <Box display="flex">
                <Typography variant="body1" className={classes.priceText}>
                  {`USD $${plan.price}/`}
                </Typography>
                <Typography variant="body1" className={classes.priceText}>
                  {plan.frequency}
                </Typography>
              </Box>
            </Box>
            )}
            <Divider className={classes.divider} />
            <Typography variant="body1" style={{ marginTop: 16, fontSize: 10, letterSpacing: '0.16px' }}>
              {termsServicePart1}
              {' '}
              <a href="/" target="_blank" rel="noopener noreferrer">{termsServicePart2}</a>
              {' '}
              {termsServicePart3}
            </Typography>
            {includesFreeInitialMonth && <Typography variant="body1" style={{ marginTop: 3, fontSize: 10, letterSpacing: '0.16px' }}>
                {reminderUserHaveText}
                {' '}
                <span style={{ fontWeight: 600 }}>{thirtyDaysFreeText}</span>
                {' '}
                {aboutAnyOfHisPlansText}
              </Typography>
            }
          </Box>
        </Grid>
      </Grid>

      <Dialog
        classes={{ paper: classes.dialogPaper }}
        open={openSubscriptionDialog}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Typography color="primary" variant="h4" align="center">
            { selectedPlanIsFree
              ? <FormattedMessage id="subscription.processing" defaultMessage="Realizando el cambio" />
              : <FormattedMessage id="subscription.paying" defaultMessage="Realizando el pago" />}
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center" height="70%" mt={2}>
            <Typography variant="subtitle2" align="center">
              <FormattedMessage id="subscription.wait" defaultMessage="Por favor aguarde un momento... No cierre esta pantalla" />
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
            <div className={classes.wrapper}>
              <Fab aria-label="save" color="primary" component={Link} to="/stripe/plans">
                {(() => {
                  if (loading) {
                    return <PaymentIcon />;
                  }
                  if (confirmed && !loading) {
                    return <Check />;
                  }
                  return <Clear />;
                })()}
              </Fab>
              {loading && <CircularProgress size={68} className={classes.fabProgress} />}
            </div>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog classes={{ paper: classes.dialogPaper }} open={confirmPlanChangeDialog} onClose={handleConfirmChange} aria-labelledby="form-dialog-title">
        <Box pt={3} pb={1} px={3} display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography align="left" className={classes.textTitleDialog} variant="body1">
              <FormattedMessage id="stripe.changePaymentPlan" defaultMessage="Cambiar el plan de pago" />
            </Typography>
          </Box>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleConfirmChange}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Box justifyContent="left" height="70%" mb={1}>
            <Typography variant="subtitle2" align="left">
              <FormattedMessage id="stripe.currentPlan" defaultMessage="PLAN ACTUAL:" />
            </Typography>
            <Typography variant="subtitle2" align="left">
              <b>{currentPlanWithPrice.name}</b>
              {' - '}
              {`USD $${currentPlanWithPrice.price} al`}
              {' '}
              {currentPlanWithPrice.frequency}
            </Typography>
            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
            <Typography variant="subtitle2" align="left">
              <FormattedMessage id="stripe.selectPlan" defaultMessage="NUEVO PLAN:" />
            </Typography>
            <Typography variant="subtitle2" align="left">
              <b>{plan.name}</b>
              {' - '}
              {`USD $${plan.price} al`}
              {' '}
              {plan.frequency}
            </Typography>
            <Typography variant="subtitle2" align="left" style={{ fontSize: '10px', marginTop: '16px' }}>
              <FormattedMessage id="stripe.prorrateo" defaultMessage="*El monto proporcional se verá reflejado en la facturación del próximo mes." />
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmChange}
            variant="outlined"
            text={<FormattedMessage id="app.cancel" defaultMessage="Cancelar" />}
          />
          <Button
            onClick={(e) => { handleConfirmChange(); handleSubmit(e); }}
            text={<FormattedMessage id="stripe.confirmChange" defaultMessage="Confirmar cambio" />}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

CheckoutForm.propTypes = {
  handleGoBack: PropTypes.func.isRequired,
  currentPlanId: PropTypes.number.isRequired,
  plan: PropTypes.shape(),
  currentPlanWithPrice: PropTypes.shape(),
  includesFreeInitialMonth: PropTypes.bool,
};

CheckoutForm.defaultProps = {
  plan: { key: 0 },
  currentPlanWithPrice: { key: 0 },
  includesFreeInitialMonth: false,
};

export default CheckoutForm;
