import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: '#F8F8F8',
    },
    paper: {
        backgroundColor: '#EEEEEE',
        height: '200px',
        padding: theme.spacing(5),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginBottom: theme.spacing(1),
            padding: theme.spacing(2),
        },
    },
    actionIcon: {
        color: '#373435',
        height: '20px',
        width: '20px'
    },
    closeButton: {
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2)
    },
    closeIcon: {
        height: 24,
        width: 24
    },
    closeIconContainer: {
        margin: '10px',
        position: 'absolute',
        right: 0,
        top: 0
    },
    dialog: {
        padding: '20px 60px 20px 60px',
    },
    downloadLastVersionIcon: {
        textTransform: 'uppercase',
        fontSize: '10px',
        color: '#F16C24',
        fontWeight: 600,
    },
    uploadButton: {
        width: '130px',
        height: '25px',
        backgroundColor: '#F16C24',
        color: '#fff',
        cursor: 'pointer',
        padding: '5px 15px',
        borderRadius: '30px',
    },
    uploadContainer: {
        textAlign: '-webkit-center',
    },
    quill: {
        height: 283,
    },
    clearIcon: {
        color: '#F16C24',
    },
    successAlertContainer: {
        backgroundColor: '#43A047',
        width: 209,
        height: 23,
        marginTop: 18,
    },
    checkIcon: {
        width: 13,
        height: 13,
        color: '#FFFFFF',
        display: 'inline',
        margin: 'auto',
    },
    successAlert: {
        paddingLeft: 5,
        margin: 'auto',
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '24px',
        display: 'inline',
        color: '#FFFFFF',
    },
    linkDocuments: {
        textTransform: 'uppercase',
        fontSize: '10px',
        color: '#F16C24',
        fontWeight: 600,
    },
    fileError: {
        display: 'block',
        color: 'red',
    },
    formControl: {
        marginTop: '0px',
    },
}));

export default useStyles;
