import React from 'react';
import { useIntl } from 'react-intl';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DataTable from '../../DataTable/DataTable';
import PageLayout from '../../PageLayout';
import Button from '../../controls/Button';

function ProcessPage() {
  const intl = useIntl();
  const userAuth = useSelector((state) => state.authentication.user);
  const title = intl.formatMessage({ id: 'processes.title', defaultMessage: 'Procesos' });
  const columns = [{
    name: 'id',
    options: {
      display: 'excluded',
      filter: false,
    },
  }, {
    name: 'name',
    label: intl.formatMessage({ id: 'processes.name', defaultMessage: 'Nombre' }),
  }, {
    name: 'area',
    label: intl.formatMessage({ id: 'processes.area', defaultMessage: 'Área' }),
  }];
  const addButtonText = intl.formatMessage({ id: 'processes.addProcess', defaultMessage: '+ Agregar proceso' });
  const keys = ['id', 'name', 'area'];
  const fetchUrl = `companies/${userAuth.company.id}/processes/`;
  const itemUrl = 'processes/';

  function ProcessPageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/processes" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{title}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  function ButtonGroup() {
    if (userAuth.company.roles.includes('Admin')) {
      return (
        <Button
          text={addButtonText}
          component={Link}
          to="processes/new"
          style={{ fontSize: '11px', fontWeight: 600 }}
        />
      );
    }
    return null;
  }

  return (
    <PageLayout
      title={title}
      breadCrumbs={<ProcessPageBreadcrumbs />}
      buttonGroup={<ButtonGroup />}
    >
      <DataTable
        title={title}
        fetchUrl={fetchUrl}
        itemUrl={itemUrl}
        keys={keys}
        columns={columns}
        addButtonText={addButtonText}
        addButtonTo="processes/new"
      />
    </PageLayout>
  );
}

export default ProcessPage;
