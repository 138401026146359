import React, { useEffect } from 'react';
import {
    Breadcrumbs,
    LinearProgress,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { riskActions } from '../../../redux/actions';
import EditEvaluationForm from './EditEvaluationForm';
import PageLayout from '../../PageLayout';

const useStyles = makeStyles(() => ({
    textBreadcrumbs: {
        fontWeight: 400,
        fontSize: 11,
    },
    textBreadcrumbsBold: {
        fontWeight: 700,
        fontSize: 11,
    },
}));

const EditRiskBreadcrumbs = ({
    modifyText,
    risksText,
    riskId,
    riskTitle,
}) => {
    const classes = useStyles();
    const riskLink = riskId ? `/risks/${riskId}` : '';

    return (
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
                color="inherit"
                style={{ textDecoration: 'none' }}
                to="/risks"
            >
                <Typography
                    className={classes.textBreadcrumbs}
                    color="textPrimary"
                    variant="caption"
                >
                    {risksText}
                </Typography>
            </Link>
            {
                riskId && (
                    <Link
                        color="inherit"
                        style={{ textDecoration: 'none' }}
                        to={riskLink}
                    >
                        <Typography
                            className={classes.textBreadcrumbs}
                            color="textPrimary"
                            variant="caption"
                        >
                            {riskTitle}
                        </Typography>
                    </Link>
                )
            }
            <Typography
                className={classes.textBreadcrumbsBold}
                color="textPrimary"
                variant="caption"
            >
                {modifyText}
            </Typography>
        </Breadcrumbs>
    );
}


const EditEvaluationPage = ({ routeParams: { id: riskId, evaluationId } }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const risk = useSelector((state) => state.risk.data);

    const editTitle = intl.formatMessage({ id: 'risks.editEvaluation', defaultMessage: 'Modificar Evaluación' });
    const risksText = intl.formatMessage({ id: 'risks.title', defaultMessage: 'Riesgos' });
    const modifyText = intl.formatMessage({ id: 'app.modify', defaultMessage: 'Modificar' });

    useEffect(() => {
        dispatch(riskActions.get(riskId));
    }, [dispatch, riskId]);

    return (
        <>
            {risk?.riskEvaluations
                ? (
                    <PageLayout
                        title={editTitle}
                        breadCrumbs={
                            <EditRiskBreadcrumbs
                                risksText={risksText}
                                riskTitle={risk.title}
                                riskId={riskId}
                                modifyText={modifyText}
                            />
                        }>
                        <EditEvaluationForm
                            risk={risk}
                            data={risk.riskEvaluations?.find((evaluation) => evaluation.id === Number(evaluationId))}
                        />
                    </PageLayout>
                )
                : (
                    <PageLayout
                        title={editTitle}
                        breadCrumbs={
                            <EditRiskBreadcrumbs
                                risksText={risksText}
                                modifyText={modifyText}
                            />
                        }>
                        <LinearProgress />
                    </PageLayout>
                )}
        </>

    );
}

EditEvaluationPage.propTypes = {
    routeParams: PropTypes.shape({
        id: PropTypes.string.isRequired,
        evaluationId: PropTypes.string.isRequired,
    }),
};

EditEvaluationPage.defaultProps = {
    routeParams: {},
};

export default EditEvaluationPage;
