import alertActions from './alert.actions';
import { personRoleConstants } from '../../constants';
import { personRoleService } from '../../services';

function create(personRole) {
  function request() { return { type: personRoleConstants.CREATE_REQUEST }; }
  function success() { return { type: personRoleConstants.CREATE_SUCCESS }; }
  function failure(error) { return { type: personRoleConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    personRoleService.create(personRole)
      .then(
        () => {
          dispatch(success());
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getAll() {
  function request() { return { type: personRoleConstants.GET_ALL_REQUEST }; }
  function success(personRole) { return { type: personRoleConstants.GET_ALL_SUCCESS, personRole }; }
  function failure(error) { return { type: personRoleConstants.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    personRoleService.getAll()
      .then(
        (response) => {
          dispatch(success(response));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function get(id) {
  function request() { return { type: personRoleConstants.GET_REQUEST }; }
  function success(personRole) { return { type: personRoleConstants.GET_SUCCESS, personRole }; }
  function failure(error) { return { type: personRoleConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    personRoleService.get(id)
      .then(
        (response) => {
          dispatch(success(response));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function update(id, personRole) {
  function request() { return { type: personRoleConstants.UPDATE_REQUEST }; }
  function success() { return { type: personRoleConstants.UPDATE_SUCCESS }; }
  function failure(error) { return { type: personRoleConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());
    personRoleService.update(id, personRole)
      .then(
        () => {
          dispatch(success());
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function deleteObjective(personRoleId) {
  function request() { return { type: personRoleConstants.DELETE_REQUEST }; }
  function success() { return { type: personRoleConstants.DELETE_SUCCESS }; }
  function failure() { return { type: personRoleConstants.DELETE_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    personRoleService.deleteObjective(personRoleId)
      .then(
        () => {
          dispatch(success());
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

const personRoleActions = {
  create,
  getAll,
  get,
  update,
  deleteObjective,
};

export default personRoleActions;
