/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import {
  FormControl, IconButton, MenuItem,
  Typography, makeStyles, Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import ClearIcon from '@material-ui/icons/Clear';
import { deviationActions } from '../../../../redux/actions';
import { personService } from '../../../../services';
import { deviationTexts, deviationConstants } from '../../../../constants';
import DatePickerValidator from '../../../controls/DatePickerValidator';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    textAlign: 'justify',
  },
  textArea: {
    '& .MuiInputBase-inputMultiline': {
      backgroundColor: '#F9F9F9',
      minHeight: '75px',
      alignItems: 'flex-start',
      padding: 8,
    },
  },
  labelAreaText: {
    fontSize: '12px',
    lineHeight: '12px',
    textAlign: 'justify',
  },
  textLabel: {
    '& .MuiFormLabel-root': {
      fontSize: '12px',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px) scale(1)',
    },
    padding: '0px 40px',
  },
  requiredField: {
    fontSize: '12px',
    lineHeight: '12px',
    textAlign: 'justify',
    paddingBottom: '16px',
  },
  textLabelRequired: {
    fontSize: '12px',
  },
}));

const BoardModalCorrectiveActionDefinedDeviationForm = ({
  data, target, setRefToTheForm, eventBus,
}) => {
  const initialFieldValues = {
    ...data.cardDetails,
    currentState: target,
    plannedImplementationDate: data.cardDetails.plannedImplementationDate ? data.cardDetails.plannedImplementationDate : null,
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deviationDetails, setDeviationDetails] = useState(initialFieldValues);
  const [people, setPeople] = useState([]);
  const formEl = useRef();
  setRefToTheForm(formEl);

  useEffect(() => {
    const fetchData = async () => {
      const resPersonService = await personService.getAll();
      setPeople(resPersonService);
    };
    fetchData();
  }, []);

  useEffect(() => {
    ValidatorForm.addValidationRule('passIfRuleIsUndefined', () => true);
  }, []);

  function handleSaveEditDeviations() {
    dispatch(
      deviationActions.updateDeviation(
        data.cardDetails.id,
        deviationDetails,
        deviationTexts.successfulUpdate,
      ),
    );
  }

  function handleSubmitForm() {
    handleSaveEditDeviations();
    const newCurrentCard = {
      ...data, cardDetails: { ...deviationDetails },
    };
    eventBus.publish({
      type: 'MOVE_CARD',
      index: 0,
      ...newCurrentCard,
    });
    eventBus.publish({
      type: 'UPDATE_CARD',
      laneId: target,
      card: deviationDetails,
    });
  }

  function handleChangeFormData(e) {
    const { name, value } = e.target;
    setDeviationDetails({ ...deviationDetails, [name]: value });
  }

  function handleClearDate(e, nameDate) {
    e.stopPropagation();
    setDeviationDetails({ ...deviationDetails, [nameDate]: null });
  }

  const plannedImplementationDateText = (
    <Typography className={classes.textLabelRequired}>
      {deviationTexts.plannedImplementationDate}
      {deviationConstants.statusForm[target].rules.plannedImplementationDate && <span style={{ color: 'red' }}> *</span>}
    </Typography>
  );

  const headOfImplementationText = (
    <Typography className={classes.textLabelRequired}>
      {deviationTexts.headOfImplementation}
      {deviationConstants.statusForm[target].rules.headOfImplementation && <span style={{ color: 'red' }}> *</span>}
    </Typography>
  );

  return (
    <ValidatorForm onSubmit={() => handleSubmitForm()} autoComplete="off" className={classes.textLabel} instantValidate ref={formEl}>
      <Typography className={classes.requiredField}>
        Campos obligatorios
        <span style={{ color: 'red' }}> *</span>
      </Typography>
      {!data.cardDetails.immediateAction && (
      <Box py={1}>
        <Typography className={classes.labelAreaText}>
          {deviationTexts.immediateAction}
          {deviationConstants.statusForm[target].rules.immediateAction && <span style={{ fontWeight: 'normal', color: 'red' }}> *</span>}
        </Typography>
        <TextValidator
          classes={{ root: classes.textArea }}
          style={{ marginTop: 8 }}
          value={deviationDetails.immediateAction}
          onChange={handleChangeFormData}
          fullWidth
          margin="normal"
          id="immediateActionId"
          name="immediateAction"
          size="small"
          multiline
          InputLabelProps={{ shrink: true }}
          validators={[deviationConstants.statusForm[target].rules.immediateAction || 'passIfRuleIsUndefined']}
          errorMessages={[deviationTexts.deviationDefaultRequired]}
        />
      </Box>
      )}
      <Box py={1}>
        <Typography className={classes.labelAreaText}>
          {deviationTexts.correctiveAction}
          {deviationConstants.statusForm[target].rules.correctiveAction && <span style={{ fontWeight: 'normal', color: 'red' }}> *</span>}
        </Typography>
        <TextValidator
          classes={{ root: classes.textArea }}
          style={{ marginTop: 8 }}
          value={deviationDetails.correctiveAction}
          onChange={handleChangeFormData}
          fullWidth
          margin="normal"
          id="immediateAction"
          name="correctiveAction"
          size="small"
          multiline
          InputLabelProps={{ shrink: true }}
          validators={[deviationConstants.statusForm[target].rules.correctiveAction || 'passIfRuleIsUndefined']}
          errorMessages={[deviationTexts.deviationDefaultRequired]}
        />
      </Box>
      <Box py={1}>
        <DatePickerValidator
          style={{ marginTop: 0 }}
          cancelLabel={deviationTexts.cancel}
          format="dd/MM/yyyy"
          fullWidth
          id="plannedImplementationDateId"
          label={plannedImplementationDateText}
          margin="normal"
          name="plannedImplementationDate"
          size="small"
          value={deviationDetails.plannedImplementationDate}
          onChange={(value) => {
            setDeviationDetails({ ...deviationDetails, plannedImplementationDate: value });
          }}
          InputProps={{
            endAdornment: (
              <IconButton style={{ padding: 5 }} onClick={(e) => handleClearDate(e, 'plannedImplementationDate')}>
                <ClearIcon />
              </IconButton>
            ),
          }}
          validators={[deviationConstants.statusForm[target].rules.plannedImplementationDate || 'passIfRuleIsUndefined']}
          errorMessages={[deviationTexts.deviationDefaultRequired]}
        />
      </Box>
      <Box py={1}>
        <FormControl className={classes.formControl}>
          <SelectValidator
            label={headOfImplementationText}
            id="headOfImplementation-select"
            value={deviationDetails.headOfImplementation}
            name="headOfImplementation"
            onChange={handleChangeFormData}
            fullWidth
            validators={[deviationConstants.statusForm[target].rules.headOfImplementation || 'passIfRuleIsUndefined']}
            errorMessages={[deviationTexts.deviationDefaultRequired]}
          >
            <MenuItem style={{ fontSize: '12px' }} value="">
              <em>{deviationTexts.selectOption}</em>
            </MenuItem>
            {people.map((item) => {
              if (!item.names) return null;
              return (
                <MenuItem style={{ fontSize: '12px' }} key={`${item.id}${item.surnames}`} value={item.id}>
                  {`${item.names} ${item.surnames}`}
                </MenuItem>
              );
            })}
          </SelectValidator>
        </FormControl>
      </Box>

    </ValidatorForm>
  );
};

BoardModalCorrectiveActionDefinedDeviationForm.propTypes = {
  data: PropTypes.shape().isRequired,
  eventBus: PropTypes.shape().isRequired,
  setRefToTheForm: PropTypes.func.isRequired,
  target: PropTypes.string.isRequired,
};

export default BoardModalCorrectiveActionDefinedDeviationForm;
