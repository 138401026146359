import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, makeStyles, Box, Grid,
  Typography, Container, InputAdornment, IconButton, TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '../controls/Button';
import { outlineActions } from '../../redux/actions';
import { validationConstants } from '../../constants';
import CustomRatingOutline from '../controls/CustomRatingOutline';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  root: {
    width: '100%',
    backgroundColor: '#F8F8F8',
    position: 'relative',
    overflow: 'auto',
    height: 200,
    maxHeight: 300,
    paddingTop: 16,
  },
}));

const OutlineForm = ({ edit, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [noKnowledgeError, setNotKnowledgeError] = useState(false);
  const initialFieldValues = {
    profileName: (data && Object.keys(data).length) ? data.name : '',
    requiredKnowledges: (data && Object.keys(data).length)
      ? JSON.parse(data.requiredKnowledges)
      : [],
    deleteOutlineDialog: false,
    newKnowledge: '',
  };
  const [values, setValues] = useState(initialFieldValues);
  const creatingOutline = useSelector((state) => state.outline.creating);
  const deletingOutline = useSelector((state) => state.outline.deleting);
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const nameLabel = intl.formatMessage({ id: 'outline.name', defaultMessage: 'Nombre del perfil' });
  const requiredKnowledgesLabel = intl.formatMessage({ id: 'outline.requiredKnowledges', defaultMessage: 'Conocimientos requeridos' });
  const addRequiredKwnoledgesText = intl.formatMessage({ id: 'outline.addRequiredKnowledges', defaultMessage: 'Agregue los conocimientos requeridos para el perfil' });
  const deleteOutlineTitle = intl.formatMessage({ id: 'outline.delete.title', defaultMessage: 'Eliminar perfil' });
  const deleteOutlineButtonText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'Eliminar' });
  const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const deleteOutlineText = intl.formatMessage({ id: 'outline.deleteOutlineText', defaultMessage: '¿Está seguro que quiere eliminar el perfil?' });
  const deleteText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'eliminar' });
  const backText = intl.formatMessage({ id: 'app.back', defaultMessage: 'volver' });
  const saveText = intl.formatMessage({ id: 'app.save', defaultMessage: 'guardar' });
  const createText = intl.formatMessage({ id: 'app.create', defaultMessage: 'crear' });
  const knowledgeLabel = intl.formatMessage({ id: 'outlineEvaluation.knowledge', defaultMessage: 'Conocimiento' });
  const desiredLevelLabel = intl.formatMessage({ id: 'outlineEvaluation.desiredLevel', defaultMessage: 'Nivel deseado' });
  const noKnowledgeErrorLabel = intl.formatMessage({ id: 'validation.enterARequirement', defaultMessage: 'Ingrese un requerimiento' });

  let keyCount = 1;

  function handleSubmit(e) {
    e.preventDefault();
    const outline = {
      name: values.profileName,
      requiredKnowledges: JSON.stringify(values.requiredKnowledges),
    };

    if (data) { outline.id = data.id; }

    if (!edit) {
      dispatch(outlineActions.create(outline, '/outlines/'));
    } else {
      dispatch(outlineActions.update(outline, '/outlines/'));
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  }

  function handleDialogOpen(dialogName) {
    setValues({
      ...values,
      [dialogName]: true,
    });
  }

  function handleDialogClose(dialogName) {
    setValues({
      ...values,
      [dialogName]: false,
    });
  }

  function deleteOutline() {
    dispatch(outlineActions.deleteOutline(data.id, '/outlines'));
  }

  const handleOnChangeMinRequeridKnowledge = (newMinReq, knowledgeName) => {
    const indexFound = values.requiredKnowledges.findIndex((item) => item.name === knowledgeName);
    values.requiredKnowledges[indexFound].minRequired = newMinReq;
    setValues((prev) => ({ ...prev }));
  };

  const handleClickAddKnowledge = () => {
    if (values.newKnowledge !== '') {
      const auxNewKnowledge = { name: values.newKnowledge, minRequired: 2 };
      values.requiredKnowledges.push(auxNewKnowledge);
      setValues((prev) => ({ ...prev, newKnowledge: '' }));
      setNotKnowledgeError(false);
    } else {
      setNotKnowledgeError(true);
    }
  };

  const handleClickRemoveKnowledge = (item) => {
    const requiredKnowledgesAux = values.requiredKnowledges;
    const index = requiredKnowledgesAux.indexOf(item);
    if (index >= 0) {
      requiredKnowledgesAux.splice(index, 1);
      setValues({ ...values, requiredKnowledges: requiredKnowledgesAux });
    }
  };

  function getKey() {
    keyCount += 1;
    return keyCount;
  }

  return (
    <div className={classes.main}>
      <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Container>
              <TextValidator
                value={values.profileName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                id="profileName"
                label={nameLabel}
                name="profileName"
                size="small"
                validators={['required']}
                errorMessages={[validationConstants.OUTLINE_NAME_REQUIRED]}
              />
            </Container>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Container>
              <Typography variant="caption" gutterBottom>
                <strong style={{ fontSize: '14px' }}>{requiredKnowledgesLabel}</strong>
              </Typography>
              <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                {addRequiredKwnoledgesText}
              </Typography>
              <TextField
                error={noKnowledgeError}
                helperText={noKnowledgeError ? noKnowledgeErrorLabel : ''}
                value={values.newKnowledge}
                onChange={handleChange}
                fullWidth
                margin="normal"
                name="newKnowledge"
                label={requiredKnowledgesLabel}
                type="text"
                id="newKnowledge"
                style={{ marginTop: 0 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickAddKnowledge}
                        tabIndex="-1"
                      >
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <List className={classes.root}>
                <ListItem key={getKey()} disableGutters>
                  <ListItemText primary={knowledgeLabel} />
                  <Typography variant="body1" style={{ width: '260px' }}>
                    {desiredLevelLabel}
                  </Typography>
                </ListItem>
                {values.requiredKnowledges.map((item) => (
                  <ListItem key={getKey()} disableGutters>
                    <ListItemText primary={item.name} />
                    <CustomRatingOutline
                      knowledgeName={item.name}
                      knowledgeScore={item.minRequired}
                      handleOnChangeScoreKnowledge={handleOnChangeMinRequeridKnowledge}
                    />
                    <IconButton
                      onClick={() => handleClickRemoveKnowledge(item)}
                      tabIndex="-1"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Container>
          </Grid>
        </Grid>

        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
          <Button
            component={Link}
            to="/outlines"
            variant="outlined"
            text={backText}
            style={{ fontSize: '10px', fontWeight: 600 }}
          />
          {edit
            ? (
              <>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => { handleDialogOpen('deleteOutlineDialog'); }}
                  text={deleteText}
                  style={{ fontSize: '10px', fontWeight: 600 }}
                  disabled={!isAdmin}
                />
                <Button
                  type="submit"
                  text={saveText}
                  disabled={!isAdmin}
                  style={{ fontSize: '10px', fontWeight: 600 }}
                />
              </>
            )
            : (
              <>
                <Button
                  type="submit"
                  loading={creatingOutline}
                  text={createText}
                  disabled={!isAdmin}
                />
              </>
            )}

        </Box>
      </ValidatorForm>
      <Dialog open={values.deleteOutlineDialog} onClose={() => { handleDialogClose('deleteOutlineDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{deleteOutlineTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {deleteOutlineText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button text={cancelButtonText} onClick={() => { handleDialogClose('deleteOutlineDialog'); }} />
          <Button
            className={classes.menuButton}
            text={deleteOutlineButtonText}
            color="secondary"
            loading={deletingOutline}
            onClick={deleteOutline}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

OutlineForm.propTypes = {
  data: PropTypes.shape(),
  edit: PropTypes.bool,
};

OutlineForm.defaultProps = {
  data: {},
  edit: false,
};

export default OutlineForm;
