import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, makeStyles, Box, Grid,
  Typography, Container, MenuItem, Select, List, ListItem,
  IconButton, LinearProgress, InputLabel, FormControl,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import ClearIcon from '@material-ui/icons/Clear';
import { Autocomplete } from '@material-ui/lab';
import { workFieldHelper } from '../../helpers';
import {
  workFieldEvaluationActions, workFieldActions,
} from '../../redux/actions';
import Button from '../controls/Button';
import CustomRatingWorkField from '../controls/CustomRatingWorkField';
import { workFieldEvaluationTexts, workFieldTexts } from '../../constants';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  formControl: {
    minWidth: '100%',
  },
  textTitleItems: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '18px',
  },
  textSubtitleItems: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  getContentAnchorEl: null,
};

const WorkFieldEvaluationForm = ({
  edit, data, people, providers,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialFieldValues = {
    date: (data && data.date) ? data.date : new Date(),
    answers: (data && data.provider) ? JSON.parse(data.answers) : [],
    provider: (data && data.provider) ? data.provider : '',
    evaluatedWorkField: (data && data.workField) ? data.workField : null,
    deleteWorkFieldEvaluationDialog: false,
    responsiblePeople: (data && data.responsiblePeople) ? data.responsiblePeople : [],
    workFieldSelected: (data && data.workField) ? data.workField : '',
  };
  const creatingWorkFieldEvaluation = useSelector((state) => state.workFieldEvaluation.creating);
  const updatingWorkFieldEvaluation = useSelector((state) => state.workFieldEvaluation.updating);
  const deletingWorkFieldEvaluation = useSelector((state) => state.workFieldEvaluation.deleting);
  const workField = useSelector((state) => state.workField.data);
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const [values, setValues] = useState(initialFieldValues);
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    const providerHasWorkField = (
      values.provider
      && values.workFieldSelected
      && values.workFieldSelected.id !== 0);

    if (providerHasWorkField && providers
      && !values.evaluatedWorkField && values.responsiblePeople.length) {
      if (!workField || workField.id !== values.workFieldSelected.id) {
        dispatch(workFieldActions.get(values.workFieldSelected.id));
      }
      if (checked.length === 0) {
        const checkedArray = values.responsiblePeople.map((person) => {
          const auxWorkFieldItems = JSON.parse(values.workFieldSelected.workFieldItems)
            .map((item) => ({ ...item, score: 0 }));
          return { personId: person.id, answers: auxWorkFieldItems };
        });
        setChecked(checkedArray);
      }
    }

    if (edit && values.answers.length
      && checked.length === 0
      && values.responsiblePeople.length !== 0) {
      setChecked(values.answers);
    }
  }, [checked, dispatch, edit, providers, values.answers, values.evaluatedWorkField,
    values.provider, values.responsiblePeople, values.workFieldSelected, workField]);

  function handleSubmit(e) {
    e.preventDefault();
    const workFieldStatus = workFieldHelper.getWorkFieldStatus(values.answers, checked);
    const evaluationStatus = workFieldHelper.getEvaluationStatus(workFieldStatus);

    const workFieldEvaluation = {
      date: new Date(values.date).toISOString(),
      answers: JSON.stringify(values.answers),
      evaluationStatus,
      provider: { id: values.provider.id },
      workField: values.evaluatedWorkField
        ? { id: values.evaluatedWorkField.id }
        : { id: workField.id },
      responsiblePeople: values.responsiblePeople,
    };

    if (data) { workFieldEvaluation.id = data.id; }

    if (!edit) {
      dispatch(workFieldEvaluationActions.create(
        workFieldEvaluation, workFieldEvaluationTexts.successfulSave,
      ));
    } else {
      dispatch(workFieldEvaluationActions.update(
        workFieldEvaluation, workFieldEvaluationTexts.successfulUpdate,
      ));
    }
  }

  function handleChangeSelectedProvider(e) {
    const { name, value } = e.target;
    setValues({ ...values, workFieldSelected: '', [name]: value });
    setChecked([]);
  }

  function handleChangeSelect(e) {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setChecked([]);
  }

  function handleChangeSelectResponsiblePeople(e, newResponsiblePeople) {
    let newChecked = [];
    if (values.workFieldSelected && checked.length > newResponsiblePeople.length) {
      newChecked = checked.filter((item) => newResponsiblePeople
        .find((person) => person.id === item.personId));
    }
    if (values.workFieldSelected && checked.length < newResponsiblePeople.length) {
      const newPeople = newResponsiblePeople.filter((person) => !checked
        .find((item) => item.personId === person.id));
      const newNewWorkFieldItems = newPeople.map((person) => {
        const auxWorkFieldItems = JSON.parse(values.workFieldSelected.workFieldItems)
          .map((item) => ({ ...item, score: 0 }));
        return { personId: person.id, answers: auxWorkFieldItems };
      });
      newChecked = checked.concat(newNewWorkFieldItems);
    }
    if (values.workFieldSelected && checked.length !== 0 && newResponsiblePeople.length === 0) {
      newChecked = [];
    }

    setChecked(newChecked);
    setValues({ ...values, responsiblePeople: newResponsiblePeople, answers: newChecked });
  }

  function handleDialogOpen(dialogName) {
    setValues({ ...values, [dialogName]: true });
  }

  function handleDialogClose(dialogName) {
    setValues({ ...values, [dialogName]: false });
  }

  function deleteWorkFieldEvaluation() {
    dispatch(workFieldEvaluationActions.deleteWorkFieldEvaluation(data.id, '/workFieldEvaluations'));
  }

  function handleClr(e) {
    e.stopPropagation();
    setValues({ ...values, date: null });
  }

  const handleChangeScoreWorkFieldItem = (newScore, itemName, itemPersonId) => {
    const indexPerson = checked.findIndex((item) => item.personId === itemPersonId);
    const indexItem = checked[indexPerson].answers.findIndex((item) => item.name === itemName);
    checked[indexPerson].answers[indexItem].score = newScore;
    const currentAnswer = checked[indexPerson].answers[indexItem];

    const indexFoundPerson = values.answers.findIndex((item) => item.personId === itemPersonId);
    if (indexFoundPerson !== -1) {
      const indexFoundItem = values.answers[indexFoundPerson].answers
        .findIndex((item) => item.name === itemName);
      if (indexFoundItem !== -1) {
        values.answers[indexFoundPerson].answers[indexFoundItem].score = newScore;
      } else {
        values.answers[indexFoundPerson].answers.push(currentAnswer);
      }
    } else {
      values.answers.push({ personId: itemPersonId, answers: [currentAnswer] });
    }
    setValues((prev) => ({ ...prev }));
  };

  const submitButtonDisabled = () => {
    const selectedPerson = values.provider;
    const providerSelectedWithoutEvaluation = selectedPerson && !values.workFieldSelected;
    const atLeastOneEvaluated = checked.length
    && workFieldHelper.getWorkFieldStatus(values.answers, checked)
      .some((item) => item.result);
    const allEmptyAnswer = checked.map((person) => person.answers
      .every((item) => !item.score))
      .every((item) => item);

    return (!selectedPerson
      || providerSelectedWithoutEvaluation
      || !(atLeastOneEvaluated || allEmptyAnswer)
      || !isAdmin
    );
  };

  const saveButtonDisabled = () => {
    const atLeastOneEvaluated = checked.length
    && workFieldHelper.getWorkFieldStatus(values.answers, checked)
      .some((item) => item.result);
    return !isAdmin || !atLeastOneEvaluated;
  };

  const getMessageSelectWorkFieldOptions = (selectedPerson) => {
    if (selectedPerson) {
      const providerHasWorkField = selectedPerson.workFields.length;
      return providerHasWorkField
        ? workFieldEvaluationTexts.selectOption
        : workFieldEvaluationTexts.providerHasNotWorkField;
    }
    return workFieldEvaluationTexts.selectPersonRequired;
  };

  return (
    <div className={classes.main}>
      {((providers && providers.length !== 0) || edit) ? (
        <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Container>
                {edit
                  ? (
                    <>
                      <Typography variant="caption" gutterBottom style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {workFieldEvaluationTexts.providerEvaluated}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                        {values.provider.name}
                      </Typography>
                    </>
                  )
                  : (
                    <FormControl className={classes.formControl}>
                      <InputLabel id="provider">{workFieldEvaluationTexts.providerEvaluated}</InputLabel>
                      <Select
                        id="provider"
                        name="provider"
                        defaultValue={values.provider}
                        value={values.provider}
                        displayEmpty
                        onChange={handleChangeSelectedProvider}
                        MenuProps={MenuProps}
                        style={{ width: '100%' }}
                      >
                        {providers && providers.map((providerToSelect) => {
                          if (values.provider && (providerToSelect.id !== values.provider.id)) {
                            return (
                              <MenuItem key={providerToSelect.id} value={providerToSelect}>
                                {providerToSelect.name}
                              </MenuItem>
                            );
                          } if (values.provider) {
                            return (
                              <MenuItem key={values.provider.id} value={values.provider}>
                                {values.provider.name}
                              </MenuItem>
                            );
                          }
                          if (!values.provider) {
                            return (
                              <MenuItem key={providerToSelect.id} value={providerToSelect}>
                                {providerToSelect.name}
                              </MenuItem>
                            );
                          }
                          return null;
                        })}
                      </Select>
                    </FormControl>
                  )}
              </Container>
            </Grid>

            <Grid item xs={12} md={6}>
              <Container>
                {edit
                  ? (
                    <>
                      <Typography variant="caption" gutterBottom style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {workFieldEvaluationTexts.workFieldEvaluated}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                        {values.workFieldSelected.name}
                      </Typography>
                    </>
                  )
                  : (
                    <FormControl className={classes.formControl}>
                      <InputLabel id="workFieldSelected">{workFieldEvaluationTexts.workFieldEvaluated}</InputLabel>
                      <Select
                        id="workFieldSelected"
                        name="workFieldSelected"
                        defaultValue={values.workFieldSelected}
                        value={values.workFieldSelected}
                        displayEmpty
                        onChange={handleChangeSelect}
                        MenuProps={MenuProps}
                        style={{ width: '100%' }}
                      >
                        <MenuItem value={null} disabled>
                          <em>{getMessageSelectWorkFieldOptions(values.provider)}</em>
                        </MenuItem>
                        {
                          values.provider && values.provider.workFields.map((item) => (
                            <MenuItem key={item.workFieldId} value={item.workField}>
                              {item.workField.name}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  )}
              </Container>
            </Grid>

            <Grid item xs={12} md={6}>
              <Container>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    filterSelectedOptions
                    getOptionLabel={(option) => option.names}
                    getOptionSelected={(option, value) => option.id === value.id}
                    id="responsiblePeople-select"
                    multiple
                    options={people}
                    size="small"
                    loading={!people.length}
                    value={values.responsiblePeople}
                    onChange={handleChangeSelectResponsiblePeople}
                    renderOption={(option) => <Typography noWrap style={{ fontSize: '12px' }}>{`${option.names} ${option.surnames}`}</Typography>}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        value={values.responsiblePeople}
                        variant="standard"
                        label={workFieldEvaluationTexts.workFieldEvaluationInChargePerson}
                        placeholder="..."
                        style={{ fontSize: '12px' }}
                        validators={['required']}
                        errorMessages={[workFieldEvaluationTexts.responsibleRequiredErrorMessage]}
                      />
                    )}
                  />
                </FormControl>
              </Container>
            </Grid>

            <Grid item xs={12} md={6}>
              <Container>
                <DatePicker
                  value={values.date}
                  onChange={(value) => { setValues({ ...values, date: value }); }}
                  fullWidth
                  id="date"
                  label={workFieldEvaluationTexts.date}
                  name="date"
                  size="small"
                  format="dd/MM/yyyy"
                  InputProps={{
                    endAdornment: (
                      <IconButton style={{ padding: 5 }} onClick={(e) => handleClr(e)}>
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Container>
            </Grid>

            <Grid item xs={12} md={9}>
              <Container>
                {((!values.provider && !values.evaluatedWorkField)
                  || (values.provider && !values.workFieldSelected)
                  || (values.provider && values.workFieldSelected))
                  && !values.responsiblePeople.length
                  && (
                    <Typography variant="caption" display="block" gutterBottom style={{ fontSize: '14px', fontWeight: 'bold' }}>
                      {workFieldEvaluationTexts.workFieldItems}
                    </Typography>
                  )}

                {!values.provider && !values.evaluatedWorkField && (
                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                    {workFieldEvaluationTexts.selectPersonRequiredWorkFieldItems}
                  </Typography>
                )}

                {values.provider && !values.provider.workFields.length && !edit && (
                  <>
                    <Typography variant="subtitle1" display="inline" gutterBottom style={{ fontSize: '12px' }}>
                      {workFieldEvaluationTexts.noWorkField}
                    </Typography>
                    <Link to={`/providers/${values.provider.id}/edit`} style={{ textDecoration: 'none' }}>
                      <Typography variant="subtitle1" display="inline" gutterBottom style={{ fontSize: '12px', color: '#F16C24' }}>
                        {workFieldEvaluationTexts.createLink}
                      </Typography>
                    </Link>
                  </>
                )}

                {values.provider
                  && !!values.provider.workFields.length
                  && !values.workFieldSelected && (
                    <Typography variant="subtitle1" display="inline" gutterBottom style={{ fontSize: '12px' }}>
                      {workFieldEvaluationTexts.selectWorkFieldToEvaluate}
                    </Typography>
                )}

                {values.provider
                  && (values.workFieldSelected && values.workFieldSelected.id !== 0)
                  && (workField || values.evaluatedWorkField)
                  && values.responsiblePeople.length > 0
                  && (
                    <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                      {workFieldEvaluationTexts.checkApprovedWorkFieldItems}
                    </Typography>
                  )}

                {values.provider
                  && (values.workFieldSelected && values.workFieldSelected.id !== 0)
                  && !values.responsiblePeople.length
                  && (
                    <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                      {workFieldEvaluationTexts.responsibleRequiredErrorMessage}
                    </Typography>
                  )}

                {values.provider
                  && (values.workFieldSelected && values.workFieldSelected.id !== 0) && (
                    <List dense>
                      {checked.map((itemForPerson) => (
                        <Box key={itemForPerson.personId} pb={2}>
                          <ListItem disableGutters>
                            <Typography className={classes.textTitleItems} style={{ paddingTop: '8px', paddingBottom: '8px', minWidth: '300px' }}>
                              {workFieldEvaluationTexts.evaluator}
                              {': '}
                              {people && workFieldHelper.getNameForResponsibility(
                                people.find((person) => person.id === itemForPerson.personId),
                              )}
                            </Typography>
                          </ListItem>
                          <ListItem disableGutters>
                            <Typography className={classes.textTitleItems} style={{ minWidth: '300px' }}>
                              {workFieldTexts.itemsToBeEvaluated}
                            </Typography>
                            <Typography className={classes.textTitleItems} style={{ width: '220px' }}>
                              {workFieldTexts.weightingPercentage}
                            </Typography>
                            <Typography className={classes.textTitleItems} style={{ width: '260px' }} />
                          </ListItem>
                          {itemForPerson.answers.map((item) => (
                            <ListItem key={`${item.name}-${itemForPerson.personId}`} disableGutters>
                              <Typography className={classes.textTitleItems} style={{ minWidth: '300px' }}>{item.name}</Typography>
                              <Typography className={classes.textSubtitleItems} style={{ width: '220px' }}>{`${item.weightPercentage}%`}</Typography>
                              <CustomRatingWorkField
                                withColor
                                itemName={item.name}
                                itemScore={item.score}
                                itemToPersonId={itemForPerson.personId}
                                handleOnChangeScoreWorkFieldItem={handleChangeScoreWorkFieldItem}
                              />
                            </ListItem>
                          ))}
                        </Box>
                      ))}
                    </List>
                )}

              </Container>
            </Grid>

          </Grid>

          <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
            <Button
              component={Link}
              to="/workFieldEvaluations"
              variant="outlined"
              text={workFieldEvaluationTexts.back}
              style={{ fontSize: '10px', fontWeight: 600 }}
            />
            {edit
              ? (
                <>
                  <Button
                    color="secondary"
                    disabled={!isAdmin}
                    onClick={() => { handleDialogOpen('deleteWorkFieldEvaluationDialog'); }}
                    style={{ fontSize: '10px', fontWeight: 600 }}
                    text={workFieldEvaluationTexts.delete}
                    type="button"
                  />
                  <Button
                    disabled={saveButtonDisabled()}
                    loading={updatingWorkFieldEvaluation}
                    style={{ fontSize: '10px', fontWeight: 600 }}
                    text={workFieldEvaluationTexts.save}
                    type="submit"
                  />
                </>
              )
              : (
                <Button
                  disabled={submitButtonDisabled()}
                  loading={creatingWorkFieldEvaluation}
                  style={{ fontSize: '10px', fontWeight: 600 }}
                  text={workFieldEvaluationTexts.create}
                  type="submit"
                />
              )}

            <Dialog open={values.deleteWorkFieldEvaluationDialog} onClose={() => { handleDialogClose('deleteWorkFieldEvaluationDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">{workFieldEvaluationTexts.delete}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {workFieldEvaluationTexts.deleteWorkFieldEvaluation}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button text={workFieldEvaluationTexts.cancel} onClick={() => { handleDialogClose('deleteWorkFieldEvaluationDialog'); }} />
                <Button
                  text={workFieldEvaluationTexts.delete}
                  color="secondary"
                  loading={deletingWorkFieldEvaluation}
                  onClick={deleteWorkFieldEvaluation}
                />
              </DialogActions>
            </Dialog>
          </Box>
        </ValidatorForm>
      ) : <LinearProgress />}

    </div>
  );
};

WorkFieldEvaluationForm.propTypes = {
  data: PropTypes.shape(),
  edit: PropTypes.bool,
  people: PropTypes.arrayOf(PropTypes.shape()),
  providers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    workFields: PropTypes.arrayOf(PropTypes.shape({
      workFieldId: PropTypes.number,
      workField: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        workFieldItems: PropTypes.string,
      }),
    })),
    productsOrServicesOffered: PropTypes.string,
    cuit: PropTypes.string,
    businessName: PropTypes.string,
    address: PropTypes.string,
    contactPerson: PropTypes.string,
    email: PropTypes.string,
  })),
};

WorkFieldEvaluationForm.defaultProps = {
  data: {},
  edit: false,
  people: [],
  providers: [],
};

export default WorkFieldEvaluationForm;
