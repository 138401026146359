import { processConstants } from '../../constants';

function process(state = {}, action) {
  switch (action.type) {
    case processConstants.CREATE_REQUEST:
      return { creating: true };
    case processConstants.CREATE_SUCCESS:
      return { createdProcess: action.createdProcess };
    case processConstants.CREATE_FAILURE:
      return { creating: false };
    case processConstants.GET_REQUEST:
      return { requesting: true };
    case processConstants.GET_SUCCESS:
      return { data: action.process };
    case processConstants.GET_FAILURE:
      return {};
    case processConstants.GET_ALL_REQUEST:
      return { requesting: true };
    case processConstants.GET_ALL_SUCCESS:
      return { processes: action.processes };
    case processConstants.GET_ALL_FAILURE:
      return {};
    case processConstants.LINK_INTERESTED_PEOPLE_REQUEST:
      return { requesting: true };
    case processConstants.LINK_INTERESTED_PEOPLE_FAILURE:
      return {};
    case processConstants.LINK_RESPONSIBLE_PEOPLE_REQUEST:
      return { requesting: true };
    case processConstants.LINK_RESPONSIBLE_PEOPLE_FAILURE:
      return {};
    case processConstants.UPDATE_REQUEST:
      return { updating: true };
    case processConstants.UPDATE_SUCCESS:
      return { data: action.updatedProcess };
    case processConstants.UPDATE_FAILURE:
    case processConstants.CLEAN_CREATED_PROCESS:
      return {};
    default:
      return state;
  }
}

export default process;
