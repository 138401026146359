import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs, LinearProgress, Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PageLayout from '../../PageLayout';
import { objectiveActions } from '../../../redux/actions';
import ObjectiveViewDetail from './ObjectiveViewDetail';

const ObjectiveDetailPage = ({ routeParams: { id: objectiveId } }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const objective = useSelector((state) => state.objective.data);
  const title = intl.formatMessage({ id: 'objective.title', defaultMessage: 'Objetivos' });
  const objectiveDetails = intl.formatMessage({ id: 'objective.objectiveDetails', defaultMessage: 'Detalles' });

  function ObjectiveDetailBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/objectives" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{title}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{objectiveDetails}</Typography>
      </Breadcrumbs>
    );
  }

  function ObjectiveBreadcrumbs() {
    const link = `/objectives/${objectiveId}`;
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/objectives" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{title}</Typography>
        </Link>
        <Link color="inherit" to={link} style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{objective.title}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  useEffect(() => {
    dispatch(objectiveActions.getObjectives(objectiveId));
  }, [objectiveId, dispatch]);

  return (
    <>
      {objective
        ? (
          <PageLayout title={objective.title} breadCrumbs={<ObjectiveBreadcrumbs />}>
            <ObjectiveViewDetail data={objective} />
          </PageLayout>
        )
        : (
          <PageLayout title={title} breadCrumbs={<ObjectiveDetailBreadcrumbs />}>
            <LinearProgress />
          </PageLayout>
        )}
    </>
  );
};

ObjectiveDetailPage.propTypes = {
  routeParams: PropTypes.shape(),
};

ObjectiveDetailPage.defaultProps = {
  routeParams: {},
};

export default ObjectiveDetailPage;
