import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Breadcrumbs,
  Button as MuiButton,
  Container,
  Grid,
  Hidden,
  IconButton,
  LinearProgress,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  Clear as ClearIcon,
  VisibilityOutlined as VisibilityIcon
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import ReactQuill from 'react-quill';
import Dropzone from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import PropTypes from 'prop-types';
import moment from 'moment';

import { documentActions } from '../../../redux/actions';
import { DownloadIcon, UploadIcon } from '../../../assets/icons';
import {
  initialDocumentState,
  getActualVersion,
  handleClickOnDocument
} from '../../../common/constants';
import { personService } from '../../../services';
import { renderFileSize } from '../../../helpers';
import { validationConstants } from '../../../constants';
import Button from '../../controls/Button';
import DocumentPreviewModal from '../../controls/Modals/DocumentPreview';
import FileIcon from '../../../assets/icons/FileIcon.png';
import PageLayout from '../../PageLayout';
import useStyles from './useStyles';
import {
  formats,
  initialFieldValues,
  modules,
} from './constants';

const NewVersionDocumentPage = ({ routeParams: { id: documentId } }) => {
  const classes = useStyles();
  const { roles } = useSelector((state) => state.authentication.user);
  const creatingNewVersionDocument = useSelector((state) => state.document.creating);
  const document = useSelector((state) => state.document.data);
  const dispatch = useDispatch();
  const intl = useIntl();

  const [componentMounted, setComponentMounted] = useState(false);
  const [documentState, setDocumentState] = useState(initialDocumentState);
  const [documentWithFile, setDocumentWithFile] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [people, setPeople] = useState([]);
  const [values, setValues] = useState(initialFieldValues);

  const isAdmin = roles.includes('Admin');

  const documentText = intl.formatMessage({ id: 'documents.title_pl', defaultMessage: 'Documentos' });
  const downloadLastVersionText = intl.formatMessage({ id: 'document.downloadLastVersion', defaultMessage: 'Descargar ultima versión del documento' });
  const nameLabel = intl.formatMessage({ id: 'document.nameForm', defaultMessage: 'Nombre versión (opcional)' });
  const newVersionTitle = intl.formatMessage({ id: 'document.newVersionTitle', defaultMessage: 'Nueva Versión' });
  const previewDocument = intl.formatMessage({ id: 'document.preview', defaultMessage: 'Vista previa' });
  const reviewerUserNamesLabel = intl.formatMessage({ id: 'document.reviewerUserNames', defaultMessage: 'Revisor' });
  const selectOption = intl.formatMessage({ id: 'document.selectOption', defaultMessage: 'Seleccione una opción' });
  const titleLabel = intl.formatMessage({ id: 'document.titleForm', defaultMessage: 'Título del documento' });
  const uploadRestrictionText = intl.formatMessage({ id: 'document.restriction', defaultMessage: 'Un archivo máximo de 10 MB, formatos permitidos pdf, txt, doc' });
  const versionMissingContentText = intl.formatMessage({ id: 'document.newVersion.missingContent', defaultMessage: 'Ingrese el contenido correspondiente a la nueva versión' });
  const versionMissingFileText = intl.formatMessage({ id: 'document.newVersion.missingFile', defaultMessage: 'Ingrese el archivo correspondiente a la nueva versión' });
  const versionsLabel = intl.formatMessage({ id: 'app.previousVersions', defaultMessage: 'Versiones Anteriores' });

  const documentAuthor = <FormattedMessage id="document.author" defaultMessage="Autor" />;
  const documentChangeDetails = <FormattedMessage id="document.changeDetails" defaultMessage="Detalle de cambios" />;
  const documentDate = <FormattedMessage id="document.date" defaultMessage="Fecha y hora" />;
  const documentTitle = <FormattedMessage id="document.tableTitle" defaultMessage="Título" />;

  const { title, versionName, editableHtml, file, comment, versionMissing, reviewerId } = values;

  const NewVersionDocumentBreadcrumbs = () => {
    const documentLink = `/documents/${documentId}`;

    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/documents" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{documentText}</Typography>
        </Link>
        <Link color="inherit" to={documentLink} style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{document.title}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{newVersionTitle}</strong></Typography>
      </Breadcrumbs>
    );
  };

  const NewVersionDocumentLoadingBreadcrumbs = () => (
    <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
      <Link color="inherit" to="/" style={{ textDecoration: 'none' }}>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{documentText}</Typography>
      </Link>
      <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{newVersionTitle}</strong></Typography>
    </Breadcrumbs>
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const fd = new FormData();

    if ((documentWithFile && !file) || (!documentWithFile && !editableHtml)) {
      setValues({ ...values, versionMissing: true });
    } else {
      fd.append('title', title);
      fd.append('editableHtml', editableHtml);
      fd.append('file', file);
      fd.append('comment', comment);
      fd.append('version', document.actualDocumentVersionId + 1);
      fd.append('reviewerId', Number(reviewerId));

      if (!versionName) {
        fd.append('versionName', document.actualDocumentVersionId + 1);
      } else {
        fd.append('versionName', versionName);
      }

      fd.append('id', documentId);
      dispatch(documentActions.newVersion(fd, documentId, document.type));
    }
  };

  const handleChangeQuill = (html) => setValues({ ...values, editableHtml: html });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleDownloadAction = (id) => {
    const actualVersion = getActualVersion(document);
    const filename = `${document.title}.${actualVersion.extension}`;

    dispatch(documentActions.download(id, actualVersion.contentType, filename));
  };

  const getFilesFromEvent = (e) => new Promise((resolve) => {
    getDroppedOrSelectedFiles(e).then((chosenFiles) => {
      resolve(chosenFiles.map((f) => f.fileObject));
    });
  });

  const handleChangeStatus = ({ file: fileChanged }, status) => {
    if (status === 'done') {
      setValues({
        ...values, file: fileChanged,
      });
    }
  };

  const InputLoaded = (meta) => {
    const fileExtension = file ? file.name.split('.').pop() : '';

    const handleClearFile = () => {
      if (meta.files.length) {
        meta.fileWithMeta.remove();
      }
      setValues({ ...values, file: '' });
    };

    return (
      <div className={classes.uploadContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} pt={5} mt={5}>
            <img className={classes.fileIcon} src={FileIcon} alt="File" />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{
              fontSize: '10px', color: '#000000', fontWeight: '500', lineHeight: '18px',
            }}
            >
              {file.name}
              <IconButton onClick={handleClearFile} style={{ textDecoration: 'none', padding: '0px' }}>
                <ClearIcon className={classes.clearIcon} />
              </IconButton>
            </Typography>
            <Typography style={{
              fontSize: '8px', color: '#757575', fontWeight: '500', lineHeight: '18px', textAlign: 'center',
            }}
            >
              {renderFileSize(file.size, fileExtension)}
            </Typography>
            <Box display="flex" className={classes.successAlertContainer}>
              <CheckCircleIcon className={classes.checkIcon} />
              <Typography className={classes.successAlert}>
                {intl.formatMessage({ id: 'document.upload_success', defaultMessage: 'El archivo se subió correctamente' })}
              </Typography>
            </Box>
          </Grid>

        </Grid>
      </div>
    );
  };

  const Input = ({ onFiles, file: fileInput, getFilesFromEvent: getFilesFromEventInput }) => {
    const uploadText = intl.formatMessage({ id: 'app.upload_file', defaultMessage: 'SUBIR ARCHIVO' });

    const handleChangeFile = (e) => {
      getFilesFromEventInput(e).then((chosenFiles) => {
        onFiles(chosenFiles);
        setValues({
          ...values, file: chosenFiles[0],
        });
      });
    };

    return (
      <div className={classes.uploadContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} pt={5} mt={5}>
            <img className={classes.fileIcon} src={FileIcon} alt="File" />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="file">
              <Box display="flex" justifyContent="center" className={classes.uploadButton}>
                <UploadIcon display="inline" />
                <Typography display="inline" style={{ fontSize: 11 }}>{uploadText}</Typography>
                <input
                  style={{ display: 'none' }}
                  id="file"
                  name="file"
                  value={fileInput}
                  type="file"
                  onChange={handleChangeFile}
                />
              </Box>
            </label>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" style={{ fontSize: '9px', color: '#757575' }}>
              {uploadRestrictionText}
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const responsePersonService = await personService.getAll();

      setPeople(responsePersonService);
    };

    if (!componentMounted) {
      fetchData();
      dispatch(documentActions.get(documentId));
      setComponentMounted(true);
    }

    if (document) {
      const actualVersion = getActualVersion(document);

      if (actualVersion.extension) {
        setDocumentWithFile(true);
      }

      setValues({
        comment: '',
        editableHtml: '',
        file: '',
        reviewerId: document.reviewerId ? String(document.reviewerId) : '',
        title: document.title ? document.title : '',
        versions: document.versions ? document.versions : [],
      });
    }
  }, [dispatch, document, documentId, componentMounted]);

  return (
    <PageLayout
      title={newVersionTitle}
      breadCrumbs={document
        ? <NewVersionDocumentBreadcrumbs />
        : <NewVersionDocumentLoadingBreadcrumbs />}
    >
      {document ? (
        <div className={classes.main}>
          <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} md={4}>
                <Container>
                  <TextValidator
                    value={title}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    id="title"
                    label={titleLabel}
                    name="title"
                    size="small"
                    validators={['required']}
                    errorMessages={[validationConstants.DOCUMENT_TITLE_REQUIRED]}
                  />
                </Container>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Container>
                  <TextValidator
                    value={versionName}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    id="versionName"
                    label={nameLabel}
                    name="versionName"
                    size="small"
                  />
                </Container>
              </Grid>
              <Hidden smDown>
                <Grid item md={1} />
              </Hidden>
              <Grid item xs={12} sm={6} md={4}>
                <Container>
                  <SelectValidator
                    className={classes.formControl}
                    onChange={handleChange}
                    value={reviewerId}
                    fullWidth
                    id="reviewerId"
                    label={reviewerUserNamesLabel}
                    name="reviewerId"
                    size="small"
                    validators={['required']}
                    errorMessages={[validationConstants.DOCUMENT_REVIEWER_REQUIRED]}
                  >
                    <MenuItem style={{ fontSize: '12px' }} value={null}>
                      <em>{selectOption}</em>
                    </MenuItem>
                    {people.map((item) => {
                      if (!item.names) return null;
                      return (
                        <MenuItem style={{ fontSize: '12px' }} key={item.id} value={String(item.id)}>
                          {`${item.names} ${item.surnames}`}
                        </MenuItem>
                      );
                    })}
                  </SelectValidator>
                </Container>
              </Grid>
              <Grid item xs={12} sm={12} md={9} style={{ paddingLeft: '36px' }}>
                {documentWithFile
                  ? (
                    <Container>
                      <Dropzone
                        style={{ height: '400px' }}
                        InputComponent={file ? InputLoaded : Input}
                        PreviewComponent={InputLoaded}
                        getFilesFromEvent={getFilesFromEvent}
                        onChangeStatus={handleChangeStatus}
                        maxFiles={1}
                      />
                    </Container>
                  ) : <ReactQuill modules={modules} formats={formats} className={classes.quill} value={editableHtml} name="editableHtml" id="editableHtml" onChange={handleChangeQuill} />}
                {documentWithFile && (
                  <Box style={{ paddingLeft: '36px' }}>
                    <DownloadIcon className={classes.downloadLastVersionIcon} display="inline" />
                    <MuiButton color="primary" onClick={() => { handleDownloadAction(document.id); }} display="inline" className={classes.linkDocuments} style={{ paddingLeft: '10px', textDecoration: 'none' }}>{downloadLastVersionText}</MuiButton>
                  </Box>
                )}
                {versionMissing && <span style={{ marginTop: !documentWithFile ? '50px' : '' }} className={classes.fileError}>{documentWithFile ? versionMissingFileText : versionMissingContentText}</span>}

              </Grid>

              <Grid item xs={3}>
                <Box className={classes.paper} style={{ padding: '17px 25px' }}>
                  <Typography variant="caption" color="textPrimary" style={{ weight: 600, fontSize: '12px' }}>
                    <strong><FormattedMessage id="document.changeDetails" defaultMessage="Detalle de cambios" /></strong>
                  </Typography>
                  <TextField
                    value={comment}
                    name="comment"
                    id="comment"
                    multiline
                    minRows={5}
                    onChange={handleChange}
                    style={{ background: '#fff', marginTop: '12px', padding: '13px 0 0 0px' }}
                  />
                  <Box xs={12}>
                    <Typography variant="caption" style={{ weight: 400, fontSize: '10px', color: 'Black 54%' }}>
                      <FormattedMessage id="document.helpChangeDetails" defaultMessage="Describa brevemente los cambios que ha realizado." />
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              {document.versions && (
                <Grid item xs={12} sm={12} style={{ padding: '36px' }}>
                  <Typography variant="h6" gutterBottom style={{ fontSize: '14px' }}>
                    <strong>{versionsLabel}</strong>
                  </Typography>
                  <Box>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow style={{ fontSize: '12px' }}>
                          <TableCell size="small" style={{ paddingLeft: '0px' }}><strong>{documentTitle}</strong></TableCell>
                          <TableCell size="small"><strong>{documentDate}</strong></TableCell>
                          <TableCell size="small"><strong>{documentAuthor}</strong></TableCell>
                          <TableCell size="small"><strong>{reviewerUserNamesLabel}</strong></TableCell>
                          <TableCell size="small"><strong>{documentChangeDetails}</strong></TableCell>
                          <TableCell> </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {document.versions.map((version) => (
                          <TableRow key={version.id} style={{ fontSize: '12px' }}>
                            <TableCell size="small" style={{ paddingLeft: '0px' }}>{version.versionName}</TableCell>
                            <TableCell size="small">{moment(version.creationDate).format('DD-MM-YYYY HH:mm:ss')}</TableCell>
                            <TableCell size="small">{version.creatorUserNames}</TableCell>
                            <TableCell size="small">{version.reviewerUserNames}</TableCell>
                            <TableCell size="small">{(version.comment !== 'undefined') ? version.comment : ''}</TableCell>
                            <TableCell size="small">
                              <IconButton
                                style={{ textDecoration: 'none', color: '#373435' }}
                                onClick={() => handleClickOnDocument({
                                  document,
                                  setDocumentState,
                                  setIsPreviewModalOpen,
                                  version
                                })}
                              >
                                <Tooltip title={previewDocument}>
                                  <VisibilityIcon className={classes.actionIcon} />
                                </Tooltip>
                              </IconButton>
                              {(version.contentType) && (
                                <IconButton onClick={() => handleDownloadAction(document.id)} style={{ textDecoration: 'none', color: '#373435' }}>
                                  <DownloadIcon className={classes.actionIcon} />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Grid>
              )}
            </Grid>

            <Box display="flex" alignItems="center" justifyContent="center" mt={5}>
              <Button component={Link} to={`/documents/${documentId}`} variant="outlined" text={<FormattedMessage id="app.cancel" defaultMessage="Cancelar" />} />
              <Button disabled={!isAdmin} loading={creatingNewVersionDocument} type="submit" text={<FormattedMessage id="app.save" defaultMessage="Guardar" />} />
            </Box>
          </ValidatorForm>
          <DocumentPreviewModal
            documentState={documentState}
            handleClose={() => setIsPreviewModalOpen(false)}
            isOpen={isPreviewModalOpen}
          />
        </div>
      ) : <LinearProgress />}

    </PageLayout>
  );
}

NewVersionDocumentPage.propTypes = {
  routeParams: PropTypes.shape(),
};

NewVersionDocumentPage.defaultProps = {
  routeParams: {},
};

export default NewVersionDocumentPage;