import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { providerActions } from '../../../redux/actions';
import BulkCreationResult from '../../BulkUpload/BulkCreationResult';

const useStyles = makeStyles((theme) => ({
  paddingNone: {
    padding: theme.spacing(0),
  },
  paddingItem: {
    paddingLeft: theme.spacing(0),
  },
  paddingTitles: {
    padding: '6px 0px 6px 12px',
  },
}));

const ProviderBulkCreationResult = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const provider = useSelector((state) => state.provider);

  const tableErrors = [
    {
      id: 1,
      row: 'row',
      name: intl.formatMessage({ id: 'providers.row', defaultMessage: 'Fila' }),
      classesHead: classes.paddingNone,
      classesBody: classes.paddingItem,
    },
    {
      id: 2,
      row: 'name',
      name: intl.formatMessage({ id: 'providers.name', defaultMessage: 'Nombre' }),
      classesHead: classes.paddingTitles,
    },
    {
      id: 3,
      row: 'productsOrServicesOffered',
      name: intl.formatMessage({ id: 'providers.productsOrServicesOffered', defaultMessage: 'Producto o servicio ofrecido' }),
      classesHead: classes.paddingTitles,
    },
    {
      id: 4,
      row: 'businessName',
      name: intl.formatMessage({ id: 'providers.businessName', defaultMessage: 'Razón Social' }),
      classesHead: classes.paddingTitles,
    },
    {
      id: 5,
      row: 'cuit',
      name: intl.formatMessage({ id: 'providers.cuit', defaultMessage: 'CUIT' }),
      classesHead: classes.paddingTitles,
    },
    {
      id: 6,
      row: 'address',
      name: intl.formatMessage({ id: 'providers.address', defaultMessage: 'Dirección' }),
      classesHead: classes.paddingTitles,
    },
    {
      id: 7,
      row: 'contactPerson',
      name: intl.formatMessage({ id: 'providers.contactPerson', defaultMessage: 'Persona (contacto)' }),
      classesHead: classes.paddingTitles,
    },
    {
      id: 8,
      row: 'email',
      name: intl.formatMessage({ id: 'providers.email', defaultMessage: 'Email (contacto)' }),
      classesHead: classes.paddingTitles,
    },
  ];
  const tableLoaded = [
    {
      id: 1,
      row: 'name',
      name: intl.formatMessage({ id: 'providers.name', defaultMessage: 'Nombre' }),
      classesHead: classes.paddingTitles,
    },
    {
      id: 2,
      row: 'productsOrServicesOffered',
      name: intl.formatMessage({ id: 'providers.productsOrServicesOffered', defaultMessage: 'Producto o servicio ofrecido' }),
      classesHead: classes.paddingTitles,
    },
    {
      id: 3,
      row: 'businessName',
      name: intl.formatMessage({ id: 'providers.businessName', defaultMessage: 'Razón Social' }),
      classesHead: classes.paddingTitles,
    },
    {
      id: 4,
      row: 'cuit',
      name: intl.formatMessage({ id: 'providers.cuit', defaultMessage: 'CUIT' }),
      classesHead: classes.paddingTitles,
    },
    {
      id: 5,
      row: 'address',
      name: intl.formatMessage({ id: 'providers.address', defaultMessage: 'Dirección' }),
      classesHead: classes.paddingTitles,
    },
    {
      id: 6,
      row: 'contactPerson',
      name: intl.formatMessage({ id: 'providers.contactPerson', defaultMessage: 'Persona (contacto)' }),
      classesHead: classes.paddingTitles,
    },
    {
      id: 7,
      row: 'email',
      name: intl.formatMessage({ id: 'providers.email', defaultMessage: 'Email (contacto)' }),
      classesHead: classes.paddingTitles,
    },
  ];

  const linkRedirect = '/providers/upload';
  const linkEntity = '/providers';
  const text = {
    bulkCreationBreadcrumbs: intl.formatMessage({ id: 'providers.bulkCreationBreadcrumbs', defaultMessage: 'Carga múltiple' }),
    bulkNotCreated: intl.formatMessage({ id: 'providers.bulkProvidersNotCreated', defaultMessage: 'Estos proveedores no serán creados' }),
    bulkUploadedSuccess: intl.formatMessage({ id: 'providers.bulkProvidersUploadedSuccess', defaultMessage: 'Proveedores cargados correctamente:' }),
    create: intl.formatMessage({ id: 'providers.createBulkProviders', defaultMessage: 'Crear proveedores' }),
    emptyListSuccessfulUpload: intl.formatMessage({ id: 'providers.emptyListSuccessfulUploadProviders', defaultMessage: 'No hay proveedores cargados correctamente, por favor vuelva a cargar los datos respetando el archivo de ejemplo.' }),
    messageUploadedSuccess: intl.formatMessage({ id: 'providers.messageUploadedSuccess', defaultMessage: 'Los proveedores se han agregado correctamente' }),
    onlySuccessUploadWillBeCreated: intl.formatMessage({ id: 'providers.onlySuccessUploadProvidersWillBeCreated', defaultMessage: 'Solo se crearán los proveedores cargados correctamente.' }),
    title: intl.formatMessage({ id: 'providers.title', defaultMessage: 'Proveedores' }),
  };
  function handleConfirmProviders() {
    return (dispatch(
      providerActions.confirmCreateBulk(
        provider.bulkCreationPreviewResult.id, text.messageUploadedSuccess,
      ),
    ));
  }

  return (
    <BulkCreationResult
      entity={provider}
      handleConfirmResult={handleConfirmProviders}
      linkEntity={linkEntity}
      linkRedirect={linkRedirect}
      tableErrors={tableErrors}
      tableLoaded={tableLoaded}
      text={text}
    />
  );
};

export default ProviderBulkCreationResult;
