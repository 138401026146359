import { riskConstants } from '../../constants';

function risk(state = {}, action) {
  switch (action.type) {
    case riskConstants.CREATE_REQUEST:
      return { creating: true };
    case riskConstants.CREATE_SUCCESS:
      return { createdRisk: action.createdRisk };
    case riskConstants.CREATE_FAILURE:
      return { error: action.error };

    case riskConstants.GET_REQUEST:
      return { getting: true };
    case riskConstants.GET_SUCCESS:
      return { data: action.risk };
    case riskConstants.GET_FAILURE:
      return { error: action.error };

    case riskConstants.GET_ALL_REQUEST:
      return { getting: true };
    case riskConstants.GET_ALL_SUCCESS:
      return { risks: action.risks };
    case riskConstants.GET_ALL_FAILURE:
      return { error: action.error };

    case riskConstants.UPDATE_REQUEST:
      return { getting: true };
    case riskConstants.UPDATE_SUCCESS:
      return { data: action.updatedRisk };
    case riskConstants.UPDATE_FAILURE:
      return { error: action.error };

    case riskConstants.DELETE_REQUEST:
      return { deleting: true };
    case riskConstants.DELETE_SUCCESS:
      return { };
    case riskConstants.DELETE_FAILURE:
      return {};

    case riskConstants.CREATE_BULK_REQUEST:
      return { creating: true };
    case riskConstants.CREATE_BULK_SUCCESS:
      return { bulkCreationPreviewResult: action.bulkCreationPreviewResult };
    case riskConstants.CREATE_BULK_FAILURE:
      return { error: action.error };

    case riskConstants.CONFIRM_CREATE_BULK_REQUEST:
      return { ...state, confirming: true };
    case riskConstants.CONFIRM_CREATE_BULK_SUCCESS:
      return { bulkCreationPreviewResult: null, noLinkedProcesses: true };
    case riskConstants.CONFIRM_CREATE_BULK_FAILURE:
      return { error: action.error };

    default:
      return state;
  }
}

export default risk;
