import { workFieldEvaluationConstants } from '../../constants';

function workFieldEvaluation(state = {}, action) {
  switch (action.type) {
    case workFieldEvaluationConstants.CREATE_REQUEST:
      return { data: state.data, creating: true };
    case workFieldEvaluationConstants.CREATE_SUCCESS:
      return { createdOutline: action.entity };
    case workFieldEvaluationConstants.CREATE_FAILURE:
      return { error: action.error };

    case workFieldEvaluationConstants.GET_REQUEST:
      return { getting: true };
    case workFieldEvaluationConstants.GET_SUCCESS:
      return { data: action.workFieldEvaluation };
    case workFieldEvaluationConstants.GET_FAILURE:
      return { error: action.error };

    case workFieldEvaluationConstants.GET_ALL_REQUEST:
      return { getting: true };
    case workFieldEvaluationConstants.GET_ALL_SUCCESS:
      return { workFieldEvaluations: action.workFieldEvaluations };
    case workFieldEvaluationConstants.GET_ALL_FAILURE:
      return { error: action.error };

    case workFieldEvaluationConstants.GET_ALL_UNAPPROVED_REQUEST:
      return { getting: true };
    case workFieldEvaluationConstants.GET_ALL_UNAPPROVED_SUCCESS:
      return { workFieldEvaluations: action.workFieldEvaluations };
    case workFieldEvaluationConstants.GET_ALL_UNAPPROVED_FAILURE:
      return { error: action.error };

    case workFieldEvaluationConstants.UPDATE_REQUEST:
      return { data: state.data, updating: true };
    case workFieldEvaluationConstants.UPDATE_SUCCESS:
      return { data: action.entity };
    case workFieldEvaluationConstants.UPDATE_FAILURE:
      return { error: action.error };

    case workFieldEvaluationConstants.DELETE_REQUEST:
      return { deleting: true };
    case workFieldEvaluationConstants.DELETE_SUCCESS:
      return { deleted: true };
    case workFieldEvaluationConstants.DELETE_FAILURE:
      return {};

    default:
      return state;
  }
}

export default workFieldEvaluation;
