import React, { useState } from 'react';
import {
  Box,
  Breadcrumbs,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import GetAppIcon from '@material-ui/icons/GetApp';

import PageLayout from '../PageLayout';
import BulkCreationDropzone from '../controls/BulkCreationDropzone';
import Button from '../controls/Button';
import VideoTutorialModal from '../controls/VideoTutorialModal';
import { videoTutorialId } from '../../constants';

const useStyles = makeStyles(() => ({
  textSubtitle: {
    fontSize: 12,
    paddingTop: 14,
    paddingBottom: 6,
  },
  textStep: {
    fontSize: 12,
    fontWeight: 600,
    paddingTop: 4,
  },
}));

const BulkCreation = ({
  handleDispatcher,
  linkDownload,
  linkEntity,
  text
}) => {
  const classes = useStyles();
  const [fileUpload, setFileUpload] = useState(null);

  const buttonDownloadSample = (
    <>
      <GetAppIcon style={{ marginRight: 8 }} />
      {text.downloadListExample}
    </>
  );

  function NewBulkUploadBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to={linkEntity} style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{text.title}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{text.bulkCreationBreadcrumbs}</Typography>
      </Breadcrumbs>
    );
  }

  return (
    <PageLayout
      title={text.titlePageLayout}
      breadCrumbs={<NewBulkUploadBreadcrumbs />}
    >
      <Box pl={3}>
        <Box sx={{ display: 'flex', gap: '12px' }}>
          <Typography variant="h6" style={{ fontSize: '18px', fontWeight: 700 }}>
            {text.bulkCreationTitle}
          </Typography>
          <VideoTutorialModal
            title={<FormattedMessage id="videoTutorial.riskMassiveUploadTitle" defaultMessage="Carga masiva de riesgos" />}
            videoId={videoTutorialId.RISK_MASSIVE_UPLOAD}
          />
        </Box>
        <Typography variant="body1" className={classes.textSubtitle}>{text.bulkCreationSubtitle}</Typography>
        <Button
          text={buttonDownloadSample}
          variant="outlined"
          href={linkDownload}
          target="_blank"
          download
          style={{ fontSize: '11px', fontWeight: 600 }}
        />
        <Typography variant="body1" className={classes.textSubtitle}>{text.bulkCreationSteps}</Typography>
        <Typography variant="h5" className={classes.textStep}>{text.bulkCreationStep1}</Typography>
        <Typography variant="h5" className={classes.textStep}>{text.bulkCreationStep2}</Typography>
        <Typography variant="h5" className={classes.textStep}>{text.bulkCreationStep3}</Typography>
        <BulkCreationDropzone
          setFileUpload={setFileUpload}
          fileUpload={fileUpload}
          handleDispatcher={handleDispatcher}
        />
      </Box>
    </PageLayout>
  );
};

BulkCreation.propTypes = {
  text: PropTypes.shape(),
  linkEntity: PropTypes.string,
  linkDownload: PropTypes.string,
  handleDispatcher: PropTypes.func.isRequired,
};

BulkCreation.defaultProps = {
  text: {},
  linkEntity: '',
  linkDownload: '',
};

export default BulkCreation;
