import axios from '../axios/axios';
import { authHeader, userCompanySelectorHelper } from '../helpers';
import handler from './handler';

function addDeviation(deviation) {
  const headers = authHeader();
  const company = userCompanySelectorHelper.getCurrentCompany();
  const postUrl = `companies/${company.id}/deviations`;
  return axios.post(postUrl, deviation, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAllDeviations() {
  const headers = authHeader();
  const company = userCompanySelectorHelper.getCurrentCompany();
  const getUrl = `companies/${company.id}/deviations`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getDeviations(id) {
  const headers = authHeader();
  const company = userCompanySelectorHelper.getCurrentCompany();
  const getUrl = `companies/${company.id}/deviations/${id}`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function updateDeviation(id, deviation) {
  const headers = authHeader();
  const company = userCompanySelectorHelper.getCurrentCompany();
  const updateUrl = `companies/${company.id}/deviations/${id}`;
  return axios.put(updateUrl, deviation, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function createBulkDeviations(deviationsExcelFile) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `companies/${company.id}/deviations/bulk`;
  return axios.post(postUrl, deviationsExcelFile, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function confirmCreateBulkDeviations(id) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `companies/${company.id}/deviations/bulk/${id}`;
  return axios.post(postUrl, {}, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function deleteDeviations(id) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const deleteUrl = `companies/${company.id}/deviations/${id}`;
  return axios.delete(deleteUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const companyService = {
  addDeviation,
  deleteDeviations,
  getAllDeviations,
  getDeviations,
  updateDeviation,
  createBulkDeviations,
  confirmCreateBulkDeviations,
};

export default companyService;
