import React from 'react';
import { useIntl } from 'react-intl';
import {
  Breadcrumbs, LinearProgress, Typography,
} from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageLayout from '../PageLayout';
import CompanySubscriptionConfig from './CompanySubscriptionConfig/CompanySubscriptionConfig';
import { alertActions } from '../../redux/actions';

function CompanyConfigPage() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const title = intl.formatMessage({ id: 'company.configuration', defaultMessage: 'Configuración de empresa' });
  const titleDataTable = intl.formatMessage({ id: 'user.company', defaultMessage: 'Empresa' });

  function CompanyConfigPageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/user/profile" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{title}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{company.data.name}</Typography>
      </Breadcrumbs>
    );
  }

  function LoadingCompanyConfigPageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/user/profile" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{title}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{titleDataTable}</Typography>
      </Breadcrumbs>
    );
  }

  if (!isAdmin) {
    const message = intl.formatMessage({ id: 'error.UserCanNotPerformTheAction', defaultMessage: 'El usuario no puede realizar la acción debido a sus roles asignados.' });
    dispatch(alertActions.error(message));
    return <Redirect to={{ pathname: '/home' }} />;
  }

  return (
    <>
      {company.data
        ? (
          <PageLayout
            title={company.data.name}
            breadCrumbs={<CompanyConfigPageBreadcrumbs />}
          >
            <CompanySubscriptionConfig company={company} />
          </PageLayout>
        )
        : (
          <PageLayout
            title={title}
            breadCrumbs={<LoadingCompanyConfigPageBreadcrumbs />}
          >
            <LinearProgress />
          </PageLayout>
        )}

    </>
  );
}

export default CompanyConfigPage;
