const outlineEvaluationConstants = {
  CREATE_REQUEST: 'OUTLINE_EVALUATION_CREATE_REQUEST',
  CREATE_SUCCESS: 'OUTLINE_EVALUATION_CREATE_SUCCESS',
  CREATE_FAILURE: 'OUTLINE_EVALUATION_CREATE_FAILURE',

  GET_REQUEST: 'OUTLINE_EVALUATION_GET_REQUEST',
  GET_SUCCESS: 'OUTLINE_EVALUATION_GET_SUCCESS',
  GET_FAILURE: 'OUTLINE_EVALUATION_GET_FAILURE',

  GET_ALL_REQUEST: 'OUTLINE_EVALUATION_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'OUTLINE_EVALUATION_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'OUTLINE_EVALUATION_GET_ALL_FAILURE',

  UPDATE_REQUEST: 'OUTLINE_EVALUATION_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'OUTLINE_EVALUATION_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'OUTLINE_EVALUATION_UPDATE_FAILURE',

  DELETE_REQUEST: 'OUTLINE_EVALUATION_DELETE_REQUEST',
  DELETE_SUCCESS: 'OUTLINE_EVALUATION_DELETE_SUCCESS',
  DELETE_FAILURE: 'OUTLINE_EVALUATION_DELETE_FAILURE',

  CLEAN_CREATED_OUTLINE_EVALUATION: 'CLEAN_CREATED_OUTLINE_EVALUATION',

  FOR_REVISION: 0,
  APPROVED: 1,
  PENDING: 2,
};

export default outlineEvaluationConstants;
