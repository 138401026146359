import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Container,
  Divider,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import moment from 'moment';

import { personActions, riskActions } from '../../../redux/actions';

import Button from '../../controls/Button';

import validationConstants from '../../../constants/validation.constants';
import {
  getRiskColor,
  getRiskResult,
  InputProps,
  probabilityImpactValues,
  riskResultLimits,
} from './constants';

const useStyles = makeStyles(() => ({
  containerEvaluationText: { marginTop: 16 },
  containerFormattedMessage: { marginTop: 32 },
  containerSelectValidator: { marginTop: 32 },
  divider: { width: '100%' },
  evaluationText: {
    fontSize: 14,
    fontWeight: 600,
  },
  input: {
    background: '#FFFFFF',
    margin: 2,
  },
  label: {
    color: '#6D6D6D',
    fontSize: 12,
    fontWeight: 400,
  },
  lastActions: {
    fontSize: 11,
    fontWeight: 400,
  },
  main: { backgroundColor: '#F8F8F8' },
  previousEvaluation: {
    fontSize: 12,
    fontWeight: 700,
  },
  selectValidator: { width: '100%' },
}));

const RiskEvaluationForm = ({ data, risk }) => {
  const dispatch = useDispatch();
  const people = useSelector((state) => state.person.people);
  const user = useSelector((state) => state.authentication.user);
  const intl = useIntl();
  const classes = useStyles();

  const initialFieldValues = {
    actions: data.actions || '',
    evaluationDate: data.evaluationDate || new Date(),
    impact: data.impact || '',
    inChargePersonId: data.inChargePersonId || '',
    nextEvaluationDate: data.nextEvaluationDate || new Date(),
    probability: data.probability || '',
    result: data.result || '',
    riskLevel: null,
    riskResult: null,
  };

  const [values, setValues] = useState(initialFieldValues);

  const {
    actions,
    evaluationDate,
    impact,
    inChargePersonId,
    nextEvaluationDate,
    probability,
    result,
    riskLevel,
    riskResult,
  } = values;

  const isAdmin = user.roles.includes('Admin');

  const backText = intl.formatMessage({ id: 'app.back', defaultMessage: 'volver' });
  const evaluationDateLabel = intl.formatMessage({ id: 'risks.evaluationDate', defaultMessage: 'Fecha de evaluación' });
  const evaluationText = intl.formatMessage({ id: 'app.evaluation', defaultMessage: 'Evaluación' });
  const invalidDateLabel = intl.formatMessage({ id: 'app.invalid_date', defaultMessage: 'Fecha incorrecta' });
  const lastActionResults = intl.formatMessage({ id: 'risks.lastActionsResults', defaultMessage: 'Resultados de acciones anteriores' });
  const newActionsLabel = intl.formatMessage({ id: 'risks.newActions', defaultMessage: 'Nuevas acciones' });
  const newImpactLabel = intl.formatMessage({ id: 'risks.newImpact', defaultMessage: 'Nuevo impacto' });
  const newProbabilityLabel = intl.formatMessage({ id: 'risks.newProbability', defaultMessage: 'Nueva probabilidad' });
  const nextEvaluationDateLabel = intl.formatMessage({ id: 'risks.nextEvaluationDate', defaultMessage: 'Fecha de próxima evaluación' });
  const previousEvaluationActionsText = intl.formatMessage({ id: 'risks.previousEvaluationActions', defaultMessage: 'Acciones evaluación anterior' });
  const resultWillBeCalculatedText = intl.formatMessage({ id: 'risks.resultWillBeCalculated', defaultMessage: 'Se autocalculará el resultado' });
  const riskInChargePersonLabel = intl.formatMessage({ id: 'risks.label.inChargePerson', defaultMessage: 'Responsable de cumplimiento' });
  const saveText = intl.formatMessage({ id: 'app.save', defaultMessage: 'guardar' });

  const getRiskLevel = (riskResultValue) => {
    if (riskResultValue >= riskResultLimits.veryHigh) { return intl.formatMessage({ id: 'risks.level.veryHigh', defaultMessage: 'Muy alto' }); }
    if (riskResultValue >= riskResultLimits.high) { return intl.formatMessage({ id: 'risks.level.high', defaultMessage: 'Alto' }); }
    if (riskResultValue >= riskResultLimits.mid) { return intl.formatMessage({ id: 'risks.level.mid', defaultMessage: 'Medio' }); }
    return intl.formatMessage({ id: 'risks.level.low', defaultMessage: 'Bajo' });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const evaluation = {
      actions,
      evaluationDate,
      id: data.id,
      impact,
      inChargePersonId,
      nextEvaluationDate,
      probability,
      result,
    };

    dispatch(riskActions.riskEvaluationUpdate(risk, evaluation, '/risks/'));
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  }

  const getLastEvaluation = () => {
    if (risk && risk.riskEvaluations) return risk.riskEvaluations[risk.riskEvaluations.length - 1];
    return { actions: '', evaluationDate: null };
  }

  const lastActions = () => {
    const lastEvaluation = getLastEvaluation();
    return lastEvaluation.actions;
  }

  const lastEvaluationDate = () => {
    const lastEvaluation = getLastEvaluation();
    return moment(lastEvaluation.evaluationDate).format('DD/MM/YYYY');
  }

  useEffect(() => {
    if (!people) {
      dispatch(personActions.getAll());
    }

    if (probability && impact) {
      const riskResultValue = getRiskResult(probability, impact);
      setValues({
        ...values,
        riskResult: riskResultValue,
        riskLevel: getRiskLevel(riskResultValue),
      });
    }
    // eslint-disable-next-line
  }, [dispatch, user, risk, probability, impact]);

  return (
    <div className={classes.main}>
      <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Container>
              <DatePicker
                value={evaluationDate}
                onChange={(value) => { setValues({ ...values, evaluationDate: value }); }}
                fullWidth
                margin="normal"
                id="evaluationDate"
                name="evaluationDate"
                label={evaluationDateLabel}
                size="small"
                format="dd/MM/yyyy"
              />
            </Container>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Container>
              <DatePicker
                value={nextEvaluationDate}
                onChange={(value) => {
                  setValues({ ...values, nextEvaluationDate: value });
                }}
                fullWidth
                margin="normal"
                id="nextEvaluationDate"
                name="nextEvaluationDate"
                label={nextEvaluationDateLabel}
                size="small"
                format="dd/MM/yyyy"
                minDateMessage={invalidDateLabel}
                minDate={new Date(evaluationDate)}
              />
            </Container>
          </Grid>
          <Grid item xs={12} sm={12} className={classes.containerEvaluationText}>
            <Container maxWidth={false}>
              <Box display="flex" alignItems="flex-start" justifyContent="flex-start">
                <Typography variant="subtitle2" color="textPrimary" className={classes.evaluationText}>{evaluationText}</Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center" pt={1}>
                <Divider variant="middle" className={classes.divider} />
              </Box>
            </Container>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Container>
              <Typography variant="caption" color="textPrimary" className={classes.label}>
                {lastActionResults}
              </Typography>
              <TextField
                InputProps={InputProps}
                value={result}
                name="result"
                id="result"
                multiline
                minRows={5}
                onChange={handleChange}
                fullWidth
                className={classes.input}
              />
            </Container>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Container>
              <Typography variant="caption" color="textPrimary" className={classes.label}>
                {newActionsLabel}
              </Typography>
              <TextValidator
                InputProps={InputProps}
                value={actions}
                name="actions"
                id="actions"
                multiline
                rows={5}
                onChange={handleChange}
                fullWidth
                className={classes.input}
                validators={['required']}
                errorMessages={[validationConstants.ACTIONS_REQUIRED]}
              />
            </Container>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Container>
                  <SelectValidator
                    id="probability"
                    name="probability"
                    value={probability}
                    label={newProbabilityLabel}
                    displayEmpty
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    className={classes.selectValidator}
                    validators={['required']}
                    errorMessages={[validationConstants.RISK_PROBABILITY_REQUIRED]}
                  >
                    <MenuItem disabled value="">
                      <em>{newProbabilityLabel}</em>
                    </MenuItem>
                    {probabilityImpactValues.map((val) => (
                      <MenuItem key={val} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </Container>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Container>
                  <SelectValidator
                    id="impact"
                    name="impact"
                    value={impact}
                    displayEmpty
                    label={newImpactLabel}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    className={classes.selectValidator}
                    validators={['required']}
                    errorMessages={[validationConstants.RISK_IMPACT_REQUIRED]}
                  >
                    <MenuItem disabled value="">
                      <em>{newImpactLabel}</em>
                    </MenuItem>
                    {probabilityImpactValues.map((val) => (
                      <MenuItem key={val} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </Container>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.containerFormattedMessage}>
                <Container>
                  <Box display="flex" alignItems="flex-start" justifyContent="flex-start" mt={3}>
                    <span>
                      <FormattedMessage id="risks.coefficient" defaultMessage="Coeficiente" />
                      :
                    </span>
                    <span style={{ paddingLeft: 5, color: getRiskColor(riskResult) }}>
                      <strong>{riskResult || resultWillBeCalculatedText}</strong>
                    </span>
                  </Box>
                </Container>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.containerFormattedMessage}>
                <Container>
                  <Box display="flex" alignItems="flex-start" justifyContent="flex-start" mt={3}>
                    <span>
                      <FormattedMessage id="app.risks" defaultMessage="Riesgos" />
                      :
                    </span>
                    <span style={{ paddingLeft: 5, color: getRiskColor(riskResult) }}>
                      <strong>{riskLevel}</strong>
                    </span>
                  </Box>
                </Container>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.containerSelectValidator}>
              <Container>
                {people?.length && (
                  <SelectValidator
                    id="inChargePersonId-select"
                    label={riskInChargePersonLabel}
                    name="inChargePersonId"
                    value={inChargePersonId}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    className={classes.selectValidator}
                    validators={['required']}
                    errorMessages={[validationConstants.RISK_RESPONSIBLE_REQUIRED]}
                  >
                    {people.map((item) => {
                      if (!item.names) return null;
                      return (
                        <MenuItem key={`${item.id}${item.surnames}`} value={item.id}>
                          {`${item.names} ${item.surnames}`}
                        </MenuItem>
                      );
                    })}
                  </SelectValidator>
                )}
              </Container>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Container>
              <Box display="flex" alignItems="flex-start" justifyContent="flex-start" mt={3}>
                <Typography variant="caption" color="textPrimary" className={classes.previousEvaluation}>
                  {`${previousEvaluationActionsText} ${lastEvaluationDate()}`}
                </Typography>
              </Box>
              <Box display="flex" alignItems="flex-start" justifyContent="flex-start" mt={1}>
                <Typography variant="caption" color="textPrimary" className={classes.lastActions}>
                  {lastActions()}
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Grid>
        <Box display="flex" alignItems="flex-end" justifyContent="flex-end" mt={2}>
          <Button
            component={Link}
            to={`/risks/${risk.id}`}
            variant="outlined"
            text={backText}
          />
          <Button
            type="submit"
            text={saveText}
            style={{ fontSize: '10px', fontWeight: 600 }}
            disabled={!isAdmin}
          />
        </Box>
      </ValidatorForm>
    </div>
  );
}

RiskEvaluationForm.propTypes = {
  data: PropTypes.object,
  risk: PropTypes.object,
};

RiskEvaluationForm.defaultProps = {
  data: {},
  risk: {},
};

export default RiskEvaluationForm;
