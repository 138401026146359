import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box, Container, FormControl, Grid,
  InputLabel,
  LinearProgress, makeStyles, MenuItem, Select, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { DatePicker } from '@material-ui/pickers';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import {
  processActions, riskActions, workFieldEvaluationActions, providerActions,
} from '../../../redux/actions';
import Button from '../../controls/Button';
import { validationConstants, workFieldEvaluationTexts } from '../../../constants';
import { sortableHelper, workFieldHelper } from '../../../helpers';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  formTitle: {
    borderBottom: '1px solid #EBEBEB',
    marginTop: '40px',
    marginBottom: '10px',
  },
  formControlProvider: {
    width: '100%',
    marginTop: '16px',
    textAlign: 'left',
  },
}));

const InputProps = { disableUnderline: true };

const EditRiskForm = ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { roles } = useSelector((state) => state.authentication.user);
  const processes = useSelector((state) => state.process.processes);
  const creatingRisk = useSelector((state) => state.risk.creating);
  const company = useSelector((state) => state.company.data);
  const providers = useSelector((state) => state.provider.providers);
  const workFieldEvaluationUnapproved = useSelector(
    (state) => state.workFieldEvaluation.workFieldEvaluations,
  );

  const isAdmin = roles.includes('Admin');

  const invalidData = [null, undefined, NaN, ''];
  const initialRiskProcesses = data && data.processes ? data.processes : [];
  const initialFieldValues = {
    riskTitle: data && data.title ? data.title : '',
    riskDetectionDate: data && data.detectionDate ? data.detectionDate : new Date(),
    riskIdentification: data && data.initialTreatmentIdentification ? data.initialTreatmentIdentification : '',
    riskDetail: data && data.detail ? data.detail : '',
    riskContext: data && !invalidData.includes(data.context) ? data.context : '',
    riskType: data && !invalidData.includes(data.type) ? data.type : '',
    riskStakeholdersIdentification: data && data.stakeholdersIdentification ? data.stakeholdersIdentification : '',
    riskRequirement: data && data.stakeholdersNeeds ? data.stakeholdersNeeds : '',
    swotItemName: data && data.swotItemName ? data.swotItemName : '',
    workFieldEvaluationId: data && data.workFieldEvaluationId ? data.workFieldEvaluationId : '',
    selectedProvider: null,
  };
  const [values, setValues] = useState(initialFieldValues);
  const [riskProcesses, setRiskProcesses] = useState(initialRiskProcesses);
  const [componentMounted, setComponentMounted] = useState(false);
  const [swotItemId, setSwotItemId] = useState(null);

  const titleLabel = intl.formatMessage({ id: 'risks.label.title', defaultMessage: 'Título' });
  const processesLabel = intl.formatMessage({ id: 'risks.label.processes', defaultMessage: 'Procesos' });
  const detectionDateLabel = intl.formatMessage({ id: 'risks.label.detectionDate', defaultMessage: 'Fecha de detección' });
  const invalidDateLabel = intl.formatMessage({ id: 'app.invalid_date', defaultMessage: 'Fecha incorrecta' });
  const noProcessesText = intl.formatMessage({ id: 'app.noProcesses', defaultMessage: 'No hay procesos cargados en el sistema, puede ' });
  const createProcessLink = intl.formatMessage({ id: 'app.createProcess', defaultMessage: 'crear un nuevo proceso' });
  const editText = intl.formatMessage({ id: 'app.edit', defaultMessage: 'Editar' });
  const cancelText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const swotItemNameLabel = intl.formatMessage({ id: 'risks.swotAssociated', defaultMessage: 'Contexto de la organización' });

  useEffect(() => {
    if (!componentMounted) {
      dispatch(processActions.getAll());
      dispatch(workFieldEvaluationActions.getAllUnapproved());
      dispatch(providerActions.getAll());
      setComponentMounted(true);
    }
    if (data && data.workFieldEvaluationId && workFieldEvaluationUnapproved && providers) {
      const workFieldEvaluation = workFieldEvaluationUnapproved
        .find((item) => item.id === data.workFieldEvaluationId);
      if (workFieldEvaluation) {
        const provider = providers.find((p) => p.id === workFieldEvaluation.provider.id);
        if (provider) {
          setValues((prev) => ({ ...prev, selectedProvider: provider.id }));
        }
      }
    }
  }, [dispatch, componentMounted, data, workFieldEvaluationUnapproved, providers]);

  function handleSubmit(e) {
    e.preventDefault();
    const riskProcessesId = riskProcesses.map((processF) => processF.id);

    const lastRiskEvaluation = data.riskEvaluations.find(
      (riskE) => riskE.id === data.lastRiskEvaluationId,
    );

    const risk = {
      id: data.id,
      title: values.riskTitle,
      detectionDate: values.riskDetectionDate,
      processes: riskProcessesId,
      initialTreatmentIdentification: values.riskIdentification,
      detail: values.riskDetail,
      context: values.riskContext,
      type: values.riskType,
      stakeHoldersIdentification: values.riskStakeholdersIdentification,
      stakeHoldersNeeds: values.riskRequirement,
      actions: lastRiskEvaluation.actions,
      probability: lastRiskEvaluation.probability,
      impact: lastRiskEvaluation.impact,
      swotItemName: values.swotItemName,
      swotItemId,
    };

    dispatch(riskActions.updateNonVersionableData(risk, '/risks/'));
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues((prevValue) => ({ ...prevValue, [name]: value }));
  }

  function handleChangeSwotItem(e, value = {}) {
    if (value.id) {
      setSwotItemId(value.id);
      setValues({ ...values, swotItemName: value.description });
    } else {
      setSwotItemId(null);
      setValues({ ...values, swotItemName: e.target.value });
    }
  }

  return (
    <>
      {processes
        ? (
          <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Container>
                  <TextValidator
                    value={values.riskTitle}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    id="riskTitle"
                    label={titleLabel}
                    name="riskTitle"
                    size="small"
                    validators={['required']}
                    errorMessages={[validationConstants.RISK_TITLE_REQUIRED]}
                  />
                </Container>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Container>
                  <DatePicker
                    value={values.riskDetectionDate}
                    onChange={(value) => setValues({ ...values, riskDetectionDate: value })}
                    fullWidth
                    margin="normal"
                    id="riskDetectionDate"
                    label={detectionDateLabel}
                    name="riskDetectionDate"
                    size="small"
                    format="dd/MM/yyyy"
                    minDateMessage={invalidDateLabel}
                  />
                </Container>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Container>
                  {processes && processes.length === 0 ? (
                    <Box mt={5}>
                      <Typography variant="subtitle1" display="inline" gutterBottom style={{ fontSize: '12px' }}>
                        {noProcessesText}
                      </Typography>
                      <Link to="/processes/new" style={{ textDecoration: 'none' }}>
                        <Typography variant="subtitle1" display="inline" gutterBottom style={{ fontSize: '12px', color: '#F16C24' }}>
                          {createProcessLink}
                        </Typography>
                      </Link>
                    </Box>

                  ) : (
                    <FormControl className={classes.formControl}>
                      <Autocomplete
                        filterSelectedOptions
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        id="processes-select"
                        multiple
                        options={processes}
                        size="small"
                        loading={!processes.length}
                        value={riskProcesses}
                        onChange={(event, newValue) => {
                          setRiskProcesses(newValue);
                        }}
                        renderOption={(option) => <Typography noWrap style={{ fontSize: '12px' }}>{option.name}</Typography>}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            value={riskProcesses}
                            variant="standard"
                            label={processesLabel}
                            placeholder="..."
                            style={{ fontSize: '12px' }}
                            validators={['required']}
                            errorMessages={[validationConstants.RISK_PROCESS_REQUIRED]}
                          />
                        )}
                      />
                    </FormControl>
                  )}

                </Container>
              </Grid>

              <Grid item xs={12} sm={6} />

              <Grid item xs={12} sm={6}>
                <Container>
                  <Typography variant="caption" color="textPrimary" style={{ weight: 400, fontSize: 12 }}>
                    <FormattedMessage id="risks.label.identification" defaultMessage="Identificación" />
                  </Typography>
                  <TextField
                    InputProps={InputProps}
                    value={values.riskIdentification}
                    name="riskIdentification"
                    id="riskIdentification"
                    multiline
                    rows={5}
                    onChange={handleChange}
                    fullWidth
                    style={{ background: '#FFFFFF' }}
                  />
                </Container>
              </Grid>

              <Grid item xs={12} sm={6}>
                {providers && providers.length > 0 && (
                <Container>
                  <FormControl className={classes.formControlProvider}>
                    <InputLabel shrink={values.selectedProvider} id="selectedProvider">{workFieldEvaluationTexts.relatedProvider}</InputLabel>
                    <Select
                      disabled
                      id="selectedProvider"
                      name="selectedProvider"
                      labelId="selectedProvider"
                      value={values.selectedProvider}
                      displayEmpty
                      style={{ width: '100%' }}
                    >
                      <MenuItem disabled style={{ fontSize: '12px' }} value="">
                        <em>{workFieldEvaluationTexts.selectOption}</em>
                      </MenuItem>
                      {providers.map((provider) => (
                        <MenuItem key={provider.id} value={provider.id} style={{ fontSize: '12px' }}>
                          {provider.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Container>
                )}
                {values.selectedProvider && (
                <>
                  <Container>
                    <FormControl className={classes.formControlProvider}>
                      <InputLabel id="workFieldEvaluationId">{workFieldEvaluationTexts.relatedEvaluation}</InputLabel>
                      <Select
                        disabled
                        id="workFieldEvaluationId"
                        name="workFieldEvaluationId"
                        labelId="workFieldEvaluationId"
                        value={values.workFieldEvaluationId}
                        displayEmpty
                        style={{ width: '100%' }}
                      >
                        <MenuItem disabled style={{ fontSize: '12px' }} value="">
                          <Grid container>
                            <Grid item xs={4}><b><em>{workFieldEvaluationTexts.date}</em></b></Grid>
                            <Grid item xs={4}>
                              <b><em>{workFieldEvaluationTexts.workFieldEvaluated}</em></b>
                            </Grid>
                            <Grid item xs={4}>
                              <b><em>{workFieldEvaluationTexts.resultEvaluation}</em></b>
                            </Grid>
                          </Grid>
                        </MenuItem>
                        {workFieldEvaluationUnapproved && workFieldEvaluationUnapproved
                          .filter((e) => e.provider.id === values.selectedProvider)
                          .sort((a, b) => new Date(b.date) - new Date(a.date))
                          .map((evaluation) => (
                            <MenuItem key={evaluation.id} value={evaluation.id} style={{ fontSize: '12px' }}>
                              <Grid container>
                                <Grid item xs={4}>{moment(evaluation.date).format('DD/MM/YYYY')}</Grid>
                                <Grid item xs={4}>{evaluation.workField.name}</Grid>
                                <Grid item xs={4}>
                                  {workFieldHelper
                                    .getEvaluationStatusLabel(evaluation.evaluationStatus)}
                                </Grid>
                              </Grid>
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Container>
                </>
                )}
              </Grid>

              <Grid item xs={12} className={classes.formTitle}>
                <Container maxWidth={false}>
                  <Typography variant="caption" gutterBottom style={{ fontSize: 14, fontWeight: 700 }}>
                    <FormattedMessage id="risks.organizationContext" defaultMessage="Contexto organización" />
                  </Typography>
                </Container>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Container>
                  <Typography variant="caption" color="textPrimary" style={{ weight: 400, fontSize: 12 }}>
                    <FormattedMessage id="risks.label.detail" defaultMessage="Detalle" />
                  </Typography>
                  <TextField
                    InputProps={InputProps}
                    value={values.riskDetail}
                    name="riskDetail"
                    id="riskDetail"
                    multiline
                    rows={5}
                    onChange={handleChange}
                    fullWidth
                    style={{ background: '#FFFFFF' }}
                  />
                </Container>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Container>
                  <Autocomplete
                    id="swotItemName"
                    name="swotItemName"
                    freeSolo
                    label={swotItemNameLabel}
                    options={company.swotItems}
                    inputValue={values.swotItemName}
                    groupBy={(option) => sortableHelper.getNameByType(option.type)}
                    getOptionLabel={(option) => option.description}
                    disableClearable
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label={swotItemNameLabel}
                        value={values.swotItemName}
                        onChange={(event) => { handleChangeSwotItem(event); }}
                        margin="normal"
                        validators={['required']}
                        errorMessages={[validationConstants.RISK_SWOT_ITEM_REQUIRED]}
                      />
                    )}
                    onChange={(event, value) => { handleChangeSwotItem(event, value); }}
                  />
                </Container>
              </Grid>

              <Grid item xs={12} className={classes.formTitle}>
                <Container maxWidth={false}>
                  <Typography variant="caption" gutterBottom style={{ fontSize: 14, fontWeight: 700 }}>
                    <FormattedMessage id="risks.stakeholders" defaultMessage="Partes interesadas" />
                  </Typography>
                </Container>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Container>
                  <Typography variant="caption" color="textPrimary" style={{ weight: 400, fontSize: 12 }}>
                    <FormattedMessage id="risks.label.stakeholdersIdentification" defaultMessage="Identificación" />
                  </Typography>
                  <TextField
                    InputProps={InputProps}
                    value={values.riskStakeholdersIdentification}
                    name="riskStakeholdersIdentification"
                    id="riskStakeholdersIdentification"
                    multiline
                    rows={5}
                    onChange={handleChange}
                    fullWidth
                    style={{ background: '#FFFFFF' }}
                  />
                </Container>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Container>
                  <Typography variant="caption" color="textPrimary" style={{ weight: 400, fontSize: 12 }}>
                    <FormattedMessage id="risks.label.risks.label.stakeholdersRequirement" defaultMessage="Requisito" />
                  </Typography>
                  <TextField
                    InputProps={InputProps}
                    value={values.riskRequirement}
                    name="riskRequirement"
                    id="riskRequirement"
                    multiline
                    rows={5}
                    onChange={handleChange}
                    fullWidth
                    style={{ background: '#FFFFFF' }}
                  />
                </Container>
              </Grid>

            </Grid>

            <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
              <Button
                component={Link}
                to={`/risks/${data.id}`}
                variant="outlined"
                text={cancelText}
                style={{ fontSize: '10px', fontWeight: 600 }}
              />
              <Button
                type="submit"
                loading={creatingRisk}
                text={editText}
                disabled={!isAdmin}
              />
            </Box>
          </ValidatorForm>
        ) : (
          <LinearProgress />
        )}
    </>
  );
};

EditRiskForm.propTypes = {
  data: PropTypes.shape(PropTypes.string),
};

EditRiskForm.defaultProps = {
  data: {},
};

export default EditRiskForm;
