import React from 'react';
import { SvgIcon } from '@material-ui/core';

function OrgChartIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 531.000000 531.000000">
      <g transform="translate(0.000000,531.000000) scale(0.100000,-0.100000)">
        <path d="M2145 4397 c-87 -35 -151 -93 -187 -172 -23 -49 -23 -57 -23 -385 0 -329 0 -336 23 -386 27 -60 89 -123 152 -156 l45 -23 505 0 505 0 45 23 c63 33 125 96 152 156 23 50 23 57 23 386 0 329 0 336 -23 386 -27 60 -89 123  -152 156 l-45 23 -495 2 c-363 1 -503 -1 -525 -10z" />
        <path d="M2550 2980 l0 -100 -682 0 c-557 0 -696 -3 -751 -15 -157 -33 -286 -133 -357 -278 -49 -99 -59 -167 -60 -389 l0 -158 114 0 114 0 4 193 c3 180 5 195 28 242 33 68 99 129 166 154 53 20 72 21 739 21 l685 0 0 -305 0 -305 110 0 110 0 0 305 0 305 685 0 c667 0 686 -1 739 -21 67 -25 133 -86 166 -154 24 -47 25 -62 28 -242 l3 -193 115 0 114 0 0 158 c-1 217 -12 289 -58 387 -69 143 -201 247 -359 280 -55 12 -194 15 -750 15 l-683 0 0 100 0 100 -110 0 -110 0 0 -100z" />
        <path d="M292 1829 c-64 -25 -140 -98 -173 -166 -24 -48 -24 -50 -24 -383 0-333 0 -335 24 -383 35 -71 109 -141 177 -166 55 -21 68 -22 538 -19 l481 3 53 29 c60 34 111 85 144 148 23 42 23 47 23 388 l0 345 -29 53 c-34 60 -85 111 -148 144 -43 23 -43 23 -528 25 -468 2 -487 2 -538 -18z" />
        <path d="M2155 1837 c-56 -22 -90 -45 -131 -89 -84 -87 -94 -138 -94 -468 0 -254 9 -339 40 -400 24 -47 94 -113 150 -142 l45 -23 495 0 495 0 45 23 c56 29 126 95 150 142 31 61 40 146 40 400 0 254 -9 339 -40 400 -24 47 -94 113 -150 142 l-45 23 -485 2 c-356 1 -493 -1 -515 -10z" />
        <path d="M3999 1836 c-88 -31 -154 -93 -195 -180 -23 -50 -24 -56 -24 -377 l0 -326 30 -61 c33 -66 85 -118 152 -154 43 -23 44 -23 524 -26 470 -3 483 -2 538 19 68 25 142 95 177 166 24 48 24 50 24 383 0 333 0 335 -24 383 -34 71 -109 141 -176 166 -54 20 -73 21 -523 21 -356 -1 -476 -4 -503 -14z" />
      </g>
    </SvgIcon>
  );
}

export default OrgChartIcon;
