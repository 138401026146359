import {
  errorCodeHelper, history, dispatch, userHelper,
} from '../helpers';
import { userActions } from '../redux/actions';

function logout() {
  if (localStorage.getItem('impersonator')) {
    localStorage.removeItem('impersonator');
  }
  if (localStorage.getItem('step')) {
    localStorage.removeItem('step');
  }
  if (localStorage.getItem('user')) {
    localStorage.removeItem('user');
    history.push('/login');
  }
}

function handleResponse(response) {
  return response.data;
}

function rejectPromiseWith(message) {
  return Promise.reject(message);
}

function handleError(error) {
  if (error.response) {
    if (error.response.status === 401) {
      dispatch(userActions.logout());
    }

    if (error.response.status === 500) {
      return rejectPromiseWith(error.response.data.detail);
    }

    if (error.response.status === 403
      && errorCodeHelper.isSubscriptionError(error.response.data.status)
    ) {
      return rejectPromiseWith([
        errorCodeHelper.getSubscriptionErrorMessage(error.response.data.status),
        errorCodeHelper.errorTexts(error.response.data.status),
      ]);
    }

    if (error.response.status === 403) {
      return Promise.reject(errorCodeHelper.errorTexts(635));
    }

    if (error.response.data.status === 622 && error.response.data.stripeError) {
      return rejectPromiseWith(
        error.response.data.stripeError.declineCode
          ? errorCodeHelper.getStripeErrorMessage(error.response.data.stripeError)
          : error.response.data.stripeError.message,
      );
    }

    if (error.response.data.status === 606) {
      userHelper.proposeResendEmail();
    }

    return rejectPromiseWith(errorCodeHelper.errorTexts(error.response.data.status));
  }
  return rejectPromiseWith(errorCodeHelper.errorTexts('default'));
}

const handler = {
  logout,
  handleResponse,
  handleError,
};

export default handler;
