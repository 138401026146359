import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Toolbar, Box } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import PertixAppBar from './PertixAppBar';
import PertixDrawer from './PertixDrawer';
import PertixFooter from './PertixFooter';
import CompanyCreateModal from './CompanyCreateModal';
import SetNewPasswordModal from './SetNewPasswordModal';
import { stripeActions, modalAlertActions, exampleCompanyActions } from '../../redux/actions';
import { stripePlanSpecs } from '../../constants';
import SetExampleProcessMapModal from './ExampleProcessMap/SetExampleProcessMapModal';
import { history } from '../../helpers';
import tutorialActions from '../../redux/actions/tutorial.actions';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    backgroundColor: '#F8F8F8',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
}));

const LoggedInLayout = ({ children }) => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const impersonator = useSelector((state) => state.impersonate.data);
  const companyData = useSelector((state) => state.company.data);
  const step = useSelector((state) => state.tutorial.step);
  const requestingFirstLogin = useSelector((state) => state.company.requesting);
  const { mustChangePassword, isFirstLogin } = useSelector((state) => state.authentication.user);
  const showWelcomeModal = useSelector((state) => state.modals.showWelcomeModal);
  const isCompanyFirstLogin = companyData && !companyData.isPending && companyData.isFirstLogin;
  useEffect(() => {
    document.title = intl.formatMessage({ id: 'home.docTitle', defaultMessage: 'Pertix - Home' });
  }, [intl]);

  function handleFirstLoginWelcomeModal() {
    const titleStripe = intl.formatMessage({ id: 'stripe.welcome', defaultMessage: '¡Bienvenido a Pertix!' });
    const messageStripe = intl.formatMessage({ id: 'stripe.welcomeMessage', defaultMessage: 'Ya podes comenzar a usar Pertix en el plan Free. Podes cambiar de plan en cualquier momento haciendo click en “Planes” en la barra superior del sistema.' });
    const buttonStripe = intl.formatMessage({ id: 'stripe.seePlan', defaultMessage: 'Ver Planes' });
    dispatch(modalAlertActions.info(titleStripe, messageStripe, buttonStripe));
    dispatch(exampleCompanyActions.showWelcomeModal());
  }

  function handleCancelSubscriptionsNotified() {
    const unsubscribedText = {
      title: intl.formatMessage({ id: 'stripe.subscriptionCanceled', defaultMessage: 'SUSCRIPCIÓN CANCELADA' }),
      message: intl.formatMessage({ id: 'stripe.subscriptionCanceledMessage', defaultMessage: 'Lamentablemente, no pudimos resolver los problemas con tu pago y tu plan fue dado de baja. ¡Pero no te preocupes! La información de tu empresa se mantendrá en el sistema y podrás acceder a ella de nuevo una vez renueves tu plan.' }),
      button: intl.formatMessage({ id: 'stripe.renewPlan', defaultMessage: 'Renovar plan' }),
    };
    dispatch(stripeActions.cancelSubscriptionNotified(companyData.id));
    dispatch(modalAlertActions.infoSubscription(unsubscribedText));
  }

  useEffect(() => {
    if(!!isFirstLogin && !isCompanyFirstLogin && !step) dispatch(tutorialActions.setTutorialStep(1));
  }, [isFirstLogin, dispatch, isCompanyFirstLogin, step]);

  return (
    <div className={classes.root}>
      {companyData && companyData.isPending && <CompanyCreateModal companyId={companyData.id} />}
      {mustChangePassword && <SetNewPasswordModal isOpen registration />}
      {companyData && !companyData.isPending && !companyData.isFirstLogin
      && showWelcomeModal && history.location.pathname === '/processes/map' && <SetExampleProcessMapModal /> }
      {isCompanyFirstLogin && !requestingFirstLogin && handleFirstLoginWelcomeModal()}
      {companyData && companyData.isCancelSubscriptionsNotified
        && handleCancelSubscriptionsNotified()}
      <CssBaseline />
      <PertixAppBar />
      <PertixDrawer />
      <main className={classes.content}>
        {
          companyData
          && stripePlanSpecs.subscriptionStatusInvalid(companyData.subscriptionStatus)
          && <Box pt={4} />
        }
        {!!impersonator && <Box pt={3.25} />}
        <Toolbar />
        {children}
        <PertixFooter />
      </main>
    </div>
  );
};

LoggedInLayout.propTypes = {
  children: PropTypes.element,
};

LoggedInLayout.defaultProps = {
  children: {},
};

export default LoggedInLayout;
