import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { objectiveActions } from '../../../redux/actions';
import { objectiveTexts } from '../../../constants';
import BulkCreationResult from '../../BulkUpload/BulkCreationResult';

const useStyles = makeStyles((theme) => ({
  paddingNone: {
    padding: theme.spacing(0),
  },
  paddingItem: {
    paddingLeft: theme.spacing(0),
  },
}));

const ObjectiveBulkCreationResult = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const objective = useSelector((state) => state.objective);

  const tableErrors = [
    {
      id: 1,
      row: 'row',
      name: objectiveTexts.rowTitle,
      classesHead: classes.paddingNone,
      classesBody: classes.paddingItem,
    },
    {
      id: 2,
      row: 'title',
      name: objectiveTexts.titleObjective,
    },
  ];
  const tableLoaded = [
    {
      id: 1,
      row: 'title',
      name: objectiveTexts.objectiveTitleText,
      classesHead: classes.paddingNone,
      classesBody: classes.paddingItem,
    },
  ];
  const linkRedirect = '/objectives/upload';
  const linkEntity = '/objectives';
  const text = {
    bulkCreationBreadcrumbs: objectiveTexts.bulkCreationBreadcrumbs,
    bulkNotCreated: objectiveTexts.bulkObjectivesNotCreated,
    bulkUploadedSuccess: objectiveTexts.bulkObjectivesUploadedSuccess,
    create: objectiveTexts.createObjective,
    emptyListSuccessfulUpload: objectiveTexts.emptyListSuccessfulUploadObjectives,
    messageUploadedSuccess: objectiveTexts.messageUploadedSuccess,
    onlySuccessUploadWillBeCreated: objectiveTexts.onlySuccessUploadObjectivesWillBeCreated,
    title: objectiveTexts.title,
  };

  function handleConfirmObjectives() {
    return (dispatch(
      objectiveActions.confirmCreateBulkObjectives(
        objective.bulkCreationPreviewResult.id, text.messageUploadedSuccess,
      ),
    ));
  }

  return (
    <BulkCreationResult
      entity={objective}
      handleConfirmResult={handleConfirmObjectives}
      linkEntity={linkEntity}
      linkRedirect={linkRedirect}
      tableErrors={tableErrors}
      tableLoaded={tableLoaded}
      text={text}
    />
  );
};

export default ObjectiveBulkCreationResult;
