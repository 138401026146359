import { calendarConstants } from '../../constants';

function calendarEvent(state = {}, action) {
  switch (action.type) {
    case calendarConstants.GET_REQUEST:
      return { requesting: true };
    case calendarConstants.GET_SUCCESS:
      return { data: action.event };
    case calendarConstants.GET_FAILURE:
      return { error: true };

    default:
      return state;
  }
}

export default calendarEvent;