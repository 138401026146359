import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box, Breadcrumbs, makeStyles, Typography, Table, TableBody,
  TableCell, TableRow, LinearProgress, Grid,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import {
  history, transformTrainingStatus, urlHelper, trainingHelper,
} from '../../../helpers';
import PageLayout from '../../PageLayout';
import Button from '../../controls/Button';
import trainingActions from '../../../redux/actions/training.actions';
import TrainingDocuments from '../TrainingDocuments';

const useStyles = makeStyles(() => ({
  textWithLink: {
    fontSize: 12,
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
  },
}));

function TrainingDetailsPage({ routeParams: { id: trainingId } }) {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const training = useSelector((state) => state.training.data);
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const editLink = `${trainingId}/edit`;
  const trainingDetailsText = intl.formatMessage({ id: 'training.details', defaultMessage: 'Detalle de capacitación' });
  const trainingText = intl.formatMessage({ id: 'training.trainingsText', defaultMessage: 'Capacitaciones' });
  const totalTimeLabel = intl.formatMessage({ id: 'training.totalTimeLabel', defaultMessage: 'Duración total en horas' });
  const dateFromLabel = intl.formatMessage({ id: 'app.date_start', defaultMessage: 'Fecha de inicio' });
  const dateToLabel = intl.formatMessage({ id: 'app.date_end', defaultMessage: 'Fecha de fin' });
  const descriptionLabel = intl.formatMessage({ id: 'training.descriptionLabel', defaultMessage: 'Descripción de la capacitación | Vínculo' });
  const inChargePersonLabel = intl.formatMessage({ id: 'training.inChargePerson', defaultMessage: 'Capacitador' });
  const statusLabel = intl.formatMessage({ id: 'app.status', defaultMessage: 'Estado' });
  const peopleListLabel = intl.formatMessage({ id: 'training.peopleToTrainList', defaultMessage: 'Listado de participantes' });
  const presentText = intl.formatMessage({ id: 'app.present', defaultMessage: 'Presente' });
  const absentText = intl.formatMessage({ id: 'app.absent', defaultMessage: 'Ausente' });
  const satisfactoryText = intl.formatMessage({ id: 'app.satisfactory', defaultMessage: 'Satisfactoria' });
  const unsatisfactoryText = intl.formatMessage({ id: 'app.unsatisfactory', defaultMessage: 'No satisfactoria' });
  const nameAndSurnameText = intl.formatMessage({ id: 'app.nameAndSurname', defaultMessage: 'Nombre y apellido' });
  const assistanceText = intl.formatMessage({ id: 'app.assistance', defaultMessage: 'Asistencia' });
  const evaluationText = intl.formatMessage({ id: 'app.evaluation', defaultMessage: 'Evaluación' });
  const evaluationCommentText = intl.formatMessage({ id: 'app.evaluationComment', defaultMessage: 'Comentario de la evaluación' });
  const commentsLabel = intl.formatMessage({ id: 'app.generalComments', defaultMessage: 'Comentarios generales' });
  const effectivenessVerificationDateLabel = intl.formatMessage({ id: 'training.effectivenessVerificationDate', defaultMessage: 'Fecha prevista de verificación de efectividad' });
  const effectivenessVerifiedDateLabel = intl.formatMessage({ id: 'training.effectivenessVerifiedDate', defaultMessage: 'Fecha efectiva de verificación de efectividad' });
  const trainerLabel = intl.formatMessage({ id: 'training.trainerType', defaultMessage: 'Tipo de capacitador' });

  useEffect(() => {
    dispatch(trainingActions.get(trainingId));
  }, [dispatch, trainingId]);

  function TrainingDetailsBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/trainings" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{trainingText}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{training.title}</strong></Typography>
      </Breadcrumbs>
    );
  }

  function TrainingDetailsLoadingBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/trainings" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{trainingText}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{trainingDetailsText}</strong></Typography>
      </Breadcrumbs>
    );
  }

  function getEvaluationText(person) {
    if (training.status < 3) return '-';
    return (person.evaluationResult) ? satisfactoryText : unsatisfactoryText;
  }

  return (
    <>
      {training
        ? (
          <PageLayout title={training.title} breadCrumbs={<TrainingDetailsBreadcrumbs />}>
            <Box pl={3} pt={2}>
              <Grid container spacing={5}>
                <Grid item sm={8}>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="caption" gutterBottom>
                        <strong style={{ fontSize: 14 }}>{totalTimeLabel}</strong>
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom style={{ fontSize: 12 }}>
                        {training.totalTime}
                        {' '}
                        hs.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="caption" gutterBottom>
                        <strong style={{ fontSize: 14 }}>{dateFromLabel}</strong>
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom style={{ fontSize: 12 }}>
                        {moment(training.dateFrom).format('DD/MM/YYYY - H.mm')}
                        {' '}
                        Hs.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="caption" gutterBottom>
                        <strong style={{ fontSize: 14 }}>{inChargePersonLabel}</strong>
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom style={{ fontSize: 12 }}>
                        {training.inChargePersonName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="caption" gutterBottom>
                        <strong style={{ fontSize: 14 }}>{dateToLabel}</strong>
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom style={{ fontSize: 12 }}>
                        {moment(training.dateTo).format('DD/MM/YYYY - H.mm')}
                        {' '}
                        Hs.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  { training.description && (
                    <>
                      <Typography variant="caption" gutterBottom>
                        <strong style={{ fontSize: 14 }}>{descriptionLabel}</strong>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        className={classes.textWithLink}
                      >
                        {urlHelper.parseLinksInText(training.description)}
                      </Typography>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: 14 }}>{trainerLabel}</strong>
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: 12 }}>
                    {trainingHelper.translateTrainerType(training.trainerType)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: 14 }}>{effectivenessVerificationDateLabel}</strong>
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: 12 }}>
                    {
                      training.effectivenessVerificationDate
                        ? moment(training.effectivenessVerificationDate).format('DD/MM/YYYY')
                        : '-'
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: 14 }}>{effectivenessVerifiedDateLabel}</strong>
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: 12 }}>
                    {
                      training.effectivenessVerifiedDate
                        ? moment(training.effectivenessVerifiedDate).format('DD/MM/YYYY')
                        : '-'
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: 14 }}>{statusLabel}</strong>
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: 12 }}>
                    {transformTrainingStatus(training.status)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box>
                    <Typography variant="caption" gutterBottom>
                      <strong style={{ fontSize: 14 }}>{peopleListLabel}</strong>
                    </Typography>
                    <Table style={{ marginTop: 16 }}>
                      <TableBody>
                        <TableRow>
                          <TableCell size="small" style={{ fontSize: 12, paddingLeft: 0 }}><strong>{nameAndSurnameText}</strong></TableCell>
                          <TableCell size="small" style={{ fontSize: 12 }}><strong>{assistanceText}</strong></TableCell>
                          <TableCell size="small" style={{ fontSize: 12 }}><strong>{evaluationText}</strong></TableCell>
                          <TableCell size="small" style={{ fontSize: 12 }}><strong>{evaluationCommentText}</strong></TableCell>
                        </TableRow>
                        {training.peopleToTrain.map((person) => (
                          <TableRow key={person.id}>
                            <TableCell size="small" style={{ fontSize: 12, paddingLeft: 0 }}>
                              {`${person.names} ${person.surnames}`}
                            </TableCell>
                            <TableCell size="small" style={{ fontSize: 12 }}>
                              {person.wasPresent ? presentText : absentText}
                            </TableCell>
                            <TableCell size="small" style={{ fontSize: 12 }}>
                              {getEvaluationText(person)}
                            </TableCell>
                            <TableCell size="small" style={{ fontSize: 12 }}>
                              <Box style={{
                                width: 200,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              >
                                { training.status > 2 && person.comment ? (
                                  <>
                                    <Tooltip title={person.comment} placement="top">
                                      <Typography
                                        variant="caption"
                                        style={{
                                          fontWeight: 400,
                                          fontSize: 11,
                                        }}
                                      >
                                        {person.comment}
                                      </Typography>
                                    </Tooltip>
                                  </>
                                ) : '-'}
                              </Box>
                            </TableCell>
                          </TableRow>
                        )) }
                      </TableBody>
                    </Table>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TrainingDocuments
                    documents={training.documents}
                    trainingId={training.id}
                    showDivider
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  { training.comments && (
                    <>
                      <Typography variant="caption" gutterBottom>
                        <strong style={{ fontSize: 14 }}>{commentsLabel}</strong>
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom style={{ fontSize: 12 }}>
                        {training.comments}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end" mt={2}>
              <Button
                onClick={history.goBack}
                variant="outlined"
                text={<FormattedMessage id="app.back" defaultMessage="volver" />}
              />
              <Button
                component={Link}
                to={editLink}
                type="submit"
                text={<FormattedMessage id="app.edit" defaultMessage="editar" />}
                disabled={!isAdmin}
              />
            </Box>
          </PageLayout>
        )
        : (
          <PageLayout
            title={trainingDetailsText}
            breadCrumbs={<TrainingDetailsLoadingBreadcrumbs />}
          >
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
}

TrainingDetailsPage.propTypes = {
  routeParams: PropTypes.shape(),
};

TrainingDetailsPage.defaultProps = {
  routeParams: {},
};

export default TrainingDetailsPage;
