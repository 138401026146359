import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Rating } from '@material-ui/lab';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import colors from '../../assets/colors';

const CustomRatingOutline = ({
  knowledgeName, knowledgeScore, withColor, handleOnChangeScoreKnowledge, readOnly,
}) => {
  const intl = useIntl();

  const textToDisplayScore = {
    0: intl.formatMessage({ id: 'outlineEvaluation.notEvaluated', defaultMessage: 'Sin evaluar' }),
    1: intl.formatMessage({ id: 'outlineEvaluation.minimum', defaultMessage: 'Mínimo' }),
    2: intl.formatMessage({ id: 'outlineEvaluation.fair', defaultMessage: 'Regular' }),
    3: intl.formatMessage({ id: 'outlineEvaluation.good', defaultMessage: 'Bueno' }),
    4: intl.formatMessage({ id: 'outlineEvaluation.veryGood', defaultMessage: ' bueno' }),
    5: intl.formatMessage({ id: 'outlineEvaluation.excellent', defaultMessage: 'Excelente' }),
  };
  const colorToDisplayScore = {
    disapproved: colors.red,
    approved: colors.green,
  };
  return (
    <>
      <Rating
        name={knowledgeName}
        value={knowledgeScore}
        onChange={(event, newValue) => {
          handleOnChangeScoreKnowledge(newValue, knowledgeName);
        }}
        readOnly={readOnly}
        emptyIcon={<StarBorderIcon fontSize="inherit" />}
        style={{ color: withColor ? colorToDisplayScore[withColor] : colors.blackGrey }}
      />
      <Typography variant="body2" style={{ marginLeft: 8, color: colors.grey, width: '92px' }}>
        {textToDisplayScore[knowledgeScore || 0]}
      </Typography>
    </>
  );
};

CustomRatingOutline.propTypes = {
  knowledgeName: PropTypes.string.isRequired,
  knowledgeScore: PropTypes.number.isRequired,
  handleOnChangeScoreKnowledge: PropTypes.func,
  withColor: PropTypes.string,
  readOnly: PropTypes.bool,
};

CustomRatingOutline.defaultProps = {
  withColor: null,
  readOnly: false,
  handleOnChangeScoreKnowledge: () => {},
};

export default CustomRatingOutline;
