import React, { useEffect } from 'react';
import { Box, Breadcrumbs, makeStyles, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import LoggedInLayout from '../../Home/LoggedInLayout';
import OrgChart from './OrgChart';
import { useSelector } from 'react-redux';
import TutorialInfo from '../../controls/TutorialInfo';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  breadcrumbs: {
    padding: '0 0 0 40px',
    position: 'relative',
    zIndex: 2,
    top: '16px',
  },
}));

const OrgChartPage = () => {
  const intl = useIntl();
  const classes = useStyles();
  const step = useSelector((state) => state.tutorial.step);
  const orgChartLabel = intl.formatMessage({ id: 'orgChart.title', defaultMessage: 'Organigrama' });
  const editLabel = intl.formatMessage({ id: 'app.edit', defaultMessage: 'Editar' });

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'home.orgChartTitle', defaultMessage: 'Pertix - Organigrama' });
  }, [intl]);

  return (
    <LoggedInLayout>
      <div className={classes.main}>
        <Box>
          <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
            <Link color="inherit" to="/orgchart" style={{ textDecoration: 'none' }}>
              <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{orgChartLabel}</Typography>
            </Link>
            <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{editLabel}</Typography>
          </Breadcrumbs>
          {step === 6 && (
            <Box display="flex" justifyContent="center" zIndex={8000}>
              <TutorialInfo step={6} />
            </Box>
          )}
        </Box>
        <OrgChart />
      </div>
    </LoggedInLayout>
  );
};

export default OrgChartPage;
