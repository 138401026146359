const companyConstants = {

  GET_REQUEST: 'COMPANY_GET_REQUEST',
  GET_SUCCESS: 'COMPANY_GET_SUCCESS',
  GET_FAILURE: 'COMPANY_GET_FAILURE',

  UPDATE_REQUEST: 'COMPANY_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'COMPANY_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'COMPANY_UPDATE_FAILURE',

  FIRST_LOGIN_COMPANY_REQUEST: 'FIRST_LOGIN_COMPANY_REQUEST',
  FIRST_LOGIN_COMPANY_SUCCESS: 'FIRST_LOGIN_COMPANY_SUCCESS',
  FIRST_LOGIN_COMPANY_FAILURE: 'FIRST_LOGIN_COMPANY_FAILURE',

};

export default companyConstants;
