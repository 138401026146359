import { objectiveTexts } from '../constants';

function calculateAccObjective(measuresList) {
  return Object.entries(measuresList).reduce((acc, [key, value]) => {
    if (key === 'id') return acc;
    return acc + value;
  }, 0);
}

function formattedValue(value, isBoolean) {
  if (isBoolean) {
    return value ? objectiveTexts.complies : objectiveTexts.doesNotComply;
  }
  return new Intl.NumberFormat('es-AR').format(value);
}

const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

const isInvalidData = (data) => [null, undefined, NaN, ''].includes(data);

function getLastMeasure(measures) {
  let lastMeasure = null;
  months.forEach((month) => {
    if (!isInvalidData(measures[month])) {
      lastMeasure = measures[month];
    }
  });
  return lastMeasure;
}

const objectiveHelper = {
  calculateAccObjective, formattedValue, getLastMeasure, isInvalidData,
};

export default objectiveHelper;
