export default {
  red: '#F22525',
  blackGrey: '#383838',
  orange: '#F2AC20',
  green: '#43A047',
  grey: '#6D6D6D',
  orangeLight: '#F79A56',
  rustyRed: '#D12828',
  white: '#FFFFFF',
  greyLight: '#727272',
  darkGray: '#727272',
  yellow: '#FBBC05',
};
