const listDeviationsExample = `${process.env.REACT_APP_API_URL}downloads/ListDeviationsExample.xlsx`;
const listObjectivesExample = `${process.env.REACT_APP_API_URL}downloads/ListObjectivesExampleV002.xlsx`;
const listRisksExample = `${process.env.REACT_APP_API_URL}downloads/ListRisksExampleV003.xlsx`;
const listUsersExample = `${process.env.REACT_APP_API_URL}downloads/ListUsersExampleV004.xlsx`;
const listProvidersExample = `${process.env.REACT_APP_API_URL}downloads/ListProvidersExample.xlsx`;

const downloadHelper = {
  listDeviationsExample,
  listObjectivesExample,
  listRisksExample,
  listUsersExample,
  listProvidersExample,
};

export default downloadHelper;
