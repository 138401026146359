import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Breadcrumbs, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  IconButton, makeStyles, Typography, Tooltip,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import CreateIcon from '@material-ui/icons/Create';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DataTable from '../../DataTable/DataTable';
import PageLayout from '../../PageLayout';
import Button from '../../controls/Button';
import { workFieldEvaluationActions } from '../../../redux/actions';
import { history, workFieldHelper } from '../../../helpers';
import { workFieldEvaluationTexts } from '../../../constants';
import PopoverWarningMessage from '../../controls/PopoverWarningMessage';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    textDecoration: 'none',
    fontWeight: 400,
    fontSize: 11,
  },
  tableBody: {
    textDecoration: 'none',
    fontSize: '12px',
    color: 'black',
  },
  tableBodyPending: {
    textDecoration: 'none',
    fontSize: '12px',
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
}));

const initialFieldValues = { workFieldEvaluationId: '', deleteWorkFieldEvaluationDialog: false };

const WorkFieldEvaluationPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();
  const user = useSelector((state) => state.authentication.user);
  const deletingWorkFieldEvaluation = useSelector((state) => state.workFieldEvaluation.deleting);
  const deletedWorkFieldEvaluation = useSelector((state) => state.workFieldEvaluation.deleted);
  const isAdmin = user.roles.includes('Admin');
  const [values, setValues] = useState(initialFieldValues);

  function handleDialogOpen(dialogName, id = null) {
    if (id) {
      setValues({ workFieldEvaluationId: id, [dialogName]: true });
    } else {
      setValues({ ...values, [dialogName]: true });
    }
  }

  function handleDialogClose(dialogName) {
    setValues({ ...values, [dialogName]: false });
  }

  useEffect(() => {
    if (deletedWorkFieldEvaluation) {
      setValues((prev) => ({ ...prev, deleteWorkFieldEvaluationDialog: false }));
    }
  }, [deletedWorkFieldEvaluation]);

  function actionsButtons(link, id) {
    if (!isAdmin) return null;
    return (
      <>
        <IconButton onClick={() => { history.push(link); }} style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}>
          <Tooltip title={workFieldEvaluationTexts.editWorkFieldEvaluationTitle}>
            <CreateIcon style={{ width: 16, height: 16, color: '#F79A56' }} />
          </Tooltip>
        </IconButton>

        <IconButton onClick={() => { handleDialogOpen('deleteWorkFieldEvaluationDialog', id); }} style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}>
          <Tooltip title={workFieldEvaluationTexts.deleteWorkFieldEvaluationTitle}>
            <DeleteRoundedIcon style={{ width: 16, height: 16, color: '#727272' }} />
          </Tooltip>
        </IconButton>
      </>
    );
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'providerName',
      label: intl.formatMessage({ id: 'workFieldEvaluation.provider', defaultMessage: 'Persona evaluada' }),
    },
    {
      name: 'workFieldName',
      label: intl.formatMessage({ id: 'workFieldEvaluation.workField', defaultMessage: 'Perfil evaluado' }),
    },
    {
      name: 'evaluationStatus',
      label: intl.formatMessage({ id: 'workFieldEvaluation.result', defaultMessage: 'Resultado de evaluación' }),
      options: {
        customBodyRender: (status, meta) => {
          const id = meta.rowData[0];
          const answers = meta.rowData[5];
          const answersParsed = JSON.parse(answers);
          const allEvaluation = answersParsed && workFieldHelper
            .getWorkFieldStatus(answersParsed, answersParsed)
            .map((answer) => answer.result);
          const countEvaluated = allEvaluation && allEvaluation.filter((result) => !!result).length;
          const evaluationStatus = `${countEvaluated}/${allEvaluation.length}`;
          const popoverMessage = intl.formatMessage({ id: 'workFieldEvaluation.popoverMessage', defaultMessage: 'Varios no han completado la evaluación' }, { value: evaluationStatus });
          const link = `/workFieldEvaluations/${id}`;
          if (status === workFieldHelper.statusEnum.PENDING) {
            return (
              <Box display="flex">
                <PopoverWarningMessage id={id} message={popoverMessage} />
                <Typography key={id} component={Link} to={link} className={classes.tableBodyPending} style={{ marginLeft: '16px' }}>
                  {workFieldHelper.getEvaluationStatusLabel(status)}
                </Typography>
              </Box>
            );
          }

          return (
            <Typography key={id} component={Link} to={link} className={classes.tableBody}>
              {workFieldHelper.getEvaluationStatusLabel(status)}
            </Typography>
          );
        },
        customDownloadBodyRender: (date) => workFieldHelper.getEvaluationStatusLabel(date),
        filterOptions: { renderValue: (date) => workFieldHelper.getEvaluationStatusLabel(date) },
        customFilterListOptions: {
          render: (date) => workFieldHelper.getEvaluationStatusLabel(date),
        },
      },
    },
    {
      name: 'date',
      label: intl.formatMessage({ id: 'workFieldEvaluation.date', defaultMessage: 'Fecha de evaluación' }),
      options: {
        customBodyRender: (date) => moment(date).format('DD/MM/YYYY'),
        customDownloadBodyRender: (date) => moment(date).format('DD/MM/YYYY'),
      },
    },
    {
      name: 'answers',
      options: {
        display: 'excluded',
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, meta) => {
          const id = meta.rowData[0];
          const link = `/workFieldEvaluations/${id}/edit`;
          return actionsButtons(link, id);
        },
      },
    },
  ];

  const keys = ['id', 'providerName', 'workFieldName', 'evaluationStatus', 'date', 'answers'];
  const fetchUrl = `companies/${user.company.id}/WorkFieldEvaluations/`;
  const itemUrl = 'workFieldEvaluations/';

  function deleteWorkFieldEvaluation(id) {
    dispatch(workFieldEvaluationActions.deleteWorkFieldEvaluation(id));
  }

  function WorkFieldEvaluationPageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography component={Link} to="/providers" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {workFieldEvaluationTexts.providers}
        </Typography>
        <Typography component={Link} to="/workFieldEvaluations" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {workFieldEvaluationTexts.evaluations}
        </Typography>
      </Breadcrumbs>
    );
  }

  function ButtonGroup() {
    if (user.company.roles.includes('Admin')) {
      return (
        <Button
          text={workFieldEvaluationTexts.addButton}
          component={Link}
          to="workFieldEvaluations/new"
          style={{ fontSize: '11px', fontWeight: 600 }}
        />
      );
    }
    return null;
  }

  return (
    <PageLayout
      title={workFieldEvaluationTexts.evaluationProvider}
      breadCrumbs={<WorkFieldEvaluationPageBreadcrumbs />}
      buttonGroup={<ButtonGroup />}
    >
      <DataTable
        title={workFieldEvaluationTexts.evaluationProvider}
        fetchUrl={fetchUrl}
        itemUrl={itemUrl}
        keys={keys}
        columns={columns}
      />
      <Dialog open={values.deleteWorkFieldEvaluationDialog} onClose={() => { handleDialogClose('deleteWorkFieldEvaluationDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{workFieldEvaluationTexts.deleteWorkFieldEvaluationTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {workFieldEvaluationTexts.deleteWorkFieldEvaluation}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button text={workFieldEvaluationTexts.cancel} onClick={() => { handleDialogClose('deleteWorkFieldEvaluationDialog'); }} />
          <Button
            text={workFieldEvaluationTexts.delete}
            color="secondary"
            loading={deletingWorkFieldEvaluation}
            onClick={() => { deleteWorkFieldEvaluation(values.workFieldEvaluationId); }}
          />
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
};

export default WorkFieldEvaluationPage;
