import React, {
  useRef, useState, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {
  Card, makeStyles, CardContent, CardHeader, Divider, List, ListItem,
  ListItemSecondaryAction, ListItemText, Popover, Box, Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { deviationConstants, deviationTexts } from '../../../constants';
import { deviationActions } from '../../../redux/actions';

const useStyles = makeStyles(() => ({
  container: {
    padding: '1px 8px 0px 8px',
  },
  root: {
    boxShadow: 'none',
    marginBottom: 8,
    marginTop: 8,
  },
  cardHeader: {
    minHeight: 44,
    padding: 4,
    paddingLeft: 8,
  },
  avatarHeader: {
    marginRight: 4,
    marginTop: 1,
  },
  avatarId: {
    color: '#757575',
    fontSize: '12px',
    lineHeight: '20px',
    marginRight: 4,
    textDecoration: 'none',
  },
  title: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    textDecoration: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  iconTitle: {
    color: '#757575',
    padding: 8,
  },
  iconAction: {
    margin: 0,
    alignSelf: 'auto',
  },
  cardContent: {
    padding: 0,
  },
  listItemAvatar: {
    color: '#757575',
    marginLeft: 8,
    marginRight: 8,
    minWidth: 0,
    paddingTop: 4,
  },
  listItemText: {
    color: '#757575',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
    maxWidth: '200px',
    maxHeight: '40px',
    overflow: 'hidden',
  },
  listItemDense: {
    padding: 0,
  },
  dividerFooter: {
    height: 4,
  },
  popover: {
    pointerEvents: 'none',
  },
  paperPopoverAlert: {
    border: '1px solid',
    borderColor: '#FF0000',
    color: '#FF0000',
    boxShadow: 'none',
    maxWidth: 145,
    padding: 8,
  },
  paperPopoverVerify: {
    border: '1px solid',
    borderColor: '#71A923',
    boxShadow: 'none',
    maxWidth: 145,
    padding: 8,
  },
  textPopover: {
    fontSize: '10px',
    fontWeight: 300,
    lineHeight: '12px',
  },
}));

const ProcessDeviationCustomCard = ({
  id, title, origin, immediateAction, detectionDate, laneId,
  effectivenessVerificationDate, effectivenessVerificationMethodology,
  effectivenessVerified, documents, ...rest
}) => {
  const deviationData = {
    id,
    title,
    origin,
    documents,
    detectionDate,
    immediateAction,
    effectivenessVerificationDate,
    effectivenessVerificationMethodology,
    effectivenessVerified,
    ...rest,
  };
  const classes = useStyles();
  const alertRef = useRef();
  const intl = useIntl();
  const dispatch = useDispatch();
  const colorCard = useMemo(() => deviationConstants.origin[origin].color, [origin]);
  const translations = useMemo(() => ({
    documentsNotUpload: intl.formatMessage({ id: 'deviation.card.documentsNotUpload', defaultMessage: 'No se ha cargado ningún documento.' }),
    immediateActionAlreadyDefined: intl.formatMessage({ id: 'deviation.card.immediateActionAlreadyDefined', defaultMessage: 'Una acción inmediata ya ha sido definida.' }),
    immediateActionNotDefined: intl.formatMessage({ id: 'deviation.card.immediateActionNotDefined', defaultMessage: 'No has tomado una acción inmediata para este desvío.' }),
    effectivenessVerifiedNegativeResult: intl.formatMessage({ id: 'deviation.card.effectivenessVerifiedNegativeResult', defaultMessage: 'Revisar la verificación de efectividad.' }),
    effectivenessVerifiedPositiveResult: intl.formatMessage({ id: 'deviation.card.effectivenessVerifiedPositiveResult', defaultMessage: 'El resultado de la verificación fue exitoso.' }),
    methodologyNotDefined: intl.formatMessage({ id: 'deviation.card.methodologyNotDefined', defaultMessage: 'No has definido una metodología de verificación.' }),
  }), [intl]);
  const [anchorElAlertPopover, setAnchorElAlertPopover] = useState(null);
  const [alertMessage, setAlertMessage] = useState(false);
  const [checkedMessage, setCheckedMessage] = useState(false);
  const [isArchived, setIsArchived] = useState(rest.currentState);

  useEffect(() => {
    if (laneId === deviationConstants.status.documented.key) {
      if (immediateAction) {
        setCheckedMessage(translations.immediateActionAlreadyDefined);
      }
    }
    if (laneId === deviationConstants.status.analyzed.key) {
      if (!immediateAction) {
        setAlertMessage(translations.immediateActionNotDefined);
      }
      if ((alertMessage === translations.immediateActionNotDefined) && immediateAction) {
        setAlertMessage(false);
      }
    }
    if (laneId === deviationConstants.status.correctiveActionImplemented.key) {
      if (!documents.length) {
        setAlertMessage(translations.documentsNotUpload);
      }
      if (!effectivenessVerificationMethodology) {
        setAlertMessage(translations.methodologyNotDefined);
      }
      if (effectivenessVerificationDate
        && !effectivenessVerified
      ) {
        setAlertMessage(translations.effectivenessVerifiedNegativeResult);
      }
      if (effectivenessVerified) {
        setCheckedMessage(translations.effectivenessVerifiedPositiveResult);
      }
    }
  }, [
    documents,
    effectivenessVerificationDate,
    effectivenessVerificationMethodology,
    effectivenessVerified,
    immediateAction,
    laneId,
    translations,
    alertMessage,
  ]);

  const handleAlertPopoverOpen = () => {
    setAnchorElAlertPopover(alertRef.current);
  };
  const handleAlertPopoverClose = () => {
    setAnchorElAlertPopover(null);
  };

  const handleArchiveCard = (modifiedCardProps) => {
    setIsArchived(deviationConstants.status.archived.key);
    dispatch(deviationActions.updateDeviation(id,
      { ...deviationData, ...modifiedCardProps },
      deviationTexts.successfulArchive));
  };
  if (isArchived === deviationConstants.status.archived.key) return null;
  return (
    <Box className={classes.container}>
      <Card className={classes.root}>
        <CardHeader
          classes={{
            root: classes.cardHeader,
            action: classes.iconAction,
            avatar: classes.avatarHeader,
          }}
          avatar={(
            <Typography
              component={Link}
              to={`/deviations/${id}`}
              className={classes.avatarId}
              variant="body2"
            >
              {`#${id}`}
            </Typography>
          )}
          action={(
            <IconButton
              aria-label="settings"
              className={classes.iconTitle}
              onClick={() => {
                handleArchiveCard({ currentState: deviationConstants.status.archived.key });
              }}
            >
              <Tooltip title={deviationTexts.archive}>
                <ArchiveOutlinedIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          )}
          title={(
            <Typography
              component={Link}
              to={`/deviations/${id}`}
              className={classes.title}
              variant="body2"
            >
              {title}
            </Typography>
          )}
        />
        <Divider style={{ marginLeft: 4, marginRight: 4 }} />
        <CardContent classes={{ root: classes.cardContent }}>
          <List disablePadding>
            <ListItem
              disableGutters
              classes={{ root: classes.listItemDense }}
            >
              <ListItemText
                classes={{ root: classes.listItemAvatar }}
                primary={(
                  <Tooltip title={deviationTexts.detectionDate} placement="bottom-start">
                    <Typography className={classes.listItemText}>
                      {moment(detectionDate).format('DD/MM/YYYY')}
                    </Typography>
                  </Tooltip>
                    )}
              />
              {(alertMessage || checkedMessage) && (
              <ListItemSecondaryAction>
                <IconButton
                  ref={alertRef}
                  aria-owns={
                        anchorElAlertPopover
                          ? `mouse-over-popover-#${id}`
                          : undefined
                      }
                  aria-haspopup="true"
                  onMouseEnter={handleAlertPopoverOpen}
                  onMouseLeave={handleAlertPopoverClose}
                  onClick={handleAlertPopoverClose}
                  edge="end"
                  aria-label="status"
                >
                  {alertMessage ? (
                    <ErrorOutlineIcon style={{ color: '#FF0000' }} />
                  ) : (
                    <CheckCircleOutlineIcon style={{ color: '#71A923' }} />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
              )}
            </ListItem>
          </List>
        </CardContent>
        <Divider
          style={{ background: colorCard }}
          classes={{ root: classes.dividerFooter }}
        />
      </Card>

      <Popover
        id={`mouse-over-popover-#${id}`}
        className={classes.popover}
        classes={{
          paper: alertMessage
            ? classes.paperPopoverAlert
            : classes.paperPopoverVerify,
        }}
        open={Boolean(anchorElAlertPopover)}
        anchorEl={anchorElAlertPopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handleAlertPopoverClose}
        disableRestoreFocus
      >
        <Typography className={classes.textPopover}>
          {alertMessage || checkedMessage}
        </Typography>
      </Popover>
    </Box>
  );
};

ProcessDeviationCustomCard.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  laneId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired,
  detectionDate: PropTypes.string,
  documents: PropTypes.arrayOf(PropTypes.object),
  effectivenessVerified: PropTypes.bool,
  effectivenessVerificationMethodology: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  effectivenessVerificationDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  immediateAction: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

ProcessDeviationCustomCard.defaultProps = {
  detectionDate: null,
  documents: [],
  effectivenessVerificationDate: null,
  effectivenessVerificationMethodology: null,
  effectivenessVerified: null,
  immediateAction: null,
};

export default ProcessDeviationCustomCard;
