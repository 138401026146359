import { documentConstants } from '../../constants';

function document(state = {}, action) {
  switch (action.type) {
    case documentConstants.CREATE_REQUEST:
      return { creating: true };
    case documentConstants.CREATE_SUCCESS:
      return { creating: action.document };
    case documentConstants.CREATE_FAILURE:
      return { creating: false };

    case documentConstants.GET_REQUEST:
      return { requesting: true };
    case documentConstants.GET_SUCCESS:
      return {
        data: action.document,
      };
    case documentConstants.GET_FAILURE:
      return {};

    case documentConstants.UPDATE_REQUEST:
      return { updating: true };
    case documentConstants.UPDATE_SUCCESS:
      return {};
    case documentConstants.UPDATE_FAILURE:
      return {};

    case documentConstants.UNLINK_REQUEST:
      return { requesting: true };
    case documentConstants.UNLINK_SUCCESS:
      return {
        data: action.document,
      };
    case documentConstants.UNLINK_FAILURE:
      return {};

    case documentConstants.DELETE_FAILURE:
      return {};
    case documentConstants.DELETE_REQUEST:
      return { requesting: true };
    case documentConstants.DELETE_SUCCESS:
      return {
        data: action.document,
      };

    case documentConstants.NEW_VERSION_FAILURE:
      return { creating: false };
    case documentConstants.NEW_VERSION_REQUEST:
      return { ...state, creating: true };
    case documentConstants.NEW_VERSION_SUCCESS:
      return { data: action.document };

    case documentConstants.GET_ALL_FAILURE:
      return {};
    case documentConstants.GET_ALL_REQUEST:
      return { requesting: true };
    case documentConstants.GET_ALL_SUCCESS:
      return {
        data: action.documents,
      };

    case documentConstants.LINK_REQUEST:
      return { requesting: true };
    case documentConstants.LINK_SUCCESS:
      return {};
    case documentConstants.LINK_FAILURE:
      return {};
    default:
      return state;
  }
}

export default document;
