import axios from '../axios/axios';
import { authHeader, userCompanySelectorHelper } from '../helpers';
import handler from './handler';

function getCards() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `companies/${company.id}/cards`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAllowedActions({ fullAllowedActions }) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `companies/${company.id}/Users/allowed-actions`;
  return axios.post(postUrl, { fullAllowedActions }, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function unsubscribe() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `companies/${company.id}/subscription/cancel`;
  return axios.post(postUrl, {}, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function updateSubscribeActions(subscriptionResource) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `companies/${company.id}/subscription/update`;
  return axios.post(postUrl, subscriptionResource, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function cancelSubscriptionNotified() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `companies/${company.id}/subscription/notify-cancel`;
  return axios.post(postUrl, {}, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const stripeService = {
  getCards, getAllowedActions, unsubscribe, updateSubscribeActions, cancelSubscriptionNotified,
};

export default stripeService;
