import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableCell, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles(() => ({
  defaultCursor: {
    cursor: 'default',
  },
  textCell: {
    fontSize: 12,
  },
}));

const FormatItemTableCell = ({
  value, children, className, ...others
}) => {
  const classes = useStyles();
  const mixClassName = `${classes.defaultCursor} ${className} `;
  if (children) {
    return <TableCell className={mixClassName} {...others}>{children}</TableCell>;
  }
  if (typeof value === 'boolean') {
    return <TableCell className={mixClassName} {...others}>{value ? <CheckCircleOutlineIcon style={{ fontSize: 12 }} /> : '-'}</TableCell>;
  }
  return (
    <TableCell className={mixClassName} {...others}>
      <Typography className={classes.textCell}>
        {value}
      </Typography>
    </TableCell>
  );
};
FormatItemTableCell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.element,
    PropTypes.number,
    PropTypes.string,
  ]),
};
FormatItemTableCell.defaultProps = {
  children: '',
  className: '',
  value: '',
};

export default FormatItemTableCell;
