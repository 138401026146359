const request = {
  GET_CARDS_REQUEST: 'STRIPE_GET_CARDS_REQUEST',
  GET_CARDS_SUCCESS: 'STRIPE_GET_CARDS_SUCCESS',
  GET_CARDS_FAILURE: 'STRIPE_GET_CARDS_FAILURE',

  GET_ALLOWED_ACTION_REQUEST: 'STRIPE_GET_ALLOWED_ACTION_REQUEST',
  GET_ALLOWED_ACTION_SUCCESS: 'STRIPE_GET_ALLOWED_ACTION_SUCCESS',
  GET_ALLOWED_ACTION_FAILURE: 'STRIPE_GET_ALLOWED_ACTION_FAILURE',
};

const stripeConstants = {
  request,
};

export default stripeConstants;
