import React, { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Box, Grid,
  Typography, InputAdornment, IconButton, TextField, MenuItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '../controls/Button';
import { workFieldActions } from '../../redux/actions';
import { objectiveConstants, validationConstants, workFieldTexts } from '../../constants';
import CustomSliderWorkField from '../controls/CustomSliderWorkField';
import { workFieldHelper } from '../../helpers';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  root: {
    backgroundColor: '#F8F8F8',
    maxHeight: 450,
    overflow: 'auto',
    paddingTop: 16,
    position: 'relative',
    width: '100%',
  },
  inputContainer: {
    padding: '0 24px',
  },
  textItems: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  textTitleItems: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '18px',
  },
}));

const WorkFieldForm = ({ edit, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const creatingWorkField = useSelector((state) => state.workField.creating);
  const deletingWorkField = useSelector((state) => state.workField.deleting);
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');

  const initialFieldValues = {
    workFieldName: data && data.name ? data.name : '',
    workFieldItems: data && data.workFieldItems ? JSON.parse(data.workFieldItems) : [],
    frequency: data && data.frequency ? data.frequency : '',
    deleteWorkFieldDialog: false,
    newItem: '',
  };
  const [values, setValues] = useState(initialFieldValues);
  const [noWorkFieldItemError, setNotWorkFieldItemError] = useState(false);

  function handleDeleteWorkField() {
    dispatch(workFieldActions.deleteWorkField(data.id, workFieldTexts.successfulDelete));
  }

  function handleCreateWorkField(workField) {
    dispatch(workFieldActions.create(workField, workFieldTexts.successfulSave));
  }

  function handleSaveEditWorkField(workField) {
    dispatch(workFieldActions.update(workField.id, workField, workFieldTexts.successfulUpdate));
  }

  function getWeightPercentageAllItems(workFieldItems) {
    const totalWeightingPoint = workFieldHelper.getTotalWeightingPoints(workFieldItems);
    let lastPercentage = 100;
    return workFieldItems
      .map((item, index) => {
        const lastItem = workFieldHelper.isLastItem(index, workFieldItems);
        const percentage = workFieldHelper
          .getPercentage(item.weightingPoint, totalWeightingPoint);
        lastPercentage -= lastItem ? 0 : percentage;
        const weightPercentage = lastItem ? lastPercentage : percentage;
        return ({ ...item, weightPercentage, score: 0 });
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const auxWorkFieldItems = getWeightPercentageAllItems(values.workFieldItems);
    const workField = {
      name: values.workFieldName,
      workFieldItems: JSON.stringify(auxWorkFieldItems),
      frequency: values.frequency,
    };

    if (data && edit) {
      workField.id = data.id;
      handleSaveEditWorkField(workField);
    } else {
      handleCreateWorkField(workField);
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  }

  function handleDialogOpen(dialogName) {
    setValues((prev) => ({ ...prev, [dialogName]: true }));
  }

  function handleDialogClose(dialogName) {
    setValues((prev) => ({ ...prev, [dialogName]: false }));
  }

  const handleOnChangeWeightingPoints = (newWeightingPoint, itemName) => {
    const indexFound = values.workFieldItems.findIndex((item) => item.name === itemName);
    values.workFieldItems[indexFound].weightingPoint = newWeightingPoint;
    setValues((prev) => ({
      ...prev,
      workFieldItems: getWeightPercentageAllItems(prev.workFieldItems),
    }));
  };

  const handleClickAddWorkFieldItem = () => {
    if (values.newItem !== '') {
      const auxNewWorkFieldItem = { name: values.newItem, weightingPoint: 30 };
      values.workFieldItems.push(auxNewWorkFieldItem);
      setValues((prev) => ({ ...prev, newItem: '', workFieldItems: getWeightPercentageAllItems(prev.workFieldItems) }));
      setNotWorkFieldItemError(false);
    } else {
      setNotWorkFieldItemError(true);
    }
  };

  const handleClickRemoveWorkFieldItem = (item) => {
    const workFieldItemsAux = values.workFieldItems;
    const index = workFieldItemsAux.indexOf(item);
    if (index >= 0) {
      workFieldItemsAux.splice(index, 1);
      setValues((prev) => ({
        ...prev,
        workFieldItems: getWeightPercentageAllItems(workFieldItemsAux),
      }));
    }
  };

  return (
    <div className={classes.main}>
      <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box className={classes.inputContainer}>
              <TextValidator
                value={values.workFieldName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                id="workFieldName"
                label={workFieldTexts.name}
                name="workFieldName"
                size="small"
                validators={['required']}
                errorMessages={[validationConstants.WORK_FIELD_NAME_REQUIRED]}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box className={classes.inputContainer}>
              <Typography variant="caption" gutterBottom style={{ fontSize: '14px', fontWeight: 700 }}>
                {workFieldTexts.itemsToBeEvaluated}
              </Typography>
              <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                {workFieldTexts.addRequiredWorkFieldItems}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box className={classes.inputContainer}>
              <TextField
                error={noWorkFieldItemError}
                helperText={noWorkFieldItemError ? validationConstants.WORK_FIELD_ITEMS_REQUIRED : ''}
                value={values.newItem}
                onChange={handleChange}
                fullWidth
                margin="normal"
                name="newItem"
                label={workFieldTexts.workFieldItemsToBeEvaluated}
                type="text"
                id="newItem"
                style={{ marginTop: 0 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickAddWorkFieldItem} tabIndex="-1">
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <List className={classes.root}>
                <ListItem disableGutters>
                  <ListItemText
                    primary={workFieldTexts.item}
                    disableTypography
                    className={classes.textTitleItems}
                  />
                  <Typography variant="body1" className={classes.textTitleItems} style={{ width: '260px' }}>
                    {workFieldTexts.weightingPoints}
                  </Typography>
                </ListItem>
                {values.workFieldItems.map((item, index) => {
                  const keyId = `keyId-${index}`;
                  return (
                    <ListItem key={keyId} disableGutters>
                      <ListItemText
                        primary={item.name}
                        disableTypography
                        className={classes.textItems}
                      />
                      <CustomSliderWorkField
                        itemName={item.name}
                        weightingPoint={item.weightingPoint}
                        weightPercentage={item.weightPercentage}
                        handleOnChangeWeightingPoints={handleOnChangeWeightingPoints}
                      />
                      <IconButton
                        onClick={() => handleClickRemoveWorkFieldItem(item)}
                        tabIndex="-1"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.inputContainer}>
              <SelectValidator
                label={workFieldTexts.frequency}
                id="frequency-select"
                disabled={edit}
                value={values.frequency}
                name="frequency"
                onChange={handleChange}
                fullWidth
                validators={['required']}
                errorMessages={[workFieldTexts.isRequired]}
              >
                <MenuItem style={{ fontSize: '12px' }} value="">
                  <em>{workFieldTexts.selectOption}</em>
                </MenuItem>
                {Object.entries(objectiveConstants.frequency).map(([key, value], index) => (
                  <MenuItem
                    style={{ fontSize: '12px' }}
                    key={`select-${key + index}`}
                    value={value.key}
                  >
                    {value.label}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Box>
          </Grid>
        </Grid>

        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
          <Button
            component={Link}
            to="/workFields"
            variant="outlined"
            text={workFieldTexts.back}
            style={{ fontSize: '10px', fontWeight: 600 }}
          />
          {edit ? (
            <>
              <Button
                color="secondary"
                disabled={!isAdmin}
                onClick={() => { handleDialogOpen('deleteWorkFieldDialog'); }}
                style={{ fontSize: '10px', fontWeight: 600 }}
                text={workFieldTexts.delete}
                type="button"
              />
              <Button
                disabled={!isAdmin || !values.workFieldItems.length}
                style={{ fontSize: '10px', fontWeight: 600 }}
                loading={creatingWorkField}
                text={workFieldTexts.save}
                type="submit"
              />
            </>
          ) : (
            <Button
              disabled={!isAdmin || !values.workFieldItems.length}
              style={{ fontSize: '10px', fontWeight: 600 }}
              loading={creatingWorkField}
              text={workFieldTexts.create}
              type="submit"
            />
          )}
        </Box>
      </ValidatorForm>
      <Dialog
        open={values.deleteWorkFieldDialog}
        onClose={() => { handleDialogClose('deleteWorkFieldDialog'); }}
        fullWidth
        max-width="xs"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{workFieldTexts.deleteTitleDialog}</DialogTitle>
        <DialogContent>
          <DialogContentText>{workFieldTexts.deleteContentDialog}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            text={workFieldTexts.cancel}
            onClick={() => { handleDialogClose('deleteWorkFieldDialog'); }}
          />
          <Button
            text={workFieldTexts.delete}
            color="secondary"
            loading={deletingWorkField}
            onClick={handleDeleteWorkField}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

WorkFieldForm.propTypes = {
  data: PropTypes.shape(),
  edit: PropTypes.bool,
};

WorkFieldForm.defaultProps = {
  data: {},
  edit: false,
};

export default WorkFieldForm;
