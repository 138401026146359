import axios from '../axios/axios';
import { authHeader, userCompanySelectorHelper } from '../helpers';
import handler from './handler';

function register(user) {
  const affiliateId = localStorage.getItem('affiliate');
  const registeredWithTelecomPromo = localStorage.getItem('utm_source') === 'telecom';
  return axios.post('users/register', { ...user, affiliateId, registeredWithTelecomPromo })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function confirm(email, token, affiliate, companyId) {
  const userConfirm = { email, token, affiliate, companyId };
  return axios.post('auth/confirm', userConfirm)
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function setCompany(userCompany) {
  const headers = authHeader();
  const registeredWithTelecomPromo = localStorage.getItem('utm_source') === 'telecom';
  userCompany.registeredWithTelecomPromo = registeredWithTelecomPromo;
  return axios.post('users/companies', userCompany, { headers })
    .then(handler.handleResponse)
    .then((user) => {
      localStorage.setItem('user', JSON.stringify(user));
      return user;
    })
    .catch(handler.handleError);
}

function login(email, password) {
  const user = { email, password };
  return axios.post('auth/login', user)
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function logout() {
  handler.logout();
}

function googleLogin(token) {
  const googleToken = { token };
  return axios.post('auth/login/google', googleToken)
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function forgotPassword(email) {
  const userForgotPassword = { email };
  return axios.post('auth/password/forgot', userForgotPassword)
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function resetPassword(email, newPassword, token) {
  const userResetPassword = { email, newPassword, token };
  return axios.post('auth/password/reset', userResetPassword)
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function updatePassword(email, password, newPassword) {
  const userUpdatePassword = { email, password, newPassword };
  return axios.put('users/password', userUpdatePassword)
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function create(user) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `/companies/${company.id}/users`;
  return axios.post(postUrl, user, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function createBulkUsers(usersExcelFile) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `/companies/${company.id}/users/bulk`;
  return axios.post(postUrl, usersExcelFile, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function confirmCreateBulkUsers(id) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `/companies/${company.id}/users/bulk/${id}`;
  return axios.post(postUrl, {}, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function get(id) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/users/${id}`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAll() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/users`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function update(user) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const putUrl = `/companies/${company.id}/users/${user.id}`;
  return axios.put(putUrl, user, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function deleteUser(userId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const deleteUrl = `/companies/${company.id}/users/${userId}`;
  return axios.delete(deleteUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function requestResendEmail(email) {
  const userEmail = { email };
  return axios.post('auth/resend-email', userEmail)
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function setTutorialAsCompleted(userId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  return axios.put(`/companies/${company.id}/users/${userId}/complete-tutorial`, {}, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const userService = {
  register,
  confirm,
  setCompany,
  login,
  logout,
  googleLogin,
  forgotPassword,
  resetPassword,
  updatePassword,
  create,
  createBulkUsers,
  confirmCreateBulkUsers,
  get,
  getAll,
  update,
  deleteUser,
  requestResendEmail,
  setTutorialAsCompleted,
};

export default userService;
