import React from 'react';
import { FormattedMessage } from 'react-intl';

const deviationTexts = {
  backText: <FormattedMessage id="app.back" defaultMessage="Volver" />,
  saveText: <FormattedMessage id="app.save" defaultMessage="Guardar" />,
  cancel: <FormattedMessage id="app.cancel" defaultMessage="Cancelar" />,
  edit: <FormattedMessage id="app.edit" defaultMessage="Editar" />,
  deleteButton: <FormattedMessage id="app.delete" defaultMessage="Eliminar" />,
  deleteDeviationContentDialog: <FormattedMessage id="deviation.form.deleteDeviationContentModal" defaultMessage="¿Está seguro que quiere eliminar este desvío?" />,
  deleteDeviationTitleDialog: <FormattedMessage id="deviation.form.deleteDeviationTitleModal" defaultMessage="Eliminar desvío" />,
  successfulDelete: <FormattedMessage id="deviation.form.successfulDelete" defaultMessage="Se ha eliminado con éxito" />,
  successfulUpdate: <FormattedMessage id="deviation.form.successfulUpdate" defaultMessage="Se ha actualizado correctamente" />,
  successfulArchive: <FormattedMessage id="deviation.form.successfulArchive" defaultMessage="Se ha archivado con éxito" />,
  successfulSave: <FormattedMessage id="deviation.form.successfulSave" defaultMessage="Se ha guardado con éxito" />,
  selectOption: <FormattedMessage id="deviation.form.selectOption" defaultMessage="Seleccione una opción" />,
  state: <FormattedMessage id="deviation.form.state" defaultMessage="Estado" />,
  title: <FormattedMessage id="deviation.form.title" defaultMessage="Título" />,
  origin: <FormattedMessage id="deviation.form.origin" defaultMessage="Origen" />,
  noProcessesToSelect: <FormattedMessage id="deviation.form.noProcessesToSelect" defaultMessage="No hay procesos para seleccionar" />,
  processes: <FormattedMessage id="deviation.form.processes" defaultMessage="Procesos" />,
  detectionDate: <FormattedMessage id="deviation.form.detectionDate" defaultMessage="Fecha de detección" />,
  incorrectDate: <FormattedMessage id="deviation.form.incorrectDate" defaultMessage="Fecha incorrecta" />,
  descriptionOfDeviation: <FormattedMessage id="deviation.form.descriptionOfDeviation" defaultMessage="Descripción del desvío" />,
  immediateAction: <FormattedMessage id="deviation.form.immediateAction" defaultMessage="Acción inmediata" />,
  analysis: <FormattedMessage id="deviation.form.analysis" defaultMessage="Análisis" />,
  potentialCauseBold: <FormattedMessage
    id="deviation.form.potentialCauseBold"
    defaultMessage="<b>Causas</b> (reales o potenciales)"
    values={{
      b: (chunks) => <b>{chunks}</b>,
    }}
  />,
  potentialCause: <FormattedMessage id="deviation.form.potentialCause" defaultMessage="Causas (reales o potenciales)" />,
  correctiveAction: <FormattedMessage id="deviation.form.correctiveAction" defaultMessage="Acción correctiva/preventiva" />,
  implementationCorrectiveAction: <FormattedMessage id="deviation.form.implementationCorrectiveAction" defaultMessage="Implementación de la acción correctiva" />,
  plannedImplementationDate: <FormattedMessage id="deviation.form.plannedImplementationDate" defaultMessage="Fecha de implementación prevista" />,
  headOfImplementation: <FormattedMessage id="deviation.form.headOfImplementation" defaultMessage="Persona responsable de la implementación" />,
  effectivenessVerificationTitle: <FormattedMessage id="deviation.form.effectivenessVerificationTitle" defaultMessage="Verificación de efectividad" />,
  effectiveImplementationDate: <FormattedMessage id="deviation.form.effectiveImplementationDate" defaultMessage="Fecha de implementación efectiva" />,
  expectedEffectivenessVerificationDate: <FormattedMessage id="deviation.form.expectedEffectivenessVerificationDate" defaultMessage="Fecha prevista de verificación de efectividad" />,
  headOfEffectivenessVerification: <FormattedMessage id="deviation.form.headOfEffectivenessVerification" defaultMessage="Persona responsable de la verificación de efectividad" />,
  effectivenessVerificationMethodology: <FormattedMessage id="deviation.form.effectivenessVerificationMethodology" defaultMessage="Metodología de verificación" />,
  effectivenessVerified: <FormattedMessage id="deviation.form.effectivenessVerified" defaultMessage="Resultado de la verificación" />,
  positiveResult: <FormattedMessage id="deviation.form.positiveResult" defaultMessage="Resultado positivo" />,
  negativeResult: <FormattedMessage id="deviation.form.negativeResult" defaultMessage="Resultado negativo" />,
  archive: <FormattedMessage id="deviation.form.archive" defaultMessage="Archivar" />,
  archiveDeviation: <FormattedMessage id="deviation.form.archiveDeviation" defaultMessage="Archivar desviación" />,
  archiveConfirm: <FormattedMessage id="deviation.form.archiveConfirm" defaultMessage="¿Está seguro que desea archivarlo?" />,
  generalInformation: <FormattedMessage id="deviation.form.generalInformation" defaultMessage="Información general" />,
  effectivenessVerificationDate: <FormattedMessage id="deviation.form.effectivenessVerificationDate" defaultMessage="Fecha de verificación efectiva" />,
  changeHistory: <FormattedMessage id="deviation.form.changeHistory" defaultMessage="Historial de cambios" />,
  versionAuthor: <FormattedMessage id="deviation.form.versionAuthor" defaultMessage="Autor" />,
  versionDate: <FormattedMessage id="deviation.form.versionDate" defaultMessage="Fecha y hora" />,
  viewVersionLink: <FormattedMessage id="deviation.form.viewVersionLink" defaultMessage="Ver versión" />,
  processesLoading: <FormattedMessage id="deviation.form.processesLoading" defaultMessage="Cargando procesos" />,

  deviationStatusRequired: <FormattedMessage id="validation.deviationStatusRequired" defaultMessage="Seleccione el estado actual" />,
  deviationTitleRequired: <FormattedMessage id="validation.deviationTitleRequired" defaultMessage="Ingrese el título de la desviación" />,
  deviationOriginRequired: <FormattedMessage id="validation.deviationOriginRequired" defaultMessage="Seleccione el origen" />,
  deviationProcessesRequired: <FormattedMessage id="validation.deviationProcessesRequired" defaultMessage="Seleccione los procesos involucrados" />,
  deviationDefaultRequired: <FormattedMessage id="validation.deviationDefaultRequired" defaultMessage="Este campo es requerido para continuar" />,
  deviationEffectivenessVerified: <FormattedMessage id="validation.deviationEffectivenessVerified" defaultMessage="Seleccione el resultado de la verificacion" />,
  deviationEffectivenessVerificationDate: <FormattedMessage id="validation.deviationEffectivenessVerificationDate" defaultMessage="Ingrese la fecha correspondiente" />,

};

export default deviationTexts;
