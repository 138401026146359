import { Breadcrumbs, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Home from '../../Home/LoggedInLayout';
import ProcessMap from './ProcessMap';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  breadcrumbs: {
    padding: '0 0 0 40px',
    position: 'relative',
    zIndex: 2,
    top: '16px',
  },
}));

function ProcessMapPage() {
  const classes = useStyles();
  const intl = useIntl();
  const processText = intl.formatMessage({ id: 'processes.title', defaultMessage: 'Procesos' });
  const mapText = intl.formatMessage({ id: 'processMap.map', defaultMessage: 'Mapa' });
  const editText = intl.formatMessage({ id: 'app.edit', defaultMessage: 'Editar' });
  const [isEditModeForBreadcrumbs, setIsEditModeForBreadcrumbs] = useState(false);
  return (
    <Home>
      <div className={classes.main}>
        <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
          <Link color="inherit" to="/processes" style={{ textDecoration: 'none' }}>
            <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{processText}</Typography>
          </Link>
          <Link color="inherit" to="/processes/map" style={{ textDecoration: 'none' }}>
            <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{mapText}</Typography>
          </Link>
          {isEditModeForBreadcrumbs && (
            <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{editText}</strong></Typography>
          )}
        </Breadcrumbs>
        <ProcessMap setIsEditModeForBreadcrumbs={setIsEditModeForBreadcrumbs} />
      </div>
    </Home>
  );
}

export default ProcessMapPage;
