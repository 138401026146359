import React from 'react';
import { SvgIcon } from "@material-ui/core";

function FodaIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M0.568115 18H2.55675V16H0.568115V18ZM4.54539 18H6.53402V16H4.54539V18ZM2.55675 4H0.568115V6H2.55675V4ZM0.568115 14H2.55675V12H0.568115V14ZM6.53402 0H4.54539V2H6.53402V0ZM2.55675 0H0.568115V2H2.55675V0ZM14.4886 0H12.4999V2H14.4886V0ZM16.4772 6H18.4658V4H16.4772V6ZM16.4772 0V2H18.4658V0H16.4772ZM12.4999 18H14.4886V16H12.4999V18ZM10.5113 0H8.52266V8H0.568115V10H8.52266V18H10.5113V10H18.4658V8H10.5113V0ZM16.4772 18H18.4658V16H16.4772V18ZM16.4772 14H18.4658V12H16.4772V14Z" />
      </SvgIcon>
    );
  }

  export default FodaIcon