import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Box, Breadcrumbs, Typography, makeStyles, LinearProgress,
} from '@material-ui/core';
import Board from 'react-trello';
import { useDispatch, useSelector } from 'react-redux';
import PageLayout from '../../PageLayout';
import CustomCard from './Component/CustomCard';
import CustomLaneHeader from './Component/CustomLaneHeader';
import Button from '../../controls/Button';
import { alertActions, deviationActions } from '../../../redux/actions';
import { deviationConstants, deviationTexts } from '../../../constants';
import { deviationHelper } from '../../../helpers';
import ModalDragAndDropConfirm from './Component/ModalDragAndDropConfirm';

const useStyles = makeStyles(() => ({
  boardContainer: {
    '& .smooth-dnd-container.vertical': {
      minHeight: '50vh',
    },
  },
}));

const initialValues = {
  lanes: [{
    id: 'loading', title: 'loading',
  }],
};
const initialCurrentCard = {
  cardDetails: {
    title: '',
  },
  cardId: '',
  fromLaneId: '',
  toLaneId: 'loading',
};
const DeviationBoardPage = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const deviations = useSelector((state) => state.deviation.deviations);
  const getAllSuccess = useSelector((state) => state.deviation.getAllSuccess);
  const errorRequest = useSelector((state) => state.deviation.error);
  const updatedDeviation = useSelector((state) => state.deviation.updated);
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const [boardData, setBoardData] = useState(initialValues);
  const [eventBus, setEventBus] = useState(undefined);
  const [openModal, setOpenDialog] = useState(false);
  const [currentCard, setCurrentCard] = useState(initialCurrentCard);
  const [changedBoardData, setChangedBoardData] = useState(initialValues);
  const [laneBoardWidth, setLaneBoardWidth] = useState(297);
  const translations = useMemo(() => ({
    title: intl.formatMessage({ id: 'deviation.title', defaultMessage: 'Desvíos' }),
    boardDeviation: intl.formatMessage({ id: 'deviation.boardDeviation', defaultMessage: 'Tablero' }),
    addButtonText: intl.formatMessage({ id: 'deviation.add.title', defaultMessage: '+ Crear nuevo' }),
    warningImmediateActionNone: intl.formatMessage({ id: 'deviation.warningImmediateActionNone', defaultMessage: 'Aún no tomó una acción inmediata, desea continuar?' }),
    alertImmediateActionNone: intl.formatMessage({ id: 'deviation.alertImmediateActionNone', defaultMessage: 'No puede mover esta tarjeta, porque no tomó una acción inmediata' }),
    warningMovement: intl.formatMessage({ id: 'deviation.movementNotAllowedWarning', defaultMessage: 'Solo se puede mover al estado siguiente inmediato' }),
  }), [intl]);

  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width: containerWidth } = containerElem.getBoundingClientRect();
      if (containerWidth > 1275) setLaneBoardWidth((containerWidth / 4.20));
    }
  }, []);

  useEffect(() => {
    if (deviations) setBoardData(deviationHelper.toBoardData(deviations));
  }, [deviations]);

  useEffect(() => {
    dispatch(deviationActions.getAllDeviations());
  }, [dispatch]);

  useEffect(() => {
    if (errorRequest) {
      eventBus.publish({
        type: 'REFRESH_BOARD',
        data: boardData,
      });
    }
  }, [errorRequest, eventBus, boardData]);

  useEffect(() => {
    if (updatedDeviation) {
      setBoardData(changedBoardData);
      setOpenDialog(false);
    }
  }, [updatedDeviation, changedBoardData]);

  const toggleModalConfirm = useCallback(() => {
    setOpenDialog(!openModal);
  }, [openModal]);

  const deviationMovementIsAllowed = (source, target) => {
    if (source === deviationConstants.status.documented.key
      && target === deviationConstants.status.analyzed.key
    ) return true;
    if (source === deviationConstants.status.analyzed.key
      && target === deviationConstants.status.correctiveActionDefined.key
    ) return true;
    if (source === deviationConstants.status.correctiveActionDefined.key
      && target === deviationConstants.status.correctiveActionImplemented.key
    ) return true;
    return false;
  };

  const backwardDeviationMovementsIsAllowed = (source, target) => {
    if (source === deviationConstants.status.analyzed.key
      && target === deviationConstants.status.documented.key
    ) return true;
    if (source === deviationConstants.status.correctiveActionDefined.key
      && target !== deviationConstants.status.correctiveActionImplemented.key
    ) return true;
    if (source === deviationConstants.status.correctiveActionImplemented.key) return true;
    return false;
  };

  const handleDragEnd = (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
    if (sourceLaneId === targetLaneId) return false;
    if (deviationMovementIsAllowed(sourceLaneId, targetLaneId)) {
      setCurrentCard({
        fromLaneId: sourceLaneId,
        toLaneId: targetLaneId,
        cardId,
        cardDetails,
      });
      toggleModalConfirm();
    } else if (backwardDeviationMovementsIsAllowed(sourceLaneId, targetLaneId)) {
      dispatch(
        deviationActions.updateDeviation(cardId,
          { ...cardDetails, currentState: targetLaneId }, deviationTexts.successfulUpdate),
      );
      return true;
    } else dispatch(alertActions.warning(translations.warningMovement));
    return false;
  };

  const onBoardDataChange = (newData) => {
    setChangedBoardData(newData);
  };

  const customBoardComponents = {
    Card: CustomCard,
    LaneHeader: CustomLaneHeader,
  };

  function DeviationBoardPageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/deviations" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{translations.title}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{translations.boardDeviation}</strong></Typography>
      </Breadcrumbs>
    );
  }

  function ButtonGroup() {
    if (!isAdmin) return null;
    return (
      <Button
        text={translations.addButtonText}
        component={Link}
        to="new"
        style={{ fontSize: '11px', fontWeight: 600 }}
      />
    );
  }

  return (
    <PageLayout
      title={translations.title}
      breadCrumbs={<DeviationBoardPageBreadcrumbs />}
      buttonGroup={<ButtonGroup />}
    >
      <Box pl={2} className={classes.boardContainer} ref={containerRef}>
        {!getAllSuccess && <LinearProgress />}
        <Board
          draggable
          cardDraggable={isAdmin}
          data={boardData}
          components={customBoardComponents}
          style={{ backgroundColor: 'transparent', height: 'auto' }}
          cardStyle={{ width: (laneBoardWidth * 0.87) }}
          laneStyle={{
            backgroundColor: '#EBEBEB',
            borderRadius: '8px',
            width: laneBoardWidth,
            height: '67vh',
            maxHeight: 'none',
            whiteSpace: 'normal',
          }}
          laneDraggable={false}
          handleDragEnd={handleDragEnd}
          eventBusHandle={setEventBus}
          onDataChange={onBoardDataChange}
        />
        <ModalDragAndDropConfirm
          currentCard={currentCard}
          eventBus={eventBus}
          openModal={openModal}
          toggleModalConfirm={toggleModalConfirm}
        />
      </Box>
    </PageLayout>
  );
};

export default DeviationBoardPage;
