import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import {
  Box, Typography, makeStyles, Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import GaugeChart from 'react-gauge-chart';
import { Link } from 'react-router-dom';
import { objectiveConstants, objectiveTexts } from '../../../constants';
import { objectiveHelper } from '../../../helpers';

const useStyles = makeStyles(() => ({
  containerCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderTop: 'solid thick',
    height: 212,
    width: 190,
  },
  textTitle: {
    color: '#212121',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '19px',
    margin: '16px',
    minHeight: '38px',
    overflow: 'hidden',
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  valueObjectiveCard: {
    color: '#757575',
    fontSize: '12px',
    fontWeight: 700,
  },
  valueMeasurementCard: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '10px',
  },
  valueObjective: {
    color: '#757575',
    fontSize: '20px',
    fontWeight: 700,
  },
  valueMeasurement: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '10px',
  },
  textSubtitle: {
    color: '#757575',
    fontSize: '14px',
    paddingTop: '8px',
  },
}));

const ObjectiveGauge = ({
  annualTarget,
  id,
  measures,
  operator,
  isCard,
  title,
  isAccumulativeObjective,
}) => {
  const classes = useStyles();
  const [lastMeasure, setLastMeasure] = useState(0);
  const invalidData = useMemo(() => [null, undefined, NaN, ''], []);
  const redColor = '#F22525';
  const greenColor = '#43A047';
  const STEPS_QUANTITY = 8;
  const isBooleanOperator = operator === objectiveConstants.BOOLEAN_OPERATOR;

  useEffect(() => {
    const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
    if (measures) {
      if (isAccumulativeObjective) {
        setLastMeasure(objectiveHelper.calculateAccObjective(measures));
      } else {
        months.forEach((month) => {
          if (!invalidData.includes(measures[month])) {
            setLastMeasure(measures[month]);
          }
        });
      }
    }
  }, [measures, invalidData, isAccumulativeObjective]);

  const completeColor = useCallback((quantToReplace, color) => {
    const defaultGaugeColor = Array(8).fill('#D6D6D6');
    const colorArc = defaultGaugeColor.map((item, index) => {
      if (index < quantToReplace) return color;
      return item;
    });
    return { colorArc, color };
  },
  []);

  const getPercentage = useCallback((value, maxValue) => {
    if (isBooleanOperator) { return value; }
    const calc = (value * 0.5) / Number(maxValue);
    return calc < 1 ? calc : 1;
  }, [isBooleanOperator]);

  const getColorGauge = useCallback((value, maxValue) => {
    const STEP_INCREMENT = 1 / STEPS_QUANTITY;
    const percent = getPercentage(value, maxValue);
    const stepsFilled = percent / STEP_INCREMENT;
    const minorOperators = ['<=', '<'];
    const unequalOperators = ['<', '>'];
    let rightColor = greenColor;
    let leftColor = redColor;
    if (operator === '=') {
      rightColor = redColor;
    }
    if (minorOperators.includes(operator)) {
      rightColor = redColor;
      leftColor = greenColor;
    }
    if (unequalOperators.includes(operator) && percent === 0.5) {
      return completeColor(4, redColor);
    }
    if (percent === 0.5) {
      return completeColor(4, greenColor);
    }
    if (stepsFilled <= STEPS_QUANTITY / 2) {
      return completeColor(stepsFilled, leftColor);
    }
    return completeColor(stepsFilled, rightColor);
  },
  [completeColor, getPercentage, operator]);

  return (
    <Box
      className={isCard ? classes.containerCard : ''}
      style={{ borderTopColor: getColorGauge(lastMeasure, annualTarget).color }}
    >
      {isCard && (
        <Typography
          align="center"
          component={Link}
          to={`/objectives/${id}`}
          className={classes.textTitle}
          variant="body1"
        >
          {title}
        </Typography>
      )}
      <Tooltip title={objectiveTexts.annualTarget} aria-label="valueObjective" placement="top">
        <Typography
          align="center"
          className={isCard ? classes.valueObjectiveCard : classes.valueObjective}
          variant="body1"
        >
          {isBooleanOperator
            ? objectiveConstants.translateOperators(operator)
            : `${operator}  ${new Intl.NumberFormat('es-AR').format(annualTarget)}`}
        </Typography>
      </Tooltip>
      <GaugeChart
        id="gauge-chart"
        percent={getPercentage(lastMeasure, annualTarget)}
        nrOfLevels={STEPS_QUANTITY}
        colors={getColorGauge(lastMeasure, annualTarget).colorArc}
        needleColor="#D6D6D6"
        needleBaseColor="#D6D6D6"
        hideText
        cornerRadius={isCard ? 3 : 6}
      />
      <Tooltip disableHoverListener={!isCard} title={isAccumulativeObjective ? objectiveTexts.accumulativeTotal : objectiveTexts.lastMeasure} aria-label="titleObjective">
        <Typography
          align="center"
          className={isCard ? classes.valueMeasurementCard : classes.valueMeasurement}
          style={{ color: getColorGauge(lastMeasure, annualTarget).color }}
          variant="body1"
        >
          {measures
            ? objectiveHelper.formattedValue(lastMeasure, isBooleanOperator)
            : '-'}
        </Typography>
      </Tooltip>
      {!isCard && (
        <Typography
          align="center"
          className={classes.textSubtitle}
          variant="body1"
        >
          {isAccumulativeObjective ? objectiveTexts.accumulativeTotal : objectiveTexts.lastMeasure}
        </Typography>
      )}
    </Box>
  );
};

ObjectiveGauge.propTypes = {
  annualTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isCard: PropTypes.bool,
  isAccumulativeObjective: PropTypes.bool,
  measures: PropTypes.shape(),
  operator: PropTypes.string,
  title: PropTypes.string,
};

ObjectiveGauge.defaultProps = {
  annualTarget: null,
  id: null,
  isCard: false,
  isAccumulativeObjective: false,
  measures: {},
  operator: null,
  title: null,
};

export default ObjectiveGauge;
