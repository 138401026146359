import React, { useEffect } from 'react';
import {
  Breadcrumbs, Typography, makeStyles, LinearProgress,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import WorkFieldEvaluationForm from '../WorkFieldEvaluationForm';
import { personActions, providerActions } from '../../../redux/actions';
import PageLayout from '../../PageLayout';
import { providersTexts, workFieldEvaluationTexts } from '../../../constants';

const useStyles = makeStyles(() => ({
  breadcrumbs: {
    textDecoration: 'none',
    fontWeight: 400,
    fontSize: 11,
  },
}));

const NewWorkFieldEvaluationPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const people = useSelector((state) => state.person.people);
  const providers = useSelector((state) => state.provider.providers);

  useEffect(() => {
    if (!people) {
      dispatch(personActions.getAll());
    }
    if (!providers) {
      dispatch(providerActions.getAll());
    }
  }, [dispatch, people, providers]);

  function NewWorkFieldEvaluationBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography component={Link} to="/providers" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {providersTexts.providers}
        </Typography>
        <Typography component={Link} to="/workFieldEvaluations" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {workFieldEvaluationTexts.evaluations}
        </Typography>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{workFieldEvaluationTexts.newTitle}</Typography>
      </Breadcrumbs>
    );
  }

  function NewWorkFieldEvaluationLoadingBreadcrumbs() {
    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Typography component={Link} to="/providers" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {providersTexts.providers}
        </Typography>
        <Typography component={Link} to="/workFieldEvaluations" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {workFieldEvaluationTexts.evaluations}
        </Typography>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{workFieldEvaluationTexts.newTitle}</Typography>
      </Breadcrumbs>
    );
  }

  return (
    <>
      {providers && people ? (
        <PageLayout
          title={workFieldEvaluationTexts.newTitle}
          breadCrumbs={<NewWorkFieldEvaluationBreadcrumbs />}
        >
          <WorkFieldEvaluationForm people={people} providers={providers} />
        </PageLayout>
      ) : (
        <PageLayout
          title={workFieldEvaluationTexts.newTitle}
          breadCrumbs={<NewWorkFieldEvaluationLoadingBreadcrumbs />}
        >
          <LinearProgress />
        </PageLayout>
      )}
    </>
  );
};

export default NewWorkFieldEvaluationPage;
