import React, { useEffect } from 'react';
import {
  Breadcrumbs, Typography, LinearProgress, makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PageLayout from '../../PageLayout';
import workFieldActions from '../../../redux/actions/workField.actions';
import { providersTexts, workFieldTexts } from '../../../constants';
import WorkFieldViewDetail from './WorkFieldViewDetail';

const useStyles = makeStyles(() => ({
  breadcrumbs: {
    textDecoration: 'none',
    fontWeight: 400,
    fontSize: 11,
  },
}));

const WorkFieldDetailsPage = ({ routeParams: { id: workFieldId } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const workField = useSelector((state) => state.workField.data);

  useEffect(() => {
    dispatch(workFieldActions.get(workFieldId));
  }, [dispatch, workFieldId]);

  function WorkFieldDetailsBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography component={Link} to="/providers" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {providersTexts.providers}
        </Typography>
        <Typography component={Link} to="/workFields" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {workFieldTexts.workFields}
        </Typography>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{workField.name}</Typography>
      </Breadcrumbs>
    );
  }

  function WorkFieldDetailsLoadingBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography component={Link} to="/providers" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {providersTexts.providers}
        </Typography>
        <Typography component={Link} to="/workFields" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {workFieldTexts.workFields}
        </Typography>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{workFieldTexts.workFieldsDetails}</Typography>
      </Breadcrumbs>
    );
  }

  return (
    <>
      {workField
        ? (
          <PageLayout title={workField.name} breadCrumbs={<WorkFieldDetailsBreadcrumbs />}>
            <WorkFieldViewDetail data={workField} />
          </PageLayout>
        )
        : (
          <PageLayout
            title={workFieldTexts.workFieldsDetails}
            breadCrumbs={<WorkFieldDetailsLoadingBreadcrumbs />}
          >
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
};

WorkFieldDetailsPage.propTypes = {
  routeParams: PropTypes.shape(),
};

WorkFieldDetailsPage.defaultProps = {
  routeParams: {},
};

export default WorkFieldDetailsPage;
