import { personConstants } from '../../constants';

function person(state = {}, action) {
  switch (action.type) {
    case personConstants.CREATE_REQUEST:
      return { creating: true };
    case personConstants.CREATE_SUCCESS:
      return { createdPerson: action.createdPerson };
    case personConstants.CREATE_FAILURE:
      return { creating: false };

    case personConstants.GET_REQUEST:
      return { requesting: true };
    case personConstants.GET_SUCCESS:
      return { data: action.person, imageUrl: action.person.imageUrl };
    case personConstants.GET_FAILURE:
      return {};

    case personConstants.GET_ALL_REQUEST:
      return { requesting: true };
    case personConstants.GET_ALL_SUCCESS:
      return { people: action.people };
    case personConstants.GET_ALL_FAILURE:
      return {};

    case personConstants.UPDATE_REQUEST:
      return { data: state.data, updating: true };
    case personConstants.UPDATE_SUCCESS:
      return { data: action.updatedPerson };
    case personConstants.UPDATE_FAILURE:
    case personConstants.CLEAN_CREATED_PROCESS:
      return {};

    case personConstants.DELETE_REQUEST:
      return { deleting: true };
    case personConstants.DELETE_SUCCESS:
      return { };
    case personConstants.DELETE_FAILURE:
      return {};

    case personConstants.PERSONAL_PHOTO_SUCCESS:
      return { ...state, imageUrl: action.person.imageUrl };
    case personConstants.PERSONAL_PHOTO_FAILURE:
      return {};

    case personConstants.LINK_USER_REQUEST:
      return { ...state, linking: true };
    case personConstants.LINK_USER_SUCCESS:
      return { ...state, linking: false };
    case personConstants.LINK_USER_FAILURE:
      return { ...state, linking: false };

    case personConstants.UNLINK_USER_REQUEST:
      return { ...state, linking: true };
    case personConstants.UNLINK_USER_SUCCESS:
      return { ...state, linking: false };
    case personConstants.UNLINK_USER_FAILURE:
      return { ...state, linking: false };
    case personConstants.CLEAN_CREATED_PERSON:
      return {};
    default:
      return state;
  }
}

export default person;
