/* eslint-disable max-len */
import React, { useState, useCallback } from 'react';
import {
  Box,
  Typography, makeStyles, Table, TableHead, TableRow, TableBody, TableCell,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { objectiveConstants, objectiveTexts } from '../../../constants';
import SetNewMeasureModal from '../SetNewMeasureModal/SetNewMeasureModal';
import { objectiveHelper } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
  textSeparator: {
    marginLeft: '24px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
    textAlign: 'initial',
  },
  tableBody: {
    padding: '8px 16px 8px 16px',
    fontSize: '12px',
    cursor: 'default',
  },
  tableHead: {
    fontSize: '12px',
    paddingTop: '0px',
    fontWeight: 700,
    cursor: 'default',
  },
  tableLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 600,
    textAlign: 'center',
    fontSize: '11px',
    textTransform: 'uppercase',
    paddingLeft: '4px',
  },
  tableValuesRed: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    padding: '8px 16px 8px 16px',
    fontSize: '12px',
    cursor: 'default',
  },
  tableValues: {
    fontWeight: 600,
    padding: '8px 16px 8px 16px',
    fontSize: '12px',
    cursor: 'default',
  },
}));

const ObjectiveMeasures = ({
  measureFrequency, measures, year, showNewMeasure, isAccumulativeObjective, operator,
}) => {
  const classes = useStyles();
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const [openNewMeasureModal, setOpenNewMeasureModal] = useState(false);
  const [selectedMeasure, setSelectedMeasure] = useState(null);
  const invalidData = [null, undefined, NaN, ''];
  const accumulatedMeasures = { ...measures };
  let previousValue = 0;
  const isBooleanOperator = operator === objectiveConstants.BOOLEAN_OPERATOR;

  const toggleNewMeasureModal = useCallback(((value) => {
    setOpenNewMeasureModal(value);
  }), []);

  function handleEditMeasures(value) {
    setSelectedMeasure(value);
    toggleNewMeasureModal(true);
  }

  return (
    <>
      <Typography className={classes.textSeparator}>
        {year}
      </Typography>

      <Box pl={3} pr={3} pb={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead} width="30%" size="small" style={{ paddingLeft: '0px' }}>{objectiveTexts.monthTitle}</TableCell>
              <TableCell className={classes.tableHead} width="40%" size="small">{objectiveTexts.valueTitle}</TableCell>
              {isAccumulativeObjective && <TableCell className={classes.tableHead} width="20%" size="small">Acumulado</TableCell>}
              {showNewMeasure && <TableCell className={classes.tableHead} width="10%" size="small" />}
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(objectiveConstants.frequency[measureFrequency]) && objectiveConstants.frequency[measureFrequency].values.map((item) => {
              if (measures && !invalidData.includes(measures[item])) {
                accumulatedMeasures[item] = previousValue + measures[item];
                previousValue = accumulatedMeasures[item];
              }
              return (
                <TableRow key={item}>
                  <TableCell className={classes.tableBody} width="30%" size="small" style={{ paddingLeft: '0px' }}>{objectiveTexts[item]}</TableCell>
                  <TableCell
                    className={
                      (measures && !invalidData.includes(measures[item]))
                      || moment().month(item).year(year).isSameOrAfter(moment())
                        ? classes.tableValues
                        : classes.tableValuesRed
                    }
                  >
                    {(measures && !invalidData.includes(measures[item]))
                      ? objectiveHelper.formattedValue(measures[item], isBooleanOperator)
                      : objectiveTexts.withoutMeasure}
                  </TableCell>
                  {isAccumulativeObjective && (
                  <TableCell
                    className={
                      (measures && !invalidData.includes(measures[item]))
                      || moment().month(item).year(year).isSameOrAfter(moment())
                        ? classes.tableValues
                        : classes.tableValuesRed
                    }
                  >
                    {(accumulatedMeasures && !invalidData.includes(accumulatedMeasures[item]))
                      ? new Intl.NumberFormat('es-AR').format(accumulatedMeasures[item])
                      : new Intl.NumberFormat('es-AR').format(previousValue)}
                  </TableCell>
                  )}
                  {isAdmin && showNewMeasure && (
                  <TableCell width="10%" size="small" className={`${classes.tableBody} ${classes.tableLink}`} onClick={() => handleEditMeasures(item)}>
                    <Box display="flex">
                      <ShowChartIcon style={{ width: 16, height: 16, color: '#F79A56' }} />
                      <Typography className={classes.tableLink}>
                        {objectiveTexts.measure}
                      </Typography>
                    </Box>
                  </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      {showNewMeasure && (
      <SetNewMeasureModal
        openNewMeasureModal={openNewMeasureModal}
        toggleNewMeasureModal={toggleNewMeasureModal}
        measure={selectedMeasure}
        isBooleanOperator={isBooleanOperator}
      />
      )}
    </>
  );
};

ObjectiveMeasures.propTypes = {
  measureFrequency: PropTypes.string,
  measures: PropTypes.shape(),
  year: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  showNewMeasure: PropTypes.bool,
  isAccumulativeObjective: PropTypes.bool,
  operator: PropTypes.string,
};

ObjectiveMeasures.defaultProps = {
  measureFrequency: null,
  measures: {},
  year: null,
  showNewMeasure: true,
  isAccumulativeObjective: false,
  operator: null,
};

export default ObjectiveMeasures;
