import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {
  MenuItem,
  Menu,
  ListItemIcon,
  Box,
  Button,
  Hidden,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SecurityIcon from '@material-ui/icons/Security';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import pertixLogo from '../../assets/pertix-logo.svg';
import SetNewPasswordModal from './SetNewPasswordModal';
import ImpersonatingHeader from './ImpersonatingHeader';
import PaymentErrorHeader from './PaymentErrorHeader';
import { stripePlanSpecs } from '../../constants';
import TutorialTooltip from '../controls/TutorialTooltip';
import { STEPS } from '../../constants/tutorial.constants';
import tutorialActions from '../../redux/actions/tutorial.actions';
import { history } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    borderBottom: '1px solid #DADADA',
  },
  logo: {
    position: 'relative',
    width: '145px',
    height: '52px',
  },
  item: {
    fontSize: '12px',
    fontWeight: '400',
  },
  navbarItem: {
    fontSize: '11px',
    fontWeight: '500',
  },
  personalPhoto: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.getContrastText(theme.palette.primary.main),
    height: '35px',
    width: '35px',
  },
}));

const initialFieldValues = {
  changePasswordDialog: false,
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
  showPassword: false,
};

export default function PertixAppBar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector((state) => state.authentication.user);
  const company = useSelector((state) => state.company.data);
  const impersonator = useSelector((state) => state.impersonate.data);
  const step = useSelector((state) => state.tutorial.step);
  const openAuth = Boolean(anchorEl);
  const intl = useIntl();
  const contactText = intl.formatMessage({ id: 'app.contact', defaultMessage: 'Contactar asesor' });
  const plansText = intl.formatMessage({ id: 'app.plans', defaultMessage: 'Planes' });
  const [values, setValues] = useState(initialFieldValues);
  const {
    changePasswordDialog,
  } = values;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleDialogOpen(dialogName) {
    setValues({ [dialogName]: true });
  }

  function handleDialogClose(dialogName) {
    setValues({
      ...values,
      [dialogName]: false,
    });
  }

  function handleCloseTutorialTooltip() {
    dispatch(tutorialActions.setTutorialStep(6));
    history.push('/orgchart');
  }

  return (
    <AppBar position="fixed" elevation={0} color="inherit" className={classes.appBar}>

      {company
      && stripePlanSpecs.subscriptionStatusInvalid(company.subscriptionStatus)
      && <PaymentErrorHeader />}

      {!!impersonator && <ImpersonatingHeader />}

      <Toolbar>
        <Box display="flex" flexGrow={1}>
          <Link to="/home">
            <img alt="" src={pertixLogo} className={classes.logo} />
          </Link>
        </Box>
        <div>
          <Hidden smDown>
            <TutorialTooltip
              open={step === 5}
              interactive
              title={
                <Box display="flex">
                  <Box>
                    {STEPS[4].text}
                    {' '}
                    <a href='https://www.youtube.com/@pertixtech' target="_blank" rel="noopener noreferrer" style={{ fontWeight: 700, color: '#FFF' }}>
                      {STEPS[4].link}
                    </a>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <IconButton style={{ padding: 0, color: '#FFF' }}>
                      <CloseIcon onClick={handleCloseTutorialTooltip} />
                    </IconButton>
                  </Box>
                </Box>
              }
              arrow
            >
              <Box component="div" mr={4} display="inline">
                <Button
                  color="inherit"
                  className={classes.navbarItem}
                  component="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://wa.me/+541151617701"
                >
                  {contactText}
                </Button>
              </Box>
            </TutorialTooltip>
            {user && company && user.id === company.creatorUser && (
            <Box component="div" mr={5} display="inline">
              <Button color="inherit" component={Link} to="/stripe/plans" className={classes.navbarItem}>{plansText}</Button>
            </Box>
            )}
            <Box component="div" display="inline" className={classes.navbarItem} style={{ textTransform: 'uppercase' }}>
              { (user.company && user.company.person)
                ? `${user.company.person.names} ${user.company.person.surnames}`
                : user.email}
            </Box>
          </Hidden>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            { (user.company && user.company.person && user.company.person.imageUrl)
              ? (
                <Avatar
                  alt={`${user.company.person.names} ${user.company.person.surnames}`}
                  src={user.company.person.imageUrl}
                  className={classes.personalPhoto}
                />
              )
              : <AccountCircle color="primary" fontSize="large" />}
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={openAuth}
            onClose={handleClose}
          >
            <MenuItem component={Link} to="/user/profile">
              <ListItemIcon style={{ minWidth: '32px' }}>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <span className={classes.item}>Editar perfil</span>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon style={{ minWidth: '32px' }}>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <span className={classes.item}>Configuración</span>
            </MenuItem>
            <MenuItem onClick={() => { handleDialogOpen('changePasswordDialog'); }}>
              <ListItemIcon style={{ minWidth: '32px' }}>
                <SecurityIcon fontSize="small" />
              </ListItemIcon>
              <span className={classes.item}>Modificar contraseña</span>
            </MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/logout">
              <ListItemIcon style={{ minWidth: '32px' }}>
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              <span className={classes.item}>Cerrar sesión</span>
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
      <Divider />
      <SetNewPasswordModal isOpen={changePasswordDialog} close={() => (handleDialogClose('changePasswordDialog'))} />

    </AppBar>
  );
}
