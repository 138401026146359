import React, { useState } from 'react';
import {
  makeStyles, Typography, Container, Grid, List, ListItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import TermsAndConditionsModal from '../controls/TermsAndConditionsModal';
import { privacyPolicyText, termsAndConditionsText } from '../../constants';

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: 'auto',
    backgroundColor: '#757575',
    height: '81px',
  },
  container: {
    paddingLeft: '152px',
    paddingRight: '152px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      paddingRight: '32px',
    },
  },
  itemFooter: {
    textDecoration: 'none',
    fontSize: '10px',
    color: 'white',
    cursor: 'pointer',
  },
}));

const initialModalState = {
  openTermsAndConditions: false,
  openPrivacyPolicy: false,
};

export default function PertixFooter() {
  const classes = useStyles();
  const [modalState, setModalState] = useState(initialModalState);

  function handleModalState(name, value) {
    setModalState({ ...modalState, [name]: value });
  }

  return (
    <footer className={classes.footer}>
      <Container className={classes.container}>
        <Grid container spacing={0}>
          <Grid item xs={4} sm={4}>
            <List dense>
              <ListItem>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  style={{ color: 'white', fontSize: '10px' }}
                >
                  <strong>PRODUCTO</strong>
                </Typography>
              </ListItem>
              <ListItem style={{ paddingTop: '0' }}>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                >
                  <Link to="/stripe/plans" className={classes.itemFooter}>
                    <FormattedMessage id="app.plansAndPrice " defaultMessage="Planes y precio" />
                  </Link>

                </Typography>
              </ListItem>
              <ListItem style={{ paddingTop: '0' }}>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                >
                  <a target="_blank" rel="noopener noreferrer" href="mailto:soporte.latam@pertixtech.com" className={classes.itemFooter}>
                    <FormattedMessage id="app.requestSupport " defaultMessage="Pedir soporte" />
                  </a>
                </Typography>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={4} sm={4}>
            <List dense>
              <ListItem>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  style={{ color: 'white', fontSize: '10px' }}
                >
                  <strong><FormattedMessage id="app.legal " defaultMessage="LEGAL" /></strong>
                </Typography>
              </ListItem>
              <ListItem style={{ paddingTop: '0' }}>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                >
                  <Typography className={classes.itemFooter} onClick={() => handleModalState('openTermsAndConditions', true)}>
                    <FormattedMessage id="app.termsAndConditions " defaultMessage="Términos y condiciones" />
                  </Typography>
                </Typography>
              </ListItem>
              <ListItem style={{ paddingTop: '0' }}>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                >
                  <Typography className={classes.itemFooter} onClick={() => handleModalState('openPrivacyPolicy', true)}>
                    <FormattedMessage id="app.privacyPolicy " defaultMessage="Política de privacidad" />
                  </Typography>
                </Typography>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={4} sm={4}>
            <List dense>
              <ListItem>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  style={{ color: 'white', fontSize: '10px' }}
                >
                  <strong><FormattedMessage id="app.contacto" defaultMessage="Contacto" /></strong>
                </Typography>
              </ListItem>
              <ListItem style={{ paddingTop: '0' }}>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="mailto:soporte.latam@pertixtech.com"
                    className={classes.itemFooter}
                  >
                    soporte.latam@pertixtech.com
                  </a>
                </Typography>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
      <TermsAndConditionsModal
        open={modalState.openTermsAndConditions}
        setOpen={(value) => handleModalState('openTermsAndConditions', value)}
        name="termsAndConditions"
        constants={termsAndConditionsText}
      />
      <TermsAndConditionsModal
        open={modalState.openPrivacyPolicy}
        setOpen={(value) => handleModalState('openPrivacyPolicy', value)}
        name="privacyPolicy"
        constants={privacyPolicyText}
      />
    </footer>
  );
}
