import React from 'react';
import { Breadcrumbs, Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PageLayout from '../../PageLayout';
import WorkFieldForm from '../WorkFieldForm';
import { providersTexts, workFieldTexts } from '../../../constants';

const useStyles = makeStyles(() => ({
  breadcrumbs: {
    textDecoration: 'none',
    fontWeight: 400,
    fontSize: 11,
  },
}));

const NewWorkFieldPage = () => {
  const classes = useStyles();
  function NewWorkFieldBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography component={Link} to="/providers" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {providersTexts.providers}
        </Typography>
        <Typography component={Link} to="/workFields" variant="caption" color="textPrimary" className={classes.breadcrumbs}>
          {workFieldTexts.workFields}
        </Typography>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{workFieldTexts.new}</Typography>
      </Breadcrumbs>
    );
  }

  return (
    <PageLayout title={workFieldTexts.newWorkField} breadCrumbs={<NewWorkFieldBreadcrumbs />}>
      <WorkFieldForm />
    </PageLayout>
  );
};

export default NewWorkFieldPage;
