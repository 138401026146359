import alertActions from './alert.actions';
import { calendarConstants } from '../../constants';
import { calendarService } from '../../services';

function getEvent(eventType, eventId) {
  function request() { return { type: calendarConstants.GET_REQUEST }; }
  function success(event) {
    return { type: calendarConstants.GET_SUCCESS, event };
  }
  function failure(error) { return { type: calendarConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());
    calendarService.getEvents(eventType, eventId)
      .then(
        (response) => {
          dispatch(success(response));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

const calendarEventActions = {
  getEvent,
};

export default calendarEventActions;
