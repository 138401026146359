const documentConstants = {
  CREATE_REQUEST: 'DOCUMENT_CREATE_REQUEST',
  CREATE_SUCCESS: 'DOCUMENT_CREATE_SUCCESS',
  CREATE_FAILURE: 'DOCUMENT_CREATE_FAILURE',

  GET_REQUEST: 'DOCUMENT_GET_REQUEST',
  GET_SUCCESS: 'DOCUMENT_GET_SUCCESS',
  GET_FAILURE: 'DOCUMENT_GET_FAILURE',

  UPDATE_REQUEST: 'DOCUMENT_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'DOCUMENT_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'DOCUMENT_UPDATE_FAILURE',

  DELETE_REQUEST: 'DOCUMENT_DELETE_REQUEST',
  DELETE_SUCCESS: 'DOCUMENT_DELETE_SUCCESS',
  DELETE_FAILURE: 'DOCUMENT_DELETE_FAILURE',

  UNLINK_REQUEST: 'DOCUMENT_UNLINK_REQUEST',
  UNLINK_SUCCESS: 'DOCUMENT_UNLINK_SUCCESS',
  UNLINK_FAILURE: 'DOCUMENT_UNLINK_FAILURE',

  DOWNLOAD_REQUEST: 'DOCUMENT_DOWNLOAD_REQUEST',
  DOWNLOAD_SUCCESS: 'DOCUMENT_DOWNLOAD_SUCCESS',
  DOWNLOAD_FAILURE: 'DOCUMENT_DOWNLOAD_FAILURE',

  NEW_VERSION_REQUEST: 'DOCUMENT_NEW_VERSION_REQUEST',
  NEW_VERSION_SUCCESS: 'DOCUMENT_NEW_VERSION_SUCCESS',
  NEW_VERSION_FAILURE: 'DOCUMENT_NEW_VERSION_FAILURE',

  GET_ALL_REQUEST: 'DOCUMENT_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'DOCUMENT_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'DOCUMENT_GET_ALL_FAILURE',

  LINK_REQUEST: 'DOCUMENT_LINK_REQUEST',
  LINK_SUCCESS: 'DOCUMENT_LINK_SUCCESS',
  LINK_FAILURE: 'DOCUMENT_LINK_FAILURE',

  PROCESS_DOCUMENT: 1,
  PERSON_DOCUMENT: 2,
  DEVIATION_DOCUMENT: 3,
  TRAINING_DOCUMENT: 4,
  PROVIDER_DOCUMENT: 5,
  WORKFIELD_EVALUATION_DOCUMENT: 6,

};

export default documentConstants;
