import { processConstants } from '../../constants';
import { processService } from '../../services';
import { history } from '../../helpers';
import alertActions from './alert.actions';
import tutorialActions from './tutorial.actions';

function create(process, redirectUrl = null) {
  function request(processToCreate) {
    return {
      type: processConstants.CREATE_REQUEST, processToCreate,
    };
  }
  function success(createdProcess) {
    return {
      type: processConstants.CREATE_SUCCESS, createdProcess,
    };
  }
  function failure(error) { return { type: processConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(process));

    processService.create(process)
      .then(
        (createdProcess) => {
          dispatch(success(createdProcess));
          if (localStorage.getItem('step')) dispatch(tutorialActions.setTutorialStep(4));
          if (redirectUrl) { history.replace(`${redirectUrl}${createdProcess.id}`); }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function get(id) {
  function request() { return { type: processConstants.GET_REQUEST }; }
  function success(process) { return { type: processConstants.GET_SUCCESS, process }; }
  function failure(error) { return { type: processConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    processService.get(id)
      .then(
        (process) => {
          dispatch(success(process));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
          history.push('/home');
        },
      );
  };
}

function getAll() {
  function request() { return { type: processConstants.GET_ALL_REQUEST }; }
  function success(processes) { return { type: processConstants.GET_ALL_SUCCESS, processes }; }
  function failure(error) { return { type: processConstants.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    processService.getAll()
      .then(
        (processes) => {
          dispatch(success(processes));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function update(process, redirectUrl = null) {
  function request(processToUpdate) {
    return {
      type: processConstants.UPDATE_REQUEST, processToUpdate,
    };
  }
  function success(updatedProcess) {
    return {
      type: processConstants.UPDATE_SUCCESS, updatedProcess,
    };
  }
  function failure(error) { return { type: processConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(process));

    processService.update(process)
      .then(
        (updatedProcess) => {
          dispatch(success(updatedProcess));
          if (redirectUrl) { history.push(`${redirectUrl}${updatedProcess.id}`); }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function deleteProcess(processId, redirectUrl = null) {
  function request() { return { type: processConstants.DELETE_REQUEST }; }
  function success() { return { type: processConstants.DELETE_SUCCESS }; }
  function failure() { return { type: processConstants.DELETE_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    processService.deleteProcess(processId)
      .then(
        () => {
          dispatch(success());
          if (redirectUrl) { history.push(redirectUrl); }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getWithDocuments(id) {
  function request() { return { type: processConstants.GET_REQUEST }; }
  function success(process) { return { type: processConstants.GET_SUCCESS, process }; }
  function failure(error) { return { type: processConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    processService.getWithDocuments(id)
      .then(
        (process) => {
          dispatch(success(process));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
          history.push('/home');
        },
      );
  };
}

function linkInterestedPeople(processId, interestedPeople) {
  function request() { return { type: processConstants.LINK_INTERESTED_PEOPLE_REQUEST }; }
  function success(result) {
    return { type: processConstants.LINK_INTERESTED_PEOPLE_SUCCESS, result };
  }
  function failure(error) {
    return { type: processConstants.LINK_INTERESTED_PEOPLE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    processService.linkInterestedPeople(processId, interestedPeople)
      .then(
        (result) => {
          dispatch(success(result));
          dispatch(getWithDocuments(processId));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function linkResponsiblePeople(processId, responsiblePeople) {
  function request() { return { type: processConstants.LINK_RESPONSIBLE_PEOPLE_REQUEST }; }
  function success(result) {
    return { type: processConstants.LINK_RESPONSIBLE_PEOPLE_SUCCESS, result };
  }
  function failure(error) {
    return { type: processConstants.LINK_RESPONSIBLE_PEOPLE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    processService.linkResponsiblePeople(processId, responsiblePeople)
      .then(
        (result) => {
          dispatch(success(result));
          dispatch(getWithDocuments(processId));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function unlinkInterested(processId, personId) {
  function request() { return { type: processConstants.UNLINK_INTERESTED_REQUEST }; }
  function success(response) {
    return { type: processConstants.UNLINK_INTERESTED_SUCCESS, response };
  }
  function failure(error) { return { type: processConstants.UNLINK_INTERESTED_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    processService.unlinkInterested(processId, personId)
      .then(
        (response) => {
          dispatch(success(response));
          dispatch(getWithDocuments(processId));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function unlinkResponsible(processId, personId) {
  function request() { return { type: processConstants.UNLINK_RESPONSIBLE_REQUEST }; }
  function success(response) {
    return { type: processConstants.UNLINK_RESPONSIBLE_SUCCESS, response };
  }
  function failure(error) { return { type: processConstants.UNLINK_RESPONSIBLE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ document }));
    processService.unlinkResponsible(processId, personId)
      .then(
        (response) => {
          dispatch(success(response));
          dispatch(getWithDocuments(processId));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

const processActions = {
  create,
  get,
  getAll,
  update,
  deleteProcess,
  getWithDocuments,
  linkInterestedPeople,
  linkResponsiblePeople,
  unlinkInterested,
  unlinkResponsible,
};

export default processActions;
