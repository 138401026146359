import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  videoResponsive: {
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
  },
  iframe: {
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
}));

const YouTubeEmbed = ({ videoId }) => {
  const classes = useStyles();
  return (
    <Box className={classes.videoResponsive}>
      <iframe
        className={classes.iframe}
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded tutorial youtube"
      />
    </Box>
  );
};

YouTubeEmbed.propTypes = {
  videoId: PropTypes.string.isRequired,
};

export default YouTubeEmbed;
