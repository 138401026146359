const workFieldEvaluationConstants = {
  CREATE_REQUEST: 'WORK_FIELD_EVALUATION_CREATE_REQUEST',
  CREATE_SUCCESS: 'WORK_FIELD_EVALUATION_CREATE_SUCCESS',
  CREATE_FAILURE: 'WORK_FIELD_EVALUATION_CREATE_FAILURE',

  GET_REQUEST: 'WORK_FIELD_EVALUATION_GET_REQUEST',
  GET_SUCCESS: 'WORK_FIELD_EVALUATION_GET_SUCCESS',
  GET_FAILURE: 'WORK_FIELD_EVALUATION_GET_FAILURE',

  GET_ALL_REQUEST: 'WORK_FIELD_EVALUATION_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'WORK_FIELD_EVALUATION_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'WORK_FIELD_EVALUATION_GET_ALL_FAILURE',

  GET_ALL_UNAPPROVED_REQUEST: 'WORK_FIELD_EVALUATION_GET_ALL_UNAPPROVED_REQUEST',
  GET_ALL_UNAPPROVED_SUCCESS: 'WORK_FIELD_EVALUATION_GET_ALL_UNAPPROVED_SUCCESS',
  GET_ALL_UNAPPROVED_FAILURE: 'WORK_FIELD_EVALUATION_GET_ALL_UNAPPROVED_FAILURE',

  UPDATE_REQUEST: 'WORK_FIELD_EVALUATION_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'WORK_FIELD_EVALUATION_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'WORK_FIELD_EVALUATION_UPDATE_FAILURE',

  DELETE_REQUEST: 'WORK_FIELD_EVALUATION_DELETE_REQUEST',
  DELETE_SUCCESS: 'WORK_FIELD_EVALUATION_DELETE_SUCCESS',
  DELETE_FAILURE: 'WORK_FIELD_EVALUATION_DELETE_FAILURE',

  CLEAN_CREATED: 'CLEAN_CREATED_WORK_FIELD_EVALUATION',
};

export default workFieldEvaluationConstants;
