import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box, Breadcrumbs, makeStyles, Typography, Grid,
  LinearProgress, Divider, Dialog, DialogTitle, DialogContent,
  DialogContentText, DialogActions, Table, TableRow,
  TableCell, TableHead, TableBody,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Edit } from '@material-ui/icons';

import { riskActions, workFieldEvaluationActions } from '../../../redux/actions';
import { personService } from '../../../services';
import PageLayout from '../../PageLayout';
import Button from '../../controls/Button';
import { riskHelper, workFieldHelper } from '../../../helpers';
import { workFieldEvaluationTexts } from '../../../constants';
import colors from '../../../assets/colors';

const useStyles = makeStyles((theme) => ({
  table: {
    fontSize: '12px',
  },
  textTitle: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '12px',
    textAlign: 'left',
  },
  textValue: {
    paddingTop: '8px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'left',
    textDecoration: 'none',
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '10px',
    fontWeight: 600,
    textTransform: 'uppercase',
    textDecoration: 'none',
  },
}));
function RiskDetailsPage({ routeParams: { id: riskId } }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const risk = useSelector((state) => state.risk.data);
  const deletingRisk = useSelector((state) => state.risk.deleting);
  const { roles } = useSelector((state) => state.authentication.user);
  const company = useSelector((state) => state.company.data);
  const workFieldEvaluations = useSelector(
    (state) => state.workFieldEvaluation.workFieldEvaluations,
  );
  const workFieldEvaluation = workFieldEvaluations && risk && risk.workFieldEvaluationId
    && workFieldEvaluations.find((wfe) => wfe.id === risk.workFieldEvaluationId);
  const isAdmin = roles.includes('Admin');
  const [componentMount, setComponentMount] = useState(false);
  const [deleteRiskDialog, setDeleteRiskDialog] = useState(false);
  const [lastRiskEvaluation, setLastRiskEvaluation] = useState('');
  const [people, setPeople] = useState([]);
  const probabilityLabel = intl.formatMessage({ id: 'risks.probability', defaultMessage: 'Probabilidad' });
  const detectionDateLabel = intl.formatMessage({ id: 'risks.detectionDate', defaultMessage: 'Fecha de detección' });
  const identificationLabel = intl.formatMessage({ id: 'risks.identification', defaultMessage: 'Identificación' });
  const impactLabel = intl.formatMessage({ id: 'risks.impact', defaultMessage: 'Impacto' });
  const nextEvaluationDateLabel = intl.formatMessage({ id: 'risks.nextEvaluationDate', defaultMessage: 'Fecha de próxima evaluación' });
  const coefficientLabel = intl.formatMessage({ id: 'risks.coefficient', defaultMessage: 'Coeficiente' });
  const riskLabel = intl.formatMessage({ id: 'risks.risk', defaultMessage: 'Riesgo' });
  const processesLabel = intl.formatMessage({ id: 'risks.processes', defaultMessage: 'Procesos' });
  const organizationContextLabel = intl.formatMessage({ id: 'risks.organizationContext', defaultMessage: 'Contexto organización' });
  const detailLabel = intl.formatMessage({ id: 'risks.detail', defaultMessage: 'Detalle' });
  const stakeholdersLabel = intl.formatMessage({ id: 'risks.stakeholders', defaultMessage: 'Partes interesadas' });
  const stakeholdersIdentificationLabel = intl.formatMessage({ id: 'risk.stakeholdersIdentification', defaultMessage: 'Identificación' });
  const stakeholdersNeedsLabel = intl.formatMessage({ id: 'risks.stakeholdersNeeds', defaultMessage: 'Requisito' });
  const riskEvaluationsLabel = intl.formatMessage({ id: 'risks.evaluations', defaultMessage: 'Evaluaciones' });
  const deleteRiskTitle = intl.formatMessage({ id: 'risks.delete.title', defaultMessage: 'Eliminar riesgo' });
  const deleteRiskButtonText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'Eliminar' });
  const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const deleteRiskText = intl.formatMessage({ id: 'people.deleteRiskText', defaultMessage: '¿Está seguro que quiere eliminar el riesgo?' });
  const deleteText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'eliminar' });
  const detailText = intl.formatMessage({ id: 'app.detail', defaultMessage: 'Detalle' });
  const risksTitle = intl.formatMessage({ id: 'risks.risksTitle', defaultMessage: 'Riesgos' });
  const riskDetailsText = intl.formatMessage({ id: 'risks.details', defaultMessage: 'Detalle de riesgo' });
  const noRiskProcessesText = intl.formatMessage({ id: 'risks.noProcesses', defaultMessage: 'No hay procesos asociados al riesgo' });
  const editText = intl.formatMessage({ id: 'app.edit', defaultMessage: 'Editar' });
  const responsibleText = intl.formatMessage({ id: 'risks.label.inChargePerson', defaultMessage: 'Responsable de cumplimiento' });
  const noRiskResponsibleText = intl.formatMessage({ id: 'risks.noResponsible', defaultMessage: 'No hay un responsable de cumplimiento asociado al riesgo' });
  const swotItemNameLabel = intl.formatMessage({ id: 'risks.swotAssociated', defaultMessage: 'Contexto de la organización' });
  const noSwotAssociatedText = intl.formatMessage({ id: 'risks.noSwotAssociated', defaultMessage: 'No hay un contexto de la organización asociado al riesgo' });

  useEffect(() => {
    if (!componentMount) {
      dispatch(riskActions.get(riskId));
      dispatch(workFieldEvaluationActions.getAll());
      setComponentMount(true);
    } else if (risk) {
      const lastRiskEvaluationAux = risk.riskEvaluations ? risk.riskEvaluations.find(
        (evaluation) => evaluation.id === risk.lastRiskEvaluationId,
      ) : '';
      setLastRiskEvaluation(lastRiskEvaluationAux);
    }
  }, [dispatch, componentMount, risk, riskId]);

  useEffect(() => {
    const fetchData = async () => {
      const resPersonService = await personService.getAll();
      setPeople(resPersonService);
    };
    fetchData();
  }, []);

  const [riskSwotItemName, setRiskSwotItemName] = useState('');

  useEffect(() => {
    if (risk && company) {
      if (risk.swotItemId) {
        const riskSwotItem = company.swotItems.find((swotItem) => swotItem.id === risk.swotItemId);
        setRiskSwotItemName(riskSwotItem ? riskSwotItem.description : risk.swotItemName);
      } else {
        setRiskSwotItemName(risk.swotItemName);
      }
    }
  }, [company, risk]);

  function RiskDetailsBreadcrumbs() {
    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/risks" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{risksTitle}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{detailText}</strong></Typography>
      </Breadcrumbs>
    );
  }

  function handleDeleteRisk() {
    setDeleteRiskDialog(true);
  }

  function handleDeleteDialogClose() {
    setDeleteRiskDialog(false);
  }

  function deleteRisk() {
    dispatch(riskActions.deleteRisk(risk.id));
    handleDeleteDialogClose();
  }

  function RiskDetailsLoadingBreadcrumbs() {
    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/risks" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{risksTitle}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{riskDetailsText}</strong></Typography>
      </Breadcrumbs>
    );
  }

  return (
    <>
      {risk && lastRiskEvaluation
        ? (
          <PageLayout title={risk.title} breadCrumbs={<RiskDetailsBreadcrumbs />}>
            <Box pl={3} pr={5}>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: '14px' }}>{probabilityLabel}</strong>
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                    {lastRiskEvaluation.probability}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: '14px' }}>{detectionDateLabel}</strong>
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                    {moment(risk.detectionDate).format('DD/MM/YYYY')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: '14px' }}>{identificationLabel}</strong>
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                    {risk.initialTreatmentIdentification}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: '14px' }}>{impactLabel}</strong>
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                    {lastRiskEvaluation.impact}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: '14px' }}>{nextEvaluationDateLabel}</strong>
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                    {moment(lastRiskEvaluation.nextEvaluationDate).format('DD/MM/YYYY')}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: '14px' }}>{responsibleText}</strong>
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                    {risk && lastRiskEvaluation.inChargePersonId
                      ? people.map((item) => {
                        if (item.id === lastRiskEvaluation.inChargePersonId) return (`${item.names} ${item.surnames}`);
                        return null;
                      })
                      : noRiskResponsibleText}
                  </Typography>
                </Grid>

              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: '14px' }}>{coefficientLabel}</strong>
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                    {riskHelper.getRiskCoefficient(
                      lastRiskEvaluation.probability,
                      lastRiskEvaluation.impact,
                    )}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                    {`${riskLabel}: `}
                    <span
                      style={{
                        color: riskHelper.getRiskColor(
                          lastRiskEvaluation.probability,
                          lastRiskEvaluation.impact,
                        ),
                        fontWeight: 700,
                      }}
                    >
                      {
                        riskHelper
                          .getRiskLevel(lastRiskEvaluation.probability, lastRiskEvaluation.impact)
                      }
                    </span>

                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: '14px' }}>{processesLabel}</strong>
                  </Typography>
                  {risk.processes && risk.processes.map((process) => (
                    <Typography key={process.id} variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                      {process.name}
                    </Typography>
                  ))}
                  {risk.processes && (risk.processes.length === 0) && (
                    <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                      {noRiskProcessesText}
                    </Typography>
                  )}

                </Grid>
                {risk.workFieldEvaluationId && workFieldEvaluation
                  && (
                    <>
                      <Grid item xs={12} sm={4}>
                        <Typography className={classes.textTitle}>
                          {workFieldEvaluationTexts.relatedProvider}
                        </Typography>
                        <Typography className={classes.textValue}>
                          {workFieldEvaluation.provider.name}
                        </Typography>
                        <Typography className={classes.textTitle} style={{ marginTop: 20 }}>
                          {workFieldEvaluationTexts.relatedEvaluation}
                        </Typography>
                        <Typography className={classes.textTitle} style={{ marginTop: 12 }}>
                          <Grid container>
                            <Grid item xs={4}>{workFieldEvaluationTexts.date}</Grid>
                            <Grid item xs={4}>{workFieldEvaluationTexts.workFieldEvaluated}</Grid>
                            <Grid item xs={4}>{workFieldEvaluationTexts.resultEvaluation}</Grid>
                          </Grid>
                        </Typography>
                        <Typography className={classes.textValue}>
                          <Grid container>
                            <Grid item xs={4}>{moment(workFieldEvaluation.date).format('DD/MM/YYYY')}</Grid>
                            <Grid item xs={4}>{workFieldEvaluation.workField.name}</Grid>
                            <Grid item xs={4}>
                              {workFieldHelper
                                .getEvaluationStatusLabel(workFieldEvaluation.evaluationStatus)}
                            </Grid>
                          </Grid>
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="flex-start" pt={1}>
                          <Typography
                            className={classes.link}
                            color="primary"
                            component={Link}
                            display="inline"
                            to={`/workFieldEvaluations/${workFieldEvaluation.id}`}
                          >
                            {workFieldEvaluationTexts.seeEvaluation}
                          </Typography>
                        </Box>
                      </Grid>
                    </>
                  )}

              </Grid>

              <Box pt={5} pb={2}>
                <Typography variant="h6" style={{ fontSize: '16px', fontWeight: 600 }}>
                  <strong>{organizationContextLabel}</strong>
                </Typography>
                <Divider />
              </Box>

              <Grid container spacing={3}>

                <Grid item xs={12} sm={4}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: '14px' }}>{swotItemNameLabel}</strong>
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                    {riskSwotItemName || noSwotAssociatedText}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: '14px' }}>{detailLabel}</strong>
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                    {risk.detail}
                  </Typography>
                </Grid>
              </Grid>

              <Box pt={5} pb={2}>
                <Typography variant="h6" style={{ fontSize: '16px', fontWeight: 600 }}>
                  <strong>{stakeholdersLabel}</strong>
                </Typography>
                <Divider />
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: '14px' }}>{stakeholdersIdentificationLabel}</strong>
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                    {risk.stakeholdersIdentification}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={4} />

                <Grid item xs={12} sm={4}>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: '14px' }}>{stakeholdersNeedsLabel}</strong>
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                    {risk.stakeholdersNeeds}
                  </Typography>
                </Grid>

              </Grid>

              <Box pt={5} pb={2}>
                <Typography variant="h6" style={{ fontSize: '16px', fontWeight: 600 }}>
                  <strong>{riskEvaluationsLabel}</strong>
                </Typography>
                <Divider />
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell size="small" style={{ fontSize: 12, fontWeight: 700 }}><FormattedMessage id="app.date" defaultMessage="Fecha" /></TableCell>
                        <TableCell size="small" style={{ fontSize: 12, fontWeight: 700, lineHeight: 1 }}><FormattedMessage id="risks.nextEvaluation" defaultMessage="Próxima evaluación" /></TableCell>
                        <TableCell size="small" style={{ fontSize: 12, fontWeight: 700, lineHeight: 1 }}>
                          <FormattedMessage id="risks.probability" defaultMessage="Probabilidad" />
                          /
                          {' '}
                          <FormattedMessage id="risks.impact" defaultMessage="Impacto" />
                        </TableCell>
                        <TableCell size="small" style={{ fontSize: 12, fontWeight: 700 }}><FormattedMessage id="risks.coefficient" defaultMessage="Coeficiente" /></TableCell>
                        <TableCell size="small" style={{ fontSize: 12, fontWeight: 700, lineHeight: 1 }}>
                          <FormattedMessage id="risks.resultOfPreviousActions" defaultMessage="Resultado de acciones anteriores" />
                        </TableCell>
                        <TableCell size="small" style={{ fontSize: 12, fontWeight: 700 }}><FormattedMessage id="risks.actions" defaultMessage="Acciones" /></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {risk.riskEvaluations.map((evaluation) => (
                        <TableRow key={evaluation.id}>
                          <TableCell size="small" style={{ fontSize: 11, fontWeight: 400 }}>{moment(evaluation.evaluationDate).format('DD/MM/YY')}</TableCell>
                          <TableCell size="small" style={{ fontSize: 11, fontWeight: 400 }}>{moment(evaluation.nextEvaluationDate).format('DD/MM/YY')}</TableCell>
                          <TableCell size="small" style={{ fontSize: 11, fontWeight: 400 }}>
                            {`${evaluation.probability} / ${evaluation.impact}`}
                          </TableCell>
                          <TableCell size="small" style={{ fontSize: 11, fontWeight: 400, padding: '6px 0px 6px 16px' }}>
                            <span style={{ display: 'inline-block', width: '2em' }}>
                              {`${riskHelper.getRiskCoefficient(evaluation.probability, evaluation.impact)} `}
                            </span>
                            <span
                              style={{
                                color: riskHelper.getRiskColor(
                                  evaluation.probability,
                                  evaluation.impact,
                                ),
                                fontWeight: 700,
                              }}
                            >
                              {riskHelper.getRiskLevel(evaluation.probability, evaluation.impact)}
                            </span>
                          </TableCell>
                          <TableCell size="small">
                            <Typography
                              variant="caption"
                              style={{ fontWeight: 400, fontSize: 11 }}
                            >
                              {evaluation.result}
                            </Typography>
                          </TableCell>
                          <TableCell
                            size="small"
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              variant="caption"
                              style={{ fontWeight: 400, fontSize: 11 }}
                            >
                              {evaluation.actions}
                            </Typography>
                            <Link to={`/risks/${riskId}/evaluation/${evaluation.id}/edit`}>
                              <Edit style={{
                                fontSize: '14px',
                                color: colors.orange,
                              }}
                              />
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>

              <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                <Button
                  component={Link}
                  to={`/risks`}
                  variant="outlined"
                  text={<FormattedMessage id="app.back" defaultMessage="volver" />}
                />
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => { handleDeleteRisk(); }}
                  text={deleteText}
                  style={{ fontSize: '10px', fontWeight: 600 }}
                  disabled={!isAdmin}
                />
                <Button
                  component={Link}
                  to={`/risks/${riskId}/edit`}
                  text={editText}
                  disabled={!isAdmin}
                />
                <Button
                  component={Link}
                  to={`/risks/${riskId}/new`}
                  text={<FormattedMessage id="risks.newEvaluation" defaultMessage="Nueva evaluación" />}
                  disabled={!isAdmin}
                />
              </Box>
            </Box>

            <Dialog open={deleteRiskDialog} onClose={() => { handleDeleteDialogClose(); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">{deleteRiskTitle}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {deleteRiskText}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button text={cancelButtonText} onClick={() => { handleDeleteDialogClose(); }} />
                <Button
                  className={classes.menuButton}
                  text={deleteRiskButtonText}
                  color="secondary"
                  loading={deletingRisk}
                  onClick={deleteRisk}
                />
              </DialogActions>
            </Dialog>
          </PageLayout>

        )
        : (
          <PageLayout title={riskDetailsText} breadCrumbs={<RiskDetailsLoadingBreadcrumbs />}>
            <LinearProgress />
          </PageLayout>
        )}
    </>

  );
}

RiskDetailsPage.propTypes = {
  routeParams: PropTypes.shape(),
};

RiskDetailsPage.defaultProps = {
  routeParams: {},
};

export default RiskDetailsPage;
