const trainingConstants = {
  CREATE_REQUEST: 'TRAINING_CREATE_REQUEST',
  CREATE_SUCCESS: 'TRAINING_CREATE_SUCCESS',
  CREATE_FAILURE: 'TRAINING_CREATE_FAILURE',

  GET_REQUEST: 'TRAINING_GET_REQUEST',
  GET_SUCCESS: 'TRAINING_GET_SUCCESS',
  GET_FAILURE: 'TRAINING_GET_FAILURE',

  GET_ALL_REQUEST: 'TRAINING_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'TRAINING_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'TRAINING_GET_ALL_FAILURE',

  UPDATE_REQUEST: 'TRAINING_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'TRAINING_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'TRAINING_UPDATE_FAILURE',

  DELETE_REQUEST: 'TRAINING_DELETE_REQUEST',
  DELETE_SUCCESS: 'TRAINING_DELETE_SUCCESS',
  DELETE_FAILURE: 'TRAINING_DELETE_FAILURE',

  CLEAN_CREATED_PROCESS: 'CLEAN_CREATED_TRAINING',

  PLANNED: 0,
  CANCELED: 1,
  DONE: 2,
  FINISHED: 3,
};

export default trainingConstants;
