import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, Popover, IconButton, Typography,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paperPopoverAlert: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    boxShadow: 'none',
    maxWidth: (props) => props.maxWidth || 145,
    padding: 8,
  },
  textPopover: {
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '12px',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  iconButton: {
    padding: 0,
  },
}));

const PopoverWarningMessage = ({ id, message, customStyle }) => {
  const classes = useStyles(customStyle);
  const alertRef = useRef();
  const [anchorElAlertPopover, setAnchorElAlertPopover] = useState(null);

  const handleAlertPopoverOpen = () => {
    setAnchorElAlertPopover(alertRef.current);
  };
  const handleAlertPopoverClose = () => {
    setAnchorElAlertPopover(null);
  };

  return (
    <>
      <IconButton
        aria-haspopup="true"
        aria-label="status"
        aria-owns={anchorElAlertPopover ? `mouse-over-popover-#${id}` : undefined}
        className={classes.iconButton}
        edge="end"
        onClick={handleAlertPopoverClose}
        onMouseEnter={handleAlertPopoverOpen}
        onMouseLeave={handleAlertPopoverClose}
        ref={alertRef}
      >
        <ErrorOutlineIcon className={classes.icon} />
      </IconButton>
      <Popover
        anchorEl={anchorElAlertPopover}
        className={classes.popover}
        classes={{ paper: classes.paperPopoverAlert }}
        disableRestoreFocus
        id={`mouse-over-popover-#${id}`}
        open={Boolean(anchorElAlertPopover)}
        onClose={handleAlertPopoverClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <Typography className={classes.textPopover}>{message}</Typography>
      </Popover>
    </>
  );
};

PopoverWarningMessage.propTypes = {
  id: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  customStyle: PropTypes.shape({}),
};
PopoverWarningMessage.defaultProps = {
  customStyle: {},
};

export default PopoverWarningMessage;
