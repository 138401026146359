import React, { useState, useEffect } from 'react';
import {
  Box, DialogContent, makeStyles, Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { riskHelper } from '../../../../helpers';
import CalendarEventDetailPersonCard from '../CalendarEventDetailPersonCard';

const useStyles = makeStyles(() => ({
  flexCenterBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  dialogContent: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    margin: '20px 0px 10px',
    boxSizing: 'border-box',
  },
  lastEvaluationDataContainer: {
    width: '216px',
    height: '141px',
    background: '#F8F8F8',
    borderRadius: '8px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
    padding: '6px 17px 17px',
  },
  infoTitle: { fontSize: '11px', fontWeight: 600 },
  infoText: { fontSize: '11px', fontWeight: 400 },
  lastEvaluationDataTitle: { padding: '6px', borderBottom: '1px solid #EDEDED', width: '100%' },
}));

const CalendarEventDetailRiskContent = ({ event }) => {
  const classes = useStyles();
  const intl = useIntl();
  const lastEvaluationDataText = intl.formatMessage({ id: 'calendar.lastEvaluationDataText', defaultMessage: 'Datos de última evaluación' });
  const dateText = intl.formatMessage({ id: 'risks.detectionDate', defaultMessage: 'Fecha de detección' });
  const coefficientText = intl.formatMessage({ id: 'risks.coefficient', defaultMessage: 'Coeficiente' });
  const responsibleText = intl.formatMessage({ id: 'risks.label.inChargePerson', defaultMessage: 'Responsable de cumplimiento' });

  const [lastRiskEvaluation, setLastRiskEvaluation] = useState('');
  const { riskEvaluations, lastRiskEvaluationId } = event.eventInfo;

  useEffect(() => {
    const lastRiskEvaluationAux = riskEvaluations
      ? riskEvaluations.find((evaluation) => evaluation.id === lastRiskEvaluationId)
      : '';
    setLastRiskEvaluation(lastRiskEvaluationAux);
  }, [lastRiskEvaluationId, riskEvaluations]);

  return (
    <DialogContent className={classes.dialogContent}>
      {event.eventResponsible
        && (
          <Box className={classes.flexCenterBox}>
            <Typography variant="h5" style={{ fontSize: '12px', fontWeight: 600 }}>
              {responsibleText}
            </Typography>
            <CalendarEventDetailPersonCard person={event.eventResponsible} />
          </Box>
        )}

      <Box display="flex">
        <Box className={classes.lastEvaluationDataContainer}>
          <Box className={classes.lastEvaluationDataTitle}>
            <Typography variant="h5" className={classes.infoTitle}>
              {lastEvaluationDataText}
            </Typography>
          </Box>
          <Box my={1.6}>
            <Typography variant="h5" className={classes.infoTitle}>
              {dateText}
            </Typography>
            <Typography variant="h6" className={classes.infoText}>
              {moment(lastRiskEvaluation.evaluationDate).format('DD/MM/YYYY')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" className={classes.infoTitle}>
              {coefficientText}
            </Typography>
            <Box display="flex">
              <Typography display="inline" variant="h6" className={classes.infoText}>
                {riskHelper.getRiskCoefficient(
                  lastRiskEvaluation.probability,
                  lastRiskEvaluation.impact,
                )}
              </Typography>
              <Typography
                display="inline"
                variant="h6"
                className={classes.infoText}
                style={{
                  marginLeft: '11px',
                  color: riskHelper.getRiskColor(
                    lastRiskEvaluation.probability,
                    lastRiskEvaluation.impact,
                  ),
                }}
              >
                {riskHelper.getRiskLevel(lastRiskEvaluation.probability, lastRiskEvaluation.impact)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </DialogContent>
  );
};

CalendarEventDetailRiskContent.propTypes = {
  event: PropTypes.shape({
    type: PropTypes.string.isRequired,
    eventInfo: PropTypes.shape({
      processes: PropTypes.arrayOf(PropTypes.any),
      riskEvaluations: PropTypes.arrayOf(PropTypes.shape()),
      lastRiskEvaluationId: PropTypes.number,
    }),
    eventResponsible: PropTypes.shape({}),
  }).isRequired,
};

export default CalendarEventDetailRiskContent;
