/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  AppBar, Box, Container, Tab, Table, TableBody, TableCell,
  TableHead, TableRow, Tabs, Tooltip, Typography, Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { orange } from '@material-ui/core/colors';
import TabPanel from '../../controls/TabPanel';
import ButtonLoad from '../../controls/Button';
import { stripePlanSpecs } from '../../../constants';
import FormatPlansTableCell from '../Component/FormatPlansTableCell';
import FormatItemTableCell from '../Component/FormatItemTableCell';
import CheckoutForm from '../CheckoutForm';
import UnsubscribeModal from '../Component/UnsubscribeModal';

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  flexContainer: {
    justifyContent: 'space-evenly',
  },
  textTitle: {
    fontSize: 18,
    fontWeight: 700,
    paddingBottom: 16,
  },
  textSubtitle: {
    fontSize: 14, fontWeight: 400,
  },
  textSubtitleBold: {
    fontSize: 14, fontWeight: 600,
  },
  expandedText: {
    cursor: 'pointer',
  },
  textCell: { fontSize: 12 },
  buttonCancel: {
    borderRadius: 30,
    color: orange[500],
    fontSize: '10px',
    height: '36px',
  },
}));

const annualTab = 1;
const monthlyTab = 0;

const StripePlans = ({
  company, listEnabledTools, planPrices, isAffiliate, includesFreeInitialMonth
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [tabToDisplay, setTabToDisplay] = useState(annualTab);
  const [productSelected, setProductSelected] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(stripePlanSpecs.plans.PertixFree);
  const [expandedListAvailable, setExpandedListAvailable] = useState(false);
  const [openUnsubscribeDialog, setOpenUnsubscribeDialog] = useState(false);
  const currentTabIsAnnual = tabToDisplay === annualTab;
  const yourCurrentPlanText = intl.formatMessage({ id: 'stripe.yourCurrentPlan', defaultMessage: '¡Este es tu plan actual!' });
  const cancelPlanText = intl.formatMessage({ id: 'stripe.cancelPlan', defaultMessage: 'CANCELAR SUSCRIPCIÓN' });
  const selectText = intl.formatMessage({ id: 'app.select', defaultMessage: 'Seleccionar' });
  const seeLessText = intl.formatMessage({ id: 'app.seeLess...', defaultMessage: 'Ver menos...' });
  const seeMoreText = intl.formatMessage({ id: 'app.seeMore...', defaultMessage: 'Ver más...' });
  const displayListToolsText = intl.formatMessage({ id: 'stripe.displayListTools', defaultMessage: 'Mostrar listado de herramientas disponibles' });
  const closeListToolsText = intl.formatMessage({ id: 'stripe.closeListTools', defaultMessage: 'Cerrar listado de herramientas' });
  const toolsText = intl.formatMessage({ id: 'stripe.tools', defaultMessage: 'Herramientas' });
  const maxUsersExceededText = intl.formatMessage({ id: 'stripe.maxUsersExceeded', defaultMessage: 'La cantidad de empleados actual es mayor a la cantidad admitida por el plan' });
  const maxStorageExceededText = intl.formatMessage({ id: 'stripe.maxStorageExceeded', defaultMessage: 'El almacenamiento actual es mayor a la capacidad de almacenamiento admitida por el plan' });
  const reminderUserHaveText = intl.formatMessage({ id: 'stripe.reminderUserHave', defaultMessage: 'Recordá que tenés' });
  const thirtyDaysFreeText = intl.formatMessage({ id: 'stripe.30DaysFree', defaultMessage: '30 días gratis' });
  const aboutAnyOfHisPlansText = intl.formatMessage({ id: 'stripe.aboutAnyOfHisPlans', defaultMessage: 'sobre cualquiera de nuestros planes.' });

  const thisPlanAllowsCouponApplication = (planId) => stripePlanSpecs.plansThatAcceptCoupon.includes(planId);

  const isCouponValidForPlan = (coupon, planId) => {
    if (!coupon || !coupon.appliesTo || !coupon.appliesTo.products) return false;
    return coupon.appliesTo.products.includes(planId);
  };

  const toolsComponent = (
    <Tooltip title={expandedListAvailable ? closeListToolsText : displayListToolsText}>
      <Box display="flex" onClick={() => setExpandedListAvailable((prev) => !prev)} className={classes.expandedText}>
        <Typography className={classes.textCell}>
          {toolsText}
        </Typography>
        <Box style={{ paddingLeft: 5, paddingTop: 2 }}>
          {expandedListAvailable
            ? <ExpandLess style={{ color: '#757575' }} />
            : <ExpandMore style={{ color: '#757575' }} />}
        </Box>
      </Box>
    </Tooltip>
  );

  const formatPrice = (price, stripeDiscount = 0) => {
    const formattedPrice = (((price / 100) * (100 - stripeDiscount)) / 100);
    return formattedPrice.toFixed(2).replace('.', ',');
  };

  const toggleUnsubscribeDialog = () => {
    setOpenUnsubscribeDialog((prev) => !prev);
  };

  const handleTabChange = (event, newValue) => {
    setTabToDisplay(newValue);
  };

  function handleSelectPlan(planSelected) {
    setProductSelected(planSelected);
  }

  function handleGoBack() {
    setProductSelected(null);
  }

  useEffect(() => {
    if (company && company.plan && !productSelected) {
      const completeCurrentPlan = stripePlanSpecs.searchPlan(company.plan);
      setCurrentPlan(completeCurrentPlan);
      setTabToDisplay(completeCurrentPlan.isAnnual ? annualTab : monthlyTab);
    }
  }, [company, productSelected]);

  function getListAvailable(list) {
    return list.map((tier, indexList) => {
      const keyIdList = `${tier.name} ${indexList}`;
      return (
        <TableRow key={keyIdList}>
          <FormatItemTableCell value={tier.name} width="20%" />
          {tier.values.map((value, index) => {
            const keyId = `${value} ${index}`;
            return <FormatItemTableCell key={keyId} value={value} align="center" width="20%" />;
          })}
        </TableRow>
      );
    });
  }

  function getPlanWithPrice(plan) {
    if (planPrices && planPrices.prices) {
      const planWithPrice = planPrices.prices.find((planPrice) => plan.key === planPrice.plan);
      const price = planWithPrice ? formatPrice(planWithPrice.unitAmount) : '0';

      if (
        isAffiliate
        && plan.toSetStrikeThroughPrice
        && thisPlanAllowsCouponApplication(plan.key)
        && isCouponValidForPlan(planPrices.coupon, plan.key)
      ) {
        const stripeDiscount = planPrices.coupon.percentOff;
        const priceWithDiscount = formatPrice(planWithPrice.unitAmount, stripeDiscount);
        return { ...plan, price: priceWithDiscount, strikethroughPrice: price };
      }
      return { ...plan, price };
    }
    return plan;
  }

  function isCurrentPlan(monthlyPlan, annualPlan) {
    if (currentPlan.key === monthlyPlan.key && !currentTabIsAnnual) return true;
    if (currentPlan.key === annualPlan.key && currentTabIsAnnual) return true;
    return false;
  }

  function planDependingOnFrequency(monthlyPlan, annualPlan) {
    if (currentTabIsAnnual) return annualPlan;
    return monthlyPlan;
  }

  function isMaxUsersExceeded(planToSelect) {
    return listEnabledTools.users.current > planToSelect.maxUsers;
  }
  function isMaxStorageExceeded(planToSelect) {
    return listEnabledTools.storage.current > planToSelect.maxStorageInBytes;
  }

  function isNonSelectablePlan(planToSelect) {
    if (!listEnabledTools) return true;
    return isMaxUsersExceeded(planToSelect) || isMaxStorageExceeded(planToSelect);
  }

  function renderButtonDependingOn(monthlyPlan, annualPlan) {
    const planSelected = getPlanWithPrice(planDependingOnFrequency(monthlyPlan, annualPlan));

    if (isCurrentPlan(monthlyPlan, annualPlan)) {
      return (
        <>
          <Typography style={{ fontSize: 12 }}>{yourCurrentPlanText}</Typography>
          {![stripePlanSpecs.plans.PertixFree.key, stripePlanSpecs.plans.PertixStarterFree.key].includes(planSelected.key) && (
          <Button
            variant="text"
            classes={{ root: classes.buttonCancel }}
            onClick={toggleUnsubscribeDialog}
          >
            {cancelPlanText}
          </Button>
          )}
        </>
      );
    }
    return (
      <Tooltip
        placement="top-start"
        title={isMaxUsersExceeded(planSelected) ? maxUsersExceededText : maxStorageExceededText}
        disableHoverListener={!isNonSelectablePlan(planSelected)}
      >
        <span>
          <ButtonLoad
            text={selectText}
            disabled={isNonSelectablePlan(planSelected)}
            onClick={() => handleSelectPlan(planSelected)}
            style={{ minWidth: 120, height: 'none', fontSize: 10 }}
          />
        </span>
      </Tooltip>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box pb={4}>
        <Typography variant="h4" align="center" color="primary" className={classes.textTitle}>
          <FormattedMessage id="stripe.pertixPlans" defaultMessage="Planes Pertix" />
        </Typography>
        <Typography variant="body1" align="center" className={classes.textSubtitle}>
          <FormattedMessage id="stripe.subtitlePlans" defaultMessage="Elegí el plan ideal para tu empresa, podrás cambiarlo en cualquier momento" />
        </Typography>
        {includesFreeInitialMonth && <Typography variant="body1" align="center" className={classes.textSubtitle}>
            {reminderUserHaveText}
            {' '}
            <span style={{ fontWeight: 600 }}>{thirtyDaysFreeText}</span>
            {' '}
            {aboutAnyOfHisPlansText}
          </Typography>
        }
      </Box>
      {!productSelected && (
      <div className={classes.root}>
        <AppBar position="static" color="inherit">
          <Tabs value={tabToDisplay} onChange={handleTabChange} variant="fullWidth" aria-label="tabs prices" indicatorColor="primary" classes={{ flexContainer: classes.flexContainer }}>
            <Tab label="Precio Mensual" {...a11yProps(0)} />
            <Tab label="Precio Anual" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={tabToDisplay} index={0}>
          <Table size="small" aria-label="a dense TableHeadMonth">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableTitle} width="20%" />
                { currentPlan.key !== stripePlanSpecs.plans.PertixStarterFree.key 
                  ? (<FormatPlansTableCell plan={getPlanWithPrice(stripePlanSpecs.plans.PertixFreeMonth)} className={classes.tableTitle} width="20%" />)
                  : (<FormatPlansTableCell plan={getPlanWithPrice(stripePlanSpecs.plans.PertixStarterFreeMonth)} className={classes.tableTitle} width="20%" />)
                }
                <FormatPlansTableCell plan={getPlanWithPrice(stripePlanSpecs.plans.PertixStarterMonth)} isValidForAffiliateDiscountedPrice={isAffiliate && isCouponValidForPlan(planPrices.coupon, stripePlanSpecs.plans.PertixStarterMonth.key)} className={classes.tableTitle} width="20%" />
                <FormatPlansTableCell plan={getPlanWithPrice(stripePlanSpecs.plans.PertixAdvancedMonth)} isValidForAffiliateDiscountedPrice={isAffiliate && isCouponValidForPlan(planPrices.coupon, stripePlanSpecs.plans.PertixAdvancedMonth.key)} className={classes.tableTitle} width="20%" />
              </TableRow>
            </TableHead>
          </Table>
        </TabPanel>
        <TabPanel value={tabToDisplay} index={1}>
          <Table size="small" aria-label="a dense TableHeadAnnual">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableTitle} width="20%" />
                { currentPlan.key !== stripePlanSpecs.plans.PertixStarterFree.key 
                  ? (<FormatPlansTableCell plan={getPlanWithPrice(stripePlanSpecs.plans.PertixFree)} className={classes.tableTitle} width="20%" />)
                  : (<FormatPlansTableCell plan={getPlanWithPrice(stripePlanSpecs.plans.PertixStarterFree)} className={classes.tableTitle} width="20%" />)
                }
                <FormatPlansTableCell plan={getPlanWithPrice(stripePlanSpecs.plans.PertixStarterYear)} isValidForAffiliateDiscountedPrice={isAffiliate && isCouponValidForPlan(planPrices.coupon, stripePlanSpecs.plans.PertixStarterYear.key)} className={classes.tableTitle} width="20%" />
                <FormatPlansTableCell plan={getPlanWithPrice(stripePlanSpecs.plans.PertixAdvancedYear)} isValidForAffiliateDiscountedPrice={isAffiliate && isCouponValidForPlan(planPrices.coupon, stripePlanSpecs.plans.PertixAdvancedYear.key)} className={classes.tableTitle} width="20%" />
              </TableRow>
            </TableHead>
          </Table>
        </TabPanel>

        <Table size="small" aria-label="a dense TableBody">
          <TableBody>
            {getListAvailable(expandedListAvailable
              ? stripePlanSpecs.expandedListAvailableTools({ starterFreeInsteadOfFree: currentPlan.key === stripePlanSpecs.plans.PertixStarterFree.key})
              : stripePlanSpecs.listAvailableTools({ starterFreeInsteadOfFree: currentPlan.key === stripePlanSpecs.plans.PertixStarterFree.key}))}
            <TableRow>
              <FormatItemTableCell width="20%">{toolsComponent}</FormatItemTableCell>
              <FormatItemTableCell onClick={() => setExpandedListAvailable((prev) => !prev)} value={expandedListAvailable ? seeLessText : seeMoreText} className={classes.expandedText} align="center" width="20%" />
              <FormatItemTableCell onClick={() => setExpandedListAvailable((prev) => !prev)} value={expandedListAvailable ? seeLessText : seeMoreText} className={classes.expandedText} align="center" width="20%" />
              <FormatItemTableCell onClick={() => setExpandedListAvailable((prev) => !prev)} value={expandedListAvailable ? seeLessText : seeMoreText} className={classes.expandedText} align="center" width="20%" />
            </TableRow>
            <TableRow>
              <FormatItemTableCell width="20%" />
              <FormatItemTableCell align="center" width="20%">
              { currentPlan.key !== stripePlanSpecs.plans.PertixStarterFree.key 
                ? renderButtonDependingOn(stripePlanSpecs.plans.PertixFreeMonth, stripePlanSpecs.plans.PertixFree)
                : renderButtonDependingOn(stripePlanSpecs.plans.PertixStarterFreeMonth, stripePlanSpecs.plans.PertixStarterFree)
              }
              </FormatItemTableCell>
              <FormatItemTableCell align="center" width="20%">
                {renderButtonDependingOn(stripePlanSpecs.plans.PertixStarterMonth, stripePlanSpecs.plans.PertixStarterYear)}
              </FormatItemTableCell>
              <FormatItemTableCell align="center" width="20%">
                {renderButtonDependingOn(stripePlanSpecs.plans.PertixAdvancedMonth, stripePlanSpecs.plans.PertixAdvancedYear)}
              </FormatItemTableCell>
            </TableRow>
          </TableBody>
        </Table>
        <UnsubscribeModal openDialogCancel={openUnsubscribeDialog} toggleDialogCancel={toggleUnsubscribeDialog} />
      </div>
      )}
      {Boolean(productSelected) && (
        <CheckoutForm
          plan={productSelected}
          handleGoBack={handleGoBack}
          currentPlanId={currentPlan.key}
          currentPlanWithPrice={getPlanWithPrice(currentPlan)}
          includesFreeInitialMonth={includesFreeInitialMonth}
        />
      )}

    </Container>
  );
};

StripePlans.propTypes = {
  company: PropTypes.shape(),
  listEnabledTools: PropTypes.shape(),
  planPrices: PropTypes.shape(),
  isAffiliate: PropTypes.bool,
  includesFreeInitialMonth: PropTypes.bool,
};

StripePlans.defaultProps = {
  company: null,
  listEnabledTools: null,
  planPrices: null,
  isAffiliate: false,
  includesFreeInitialMonth: false,
};

export default StripePlans;
