import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link, Redirect } from 'react-router-dom';
import {
  Breadcrumbs, LinearProgress, Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import PageLayout from '../../PageLayout';
import StripePlans from '../StripePlans/StripePlans';
import { alertActions, affiliateActions, stripeActions } from '../../../redux/actions';

const StripePage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const planPrices = useSelector((state) => state.affiliate.prices);
  const user = useSelector((state) => state.authentication.user);
  const company = useSelector((state) => state.company.data);
  const listEnabledTools = useSelector((state) => state.stripe.data);
  const plansLabel = intl.formatMessage({ id: 'stripe.plans', defaultMessage: 'Planes' });
  const companyCreatorOnlyAccessLabel = intl.formatMessage({ id: 'stripe.companyCreatorOnlyAccess', defaultMessage: 'Solo puede acceder el creador del empresa' });

  useEffect(() => {
    if (company) {
      dispatch(stripeActions.getAllowedActions({ fullAllowedActions: true }));
      dispatch(affiliateActions.getPrices());
    }
  }, [company, dispatch]);

  function CompanyDetailBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/stripe/plans" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{plansLabel}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  if (user && company && user.id !== company.creatorUser) {
    dispatch(alertActions.info(companyCreatorOnlyAccessLabel));
    return <Redirect to={{ pathname: '/home' }} />;
  }

  return (
    <>
      {company && listEnabledTools && planPrices
        ? (
          <PageLayout>
            <StripePlans
              company={company}
              listEnabledTools={listEnabledTools}
              planPrices={planPrices}
              isAffiliate={planPrices.coupon && planPrices.coupon.valid}
              includesFreeInitialMonth={planPrices.includesFreeInitialMonth}
            />
          </PageLayout>
        )
        : (
          <PageLayout breadCrumbs={<CompanyDetailBreadcrumbs />}>
            <LinearProgress />
          </PageLayout>
        )}
    </>
  );
};

export default StripePage;
