import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Breadcrumbs, Dialog, makeStyles,
  DialogActions, Box,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { useDispatch, useSelector } from 'react-redux';
import MuiButton from '@material-ui/core/Button';
import { AccountBox } from '@material-ui/icons';
import PersonIcon from '@material-ui/icons/Person';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DataTable from '../../DataTable/DataTable';
import PageLayout from '../../PageLayout';
import Button from '../../controls/Button';
import CircleButton from '../../controls/CircleButton';
import { alertActions, userActions } from '../../../redux/actions';
import { history } from '../../../helpers';
import SetNewPersonModal from '../SetNewPersonModal/SetNewPersonModal';

const useStyles = makeStyles((theme) => ({
  linkPerson: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    paddingLeft: '0px',
    textTransform: 'none',
  },
}));

function UserPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'user.usersTitle', defaultMessage: 'Usuarios' });
  const user = useSelector((state) => state.authentication.user);
  const isAdmin = user.roles.includes('Admin');
  const initialFieldValues = {
    selectedUserId: 0, selectedLinkedPersonId: 0, deleteUserDialog: false,
  };
  const [linkPersonDialog, setLinkPersonDialog] = useState(false);
  const [values, setValues] = useState(initialFieldValues);
  const {
    selectedUserId, selectedLinkedPersonId, deleteUserDialog,
  } = values;
  const deleteUserTitle = intl.formatMessage({ id: 'user.delete.title', defaultMessage: 'Eliminar usuario' });
  const editUserTitle = intl.formatMessage({ id: 'user.edit.title', defaultMessage: 'Modificar usuario' });
  const deleteUserText = intl.formatMessage({ id: 'user.deleteUserText', defaultMessage: '¿Está seguro que quiere eliminar el usuario?' });
  const deletingUser = useSelector((state) => state.user.deleting);
  const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const deletePersonButtonText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'Eliminar' });
  const addButtonText = intl.formatMessage({ id: 'user.createUser', defaultMessage: 'Crear usuario' });
  const linkPersonText = intl.formatMessage({ id: 'user.linkPerson', defaultMessage: 'Asociar persona' });
  const bulkCreationTitle = intl.formatMessage({ id: 'user.bulkCreationTitle', defaultMessage: 'Carga múltiple' });
  const companyCreatorOnlyAccessLabel = intl.formatMessage({ id: 'stripe.companyCreatorOnlyAccess', defaultMessage: 'Solo puede acceder el creador del empresa' });

  if (!user.isSuperAdmin) {
    dispatch(alertActions.info(companyCreatorOnlyAccessLabel));
    return <Redirect to={{ pathname: '/home' }} />;
  }

  const addButtonTextWithIcon = (
    <>
      <PersonIcon style={{ marginRight: 8 }} />
      {addButtonText}
    </>
  );
  function handleDialogOpen(dialogName, id = null) {
    if (id) {
      setValues({
        selectedUserId: id,
        [dialogName]: true,
      });
    } else {
      setValues({
        ...values,
        [dialogName]: true,
      });
    }
  }

  function handleLinkPersonDialog(userId, linkedPersonId) {
    setValues({
      selectedUserId: userId,
      selectedLinkedPersonId: linkedPersonId,
      deleteUserDialog: false,
    });
    setLinkPersonDialog(true);
  }

  function actionsButtons(link, userId, linkedPersonId) {
    return (
      <>

        <IconButton onClick={() => { handleLinkPersonDialog(userId, linkedPersonId); }} style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}>
          <Tooltip title={linkPersonText}>
            <AccountBox />
          </Tooltip>
        </IconButton>

        <IconButton onClick={() => { history.push(link); }} style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}>
          <Tooltip title={editUserTitle}>
            <CreateIcon style={{ width: 16, height: 16, color: '#F79A56' }} />
          </Tooltip>
        </IconButton>

        {user.id !== userId && (
          <IconButton onClick={() => { handleDialogOpen('deleteUserDialog', userId); }} style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}>
            <Tooltip title={deleteUserTitle}>
              <DeleteRoundedIcon style={{ width: 16, height: 16, color: '#727272' }} />
            </Tooltip>
          </IconButton>
        )}

      </>
    );
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'username',
      label: intl.formatMessage({ id: 'user.email', defaultMessage: 'Email' }),
    },
    {
      name: 'roles',
      label: intl.formatMessage({ id: 'user.role', defaultMessage: 'Rol' }),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (companies) => {
          const company = companies.find((item) => String(item.id) === String(user.company.id));
          return company.roles;
        },
        customDownloadBodyRender: (companies) => {
          const company = companies.find((item) => String(item.id) === String(user.company.id));
          return company.roles;
        },
      },
    },
    {
      name: 'linkedPerson',
      label: intl.formatMessage({ id: 'user.linkedPerson', defaultMessage: 'Persona asociada' }),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (companies) => {
          const company = companies.find((item) => String(item.id) === String(user.company.id));
          let result = intl.formatMessage({ id: 'user.doesNotHave', defaultMessage: 'No posee' });
          if (company.person !== null) {
            const personLink = `/people/${company.person.id}`;
            result = (
              <MuiButton
                className={classes.linkPerson}
                onClick={() => { history.push(personLink); }}
                display="inline"
              >
                {`${company.person.names} ${company.person.surnames}`}
              </MuiButton>
            );
          }
          return result;
        },
        customDownloadBodyRender: (companies) => {
          const company = companies.find((item) => String(item.id) === String(user.company.id));
          if (company.person !== null) {
            return `${company.person.names} ${company.person.surnames}`;
          }
          return intl.formatMessage({ id: 'user.doesNotHave', defaultMessage: 'No posee' });
        },
      },
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, meta) => {
          const userId = meta.rowData[0];
          const link = `/users/${userId}/edit`;
          const personId = meta.rowData[3].id;
          return actionsButtons(link, userId, personId);
        },
      },
    },
  ];

  const keys = ['id', 'email', 'companies', 'companies'];
  const fetchUrl = `companies/${user.company.id}/users/`;
  const itemUrl = 'users/';

  function handleDialogClose(dialogName) {
    setValues({
      ...values,
      [dialogName]: false,
    });
  }

  function deleteUser(id) {
    dispatch(userActions.deleteUser(id));
    handleDialogClose('deleteUserDialog');
  }

  function UserPageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/users" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{title}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  function ButtonGroup() {
    if (user.company.roles.includes('Admin')) {
      return (
        <Box display="flex">
          <Button
            text={addButtonTextWithIcon}
            component={Link}
            to="users/new"
            style={{ fontSize: '11px', fontWeight: 600 }}
          />
          <CircleButton
            text={(
              <Tooltip title={bulkCreationTitle}>
                <GroupAddIcon color="primary" fontSize="small" />
              </Tooltip>
            )}
            component={Link}
            to="users/upload"
            variant="outlined"
          />
        </Box>
      );
    }
    return null;
  }

  if (!isAdmin) {
    const message = intl.formatMessage({ id: 'error.UserCanNotPerformTheAction', defaultMessage: 'El usuario no puede realizar la acción debido a sus roles asignados.' });
    dispatch(alertActions.error(message));
    return <Redirect to={{ pathname: '/home' }} />;
  }

  return (
    <PageLayout
      title={title}
      breadCrumbs={<UserPageBreadcrumbs />}
      buttonGroup={<ButtonGroup />}
    >
      <DataTable
        title={title}
        fetchUrl={fetchUrl}
        itemUrl={itemUrl}
        keys={keys}
        columns={columns}
        addButtonText={addButtonTextWithIcon}
        addButtonTo="users/new"
      />
      <Dialog open={deleteUserDialog} onClose={() => { handleDialogClose('deleteUserDialog'); }} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{deleteUserTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {deleteUserText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button text={cancelButtonText} onClick={() => { handleDialogClose('deleteUserDialog'); }} />
          <Button
            text={deletePersonButtonText}
            color="secondary"
            loading={deletingUser}
            onClick={() => { deleteUser(selectedUserId); }}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        open={linkPersonDialog}
        onClose={() => { handleDialogClose('linkPersonDialog'); }}
        aria-labelledby="form-dialog-title"
      >
        <SetNewPersonModal
          isOpen={linkPersonDialog}
          setIsOpen={setLinkPersonDialog}
          userId={selectedUserId}
          linkedPersonId={selectedLinkedPersonId}
        />

      </Dialog>
    </PageLayout>
  );
}

export default UserPage;
