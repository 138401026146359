import React from 'react';
import { SvgIcon } from "@material-ui/core";

function DraftsIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M2.55114 0C1.45739 0 0.572443 0.9 0.572443 2L0.5625 18C0.5625 19.1 1.44744 20 2.54119 20H14.483C15.5767 20 16.4716 19.1 16.4716 18V6L10.5057 0H2.55114ZM9.51136 7V1.5L14.9801 7H9.51136Z" />
      </SvgIcon>
    );
  }

  export default DraftsIcon

  