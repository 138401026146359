import axios from '../axios/axios';
import { authHeader, userCompanySelectorHelper } from '../helpers';
import handler from './handler';

function addObjective(objective) {
  const headers = authHeader();
  const company = userCompanySelectorHelper.getCurrentCompany();
  const postUrl = `companies/${company.id}/objectives`;
  return axios.post(postUrl, objective, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAllObjectives() {
  const headers = authHeader();
  const company = userCompanySelectorHelper.getCurrentCompany();
  const getUrl = `companies/${company.id}/objectives`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getObjectives(id) {
  const headers = authHeader();
  const company = userCompanySelectorHelper.getCurrentCompany();
  const getUrl = `companies/${company.id}/objectives/${id}`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function updateObjective(id, objective) {
  const headers = authHeader();
  const company = userCompanySelectorHelper.getCurrentCompany();
  const updateUrl = `companies/${company.id}/objectives/${id}`;
  return axios.put(updateUrl, objective, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function updateMeasure(id, objective) {
  const headers = authHeader();
  const company = userCompanySelectorHelper.getCurrentCompany();
  const updateUrl = `companies/${company.id}/objectives/${id}/measures`;
  return axios.put(updateUrl, objective, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function deleteObjective(id) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const deleteUrl = `companies/${company.id}/objectives/${id}`;
  return axios.delete(deleteUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function createBulkObjectives(objectivesExcelFile) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `companies/${company.id}/objectives/bulk`;
  return axios.post(postUrl, objectivesExcelFile, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function confirmCreateBulkObjectives(id) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `companies/${company.id}/objectives/bulk/${id}`;
  return axios.post(postUrl, {}, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const objectiveService = {
  addObjective,
  getAllObjectives,
  getObjectives,
  updateObjective,
  updateMeasure,
  deleteObjective,
  createBulkObjectives,
  confirmCreateBulkObjectives,
};

export default objectiveService;
