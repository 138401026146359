const outlineConstants = {
  CREATE_REQUEST: 'OUTLINE_CREATE_REQUEST',
  CREATE_SUCCESS: 'OUTLINE_CREATE_SUCCESS',
  CREATE_FAILURE: 'OUTLINE_CREATE_FAILURE',

  GET_REQUEST: 'OUTLINE_GET_REQUEST',
  GET_SUCCESS: 'OUTLINE_GET_SUCCESS',
  GET_FAILURE: 'OUTLINE_GET_FAILURE',

  GET_ALL_REQUEST: 'OUTLINE_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'OUTLINE_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'OUTLINE_GET_ALL_FAILURE',

  UPDATE_REQUEST: 'OUTLINE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'OUTLINE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'OUTLINE_UPDATE_FAILURE',

  DELETE_REQUEST: 'OUTLINE_DELETE_REQUEST',
  DELETE_SUCCESS: 'OUTLINE_DELETE_SUCCESS',
  DELETE_FAILURE: 'OUTLINE_DELETE_FAILURE',

  LINK_REQUEST: 'OUTLINE_LINK_REQUEST',
  LINK_SUCCESS: 'OUTLINE_LINK_SUCCESS',
  LINK_FAILURE: 'OUTLINE_LINK_FAILURE',

  UNLINK_REQUEST: 'OUTLINE_UNLINK_REQUEST',
  UNLINK_SUCCESS: 'OUTLINE_UNLINK_SUCCESS',
  UNLINK_FAILURE: 'OUTLINE_UNLINK_FAILURE',

  CLEAN_CREATED_OUTLINE: 'CLEAN_CREATED_OUTLINE',
};

export default outlineConstants;
