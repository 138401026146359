import React, { useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import CalendarEventDetailModal from '../CalendarEventDetailModal/CalendarEventDetailModal';
import { calendarAllActions } from '../../../redux/actions';

const useStyles = makeStyles(() => ({
  calendarWrapper: {
    width: '100%',
    height: 'auto',
  },
}));

const headerToolbar = {
  left: 'prev,next,title',
  center: '',
  right: 'today',
};

const views = {
  dayGridMonth: {
    titleFormat: { year: 'numeric', month: 'short' },
  },
};

const Calendar = ({ events }) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const calendarRef = useRef();
  const plugins = [interactionPlugin, dayGridPlugin];
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [eventId, setEventId] = useState('');
  const [eventType, setEventType] = useState('');

  const toggleEventModalOpen = () => {
    setIsEventModalOpen(false);
    setEventId('');
    setEventType('');
  };

  const eventDidMount = (e) => {
    if (e.event.extendedProps.background) {
      e.el.style.background = e.event.extendedProps.background;
    }
  };

  const myCustomButtons = {
    prev: {
      click: () => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.prev();
        const startDate = moment(calendarApi.view.activeStart).format('DD-MM-YYYY');
        const endDate = moment(calendarApi.view.activeEnd).format('DD-MM-YYYY');
        dispatch(calendarAllActions.getMonthlyEvents(startDate, endDate));
      },
    },
    next: {
      click: () => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.next();
        const startDate = moment(calendarApi.view.activeStart).format('DD-MM-YYYY');
        const endDate = moment(calendarApi.view.activeEnd).format('DD-MM-YYYY');
        dispatch(calendarAllActions.getMonthlyEvents(startDate, endDate));
      },
    },
  };

  const eventTypeLabel = {
    objective: intl.formatMessage({ id: 'calendar.objectiveEventType', defaultMessage: ' - Medición' }),
    dateFrom: intl.formatMessage({ id: 'calendar.trainingStartEventType', defaultMessage: ' - Inicio' }),
    effectivenessVerificationDate: intl.formatMessage({ id: 'calendar.effectivenessVerificationDateEventType', defaultMessage: ' - Verificación' }),
    evaluation: intl.formatMessage({ id: 'calendar.evaluationEventType', defaultMessage: ' - Evaluación' }),
    plannedImplementationDate: intl.formatMessage({ id: 'calendar.plannedImplementationDateEventType', defaultMessage: ' - Implementación' }),
    expectedEffectivenessVerificationDate: intl.formatMessage({ id: 'calendar.expectedEffectivenessVerificationDateEventType', defaultMessage: ' - Verificación' }),
    risk: intl.formatMessage({ id: 'calendar.riskEventType', defaultMessage: ' - Evaluación' }),
    workfield_evaluation: intl.formatMessage({ id: 'calendar.workfield_evaluationEventType', defaultMessage: ' - Evaluación del proveedor' }),
  };

  const getEventTypeLabel = (type, subtype) => {
    if (subtype && type !== 'objective') return eventTypeLabel[subtype];
    return eventTypeLabel[type];
  };

  const renderEventContent = (eventInfo) => {
    const { type, subtype } = eventInfo.event._def.extendedProps;
    return eventInfo.event.title + getEventTypeLabel(type, subtype);
  };

  const handleEventClick = (e) => {
    const { publicId } = e.event._def;
    const { type } = e.event._def.extendedProps;
    setEventType(type);
    setEventId(publicId.toString());
    setIsEventModalOpen(true);
  };

  return (
    <Box className={classes.calendarWrapper}>
      <FullCalendar
        plugins={plugins}
        initialView="dayGridMonth"
        eventContent={renderEventContent}
        events={events}
        navLinks
        editable={false}
        dayMaxEvents
        headerToolbar={headerToolbar}
        eventClick={(e) => handleEventClick(e)}
        views={views}
        expandRows
        locales={allLocales}
        locale="es"
        aspectRatio={1.35}
        eventDidMount={eventDidMount}
        showNonCurrentDates
        navLinkDayClick="disabled"
        customButtons={myCustomButtons}
        ref={calendarRef}
      />

      <CalendarEventDetailModal
        openDialog={isEventModalOpen}
        toggleDialog={toggleEventModalOpen}
        eventType={eventType}
        eventId={eventId}
      />
    </Box>
  );
};

Calendar.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    subType: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
  })).isRequired,
};

export default Calendar;
