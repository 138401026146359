import axios from '../axios/axios';
import { authHeader } from '../helpers';
import handler from './handler';

function createCompany(newCompany) {
  const headers = authHeader();
  return axios.post('companies', newCompany, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function deleteCompany(companyId) {
  const headers = authHeader();
  const deleteUrl = `companies/${companyId}`;
  return axios.delete(deleteUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function resetUserPassword(userId) {
  const headers = authHeader();
  return axios.post(`companies/users/${userId}/reset-password`, null, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const superAdminService = {
  createCompany,
  deleteCompany,
  resetUserPassword,
};

export default superAdminService;
