/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import moment from 'moment';
import { stripeActions } from '../redux/actions';
import PageNotAllowed from './Home/PageNotAllowed';
import PageSubscriptionError from './Home/PageSubscriptionError';
import { stripePlanSpecs } from '../constants';

const PrivateRouteWithParamsAndSubscription = ({ component: Component, toolName, ...rest }) => {
  const dispatch = useDispatch();
  const listEnabledTools = useSelector((state) => state.stripe.data);
  const company = useSelector((state) => state.company.data);
  const allowedSubscriptionStatusList = ['active', 'trialing', null];

  useEffect(() => {
    dispatch(stripeActions.getAllowedActions({ fullAllowedActions: false }));
  }, [dispatch]);

  const thisIsAllowed = (tool) => {
    if (!tool) return true;
    if (!listEnabledTools) return false;
    if (listEnabledTools[tool] === undefined) return true;
    return listEnabledTools[tool].isValid;
  };

  const calculateRemainingTime = () => {
    const periodStart = moment(company.currentPeriodStart);
    const today = moment();
    return !(today.diff(periodStart, 'days') > stripePlanSpecs.maxDaysOfDelayPayment);
  };

  const verifySubscription = () => {
    if (allowedSubscriptionStatusList.includes(company.subscriptionStatus)) return true;
    if (company.subscriptionStatus === 'past_due') return calculateRemainingTime();
    return false;
  };

  const checkIfComponentCanBeDisplayed = (props, tool) => {
    if (!company) return <PageNotAllowed requestCompany toolName={tool} />;
    if ((verifySubscription() && thisIsAllowed(tool)) || (tool === 'subscription')) {
      return <Component routeParams={props.match.params} {...props} />;
    }
    if (!verifySubscription()) {
      return <PageSubscriptionError subscriptionStatus={company.subscriptionStatus} />;
    }
    return <PageNotAllowed toolName={tool} />;
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        localStorage.getItem('user')
          ? checkIfComponentCanBeDisplayed(props, toolName)
          : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )}
    />
  );
};

export default PrivateRouteWithParamsAndSubscription;
