import React, { useState, useEffect } from 'react';
import {
  Box, Grid, Typography, makeStyles,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../controls/Button';
import { objectiveTexts, objectiveConstants } from '../../../constants';
import { personService } from '../../../services';
import { objectiveActions } from '../../../redux/actions';
import ObjectiveMeasures from './ObjectiveMeasures';
import ObjectiveGauge from './ObjectiveGauge';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
  textTitle: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '12px',
    textAlign: 'left',
  },
  textValue: {
    paddingTop: '8px',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'left',
  },
  textSeparator: {
    marginLeft: '24px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  textSeparatorMeasure: {
    marginTop: '32px',
    marginLeft: '24px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  divider: {
    marginBottom: '16px',
    marginLeft: '24px',
  },
}));

const ObjectiveViewDetail = ({ data }) => {
  const objectiveDetails = { ...data };
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const deletingObjective = useSelector((state) => state.objective.deleting);
  const { roles } = useSelector((state) => state.authentication.user);
  const isAdmin = roles.includes('Admin');
  const [people, setPeople] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const resPersonService = await personService.getAll();
      setPeople(resPersonService);
    };
    fetchData();
  }, []);

  function toggleDeleteDialog() {
    setOpenDeleteDialog(!openDeleteDialog);
  }

  function handleDeleteObjectives(id) {
    dispatch(objectiveActions.deleteObjective(id, objectiveTexts.successfulDelete));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={classes.main}>
      <Typography className={classes.textSeparator}>
        {objectiveTexts.generalInformation}
      </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3} align="center">
        <Grid item xs={12} sm={6}>
          <Box px={3}>
            <Typography className={classes.textTitle}>
              {objectiveTexts.yearTitle}
            </Typography>
            <Typography className={classes.textValue}>
              {objectiveDetails.year}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box px={3}>
            <Typography className={classes.textTitle}>
              {objectiveTexts.descriptionOfObjective}
            </Typography>
            <Typography className={classes.textValue}>
              {objectiveDetails.description}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box pl={3} pt={2} align="flex-start">
            <Typography className={classes.textTitle}>
              {objectiveTexts.processes}
            </Typography>
            <Typography className={classes.textValue}>
              {objectiveDetails.processes.map((item, index) => {
                if ((objectiveDetails.processes.length - 1) !== index) return (`${item.name}, `);
                return (`${item.name}`);
              })}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box px={3} pt={2}>
            <Typography className={classes.textTitle}>
              {objectiveTexts.evaluationMethod}
            </Typography>
            <Typography className={classes.textValue}>
              {objectiveDetails.evaluationMethod}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box px={3} pt={2}>
            <Typography className={classes.textTitle}>
              {objectiveTexts.actionPlanForCompliance}
            </Typography>
            <Typography className={classes.textValue}>
              {objectiveDetails.actionPlanForCompliance}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box px={3} pt={2}>
            <Typography className={classes.textTitle}>
              {objectiveTexts.necessaryResources}
            </Typography>
            <Typography className={classes.textValue}>
              {objectiveDetails.necessaryResources}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box pl={3} pt={2}>
            <Typography className={classes.textTitle}>
              {objectiveTexts.responsibleForCompliance}
            </Typography>
            <Typography className={classes.textValue}>
              {people.map((item) => {
                if (item.id === objectiveDetails.responsibleForCompliance) return (` ${item.names} ${item.surnames} `);
                return null;
              })}
            </Typography>
          </Box>
        </Grid>

      </Grid>

      <Typography className={classes.textSeparatorMeasure}>
        {objectiveTexts.measureTitle}
      </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3} align="center">

        <Grid item xs={12} sm={6}>
          <Box px={3} pt={2}>
            <Typography className={classes.textTitle}>
              {objectiveTexts.indicator}
            </Typography>
            <Typography className={classes.textValue}>
              {objectiveDetails.indicator}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box pl={3} pt={2}>
            <Typography className={classes.textTitle}>
              {objectiveTexts.annualTarget}
            </Typography>
            <Typography className={classes.textValue}>
              {objectiveConstants.translateOperators(objectiveDetails.operator)}
              {' '}
              {Boolean(objectiveDetails.annualTarget) && new Intl.NumberFormat('es-AR').format(objectiveDetails.annualTarget)}
            </Typography>
            {objectiveDetails.isAccumulativeObjective && (
            <Typography className={classes.textTitle} style={{ marginTop: '16px', color: '#F16C24' }}>
              {objectiveTexts.isAccumulativeObjective}
            </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box pl={3} pt={2}>
            <Typography className={classes.textTitle}>
              {objectiveTexts.frequency}
            </Typography>
            <Typography className={classes.textValue}>
              {objectiveConstants.frequency[objectiveDetails.frequency]
                ? objectiveConstants.frequency[objectiveDetails.frequency].label
                : '-'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} />
        {Boolean(objectiveDetails.measure && !objectiveDetails.measure.length) && (
          <>
            <Grid item xs={12} sm={6}>
              <ObjectiveMeasures
                measures={data.measure}
                measureFrequency={objectiveDetails.frequency}
                year={objectiveDetails.year}
                isAccumulativeObjective={objectiveDetails.isAccumulativeObjective}
                operator={objectiveDetails.operator}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box maxWidth="550px" px={{ xs: 0, md: 3 }} pb={3}>
                <ObjectiveGauge
                  annualTarget={objectiveDetails.annualTarget}
                  id={objectiveDetails.id}
                  measures={data.measure}
                  operator={objectiveDetails.operator}
                  title={objectiveDetails.title}
                  isAccumulativeObjective={objectiveDetails.isAccumulativeObjective}
                />
              </Box>
            </Grid>
          </>
        )}
      </Grid>

      <Box display="flex" alignItems="center" justifyContent="flex-end" mt={3}>
        <Button
          component={Link}
          to="/objectives"
          variant="outlined"
          text={objectiveTexts.backText}
          style={{ fontSize: '10px', fontWeight: 600 }}
        />
        <Button
          type="button"
          color="secondary"
          onClick={() => toggleDeleteDialog()}
          text={objectiveTexts.deleteButton}
          style={{ fontSize: '10px', fontWeight: 600 }}
          disabled={!isAdmin}
        />
        <Button
          text={objectiveTexts.edit}
          style={{ fontSize: '10px', fontWeight: 600 }}
          component={Link}
          to={`/objectives/${data.id}/edit`}
          disabled={!isAdmin}
        />
      </Box>
      <Dialog open={openDeleteDialog} onClose={() => toggleDeleteDialog()} fullWidth max-width="xs" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{objectiveTexts.deleteObjectiveTitleDialog}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {objectiveTexts.deleteObjectiveContentDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button text={objectiveTexts.cancel} onClick={() => toggleDeleteDialog()} />
          <Button
            text={objectiveTexts.deleteButton}
            color="secondary"
            loading={deletingObjective}
            onClick={() => handleDeleteObjectives(data.id)}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

ObjectiveViewDetail.propTypes = {
  data: PropTypes.shape(),
};

ObjectiveViewDetail.defaultProps = {
  data: { },
};

export default ObjectiveViewDetail;
