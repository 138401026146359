import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Breadcrumbs, LinearProgress, Typography,
} from '@material-ui/core';
import PageLayout from '../PageLayout';
import { companyActions, modalAlertActions } from '../../redux/actions';
import { userCompanySelectorHelper } from '../../helpers';
import { stripePlanSpecs } from '../../constants';

const PageNotAllowed = ({ requestCompany, toolName }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const listEnabledTools = useSelector((state) => state.stripe.data);
  const currentCompany = userCompanySelectorHelper.getCurrentCompany();
  const notAllowedLabel = intl.formatMessage({ id: 'stripe.toolNotAllowed', defaultMessage: 'Herramienta no disponible' });

  const company = useSelector((state) => state.company.data);
  const currentPlan = company 
    && company.plan && stripePlanSpecs.getCurrentPlan(company.plan);

  const neededPlan = listEnabledTools
    && stripePlanSpecs.getNeededPlan(toolName && toolName !== 'subscription' ? listEnabledTools[toolName].plan : 2);

  useEffect(() => {
    intl.formatMessage({ id: 'home.pertix', defaultMessage: 'Pertix' });
  }, [intl]);

  useEffect(() => {
    if (listEnabledTools && !requestCompany) {
      const titleStripe = intl.formatMessage({ id: 'stripe.updatePlan', defaultMessage: 'MEJORAR PLAN' });
      const messageStripe = (
        <FormattedMessage
          id="stripe.disabledTools.UpdatePlan"
          defaultMessage={`El plan ${currentPlan} no incluye esta funcionalidad. Actualiza tu cuenta al plan starter para poder acceder`}
          values={{
            currentPlan: (<b>{currentPlan}</b>),
            neededPlan: (<b>{neededPlan}</b>),
            link: (
              <b>
                <a style={{ textDecoration: 'none', color: 'inherit' }} href="/stripe/plans" target="_blank" rel="noopener noreferrer">
                  Actualiza tu cuenta
                </a>
              </b>
            ),
          }}
        />
      );
      const buttonStripe = intl.formatMessage({ id: 'stripe.seePlan', defaultMessage: 'Ver Planes' });
      dispatch(modalAlertActions.infoAndGoToHome(titleStripe, messageStripe, buttonStripe));
    }
    if (requestCompany && currentCompany) {
      dispatch(companyActions.get(currentCompany.id));
    }
  }, [listEnabledTools, dispatch, intl, requestCompany,
    currentCompany, company, currentPlan, neededPlan]);

  function PageNotAllowedBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/home" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{notAllowedLabel}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  return (
    <>
      {listEnabledTools && !requestCompany
        ? (
          <PageLayout breadCrumbs={<PageNotAllowedBreadcrumbs />}>
            <LinearProgress />
          </PageLayout>
        )
        : (
          <PageLayout>
            <LinearProgress />
          </PageLayout>
        )}
    </>
  );
};

PageNotAllowed.propTypes = {
  requestCompany: PropTypes.bool,
  toolName: PropTypes.string,
};

PageNotAllowed.defaultProps = {
  requestCompany: false,
  toolName: '',
};

export default PageNotAllowed;
