const affiliateConstants = {
  CREATE_REQUEST: 'AFFILIATE_CREATE_REQUEST',
  CREATE_SUCCESS: 'AFFILIATE_CREATE_SUCCESS',
  CREATE_FAILURE: 'AFFILIATE_CREATE_FAILURE',

  GET_ALL_REQUEST: 'AFFILIATE_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'AFFILIATE_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'AFFILIATE_GET_ALL_FAILURE',

  GET_REQUEST: 'AFFILIATE_GET_REQUEST',
  GET_SUCCESS: 'AFFILIATE_GET_SUCCESS',
  GET_FAILURE: 'AFFILIATE_GET_FAILURE',

  GET_PRICES_REQUEST: 'AFFILIATE_GET_PRICES_REQUEST',
  GET_PRICES_SUCCESS: 'AFFILIATE_GET_PRICES_SUCCESS',
  GET_PRICES_FAILURE: 'AFFILIATE_GET_PRICES_FAILURE',

  POST_RESET_PASSWORD_REQUEST: 'AFFILIATE_POST_RESET_PASSWORD_REQUEST',
  POST_RESET_PASSWORD_SUCCESS: 'AFFILIATE_POST_RESET_PASSWORD_SUCCESS',
  POST_RESET_PASSWORD_FAILURE: 'AFFILIATE_POST_RESET_PASSWORD_FAILURE',

  POST_REQUEST: 'AFFILIATE_POST_REQUEST',
  POST_SUCCESS: 'AFFILIATE_POST_SUCCESS',
  POST_FAILURE: 'AFFILIATE_POST_FAILURE',

  CLEAN: 'AFFILIATE_CLEAN',
};

export default affiliateConstants;
