import { trainingConstants, personConstants } from '../../constants';
import { trainingService } from '../../services';
import { history } from '../../helpers';
import alertActions from './alert.actions';

function create(training, redirectUrl = null) {
  function request(trainingToCreate) {
    return { type: trainingConstants.CREATE_REQUEST, trainingToCreate };
  }
  function success(createdTraining) {
    return { type: trainingConstants.CREATE_SUCCESS, createdTraining };
  }
  function failure(error) { return { type: trainingConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(training));

    trainingService.create(training)
      .then(
        (createdTraining) => {
          dispatch(success(createdTraining));
          if (redirectUrl) { history.replace(`${redirectUrl}${createdTraining.id}`); }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getAll() {
  function request() { return { type: trainingConstants.GET_ALL_REQUEST }; }
  function success(training) { return { type: trainingConstants.GET_ALL_SUCCESS, training }; }
  function failure(error) { return { type: trainingConstants.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    trainingService.getAll()
      .then(
        (training) => {
          dispatch(success(training));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function get(id) {
  function request() { return { type: trainingConstants.GET_REQUEST }; }
  function success(training) { return { type: trainingConstants.GET_SUCCESS, training }; }
  function failure(error) { return { type: trainingConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    trainingService.get(id)
      .then(
        (training) => {
          dispatch(success(training));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
          history.push('/home');
        },
      );
  };
}

function update(training, trainingId, redirectUrl = null) {
  function request(trainingToUpdate) {
    return { type: trainingConstants.UPDATE_REQUEST, trainingToUpdate };
  }
  function success(updatedTraining) {
    return { type: trainingConstants.UPDATE_SUCCESS, updatedTraining };
  }
  function failure(error) { return { type: trainingConstants.UPDATE_FAILURE, error }; }
  function cleanPerson() { return { type: personConstants.CLEAN_CREATED_PERSON }; }

  return (dispatch) => {
    dispatch(request(training));

    trainingService.update(training, trainingId)
      .then(
        (updatedTraining) => {
          dispatch(success(updatedTraining));
          dispatch(cleanPerson());
          if (redirectUrl) { history.replace(`${redirectUrl}${updatedTraining.id}`); }
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function deleteTraining(trainingId) {
  function request() { return { type: trainingConstants.DELETE_REQUEST }; }
  function success() { return { type: trainingConstants.DELETE_SUCCESS }; }
  function failure() { return { type: trainingConstants.DELETE_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    trainingService.deleteTraining(trainingId)
      .then(
        () => {
          dispatch(success());
          history.push('/trainings');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

const trainingActions = {
  create,
  get,
  getAll,
  update,
  deleteTraining,
};

export default trainingActions;
