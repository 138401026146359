import { affiliateConstants, userConstants } from '../../constants';

export default function registration(state = {}, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { registering: true };
    case userConstants.REGISTER_SUCCESS:
      return {};
    case userConstants.REGISTER_FAILURE:
      return {};
    case userConstants.REGISTER_CONFIRM_REQUEST:
      return { confirming: true };
    case userConstants.REGISTER_CONFIRM_SUCCESS:
      return { confirmed: true };
    case userConstants.REGISTER_CONFIRM_FAILURE:
      return { confirmed: false };
    case userConstants.REGISTER_CONFIRM_CLEAR:
      return {};

    case affiliateConstants.POST_RESET_PASSWORD_REQUEST:
      return { loading: true };
    case affiliateConstants.POST_RESET_PASSWORD_SUCCESS:
      return { data: action.user };
    case affiliateConstants.POST_RESET_PASSWORD_FAILURE:
      return { error: action.error };

    case affiliateConstants.CLEAN:
      return { };

    default:
      return state;
  }
}
