import React from 'react';
import { SvgIcon } from '@material-ui/core';

function ContentCopyOutlined(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 37 38">
      <path d="M25.4368 2.21289H6.93685C5.24102 2.21289 3.85352 3.60039 3.85352 5.29622V26.8796H6.93685V5.29622H25.4368V2.21289ZM30.0618 8.37956H13.1035C11.4077 8.37956 10.0202 9.76706 10.0202 11.4629V33.0462C10.0202 34.7421 11.4077 36.1296 13.1035 36.1296H30.0618C31.7577 36.1296 33.1452 34.7421 33.1452 33.0462V11.4629C33.1452 9.76706 31.7577 8.37956 30.0618 8.37956ZM30.0618 33.0462H13.1035V11.4629H30.0618V33.0462Z" fill="#F2994A" />
    </SvgIcon>
  );
}

export default ContentCopyOutlined;
