import axios from '../axios/axios';
import handler from './handler';
import { authHeader, userCompanySelectorHelper } from '../helpers';
import { documentConstants } from '../constants';

function createFromPerson(data, entityId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `/companies/${company.id}/People/${entityId}/Document`;
  return axios.post(postUrl, data, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function createFromProcess(data, entityId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `/companies/${company.id}/Processes/${entityId}/Document`;
  return axios.post(postUrl, data, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function createFromDeviation(data, entityId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `/companies/${company.id}/deviations/${entityId}/Document`;
  return axios.post(postUrl, data, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function createFromTraining(data, entityId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `/companies/${company.id}/trainings/${entityId}/Document`;
  return axios.post(postUrl, data, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function createFromProvider(data, entityId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `/companies/${company.id}/providers/${entityId}/Document`;
  return axios.post(postUrl, data, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function createFromWorkFieldEvaluation(data, entityId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const postUrl = `/companies/${company.id}/WorkFieldEvaluations/${entityId}/Document`;
  return axios.post(postUrl, data, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function get(id) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/documents/${id}`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAll() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/Documents/`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function unlinkProcess(processId, documentId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/Processes/${processId}/Document/${documentId}/Unlink`;
  const data = {
    processId,
    documentId,
  };
  return axios.post(getUrl, data, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function unlinkDeviation(deviationId, documentId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/deviations/${deviationId}/Document/${documentId}/Unlink`;
  const data = {
    deviationId,
    documentId,
  };
  return axios.post(getUrl, data, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function unlinkTraining(trainingId, documentId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/trainings/${trainingId}/Document/${documentId}/Unlink`;
  const data = { trainingId, documentId };
  return axios.post(getUrl, data, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function unlinkProvider(providerId, documentId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/providers/${providerId}/Document/${documentId}/Unlink`;
  const data = { providerId, documentId };
  return axios.post(getUrl, data, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function unlinkWorkFieldEvaluation(workFieldEvaluationId, documentId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/workFieldEvaluations/${workFieldEvaluationId}/Document/${documentId}/Unlink`;
  const data = { workFieldEvaluationId, documentId };
  return axios.post(getUrl, data, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function unlinkPerson(personId, documentId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/People/${personId}/Document/${documentId}/Unlink`;
  const data = {
    employeeId: personId,
    documentId,
  };
  return axios.post(getUrl, data, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function deleteDocument(documentId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const deleteUrl = `/companies/${company.id}/Documents/${documentId}`;
  return axios.delete(deleteUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function download(documentId, contentType, filename) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/Documents/${documentId}/Download`;
  return axios.get(getUrl, { headers, responseType: 'arraybuffer' })
    .then((response) => {
      const a = document.createElement('a');
      const blob = new Blob([response.data], { type: contentType });
      const url = window.URL.createObjectURL(blob);

      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(handler.handleError);
}

function newVersion(data, documentId, type) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  let putUrl = `/companies/${company.id}/Processes/Document/${documentId}`;
  if (type === documentConstants.PERSON_DOCUMENT) putUrl = `/companies/${company.id}/People/Document/${documentId}`;
  if (type === documentConstants.DEVIATION_DOCUMENT) putUrl = `/companies/${company.id}/deviations/Document/${documentId}`;
  if (type === documentConstants.TRAINING_DOCUMENT) putUrl = `/companies/${company.id}/trainings/Document/${documentId}`;
  if (type === documentConstants.PROVIDER_DOCUMENT) putUrl = `/companies/${company.id}/providers/Document/${documentId}`;
  if (type === documentConstants.WORKFIELD_EVALUATION_DOCUMENT) putUrl = `/companies/${company.id}/workFieldEvaluations/Document/${documentId}`;
  return axios.put(putUrl, data, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAllFromProcess() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/Processes/Documents/`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAllFromPerson() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/People/Documents/`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAllFromDeviation() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/deviations/Documents/`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAllFromTraining() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/trainings/Documents/`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAllFromProvider() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/providers/Documents/`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function getAllFromWorkFieldEvaluation() {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/WorkFieldEvaluations/Documents/`;
  return axios.get(getUrl, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function linkProcess(processId, documentId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/Processes/${processId}/Document/${documentId}/link`;
  return axios.post(getUrl, null, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function linkPerson(personId, documentId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/People/${personId}/Document/${documentId}/link`;
  return axios.post(getUrl, null, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function linkDeviation(deviationId, documentId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/deviations/${deviationId}/Document/${documentId}/link`;
  return axios.post(getUrl, null, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function linkTraining(trainingId, documentId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/trainings/${trainingId}/Document/${documentId}/link`;
  return axios.post(getUrl, null, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function linkProvider(providerId, documentId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/providers/${providerId}/Document/${documentId}/link`;
  return axios.post(getUrl, null, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

function linkWorkFieldEvaluation(WorkFieldEvaluationId, documentId) {
  const company = userCompanySelectorHelper.getCurrentCompany();
  const headers = authHeader();
  const getUrl = `/companies/${company.id}/WorkFieldEvaluations/${WorkFieldEvaluationId}/Document/${documentId}/link`;
  return axios.post(getUrl, null, { headers })
    .then(handler.handleResponse)
    .catch(handler.handleError);
}

const documentService = {
  createFromPerson,
  createFromProcess,
  createFromDeviation,
  get,
  getAll,
  unlinkProcess,
  unlinkPerson,
  unlinkDeviation,
  deleteDocument,
  download,
  newVersion,
  getAllFromProcess,
  getAllFromPerson,
  getAllFromDeviation,
  linkProcess,
  linkPerson,
  linkDeviation,
  createFromTraining,
  unlinkTraining,
  getAllFromTraining,
  linkTraining,
  createFromProvider,
  unlinkProvider,
  getAllFromProvider,
  linkProvider,
  createFromWorkFieldEvaluation,
  unlinkWorkFieldEvaluation,
  getAllFromWorkFieldEvaluation,
  linkWorkFieldEvaluation,
};

export default documentService;
