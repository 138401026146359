import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { downloadHelper } from '../../../helpers';
import BulkCreation from '../../BulkUpload/BulkCreation';
import { alertActions, userActions } from '../../../redux/actions';

const UserBulkCreation = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.authentication.user);
  const isAdmin = userAuth.roles.includes('Admin');

  const peopleBulkCreationTitle = intl.formatMessage({ id: 'user.peopleBulkCreationTitle', defaultMessage: 'Carga múltiple de personas' });
  const bulkCreationTitle = intl.formatMessage({ id: 'user.bulkCreationTitle', defaultMessage: 'Carga múltiple' });
  const peopleText = intl.formatMessage({ id: 'people.title', defaultMessage: 'Personas' });

  const linkDownload = downloadHelper.listUsersExample;
  const linkEntity = '/people';
  const text = {
    title: peopleText,
    bulkCreationTitle: peopleBulkCreationTitle,
    bulkCreationBreadcrumbs: bulkCreationTitle,
    bulkCreationSubtitle: intl.formatMessage({ id: 'user.bulkCreationSubtitle', defaultMessage: 'Usted puede crear varias personas a la vez completando nuestra plantilla. ' }),
    bulkCreationSteps: intl.formatMessage({ id: 'user.bulkCreationSteps', defaultMessage: 'Pasos:' }),
    bulkCreationStep1: intl.formatMessage({ id: 'user.bulkCreationStep1', defaultMessage: '1. Descargue la plantilla de Excel.' }),
    bulkCreationStep2: intl.formatMessage({ id: 'user.bulkCreationStep2', defaultMessage: '2. Complete la plantilla con los datos de las personas deseadas.' }),
    bulkCreationStep3: intl.formatMessage({ id: 'user.bulkCreationStep3', defaultMessage: '3. Cargue la plantilla en nuestro sistema aquí abajo.' }),
    downloadListExample: intl.formatMessage({ id: 'user.downloadUsersListExample', defaultMessage: 'Descargar plantilla' }),
    titlePageLayout: intl.formatMessage({ id: 'user.bulkUsersSubtitle', defaultMessage: 'Empresa' }, { company: userAuth.company.name }),
  };

  function handleDispatcher(formData) {
    return (
      dispatch(userActions.createBulkUsers(formData))
    );
  }

  if (!isAdmin) {
    const message = intl.formatMessage({ id: 'error.UserCanNotPerformTheAction', defaultMessage: 'El usuario no puede realizar la acción debido a sus roles asignados.' });
    dispatch(alertActions.error(message));
    return <Redirect to={{ pathname: '/home' }} />;
  }

  return (
    <BulkCreation
      text={text}
      linkEntity={linkEntity}
      linkDownload={linkDownload}
      handleDispatcher={handleDispatcher}
    />
  );
};

export default UserBulkCreation;
