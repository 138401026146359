import { stripeConstants } from '../../constants';

function stripe(state = {}, action) {
  switch (action.type) {
    case stripeConstants.request.GET_CARDS_REQUEST:
      return { data: state.data ? state.data : null, requesting_cards: true };
    case stripeConstants.request.GET_CARDS_SUCCESS:
      return { data: state.data ? state.data : null, cardsData: action.cards };
    case stripeConstants.request.GET_CARDS_FAILURE:
      return { data: state.data ? state.data : null };

    case stripeConstants.request.GET_ALLOWED_ACTION_REQUEST:
      return { requesting: true };
    case stripeConstants.request.GET_ALLOWED_ACTION_SUCCESS:
      return { data: action.list };
    case stripeConstants.request.GET_ALLOWED_ACTION_FAILURE:
      return { error: action.error };

    default:
      return state;
  }
}

export default stripe;
