import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link, Redirect } from 'react-router-dom';
import {
  Breadcrumbs, LinearProgress, Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PageLayout from '../../PageLayout';
import { affiliateActions } from '../../../redux/actions';
import AffiliateViewDetail from './AffiliateViewDetail';

const AffiliateDetailPage = ({ routeParams: { id: affiliateId } }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.authentication.user);
  const affiliate = useSelector((state) => state.affiliate.data);
  const title = intl.formatMessage({ id: 'affiliates.title', defaultMessage: 'Afiliados' });
  const affiliateDetails = intl.formatMessage({ id: 'affiliates.affiliateDetails', defaultMessage: 'Detalles' });

  function AffiliateDetailBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/affiliates" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{title}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{affiliateDetails}</Typography>
      </Breadcrumbs>
    );
  }

  function AffiliateBreadcrumbs() {
    const link = `/affiliates/${affiliateId}`;
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/affiliates" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{title}</Typography>
        </Link>
        <Link color="inherit" to={link} style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{affiliate.names}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  useEffect(() => {
    dispatch(affiliateActions.get(affiliateId));
  }, [affiliateId, dispatch]);

  if (!userAuth.isSuperAdmin) {
    return <Redirect to={{ pathname: '/home' }} />;
  }

  return (
    <>
      {affiliate
        ? (
          <PageLayout title={affiliate.name} breadCrumbs={<AffiliateBreadcrumbs />}>
            <AffiliateViewDetail data={affiliate} />
          </PageLayout>
        )
        : (
          <PageLayout title={title} breadCrumbs={<AffiliateDetailBreadcrumbs />}>
            <LinearProgress />
          </PageLayout>
        )}
    </>
  );
};

AffiliateDetailPage.propTypes = {
  routeParams: PropTypes.shape(),
};

AffiliateDetailPage.defaultProps = {
  routeParams: {},
};

export default AffiliateDetailPage;
