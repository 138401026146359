import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs, Typography,
} from '@material-ui/core';
import PageLayout from '../../PageLayout';
import ObjectiveForm from '../ObjectiveForm';

const NewObjectivePage = () => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'objective', defaultMessage: 'Objetivo' });
  const newObjective = intl.formatMessage({ id: 'app.new', defaultMessage: 'Nuevo' });

  function NewObjectivePageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/objectives" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{title}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{newObjective}</Typography>
      </Breadcrumbs>
    );
  }

  return (
    <PageLayout
      title={title}
      breadCrumbs={<NewObjectivePageBreadcrumbs />}
    >
      <ObjectiveForm />
    </PageLayout>
  );
};

export default NewObjectivePage;
