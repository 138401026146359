import React, { useEffect } from 'react';
import {
  Typography, Box, DialogContent, makeStyles, Select, MenuItem, Input, Avatar,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';
import { AccountCircle } from '@material-ui/icons';
import Button from '../../controls/Button';
import { CloseIcon } from '../../../assets/icons';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '14px',
    fontWeight: 700,
    paddingTop: '25px',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '12px',
  },
  closeIcon: {
    width: '14px',
    height: '13px',
    cursor: 'pointer',
  },
  chip: {
    margin: 2,
  },
  personalPhoto: {
    width: 22,
    height: 22,
    color: '#E0E0E0',
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  getContentAnchorEl: null,
};

const AddPeopleModal = ({
  isOpen, setIsOpen, addedPeople, personRoles, setNewPeopleInOrgChart,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const noPeopleText = intl.formatMessage({ id: 'orgChart.noPersonRoleToAdd', defaultMessage: 'No hay puestos para agregar, puede ' });
  const createPerson = intl.formatMessage({ id: 'orgChart.createPerson', defaultMessage: 'crear una nueva persona.' });
  const addPeopleText = intl.formatMessage({ id: 'orgChart.addRole', defaultMessage: 'Agregar puestos' });

  const [selectedPeople, setSelectedPeople] = React.useState([]);
  const [excludedIds, setExcludedIds] = React.useState([]);

  useEffect(() => {
    const peopleIds = addedPeople.map((person) => person.id);
    setExcludedIds(peopleIds);
  }, [addedPeople]);

  function handleSubmit(e) {
    e.preventDefault();
    setNewPeopleInOrgChart(selectedPeople);
    setIsOpen(!isOpen);
  }

  const handleChangeSelected = (event) => {
    setSelectedPeople(event.target.value);
  };

  function handleCloseModal() {
    return setIsOpen(!isOpen);
  }

  function allPeopleAdded() {
    const isAddedToOrgChart = (person) => addedPeople.some(
      (addedPerson) => addedPerson.id === person.id,
    );
    const allAdded = personRoles.every(isAddedToOrgChart);
    return allAdded;
  }

  const renderChip = (selected) => {
    const render = selected.map((value) => (
      <Chip
        key={`${value.person.names} ${value.person.surnames}`}
        label={`${value.person.names} ${value.person.surnames}`}
        className={classes.chip}
      />
    ));
    return render;
  };

  const CustomAvatar = ({ data }) => (
    <Box display="flex" alignItems="center" justifyContent="center">
      { data.imageUrl
        ? (
          <Avatar
            className={classes.personalPhoto}
            src={data.imageUrl}
            alt={`${data.names} ${data.surnames}`}
          />
        )
        : (<AccountCircle className={classes.personalPhoto} fontSize="large" />)}
    </Box>
  );

  CustomAvatar.propTypes = {
    data: PropTypes.shape({
      imageUrl: PropTypes.string,
      names: PropTypes.string,
      surnames: PropTypes.string,
    }).isRequired,
  };

  return (

    <DialogContent>
      <Box display="flex" justifyContent="flex-end">
        <CloseIcon className={classes.closeIcon} onClick={handleCloseModal} />
      </Box>

      <Typography align="center" className={classes.title}>
        <FormattedMessage id="orgChart.addPersonRolesUppercase" defaultMessage="AGREGAR PUESTOS EXISTENTES" />
      </Typography>
      <Box pt={2} align="center">
        {personRoles && !allPeopleAdded() && (
        <>
          <Box mt={2} pl={5} pr={5} ml={5} mr={5} align="center">
            <Typography className={classes.subtitle} align="center" display="inline">
              {intl.formatMessage({ id: 'orgChart.chooseRole', defaultMessage: 'Elija los puestos que desea agregar al Organigrama' })}
            </Typography>
          </Box>

          <Select
            id="selectedPeople"
            displayEmpty
            multiple
            value={selectedPeople}
            onChange={handleChangeSelected}
            input={<Input />}
            MenuProps={MenuProps}
            renderValue={(selected) => {
              if (selected.length === 0) { return <em>{addPeopleText}</em>; }
              return renderChip(selected);
            }}
            style={{ width: '100%' }}
          >
            <MenuItem disabled value="">
              <em>{addPeopleText}</em>
            </MenuItem>
            {personRoles.map((personRole) => {
              if (!excludedIds.includes(personRole.id)) {
                return (
                  <MenuItem key={personRole.id} value={personRole}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                      <Box display="flex" alignItems="center">
                        <CustomAvatar data={personRole.person} />
                        <Typography
                          variant="body2"
                          style={{
                            fontSize: '12px', marginLeft: '10px', marginRight: '6px', fontWeight: 500,
                          }}
                        >
                          {`${personRole.name}`}
                        </Typography>
                        <Typography variant="body2" style={{ fontSize: '12px', fontWeight: 400, color: '#757575' }}>
                          {`${personRole.person.names} ${personRole.person.surnames}`}
                        </Typography>
                      </Box>
                      <Checkbox color="primary" checked={selectedPeople.indexOf(personRole) > -1} />
                    </Box>
                  </MenuItem>
                );
              } return null;
            })}
          </Select>
        </>
        )}
      </Box>

      { personRoles && (!(personRoles.length > 0) || allPeopleAdded()) && (
      <Box mt={5} pl={5} pr={5} ml={5} mr={5} align="center">
        <Typography className={classes.subtitle} align="center" display="inline">
          {' '}
          {noPeopleText}
          {' '}
        </Typography>
        <Link to="/people/new" style={{ textDecoration: 'none' }}>
          <Typography className={classes.subtitle} align="center" display="inline" style={{ color: '#F16C24' }}>
            {' '}
            {createPerson}
            {' '}
          </Typography>
        </Link>
      </Box>
      )}

      <Box display="flex" alignItems="right" justifyContent="flex-end" mt={5}>
        <Button
          onClick={handleCloseModal}
          variant="outlined"
          text={<FormattedMessage id="app.cancel" defaultMessage="Cancelar" />}
        />
        <Button
          onClick={handleSubmit}
          type="submit"
          disabled={selectedPeople.length === 0}
          text={<FormattedMessage id="app.add" defaultMessage="Agregar" />}
        />
      </Box>
    </DialogContent>

  );
};

AddPeopleModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setNewPeopleInOrgChart: PropTypes.func.isRequired,
  addedPeople: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  personRoles: PropTypes.arrayOf(PropTypes.shape()),
};

AddPeopleModal.defaultProps = {
  personRoles: [],
};

export default AddPeopleModal;
