const videoTutorialId = {
  FODA: 'EQ7bnXdlKlI',
  RISK_UPLOAD: 'tfEGPZ1ivdM',
  USER_UPLOAD: 'PuOf8eBwOs8',
  ORG_CHART: 'F6H5NXTFSr0',
  DEVIATION_UPLOAD: '7p_8xGXvPPA',
  OBJECTIVE_MASSIVE_UPLOAD: 'u6s7bewgezs',
  RISK_MASSIVE_UPLOAD: 'OPOeXaI67Xo',
  EVALUATIONS: 'o4PnMoSCBXs',
};

export default videoTutorialId;
