import React from 'react';
import {
  Paper, Box, Grid, Typography, Hidden,
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import pertixLogo from '../../../assets/pertix-logo.svg';
import pertixBackgroundImage from '../../../assets/fondo.jpg';
import { alertActions } from '../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${pertixBackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
    theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundPosition: 'center left',
    backgroundSize: 'cover',
  },
  text: {
    position: 'relative',
  },
  paper: {
    margin: theme.spacing(4, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    position: 'relative',
    width: '258.05px',
    height: '92.3px',
  },
}));

// eslint-disable-next-line react/prop-types
const AuthLayout = ({ children, pageTitle, pageSubtitle }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  function handleClose() {
    dispatch(alertActions.clear());
  }

  return (
    <Grid container component="main" className={classes.root} onClick={handleClose}>
      <CssBaseline />
      <Grid item xs={false} sm={false} md={6} lg={7} className={classes.image} style={{ height: '100vh', overflowY: 'hidden' }}>
        <Hidden smDown>
          <Box px={6} pt={10} className={classes.text}>
            <Typography color="primary" variant="h4" display="block" gutterBottom>
              <Grid item xs={12}>
                <FormattedMessage id="Sistema de " defaultMessage="Sistema de " />
              </Grid>
              <Grid item xs={12}>
                <strong>
                  {' '}
                  <FormattedMessage id="gestion integral " defaultMessage="gestion integral " />
                  {' '}
                </strong>
              </Grid>
              <Grid item xs={12}>
                <FormattedMessage id="de organizaciones" defaultMessage="de organizaciones" />
              </Grid>
            </Typography>
          </Box>
        </Hidden>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={5} component={Paper} elevation={6} square style={{ height: '100vh', overflowY: 'scroll' }}>
        <div className={classes.paper}>
          <img alt="" src={pertixLogo} className={classes.logo} />
          <Box display="flex" alignItems="center" justifyContent="center" mt={3} px={10}>
            <Typography align="center" variant="subtitle1" style={{ fontSize: '14px' }}>
              <strong>{pageTitle}</strong>
            </Typography>
          </Box>

          { pageSubtitle && (
            <Box px={12}>
              <Typography variant="body2" color="textSecondary" align="center">
                {pageSubtitle}
              </Typography>
            </Box>
          )}

          {children}
        </div>
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
