import React from 'react';
import { SvgIcon } from '@material-ui/core';

function ImpersonateIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 13">
      <path d="M5.75 6.19412C7.06727 6.19412 8.12682 5.10523 8.12682 3.76356C8.12682 2.4219 7.06727 1.33301 5.75 1.33301C4.43273 1.33301 3.36364 2.4219 3.36364 3.76356C3.36364 5.10523 4.43273 6.19412 5.75 6.19412ZM5.75 8.13856C4.00318 8.13856 0.5 9.03301 0.5 10.8122V12.9997H11V10.8122C11 9.03301 7.49682 8.13856 5.75 8.13856Z" fill="#F79A56" />
      <path d="M13.1788 1.71204V0.166992L11 2.3742L13.1788 4.58141V3.03636H18V1.71204H13.1788ZM15.8212 5.46429H11V6.78861H15.8212V8.33366L18 6.12645L15.8212 3.91924V5.46429Z" fill="#F79A56" />
    </SvgIcon>
  );
}

export default ImpersonateIcon;
