/* eslint-disable max-len */
import { modalAlertConstants } from '../../constants';

function info(title, message, button) {
  return {
    type: modalAlertConstants.INFO, title, message, button,
  };
}

function infoSubscription({
  title, message, button, linkTo,
}) {
  return {
    type: modalAlertConstants.INFO, title, message, button, linkTo,
  };
}

function customInfo(data, leftButtonFunction = null) {
  return {
    type: modalAlertConstants.CUSTOM_INFO, data: { ...data }, leftButtonFunction,
  };
}

function infoAndGoToHome(title, message, button) {
  return {
    type: modalAlertConstants.INFO_GOTO_HOME, title, message, button,
  };
}

function infoAndReload(title, message, button) {
  return {
    type: modalAlertConstants.INFO_RELOAD, title, message, button,
  };
}

function clear() {
  return { type: modalAlertConstants.CLEAR };
}

const modalAlertActions = {
  info, clear, infoAndGoToHome, infoSubscription, infoAndReload, customInfo,
};

export default modalAlertActions;
