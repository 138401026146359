/* eslint-disable max-len */
import React from 'react';
import { FormattedMessage } from 'react-intl';

const errorCode = {
  600: <FormattedMessage id="error.differentFromZero" defaultMessage="Entity id is different from zero." />,
  601: <FormattedMessage id="error.differentIdFromQuery" defaultMessage="Entity id is not the same as query string id." />,
  602: <FormattedMessage id="error.idRequired" defaultMessage="The id is required." />,
  603: <FormattedMessage id="error.mailAlreadyRegistered" defaultMessage="The email is already registered." />,
  604: <FormattedMessage id="error.invalidMail" defaultMessage="The email is invalid." />,
  605: <FormattedMessage id="error.invalidToken" defaultMessage="The token is invalid." />,
  606: <FormattedMessage id="error.notConfirmedMail" defaultMessage="The email is not confirmed." />,
  607: <FormattedMessage id="error.incorrectMailOrPassword" defaultMessage="The email or password are incorrect." />,
  608: <FormattedMessage id="error.userBlocked" defaultMessage="The user is locked out." />,
  609: <FormattedMessage id="error.incorrectMailOrToken" defaultMessage="The email or token are invalid." />,
  610: <FormattedMessage id="error.roleNameRequired" defaultMessage="The role is required." />,
  611: <FormattedMessage id="error.roleCouldNotBeAssigned" defaultMessage="The role could not be assigned." />,
  612: <FormattedMessage id="error.unexpectedErrorGoogleLogin" defaultMessage="Unexpected Error in Google login." />,
  613: <FormattedMessage id="error.userNotfound" defaultMessage="User was not found." />,
  614: <FormattedMessage id="error.userAlreadyAssignedToCompany" defaultMessage="Company already has a name." />,
  615: <FormattedMessage id="error.entityNotFound" defaultMessage="The entity was not found, was delete or does not belong to your company." />,
  616: <FormattedMessage id="error.userWasNotCreated" defaultMessage="User can not be created." />,
  617: <FormattedMessage id="error.roleWasNotCreated" defaultMessage="Role can not be created." />,
  618: <FormattedMessage id="error.userCouldNotBeNotUpdated" defaultMessage="User could not be updated." />,
  619: <FormattedMessage id="error.ErrorWhenSavingFile" defaultMessage="It had been an error when trying to copy the file into the hard drive." />,
  620: <FormattedMessage id="error.FileWithoutFileName" defaultMessage="The file does not have a file name." />,
  621: <FormattedMessage id="error.DocumentHasNotAFile" defaultMessage="The document does not have a file." />,
  622: <FormattedMessage id="error.StripeError" defaultMessage="It had been an error when calling Stripe." />,
  623: <FormattedMessage id="error.UserWithNoCompany" defaultMessage="The user is not assigned to a Company." />,
  624: <FormattedMessage id="error.CompanyHasNoStripeCustomerId" defaultMessage="The company has not stripe customerId." />,
  625: <FormattedMessage id="error.WebhookCannotManageStripeEvent" defaultMessage="The stripe event cannot be managed." />,
  626: <FormattedMessage id="error.ErrorGettingWebhookEvent" defaultMessage="There had been an error when getting the webhook event." />,
  627: <FormattedMessage id="error.NoUnpaidPeriod" defaultMessage="There are no unpaid periods to pay." />,
  628: <FormattedMessage id="error.NonExistentCustomerId" defaultMessage="The requested customerId does not exist." />,
  629: <FormattedMessage id="error.DocumentTypeDoesNotMatchUrl" defaultMessage="The Document Type differs the type manage by this URL." />,
  630: <FormattedMessage id="error.UserCanNotAssignRole" defaultMessage="The logged user has no permision to assign one of the roles." />,
  631: <FormattedMessage id="error.passwordCouldNotBeUpdated" defaultMessage="The password change had failed." />,
  632: <FormattedMessage id="error.ImageNotSent" defaultMessage="The image was not sent." />,
  633: <FormattedMessage id="error.RepeatedOrderSwotItem" defaultMessage="There is a SWOT Type which has two items with the same order." />,
  634: <FormattedMessage id="error.PasswordIsRequired" defaultMessage="Password is required." />,
  635: <FormattedMessage id="error.UserCanNotPerformTheAction" defaultMessage="The user can not perform the action due to its roles assigned." />,
  636: <FormattedMessage id="error.ExcelCanNotBeImported" defaultMessage="The Excel can not be imported." />,
  637: <FormattedMessage id="error.EmailIsRequired" defaultMessage="The email is required." />,
  638: <FormattedMessage id="error.EmailIsInvalid" defaultMessage="The email is invalid." />,
  639: <FormattedMessage id="error.RoleNotFound" defaultMessage="The Role does not exist." />,
  640: <FormattedMessage id="error.DateIsInInvalidFormat" defaultMessage="The Date is in invalid format." />,
  641: <FormattedMessage id="error.OutlineDoesNotExist" defaultMessage="The Outline does not exist." />,
  642: <FormattedMessage id="error.FileIsNotASupportedExcel" defaultMessage="The file is not a supported excel with a XLSX extension." />,
  643: <FormattedMessage id="error.RepeatedEmail" defaultMessage="The email is already in the excel file." />,
  644: <FormattedMessage id="error.NamesAreRequired" defaultMessage="The names are required." />,
  645: <FormattedMessage id="error.SurnamesAreRequired" defaultMessage="The surnames are required." />,
  646: <FormattedMessage id="error.TitleIsRequired" defaultMessage="The title is required." />,
  647: <FormattedMessage id="error.YearCannotBeParsed" defaultMessage="The Year is in invalid format." />,
  648: <FormattedMessage id="error.FrequencyDoNotBelongToOptions" defaultMessage="The Frequency do not belong to the options." />,
  649: <FormattedMessage id="error.OperatorDoNotBelongToOptions" defaultMessage="The Operator do not belong to the options." />,
  650: <FormattedMessage id="error.OriginIsRequired" defaultMessage="The origin is required." />,
  651: <FormattedMessage id="error.DetectionDateIsRequired" defaultMessage="The detection date is required." />,
  652: <FormattedMessage id="error.CurrentStateIsRequired" defaultMessage="The Current State is required." />,
  653: <FormattedMessage id="error.OriginDoNotBelongToOptions" defaultMessage="The Origin do not belong to the options." />,
  654: <FormattedMessage id="error.CurrentStateDoNotBelongToOptions" defaultMessage="The Current State do not belong to the options." />,
  655: <FormattedMessage id="error.EffectivenessVerifiedDoNotBelongToOptions" defaultMessage="The Effectiveness Verified do not belong to the options." />,
  656: <FormattedMessage id="error.DescriptionIsRequired" defaultMessage="The Description is required." />,
  657: <FormattedMessage id="error.NextEvaluationDateIsRequired" defaultMessage="The Next Evaluation Date is required." />,
  658: <FormattedMessage id="error.SwotItemNameIsRequired" defaultMessage="The Swot Item is required." />,
  659: <FormattedMessage id="error.ContextDoNotBelongToOptions" defaultMessage="The Context do not belong to the options." />,
  660: <FormattedMessage id="error.TypeIsRequired" defaultMessage="The Type is required." />,
  661: <FormattedMessage id="error.TypeDoNotBelongToOptions" defaultMessage="The Type do not belong to the options." />,
  662: <FormattedMessage id="error.ActionsAreRequired" defaultMessage="The Actions are required." />,
  663: <FormattedMessage id="error.ProbabilityIsRequired" defaultMessage="The Probability is required." />,
  664: <FormattedMessage id="error.ImpactIsRequired" defaultMessage="The Impact is required." />,
  665: <FormattedMessage id="error.ProbabilityDoNotBelongToOptions" defaultMessage="The Probability do not belong to the options." />,
  666: <FormattedMessage id="error.ImpactDoNotBelongToOptions" defaultMessage="The Impact do not belong to the options." />,
  667: <FormattedMessage id="error.ProbabilityCannotBeParsed" defaultMessage="The Probability is in invalid format." />,
  668: <FormattedMessage id="error.ImpactCannotBeParsed" defaultMessage="The Impact is in invalid format." />,
  669: <FormattedMessage id="error.EvaluationDateIsRequired" defaultMessage="The Evaluation Date is required." />,
  670: <FormattedMessage id="error.ErrorOnDownloadFileFromBlob" defaultMessage="There was an error when downloading the file from the blob." />,
  671: <FormattedMessage id="error.PersonHasNoPhoto" defaultMessage="There was no photo for the given person." />,
  672: <FormattedMessage id="error.CompanyCreatorUserCannotBeDeleted" defaultMessage="The creator of the company can not be deleted." />,
  673: <FormattedMessage id="error.UserLimitExceeded" defaultMessage="The user limit has been exceeded." />,
  674: <FormattedMessage id="error.StorageLimitExceeded" defaultMessage="The storage limit has been exceeded." />,
  675: <FormattedMessage id="error.CompanyHasNoActiveSubscription" defaultMessage="The Company has no active subscription." />,
  676: <FormattedMessage id="error.UserAlreadyAffiliate" defaultMessage="The user is already an affiliate." />,
  677: <FormattedMessage id="error.CompanyAlreadyHasAnAffiliate" defaultMessage="The company already has an affiliate." />,
  678: <FormattedMessage id="error.CompanyAlreadyHasActiveSubscription" defaultMessage="The company already has an active subscription." />,
  679: <FormattedMessage id="error.DiscountCouponIsNotValid" defaultMessage="The discount coupon is not valid." />,
  680: <FormattedMessage id="error.CanNotChangePrice" defaultMessage="The Plan for the company can not be changed." />,
  681: <FormattedMessage id="error.YourCurrentPlanDoesNotAllowThisAction" defaultMessage="Your current plan does not allow this action." />,
  682: <FormattedMessage id="error.TheEventCalendarDoesNotExist" defaultMessage="The event calendar does not exist." />,
  683: <FormattedMessage id="error.EmailAlreadyConfirmed" defaultMessage="The account has already been confirmed." />,
  684: <FormattedMessage id="error.PhoneNumberIsInvalid" defaultMessage="The phone number is invalid." />,
  685: <FormattedMessage id="error.PhoneNumberIsRequired" defaultMessage="The phone number is required." />,
  686: <FormattedMessage id="error.CompanyAlreadyHasAProcessMap" defaultMessage="The company already has a Process Map." />,
  687: <FormattedMessage id="error.CompanyAlreadyHasProcesses" defaultMessage="The company already has processes." />,
  688: <FormattedMessage id="error.CountryCodeIsRequired" defaultMessage="The country code is required." />,
  689: <FormattedMessage id="error.ProviderNameRequired" defaultMessage="The provider name is required." />,
  690: <FormattedMessage id="error.ProviderProductsOrServicesOfferedRequired" defaultMessage="The provider products or services offered is required." />,
  691: <FormattedMessage id="error.PersonRoleIsRequired" defaultMessage="The person role is required." />,
  692: <FormattedMessage id="error.TelecomClientsOnly" defaultMessage="This action is only available for Telecom clients." />,
  default: <FormattedMessage id="error.serverError" defaultMessage="Oops! An unexpected error occurred" />,
};

const errorTexts = (code) => errorCode[code] || <FormattedMessage id="error.serverError" defaultMessage="Oops! An unexpected error occurred" />;

const subscriptionErrorMessage = {
  673: {
    title: <FormattedMessage id="stripe.updatePlan.maxUsersExceeded" defaultMessage="LÍMITE DE EMPLEADOS" />,
    message: <FormattedMessage id="stripe.updatePlan.maxUsersExceededMessage" defaultMessage="Llegó a la capacidad máxima de empleados que puede manejar con este plan. Para continuar con la carga, puede actualizar su plan a uno mejor" />,
    button: <FormattedMessage id="stripe.upgradePlan" defaultMessage="Aumentar plan" />,
  },
  674: {
    title: <FormattedMessage id="stripe.updatePlan.storageLimitExceeded" defaultMessage="LÍMITE DE ALMACENAMIENTO" />,
    message: <FormattedMessage id="stripe.updatePlan.storageLimitExceededMessage" defaultMessage="Llegó a la capacidad máxima de almacenamiento que puede manejar con este plan. Para continuar con la carga, puede actualizar su plan a uno mejor" />,
    button: <FormattedMessage id="stripe.upgradePlan" defaultMessage="Aumentar plan" />,
  },
  default: {
    title: <FormattedMessage id="stripe.updatePlan.default" defaultMessage="LÍMITE DEL PLAN" />,
    message: <FormattedMessage id="stripe.updatePlan.defaultMessage" defaultMessage="Llegó a la capacidad máxima de su plan, para continuar con la acción actual, puede actualizar su plan a uno mejor" />,
    button: <FormattedMessage id="stripe.upgradePlan" defaultMessage="Aumentar plan" />,
  },
};

const stripeDeclineCode = {
  authentication_required: <FormattedMessage id="stripe.authentication_required" defaultMessage="La tarjeta se rechazó porque la transacción requiere autenticación" />,
  approve_with_id: <FormattedMessage id="stripe.approve_with_id" defaultMessage="No se puede autorizar el pago" />,
  call_issuer: <FormattedMessage id="stripe.call_issuer" defaultMessage="La tarjeta se ha rechazado por un motivo desconocido" />,
  card_not_supported: <FormattedMessage id="stripe.card_not_supported" defaultMessage="La tarjeta no acepta este tipo de compras" />,
  card_velocity_exceeded: <FormattedMessage id="stripe.card_velocity_exceeded" defaultMessage="El cliente ha excedido el límite del saldo o del crédito disponible en su tarjeta" />,
  currency_not_supported: <FormattedMessage id="stripe.currency_not_supported" defaultMessage="La tarjeta no acepta la divisa especificada" />,
  do_not_honor: <FormattedMessage id="stripe.do_not_honor" defaultMessage="La tarjeta se ha rechazado por un motivo desconocido" />,
  do_not_try_again: <FormattedMessage id="stripe.do_not_try_again" defaultMessage="La tarjeta se ha rechazado por un motivo desconocido" />,
  duplicate_transaction: <FormattedMessage id="stripe.duplicate_transaction" defaultMessage="Hace muy poco se realizó otra transacción por el mismo importe con una tarjeta de crédito con los mismos datos" />,
  expired_card: <FormattedMessage id="stripe.expired_card" defaultMessage="La tarjeta ha caducado" />,
  fraudulent: <FormattedMessage id="stripe.fraudulent" defaultMessage="El pago se ha rechazado porque Stripe sospecha que es fraudulento" />,
  generic_decline: <FormattedMessage id="stripe.generic_decline" defaultMessage="La tarjeta se ha rechazado por un motivo desconocido" />,
  incorrect_number: <FormattedMessage id="stripe.incorrect_number" defaultMessage="El número de tarjeta no es correcto" />,
  incorrect_cvc: <FormattedMessage id="stripe.incorrect_cvc" defaultMessage="El número de CVC no es correcto" />,
  incorrect_pin: <FormattedMessage id="stripe.incorrect_pin" defaultMessage="El PIN introducido es incorrecto, Este código de rechazo solo se aplica a los pagos efectuados con un lector de tarjetas" />,
  incorrect_zip: <FormattedMessage id="stripe.incorrect_zip" defaultMessage="El código postal no es correcto" />,
  insufficient_funds: <FormattedMessage id="stripe.insufficient_funds" defaultMessage="La tarjeta no tiene fondos suficientes para hacer la compra" />,
  invalid_account: <FormattedMessage id="stripe.invalid_account" defaultMessage="La tarjeta o la cuenta a la que está conectada la tarjeta no es válida" />,
  invalid_amount: <FormattedMessage id="stripe.invalid_amount" defaultMessage="El importe del pago no es válido o excede el importe permitido" />,
  invalid_cvc: <FormattedMessage id="stripe.invalid_cvc" defaultMessage="El número de CVC no es correcto" />,
  invalid_expiry_month: <FormattedMessage id="stripe.invalid_expiry_month" defaultMessage="El mes de caducidad no es válido" />,
  invalid_expiry_year: <FormattedMessage id="stripe.invalid_expiry_year" defaultMessage="El año de caducidad no es válido" />,
  invalid_number: <FormattedMessage id="stripe.invalid_number" defaultMessage="El número de tarjeta no es correcto" />,
  invalid_pin: <FormattedMessage id="stripe.invalid_pin" defaultMessage="El PIN introducido es incorrecto, Este código de rechazo solo se aplica a los pagos efectuados con un lector de tarjetas" />,
  issuer_not_available: <FormattedMessage id="stripe.issuer_not_available" defaultMessage="No se ha podido establecer contacto con el emisor de la tarjeta, así que no se ha podido autorizar el pago" />,
  lost_card: <FormattedMessage id="stripe.lost_card" defaultMessage="El pago se ha rechazado porque la tarjeta figura como tarjeta perdida" />,
  merchant_blacklist: <FormattedMessage id="stripe.merchant_blacklist" defaultMessage="El pago se ha rechazado porque coincide con un valor de la lista de bloqueo del usuario de Stripe" />,
  new_account_information_available: <FormattedMessage id="stripe.new_account_information_available" defaultMessage="La tarjeta o la cuenta a la que está conectada la tarjeta no es válida" />,
  no_action_taken: <FormattedMessage id="stripe.no_action_taken" defaultMessage="La tarjeta se ha rechazado por un motivo desconocido" />,
  not_permitted: <FormattedMessage id="stripe.not_permitted" defaultMessage="El pago no está permitido" />,
  offline_pin_required: <FormattedMessage id="stripe.offline_pin_required" defaultMessage="La tarjeta se ha rechazado porque hace falta un PIN" />,
  online_or_offline_pin_required: <FormattedMessage id="stripe.online_or_offline_pin_required" defaultMessage="La tarjeta se ha rechazado porque hace falta un PIN" />,
  pickup_card: <FormattedMessage id="stripe.pickup_card" defaultMessage="El cliente no puede usar esta tarjeta para efectuar este pago, (Es posible que haya sido denunciada por pérdida o robo)" />,
  pin_try_exceeded: <FormattedMessage id="stripe.pin_try_exceeded" defaultMessage="Se ha superado el número permitido de intentos de introducción del PIN" />,
  processing_error: <FormattedMessage id="stripe.processing_error" defaultMessage="Ha ocurrido un error mientras se procesaba la tarjeta" />,
  reenter_transaction: <FormattedMessage id="stripe.reenter_transaction" defaultMessage="El emisor no ha podido procesar el pago por un motivo desconocido" />,
  restricted_card: <FormattedMessage id="stripe.restricted_card" defaultMessage="El cliente no puede usar esta tarjeta para efectuar este pago, (Es posible que haya sido denunciada por pérdida o robo)" />,
  revocation_of_all_authorizations: <FormattedMessage id="stripe.revocation_of_all_authorizations" defaultMessage="La tarjeta se ha rechazado por un motivo desconocido" />,
  revocation_of_authorization: <FormattedMessage id="stripe.revocation_of_authorization" defaultMessage="La tarjeta se ha rechazado por un motivo desconocido" />,
  security_violation: <FormattedMessage id="stripe.security_violation" defaultMessage="La tarjeta se ha rechazado por un motivo desconocido" />,
  service_not_allowed: <FormattedMessage id="stripe.service_not_allowed" defaultMessage="La tarjeta se ha rechazado por un motivo desconocido" />,
  stolen_card: <FormattedMessage id="stripe.stolen_card" defaultMessage="El pago se ha rechazado porque la tarjeta figura como robada" />,
  stop_payment_order: <FormattedMessage id="stripe.stop_payment_order" defaultMessage="La tarjeta se ha rechazado por un motivo desconocido" />,
  testmode_decline: <FormattedMessage id="stripe.testmode_decline" defaultMessage="Se utilizó el número de una tarjeta de prueba de Stripe" />,
  transaction_not_allowed: <FormattedMessage id="stripe.transaction_not_allowed" defaultMessage="La tarjeta se ha rechazado por un motivo desconocido" />,
  try_again_later: <FormattedMessage id="stripe.try_again_later" defaultMessage="La tarjeta se ha rechazado por un motivo desconocido" />,
  withdrawal_count_limit_exceeded: <FormattedMessage id="stripe.withdrawal_count_limit_exceeded" defaultMessage="El cliente ha excedido el límite del saldo o del crédito disponible en su tarjeta" />,
};

const isSubscriptionError = (code) => {
  if (code) {
    return Object.keys(subscriptionErrorMessage).includes(code.toString());
  }
  return false;
};

const getSubscriptionErrorMessage = (code) => subscriptionErrorMessage[code] || subscriptionErrorMessage.default;

const getStripeErrorMessage = ({ declineCode, message }) => stripeDeclineCode[declineCode] || message;

const errorCodeHelper = {
  errorTexts, isSubscriptionError, getSubscriptionErrorMessage, getStripeErrorMessage,
};

export default errorCodeHelper;
