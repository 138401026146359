import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { riskActions } from '../../../redux/actions';
import BulkCreationResult from '../../BulkUpload/BulkCreationResult';

const useStyles = makeStyles((theme) => ({
  paddingNone: {
    padding: theme.spacing(0),
  },
  paddingItem: {
    paddingLeft: theme.spacing(0),
  },
  paddingTitles: {
    padding: '6px 0px 6px 12px',
  },
}));

const RiskBulkCreationResult = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const risk = useSelector((state) => state.risk);

  const tableErrors = [
    {
      id: 1,
      row: 'row',
      name: intl.formatMessage({ id: 'risks.row', defaultMessage: 'Fila' }),
      classesHead: classes.paddingNone,
      classesBody: classes.paddingItem,
    },
    {
      id: 2,
      row: 'title',
      name: intl.formatMessage({ id: 'risks.name', defaultMessage: 'Título' }),
      classesHead: classes.paddingTitles,
    },
    {
      id: 3,
      row: 'detectionDate',
      name: intl.formatMessage({ id: 'risks.detectionDate', defaultMessage: 'Fecha de detección' }),
      classesHead: classes.paddingTitles,
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/YYYY'),
      },
    },
    {
      id: 4,
      row: 'nextEvaluationDate',
      name: intl.formatMessage({ id: 'risks.nextEvaluationDate', defaultMessage: 'Fecha de Próxima Evaluación' }),
      classesHead: classes.paddingTitles,
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/YYYY'),
      },
    },
    {
      id: 5,
      row: 'initialTreatmentIdentification',
      name: intl.formatMessage({ id: 'risks.initialTreatmentIdentification', defaultMessage: 'Identificación' }),
      classesHead: classes.paddingTitles,
    },
    {
      id: 6,
      row: 'actions',
      name: intl.formatMessage({ id: 'risks.actions', defaultMessage: 'Acciones' }),
      classesHead: classes.paddingTitles,
    },
    {
      id: 7,
      row: 'swotItemName',
      name: intl.formatMessage({ id: 'risks.swotAssociated', defaultMessage: 'Contexto de la organización' }),
    },
    {
      id: 8,
      row: 'probability',
      name: intl.formatMessage({ id: 'risks.probability', defaultMessage: 'Probabilidad' }),
      classesHead: classes.paddingTitles,
    },
    {
      id: 9,
      row: 'impact',
      name: intl.formatMessage({ id: 'risks.impact', defaultMessage: 'Impacto' }),
      classesHead: classes.paddingTitles,
    },
  ];
  const tableLoaded = [
    {
      id: 1,
      row: 'title',
      name: intl.formatMessage({ id: 'risks.name', defaultMessage: 'Título' }),
      classesHead: classes.paddingNone,
      classesBody: classes.paddingItem,
    },
    {
      id: 2,
      row: 'detectionDate',
      name: intl.formatMessage({ id: 'risks.detectionDate', defaultMessage: 'Fecha de detección' }),
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/YYYY'),
      },
    },
    {
      id: 3,
      row: 'nextEvaluationDate',
      name: intl.formatMessage({ id: 'risks.nextEvaluationDate', defaultMessage: 'Fecha de Próxima Evaluación' }),
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/YYYY'),
      },
    },
    { id: 4, row: 'initialTreatmentIdentification', name: intl.formatMessage({ id: 'risks.initialTreatmentIdentification', defaultMessage: 'Identificación' }) },
    { id: 5, row: 'actions', name: intl.formatMessage({ id: 'risks.actions', defaultMessage: 'Acciones' }) },
    {
      id: 6,
      row: 'swotItemName',
      name: intl.formatMessage({ id: 'risks.swotAssociated', defaultMessage: 'Contexto de la organización' }),
    },
    { id: 7, row: 'probability', name: intl.formatMessage({ id: 'risks.probability', defaultMessage: 'Probabilidad' }) },
    { id: 8, row: 'impact', name: intl.formatMessage({ id: 'risks.impact', defaultMessage: 'Impacto' }) },
  ];

  const linkRedirect = '/risks/upload';
  const linkEntity = '/risks';
  const text = {
    bulkCreationBreadcrumbs: intl.formatMessage({ id: 'risks.bulkCreationBreadcrumbs', defaultMessage: 'Carga múltiple' }),
    bulkNotCreated: intl.formatMessage({ id: 'risks.bulkRisksNotCreated', defaultMessage: 'Estos riesgos no serán creados' }),
    bulkUploadedSuccess: intl.formatMessage({ id: 'risks.bulkRisksUploadedSuccess', defaultMessage: 'Riesgos cargados correctamente:' }),
    create: intl.formatMessage({ id: 'risks.createBulkRisks', defaultMessage: 'Crear riesgos' }),
    emptyListSuccessfulUpload: intl.formatMessage({ id: 'risks.emptyListSuccessfulUploadRisks', defaultMessage: 'No hay riesgos cargados correctamente, por favor vuelva a cargar los datos respetando el archivo de ejemplo.' }),
    messageUploadedSuccess: intl.formatMessage({ id: 'risks.messageUploadedSuccess', defaultMessage: 'Los riesgos se han agregado correctamente' }),
    onlySuccessUploadWillBeCreated: intl.formatMessage({ id: 'risks.onlySuccessUploadRisksWillBeCreated', defaultMessage: 'Solo se crearán los riesgos cargados correctamente.' }),
    title: intl.formatMessage({ id: 'risks.title', defaultMessage: 'Riesgos' }),
  };
  function handleConfirmRisks() {
    return (dispatch(
      riskActions.confirmCreateBulkRisks(
        risk.bulkCreationPreviewResult.id, text.messageUploadedSuccess,
      ),
    ));
  }

  return (
    <BulkCreationResult
      entity={risk}
      handleConfirmResult={handleConfirmRisks}
      linkEntity={linkEntity}
      linkRedirect={linkRedirect}
      tableErrors={tableErrors}
      tableLoaded={tableLoaded}
      text={text}
    />
  );
};

export default RiskBulkCreationResult;
