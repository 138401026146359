import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import colors from '../../../assets/colors';

const CalendarMuiAdapter = ({ theme }) => {
  if (!theme.palette) {
    return <></>;
  }
  return (
    <Helmet>
      <style type="text/css">
        {`
                .fc {
                    font-size: 12px;
                    font-weight: 400;
                    font-family: ${theme.typography.fontFamily}
                }
                .fc .fc-button-primary {
                    background: ${colors.orangeLight};
                    border: none;
                    height: 36px;
                    width: 37px;
                    border-radius: 0;
                }
                .fc .fc-today-button {
                    width: 74px;
                    border-radius: 4px;
                }
                .fc .fc-prev-button {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
                .fc .fc-next-button {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
                .fc .fc-button-primary:not(:disabled):active,
                .fc .fc-button-primary:not(:disabled).fc-button-active {
                    background: ${theme.palette.primary.secondary};
                }
                .fc .fc-col-header-cell-cushion {
                    font-size: 14px;
                    font-weight: 500;
                }
                .fc .fc-daygrid-day-number: {
                    font-weight: 500;
                }
                .fc-event {
                    cursor: pointer;
                }
                .fc-toolbar-chunk div:first-child {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }
                .fc .fc-toolbar-title {
                    font-size: 24px;
                    text-align: center;
                    font-weight: 700;
                    text-transform: capitalize;
                    margin-left: 16px;
                }
                .fc-daygrid-event {
                    font-size: 12px;
                    color: #fff;
                    white-space: normal;
                }
            `}

      </style>
    </Helmet>
  );
};

CalendarMuiAdapter.propTypes = {
  theme: PropTypes.shape(),
};

CalendarMuiAdapter.defaultProps = {
  theme: {},
};

export default CalendarMuiAdapter;
