import alertActions from './alert.actions';
import { calendarConstants } from '../../constants';
import { calendarService } from '../../services';
import { calendarHelper } from '../../helpers';

function getAllEvents() {
  function request() { return { type: calendarConstants.GET_ALL_REQUEST }; }
  function success(events) { return { type: calendarConstants.GET_ALL_SUCCESS, events }; }
  function failure() { return { type: calendarConstants.GET_ALL_FAILURE }; }

  return (dispatch) => {
    dispatch(request());
    calendarService.getAllEvents()
      .then(
        (events) => {
          const styledEvents = calendarHelper.setFrontPropsToEvents(events);
          dispatch(success(styledEvents));
        },
        (error) => {
          dispatch(failure());
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getMonthlyEvents(startDate, endDate) {
  function success(events) { return { type: calendarConstants.GET_MONTHLY_SUCCESS, events }; }

  return (dispatch) => {
    calendarService.getMonthlyEvents(startDate, endDate)
      .then(
        (events) => {
          const styledEvents = calendarHelper.setFrontPropsToEvents(events);
          dispatch(success(styledEvents));
        },
        (error) => {
          dispatch(alertActions.error(error));
        },
      );
  };
}

const calendarAllActions = {
  getAllEvents, getMonthlyEvents,
};

export default calendarAllActions;
