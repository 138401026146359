import { uniqBy } from 'lodash-es';
import { calendarConstants } from '../../constants';

function calendarAll(state = {}, action) {
  switch (action.type) {
    case calendarConstants.GET_ALL_REQUEST:
      return { requesting: true };
    case calendarConstants.GET_MONTHLY_SUCCESS: {
      const currentEvents = state.data || [];
      const uniqEvents = uniqBy([...currentEvents, ...action.events], ((item) => item.id));
      return { data: uniqEvents };
    }
    case calendarConstants.GET_ALL_SUCCESS:
      return { data: action.events };
    case calendarConstants.GET_ALL_FAILURE:
      return { error: true };

    default:
      return state;
  }
}

export default calendarAll;
