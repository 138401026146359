import { providerConstants } from '../../constants';
import { providerService } from '../../services';
import { history } from '../../helpers';
import alertActions from './alert.actions';

function create(provider, message) {
  function request() { return { type: providerConstants.CREATE_REQUEST }; }
  function success(created) { return { type: providerConstants.CREATE_SUCCESS, created }; }
  function failure(error) { return { type: providerConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(provider));

    providerService.create(provider)
      .then(
        (createdProvider) => {
          dispatch(success(createdProvider));
          dispatch(alertActions.success(message));
          history.push(`/providers/${createdProvider.id}`);
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function getAll() {
  function request() { return { type: providerConstants.GET_ALL_REQUEST }; }
  function success(provider) { return { type: providerConstants.GET_ALL_SUCCESS, provider }; }
  function failure(error) { return { type: providerConstants.GET_ALL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    providerService.getAll()
      .then(
        (provider) => {
          dispatch(success(provider));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function get(id) {
  function request() { return { type: providerConstants.GET_REQUEST }; }
  function success(provider) { return { type: providerConstants.GET_SUCCESS, provider }; }
  function failure(error) { return { type: providerConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    providerService.get(id)
      .then(
        (provider) => {
          dispatch(success(provider));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
          history.push('/home');
        },
      );
  };
}

function update(id, provider, message) {
  function request() { return { type: providerConstants.UPDATE_REQUEST }; }
  function success(updatedProvider) {
    return { type: providerConstants.UPDATE_SUCCESS, updatedProvider };
  }
  function failure(error) { return { type: providerConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    providerService.update(id, provider)
      .then(
        (updatedProvider) => {
          dispatch(success(updatedProvider));
          dispatch(alertActions.success(message));
          history.push(`/providers/${updatedProvider.id}`);
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function deleteProvider(providerId, message) {
  function request() { return { type: providerConstants.DELETE_REQUEST }; }
  function success() { return { type: providerConstants.DELETE_SUCCESS }; }
  function failure() { return { type: providerConstants.DELETE_FAILURE }; }

  return (dispatch) => {
    dispatch(request());

    providerService.deleteProvider(providerId)
      .then(
        () => {
          dispatch(success());
          dispatch(alertActions.success(message));
          history.push('/providers');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function createBulk(excelFile) {
  function request() { return { type: providerConstants.CREATE_BULK_REQUEST }; }
  function success(bulkCreationPreviewResult) {
    return {
      type: providerConstants.CREATE_BULK_SUCCESS,
      bulkCreationPreviewResult,
    };
  }
  function failure(error) {
    return { type: providerConstants.CREATE_BULK_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    providerService.createBulk(excelFile)
      .then(
        (bulkCreationPreviewResult) => {
          dispatch(success(bulkCreationPreviewResult));
          history.push('/providers/result');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

function confirmCreateBulk(id, messageSuccess) {
  function request() { return { type: providerConstants.CONFIRM_CREATE_BULK_REQUEST }; }
  function success() { return { type: providerConstants.CONFIRM_CREATE_BULK_SUCCESS }; }
  function failure(error) {
    return { type: providerConstants.CONFIRM_CREATE_BULK_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    providerService.confirmCreateBulk(id)
      .then(
        () => {
          dispatch(success());
          dispatch(alertActions.success(messageSuccess));
          history.replace('/providers');
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        },
      );
  };
}

const providerActions = {
  create,
  get,
  getAll,
  update,
  deleteProvider,
  createBulk,
  confirmCreateBulk,
};

export default providerActions;
