import React, { useState, useEffect } from 'react';
import {
  Box, Dialog, DialogContent,
  IconButton, makeStyles, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import PlayYouTubeIcon from '../../assets/icons/PlayYouTubeIcon';
import YouTubeEmbed from './YouTubeEmbed';
import { STEPS } from '../../constants/tutorial.constants';
import TutorialTooltip from './TutorialTooltip';
import { useDispatch } from 'react-redux';
import tutorialActions from '../../redux/actions/tutorial.actions';

const useStyles = makeStyles(() => ({
  textTitleDialog: {
    color: '#F79A56',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '18px',
  },
  textDialog: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    maxWidth: '470px',
  },
  closeButton: {
    height: '25px',
    padding: '0',
    width: '25px',
  },
  playButton: {
    border: '1px solid #D12828',
    borderRadius: '30px',
    color: '#D12828',
    height: '30px',
    width: '125px'
  },
  linkText: {
    display: 'inline',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  linkTitle: {
    display: 'inline',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '24px',
    paddingRight: '4px',
  },
}));

const VideoTutorialModal = ({ body, title, videoId, isRegister, isOrgChart }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const listEnabledTools = useSelector((state) => state.stripe.data) || {};
  const step = useSelector((state) => state.tutorial.step);
  const [openDialog, setOpenDialog] = useState(false);
  const [isTutorialTooltipOpen, setIsTutorialTooltipOpen] = useState(step === 7 && isOrgChart);
  const linkYouTube = `https://www.youtube.com/watch?v=${videoId}`;
  const bodyTextDefault = <FormattedMessage id="videoTutorial.body" defaultMessage="Aquí le dejamos un video mostrando las funciones de esta sección, puede volverlo a ver en cualquier momento desde el ícono superior." />;

  const toggleDialog = () => setOpenDialog((prev) => !prev);

  function handleCloseTutorialTooltip() {
    setIsTutorialTooltipOpen(false)
    dispatch(tutorialActions.setTutorialStep(8));
  }
  
  useEffect(() => {
    if (step === 7 && isOrgChart) setIsTutorialTooltipOpen(true);
  }, [step, isOrgChart]);

  return (
    <>
      { (listEnabledTools.videoTutorials?.isValid || isRegister) && (
        <>
          {(step === 7 && isOrgChart)
          ? (
            <TutorialTooltip
              open={isTutorialTooltipOpen}
              placement="right"
              arrow
              interactive
              title={
                <Box display="flex">
                  <Box>
                    {STEPS[6].text}
                  </Box>
                  <Box display="flex" alignItems="center">
                    <IconButton style={{ padding: 0, color: '#FFF' }}>
                      <CloseIcon onClick={handleCloseTutorialTooltip} />
                    </IconButton>
                  </Box>
                </Box>
              }
            >
              <IconButton aria-label="close" className={classes.playButton} onClick={toggleDialog}>
                <PlayYouTubeIcon />
              </IconButton>
            </TutorialTooltip>
          ) : (
            <IconButton aria-label="close" className={classes.playButton} onClick={toggleDialog}>
              <Box display="flex" alignItems="center">
                <PlayYouTubeIcon />
                <Typography style={{ fontSize: '10px', fontWeight: '600', marginLeft: '10px' }}>TUTORIAL</Typography>
              </Box>
            </IconButton>
          )}
      </>
      )}
      <Dialog
        open={openDialog}
        onClose={toggleDialog}
        aria-labelledby="alert-dialog-bulkCreation-title"
        aria-describedby="alert-dialog-bulkCreation-description"
      >
        <Box pt={3} pb={1} px={3} display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography align="left" className={classes.textTitleDialog} variant="body1">
              {title}
            </Typography>
          </Box>
          <IconButton aria-label="close" className={classes.closeButton} onClick={toggleDialog}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography align="left" display="block" className={classes.textDialog} variant="body1">
            {body || bodyTextDefault}
          </Typography>
        </DialogContent>
        <Box py={2} px={3}>
          <YouTubeEmbed videoId={videoId} />
        </Box>
        <Box pb={3} px={3} display="flex" justifyContent="left">
          <Typography display="inline" className={classes.linkTitle} variant="body1">
            Link:
          </Typography>
          <Link to={{ pathname: linkYouTube }} target="_blank" rel="noopener noreferrer" className={classes.linkText}>
            {linkYouTube}
          </Link>
        </Box>
      </Dialog>
    </>
  );
};

VideoTutorialModal.propTypes = {
  videoId: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  isRegister: PropTypes.bool,
};

VideoTutorialModal.defaultProps = {
  body: '',
  isRegister: false,
};

export default VideoTutorialModal;
