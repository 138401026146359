import React from 'react';
import { useIntl } from 'react-intl';
import {
  Box, Breadcrumbs, Grid, LinearProgress, makeStyles, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageLayout from '../PageLayout';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#F8F8F8',
  },
}));

function UserProfilePage() {
  const classes = useStyles();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'user.editProfile', defaultMessage: 'Editar perfil' });
  const userEmailText = intl.formatMessage({ id: 'user.email', defaultMessage: 'Email' });
  const userCompanyText = intl.formatMessage({ id: 'user.company', defaultMessage: 'Empresa' });
  const userDataText = intl.formatMessage({ id: 'user.data', defaultMessage: 'Datos de usuario' });

  const user = useSelector((state) => state.authentication.user);
  const company = useSelector((state) => state.company);

  function ProcessPageBreadcrumbs() {
    return (
      <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/user/profile" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{title}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  return (
    <PageLayout
      title={title}
      breadCrumbs={<ProcessPageBreadcrumbs />}
    >
      {(company.data && user) ? (
        <>
          <Box pl={3}>
            <Typography variant="h6" style={{ fontSize: '18px', fontWeight: 700 }}>
              <strong>{userDataText}</strong>
            </Typography>
          </Box>
          <Box pl={4} mt={1}>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="caption" gutterBottom>
                  <strong style={{ fontSize: '14px' }}>{userEmailText}</strong>
                </Typography>
                <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                  {user.email}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" gutterBottom>
                  <strong style={{ fontSize: '14px' }}>{userCompanyText}</strong>
                </Typography>
                <Typography variant="subtitle1" gutterBottom style={{ fontSize: '12px' }}>
                  {company.data.name}
                </Typography>
              </Grid>
            </Grid>

          </Box>
        </>
      ) : <LinearProgress />}

    </PageLayout>
  );
}

export default UserProfilePage;
