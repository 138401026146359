import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs, LinearProgress, Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PageLayout from '../../PageLayout';
import { deviationActions } from '../../../redux/actions';
import DeviationViewDetail from './DeviationViewDetail';

const DeviationVersionDetailPage = ({ routeParams: { id: deviationId, versionId } }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const deviation = useSelector((state) => state.deviation.data);
  const [versionData, setVersionData] = useState(null);
  const title = intl.formatMessage({ id: 'deviation.title', defaultMessage: 'Desvíos' });
  const deviationDetails = intl.formatMessage({ id: 'deviation.deviationDetails', defaultMessage: 'Detalles' });
  const versionTitle = intl.formatMessage({ id: 'deviation.versionTitle', defaultMessage: 'Versión' });

  function DeviationDetailBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/deviations" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{title}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}><strong>{deviationDetails}</strong></Typography>
      </Breadcrumbs>
    );
  }

  function DeviationBreadcrumbs() {
    const link = `/deviations/${deviationId}`;
    const versionLink = `/deviations/${deviationId}/${versionId}`;
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/deviations" style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{title}</Typography>
        </Link>
        <Link color="inherit" to={link} style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 400, fontSize: 11 }}>{deviationId}</Typography>
        </Link>
        <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{versionTitle}</Typography>
        <Link color="inherit" to={versionLink} style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11 }}>{versionId}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  useEffect(() => {
    dispatch(deviationActions.getDeviations(deviationId));
  }, [deviationId, dispatch]);

  useEffect(() => {
    if (deviation) {
      setVersionData(
        deviation.deviationVersions.find((version) => String(version.id) === String(versionId)),
      );
    }
  }, [deviation, versionId]);

  return (
    <>
      {versionData
        ? (
          <PageLayout title={versionData.title} breadCrumbs={<DeviationBreadcrumbs />}>
            <DeviationViewDetail data={versionData} />
          </PageLayout>
        )
        : (
          <PageLayout title={title} breadCrumbs={<DeviationDetailBreadcrumbs />}>
            <LinearProgress />
          </PageLayout>
        )}
    </>
  );
};

DeviationVersionDetailPage.propTypes = {
  routeParams: PropTypes.shape(),
};

DeviationVersionDetailPage.defaultProps = {
  routeParams: {},
};

export default DeviationVersionDetailPage;
