import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box, Container, FormControl, Grid, Input, InputLabel,
  LinearProgress, makeStyles, MenuItem, Select, Typography,
} from '@material-ui/core';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { DatePicker } from '@material-ui/pickers';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import queryString from 'query-string';
import processActions from '../../redux/actions/process.actions';
import Button from '../controls/Button';
import { validationConstants, workFieldEvaluationTexts } from '../../constants';
import riskActions from '../../redux/actions/risk.actions';
import { personActions, providerActions, workFieldEvaluationActions } from '../../redux/actions';
import { history, sortableHelper, workFieldHelper } from '../../helpers';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  formTitle: {
    borderBottom: '1px solid #EBEBEB',
    marginTop: '40px',
    marginBottom: '10px',
  },
  formControlProvider: {
    width: '100%',
    marginTop: '16px',
    textAlign: 'left',
  },
  autoCompleteInput: {
    marginTop: 0,
  },
  selectValidator: {
    margin: '16px 0px 11px',
    height: '40px',
    maxHeight: '40px',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const InputProps = { disableUnderline: true };
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  getContentAnchorEl: null,
};

const riskResultColors = {
  veryHigh: '#BF1919',
  high: '#FB9435',
  mid: '#FDC41C',
  low: '#43A047',
};

const RiskForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const initialFieldValues = {
    riskTitle: '',
    riskDetectionDate: new Date(),
    riskEvaluationDate: new Date(),
    riskNextEvaluationDate: new Date(),
    riskIdentification: '',
    riskContext: '',
    riskRequirement: '',
    riskDetail: '',
    riskStakeholdersIdentification: '',
    riskEvaluationActions: '',
    riskProbability: '',
    riskImpact: '',
    swotItemName: '',
    workFieldEvaluationId: null,
    selectedProvider: null,
  };
  const [values, setValues] = useState(initialFieldValues);
  const [riskProcesses, setRiskProcesses] = useState([]);
  const [swotItemId, setSwotItemId] = useState(null);

  const company = useSelector((state) => state.company.data);
  const creatingRisk = useSelector((state) => state.risk.creating);
  const processes = useSelector((state) => state.process.processes);
  const people = useSelector((state) => state.person.people);
  const providers = useSelector((state) => state.provider.providers);
  const workFieldEvaluationUnapproved = useSelector(
    (state) => state.workFieldEvaluation.workFieldEvaluations,
  );

  const titleLabel = intl.formatMessage({ id: 'risks.label.title', defaultMessage: 'Título' });
  const processesLabel = intl.formatMessage({ id: 'risks.label.processes', defaultMessage: 'Procesos' });
  const detectionDateLabel = intl.formatMessage({ id: 'risks.label.detectionDate', defaultMessage: 'Fecha de detección' });
  const evaluationDateLabel = intl.formatMessage({ id: 'risks.label.evaluationDate', defaultMessage: 'Fecha de evaluación' });
  const nextEvaluationDateLabel = intl.formatMessage({ id: 'risks.label.nextEvaluationDate', defaultMessage: 'Fecha de próxima evaluación' });
  const invalidDateLabel = intl.formatMessage({ id: 'app.invalid_date', defaultMessage: 'Fecha incorrecta' });
  const probabilityLabel = intl.formatMessage({ id: 'risks.label.probability', defaultMessage: 'Probabilidad' });
  const impactLabel = intl.formatMessage({ id: 'risks.label.impact', defaultMessage: 'Impacto' });
  const noProcessesText = intl.formatMessage({ id: 'app.noProcesses', defaultMessage: 'No hay procesos cargados en el sistema, puede ' });
  const createProcessLink = intl.formatMessage({ id: 'app.createProcess', defaultMessage: 'crear un nuevo proceso' });
  const createText = intl.formatMessage({ id: 'app.create', defaultMessage: 'Crear' });
  const cancelText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const riskInChargePersonLabel = intl.formatMessage({ id: 'risks.label.inChargePerson', defaultMessage: 'Responsable de cumplimiento' });
  const swotItemNameLabel = intl.formatMessage({ id: 'risks.swotAssociated', defaultMessage: 'Contexto de la organización' });

  const probabilityImpactValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [componentMounted, setComponentMounted] = useState(false);
  const [riskResult, setRiskResult] = useState('-');
  const [riskLevel, setRiskLevel] = useState('-');
  const parsedQueryString = queryString.parse(history.location.search);
  const [queryParams] = useState({
    providerId: parsedQueryString.providerId,
    evaluationId: parsedQueryString.evaluationId,
  });

  const riskResultLimits = useMemo(() => ({
    veryHigh: 7,
    high: 6,
    mid: 4,
  }), []);

  function getRiskColor(riskResultC) {
    if (riskResultC >= riskResultLimits.veryHigh) { return riskResultColors.veryHigh; }
    if (riskResultC >= riskResultLimits.high) { return riskResultColors.high; }
    if (riskResultC >= riskResultLimits.mid) { return riskResultColors.mid; }
    return riskResultColors.low;
  }

  const renderChip = (selected) => selected.map((value) => (
    <Chip
      key={value.id}
      label={value.name}
      className={classes.chip}
    />
  ));

  useEffect(() => {
    if (!people) { dispatch(personActions.getAll()); }

    function getRiskResult(riskProbabilityR, riskImpactR) {
      return (riskProbabilityR + riskImpactR) / 2;
    }

    if (company.swotItems && company.swotItems.length > 0) {
      company.swotItems.sort((a, b) => a.type - b.type);
    }

    function getRiskLevel(riskResultL) {
      if (riskResultL >= riskResultLimits.veryHigh) { return intl.formatMessage({ id: 'risks.level.veryHigh', defaultMessage: 'Muy alto' }); }
      if (riskResultL >= riskResultLimits.high) { return intl.formatMessage({ id: 'risks.level.high', defaultMessage: 'Alto' }); }
      if (riskResultL >= riskResultLimits.mid) { return intl.formatMessage({ id: 'risks.level.mid', defaultMessage: 'Medio' }); }
      return intl.formatMessage({ id: 'risks.level.low', defaultMessage: 'Bajo' });
    }

    if (!componentMounted) {
      dispatch(processActions.getAll());
      dispatch(workFieldEvaluationActions.getAllUnapproved());
      dispatch(providerActions.getAll());
      setComponentMounted(true);
    }

    if (componentMounted && values.riskProbability && values.riskImpact) {
      const result = getRiskResult(values.riskProbability, values.riskImpact);
      setRiskResult(result);
      setRiskLevel(getRiskLevel(result));
    }

    if (queryParams.providerId && providers && !values.selectedProvider) {
      const selectedProvider = providers.find((p) => p.id.toString() === queryParams.providerId);
      if (selectedProvider) {
        setValues((prev) => ({ ...prev, selectedProvider: selectedProvider.id }));
      }
    }
    if (queryParams.evaluationId && workFieldEvaluationUnapproved
       && !values.workFieldEvaluationId) {
      const selectedEvaluation = workFieldEvaluationUnapproved
        .find((p) => p.id.toString() === queryParams.evaluationId);
      if (selectedEvaluation) {
        setValues((prev) => ({ ...prev, workFieldEvaluationId: selectedEvaluation.id }));
      }
    }
  }, [dispatch, componentMounted, values.riskProbability, values.riskImpact,
    riskResult, processes, intl, riskResultLimits, people, company.swotItems,
    providers, values.selectedProvider, queryParams, values.workFieldEvaluationId,
    workFieldEvaluationUnapproved]);

  function handleChangeSwotItem(e, value = {}) {
    if (value.id) {
      setSwotItemId(value.id);
      setValues({ ...values, swotItemName: value.description });
    } else {
      setSwotItemId(null);
      setValues({ ...values, swotItemName: e.target.value });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    const selectedRiskProcesses = [];
    riskProcesses.forEach((process) => {
      selectedRiskProcesses.push(process.id);
    });

    const risk = {
      title: values.riskTitle,
      detectionDate: values.riskDetectionDate,
      processes: selectedRiskProcesses,
      nextEvaluationDate: values.riskNextEvaluationDate,
      initialTreatmentIdentification: values.riskIdentification,
      detail: values.riskDetail,
      context: values.riskContext,
      type: values.riskType,
      stakeHoldersIdentification: values.riskStakeholdersIdentification,
      stakeHoldersNeeds: values.riskRequirement,
      actions: values.riskEvaluationActions,
      probability: values.riskProbability,
      impact: values.riskImpact,
      result: null,
      evaluationDate: values.riskEvaluationDate,
      inChargePersonId: values.inChargePersonId,
      swotItemName: values.swotItemName,
      workFieldEvaluationId: values.workFieldEvaluationId,
      swotItemId,
    };

    dispatch(riskActions.create(risk, '/risks/'));
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  }

  const handleChangeProcesses = (event) => {
    setRiskProcesses(event.target.value);
  };

  function handleChangeProvider(e) {
    const { value } = e.target;
    setValues({ ...values, selectedProvider: value, workFieldEvaluationId: null });
  }

  return (
    <div className="main">
      {processes
        ? (
          <ValidatorForm onSubmit={handleSubmit} autoComplete="off">

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Container>
                  <TextValidator
                    value={values.riskTitle}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    id="riskTitle"
                    label={titleLabel}
                    name="riskTitle"
                    size="small"
                    validators={['required']}
                    errorMessages={[validationConstants.RISK_TITLE_REQUIRED]}
                  />
                </Container>
              </Grid>

              <Grid item xs={12} md={6}>
                {providers && providers.length > 0 && (
                <Container>
                  <FormControl className={classes.formControlProvider}>
                    <InputLabel shrink={values.selectedProvider} id="selectedProvider">{workFieldEvaluationTexts.relatedProviderOptional}</InputLabel>
                    <Select
                      id="selectedProvider"
                      name="selectedProvider"
                      labelId="selectedProvider"
                      value={values.selectedProvider}
                      displayEmpty
                      onChange={handleChangeProvider}
                      MenuProps={MenuProps}
                      style={{ width: '100%' }}
                    >
                      <MenuItem disabled style={{ fontSize: '12px' }} value="">
                        <em>{workFieldEvaluationTexts.selectOption}</em>
                      </MenuItem>
                      {providers.map((provider) => (
                        <MenuItem key={provider.id} value={provider.id} style={{ fontSize: '12px' }}>
                          {provider.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Container>
                )}
                {values.selectedProvider && (
                <>
                  <Container>
                    <FormControl className={classes.formControlProvider}>
                      <InputLabel shrink={values.workFieldEvaluationId} id="workFieldEvaluationId">{workFieldEvaluationTexts.relatedEvaluation}</InputLabel>
                      <Select
                        id="workFieldEvaluationId"
                        name="workFieldEvaluationId"
                        labelId="workFieldEvaluationId"
                        value={values.workFieldEvaluationId}
                        displayEmpty
                        onChange={handleChange}
                        MenuProps={MenuProps}
                        style={{ width: '100%' }}
                      >
                        <MenuItem disabled style={{ fontSize: '12px' }} value="">
                          <Grid container>
                            <Grid item xs={4}><b><em>{workFieldEvaluationTexts.date}</em></b></Grid>
                            <Grid item xs={4}>
                              <b><em>{workFieldEvaluationTexts.workFieldEvaluated}</em></b>
                            </Grid>
                            <Grid item xs={4}>
                              <b><em>{workFieldEvaluationTexts.resultEvaluation}</em></b>
                            </Grid>
                          </Grid>
                        </MenuItem>
                        {workFieldEvaluationUnapproved && workFieldEvaluationUnapproved
                          .filter((e) => e.provider.id === values.selectedProvider)
                          .sort((a, b) => new Date(b.date) - new Date(a.date))
                          .map((evaluation) => (
                            <MenuItem key={evaluation.id} value={evaluation.id} style={{ fontSize: '12px' }}>
                              <Grid container>
                                <Grid item xs={4}>{moment(evaluation.date).format('DD/MM/YYYY')}</Grid>
                                <Grid item xs={4}>{evaluation.workField.name}</Grid>
                                <Grid item xs={4}>
                                  {workFieldHelper
                                    .getEvaluationStatusLabel(evaluation.evaluationStatus)}
                                </Grid>
                              </Grid>
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Container>
                </>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <Container>
                  <DatePicker
                    value={values.riskDetectionDate}
                    onChange={(value) => { setValues({ ...values, riskDetectionDate: value }); }}
                    fullWidth
                    margin="normal"
                    id="riskDetectionDate"
                    label={detectionDateLabel}
                    name="riskDetectionDate"
                    size="small"
                    format="dd/MM/yyyy"
                    minDateMessage={invalidDateLabel}
                  />
                </Container>
              </Grid>

              <Grid item xs={12} md={6}>
                <Container>
                  {processes && processes.length === 0 ? (
                    <Box mt={5}>
                      <Typography variant="subtitle1" display="inline" gutterBottom style={{ fontSize: '12px' }}>
                        {noProcessesText}
                      </Typography>
                      <Link to="/processes/new" style={{ textDecoration: 'none' }}>
                        <Typography variant="subtitle1" display="inline" gutterBottom style={{ fontSize: '12px', color: '#F16C24' }}>
                          {createProcessLink}
                        </Typography>
                      </Link>
                    </Box>

                  ) : (
                    <FormControl className={classes.formControlProvider}>
                      <InputLabel id="person">{processesLabel}</InputLabel>
                      <Select
                        id="riskProcesses"
                        displayEmpty
                        multiple
                        value={riskProcesses}
                        onChange={handleChangeProcesses}
                        MenuProps={MenuProps}
                        input={<Input />}
                        renderValue={(selected) => renderChip(selected)}
                        style={{ width: '100%' }}
                      >

                        {processes.map((process) => (
                          <MenuItem
                            key={process.id}
                            value={process}
                          >
                            <Checkbox
                              color="primary"
                              checked={riskProcesses.indexOf(process) > -1}
                            />
                            {`${process.name} `}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                </Container>
              </Grid>

              <Grid item xs={12} md={6}>
                <Container>
                  <Typography variant="caption" color="textPrimary" style={{ weight: 400, fontSize: 12 }}>
                    <FormattedMessage id="risks.label.identification" defaultMessage="Identificación" />
                  </Typography>
                  <TextField
                    InputProps={InputProps}
                    value={values.riskIdentification}
                    name="riskIdentification"
                    id="riskIdentification"
                    multiline
                    rows={5}
                    onChange={handleChange}
                    fullWidth
                    style={{ background: '#FFFFFF' }}
                  />
                </Container>
              </Grid>

              <Grid item xs={12} className={classes.formTitle}>
                <Container>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: 14 }}><FormattedMessage id="risks.organizationContext" defaultMessage="Contexto organización" /></strong>
                  </Typography>
                </Container>
              </Grid>

              <Grid item xs={12} md={6}>
                <Container>
                  <Typography variant="caption" color="textPrimary" style={{ weight: 400, fontSize: 12 }}>
                    <FormattedMessage id="risks.label.detail" defaultMessage="Detalle" />
                  </Typography>
                  <TextField
                    InputProps={InputProps}
                    value={values.riskDetail}
                    name="riskDetail"
                    id="riskDetail"
                    multiline
                    rows={5}
                    onChange={handleChange}
                    fullWidth
                    style={{ background: '#FFFFFF' }}
                  />
                </Container>
              </Grid>

              <Grid item xs={12} md={6}>
                <Container>
                  <Autocomplete
                    id="swotItemName"
                    name="swotItemName"
                    freeSolo
                    label={swotItemNameLabel}
                    options={company.swotItems}
                    inputValue={values.swotItemName}
                    groupBy={(option) => sortableHelper.getNameByType(option.type)}
                    getOptionLabel={(option) => option.description}
                    disableClearable
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label={swotItemNameLabel}
                        value={values.swotItemName}
                        onChange={(event) => { handleChangeSwotItem(event); }}
                        margin="normal"
                        validators={['required']}
                        errorMessages={[validationConstants.RISK_SWOT_ITEM_REQUIRED]}
                        className={classes.autoCompleteInput}
                      />
                    )}
                    onChange={(event, value) => { handleChangeSwotItem(event, value); }}
                  />
                </Container>
              </Grid>

              <Grid item xs={12} className={classes.formTitle}>
                <Container>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: 14 }}><FormattedMessage id="risks.stakeholders" defaultMessage="Partes involucradas" /></strong>
                  </Typography>
                </Container>
              </Grid>

              <Grid item xs={12} md={6}>
                <Container>
                  <Typography variant="caption" color="textPrimary" style={{ weight: 400, fontSize: 12 }}>
                    <FormattedMessage id="risks.label.stakeholdersIdentification" defaultMessage="Identificación" />
                  </Typography>
                  <TextField
                    InputProps={InputProps}
                    value={values.riskStakeholdersIdentification}
                    name="riskStakeholdersIdentification"
                    id="riskStakeholdersIdentification"
                    multiline
                    rows={5}
                    onChange={handleChange}
                    fullWidth
                    style={{ background: '#FFFFFF' }}
                  />
                </Container>
              </Grid>

              <Grid item xs={12} md={6}>
                <Container>
                  <Typography variant="caption" color="textPrimary" style={{ weight: 400, fontSize: 12 }}>
                    <FormattedMessage id="risks.label.risks.label.stakeholdersRequirement" defaultMessage="Requisito" />
                  </Typography>
                  <TextField
                    InputProps={InputProps}
                    value={values.riskRequirement}
                    name="riskRequirement"
                    id="riskRequirement"
                    multiline
                    rows={5}
                    onChange={handleChange}
                    fullWidth
                    style={{ background: '#FFFFFF' }}
                  />
                </Container>
              </Grid>

              <Grid item xs={12} className={classes.formTitle}>
                <Container>
                  <Typography variant="caption" gutterBottom>
                    <strong style={{ fontSize: 14 }}><FormattedMessage id="risks.evaluation" defaultMessage="Evaluación" /></strong>
                  </Typography>
                </Container>
              </Grid>

              <Grid item xs={12} md={6}>
                <Container>
                  <DatePicker
                    value={values.riskNextEvaluationDate}
                    onChange={(value) => setValues({ ...values, riskNextEvaluationDate: value })}
                    fullWidth
                    margin="normal"
                    id="riskNextEvaluationDate"
                    label={nextEvaluationDateLabel}
                    name="riskNextEvaluationDate"
                    size="small"
                    format="dd/MM/yyyy"
                    minDateMessage={invalidDateLabel}
                  />
                </Container>
              </Grid>
              <Grid item xs={12} md={6}>
                <Container>
                  <FormControl className={classes.formControl}>
                    <SelectValidator
                      className={classes.selectValidator}
                      id="inChargePersonId-select"
                      label={riskInChargePersonLabel}
                      name="inChargePersonId"
                      value={values.inChargePersonId}
                      onChange={handleChange}
                      fullWidth
                      MenuProps={MenuProps}
                      validators={['required']}
                      errorMessages={[validationConstants.RISK_RESPONSIBLE_REQUIRED]}
                    >
                      {people && people.map((item) => {
                        if (!item.names) return null;
                        return (
                          <MenuItem key={`${item.id}${item.surnames}`} value={item.id}>
                            {`${item.names} ${item.surnames}`}
                          </MenuItem>
                        );
                      })}
                    </SelectValidator>
                  </FormControl>
                </Container>
              </Grid>

              <Grid item xs={12} md={6}>
                <Container>
                  <Typography variant="caption" color="textPrimary" style={{ weight: 400, fontSize: 12 }}>
                    <FormattedMessage id="risks.label.actions" defaultMessage="Acciones" />
                  </Typography>
                  <TextValidator
                    InputProps={InputProps}
                    value={values.riskEvaluationActions}
                    name="riskEvaluationActions"
                    id="riskEvaluationActions"
                    multiline
                    rows={5}
                    onChange={handleChange}
                    fullWidth
                    style={{ background: '#FFFFFF' }}
                    validators={['required']}
                    errorMessages={[validationConstants.RISK_ACTIONS_REQUIRED]}
                  />
                </Container>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Container>
                  <SelectValidator
                    id="riskProbability"
                    name="riskProbability"
                    value={values.riskProbability}
                    label={probabilityLabel}
                    onChange={handleChange}
                    style={{ width: '100%', height: 45 }}
                    validators={['required']}
                    errorMessages={[validationConstants.RISK_PROBABILITY_REQUIRED]}
                    fullWidth
                  >
                    <MenuItem disabled value="">
                      <em>{probabilityLabel}</em>
                    </MenuItem>
                    {probabilityImpactValues.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </Container>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Container>
                  <SelectValidator
                    id="riskImpact"
                    name="riskImpact"
                    value={values.riskImpact}
                    label={impactLabel}
                    onChange={handleChange}
                    style={{ width: '100%', height: 45 }}
                    validators={['required']}
                    errorMessages={[validationConstants.RISK_IMPACT_REQUIRED]}
                    fullWidth
                  >
                    <MenuItem disabled value="">
                      <em>{impactLabel}</em>
                    </MenuItem>
                    {probabilityImpactValues.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </Container>
              </Grid>

              <Grid item xs={12} md={6}>
                <Container>
                  <DatePicker
                    value={values.riskEvaluationDate}
                    onChange={(value) => { setValues({ ...values, riskEvaluationDate: value }); }}
                    fullWidth
                    margin="normal"
                    id="riskEvaluationDate"
                    label={evaluationDateLabel}
                    name="riskEvaluationDate"
                    size="small"
                    format="dd/MM/yyyy"
                    minDateMessage={invalidDateLabel}
                  />
                </Container>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Container style={{ paddingTop: '12px' }}>
                  <TextField
                    readOnly
                    id="riskResult"
                    label={<FormattedMessage id="risks.label.coefficient" defaultMessage="Coeficiente" />}
                    value={riskResult}
                    fullWidth
                  />
                </Container>
              </Grid>
              <Grid item xs={12} sm={6} md={3} style={{ display: 'flex' }}>
                <Container style={{ paddingTop: '36px' }}>
                  <FormattedMessage id="risks.label.risk" defaultMessage="Riesgo" />
                  <span>:&nbsp;</span>
                  {values.riskProbability && values.riskImpact
                    ? (
                      <span style={{ color: getRiskColor(riskResult), fontWeight: 700 }}>
                        {riskLevel}
                      </span>
                    )
                    : (
                      <span style={{ fontWeight: 700 }}>
                        {riskLevel}
                      </span>
                    )}
                </Container>
              </Grid>

            </Grid>

            <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
              <Button
                component={Link}
                to="/risks"
                variant="outlined"
                text={cancelText}
                style={{ fontSize: '10px', fontWeight: 600 }}
              />
              <Button
                type="submit"
                loading={creatingRisk}
                text={createText}
              />
            </Box>
          </ValidatorForm>
        ) : (
          <LinearProgress />
        )}
    </div>
  );
};

export default RiskForm;
