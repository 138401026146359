const personConstants = {
  CREATE_REQUEST: 'PERSON_CREATE_REQUEST',
  CREATE_SUCCESS: 'PERSON_CREATE_SUCCESS',
  CREATE_FAILURE: 'PERSON_CREATE_FAILURE',

  GET_REQUEST: 'PERSON_GET_REQUEST',
  GET_SUCCESS: 'PERSON_GET_SUCCESS',
  GET_FAILURE: 'PERSON_GET_FAILURE',

  GET_ALL_REQUEST: 'PERSON_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'PERSON_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'PERSON_GET_ALL_FAILURE',

  UPDATE_REQUEST: 'PERSON_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PERSON_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PERSON_UPDATE_FAILURE',

  DELETE_REQUEST: 'PERSON_DELETE_REQUEST',
  DELETE_SUCCESS: 'PERSON_DELETE_SUCCESS',
  DELETE_FAILURE: 'PERSON_DELETE_FAILURE',

  PERSONAL_PHOTO_REQUEST: 'PERSON_PERSONAL_PHOTO_REQUEST',
  PERSONAL_PHOTO_SUCCESS: 'PERSON_PERSONAL_PHOTO_SUCCESS',
  PERSONAL_PHOTO_FAILURE: 'PERSON_PERSONAL_PHOTO_FAILURE',

  LINK_USER_REQUEST: 'PERSON_LINK_USER_REQUEST',
  LINK_USER_SUCCESS: 'PERSON_LINK_USER_SUCCESS',
  LINK_USER_FAILURE: 'PERSON_LINK_USER_FAILURE',

  UNLINK_USER_REQUEST: 'PERSON_UNLINK_USER_REQUEST',
  UNLINK_USER_SUCCESS: 'PERSON_UNLINK_USER_SUCCESS',
  UNLINK_USER_FAILURE: 'PERSON_UNLINK_USER_FAILURE',

  CLEAN_CREATED_PERSON: 'CLEAN_CREATED_PERSON',
};

export default personConstants;
