import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles,
  Box, Grid, Container, Typography, Select, MenuItem, IconButton, InputLabel,
  Checkbox, Input, TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import CreateIcon from '@material-ui/icons/Create';
import { validationConstants } from '../../constants';
import { outlineActions, personActions } from '../../redux/actions';
import Button from '../controls/Button';
import UploadIcon from '../../assets/icons/UploadIcon';
import MuiPhoneNumberValidator from '../controls/MuiPhoneNumberValidator';
import { validatorHelper, userHelper } from '../../helpers';
import { CloseIcon } from '../../assets/icons';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#F8F8F8',
    marginTop: '10px',
  },
  uploadLink: {
    color: '#F16C24',
  },
  uploadButton: {
    marginLeft: 15,
    backgroundColor: '#F16C24',
    color: '#fff',
    cursor: 'pointer',
    padding: '5px 15px',
    borderRadius: '30px',
    width: '172px',
    height: '25px',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '12px',
  },
  item: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  closeIcon: {
    width: 14,
    height: 13,
    cursor: 'pointer',
  },
  personRole: { fontWeight: 400, fontSize: 12, margin: 10 },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  getContentAnchorEl: null,
};

const initialImageValues = {
  url: '',
  image: '',
};

function PersonForm({ edit, data, imageUrl }) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const creatingPerson = useSelector((state) => state.person.creating);
  const deletingPerson = useSelector((state) => state.person.deleting);
  const updatingPerson = useSelector((state) => state.person.updating);
  const outlines = useSelector((state) => state.outline.outlines);
  const user = useSelector((state) => state.authentication.user);
  const company = useSelector((state) => state.company);
  const person = useSelector((state) => state.person);

  const isCompanyOwnerPersonToEdit = edit && company.data?.creatorUser === person.data?.applicationUser?.id
  const isAdmin = user.roles.includes('Admin');
  const noAccessRole = 'NoAccess';

  const getOutlinesId = (outlineToPerson) => outlineToPerson.map((outline) => outline.outlineId);

  const getUserType = (roles) => {
    if (!roles || (roles && roles.length === 0)) {
      return [noAccessRole];
    }
    return roles;
  };

  const initialFieldValues = {
    names: data ? data.names : '',
    surnames: data ? data.surnames : '',
    personnelFile: data ? data.personnelFile : '',
    role: data ? data.role : '',
    outlines: (data && data.outlines) ? getOutlinesId(data.outlines) : [],
    outlinesInputId: (data && data.outlines) ? JSON.stringify(getOutlinesId(data.outlines)) : '',
    email: data ? data.email : '',
    birthdate: data && data.birthdate ? data.birthdate : null,
    address: data ? data.address : '',
    deletePersonDialog: false,
    phoneNumber: data && data.phoneNumber ? data.phoneNumber : '',
    userType: getUserType(data ? data.userType : []),
    userTypeInput: JSON.stringify(getUserType(data ? data.userType : [])),
    personRoles: data ? data.personRoles : [],
    auxPersonRole: '',
  };
  const [imageValues, setImagesValues] = useState(initialImageValues);
  const [values, setValues] = useState(initialFieldValues);
  const createLink = intl.formatMessage({ id: 'outline.create', defaultMessage: 'crear un nuevo perfil.' });
  const firstNameLabel = intl.formatMessage({ id: 'people.names', defaultMessage: 'Nombre' });
  const lastNameLabel = intl.formatMessage({ id: 'people.surnames', defaultMessage: 'Apellido' });
  const personnelFileLabel = intl.formatMessage({ id: 'people.personnelFile', defaultMessage: 'Número de legajo' });
  const positionLabel = intl.formatMessage({ id: 'people.personRoles', defaultMessage: 'Puestos' });
  const mailLabel = intl.formatMessage({ id: 'user.mail', defaultMessage: 'mail' });
  const birthdateLabel = intl.formatMessage({ id: 'people.birthdate', defaultMessage: 'Fecha de nacimiento' });
  const addressLabel = intl.formatMessage({ id: 'people.address', defaultMessage: 'Dirección' });
  const changeProfilePhotoLabel = intl.formatMessage({ id: 'app.changeProfilePhoto', defaultMessage: 'CAMBIAR FOTO DE PERFIL' });
  const uploadProfilePhoto = intl.formatMessage({ id: 'app.uploadProfilePhoto', defaultMessage: 'SUBIR FOTO DE PERFIL' });
  const invalidDateLabel = intl.formatMessage({ id: 'app.invalid_date', defaultMessage: 'Fecha incorrecta' });
  const deletePersonTitle = intl.formatMessage({ id: 'people.delete.title', defaultMessage: 'Eliminar persona' });
  const deletePersonButtonText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'Eliminar' });
  const cancelButtonText = intl.formatMessage({ id: 'app.cancel', defaultMessage: 'Cancelar' });
  const deletePersonText = intl.formatMessage({ id: 'people.deletePersonText', defaultMessage: '¿Está seguro que quiere eliminar a la persona?' });
  const deleteText = intl.formatMessage({ id: 'app.delete', defaultMessage: 'eliminar' });
  const backText = intl.formatMessage({ id: 'app.back', defaultMessage: 'volver' });
  const saveText = intl.formatMessage({ id: 'app.save', defaultMessage: 'guardar' });
  const createText = intl.formatMessage({ id: 'app.create', defaultMessage: 'crear' });
  const noOutlineText = intl.formatMessage({ id: 'outline.noOutline', defaultMessage: 'No hay perfiles para asignar, puede ' });
  const addOutlineText = intl.formatMessage({ id: 'outline.addOutline', defaultMessage: 'Agregar perfil' });
  const phoneNumberLabel = intl.formatMessage({ id: 'people.phoneNumber', defaultMessage: 'Número de teléfono' });
  const userTypeText = intl.formatMessage({ id: 'people.userType', defaultMessage: 'Tipo de usuario' });
  const userTypeOptions = {
    Admin: intl.formatMessage({ id: 'people.admin', defaultMessage: 'Administrador' }),
    User: intl.formatMessage({ id: 'people.user', defaultMessage: 'Usuario' }),
    NoAccess: intl.formatMessage({ id: 'people.noAccess', defaultMessage: 'Sin acceso al sistema' }),
  };

  useEffect(() => {
    dispatch(outlineActions.getAll());
  }, [dispatch]);

  function handleSubmit(e) {
    e.preventDefault();
    const personFormData = new FormData();
    const listNotToAdd = ['outlines', 'phoneNumber', 'userType', 'userTypeInput'];
    Object.keys(values).forEach((key) => {
      if (values[key] && !listNotToAdd.includes(key)) {
        personFormData.append(key, values[key]);
      }
    });

    if (values.phoneNumber) {
      personFormData.append('phoneNumber', validatorHelper.phoneNumberFormatted(values.phoneNumber));
    }
    const rolesToAdd = values.userType
      ? values.userType.filter((role) => role !== noAccessRole)
      : [];
    personFormData.append('userTypeInput', JSON.stringify(rolesToAdd));
    personFormData.append('personRolesInput', JSON.stringify(values.personRoles));

    if (edit) {
      personFormData.append('id', data.id);
      dispatch(personActions.update(personFormData, data.id, '/people/'));
      return;
    }
    if (imageValues.image) personFormData.append('image', imageValues.image);
    dispatch(personActions.create(personFormData, '/people/'));
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  }

  function handleChangePhoneNumber(value) {
    setValues({ ...values, phoneNumber: value });
  }

  function handleChangeOutlines(e) {
    const { value } = e.target;
    setValues({
      ...values,
      outlines: value,
      outlinesInputId: JSON.stringify(value),
    });
  }

  function handleChangeUserType(e) {
    const { value } = e.target;
    let valueToSet = value;
    if (!values.userType.includes(noAccessRole) && value.includes(noAccessRole)) {
      valueToSet = [noAccessRole];
    }
    if (values.userType.includes(noAccessRole)
      && userHelper.roles.some((role) => value.includes(role))) {
      valueToSet = value.filter((item) => item !== noAccessRole);
    }
    setValues({
      ...values,
      userType: valueToSet,
      userTypeInput: JSON.stringify(valueToSet),
    });
  }

  function handleDialogOpen(dialogName) {
    setValues({ ...values, [dialogName]: true });
  }

  function handleDialogClose(dialogName) {
    setValues({ ...values, [dialogName]: false });
  }

  function deletePerson() {
    dispatch(personActions.deletePerson(data.id));
  }

  function handleClr(e) {
    e.stopPropagation();
    setValues({ ...values, birthdate: null });
  }

  function uploadFile(e) {
    e.preventDefault();
    if (edit) {
      const formToUpdate = new FormData();
      formToUpdate.append('image', e.target.files[0]);
      dispatch(personActions.uploadPersonalPhoto(formToUpdate, data.id));
    } else {
      setImagesValues({ url: URL.createObjectURL(e.target.files[0]), image: e.target.files[0] });
    }
  }

  function handleAddPersonRole() {
    const newRoleToAdd = {
      name: values.auxPersonRole,
      companyId: user.company.id,
      ...(data && data.id && { personId: data.id }),
    };

    setValues((prevState) => ({
      ...prevState,
      personRoles: prevState.personRoles.concat(newRoleToAdd),
      auxPersonRole: '',
      addPersonRoleModal: false,
    }));
  }

  function handleEditPersonRole(index) {
    const personRoleToEdit = values.personRoles[index];
    setValues((prevState) => ({
      ...prevState,
      auxPersonRole: personRoleToEdit.name,
      editPersonRoleModal: true,
      indexPersonRoleToEdit: index,
    }));
  }

  function handleEditPersonRoleConfirm() {
    const personRoles = [...values.personRoles];
    personRoles[values.indexPersonRoleToEdit].name = values.auxPersonRole;
    setValues((prevState) => ({
      ...prevState,
      personRoles,
      auxPersonRole: '',
      editPersonRoleModal: false,
      indexPersonRoleToEdit: null,
    }));
  }

  function handleRemovePersonRole(index) {
    const personRoleToRemove = values.personRoles[index];
    setValues((prevState) => ({
      ...prevState,
      auxPersonRole: personRoleToRemove.name,
      removePersonRoleModal: true,
      indexPersonRoleToRemove: index,
    }));
  }

  function handleRemovePersonRoleConfirm() {
    const personRoles = [...values.personRoles];
    personRoles.splice(values.indexPersonRoleToRemove, 1);
    setValues((prevState) => ({
      ...prevState,
      personRoles,
      auxPersonRole: '',
      removePersonRoleModal: false,
      indexPersonRoleToRemove: null,
    }));
  }

  return (
    <div className={classes.main}>
      <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={3}>
          { (user.roles.includes('Admin')) && (
          <Grid item xs={12} md={6}>
            <Container>
              <Box display="flex" alignItems="center" justifyContent="flex-start" className={classes.uploadLink}>
                <Box display="flex" alignItems="center">
                  {imageUrl || imageValues.url
                    ? (
                      <Avatar
                        style={{ width: 72, height: 72 }}
                        src={imageUrl || imageValues.url}
                        alt={`${values.names} ${values.surnames}`}
                      />
                    )
                    : (
                      <AccountCircle style={{
                        color: '#E0E0E0', width: 72, height: 72, transform: 'scale(1.25)',
                      }}
                      />
                    )}
                  <Box display="flex" alignItems="center">
                    <label htmlFor="file">
                      { imageUrl || imageValues.url
                        ? (
                          <Typography
                            display="inline"
                            style={{
                              textTransform: 'uppercase', cursor: 'pointer', paddingLeft: 15, fontSize: 10, fontWeight: 600,
                            }}
                          >
                            {changeProfilePhotoLabel}
                          </Typography>
                        )
                        : (
                          <Box display="flex" alignItems="center" justifyContent="center" className={classes.uploadButton}>
                            <UploadIcon display="inline" style={{ fontSize: 22, marginTop: '8px' }} />
                            <Typography display="inline" style={{ fontSize: 9 }}>{uploadProfilePhoto}</Typography>
                          </Box>
                        )}
                      <input
                        style={{ display: 'none' }}
                        id="file"
                        name="file"
                        type="file"
                        onChange={uploadFile}
                      />
                    </label>
                  </Box>
                </Box>
              </Box>
            </Container>
          </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Container style={{ paddingTop: imageUrl ? 32 : 0 }}>
              <TextValidator
                disabled={edit && data.applicationUser != null}
                value={values.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
                id="email"
                label={mailLabel}
                name="email"
                size="small"
                validators={['isEmail', 'required']}
                errorMessages={[
                  validationConstants.EMAIL_INVALID, validationConstants.EMAIL_REQUIRED,
                ]}
              />
            </Container>
          </Grid>

          <Grid item xs={12} md={6}>
            <Container>
              <TextValidator
                value={values.names}
                onChange={handleChange}
                fullWidth
                margin="normal"
                id="names"
                label={firstNameLabel}
                name="names"
                size="small"
                validators={['required']}
                errorMessages={[validationConstants.PERSON_FIRST_NAME_REQUIRED]}
              />
            </Container>
          </Grid>

          <Grid item xs={12} md={6}>
            <Container>
              <TextValidator
                value={values.surnames}
                onChange={handleChange}
                fullWidth
                margin="normal"
                id="surnames"
                label={lastNameLabel}
                name="surnames"
                size="small"
                validators={['required']}
                errorMessages={[validationConstants.PERSON_LAST_NAME_REQUIRED]}
              />
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container>
              <TextValidator
                autoComplete="off"
                value={values.personnelFile}
                onChange={handleChange}
                fullWidth
                margin="normal"
                id="personnelFile"
                label={personnelFileLabel}
                name="personnelFile"
                size="small"
                validators={['required']}
                errorMessages={[validationConstants.PERSON_PERSONNEL_FILE_REQUIRED]}
              />
            </Container>
          </Grid>

          <Grid item xs={12} md={6}>
            <Container>
              <DatePicker
                value={values.birthdate}
                onChange={(value) => {
                  setValues({ ...values, birthdate: new Date(value).toISOString() });
                }}
                fullWidth
                margin="normal"
                id="birthdate"
                label={birthdateLabel}
                name="birthdate"
                size="small"
                format="dd/MM/yyyy"
                minDateMessage={invalidDateLabel}
                disableFuture
                cancelLabel={cancelButtonText}
                InputProps={{
                  endAdornment: (
                    <IconButton style={{ padding: 5 }} onClick={(e) => handleClr(e)}>
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              />
            </Container>
          </Grid>

          <Grid item xs={12} md={6} style={{ marginTop: '13px' }}>
            <Container>
              {outlines && outlines.length
                ? (
                  <>
                    <InputLabel id="outlines-label">{addOutlineText}</InputLabel>
                    <Select
                      id="outlines"
                      name="outlines"
                      value={values.outlines}
                      labelId="outlines-label"
                      onChange={handleChangeOutlines}
                      MenuProps={MenuProps}
                      style={{ width: '100%' }}
                      multiple
                    >
                      <MenuItem disabled value="">
                        <em>{addOutlineText}</em>
                      </MenuItem>
                      {outlines.map((outline) => (
                        <MenuItem key={outline.id} value={outline.id}>
                          {outline.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )
                : (
                  <Box align="center">
                    <Typography className={classes.subtitle} align="center" display="inline">
                      {noOutlineText}
                    </Typography>

                    <Link to="/outlines/new" style={{ textDecoration: 'none' }}>
                      <Typography className={classes.subtitle} align="center" display="inline" style={{ color: '#F16C24' }}>
                        {createLink}
                      </Typography>
                    </Link>
                  </Box>
                )}
            </Container>
          </Grid>

          <Grid item xs={12} md={6}>
            <Container>
              <TextValidator
                value={values.address}
                onChange={handleChange}
                fullWidth
                margin="normal"
                id="address"
                label={addressLabel}
                name="address"
                size="small"
              />
            </Container>
          </Grid>

          <Grid item xs={12} md={6} style={{ marginTop: '16px' }}>
            <Container>
              <InputLabel shrink id="userType-label">{userTypeText}</InputLabel>
              <Select
                id="userType"
                name="userType"
                value={values.userType}
                onChange={handleChangeUserType}
                labelId="userType-label"
                MenuProps={MenuProps}
                style={{ width: '100%' }}
                multiple
                input={<Input />}
                disabled={isCompanyOwnerPersonToEdit}
                renderValue={(selected) => selected.map((value) => userTypeOptions[value]).join(', ')}
              >
                <MenuItem disabled value="">
                  <em>{userTypeText}</em>
                </MenuItem>
                {userHelper.roles.map((userType) => (
                  <MenuItem key={userType} value={userType}>
                    <Checkbox
                      color="primary"
                      checked={values.userType.indexOf(userType) > -1}
                    />
                    {userTypeOptions[userType]}
                  </MenuItem>
                ))}
                <MenuItem key={noAccessRole} value={noAccessRole}>
                  <Checkbox
                    color="primary"
                    checked={values.userType.indexOf(noAccessRole) > -1}
                  />
                  {userTypeOptions[noAccessRole]}
                </MenuItem>
              </Select>
            </Container>
          </Grid>

          <Grid item xs={12} md={6}>
            <Container>
              <MuiPhoneNumberValidator
                autoFormat={false}
                value={values.phoneNumber}
                onChange={handleChangePhoneNumber}
                defaultCountry="ar"
                fullWidth
                margin="normal"
                label={phoneNumberLabel}
                validators={['required', 'minStringLength:6']}
                errorMessages={[
                  validationConstants.PERSON_PHONE_REQUIRED,
                  validationConstants.PERSON_PHONE_INVALID,
                ]}
              />
            </Container>
          </Grid>

          <Grid item xs={12} md={6}>
            <Container>
              <Typography style={{ fontSize: '1rem', color: '#0000008a' }} className={classes.item}>
                {positionLabel}
              </Typography>
              { values.personRoles.length > 0
                ? (
                  <>
                    {values.personRoles.map((item, index) => {
                      const key = `personRole-${index}-${item.name}`;
                      return (
                        <Box key={key} display="flex" alignItems="center" justifyContent="space-between" className={classes.item}>
                          <Typography variant="caption" color="textPrimary" className={classes.personRole}>
                            {item.name}
                          </Typography>
                          <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <IconButton onClick={() => { handleEditPersonRole(index); }}>
                              <CreateIcon style={{ width: 16, height: 16, color: '#F79A56' }} />
                            </IconButton>
                            <IconButton onClick={() => { handleRemovePersonRole(index); }}>
                              <DeleteRoundedIcon style={{ width: 16, height: 16, color: '#727272' }} />
                            </IconButton>
                          </Box>
                        </Box>
                      );
                    })}
                  </>
                )
                : (
                  <Box display="flex" alignItems="center" justifyContent="flex-start" className={classes.item}>
                    <Typography variant="caption" color="textPrimary" className={classes.personRole}>
                      <FormattedMessage id="person.noRolePosition" defaultMessage="No hay puestos asignados" />
                    </Typography>
                  </Box>
                ) }
              <Typography
                variant="subtitle1"
                onClick={() => { handleDialogOpen('addPersonRoleModal'); }}
                style={{
                  fontSize: 11, fontWeight: 600, color: '#F16C24', textTransform: 'uppercase', cursor: 'pointer', padding: 10, paddingLeft: 0,
                }}
              >
                <FormattedMessage id="person.addRolePosition" defaultMessage="+ Agregar Puesto" />
              </Typography>
            </Container>
          </Grid>
          <Dialog open={!!values.addPersonRoleModal} onClose={() => { handleDialogClose('addPersonRoleModal'); }} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth>
            <DialogContent>
              <Box display="flex" justifyContent="flex-end">
                <CloseIcon className={classes.closeIcon} onClick={() => { handleDialogClose('addPersonRoleModal'); }} />
              </Box>
              <Typography align="center" className={classes.title}>
                <FormattedMessage id="person.addPersonRole" defaultMessage="Agregar puesto" />
              </Typography>
              <Box p={2} align="center">
                <Container>
                  <TextField
                    value={values.auxPersonRole}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    id="auxPersonRole"
                    label="Puesto"
                    name="auxPersonRole"
                    autoFocus
                    size="small"
                  />
                </Container>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
                <Button
                  onClick={() => { handleDialogClose('addPersonRoleModal'); }}
                  variant="outlined"
                  text={<FormattedMessage id="app.cancel" defaultMessage="Cancelar" />}
                />
                <Button
                  disabled={!values.auxPersonRole}
                  onClick={handleAddPersonRole}
                  text={<FormattedMessage id="app.save" defaultMessage="Guardar" />}
                />
              </Box>
            </DialogContent>
          </Dialog>
          <Dialog open={!!values.editPersonRoleModal} onClose={() => { handleDialogClose('editPersonRoleModal'); }} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth>
            <DialogContent>
              <Box display="flex" justifyContent="flex-end">
                <CloseIcon className={classes.closeIcon} onClick={() => { handleDialogClose('editPersonRoleModal'); }} />
              </Box>
              <Typography align="center" className={classes.title}>
                <FormattedMessage id="person.editPersonRole" defaultMessage="Editar puesto" />
              </Typography>
              <Box p={2} align="center">
                <Container>
                  <TextField
                    value={values.auxPersonRole}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    id="auxPersonRole"
                    label="Puesto"
                    name="auxPersonRole"
                    autoFocus
                    size="small"
                  />
                </Container>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
                <Button
                  onClick={() => { handleDialogClose('editPersonRoleModal'); }}
                  variant="outlined"
                  text={<FormattedMessage id="app.cancel" defaultMessage="Cancelar" />}
                />
                <Button
                  disabled={!values.auxPersonRole}
                  onClick={handleEditPersonRoleConfirm}
                  text={<FormattedMessage id="app.edit" defaultMessage="Editar" />}
                />
              </Box>
            </DialogContent>
          </Dialog>
          <Dialog open={!!values.removePersonRoleModal} onClose={() => { handleDialogClose('removePersonRoleModal'); }} fullWidth maxWidth="xs" aria-labelledby="form-dialog-title">
            <DialogContent>
              <Box display="flex" justifyContent="flex-end">
                <CloseIcon className={classes.closeIcon} onClick={() => { handleDialogClose('removePersonRoleModal'); }} />
              </Box>
              <Typography align="center" className={classes.title}>
                <FormattedMessage id="person.removePersonRole" defaultMessage="Eliminar puesto" />
              </Typography>
              <Box p={2} align="center">
                <DialogContentText>
                  <FormattedMessage id="person.removePersonRoleConfirm" defaultMessage="¿Está seguro que desea eliminar el puesto?" values={{ value: values.auxPersonRole }} />
                </DialogContentText>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
                <Button
                  onClick={() => { handleDialogClose('removePersonRoleModal'); }}
                  variant="outlined"
                  text={<FormattedMessage id="app.cancel" defaultMessage="Cancelar" />}
                />
                <Button
                  onClick={handleRemovePersonRoleConfirm}
                  text={<FormattedMessage id="app.delete" defaultMessage="Eliminar" />}
                />
              </Box>
            </DialogContent>
          </Dialog>
        </Grid>

        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
          <Button
            component={Link}
            to="/people"
            variant="outlined"
            text={backText}
            style={{ fontSize: '10px', fontWeight: 600 }}
          />
          {edit
            ? (
              <>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => { handleDialogOpen('deletePersonDialog'); }}
                  text={deleteText}
                  disabled={!isAdmin || isCompanyOwnerPersonToEdit}
                  style={{ fontSize: '10px', fontWeight: 600 }}
                />
                <Button
                  type="submit"
                  text={saveText}
                  disabled={!isAdmin}
                  loading={updatingPerson}
                  style={{ fontSize: '10px', fontWeight: 600 }}
                />
                <Dialog open={!!values.deletePersonDialog} onClose={() => { handleDialogClose('deletePersonDialog'); }} fullWidth maxWidth="xs" aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">{deletePersonTitle}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {deletePersonText}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button text={cancelButtonText} onClick={() => { handleDialogClose('deletePersonDialog'); }} />
                    <Button
                      className={classes.menuButton}
                      text={deletePersonButtonText}
                      color="secondary"
                      disabled={isCompanyOwnerPersonToEdit}
                      loading={deletingPerson}
                      onClick={deletePerson}
                    />
                  </DialogActions>
                </Dialog>
              </>
            )
            : (
              <>
                <Button
                  type="submit"
                  loading={creatingPerson}
                  text={createText}
                  disabled={!isAdmin}
                />
              </>
            )}

        </Box>
      </ValidatorForm>
    </div>
  );
}

PersonForm.propTypes = {
  data: PropTypes.shape(),
  edit: PropTypes.bool,
  imageUrl: PropTypes.string,
};

PersonForm.defaultProps = {
  data: {},
  edit: false,
  imageUrl: '',
};

export default PersonForm;
