import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { deviationActions } from '../../../redux/actions';
import { deviationConstants } from '../../../constants';
import BulkCreationResult from '../../BulkUpload/BulkCreationResult';

const useStyles = makeStyles((theme) => ({
  paddingNone: {
    padding: theme.spacing(0),
  },
  paddingItem: {
    paddingLeft: theme.spacing(0),
  },
}));

const DeviationBulkCreationResult = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const deviation = useSelector((state) => state.deviation);

  const tableErrors = [
    {
      id: 1,
      row: 'row',
      name: intl.formatMessage({ id: 'deviation.row', defaultMessage: 'Fila' }),
      classesHead: classes.paddingNone,
      classesBody: classes.paddingItem,
    },
    { id: 2, row: 'title', name: intl.formatMessage({ id: 'deviation.name', defaultMessage: 'Título' }) },
    {
      id: 3,
      row: 'origin',
      name: intl.formatMessage({ id: 'deviation.origin', defaultMessage: 'Origen' }),
      options: {
        customBodyRender: (value) => deviationConstants.origin[value].label,
      },
    },
    {
      id: 4,
      row: 'currentState',
      name: intl.formatMessage({ id: 'deviation.currentState', defaultMessage: 'Estado actual' }),
      options: {
        customBodyRender: (value) => deviationConstants.statusForm[value].label,
      },
    },
    {
      id: 5,
      row: 'detectionDate',
      name: intl.formatMessage({ id: 'deviation.form.detectionDate', defaultMessage: 'Fecha de detección' }),
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/YYYY'),
      },
    },
    { id: 6, row: 'description', name: intl.formatMessage({ id: 'deviation.description', defaultMessage: 'Descripción' }) },
  ];
  const tableLoaded = [
    {
      id: 1,
      row: 'title',
      name: intl.formatMessage({ id: 'deviation.name', defaultMessage: 'Título' }),
      classesHead: classes.paddingNone,
      classesBody: classes.paddingItem,
    },
    {
      id: 2,
      row: 'origin',
      name: intl.formatMessage({ id: 'deviation.origin', defaultMessage: 'Origen' }),
      options: {
        customBodyRender: (value) => deviationConstants.origin[value].label,
      },
    },
    {
      id: 3,
      row: 'currentState',
      name: intl.formatMessage({ id: 'deviation.currentState', defaultMessage: 'Estado actual' }),
      options: {
        customBodyRender: (value) => deviationConstants.statusForm[value].label,
      },
    },
    {
      id: 4,
      row: 'detectionDate',
      name: intl.formatMessage({ id: 'deviation.form.detectionDate', defaultMessage: 'Fecha de detección' }),
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/YYYY'),
      },
    },
    { id: 5, row: 'description', name: intl.formatMessage({ id: 'deviation.description', defaultMessage: 'Descripción' }) },
  ];

  const linkRedirect = '/deviations/upload';
  const linkEntity = '/deviations';
  const text = {
    bulkCreationBreadcrumbs: intl.formatMessage({ id: 'deviation.bulkCreationBreadcrumbs', defaultMessage: 'Carga múltiple' }),
    bulkNotCreated: intl.formatMessage({ id: 'deviation.bulkDeviationsNotCreated', defaultMessage: 'Estos desvíos no serán creados' }),
    bulkUploadedSuccess: intl.formatMessage({ id: 'deviation.bulkDeviationsUploadedSuccess', defaultMessage: 'Desvíos cargados correctamente:' }),
    create: intl.formatMessage({ id: 'deviation.createBulkDeviations', defaultMessage: 'Crear desvíos' }),
    emptyListSuccessfulUpload: intl.formatMessage({ id: 'deviation.emptyListSuccessfulUploadDeviations', defaultMessage: 'No hay desvíos cargados correctamente, por favor vuelva a cargar los datos respetando el archivo de ejemplo.' }),
    messageUploadedSuccess: intl.formatMessage({ id: 'deviation.messageUploadedSuccess', defaultMessage: 'Los desvíos se han agregado correctamente' }),
    onlySuccessUploadWillBeCreated: intl.formatMessage({ id: 'deviation.onlySuccessUploadDeviationsWillBeCreated', defaultMessage: 'Solo se crearán los desvíos cargados correctamente.' }),
    title: intl.formatMessage({ id: 'deviation.title', defaultMessage: 'Desvíos' }),
  };
  function handleConfirmDeviations() {
    return (dispatch(
      deviationActions.confirmCreateBulkDeviations(
        deviation.bulkCreationPreviewResult.id, text.messageUploadedSuccess,
      ),
    ));
  }

  return (
    <BulkCreationResult
      entity={deviation}
      handleConfirmResult={handleConfirmDeviations}
      linkEntity={linkEntity}
      linkRedirect={linkRedirect}
      tableErrors={tableErrors}
      tableLoaded={tableLoaded}
      text={text}
    />
  );
};

export default DeviationBulkCreationResult;
