const providerConstants = {
  CREATE_REQUEST: 'PROVIDER_CREATE_REQUEST',
  CREATE_SUCCESS: 'PROVIDER_CREATE_SUCCESS',
  CREATE_FAILURE: 'PROVIDER_CREATE_FAILURE',

  GET_REQUEST: 'PROVIDER_GET_REQUEST',
  GET_SUCCESS: 'PROVIDER_GET_SUCCESS',
  GET_FAILURE: 'PROVIDER_GET_FAILURE',

  GET_ALL_REQUEST: 'PROVIDER_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'PROVIDER_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'PROVIDER_GET_ALL_FAILURE',

  UPDATE_REQUEST: 'PROVIDER_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PROVIDER_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PROVIDER_UPDATE_FAILURE',

  DELETE_REQUEST: 'PROVIDER_DELETE_REQUEST',
  DELETE_SUCCESS: 'PROVIDER_DELETE_SUCCESS',
  DELETE_FAILURE: 'PROVIDER_DELETE_FAILURE',

  CREATE_BULK_REQUEST: 'PROVIDER_CREATE_BULK_REQUEST',
  CREATE_BULK_SUCCESS: 'PROVIDER_CREATE_BULK_SUCCESS',
  CREATE_BULK_FAILURE: 'PROVIDER_CREATE_BULK_FAILURE',

  CONFIRM_CREATE_BULK_REQUEST: 'PROVIDER_CONFIRM_CREATE_BULK_REQUEST',
  CONFIRM_CREATE_BULK_SUCCESS: 'PROVIDER_CONFIRM_CREATE_BULK_SUCCESS',
  CONFIRM_CREATE_BULK_FAILURE: 'PROVIDER_CONFIRM_CREATE_BULK_FAILURE',

  CLEAN_CREATED_PROCESS: 'CLEAN_CREATED_PROVIDER',
};

export default providerConstants;
